<template>
  <div :class="isHeading ? 'other-ai-container' : ''">
    <h2 v-if="isHeading" class="mb-4">{{ $t("glb_105") }}</h2>
    <div class="service-container">
      <router-link to="/pr" class="a-flex">
        <div class="icon-container shd-s">
          <img width="20" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/pr_logo.svg" class="mb-1">
        </div>
        <h3 class="service-name pr">{{ $t("aiList_1") }}</h3>
      </router-link>
      <p>{{ $t("aiList_2") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/fr" class="a-flex">
        <div class="icon-container shd-s">
          <img width="42" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fr/fr_logo.svg">
        </div>
        <h3 class="service-name fr">{{ $t("aiList_40") }}</h3>
      </router-link>
      <p>{{ $t("aiList_41") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/sc" class="a-flex">
        <div class="icon-container sai-chan shd-s">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
        </div>
        <h3 class="service-name sc">{{ $t("aiList_19") }}</h3>
      </router-link>
      <p>{{ $t("aiList_20") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/scplus" class="a-flex">
        <div class="icon-container sai-chan-plus shd-s">
          <v-img class="sai-chan-plus-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
            <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
          </v-img>
        </div>
        <h3 class="service-name sc-plus">{{ $t("aiList_26") }}</h3>
      </router-link>
      <p>{{ $t("aiList_27") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/pd" class="a-flex">
        <div class="icon-container shd-s">
          <img width="18" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pd/icon/pd_logo.svg">
        </div>
        <h3 class="service-name pd">{{ $t("aiList_34") }}</h3>
      </router-link>
      <p>{{ $t("aiList_35") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/mpm" class="a-flex">
        <div class="icon-container shd-s">
          <img width="32" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/mpm_logo.svg">
        </div>
        <h3 class="service-name mpm">{{ $t("aiList_30") }}</h3>
      </router-link>
      <p>{{ $t("aiList_31") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/lid" class="a-flex">
        <div class="icon-container shd-s">
          <img width="28" src="https://storage.googleapis.com/cre8tiveai_public_files/img/lid/icon/lid_logo.svg">
        </div>
        <h3 class="service-name lid">{{ $t("aiList_32") }}</h3>
      </router-link>
      <p>{{ $t("aiList_33") }}</p>
    </div>
    <div class="service-container">
      <a :href='$t("glb_animesr_link")' target="_blank" rel="noopener" class="a-flex">
        <div class="free-balloon">
          <div class="icon-container animesr shd-s">
            <img class="animesr-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/animesr/animesr_logo.webp">
          </div>
          <p class="service animesr">＼Free／</p>
        </div>
        <h3 class="service-name animesr">{{ $t("aiList_42") }}</h3>
      </a>
      <p>{{ $t("aiList_43") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/ep" class="a-flex">
        <div class="icon-container shd-s">
          <img width="20" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg">
        </div>
        <h3 class="service-name ep">{{ $t("aiList_28") }}</h3>
      </router-link>
      <p>{{ $t("aiList_29") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/aap" class="a-flex">
        <div class="icon-container shd-s">
          <img width="24" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aap/aap_logo.svg">
        </div>
        <h3 class="service-name aap">{{ $t("aiList_36") }}</h3>
      </router-link>
      <p>{{ $t("aiList_37") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/ps" class="a-flex">
        <div class="free-balloon">
          <div class="icon-container shd-s">
            <img width="45" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ps/ps_logo.svg">
          </div>
          <p class="service ps">＼Free／</p>
        </div>
        <h3 class="service-name ps">{{ $t("aiList_38") }}</h3>
      </router-link>
      <p>{{ $t("aiList_39") }}</p>
    </div>
    <div class="service-container">
      <router-link to="/mp" class="a-flex">
        <div class="icon-container shd-s">
          <img width="18" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/icon/mp_logo.svg">
        </div>
        <h3 class="service-name mp">{{ $t("aiList_13") }}</h3>
      </router-link>
      <p>{{ $t("aiList_14") }}</p>
    </div>
    <!-- 準備中 -->
    <template v-if="!isReleasedOnly">
      <div class="service-container">
        <router-link to="/mr" class="a-flex">
          <div class="icon-container shd-s">
            <img class="ml-2" width="28" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_logo.svg">
          </div>
          <h3 class="service-name mr">{{ $t("aiList_3") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_4") }}</p>
      </div>
      <div class="service-container">
        <router-link to="/ip" class="a-flex">
          <div class="icon-container shd-s">
            <img height="30" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ip/ip_logo.svg">
          </div>
          <h3 class="service-name ip">{{ $t("aiList_5") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_6") }}</p>
      </div>
      <div class="service-container">
        <router-link to="/oc" class="a-flex">
          <div class="icon-container shd-s">
            <img class="mr-1" height="30" src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_logo.svg">
          </div>
          <h3 class="service-name oc">{{ $t("aiList_7") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_8") }}</p>
      </div>
      <div class="service-container">
        <router-link to="/cb" class="a-flex">
          <div class="icon-container shd-s">
            <img width="20" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/cb/cb_logo.svg">
          </div>
          <h3 class="service-name cb">{{ $t("aiList_9") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_10") }}</p>
      </div>
      <div class="service-container">
        <router-link to="/fe" class="a-flex">
          <div class="icon-container shd-s">
            <img width="19" class="mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fe/fe_logo.svg">
          </div>
          <h3 class="service-name fe">{{ $t("aiList_11") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_12") }}</p>
      </div>
      <div class="service-container">
        <router-link to="/ld" class="a-flex">
          <div class="icon-container shd-s">
            <img width="30" class="ml-1 mb-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ld/ld_logo.svg">
          </div>
          <h3 class="service-name ld">{{ $t("aiList_15") }}[{{ $t("aiList_21") }}]</h3>
        </router-link>
        <p>{{ $t("aiList_16") }}</p>
      </div>
    </template>
    <!-- Next AI -->
    <template v-if="isNextAI">
      <div class="service-container">
        <div class="a-flex">
          <div class="icon-container shd-s">
            <img width="24" class="mr-1 mb-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/nextai-logo.svg">
          </div>
          <h3 class="service-name next">{{ $t("aiList_22") }}</h3>
        </div>
        <p>
          <span v-html='$t("aiList_23")'></span>
          <router-link to="/#g-form"><span v-html='$t("aiList_24")'></span></router-link>
          <span v-html='$t("aiList_25")'></span>
        </p>
      </div>
      <!-- リリース済AIが奇数の場合の調整要素（偶数の場合はコメントアウトする） -->
      <div class="service-container display-pc"></div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    isReleasedOnly: {
      type: Boolean,
      default: true
    },
    isHeading: {
      type: Boolean,
      default: true
    },
    isNextAI: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
    }
  },
  watch: {
    '$route': function (n, o) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
      console.log('new, old', [n.hash, o.hash])
    }
  },
  mounted () {
    if (this.$route.hash.match(/^#/)) {
      document.getElementById(this.$route.hash.replace(/^#/, '')).scrollIntoView()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.sai-chan-plus-image {
  overflow: visible;
  /deep/ &>.v-responsive__content,
  /deep/ &>.v-image__image {
    border-radius: 50%;
  }
}
.animesr-image {
  margin-top: 10px;
  margin-left: 1px;
}
.service-name {
  @mixin text-color($color:#757575) {
    color: $color;
    &:hover {
      text-decoration: underline;
    }
  }
  &.pr {
    @include text-color(#0D47A1);
  }
  &.sc {
    @include text-color(#D81B60);
  }
  &.sc-plus {
    @include text-color(#B753CE);
  }
  &.ep {
    @include text-color(#CB8023);
  }
  &.mpm {
    @include text-color(#46BA4D);
  }
  &.lid {
    @include text-color(#26B3CC);
  }
  &.mp {
    @include text-color(#0285D1);
  }
  &.pd {
    @include text-color(#00549A);
  }
  &.aap {
    @include text-color(#8F25C7);
  }
  &.ps {
    @include text-color(#FF5298);
  }
  &.fr {
    @include text-color(#FFA221);
  }
  &.animesr {
    @include text-color(#1E5EBA);
  }
}
</style>

<i18n src="@/locales/components/services.json"></i18n>
