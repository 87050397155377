import Vue from 'vue';
import Vuex from 'vuex';

import animeArtPainter from './animeArtPainter'
import anime4kPlayer from './anime4kPlayer'
import enpainter from './enpainter'
import faceRefiner from './faceRefiner'
import gallery from './gallery'
import lineDrawer from './lineDrawer'
import master from './master'
import monoPainter from './monoPainter'
import movingPhotoMaker from './movingPhotoMaker'
import pngSmallify from './pngSmallify'
import portraitDrawer from './portraitDrawer'
import saichan from './saichan'
import saichanPlus from './saichanPlus'
import screen from './screen'
import user from './user'
require('@/assets/scss/common.scss')

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
    // screen.jsからglobalに登録
    showErrorOnScreen({dispatch}, error) {
      dispatch('showError', error)
    },
    // user.jsより
    getCurrentGroupCreditInfoOfUser({ dispatch }) {
      dispatch('getCurrentGroupCreditInfo')
    }
  },
  modules: {
    animeArtPainter,
    anime4kPlayer,
    enpainter,
    faceRefiner,
    gallery,
    lineDrawer,
    master,
    monoPainter,
    movingPhotoMaker,
    pngSmallify,
    portraitDrawer,
    saichan,
    saichanPlus,
    screen,
    user,
  }
})
