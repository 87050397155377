import {
  SAI_CHAN_PLUS_UNIT_CREDIT, SAI_CHAN_PLUS_UNIT_CREDIT_FOR_POSE_MODELSHEET, SAI_CHAN_PLUS_UNIT_CREDIT_FOR_EXPRESSION, SAI_CHAN_PLUS_UNIT_CREDIT_FOR_HIGHRESOLUTION,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY, SAI_CHAN_PLUS_UNIT_PRICE_USD,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_POSE_MODELSHEET, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_POSE_MODELSHEET,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_EXPRESSION, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_EXPRESSION,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_HIGHRESOLUTION, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_HIGHRESOLUTION
} from '../settings'

const CLOTH_TYPE = {
  FANTASY: 1,
  UNIFORM: 2,
  OTHER: 3,
}
const POSE_TYPE = {
  FRONT: 1,
  TRADEMARK: 2,
  MODELSHEET: 3
}

// 彩ちゃん+の注文情報を保持/サーバに投げる
function initialState () {
  return {
    orderImageSrc: "", // 注文対象の透かし付き画像URL(注文確認メールに載る)
    orderImageIdx: "",  // 注文対象の画像index(透かし"無し"画像を特定するため、社内用に利用)
    faceAdjust: false, // 顔をバランス調整/適宜修正するか
    clothType: CLOTH_TYPE.FANTASY,
    clothText: "",
    poseType: POSE_TYPE.STANDING,
    poseText: "",
    changeExpression: false, // 表情
    expressionText: "",
    orderHighResolution: false, // 高解像度化
    otherText: "",
    // マスタデータ
    CLOTH_TYPE,
    POSE_TYPE
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    requiredCredits(state) {
      let amount = SAI_CHAN_PLUS_UNIT_CREDIT
      if (state.poseType === POSE_TYPE.MODELSHEET) {
        amount += SAI_CHAN_PLUS_UNIT_CREDIT_FOR_POSE_MODELSHEET
      }
      if (state.changeExpression) {
        amount += SAI_CHAN_PLUS_UNIT_CREDIT_FOR_EXPRESSION
      }
      if (state.orderHighResolution) {
        amount += SAI_CHAN_PLUS_UNIT_CREDIT_FOR_HIGHRESOLUTION
      }
      return amount
    },
    requiredJPY(state) {
      let price = SAI_CHAN_PLUS_UNIT_PRICE_JPY
      if (state.poseType === POSE_TYPE.MODELSHEET) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_POSE_MODELSHEET
      }
      if (state.changeExpression) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_EXPRESSION
      }
      if (state.orderHighResolution) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_HIGHRESOLUTION
      }
      return price
    },
    requiredUSD(state) {
      let price = SAI_CHAN_PLUS_UNIT_PRICE_USD
      if (state.poseType === POSE_TYPE.MODELSHEET) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_POSE_MODELSHEET
      }
      if (state.changeExpression) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_EXPRESSION
      }
      if (state.orderHighResolution) {
        price += SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_HIGHRESOLUTION
      }
      return price
    }
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setOrderImageInfo(state, payload) {
      state.orderImageSrc = payload.src
      state.orderImageIdx = payload.idx
    },
    setFaceAdjust(state, shouldAdjust) {
      state.faceAdjust = shouldAdjust
    },
    setCloth(state, payload) {
      state.clothType = payload.type
      state.clothText = payload.text
    },
    setPose(state, payload) {
      state.poseType = payload.type
      state.poseText = payload.text
    },
    setExpression(state, payload) {
      state.changeExpression = payload.willChange
      state.expressionText = payload.text
    },
    setHighResolution(state, willOrder) {
      state.orderHighResolution = willOrder
    },
    setOtherText(state, text) {
      state.otherText = text
    },
  },
  actions: {

  },
}