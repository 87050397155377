import { render, staticRenderFns } from "./DownloadModal.vue?vue&type=template&id=4eb5da12&scoped=true&"
import script from "./DownloadModal.vue?vue&type=script&lang=js&"
export * from "./DownloadModal.vue?vue&type=script&lang=js&"
import style0 from "./DownloadModal.vue?vue&type=style&index=0&id=4eb5da12&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb5da12",
  null
  
)

/* custom blocks */
import block0 from "@/locales/sai_chan/download_modal.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcreative_ai_front%2Fapp%2Fsrc%2Fviews%2FSaiChan%2FDownloadModal.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports