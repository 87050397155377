<template>
  <div>
    <div class="wlcm__service">
      <router-link to="/">
        <img class="wlcm__service-logo" src="../assets/img/logo.svg">
      </router-link>
    </div>

    <div class="wlcm">
      <div class="wlcm__background"></div>
      <div class="wlcm__container">
        <h2 class="wlcm__title">{{ $t("wlcm_1") }}</h2>
        <p class="wlcm__description"><span v-html="$t('wlcm_2')"></span></p>
        <home-cv-button class="cv-btn" :btnText='$t("wlcm_3")'></home-cv-button>
      </div>
    </div>

    <div class="white">
      <p class="wlcm__copyright">© 2019-2021 RADIUS5 Inc.</p>
    </div>
  </div>
</template>

<script>
import HomeCvButton from '@/components/HomeCvButton.vue'
export default {
  name: 'welcome',
  components: {
    HomeCvButton
  },
  data() {
    return {
      showUploadModal: false,
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', false)
    this.$store.commit('screen/footerVisible', false)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";

.wlcm__service {
  background-color: white;
  position: relative;
  z-index: 5;
  border-bottom: solid 1px #EEEEEE;
}

.wlcm__service-logo {
  max-width: 304px;
  padding: 24px 32px;

  @include mq-down {
    max-width: 256px;
  }
}

.wlcm {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(to top, #BBDEFB, white);
  overflow: hidden;
}

.wlcm__background {
  background-image: url('https://storage.googleapis.com/cre8tiveai_public_files/img/other/welcome-poly.svg');
  width: 100%;
  min-height: 600px;
  position: absolute;
  bottom: 0px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom -20% left 50vw;
  background-size: 100vh;

  @include mq-down {
    background-position: bottom -20% left 15vw;
  }
}

.wlcm__container {
  position: relative;
  z-index: 10;
  margin-top: 96px;

  @include mq-down {
    margin-top: 64px;
  }
}

.wlcm__title {
  font-size: 40px;
  font-weight: 500;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  @include mq-down {
    font-size: 28px;
  }
}

.wlcm__description {
  text-shadow:
    5px 5px 8px white,
    -5px -5px 8px white;
  font-size: 24px;
  margin-top: 48px;
  line-height: 40px;
  padding: 0 16px;

  @include mq-down {
    font-size: 18px;
    line-height: 32px;
  }
}

/deep/ .btn-cv {
  width: initial;
  padding: 0px 36px;
  min-height: 56px;
  font-size: 20px;
}

.wlcm__copyright {
  margin: 0;
  padding: 16px;
}

</style>
<i18n src="@/locales/welcome.json"></i18n>