<template>
  <div>
    <service-header :auth="auth"/>
    <div id="oc_top">
      <v-app>
        <!-- アイキャッチ -->
        <div class="eye_catch_container">
          <v-layout align-center justify-center row>
            <div class="icon_container">
              <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_logo.svg">
            </div>
            <img class="service-title display-sp ml-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_title.svg">
          </v-layout>
          <h1 class="explanation_1 mx-3">{{ $t("oc_1") }}</h1>

          <div class="illust-container mx-3 mb-4">
            <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_top_before.jpg'" class="before_image_container mx-2"></v-img>
            <img class="arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/arrow_ld.svg">
            <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_top_after.jpg'" class="after_image_container mx-2"></v-img>
          </div>
          <p class="note display-pc">{{ $t("oc_2") }}</p>
        </div>
        <!-- ドロップエリア -->
        <div class="drop_area_container">
          <v-layout align-center justify-center column fill-height class="drop_area">
            <p class="display-1 white--text mb-0">{{ $t("oc_3") }}</p>
          </v-layout>
        </div>

        <div class="intro_area_container">
          <p class="how_to">{{ $t("oc_4") }}<br>{{ $t("oc_5") }}</p>
          <!-- TODO: youtube埋め込み -->
          <img class="movie" :src='$t("oc_20")'>
          <!-- <img class="movie display-sp" :src='$t("oc_21")'> -->

          <!-- 広告 -->
          <ad-responsive></ad-responsive>

          <h2 class="tech">{{ $t("oc_6") }}</h2>
          <div class="ex_container">
            <v-layout align-start justify-space-around wrap>
              <v-flex sm3 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_image_3.jpg'" aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("oc_7") }}</p>
              </v-flex>
              <v-flex sm3 offset-sm1 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_image_1.jpg'"  aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("oc_8") }}</p>
              </v-flex>
              <v-flex sm3 offset-sm1 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_image_2.jpg'" aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("oc_9") }}</p>
              </v-flex>
            </v-layout>
          </div>
          <!-- 最先端のAI技術を使用！ -->
          <div class="times-container">
            <h2>
              <span v-html=" $t('oc_10') "></span>
            </h2>
            <!-- TODO: 画像差し替え -->
            <v-layout row wrap class="mt-4">
              <v-flex md5 xs12 :class="{'mx-4': $vuetify.breakpoint.smAndDown}">
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/image_oc_photo.jpg'" class="eg-pic"></v-img>
                <p>{{ $t("oc_11") }}</p>
              </v-flex>
              <v-flex md5 offset-md2 xs12 :class="{'mx-4': $vuetify.breakpoint.smAndDown}">
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/oc/image_oc_illust.jpg'" class="eg-pic"></v-img>
                <p>{{ $t("oc_12") }}</p>
              </v-flex>
            </v-layout>
          </div>
          <!-- Q&A -->
          <div class="faq-container">
            <h3>Q&A</h3>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("oc_13") }}</h4>
              <p>
                <span>A.</span>{{ $t("oc_14") }}
              </p>
            </div>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("oc_15") }}</h4>
              <p>
                <span>A.</span>{{ $t("oc_16") }}
              </p>
            </div>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("oc_17") }}</h4>
              <p>
                <span>A.</span>{{ $t("oc_18") }}
              </p>
            </div>
          </div>
          <!-- 広告 -->
          <ad-responsive></ad-responsive>
          <!-- 他のクリエイティブなAIを利用する -->
          <services :isReleasedOnly=false :isNextAI=true></services>
        </div>
      </v-app>
    </div>
  </div>
</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'
import Services from '@/components/Services.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
export default {
  name: 'OutlineClipperTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    Services,
    AdResponsive
  },
  data() {
    return {
      showUploadModal: false,
      droppedFiles: []
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

#oc_top {
  background-color: #FAFAFA;

  .eye_catch_container {
    width: 100%;
    max-width: 608px;
    text-align: center;
    margin: auto;
    position: relative;

    .icon_container {
      background-color: #fff;
      width: 80px;
      height: 80px;
      border-radius: 99px;
      box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px auto;
      img {
        height: 64%;
        margin: 7% 4% 0 0;
      }
      @include mq-down {
        width: 56px;
        height: 56px;
        margin: 16px 0px;
      }
    }
    .service-title {
      max-height: 24px;
    }
    h1.explanation_1 {
      font-size: 24px;
      color: #827717;
      font-weight: 400;
    }
    .illust-container {
      display: flex;
      justify-content: space-around;
      align-items: center;

      img.arrow {
        width: 64px;
        @include mq-down(sm) {
          width: 48px;
        }
      }
      @mixin image_container($color: #000) {
        border: solid 8px $color;
        border-radius: 16px;
        max-width: 188px;

        @include mq-down(sm) {
          border-width: 4px;
          border-radius: 12px;
        }
      }
      .before_image_container {
        @include image_container(#D4E157);
      }
      .after_image_container {
        @include image_container(#FFD740);
      }
    }
    p.note {
      margin:auto;
      font-size: 14px;
      color: #827717;
    }
  }
  // ドロップエリア
  .drop_area_container {
    background: linear-gradient(99deg, #FFEB3B, #AFB42B);
    height: 296px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    box-shadow: 0 0px 4px 0 rgba(0,0,0,0.4) inset;
    @include mq-down(sm) {
      padding: 10px;
      height: 142px;
    }

    .drop_area {
      max-width: 976px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 2px dashed #fff;
      @include mq-down(sm) {
        border-radius: 6px;
      }
    }
  }
  // イントロエリア
  .intro_area_container {
    background-color: #fff;
    justify-content: center;

    .how_to {
      margin-top: 96px;
      text-align: center;
      font-size: 28px;
      color: #424242;
      justify-content: center;
    }
    .movie {
      margin-top: 48px;
      max-width: 1040px;
      width: 100%;
    }
    .tech {
      margin-top: 128px;
      text-align: center;
      font-size: 32px;
      color: #424242;
      font-weight: 400;
    }
    .ex_container {
      max-width: 1040px;
      margin: auto;
      margin-top: 80px;

      .img {
        border-radius: 16px;
      }
      p {
        margin-top: 16px;
        font-size: 14px;
        color: #424242;
        text-align: left;
      }
    }
    h2 {
      font-size: 28px;
      color: #424242;
      font-weight: 400;
    }
    .times-container {
      max-width: 1040px;
      margin: 128px auto 0px auto;
      .eg-pic {
        border: solid #09153A 8px;
        border-radius: 20px;
        @include mq-down(md) {
          border-width: 6px;
        }
        @include mq-down(sm) {
          border-width: 4px;
        }
      }
      p {
        margin-top: 16px;
        text-align: left;
      }
    }
    .tenthousand-container {
      margin-top: 128px;
      .picture-container {
        width: 696px;
        margin: 56px auto;
      }
    }
    .faq-container {
      margin-top: 96px;
      h3 {
        font-size: 32px;
        font-weight: 400;
      }
      .qa-container {
        max-width: 720px;
        margin: 64px auto 0px auto;
        text-align: left;
        @include mq-down {
          margin: 64px 16px 0px 16px;
        }
        h4 {
          margin-bottom: 0px;
          span {
            margin-right: 4px;
            font-size: 28px;
            font-weight: 500;
            font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
          font-size: 20px;
          font-weight: 500;
          color: #234EB5;
        }
        p {
          font-size: 16px;
          span {
            color: #E91E63;
            margin-right: 4px;
            font-size: 28px;
            font-weight: 500;
            font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
        }
      }
    }
  }
}
</style>
<i18n src="@/locales/outline_clipper/top.json"></i18n>
