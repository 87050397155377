<template>
  <div id="footer" v-show="isFooterVisible">
    <div class="menu_container mb-5 pl-4">
      <v-layout row wrap>
        <v-flex class="products mt-5" md4 sm12 xs12>
          <p class="menu_title">{{ $t("apf_1") }}</p>
          <ul>
            <li><router-link to="/pr">{{ $t("apf_15") }}</router-link></li>
            <li><router-link to="/fr">{{ $t("apf_36") }}</router-link></li>
            <li><router-link to="/sc">{{ $t("apf_26") }}</router-link></li>
            <li><router-link to="/scplus">{{ $t("apf_27") }}</router-link></li>
            <li><router-link to="/ep">{{ $t("apf_28") }}</router-link></li>
            <li><router-link to="/mpm">{{ $t("apf_30") }}</router-link></li>
            <li><router-link to="/lid">{{ $t("apf_31") }}</router-link></li>
            <li><router-link to="/mp">{{ $t("apf_21") }}</router-link></li>
            <li><router-link to="/pd">{{ $t("apf_32") }}</router-link></li>
            <li><router-link to="/aap">{{ $t("apf_33") }}</router-link></li>
            <li><router-link to="/ps">{{ $t("apf_35") }}</router-link></li>
            <li><router-link to="/mr">{{ $t("apf_16") }}</router-link></li>
            <!-- <li><router-link to="/fc">{{ $t("apf_25") }}</router-link></li> -->
            <li><router-link to="/ip">{{ $t("apf_17") }}</router-link></li>
            <li><router-link to="/oc">{{ $t("apf_18") }}</router-link></li>
            <li><router-link to="/cb">{{ $t("apf_19") }}</router-link></li>
            <li><router-link to="/fe">{{ $t("apf_20") }}</router-link></li>
            <li><router-link to="/ld">{{ $t("apf_22") }}</router-link></li>
            <li><router-link to="/aar">{{ $t("apf_29") }}</router-link></li>
            <li><router-link to="/anime4k">{{ $t("apf_37") }}</router-link></li>
            <li><a :href='$t("glb_animesr_link")' target="_blank" rel="noopener">{{ $t("apf_41") }}</a></li>
          </ul>
        </v-flex>
        <v-flex class="usage mt-5" md4 sm6 xs12>
          <p class="menu_title">{{ $t("apf_2") }}</p>
          <ul>
            <li v-if="logined"><router-link to="/my">{{ $t("apf_4") }}</router-link></li>
            <li><router-link to="/price">{{ $t("apf_5") }}</router-link></li>
            <li><router-link to="/privacy">{{ $t("apf_7") }}</router-link></li>
            <li><router-link to="/terms">{{ $t("apf_6") }}</router-link></li>
            <li><router-link to="/commercial">{{ $t("apf_8") }}</router-link></li>
            <li><router-link to="/license">{{ $t("apf_24") }}</router-link></li>
            <li><a :href='$t("glb_gallery_link_1")' target="_blank" rel="noopener">{{ $t("apf_34") }}</a></li>
          </ul>
        </v-flex>
        <v-flex class="company mt-5" md4 sm6 xs12>
          <p class="menu_title">{{ $t("apf_9") }}</p>
          <ul>
            <li><a :href='$t("apf_13_link")' target="_blank" rel="noopener">{{ $t("apf_13") }}</a></li>
            <li><a :href='$t("glb_animerefiner_link")' target="_blank" rel="noopener">{{ $t("apf_38") }}</a></li>
            <li><a :href='$t("apf_11_link")' target="_blank" rel="noopener">{{ $t("apf_11") }}</a></li>
            <li><a :href='$t("glb_ooh_link")' target="_blank" rel="noopener">{{ $t("apf_39") }}</a></li>
            <li><a :href='$t("apf_40_link")' target="_blank" rel="noopener">{{ $t("apf_40") }}</a></li>
            <li><a :href='$t("apf_14_link")' target="_blank" rel="noopener">{{ $t("apf_14") }}</a></li>
          </ul>
          <div>
            <!-- TODO: ヘッダーのコピペのようだが動かないのでコメントアウト
            <p class="menu_title mt-4">{{ $t("apf_12") }}</p>
            <v-menu offset-y left class="lang-container">
              <v-btn slot="activator" color="grey darken-1" dark outline round class="lang-btn ml-0 mr-auto">
                <v-icon class="dd-icon">arrow_drop_down</v-icon>
                <v-flex class="lang-now" xs8>
                  {{ curLang }}
                </v-flex>
              </v-btn>
              <v-card>
                <v-container fluid>
                  <h4 class="h4-lang">{{ $t("apf_2") }}</h4>
                  <v-layout>
                      <v-list>
                        <v-list-tile v-for="(lang, index) in langsL" :key="index" @click="changeLang(lang)">
                          <v-list-tile-title>{{ lang }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                      <v-list>
                        <v-list-tile v-for="(lang, index) in langsR" :key="index" @click="changeLang(lang)">
                          <v-list-tile-title>{{ lang }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                  </v-layout>
                </v-container>
              </v-card>
            </v-menu>
            -->
          </div>
        </v-flex>
      </v-layout>
    </div>
    <hr>
    <div class="copy_right py-2">
      <p>© 2019-2023 RADIUS5 Inc.</p>
      <!--
      <img class="twitter" src="../assets/img/Twitter.svg">
      <img class="facebook" src="../assets/img/Facebook.svg">
      -->
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'
export default {
  name: 'AppFooter',
  data() {
    const localeDict = {
      'ja': '日本語',
      'en': 'English',
      'fr': 'Français',
      'es': 'Español',
      'pt': 'português',
      'de': 'Deutsch',
      'nl': 'Nederlands',
      'tr': 'Türkçe',
      'zh-cn': '简体中文',
      'zh-tw': '繁體中文',
      'sv': 'Svenska',
      'it': 'Italiano',
      'ko': '한국어',
      'ar': 'العربية',
      'th': 'ภาษาไทย',
      'id': 'Bahasa Indonesia',
      'pl': 'Polski',
      'vi': 'Tiếng Việt',
      'hi': 'தமிழ்',
      'ru': 'Русский',
      'bn': 'বাংলা'
    }
    let invertDict = {}
    Object.keys(localeDict).forEach(k => {
      invertDict[localeDict[k]] = k
    })
    return {
      isFooterVisible: this.$store.state.screen.isHeaderVisible,
      curLang: localeDict[this.$i18n.locale],
      localeDict: localeDict,
      invertDict: invertDict,
      langsL: [
        localeDict['ja'],
        localeDict['es'],
        localeDict['ar'],
        localeDict['ru'],
        localeDict['ko'],
        localeDict['it'],
        localeDict['id'],
        localeDict['pl'],
        localeDict['vi'],
        localeDict['th'],
        localeDict['bn']
      ],
      langsR: [
        localeDict['zh-cn'],
        localeDict['en'],
        localeDict['pt'],
        localeDict['de'],
        localeDict['fr'],
        localeDict['nl'],
        localeDict['tr'],
        localeDict['zh-tw'],
        localeDict['hi'],
        localeDict['sv']
      ]
    }
  },
  computed: {
    isHeaderVisible() {
      return this.$store.state.screen.isHeaderVisible
    },
    ...mapGetters('user', [
      'logined'
    ])
  },
  methods: {
    // TODO: app headerと同じく<a href のリンクに修正したい
    changeLang(lang) {
      const code = this.invertDict[lang]
      if (code) {
        this.curLang = lang
        localStorage.setItem('locale', code)
        Cookies.set('locale', code, {domain: 'cre8tiveai.com', expires: 365})
        this.$i18n.locale = code
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#footer {
  position: relative;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #f5f5f5;
  color: #757575;
  z-index: 100;

  .menu_container {
    margin: auto;
    max-width: 1040px;

    .menu_title {
      color: #616161;
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    ul {
      padding-left: 0px;
      margin-bottom: 0px;
    }
    li {
      font-size: 14px;
      list-style: none;
      text-align: left;
      line-height: 28px;
      font-weight: 500;
      a {
        color: #212121;
      }
    }
  }

  hr {
    display: block;
    width: 100%;
    border: 0;
    height: 1px;
    background-color: #c5c5c5;
  }

  .copy_right {

    p {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #757575;
      margin: 14px auto 8px;
    }

    img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 1px;
      &.twitter {
        right: 240px;
      }
      &.facebook {
        right: 180px;
      }
    }
  }
  // 言語選択ボタン
  .lang-container {
    float: left;
    .lang-btn {
      width: 160px;
      height: 32px;
      .lang-now {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .dd-icon {
        position: absolute;
        top:-2px;
        right: 128px;
      }
    }
  }
}
</style>

<i18n src="@/locales/components/app_footer.json"></i18n>
