import { FRONT_BASE_URL_TEMPLATE } from './settings'

// 言語に応じたHTMLの取得URL
// 本番はサブドメインのja.cre8tiveai.com等で指定言語でプリレンダリングされたHTMLを返す(サーバ側のmain.pyにて)
// localはja.localhost等のエンドポイントをvue.config.jsのdevServer.allowedHostsにて有効化してあるが、
// 返るHTMLはプリレンダリング前のものが返る。(しかし、i18n.jsのjudgeLocaleによってサブドメインに応じた言語にbody内が差し替わる)
// stage,prestageはサブドメインを用意しづらいのでTLD相当のenでプリレンダリングされたHTMLが返る
function frontBaseURLByLang(lang) {
  // 英語はサブドメイン無し
  if (lang === 'en') {
    return FRONT_BASE_URL_TEMPLATE.replace(/:\/\/{}/, '://')
  } else {
    return FRONT_BASE_URL_TEMPLATE.replace(/:\/\/{}/, `://${lang}.`)
  }
}

export {
  frontBaseURLByLang
}