
<template>
  <div class="icon-make grey lighten-4">
    <p style="position:fixed;visibility: hidden;">loaded:{{ loaded }}</p>

    <!-- モーダル↓ -->
    <!-- 初期画像(3種)選択モーダル-->
    <v-dialog scrollable persistent v-model="selectModal" max-width="756">
      <v-card class="grey lighten-5">
        <v-card-title class="white px-2">
          <v-layout justify-center align-center class="face-header mx-auto my-0">
            <v-img
              class="face-img elevation-2 ml-2"
              src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp"
            ></v-img>
            <div class="balloon balloon--left_top white mr-2">
              <p class="pink--text mb-0 mx-auto">{{ $t("sc_make_1") }}</p>
            </div>
          </v-layout>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap class="mt-5 mb-4">
            <template v-for="idx in [0, 1, 2]">
              <v-flex xs4 :key="idx">
                <v-img
                  :src="imgSrcFor1stChoice[idx]"
                  @click="fetchImagesFor2ndChoice(imgIdxFor1stChoice[idx])"
                  v-ripple="{ class: `white--text` }"
                  class="icon-btn-modal mx-auto"
                ></v-img>
              </v-flex>
            </template>
          </v-layout>
        </v-card-text>
        <v-card-text class="mb-3 text-xs-left">{{ $t("sc_make_2") }}</v-card-text>
      </v-card>
    </v-dialog>

    <!--ユーザー登録のモーダル-->
    <v-dialog v-model="showLoginModal" width="512">
      <v-card class="text-xs-left">
        <v-card-title class="headline pink--text" primary-title>{{ $t("sc_make_8") }}</v-card-title>
        <v-card-text v-show="loginModalReason === LOGIN_MODAL_REASON.DL">{{ $t("sc_make_11") }}</v-card-text>
        <v-card-text v-show="loginModalReason === LOGIN_MODAL_REASON.MANY_TRIAL">{{ $t("sc_make_9") }}</v-card-text>
        <v-card-text v-show="loginModalReason === LOGIN_MODAL_REASON.SC_PLUS_ORDER">{{ $t("sc_make_13") }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" outline round text @click="login('account')">{{ $t("sc_make_10") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- モーダル↑ -->

    <!-- 彩ちゃんガイド -->
    <v-layout justify-center align-center class="face-header mx-auto" :class="{hide:selectModal}">
      <v-img class="face-img elevation-2 ml-2" :src="sai_face"></v-img>
      <div class="balloon balloon--left_top white mr-2">
        <p class="pink--text mb-0 mx-auto">{{ saiChanGuideMessage }}</p>
      </div>
    </v-layout>

    <!-- 広告 -->
    <ad-responsive></ad-responsive>

    <v-layout justify-space-between row class="app-container">
      <!-- 画像群(2回目の選択) -->
      <div class="icon-meta-container">
        <v-fade-transition>
          <div v-show="nowLoading" class="loading-front" transition="fade-transition">
            <v-progress-circular :size="64" :width="8" color="white" block indeterminate></v-progress-circular>
          </div>
        </v-fade-transition>
        <div class="icon-container mx-auto">
          <v-scroll-x-reverse-transition>
            <v-layout
              align-start
              justify-space-between
              class="icons-column"
              v-show="!nowLoading&&!selectModal"
            >
              <template v-for="info in [
                {idx: 10, size: 's'},
                {idx:  4, size: 'm'},
                {idx:  0, size: 'l'},
                {idx:  5, size: 'm'},
                {idx: 11, size: 's'}
              ]">
                <v-img
                  @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[info.idx])"
                  :src="imgSrcFor2ndChoice[info.idx]"
                  v-ripple="{ class: `white--text` }"
                  :class="'icon-btn-' + info.size"
                  @load="update_loading_status()"
                  :key="'imgFor2ndChoice-' + info.idx"
                ></v-img>
              </template>
            </v-layout>
          </v-scroll-x-reverse-transition>
          <v-scroll-x-reverse-transition>
            <v-layout
              align-center
              justify-space-between
              :class="{'my-4': $vuetify.breakpoint.mdAndUp}"
              class="icons-column"
              v-show="!nowLoading&&!selectModal"
            >
              <v-img
                @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[8])"
                :src="imgSrcFor2ndChoice[8]"
                v-ripple="{ class: `white--text` }"
                class="icon-btn-s"
                @load="update_loading_status()"
              ></v-img>
              <v-img
                @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[3])"
                :src="imgSrcFor2ndChoice[3]"
                v-ripple="{ class: `white--text` }"
                class="icon-btn-l"
                @load="update_loading_status()"
              ></v-img>
              <v-img
                @click="fetchImagesFor2ndChoice(centerImgIdxFor2ndChoice)"
                :src="centerImgSrcFor2ndChoice"
                v-ripple="{ class: `white--text` }"
                class="icon-center"
              ></v-img>
              <v-img
                @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[1])"
                :src="imgSrcFor2ndChoice[1]"
                v-ripple="{ class: `white--text` }"
                class="icon-btn-l"
                @load="update_loading_status()"
              ></v-img>
              <v-img
                @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[9])"
                :src="imgSrcFor2ndChoice[9]"
                v-ripple="{ class: `white--text` }"
                class="icon-btn-s"
                @load="update_loading_status()"
              ></v-img>
            </v-layout>
          </v-scroll-x-reverse-transition>
          <v-scroll-x-reverse-transition>
            <v-layout
              align-end
              justify-space-between
              class="icons-column"
              v-show="!nowLoading&&!selectModal"
            >
              <template v-for="info in [
                {idx: 13, size: 's'},
                {idx:  6, size: 'm'},
                {idx:  2, size: 'l'},
                {idx:  7, size: 'm'},
                {idx: 12, size: 's'}
              ]">
                <v-img
                  @click="fetchImagesFor2ndChoice(imgIdxFor2ndChoice[info.idx])"
                  :src="imgSrcFor2ndChoice[info.idx]"
                  v-ripple="{ class: `white--text` }"
                  :class="'icon-btn-' + info.size"
                  @load="update_loading_status()"
                  :key="'imgFor2ndChoice-' + info.idx"
                ></v-img>
              </template>
            </v-layout>
          </v-scroll-x-reverse-transition>
        </div>
      </div>

      <div class="side-area">
        <!-- <v-scale-transition> -->
        <div class="current-icon__container">
          <v-img
            :src="centerImgSrcFor2ndChoice"
            v-ripple="{ class: `white--text`}"
            class="current-icon"
            @click="zoomModal=true"
          >
            <font-awesome-icon
              icon="search-plus"
              class="zoom-icon white--text"
              :class="{zooming: zoomModal}"
            />
          </v-img>
        </div>
        <!-- </v-scale-transition> -->
        <v-btn
          color="pink lighten-2"
          dark
          class="choose-btn title font-weight-medium text-none"
          :class="{'subheading':$vuetify.breakpoint.xs}"
          @click="on2ndChoiceConfirmed()"
        >
          <font-awesome-icon icon="check" class="check-icon text-none" />
          {{ $t("sc_make_3") }}
        </v-btn>

        <!-- <div class="current-container">
        </div>-->
        <v-btn
          @click="back_btn()"
          :disabled="disableBackBtn"
          color="pink lighten-2"
          column
          flat
          class="option-btn text-none"
        >
          <v-layout column align-center>
            <font-awesome-icon icon="undo-alt" class="mb-2 op-icon" />
            {{ $t("sc_make_4") }}
          </v-layout>
        </v-btn>
        <v-btn
          color="pink lighten-2"
          column
          flat
          class="option-btn display-sp text-none"
          @click.stop="historyDrawer = !historyDrawer"
        >
          <v-layout column align-center>
            <font-awesome-icon icon="history" class="mb-2 op-icon" />
            {{ $t("sc_make_5") }}
          </v-layout>
        </v-btn>
        <v-btn @click="random_btn()" color="pink lighten-2" column flat class="option-btn random text-none">
          <v-layout column align-center>
            <font-awesome-icon icon="dice" class="mb-2 op-icon" />
            {{ $t("sc_make_6") }}
          </v-layout>
        </v-btn>
        <v-btn color="pink lighten-2" column flat active-class class="option-btn home text-none" to="/sc">
          <v-layout column align-center>
            <font-awesome-icon icon="home" class="mb-2 op-icon" />
            {{ $t("sc_make_7") }}
          </v-layout>
        </v-btn>
      </div>
    </v-layout>

    <!-- ズームで表示 -->
    <v-dialog v-model="zoomModal" max-width="500">
      <v-card @click="zoomModal=false">
        <v-img :src="centerImgSrc512WMFor2ndChoice"></v-img>
      </v-card>
    </v-dialog>

    <!-- ヒストリー -->
    <div
      v-show="!selectModal"
      class="maker-footer white py-3 pink--text text--lighten-2 elevation-3 display-pc"
    >
      <div class="footer-container">
        <v-layout align-center justify-start class="history-container mx-auto">
          <font-awesome-icon class="ellipsis" v-show="imgIdxForHistory[5]" icon="ellipsis-h" />
          <template v-for="idx in [5, 4, 3, 2, 1, 0]">
            <v-img
              v-show="imgSrcForHistory[idx]"
              @click="back_img(idx, imgIdxForHistory[idx])"
              :src="imgSrcForHistory[idx]"
              v-ripple="{ class: `white--text` }"
              class="icon-btn-footer"
              :key="'imgForHistory-' + idx"
            ></v-img>
            <font-awesome-icon v-show="imgSrcForHistory[idx]" icon="chevron-right" :key="'iconForHistory-' + idx"/>
          </template>
          <v-img :src="centerImgSrcFor2ndChoice" class="history-current elevation-1"></v-img>
        </v-layout>
      </div>
    </div>

    <v-navigation-drawer
      v-model="historyDrawer"
      right
      fixed
      temporary
      width="128"
      class="display-sp"
    >
      <v-list class="pa-1 pink--text text--lighten-2">
        <v-layout align-center justify-end column fill-height>
          <h3>
            <font-awesome-icon icon="history" class="mr-1" />
            {{ $t("sc_make_5") }}
          </h3>
          <font-awesome-icon class="body-2 my-2" v-show="imgIdxForHistory[5]" icon="ellipsis-v" />
          <template v-for="idx in [5, 4, 3, 2, 1, 0]">
            <v-img
              v-show="imgSrcForHistory[idx]"
              @click="back_img(idx, imgIdxForHistory[idx])"
              :src="imgSrcForHistory[idx]"
              v-ripple="{ class: `white--text` }"
              class="icon-btn-footer"
              :key="'imgForHistorySP-' + idx"
            ></v-img>
            <font-awesome-icon
              v-show="imgSrcForHistory[idx]"
              icon="chevron-down"
              class="history-chevron-down"
              :key="'iconForHistorySP-' + idx"
            />
          </template>
          <v-img :src="centerImgSrcFor2ndChoice" class="history-current elevation-1"></v-img>
        </v-layout>
      </v-list>
    </v-navigation-drawer>
    <download-modal
      :showDialog="dlDialog"
      @onClickDownload="onClickDownload"
      :selectedImgSrc="centerImgSrc512WMFor2ndChoice"
      :selectedImgIdx="centerImgIdxFor2ndChoice"
      :holding_credit="having_credit"
      :is_dl_done="isDLdone"
      :current_plan_id="currentSubscription.id || 0"
      @proceedToOrder="openOrderModalIfLoggedIn"
      @close="dlDialog = false"
      :showDrawBodyOrderButton="$route.query.fromSaichanPlus || $route.params.fromSaichanPlus"
    ></download-modal>
    <!-- :new_selection="newSelection" -->

    <!-- MobileでのDL前に注意Dialogを表示 -->
    <v-dialog v-model="showConfirmDialogForMobile" persistent width="350">
      <v-card>
        <v-card-title class="title lighten-2 px-5" primary-title>{{ $t("glb_100") }}</v-card-title>
        <v-card-text>{{ $t("glb_102") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            flat
            @click="navigateToImageTabForMobile"
            class="mx-3 my-2"
          >{{ $t("glb_1") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 彩ちゃん+ 依頼モーダル -->
    <order-modal-container
      ref="orderModalRef"
      :show="showOrderDialog"
      @close="showOrderDialog = false"
    >
    </order-modal-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from 'vuex'

import { API_BASE_URL, SAI_CHAN_UNIT_CREDIT_FOR_DL, STRIPE_KEY } from "../../settings";
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import { isMobile } from "../util";
import DownloadModal from "./DownloadModal.vue";
import OrderModalContainer from "./Plus/OrderModalContainer.vue"

// ログインモーダルを表示する原因/契機
const LOGIN_MODAL_REASON = {
  DL: 1, // ダウンロードしようとした
  MANY_TRIAL: 2, // 特定回数以上顔生成を行った
  SC_PLUS_ORDER: 3, // 彩ちゃん+の注文に進もうとした
}

export default {
  name: "SaiChanMake",
  components: {
    AdResponsive,
    DownloadModal,
    OrderModalContainer,
  },
  data() {
    return {
      nowLoading: true,
      currentForm: null,
      selectModal: false,
      showLoginModal: false,
      loginModalReason: null,
      LOGIN_MODAL_REASON: LOGIN_MODAL_REASON,
      isDLdone: false,
      click_count: 0,
      errors: [],
      zoomModal: false,
      loader: null,
      times: 0,
      historyDrawer: null,
      dlDialog: false,
      having_credit: 0,
      loaded: [],
      sai_face:
        "https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp",
      sai_expressions: {
        normal:
          "https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp",
        nico:
          "https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg"
      },
      saiChanGuideMessage: "",
      cfm_pattern: [
        { message: "sc_serif_1", expr: "normal" },
        { message: "sc_serif_2", expr: "nico" },
        { message: "sc_serif_3", expr: "nico" },
        { message: "sc_serif_4", expr: "noraml" }
      ],
      rdm_pattern: [
        { prob: 8, message: "sc_serif_5", expr: "nico" },
        { prob: 8, message: "sc_serif_6", expr: "normal" },
        { prob: 8, message: "sc_serif_7", expr: "nico" },
        { prob: 5, message: "sc_serif_8", expr: "nico" },
        { prob: 5, message: "sc_serif_9", expr: "normal" },
        { prob: 2, message: "sc_serif_10", expr: "normal" },
        { prob: 8, message: "sc_serif_11", expr: "normal" },
        { prob: 8, message: "sc_serif_12", expr: "normal" },
        { prob: 8, message: "sc_serif_13", expr: "nico" },
        { prob: 8, message: "sc_serif_14", expr: "nico" },
        { prob: 2, message: "sc_serif_15", expr: "nico" },
        { prob: 5, message: "sc_serif_16", expr: "nico" },
        { prob: 8, message: "sc_serif_17", expr: "nico" },
        { prob: 2, message: "sc_serif_18", expr: "normal" },
        { prob: 2, message: "sc_serif_19", expr: "normal" },
        { prob: 5, message: "sc_serif_20", expr: "nico" },
        { prob: 8, message: "sc_serif_21", expr: "nico" }
      ],
      showConfirmDialogForMobile: false,
      imageDLURLForMobile: null,
      // 彩ちゃん+注文モーダル表示
      showOrderDialog: false
    };
  },
  props: ["auth"],
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    currentGroupTotalCredits() {
      return this.$store.state.user.currentGroupCreditInfo.total || 0;
    },
    currentSubscription() {
      if (this.$store.getters["user/currentGroup"] != null) {
        const currentGroup = this.$store.getters["user/currentGroup"];
        return this.$store.state.master.subscriptions.find(
          s => s.id === currentGroup.subscription_id
        );
      }
      return false;
    },
    disableBackBtn() {
      return !this.historyExists
    },
    ...mapGetters('saichan', [
      'historyExists'
    ]),
    ...mapState('saichan', {
      imgIdxFor1stChoice: state => state.imgIdxFor1stChoice,
      imgSrcFor1stChoice: state => state.imgSrcFor1stChoice,
      imgIdxForHistory: state => state.imgIdxForHistory,
      imgSrcForHistory: state => state.imgSrcForHistory,
      centerImgIdxFor2ndChoice: state => state.centerImgIdxFor2ndChoice,
      imgIdxFor2ndChoice: state => state.imgIdxFor2ndChoice,
      centerImgSrcFor2ndChoice: state => state.centerImgSrcFor2ndChoice,
      centerImgSrc512WMFor2ndChoice: state => state.centerImgSrc512WMFor2ndChoice,
      imgSrcFor2ndChoice: state => state.imgSrcFor2ndChoice,
      postDataHistories: state => state.postDataHistories,
    })
  },
  methods: {
    update_loading_status() {
      this.loaded.push(true);
      if (this.loaded.length == 14) {
        this.nowLoading = this.loaded.some(value => value == false);
      }
    },
    get_download_status() {
      const groupId = this.$store.state.user.currentGroupId;
      if (this.logined && groupId) {
        axios.get(`${API_BASE_URL}/sc/dl_status/${groupId}`).then(rs => {
          this.dlStatus = rs.data;
          this.having_credit = this.dlStatus.credit_amount;
        });
      }
    },

    // 所有クレジット数取得
    get_holding_credit() {
      if (this.$store.getters["user/currentGroup"] != null) {
        if (this.currentSubscription.id == 1) {
          return "none";
        }
        this.get_download_status()
      }
    },

    onClickDownload() {
      if (!this.logined) {
        this.showLoginModal = true;
        this.loginModalReason = LOGIN_MODAL_REASON.DL;
      } else {
        const groupId = this.$store.state.user.currentGroupId;

        axios.get(`${API_BASE_URL}/sc/dl_status/${groupId}`).then(async (rs) => {
          this.dlStatus = rs.data;
          if (this.dlStatus.credit_amount >= SAI_CHAN_UNIT_CREDIT_FOR_DL) {
            this.consume_sc();
          } else {
            const res = await this.getDownloadStatus()
            if (res.data["is_released"]) {
              this.execDownload(res.data["file_url"]);
            } else {
              this.paymentCheckout();
            }
          }
        });
      }
    },

    getDownloadStatus() {
      let form = new FormData();
      form.append("file_index", this.centerImgIdxFor2ndChoice);
      form.append("group_id", this.$store.getters["user/currentGroup"].id);
      return axios.post(`${API_BASE_URL}/sc/get_download_status`, form)
    },

    // 都度課金
    async paymentCheckout() {
      let lang
      if (this.dlStatus.paid_plan_subscribed) {
        // 一応サーバでもチェックして加入プランの言語を優先するようにしてある
        lang = this.dlStatus.language
      } else {
        lang = this.$i18n.locale == "ja" ? "ja" : "en"
      }
      let reqParam = {
        file_index: this.centerImgIdxFor2ndChoice,
        group_id: this.$store.getters["user/currentGroup"].id,
        // 彩ちゃん+から来た時は DownloadModalの表示が変わるので、決済成功/キャンセル時に戻ってきたときにも同じ表示にするのに必要
        from_plus: this.$route.query.fromSaichanPlus || this.$route.params.fromSaichanPlus,
        lang: lang
      }
      // serverからsessionを受け取り、stripeがhostしている決済ページに飛ばす
      this.$store.commit('saichan/dump')
      const res = await axios.post(`${API_BASE_URL}/payment/v3/sc/prepare`, reqParam)
      const group = this.$store.getters['user/currentGroup']
      /*global gtag*/
      gtag('event', 'show_payment_screen', {
        'event_category': 'payment',
        'event_label': 'pay_as_you_go_sc',
        'value': group.subscription_id,
      })
      /*global Stripe*/
      const stripe = Stripe(STRIPE_KEY)
      const {error} = await stripe.redirectToCheckout({
        sessionId: res.data.session_id
      })
      if (error) {
        this.$store.commit('screen/showError', error)
        // eslint-disable-next-line no-console
        console.log('[ERROR]', error)
      }
    },
    login(focusedTab) {
      this.$store.commit('saichan/dump')
      let landingPath = this.$route.path + '?restore=true'
      if (this.loginModalReason === LOGIN_MODAL_REASON.SC_PLUS_ORDER) {
        // ログイン後の顔生成でも絵師に頼むボタンを表示する
        landingPath += '&fromSaichanPlus=true'
      }
      this.auth.login(focusedTab, landingPath);
    },
    set_pattern: function(dic) {
      this.saiChanGuideMessage = this.$t(dic["message"])
      var expression = dic["expr"];
      this.sai_face = this.sai_expressions[expression];
    },
    setSaiChanGuideMessage: function() {
      if (this.times === 1) {
        this.set_pattern(this.cfm_pattern[0]);
      } else if (this.times === 2) {
        this.set_pattern(this.cfm_pattern[1]);
      } else if (this.times === 3) {
        this.set_pattern(this.cfm_pattern[2]);
      } else {
        var prob_sum = this.rdm_pattern.reduce((p, x) => p + x.prob, 0);
        var rand = Math.floor(Math.random() * prob_sum); //0から100までの乱数
        for (let v of this.rdm_pattern) {
          if (rand < prob_sum) this.set_pattern(v);
          prob_sum = prob_sum - v.prob;
        }
      }
    },

    // クレジット消費
    consume_sc: function() {
      if (!this.logined) {
        this.showLoginModal = true;
        this.loginModalReason = LOGIN_MODAL_REASON.DL;
      } else {
        const hash = require("js-sha1").create();
        const file_name = hash
          .update((parseInt(this.centerImgIdxFor2ndChoice) * 1059757).toString(10))
          .hex();
        const group = this.$store.getters['user/currentGroup']
        let form = new FormData();
        form.append("file_index", this.centerImgIdxFor2ndChoice);
        form.append("file_name", file_name);
        form.append("group_id", group.id);
        this.currentForm = form;

        axios
          .post(`${API_BASE_URL}/credit/consume/sc`, this.currentForm)
          .then(response => {
            this.execDownload(response.data);
          });

        this.isDLdone = true;
        gtag('event', 'credit_consume_complete', {
          'event_category': 'credit',
          'event_label': 'saichan',
          'value': group.subscription_id
        })
      }
    },

    // 初期３モーダルデータ取得
    fetchImagesFor1stChoice: function() {
      this.isDLdone = false;
      this.$store.dispatch('saichan/fetchImagesFor1stChoice')
        .then(() => {
          this.selectModal = true;
        })
        .catch(e => {
          this.errors.push(e);
        })
    },

    // 上部画像クリック時の処理
    fetchImagesFor2ndChoice: function(selected_img) {
      // 初期のモーダルを閉じる
      this.selectModal = false;

      // ログインしていないユーザーが３回以上クリックしたらリダイレクト
      this.redirect_not_login();

      this.nowLoading = true;

      //ログイン中(モーダルが出なかったら)
      if (this.showLoginModal != true) {
        this.loaded = []
        this.$store.dispatch('saichan/fetchImagesFor2ndChoice', selected_img)
          .then(() => {
            // 回数をプラス
            this.times += 1;
            // 台詞を更新
            this.setSaiChanGuideMessage();
            this.isDLdone = false;
          })
          .catch(e => {
            this.errors.push(e);
          })
      }
    },

    // 過去画像クリック時の処理
    back_img: function(selected_buttom_number, selected_img_index) {
      // ログインしていないユーザーが３回以上クリックしたらリダイレクト
      this.redirect_not_login();
      if (this.showLoginModal != true) {
        this.loaded = [];
        this.nowLoading = true;
        this.isDLdone = false;
        this.$store.dispatch('saichan/back_to_index', {
          selected_buttom_number: selected_buttom_number,
          selected_img_index: selected_img_index
        }).then(() => {
          this.times = 1;
        })
      }
    },

    // 戻るボタン
    back_btn: function() {
      const historyLength = this.postDataHistories.length;
      if (historyLength >= 2 && this.imgSrcForHistory[0]) {
        // ロードモーダルを表示
        this.nowLoading = true;
        this.loaded = []
        this.$store.dispatch('saichan/back')
          .then(() => {
            // 回数をマイナス
            this.times -= 1;
          })
      }
    },

    // ランダムボタン
    random_btn: function() {
      this.clearData()
        .then(() => {
          // モーダルデータ取得
          this.fetchImagesFor1stChoice();
        })

      if (window.innerWidth < 769) {
        this.$store.commit("screen/headerVisible", false);
        this.$store.commit("screen/footerVisible", false);
      }
    },

    // 暗号化
    //encryption: function() {},

    // 初回クリック時の状態に戻す処理
    clearData: function() {
      this.isDLdone = false;
      this.times = 1;
      return this.$store.dispatch('saichan/clearData')
    },

    // // ログインしていないユーザーが３回以上クリックしたらリダイレクト
    redirect_not_login: function() {
      this.click_count++;
      if (this.click_count > 4 && !this.logined) {
        this.showLoginModal = true;
        this.loginModalReason = LOGIN_MODAL_REASON.MANY_TRIAL;
      }
    },
    // 確定ボタン押下
    on2ndChoiceConfirmed() {
      this.dlDialog=true
      this.get_download_status()
    },
    execDownload(fileURL) {
      // Mobileは別タブで画像を開き、画像長押しでDLしてもらう
      if (isMobile(navigator.userAgent)) {
        this.showConfirmDialogForMobile = true;
        this.imageDLURLForMobile = fileURL;
        return;
      }

      // PCは自動でDL
      const dlLink = document.createElement("a");
      dlLink.href = fileURL;
      dlLink.download = fileURL;
      dlLink.click();
      window.URL.revokeObjectURL(fileURL);
    },
    // Mobile用にダイアログ表示後別タブで画像を開く
    navigateToImageTabForMobile() {
      const link = document.createElement("a");
      link.href = this.imageDLURLForMobile;
      link.target = "_blank";
      link.click();
      this.showConfirmDialogForMobile = false;
      this.downloading = false;
    },
    // 注文用モーダルを開く
    openOrderModalIfLoggedIn(imageInfo) {
      // 注文モーダルを一度閉じて再度開く場合のため、明示的に初期化
      this.$refs.orderModalRef.setup()

      if (!this.logined) {
        this.showLoginModal = true
        this.loginModalReason = LOGIN_MODAL_REASON.SC_PLUS_ORDER
        return
      }
      this.$store.commit('saichanPlus/setOrderImageInfo', imageInfo)
      this.dlDialog = false
      this.showOrderDialog = true
    }
  },
  created() {
    // 「ホーム」押下で彩ちゃんTOPに遷移して戻ってきたときに以前の顔生成結果が残らないように
    this.$store.commit("saichan/clearData")
    this.get_holding_credit();

    // 彩ちゃんの課金キャンセル or 課金完了、彩ちゃん+キャンセル の時に顔生成を復元する
    if (this.$route.query.restore) {
      this.$store.commit('saichan/restore')
    }
    if (this.$route.query.restore && this.$store.state.saichan.restored) {
      this.$store.commit('saichan/unsetRestored')
      this.setSaiChanGuideMessage()

      // DLモーダルを開く
      this.on2ndChoiceConfirmed()
      // 決済済みなら dl=true で遷移してくるので即DL
      if (this.$route.query.dl) {
        // ログインの完了を待ってからDL
        this.$store.state.user.logined$.subscribe({
          next: (logined) => {
            if (logined) {
              //this.onClickDownload()
              this.getDownloadStatus()
                .then((res) => {
                  if (res.data["is_released"]) {
                    this.execDownload(res.data["file_url"]);
                    this.isDLdone = true; // DLボタン下の文言を非表示にする

                    const group = this.$store.getters['user/currentGroup']
                    gtag('event', 'payment_complete', {
                      'event_category': 'payment',
                      'event_label': 'pay_as_you_go_sc',
                      'value': group.subscription_id
                    })
                  }
                });
            }
          }
        })
      }
    } else {
      // 初期選択(3択)のデータ取得
      this.fetchImagesFor1stChoice();
    }

    if (window.innerWidth < 769) {
      this.$store.commit("screen/headerVisible", false);
      this.$store.commit("screen/footerVisible", false);
    }
    if (this.logined) this.$store.dispatch("getCurrentGroupCreditInfoOfUser");
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

// variable
$accent-color: #ff5891;

@mixin override($color: white, $mode: soft-light) {
  content: "";
  background-color: $color;
  position: absolute;
  width: 100%;
  height: 100%;
  mix-blend-mode: $mode;
  transition: 0.1s;
  z-index: 50;
}

@mixin icon-style($size) {
  max-width: $size;
  max-height: $size;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(black, 0.4);
  border: solid 4px white;
  box-sizing: content-box;
  @include mq-down {
    border-width: 2px;
  }
}
@mixin icon-btn {
  cursor: pointer;
  transition: 0.2s;
  &::after {
    @include override;
    opacity: 0;
  }
  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(black, 0.4);
    position: relative;
    &::after {
      @include override;
      opacity: 0.5;
    }
  }
}

a {
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

// モーダルのスタイル
.test_icon {
  color: white;
  /*padding-right: 10%;*/
  width: 100%;
}
.icon-btn-modal {
  @include icon-style(160px);
  @include icon-btn;

  @include mq-down {
    max-width: 22vw;
  }
}
// scss classes
.face-header {
  max-width: 1040px;
  margin-top: 16px;
  margin-bottom: 16px;

  &.hide {
    visibility: hidden;
  }

  @include mq-down {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .face-img {
    width: 100%;
    max-width: 72px;
    border-radius: 99px;
    border: solid 2px $accent-color;
    @include mq-down {
      max-width: 40px;
      max-height: 40px;
    }
  }

  @mixin balloon {
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    padding: 8px 16px;
    margin-left: 20px;
    border-radius: 16px;
    p {
      font-size: 24px;
    }
    @include mq-down {
      padding: 8px 0px;
      border-radius: 8px;
      margin-left: 12px;
      width: 100%;
      p {
        font-size: calc(3.64vw + 0.4px);
      }
    }
  }

  .balloon {
    @include balloon;
    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      width: 20px;
      height: 20px; /* 吹き出しサイズ */
    }
    &:after {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      @include mq-down {
        border-radius: 8px;
      }
    }
    & > * {
      position: relative;
      z-index: 3;
      background: white;
    }
  }
  .balloon,
  .balloon::before {
    box-shadow: 0 1px 3px 0 rgba(black, 0.3); /* ぼかし距離(第3引数)，拡散距離(第4引数)，色(第5引数) */
  }
  .balloon,
  .balloon::before,
  .balloon::after {
    background: white; /* 背景色 */
  }

  .balloon--left_top::before {
    left: -6px;
    top: 50%; /* 位置 */
    transform: translateY(-50%) rotate(45deg) skew(-12deg, -12deg); /* 傾斜角(skew) */
    @include mq-down {
      left: -2px;
    }
  }
}

.icon-make {
  width: 100%;

  .app-container {
    background-color: #eeeeee;
    position: relative;
    margin: auto;
    max-width: 1040px;
    margin-bottom: 48px;
    border: solid 1px #dbdbdb;
    border-radius: 4px;
    margin-bottom: 48 px;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      /* 輪郭がぼやけてしまうのでブラー範囲を広げる */
      top: -24px;
      bottom: -24px;
      left: -24px;
      right: -24px;
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/makeing-bg.png);
      background-position: 36% center;
      background-repeat: no-repeat;
      filter: blur(24px);
    }
    @include mq-down {
      margin-bottom: 0px;
      border-radius: 0px;
    }
  }

  .loading-front {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(black, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;
  }

  .icon-meta-container {
    width: 100%;
    position: relative;
  }

  .icon-container {
    max-width: 800px;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    position: relative;
    box-sizing: content-box;
    padding-right: 16px;
    padding-left: 16px;

    @include mq-down {
      display: flex;
      justify-content: space-between;
      margin-top: auto;
      margin-bottom: auto;
      height: 73vh;
      padding: 6px;
      box-sizing: border-box;
    }

    .icons-column {
      @include mq-down {
        flex-direction: column;
      }
    }

    .icon-center {
      @include icon-style(144px);
      @include icon-btn;
      border-color: $accent-color;
      @include mq-down {
        @include icon-style(84px);
      }
    }
    .icon-btn-s {
      @include icon-style(88px);
      @include icon-btn;
      @include mq-down {
        @include icon-style(72px);
      }
    }
    .icon-btn-m {
      @include icon-style(106px);
      @include icon-btn;
      @include mq-down {
        @include icon-style(78px);
      }
    }
    .icon-btn-l {
      @include icon-style(124px);
      @include icon-btn;
      @include mq-down {
        @include icon-style(80px);
      }
    }
  }

  .side-area {
    background-color: white;
    position: relative;
    max-width: 160px;
    width: 100%;
    height: 496px;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 0px 0px 2px 0px rgba(black, 0.24);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include mq-down {
      width: 20vw;
      min-width: 88px;
      height: initial;
      border-radius: 0px;
    }

    .current-icon__container {
      align-self: normal;
      margin: 16px 16px 0;

      @include mq-down {
        margin: 4px 4px 0;
      }

      .current-icon {
        @include icon-style(100%);
        height: 100%;
        min-height: 128px;
        box-sizing: border-box;
        border-color: $accent-color;
        box-shadow: none;
        cursor: pointer;
        transition: 0.2s;

        @include mq-down {
          margin-bottom: 6px;
          min-height: 76px;
          height: auto;
        }

        &::before {
          @include override(black, darken);
          opacity: 0;
        }
        .zoom-icon {
          position: relative;
          opacity: 1;
          transition: 0.2s;
          z-index: 51;
          font-size: 28px;
          position: absolute;
          bottom: 4px;
          left: 4px;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.6));
        }

        @mixin zoom-icon-focus {
          opacity: 0.8;
          font-size: 64px;
          bottom: 25%;
          left: 25%;
        }

        &:hover {
          box-shadow: 0px 0px 8px 0px rgba(black, 0.4);
          position: relative;
          box-shadow: initial;
          @include mq-up {
            &::before {
              @include override(black, darken);
              opacity: 0.2;
            }
            .zoom-icon {
              @include zoom-icon-focus;
            }
          }
        }
        @include mq-up {
          .zooming {
            @include zoom-icon-focus;
          }
        }
      }
    }

    .choose-btn {
      align-self: normal;
      border-radius: 6px;
      max-height: 40px;
      font-size: 20px;
      font-weight: 500;
      margin: 0 16px 8px;

      @include mq-down {
        margin: 0 4px;
      }

      &:hover {
        text-decoration: none;
      }
      .check-icon {
        font-size: 16px;
        margin-right: 8px;
        @include mq-down {
          font-size: 12px;
          margin: 4px;
        }
      }
    }

    /deep/ .v-btn {
      min-width: 20px;
    }

    .option-btn {
      width: 128px;
      height: initial;
      font-size: 20px;
      padding-top: 8px;
      padding-bottom: 8px;

      .op-icon {
        font-size: 28px;
      }

      @include mq-up {
        margin: 0;
      }

      @include mq-down {
        width: 84%;
        height: initial;
        font-size: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        .op-icon {
          font-size: 28px;
        }
      }
      &.random {
        // margin-bottom: 8px;
        @include mq-down {
          margin-bottom: 0px;
        }
      }
      &.home {
        margin-bottom: 12px;
        @include mq-down {
          margin-bottom: 8px;
        }
      }
    }
  }

  .maker-footer {
    position: sticky;
    bottom: 0px;
    z-index: 100;

    .footer-container {
      max-width: 1040px;
      width: 100%;
      margin: auto;
      font-size: 40px;

      .history-container {
        max-width: 872px;
      }

      .ellipsis {
        font-size: 24px;
        align-self: flex-end;
      }
    }
  }
  .history-current {
    @include icon-style(72px);
    width: 10vh;
    border-radius: 99px;
    border-color: $accent-color;
    margin: auto 24px;
    box-shadow: initial;
    @include mq-down {
      margin: 0;
    }
  }

  .icon-btn-footer {
    @include icon-style(72px);
    @include icon-btn;
    width: 10vh;
    border-radius: 99px;
    border-width: 2px;
    margin: auto 24px;

    @include mq-down {
      margin: 0;
    }
  }

  .history-chevron-down {
    font-size: 4vh;
  }
}
</style>
<i18n>
{
  "ja": {
    "sc_serif_1": "好きなテイストのキャラを選んでください",
    "sc_serif_2": "選択したキャラに近い絵を描いていきます",
    "sc_serif_3": "少しずつ欲しい絵に近づけていきますね",
    "sc_serif_4": "いま表示中の絵は類似度がかなり高いです",
    "sc_serif_5": "中心に近いほど類似度が高いイラストです",
    "sc_serif_6": "四隅のキャラは少しテイストを変えています",
    "sc_serif_7": "中央を選択すると類似度が高い絵を描きます",
    "sc_serif_8": "顔イラスト以外も色々と勉強中です",
    "sc_serif_9": "ダウンロードすると透かしが無くなります",
    "sc_serif_10": "三角の模様とアイコンは透かしです",
    "sc_serif_11": "ランダムボタンでランダムに描きます",
    "sc_serif_12": "もどるボタンで一つ前の状態に戻ります",
    "sc_serif_13": "スピードでは誰にも負けない自信があります",
    "sc_serif_14": "100万種類以上のイラストを描けます",
    "sc_serif_15": "サービスの要望はどんどんお寄せください",
    "sc_serif_16": "商用利用できるイラストです",
    "sc_serif_17": "SNS等でシェアしてくれると喜びます",
    "sc_serif_18": "PCだけでなくスマホからも描きます",
    "sc_serif_19": "もっと絵が上手に描けるようになりたい…",
    "sc_serif_20": "ここのイラストは全部わたしが描きました",
    "sc_serif_21": "私、ときどき独創的な絵を描いちゃいます…",
    "sc_make_1": "まずは3つの中から選んでね",
    "sc_make_2": "イラスト生成AI 彩ちゃんがオリジナルのイラストを生成します。自分が欲しいテイストを選択するとその類似のイラストを生成してくれます。欲しいキャラが決まったらダウンロードしてください。",
    "sc_make_3": "確定",
    "sc_make_4": "もどる",
    "sc_make_5": "履歴",
    "sc_make_6": "ランダム",
    "sc_make_7": "ホーム",
    "sc_make_8": "ログインが必要です",
    "sc_make_9": "会員登録がまだの方は新規会員登録をしてからログインしてください。ログインをするともっと多くのイラストを生成できるようになります。",
    "sc_make_10": "新規会員登録 / ログイン",
    "sc_make_11": "イラストをダウンロードするにはログインが必要です。会員登録がまだの方は新規会員登録をしてください。",
    "sc_make_12": "新規会員登録 / ログイン",
    "sc_make_13": "絵師に依頼するにはログインが必要です。会員登録がまだの方は新規会員登録をしてください。"
  },
  "en": {
    "sc_serif_1": "Please choose your favorite character.",
    "sc_serif_2": "Draws a picture similar to the selected character",
    "sc_serif_3": "Gradually get closer to the illustration you want",
    "sc_serif_4": "The photos displayed are very similar to each other.",
    "sc_serif_5": "The closer the image appears to the center, the more similar it is to the original illustration.",
    "sc_serif_6": "The four corner characters are a little different in design.",
    "sc_serif_7": "Select the center image to draw similar pictures to it.",
    "sc_serif_8": "I am studying various type of illustrations.",
    "sc_serif_9": "When you download the image, the watermark will disappear.",
    "sc_serif_10": "The triangle pattern and icon are watermarks.",
    "sc_serif_11": "Draw an image at random by clicking the Random button",
    "sc_serif_12": "Press the return button to return to previous states.",
    "sc_serif_13": "No one beats me in drawing speed.",
    "sc_serif_14": "I draw more than 1 million kinds of illustrations.",
    "sc_serif_15": "Please let me know if you have any requests for service.",
    "sc_serif_16": "This illustration can be used commercially.",
    "sc_serif_17": "Please feel free to share my drawings on SNS.",
    "sc_serif_18": "I work not only from a PC but also from a smartphone.",
    "sc_serif_19": "I want to learn to draw better!",
    "sc_serif_20": "I drew all the illustrations here.",
    "sc_serif_21": "I sometimes draw funny pictures...",
    "sc_make_1": "First, choose from among three images.",
    "sc_make_2": "SAI generates original illustrations. If you select a picture you want, it will generate a similar picture. When you find a character you like, please feel free to download it.",
    "sc_make_3": "Select  ",
    "sc_make_4": "Undo",
    "sc_make_5": "History",
    "sc_make_6": "Random",
    "sc_make_7": "Home",
    "sc_make_8": "Please sign in",
    "sc_make_9": "If you have not create account yet, please sign in after create account. You can generate more illustrations after sign in.",
    "sc_make_10": "CREATE ACCOUNT /  SIGN IN",
    "sc_make_11": "You need to sign in to download illustrations. If you have not account, please create account.",
    "sc_make_12": "CREATE ACCOUNT /  SIGN IN",
    "sc_make_13": "You need to sign in to order illustrations. If you have not account, please create account."
  },
  "fr": {
    "sc_serif_1": "Choisissez votre type d’image.",
    "sc_serif_2": "Je crée une icône faciale similaire à l’image sélectionnée",
    "sc_serif_3": "Je vais progressivement la rapprocher de l'image souhaitée.",
    "sc_serif_4": "L'image affichée est assez similaire à l'image sélectionnée.",
    "sc_serif_5": "Plus l'image est au centre, plus la similitude est élevée.",
    "sc_serif_6": "Les personnages aux quatre coins affichent plus de différences",
    "sc_serif_7": "Sélectionner le centre pour que je dessine une image très similaire.",
    "sc_serif_8": "J'étudie beaucoup plus que des icônes faciales",
    "sc_serif_9": "Le filigrane numérique disparaîtra une fois l'image téléchargée",
    "sc_serif_10": "Le motif triangulaire et les icônes sont des filigranes",
    "sc_serif_11": "Cliquez sur \"aléatoire\" pour que je dessine aléatoirement.",
    "sc_serif_12": "Revenir en arrière",
    "sc_serif_13": "Personne n'est aussi rapide que moi",
    "sc_serif_14": "Je dessine plus d’un million d’icônes différentes",
    "sc_serif_15": "Envoyez moi plus de demandes de service",
    "sc_serif_16": "Ces images sont commercialement exploitables",
    "sc_serif_17": "Je serais ravie si vous partagiez sur les réseaux sociaux",
    "sc_serif_18": "Je suis également accessible depuis votre smartphone. ",
    "sc_serif_19": "J’aimerais dessiner mieux que ça…",
    "sc_serif_20": "J’ai fait tous ces dessins",
    "sc_serif_21": "Parfois, je dessine des images particulières…",
    "sc_make_1": "Parmi ces trois images, laquelle vous inspire le plus?",
    "sc_make_2": "SAI dessine une icône faciale originale.  \nChoisissez une image qui vous inspire, SAI dessinera une icône.  Une fois terminée, vous pourrez la télécharger. ",
    "sc_make_3": "Continuer",
    "sc_make_4": "Revenir",
    "sc_make_5": "Historique",
    "sc_make_6": "Aléatoire",
    "sc_make_7": "Accueil",
    "sc_make_8": "Il faut se connecter ",
    "sc_make_9": "Connectez-vous pour créer plus d'icônes.  Si vous n’êtes pas encore inscrit, inscrivez-vous dès maintenant. ",
    "sc_make_10": "S’inscrire / Se connecter",
    "sc_make_11": "Veuillez vous connecter pour télécharger l’image. \nVeuillez vous inscrire si vous n’êtes pas encore client. ",
    "sc_make_12": "S’inscrire / Se connecter",
    "sc_make_13": "Vous devez vous connecter aux illustrations de commande. Si vous ne l'avez pas compte, s'il vous plaît créer le compte."
  },
  "es": {
    "sc_serif_1": "Por favor elija el personaje con tu gusto preferido.",
    "sc_serif_2": "Voy a dibuja la ilustración parecida al personaje que ha elegido.",
    "sc_serif_3": "Voy ajustando a la ilustración que deseas poco a poco.",
    "sc_serif_4": "La imagen que se muestra actualmente es bastante similar.",
    "sc_serif_5": "Las imágenes que están más al centro son las que más se aproximan.",
    "sc_serif_6": "Los personajes en las cuatro esquinas tienen un aspecto ligeramente diferente.",
    "sc_serif_7": "Si elije la central, voy a dibujar la ilustración con una alta similitud.",
    "sc_serif_8": "Estoy aprendiendo varias ilustraciones además de las de rostros.",
    "sc_serif_9": "Si la descarga, desaparecerá la marca de agua digital.",
    "sc_serif_10": "El triángulo y el ícono son marcas de agua digital.",
    "sc_serif_11": "Dibujo al azar con el botón aleatorio.",
    "sc_serif_12": "Se vuelve al estado anterior con el botón de regresar.",
    "sc_serif_13": "Estoy seguro de que no perderé con otro I.A. en la velocidad.",
    "sc_serif_14": "Puedo dibujar más de 1,000,000 ilustraciones.",
    "sc_serif_15": "Por favor siéntese libre para enviar las solicitudes de servicio.",
    "sc_serif_16": "Son las ilustraciones permitidas para uso comercial.",
    "sc_serif_17": "Me alegra que puedas compartirlo por SNS, etc.",
    "sc_serif_18": "Dibujo no sólo a través de PC, sino también mediante Smartphone.",
    "sc_serif_19": "Me gustaría dibujar mejor las ilustraciones…",
    "sc_serif_20": "Dibujé todas las ilustraciones que están aquí.",
    "sc_serif_21": "A veces hago dibujos muy originales…",
    "sc_make_1": "Primero, elija una de las tres, por favor.",
    "sc_make_2": "A.I. para generar las ilustraciones.\n SAI-chan genera la ilustración original. Al elegir la que es de tu agrado, crea el dibujo parecido a ese. Al definir el personaje que quiere, descárguelo por favor.",
    "sc_make_3": "Finalización",
    "sc_make_4": "Regresar",
    "sc_make_5": "Historias",
    "sc_make_6": "Aleatório",
    "sc_make_7": "Página Principal",
    "sc_make_8": "Se requiere registrarse",
    "sc_make_9": "Los que no se registraron, por favor realicen el registro e inicien la sesión. Al registrarse, pueden generar más ilustraciones.",
    "sc_make_10": "Registro de nuevo miembro / Iniciar sesión",
    "sc_make_11": "Para descargar la ilustración, se necesita registrarse",
    "sc_make_12": "Registro de nuevo miembro / Iniciar sesión",
    "sc_make_13": "Es necesario iniciar sesión en ilustraciones de orden. Si no tiene una cuenta, por favor crear una cuenta."
  },
  "pt": {
    "sc_serif_1": "Selecione o personagem preferido",
    "sc_serif_2": "Desenhe um personagem semelhante ao selecionado",
    "sc_serif_3": "Transformando-se na ilustração solicitada passo a passo",
    "sc_serif_4": "Ilustração mostrada agora tem alta similaridade",
    "sc_serif_5": "Ilustração no centro tem maior semelhança",
    "sc_serif_6": "O personagem dos cantos tem um sabor um pouco diferente",
    "sc_serif_7": "Se você selecionar o centro, a semelhança será alta",
    "sc_serif_8": "Estudar outras coisas além da ilustração facial",
    "sc_serif_9": "Após o download, a marca d'água será removida",
    "sc_serif_10": "Triângulo e ícone são marca d'água",
    "sc_serif_11": "Botão aleatório irá desenhar aleatoriamente",
    "sc_serif_12": "O botão Voltar pode voltar ao anterior",
    "sc_serif_13": "Tenha confiança na velocidade mais do que qualquer outra pessoa",
    "sc_serif_14": "Capaz de desenhar mais de um milhão de variações",
    "sc_serif_15": "Envie-nos a solicitação de serviço",
    "sc_serif_16": "Capaz de usar para uso comercial",
    "sc_serif_17": "Estamos felizes em ser compartilhados pelo SNS",
    "sc_serif_18": "Não apenas PC, mas capaz de desenhar por smartphone",
    "sc_serif_19": "Deseja poder desenhar melhor…",
    "sc_serif_20": "Todas as ilustrações aqui são desenhadas por mim",
    "sc_serif_21": "Às vezes, eu desenho abstratamente",
    "sc_make_1": "Selecione um destes três",
    "sc_make_2": "O ilustrador AI SAI criará uma ilustração original. Selecione sua ilustração favorita e o AI criará uma ilustração com similaridade. Depois de decidir seu personagem favorito, faça o download",
    "sc_make_3": "Confirme",
    "sc_make_4": "Volte",
    "sc_make_5": "Histórico",
    "sc_make_6": "Aleatório",
    "sc_make_7": "Inicio",
    "sc_make_8": "Requer login",
    "sc_make_9": "Se você ainda não está registrado, crie uma nova conta. Após o login, você terá acesso a mais ilustrações",
    "sc_make_10": "Criar conta / login",
    "sc_make_11": "Requer login para baixar a ilustração. Se você não está registrado, crie uma nova conta",
    "sc_make_12": "Criar conta / login",
    "sc_make_13": "Você precisa entrar para ilustrações de ordem. Se você não tem a conta, por favor criar uma conta."
  },
  "de": {
    "sc_serif_1": "Wählen Sie einen Charakter mit Ihrem Geschmack",
    "sc_serif_2": "Ein Ihrer Auswahl ähnelndes Bild wird erstellt",
    "sc_serif_3": "Ich werde es schrittweise näher an das gewünschte Bild bringen",
    "sc_serif_4": "Das aktuell angezeigte Bild hat eine hohe Ähnlichkeit",
    "sc_serif_5": "Je näher an der Mitte, desto höher ist die Ähnlichkeit",
    "sc_serif_6": "Die Charaktere im äußeren Bereich haben einen etwas anderen Stil",
    "sc_serif_7": "Wählen Sie die Mitte aus, um ein Bild mit hoher Ähnlichkeit zu zeichnen",
    "sc_serif_8": "Ich studiere neben Gesichtsillustrationen auch noch andere Dinge",
    "sc_serif_9": "Das Wasserzeichen verschwindet nach dem herunterladen",
    "sc_serif_10": "Dreiecksmuster und Symbol sind Wasserzeichen",
    "sc_serif_11": "Willkürliche Zeichnung per Zufallstaste",
    "sc_serif_12": "Auf zurück klicken, um zum vorherigen Zustand zurückzukehren",
    "sc_serif_13": "In Sachen Geschwindigkeit macht mir niemand was vor",
    "sc_serif_14": "Zeichnen Sie über 1 Million Illustrationsvarianten",
    "sc_serif_15": "Bitte senden Sie mir beliebig viele Anfragen",
    "sc_serif_16": "Diese Illustration kann kommerziell genutzt werden",
    "sc_serif_17": "Ich würde mich freuen, wenn du mich auf soziale Medien teilen könntest",
    "sc_serif_18": "Zeichnen Sie nicht nur vom PC, sondern auch per Smartphone",
    "sc_serif_19": "Ich würde gerne noch besser zeichnen können...",
    "sc_serif_20": "Alle Illustrationen hier wurden von mir gezeichnet",
    "sc_serif_21": "Ich zeichne manchmal Originalbilder ...",
    "sc_make_1": "Wählen Sie zunächst eine der drei Optionen aus",
    "sc_make_2": "SAI, die Bilderstellungs-KI generiert eine originelle Illustration.  Ähnliche Abbildungen nach Ihrem Geschmack werden erstellt. Der gewünschte Charakter kann schließlich heruntergeladen werden.",
    "sc_make_3": "Bestätigen",
    "sc_make_4": "Zurück",
    "sc_make_5": "History-Menü",
    "sc_make_6": "Zufall",
    "sc_make_7": "Start",
    "sc_make_8": "Einloggen erforderlich",
    "sc_make_9": "Wenn Sie sich noch nicht registriert haben, loggen Sie sich bitte nach der Registrierung ein. Nach dem Einloggen können Sie weitere Illustrationen generieren.",
    "sc_make_10": "Neue Registrierung / Einloggen",
    "sc_make_11": "Sie müssen sich anmelden, um Bilder herunterzuladen. Registrieren Sie sich bitte, falls Sie noch kein Mitglied sind, ",
    "sc_make_12": "Neue Registrierung / Einloggen",
    "sc_make_13": "Sie müssen sich um Illustrationen anmelden. Wenn Sie noch kein Konto, erstellen Sie bitte Konto."
  },
  "nl": {
    "sc_serif_1": "Kies je favoriete personage.",
    "sc_serif_2": "Trekt een beeld vergelijkbaar met de geselecteerde teken",
    "sc_serif_3": "Geleidelijk dichter bij de afbeelding die u wilt",
    "sc_serif_4": "De getoonde foto's zijn erg op elkaar lijken.",
    "sc_serif_5": "Hoe dichter het beeld verschijnt bij het centrum, hoe meer vergelijkbaar is met de oorspronkelijke afbeelding.",
    "sc_serif_6": "De vier hoeken personages zijn een beetje anders in het ontwerp.",
    "sc_serif_7": "Selecteer de middelste afbeelding om soortgelijke foto's te vestigen.",
    "sc_serif_8": "Ik ben het bestuderen van verschillende aard van de illustraties.",
    "sc_serif_9": "Wanneer u de afbeelding te downloaden, zal het watermerk verdwijnen.",
    "sc_serif_10": "De driehoekspatroon en het pictogram zijn watermerken.",
    "sc_serif_11": "Teken een afbeelding willekeurig door te klikken op de knop Random",
    "sc_serif_12": "Druk op de Return-toets om terug te keren naar de vorige staten.",
    "sc_serif_13": "Niemand verslaat me bij het opstellen snelheid.",
    "sc_serif_14": "Ik trek meer dan 1.000.000 soorten illustraties.",
    "sc_serif_15": "Laat het me weten als u nog vragen voor service.",
    "sc_serif_16": "Deze illustratie kan commercieel worden gebruikt.",
    "sc_serif_17": "Aarzel niet om mijn tekeningen op SNS delen.",
    "sc_serif_18": "Ik werk niet alleen vanuit een pc, maar ook van een smartphone.",
    "sc_serif_19": "Ik wil leren om beter te trekken!",
    "sc_serif_20": "Ik trok hier al de illustraties.",
    "sc_serif_21": "Ik trekken soms grappige foto's ...",
    "sc_make_1": "In de eerste plaats kiezen uit drie beelden.",
    "sc_make_2": "SAI genereert originele illustraties. Als u een foto te selecteren, zal het een vergelijkbaar beeld te genereren. Wanneer u een teken dat u leuk vindt, aarzel dan niet om het te downloaden.",
    "sc_make_3": "kiezen",
    "sc_make_4": "Undo",
    "sc_make_5": "Geschiedenis",
    "sc_make_6": "Willekeurig",
    "sc_make_7": "Huis",
    "sc_make_8": "Log in alstublieft",
    "sc_make_9": "Als je nog geen account hebt te maken, meldt u zich na het registreren. U kunt meer illustraties te genereren na het inloggen.",
    "sc_make_10": "CREATE account / Inloggen",
    "sc_make_11": "Je moet inloggen om te downloaden illustraties. Als u nog niet goed zijn, maak account.",
    "sc_make_12": "CREATE account / Inloggen",
    "sc_make_13": "Je moet inloggen om te bestellen illustraties. Als u nog niet goed zijn, maak account."
  },
  "tr": {
    "sc_serif_1": "En sevdiğiniz karakteri seçin.",
    "sc_serif_2": "Seçilen karaktere benzeyen bir resim çizer",
    "sc_serif_3": "Yavaş yavaş daha yakın istediğiniz resimde olsun",
    "sc_serif_4": "Görüntülenen fotoğraflar birbirine çok benzer.",
    "sc_serif_5": "daha yakın görüntü merkezi, daha çok benzer orijinal gösterim için görüntülenir.",
    "sc_serif_6": "Dört köşe karakterleri tasarımında biraz farklıdır.",
    "sc_serif_7": "buna benzer resim çizmek için ortadaki resmi seçin.",
    "sc_serif_8": "Ben resimlerde çeşitli tip okuyorum.",
    "sc_serif_9": "Görüntüyü indirdiğinizde, filigran kaybolacaktır.",
    "sc_serif_10": "Üçgen desen ve simge filigran vardır.",
    "sc_serif_11": "Rastgele düğmesini tıklayarak rastgele bir görüntü çizmek",
    "sc_serif_12": "Basın dönüş düğmesine önceki durumlarına geri dönmek için.",
    "sc_serif_13": "Hiç kimse hızını çizim beni yener.",
    "sc_serif_14": "Ben resimlerin 1 milyondan fazla çeşit çizin.",
    "sc_serif_15": "Hizmet için bir talebiniz varsa bana bildirin.",
    "sc_serif_16": "Bu resim, ticari olarak kullanılabilir.",
    "sc_serif_17": "SNS benim çizimleri paylaşmak için çekinmeyin.",
    "sc_serif_18": "Bir PC'den değil, aynı zamanda bir akıllı telefondan sadece çalışır.",
    "sc_serif_19": "Daha iyi çizmek öğrenmek istiyorum!",
    "sc_serif_20": "Buraya her illüstrasyonlar çizdi.",
    "sc_serif_21": "Bazen komik resimler çizin ...",
    "sc_make_1": "İlk olarak, üç görüntüleri arasından seçim.",
    "sc_make_2": "SAI orijinal resimler üretir. İstediğiniz bir resmi seçerseniz, benzer bir resim oluşturur. Beğendiğiniz bir karakter bulduğunuzda, lütfen onu indirmekten çekinmeyin.",
    "sc_make_3": "seçmek",
    "sc_make_4": "Geri alma",
    "sc_make_5": "Tarih",
    "sc_make_6": "rasgele",
    "sc_make_7": "Ev",
    "sc_make_8": "Lütfen giriş yapın",
    "sc_make_9": "Henüz hesap oluşturmak yoksa, sonra oturum hesap oluşturun. İçeri işaretinden sonraki daha illüstrasyonlar üretebilir.",
    "sc_make_10": "HESAP / SIGN IN CREATE",
    "sc_make_11": "Sen indirme resimlerde oturum gerekir. Eğer hesap yoksa, bir hesap oluşturun.",
    "sc_make_12": "HESAP / SIGN IN CREATE",
    "sc_make_13": "Sen emir resimlerde oturum gerekir. Eğer hesap yoksa, bir hesap oluşturun."
  },
  "zh-cn": {
    "sc_serif_1": "请选择您喜欢的人物风格",
    "sc_serif_2": "我会绘制与您选择的风格相似的头像",
    "sc_serif_3": "我会创造越来越接近您理想中的插图",
    "sc_serif_4": "现在出现的的图片相似度已经很高了",
    "sc_serif_5": "越靠近中心相似度越高",
    "sc_serif_6": "角落的插图，我绘制了一些不同类型的风格",
    "sc_serif_7": "选中央的头像我就会绘制相似度高的插图",
    "sc_serif_8": "头像以外我也在学习",
    "sc_serif_9": "下载的话透明背景会失效",
    "sc_serif_10": "三角形与头像是透明背景",
    "sc_serif_11": "随机键可以随机绘制",
    "sc_serif_12": "返回键可以回去上一个动作 ",
    "sc_serif_13": "速度不会输给任何人",
    "sc_serif_14": "我可以绘制１００种以上的插图",
    "sc_serif_15": "关于服务有任何指教请多多反馈给我",
    "sc_serif_16": "可以使用在商业活动",
    "sc_serif_17": "如果分享在社交网络平台我会很高兴的",
    "sc_serif_18": "不只用电脑，也可以用智能手机绘制",
    "sc_serif_19": "想要画的越来越好···",
    "sc_serif_20": "这裡所有的图都是我画的",
    "sc_serif_21": "我有时候会创作一些独特的风格···",
    "sc_make_1": "请先从这３种中选择",
    "sc_make_2": "创造插图ＡＩ的ＳＡＩ会创作独一无二的插图。\n您只要选择喜欢的人物风格，ＳＡＩ就会帮您创造相似的插图。然后您只需要选择您想要的头像下载即可。",
    "sc_make_3": "确认",
    "sc_make_4": "返回",
    "sc_make_5": "履历",
    "sc_make_6": "随机",
    "sc_make_7": "ＳＡＩ的主页",
    "sc_make_8": "需要登入",
    "sc_make_9": "如果您还不是会员请注册帐号以后登入。登入以后可以绘制更多的插图",
    "sc_make_10": "注册帐号／登入",
    "sc_make_11": "下载插图需要登入，如果您还不是会员请注册帐号。",
    "sc_make_12": "注册帐号／登入",
    "sc_make_13": "您需要登录到顺序的说明。如果你还没有帐户，请创建账户。"
  },
  "zh-tw": {
    "sc_serif_1": "請選擇您喜歡的人物風格",
    "sc_serif_2": "我會繪製與您選擇的風格相似的頭像",
    "sc_serif_3": "我會創造越來越接近您理想中的插圖",
    "sc_serif_4": "現在出現的的圖片相似度已經很高了",
    "sc_serif_5": "越靠近中心相似度越高",
    "sc_serif_6": "角落的插圖，我繪製了一些不同類型的風格",
    "sc_serif_7": "選中央的頭像我就會繪製相似度高的插圖",
    "sc_serif_8": "頭像以外我也在學習",
    "sc_serif_9": "下載的話透明背景會失效",
    "sc_serif_10": "三角形跟頭像是透明背景",
    "sc_serif_11": "隨機按鍵可以隨機繪製",
    "sc_serif_12": "返回按鍵可以回去上一個動作",
    "sc_serif_13": "速度不會輸給任何人",
    "sc_serif_14": "我可以繪製１００種以上的插圖",
    "sc_serif_15": "關於服務有任何指教請多多反饋給我",
    "sc_serif_16": "可以使用在商業活動",
    "sc_serif_17": "如果分享在社交網絡平台我會很高興的",
    "sc_serif_18": "不只用電腦，也可以用智能手機繪製",
    "sc_serif_19": "想要畫的越來越好···",
    "sc_serif_20": "這裡所有的圖都是我畫的",
    "sc_serif_21": "我有時候會創作一些獨特的風格···",
    "sc_make_1": "請先從這３種中選擇",
    "sc_make_2": "創造插圖ＡＩ的ＳＡＩ會創作獨一無二的插圖。\n您只要選擇喜歡的人物風格，ＳＡＩ就會幫您創造相似的插圖。然後您只需要選擇您想要的頭像下載即可。",
    "sc_make_3": "確認",
    "sc_make_4": "返回",
    "sc_make_5": "履歷",
    "sc_make_6": "隨機",
    "sc_make_7": "ＳＡＩ的主頁",
    "sc_make_8": "需要登入",
    "sc_make_9": "如果您還不是會員請註冊帳號以後登入。登入以後可以繪製更多的插圖",
    "sc_make_10": "註冊帳號／登入",
    "sc_make_11": "下載插圖需要登入，如果您還不是會員請註冊帳號。",
    "sc_make_12": "註冊帳號／登入",
    "sc_make_13": "您需要登錄到順序的說明。如果你還沒有帳戶，請創建賬戶。"
  },
  "sv": {
    "sc_serif_1": "Välj din favorit karaktär.",
    "sc_serif_2": "Ritar en bild som liknar det valda tecknet",
    "sc_serif_3": "Så småningom komma närmare bilden du vill",
    "sc_serif_4": "Bilderna som visas är mycket lika varandra.",
    "sc_serif_5": "Ju närmare bilden visas till centrum, desto mer liknar det den ursprungliga bilden.",
    "sc_serif_6": "De fyra hörn tecken är lite annorlunda i design.",
    "sc_serif_7": "Välj mitt bilden för att dra liknande bilder till det.",
    "sc_serif_8": "Jag studerar olika typer av illustrationer.",
    "sc_serif_9": "När du laddar ner bilden, kommer vattenstämpeln att försvinna.",
    "sc_serif_10": "Den triangelmönster och ikon är vattenstämplar.",
    "sc_serif_11": "Rita en bild på måfå genom att klicka på Random knappen",
    "sc_serif_12": "Tryck på retur för att återgå till tidigare tillstånd.",
    "sc_serif_13": "Ingen slår mig att dra hastighet.",
    "sc_serif_14": "Jag drar mer än 1 miljon olika typer av illustrationer.",
    "sc_serif_15": "Hör av dig om du har några önskemål om service.",
    "sc_serif_16": "Denna illustration kan användas kommersiellt.",
    "sc_serif_17": "Tveka inte att dela mina teckningar på SNS.",
    "sc_serif_18": "Jag arbetar inte bara från en dator utan även från en smartphone.",
    "sc_serif_19": "Jag vill lära mig att dra bättre!",
    "sc_serif_20": "Jag drog alla illustrationerna här.",
    "sc_serif_21": "Jag drar ibland roliga bilder ...",
    "sc_make_1": "Först väljer bland tre bilder.",
    "sc_make_2": "SAI genererar originalillustrationer. Om du väljer en bild som du vill ha, kommer den att generera en liknande bild. När du hittar en karaktär som du vill, är du välkommen att ladda ner den.",
    "sc_make_3": "Välj",
    "sc_make_4": "Ångra",
    "sc_make_5": "Historia",
    "sc_make_6": "Slumpmässig",
    "sc_make_7": "Hem",
    "sc_make_8": "Var god logga in",
    "sc_make_9": "Om du inte har skapa konto än loggar in efter att skapa konto. Du kan generera fler illustrationer efter logga in.",
    "sc_make_10": "SKAPA Konto / Logga in",
    "sc_make_11": "Du måste logga in för att ladda ner bilderna. Om du inte har konto kan du skapa konto.",
    "sc_make_12": "SKAPA Konto / Logga in",
    "sc_make_13": "Du måste logga in för att beställa illustrationer. Om du inte har konto kan du skapa konto."
  },
  "it": {
    "sc_serif_1": "Seleziona un personaggio con il tuo stile preferito",
    "sc_serif_2": "Disegno un'immagine simile al personaggio selezionato",
    "sc_serif_3": "Mi sto avvicinando all'immagine desiderata",
    "sc_serif_4": "L'immagine che ti sto indicando adesso è abbastanza simile",
    "sc_serif_5": "Più vicina alla principale, è l'illustrazione con una maggiore somiglianza",
    "sc_serif_6": "Cambierò un po' lo stile dei personaggi che stanno ai quattro angoli",
    "sc_serif_7": "Se selezioni il centro, disegno un'immagine ad alto grado di somiglianza",
    "sc_serif_8": "Sto studiando anche altre cose oltre all'illustrazione del viso",
    "sc_serif_9": "Se fai il download, la filigrana sparisce",
    "sc_serif_10": "La figura triangolare e l'icona sono le filigrane",
    "sc_serif_11": "Disegna in modo casuale con il tasto casuale",
    "sc_serif_12": "Tasto di ritorno per tornare allo stato precedente",
    "sc_serif_13": "Ho fiducia in me stessa nella mia velocità, non perdo contro nessuno",
    "sc_serif_14": "Posso disegnare più di 1 milione di tipi d'illustrazioni",
    "sc_serif_15": "Si prega di inviare sempre più richieste di servizio",
    "sc_serif_16": "Sono illustrazioni ad uso commerciale",
    "sc_serif_17": "Sono contenta se condividete sui social network...",
    "sc_serif_18": "Disegno non solo dal PC ma anche dallo smartphone",
    "sc_serif_19": "Vorrei diventare più brava a disegnare...",
    "sc_serif_20": "Ho disegnato tutte queste illustrazioni",
    "sc_serif_21": "Io a volte disegno immagini creative...",
    "sc_make_1": "All'inizio, seleziona tra 3 illustrazioni",
    "sc_make_2": "AI crea illustrazione SAI crea un'icona originale. Scegli lo stile che desideri, e creerà un'illustrazione simile a quella scelta. Se hai deciso un personaggio, fai il download.",
    "sc_make_3": "Conferma",
    "sc_make_4": "Torna indietro",
    "sc_make_5": "Storia personale ",
    "sc_make_6": "Casuale",
    "sc_make_7": "Home",
    "sc_make_8": "È necessario effettuare il login",
    "sc_make_9": "Se ancora non sei registrato, ti preghiamo di effettuare il login dopo l'iscrizione. Se effetui il login, puoi creare più illustrazioni.",
    "sc_make_10": "Registrazione nuovo utente / Login",
    "sc_make_11": "Per fare il download dell'illustrazione, è neccessario effettuare il login. Chi non è ancora registrato, si prega di fare l'iscrizione nuovo utente.",
    "sc_make_12": "Registrazione nuovo utente / Login",
    "sc_make_13": "È necessario accedere alle illustrazioni di ordine. Se non avete conto, si prega di creare un account."
  },
  "ko": {
    "sc_serif_1": "선호하는 스타일의 캐릭터를 선택해 주세요",
    "sc_serif_2": "선택한 캐릭터에 가까운 그림을 그려보겠습니다.",
    "sc_serif_3": "원하는 그림에 조금씩 더 가깝게 접근해 가겠습니다.",
    "sc_serif_4": "지금 표시중의 그림은 매우 유사합니다",
    "sc_serif_5": "중심에 가까울수록 유사도가 높은 일러스트입니다.",
    "sc_serif_6": "네 모퉁이의 캐릭터는 조금 스타일을 변경하였습니다",
    "sc_serif_7": "중앙을 선택하면 유사도가 높은 그림을 그립니다",
    "sc_serif_8": "얼굴 일러스트 이외도 다양하게 학습중입니다",
    "sc_serif_9": "다운로드를 하면 투명무늬(워터마크)가 없어집니다",
    "sc_serif_10": "삼각형의 무늬와 아이콘은 워터마크입니다.",
    "sc_serif_11": "랜덤 버튼으로 랜덤으로 그립니다",
    "sc_serif_12": "돌아가기 버튼으로 전 단계로 돌아갑니다",
    "sc_serif_13": "스피드로는 누구에게도 지지 않을 자신이 있습니다",
    "sc_serif_14": "100만 종류 이상의 일러스트를 그릴 수 있습니다",
    "sc_serif_15": "서비스의 요망은 언제든 환영합니다",
    "sc_serif_16": "상업용으로 이용할 수 있는 일러스트입니다",
    "sc_serif_17": "SNS등으로 공유 해 주시면 감사하겠습니다",
    "sc_serif_18": "PC 뿐만이 아니라 스마트폰으로도 그립니다",
    "sc_serif_19": "좀 더 그림이 능숙하게 그리고 싶다…",
    "sc_serif_20": "여기의 일러스트는 전부 제가 그렸습니다",
    "sc_serif_21": "저, 가끔 독창적인 그림을 그립니다…",
    "sc_make_1": "먼저 3개중에서 선택해 주세요",
    "sc_make_2": "일러스트 생성 AI SAI가 오리지널의 일러스트를 생성합니다. 자신이 원하는 스타일을 선택하면 그와 유사한 일러스트를 생성해 줍니다. 원하는 캐릭터가 정해지면 다운로드해 주세요.",
    "sc_make_3": "확정",
    "sc_make_4": "돌아가기",
    "sc_make_5": "이력",
    "sc_make_6": "랜덤",
    "sc_make_7": "홈",
    "sc_make_8": "로그인이 필요합니다",
    "sc_make_9": "회원 등록이 아직이신 분은 신규 회원 등록을 하고 나서 로그인해 주세요. 로그인을 하면 좀 더 많은 일러스트를 생성할 수 있게 됩니다.",
    "sc_make_10": "신규 회원 등록 / 로그인",
    "sc_make_11": "일러스트를 다운로드하려면  로그인이 필요합니다. 회원 등록이 아직이신 분은 신규 회원 등록을 해 주세요.",
    "sc_make_12": "신규 회원 등록 / 로그인",
    "sc_make_13": "당신은 주문 그림에 로그인해야합니다. 당신이 고려하지 않은 경우, 계정을 만드십시오."
  },
  "ar": {
    "sc_serif_1": "الرجاء اختيار شخصيتك المفضلة لديك.",
    "sc_serif_2": "يرسم صورة مماثلة إلى الحرف المحدد",
    "sc_serif_3": "تدريجيا الاقتراب من التوضيح تريد",
    "sc_serif_4": "الصور المعروضة هي مشابهة جدا لبعضها البعض.",
    "sc_serif_5": "ويبدو أن أقرب الصورة إلى المركز، وأكثر ما شابه ذلك هو التوضيح الأصلي.",
    "sc_serif_6": "الأحرف الزاوية الأربعة هي مختلفة قليلا في التصميم.",
    "sc_serif_7": "حدد الصورة المركز لرسم الصور المشابهة له.",
    "sc_serif_8": "أنا أدرس نوع مختلف من الرسوم التوضيحية.",
    "sc_serif_9": "عند تحميل الصورة، والعلامة المائية تختفي.",
    "sc_serif_10": "نمط المثلث ورمز والعلامات المائية.",
    "sc_serif_11": "رسم صورة عشوائيا عن طريق النقر على زر عشوائية",
    "sc_serif_12": "اضغط على زر عودة للعودة إلى الدول السابقة.",
    "sc_serif_13": "لا أحد يضربني في رسم السرعة.",
    "sc_serif_14": "أود أن ألفت أكثر من 1 مليون أنواع من الرسوم التوضيحية.",
    "sc_serif_15": "واسمحوا لي أن أعرف إذا كان لديك أي طلبات للحصول على الخدمة.",
    "sc_serif_16": "هذا التوضيح يمكن استخدامها تجاريا.",
    "sc_serif_17": "لا تتردد في مشاركة رسوماتي على SNS.",
    "sc_serif_18": "أنا أعمل ليس فقط من جهاز كمبيوتر ولكن أيضا من الهاتف الذكي.",
    "sc_serif_19": "أريد أن أتعلم رسم أفضل!",
    "sc_serif_20": "وجهت جميع الرسوم التوضيحية هنا.",
    "sc_serif_21": "أنا في بعض الأحيان رسم صور مضحكة ...",
    "sc_make_1": "أولا، اختر من بين ثلاث صور.",
    "sc_make_2": "SAI يولد الرسوم التوضيحية الأصلية. إذا قمت بتحديد الصورة التي تريدها، فإنه سيتم إنشاء صورة مماثلة. عندما تجد الحرف الذي تريد، لا تتردد في تحميل البرنامج.",
    "sc_make_3": "تحديد",
    "sc_make_4": "الغاء التحميل",
    "sc_make_5": "التاريخ",
    "sc_make_6": "عشوائية",
    "sc_make_7": "الصفحة الرئيسية",
    "sc_make_8": "الرجاء تسجيل الدخول",
    "sc_make_9": "إذا لم تكن قد إنشاء حساب بعد، يرجى تسجيل الدخول بعد إنشاء حساب. يمكنك توليد المزيد من الرسوم التوضيحية بعد تسجيل الدخول.",
    "sc_make_10": "CREATE ACCOUNT / تسجيل الدخول",
    "sc_make_11": "تحتاج إلى تسجيل الدخول لتحميل الرسوم التوضيحية. إذا لم تكن قد حساب، يرجى إنشاء حساب.",
    "sc_make_12": "CREATE ACCOUNT / تسجيل الدخول",
    "sc_make_13": "تحتاج لتسجيل الدخول إلى الرسوم التوضيحية النظام. إذا لم تكن قد حساب، يرجى إنشاء حساب."
  },
  "th": {
    "sc_serif_1": "เลือกตัวละครที่เป็นสไตล์คุณ",
    "sc_serif_2": "วาดรูปที่ใกล้เคียงกับตัวละครที่เลือก",
    "sc_serif_3": "เริ่มเข้าใกล้ภาพที่ต้องการแล้ว",
    "sc_serif_4": "ภาพที่แสดงนี้มีลักษณะคล้ายกันมาก",
    "sc_serif_5": "รูปยิ่งเข้าใกล้จัดศูนย์กลางยิ่งมีความคล้ายกันมากยิ่งขึ้น",
    "sc_serif_6": "ตัวละครในมุมทั้ง 4 เพื่อให้รู้สึกถึงความแปลกใหม่",
    "sc_serif_7": "เลือกกึ่งกลางเพื่อให้รูปวาดมีความคล้ายคลึงมากขึ้น",
    "sc_serif_8": "อยู่ระหว่างกำลังศึกษารูปแบบอื่นนอกจากรูปวาดใบหน้า",
    "sc_serif_9": "ลายน้ำจะหายไปหลังจากทำการดาวน์โหลด",
    "sc_serif_10": "ลวดลายรูปสามเหลี่ยมกับไอคอนคือลายน้ำ",
    "sc_serif_11": "วาดรูปแบบสุ่มด้วยปุ่มสุ่ม",
    "sc_serif_12": "กดปุ่มย้อนกลับเพื่อกลับสู่สถานะก่อนหน้า",
    "sc_serif_13": "มีความมั่นใจในเรื่องความเร็วที่ไม่แพ้ใคร",
    "sc_serif_14": "วาดรูปได้มากกว่า 1 ล้านรูป",
    "sc_serif_15": "กรุณาส่งคำขอรับบริการเข้ามากันเยอะๆนะ",
    "sc_serif_16": "นี่เป็นภาพที่สามารถใช้ในเชิงพาณิชย์ได้",
    "sc_serif_17": "จะดีใจมากถ้าจะช่วยแชร์กันใน SNS",
    "sc_serif_18": "ไม่ใช่แค่วาดจากPC เท่านั้น จากสมาร์ทโฟนก็ทำได้",
    "sc_serif_19": "อยากวาดรูปให้เก่งยิ่งขึ้น",
    "sc_serif_20": "ภาพวาดทั้งหมดนี้ฉันวาดขึ้นมาเอง",
    "sc_serif_21": "บางครั้ง ฉันก็วาดรูปที่เป็นแบบฉบับของตัวเอง",
    "sc_make_1": "เลือกมาจาก 3 ตัวเลือกก่อนนะ",
    "sc_make_2": " AI สร้างรูปวาดSAI จะช่วยวาดรูปในแบบฉบับเฉพาะตัว เลือกสไตล์ในแบบที่คุณต้องการ แล้วสร้างรูปวาดขึ้นมาเป็นของตัวเอง เมื่อเลือกตัวละครที่ต้องการแล้ว ก็ทำการดาวน์โหลด",
    "sc_make_3": "ยืนยัน",
    "sc_make_4": "ย้อนกลับ",
    "sc_make_5": "ประวัติ",
    "sc_make_6": "สุ่ม",
    "sc_make_7": "หน้าหลัก",
    "sc_make_8": "ต้องเข้าสู่ระบบ",
    "sc_make_9": "หากยังไม่ได้ทำการลงทะเบียน กรุณาลงทะเบียนสมาชิกใหม่แล้วจึงเข้าสู่ระบบ เมื่อเข้าสู่ระบบแล้ว สามารถสร้างรูปวาดได้เพิ่มขึ้นอีกมากมาย",
    "sc_make_10": "ลงทะเบียนสมาชิกใหม่ / เข้าสู่ระบบ",
    "sc_make_11": "ต้องเข้าสู่ระบบก่อนทำการดาวน์โหลด หากยังไม่ได้ทำการลงทะเบียน กรุณาลงทะเบียนสมาชิกใหม่",
    "sc_make_12": "ลงทะเบียนสมาชิกใหม่ / เข้าสู่ระบบ",
    "sc_make_13": "คุณจำเป็นต้องลงชื่อเข้าใช้ภาพประกอบการสั่งซื้อ หากคุณไม่ได้ใช้บัญชีโปรดสร้างบัญชี"
  },
  "id": {
    "sc_serif_1": "Silahkan pilih karakter favorit Anda.",
    "sc_serif_2": "Menggambar sebuah gambar mirip dengan karakter yang dipilih",
    "sc_serif_3": "Secara bertahap mendapatkan lebih dekat dengan ilustrasi yang Anda inginkan",
    "sc_serif_4": "Foto-foto yang ditampilkan sangat mirip satu sama lain.",
    "sc_serif_5": "Semakin dekat gambar muncul ke pusat, yang lebih mirip itu adalah untuk ilustrasi asli.",
    "sc_serif_6": "Empat karakter sudut yang sedikit berbeda dalam desain.",
    "sc_serif_7": "Pilih gambar pusat untuk menarik gambar yang mirip dengan itu.",
    "sc_serif_8": "Saya mempelajari berbagai jenis ilustrasi.",
    "sc_serif_9": "Ketika Anda men-download gambar, watermark akan hilang.",
    "sc_serif_10": "Pola segitiga dan ikon yang watermark.",
    "sc_serif_11": "Menggambar gambar secara acak dengan mengklik tombol Acak",
    "sc_serif_12": "Tekan tombol kembali untuk kembali ke negara-negara sebelumnya.",
    "sc_serif_13": "tidak ada yang mengalahkan saya dalam menggambar kecepatan.",
    "sc_serif_14": "Aku menarik lebih dari 1 juta jenis ilustrasi.",
    "sc_serif_15": "Tolong beritahu saya jika Anda memiliki permintaan untuk layanan.",
    "sc_serif_16": "Ilustrasi ini dapat digunakan secara komersial.",
    "sc_serif_17": "Silahkan untuk berbagi gambar saya di SNS.",
    "sc_serif_18": "Saya bekerja tidak hanya dari PC tetapi juga dari smartphone.",
    "sc_serif_19": "Saya ingin belajar menggambar lebih baik!",
    "sc_serif_20": "Aku menarik semua ilustrasi di sini.",
    "sc_serif_21": "Aku kadang-kadang menarik gambar lucu ...",
    "sc_make_1": "Pertama, memilih dari antara tiga gambar.",
    "sc_make_2": "SAI menghasilkan ilustrasi asli. Jika Anda memilih gambar yang Anda inginkan, maka akan menghasilkan gambar yang sama. Bila Anda menemukan karakter yang Anda suka, jangan ragu untuk mendownloadnya.",
    "sc_make_3": "Pilih",
    "sc_make_4": "Undo",
    "sc_make_5": "Sejarah",
    "sc_make_6": "Acak",
    "sc_make_7": "Rumah",
    "sc_make_8": "Harap masuk",
    "sc_make_9": "Jika Anda belum membuat akun, silakan masuk setelah membuat akun. Anda dapat menghasilkan lebih banyak ilustrasi setelah masuk.",
    "sc_make_10": "BUAT AKUN / MASUK",
    "sc_make_11": "Anda harus masuk untuk men-download ilustrasi. Jika Anda belum memiliki akun, silakan buat akun.",
    "sc_make_12": "BUAT AKUN / MASUK",
    "sc_make_13": "Anda harus masuk untuk memesan ilustrasi. Jika Anda belum memiliki akun, silakan buat akun."
  },
  "pl": {
    "sc_serif_1": "Wybierz swoją ulubioną postać.",
    "sc_serif_2": "Rysuje obraz podobny do wybranego znaku",
    "sc_serif_3": "Stopniowo zbliżyć się do rysunku, który chcesz",
    "sc_serif_4": "Zdjęcia wyświetlane są bardzo podobne do siebie.",
    "sc_serif_5": "Im bliżej obraz pojawia się na środku, to jest bardziej podobny do oryginalnego rysunku.",
    "sc_serif_6": "Cztery postacie narożne są nieco inne w projektowaniu.",
    "sc_serif_7": "Wybierz centrum obrazu wyciągnąć podobne zdjęcia do niego.",
    "sc_serif_8": "Uczę różnego rodzaju ilustracjami.",
    "sc_serif_9": "Po pobraniu obrazu, znak wodny zniknie.",
    "sc_serif_10": "Wzór trójkąta i ikony są znaki wodne.",
    "sc_serif_11": "Narysuj obraz losowo klikając przycisk Losowa",
    "sc_serif_12": "Naciśnij przycisk RETURN, aby powrócić do poprzedniego stanu.",
    "sc_serif_13": "Nikt nie bije mnie szybkość rysowania.",
    "sc_serif_14": "Rysuję ponad 1 mln rodzaju ilustracjami.",
    "sc_serif_15": "Proszę dać mi znać, jeśli masz jakiekolwiek żądania usługi.",
    "sc_serif_16": "Ta ilustracja może być stosowany w handlu.",
    "sc_serif_17": "Zapraszamy do dzielenia się moje rysunki na SNS.",
    "sc_serif_18": "Pracuję nie tylko z komputera, ale także z smartphone.",
    "sc_serif_19": "Chcę nauczyć się rysować lepiej!",
    "sc_serif_20": "Narysowałem wszystkie ilustracje tutaj.",
    "sc_serif_21": "I czasami narysować śmieszne zdjęcia ...",
    "sc_make_1": "Po pierwsze, należy wybrać spośród trzech obrazów.",
    "sc_make_2": "SAI generuje oryginalne ilustracje. Jeśli wybierzesz zdjęcie, które chcesz, to będzie wygenerować podobny obraz. Po znalezieniu charakter chcesz, prosimy, aby go pobrać.",
    "sc_make_3": "Wybierz",
    "sc_make_4": "Cofnij",
    "sc_make_5": "Historia",
    "sc_make_6": "Losowy",
    "sc_make_7": "Dom",
    "sc_make_8": "proszę, zaloguj się",
    "sc_make_9": "Jeśli nie masz jeszcze konta tworzyć, zaloguj się po utworzyć konto. Można wygenerować kolejne ilustracje po logowania.",
    "sc_make_10": "Załóż konto / Zaloguj się",
    "sc_make_11": "Musisz się zalogować, aby pobrać ilustracjami. Jeśli nie masz konta, należy utworzyć konto.",
    "sc_make_12": "Załóż konto / Zaloguj się",
    "sc_make_13": "Musisz się zalogować, aby ilustracjami rzędu. Jeśli nie masz konta, należy utworzyć konto."
  },
  "vi": {
    "sc_serif_1": "Vui lòng chọn nhân vật yêu thích của bạn.",
    "sc_serif_2": "Vẽ một bức tranh tương tự với nhân vật được lựa chọn",
    "sc_serif_3": "Dần dần có được gần gũi hơn với hình minh họa bạn muốn",
    "sc_serif_4": "Những bức ảnh hiển thị rất giống với nhau.",
    "sc_serif_5": "Các địa điểm gần hình ảnh dường như trung tâm, tương tự hơn là để minh họa gốc.",
    "sc_serif_6": "Bốn nhân vật góc là một khác nhau chút trong thiết kế.",
    "sc_serif_7": "Chọn hình ảnh trung tâm để vẽ hình ảnh tương tự với nó.",
    "sc_serif_8": "Tôi đang nghiên cứu loại hình minh họa.",
    "sc_serif_9": "Khi bạn tải về các hình ảnh, hình mờ sẽ biến mất.",
    "sc_serif_10": "Các mô hình tam giác và biểu tượng là hình mờ.",
    "sc_serif_11": "Vẽ một hình ảnh một cách ngẫu nhiên bằng cách nhấn vào nút ngẫu nhiên",
    "sc_serif_12": "Nhấn nút quay trở lại trở lại tình trạng trước đó.",
    "sc_serif_13": "Không ai đánh bại tôi trong tốc độ vẽ.",
    "sc_serif_14": "Tôi rút ra hơn 1 triệu loại hình minh họa.",
    "sc_serif_15": "Xin vui lòng cho tôi biết nếu bạn có bất kỳ yêu cầu dịch vụ.",
    "sc_serif_16": "minh hoạ này có thể được sử dụng mục đích thương mại.",
    "sc_serif_17": "Xin vui lòng chia sẻ bản vẽ của tôi trên SNS.",
    "sc_serif_18": "Tôi làm việc không chỉ từ một máy tính mà còn từ một điện thoại thông minh.",
    "sc_serif_19": "Tôi muốn học để vẽ tốt hơn!",
    "sc_serif_20": "Tôi đã thu hút tất cả các hình ảnh minh họa ở đây.",
    "sc_serif_21": "đôi khi tôi vẽ hình ảnh hài hước ...",
    "sc_make_1": "Đầu tiên, hãy chọn một trong ba hình ảnh.",
    "sc_make_2": "SAI tạo minh họa gốc. Nếu bạn chọn một hình ảnh mà bạn muốn, nó sẽ tạo ra một hình ảnh tương tự. Khi bạn tìm thấy một nhân vật mà bạn thích, xin vui lòng miễn phí để tải nó.",
    "sc_make_3": "Lựa chọn",
    "sc_make_4": "Hoàn tác",
    "sc_make_5": "Lịch sử",
    "sc_make_6": "ngẫu nhiên",
    "sc_make_7": "Trang Chủ",
    "sc_make_8": "làm ơn đăng nhập",
    "sc_make_9": "Nếu bạn chưa tạo tài khoản, xin vui lòng đăng nhập sau khi tạo tài khoản. Bạn có thể tạo ra nhiều hình ảnh minh họa sau khi đăng nhập.",
    "sc_make_10": "TẠO TÀI KHOẢN / ĐĂNG NHẬP",
    "sc_make_11": "Bạn cần phải đăng nhập để minh họa download. Nếu bạn chưa hạch toán, hãy tạo tài khoản.",
    "sc_make_12": "TẠO TÀI KHOẢN / ĐĂNG NHẬP",
    "sc_make_13": "Bạn cần phải đăng nhập để minh họa trật tự. Nếu bạn chưa hạch toán, hãy tạo tài khoản."
  },
  "hi": {
    "sc_serif_1": "Krpaya pasandeeda svaad ke prachaarak ka choose karen.",
    "sc_serif_2": "Aapake selection ke samaan illustration banaungi.",
    "sc_serif_3": "Ise aapake vaanchhit illustration ke kareeb kar dungi.",
    "sc_serif_4": "Vartamaan dikha picture bahut samaan hai.",
    "sc_serif_5": "Beech kee pictures bahut adhik samaan hain.",
    "sc_serif_6": "4 koins par varn thode alag svaad ke saath hain.",
    "sc_serif_7": "Yadi aap kendr chunate hain, to main uchch samaanata ke saath picture banaungi.",
    "sc_serif_8": "Face illustration ke alaava any cheejon ka adhyayan kar rahi hum.",
    "sc_serif_9": "Picture download karane ke baad, transparency vighatit ho jaegee.",
    "sc_serif_10": "Trikon paitarn aur aaikan paaradarshee hai.",
    "sc_serif_11": "Betarateeb dhang se yaadrchchhik batan ke saath aakarshit karegi.",
    "sc_serif_12": "Back batan ke saath ek baar ja sakate hain.",
    "sc_serif_13": "Kisee ek se tej hum.",
    "sc_serif_14": "Miliyan se adhik prakaar ke chitran kar sakati hum.",
    "sc_serif_15": "Kisee bhee service ke lie nivedan karane ke lie svatantr mahasoos karen.",
    "sc_serif_16": "Commercial purpose ke lie is drshtaant ka use kar sakate hain.",
    "sc_serif_17": "Yadi aap SNS par saajha karate hain to mujhe khushee hogee.",
    "sc_serif_18": "Na keval PC se balki smartphone se bhee aakarshit karungi.",
    "sc_serif_19": "Main behatar illustration banana chaahata hum...",
    "sc_serif_20": "Ye sabhee illustration mere dvaara kheenche gae hain.",
    "sc_serif_21": "Main kabhee kabhee adviteey chitran karata hum...",
    "sc_make_1": "Krpaya in 3 se chunen",
    "sc_make_2": "illustration generator AI SAI adviteey illustration utpann karega. Yadi aap apane vaanchhit svaad ka choose karate hain, to vah samaan illustration utpann karegi. Yadi aap jo chaahate hain, us par nirnay lete hain, to krpaya ise download karen.",
    "sc_make_3": "Confirm",
    "sc_make_4": "Back",
    "sc_make_5": "History",
    "sc_make_6": "Random",
    "sc_make_7": "Home",
    "sc_make_8": "Login aavashyak.",
    "sc_make_9": "Yadi aapane registration nahin kiya hai, to krpaya apana registration karen aur login karen. Yadi aap login karate hain, to aap adhik illustration utpann kar sakate hain.",
    "sc_make_10": "New registration / Log in",
    "sc_make_11": "Aapako illustration download karane ke lie login karana hoga. Yadi aapane registration nahin kiya hai, to krpaya apana registration karen",
    "sc_make_12": "New registration / Log in",
    "sc_make_13": "आपको चित्र में प्रवेश करने की जरूरत है। आप खाता नहीं है, तो खाता बनाएँ।"
  },
  "ru": {
    "sc_serif_1": "Пожалуйста, выберите ваш любимый персонаж.",
    "sc_serif_2": "Рисует картину, сходную с выбранным символом",
    "sc_serif_3": "Постепенно приближаемся к иллюстрации вы хотите",
    "sc_serif_4": "Фотографии являются очень похожи друг на друга.",
    "sc_serif_5": "Чем ближе изображение появляется к центру, тем больше похож он на оригинальную иллюстрацию.",
    "sc_serif_6": "Четыре угловых символы немного отличаются по дизайну.",
    "sc_serif_7": "Выберите центр изображения, чтобы сделать подобные снимки на него.",
    "sc_serif_8": "Я изучаю различные типы иллюстраций.",
    "sc_serif_9": "При загрузке изображения, водяной знак исчезнет.",
    "sc_serif_10": "Узор треугольника и значок водяные знаки.",
    "sc_serif_11": "Нарисуйте изображение в случайном порядке, нажав кнопку Random",
    "sc_serif_12": "Нажмите кнопку возврата для возврата в предыдущее состояние.",
    "sc_serif_13": "Никто не бьет меня скорость отрисовки.",
    "sc_serif_14": "Я рисую более 1 миллиона видов иллюстраций.",
    "sc_serif_15": "Пожалуйста, дайте мне знать, если у вас есть какие-либо запросы на обслуживание.",
    "sc_serif_16": "Эта иллюстрация может быть использована в коммерческих целях.",
    "sc_serif_17": "Пожалуйста, не стесняйтесь поделиться своими рисунками на SNS.",
    "sc_serif_18": "Я работаю не только с компьютера, но и со смартфона.",
    "sc_serif_19": "Я хочу научиться рисовать лучше!",
    "sc_serif_20": "Я нарисовал все иллюстрации здесь.",
    "sc_serif_21": "Я иногда рисовать смешные картинки ...",
    "sc_make_1": "Во-первых, выбрать один из трех изображений.",
    "sc_make_2": "SAI создает оригинальные иллюстрации. Если вы выбираете изображение, которое вы хотите, он будет генерировать аналогичную картину. Когда вы найдете символ, который вы любите, пожалуйста, не стесняйтесь, чтобы загрузить его.",
    "sc_make_3": "Выбрать",
    "sc_make_4": "Отменить",
    "sc_make_5": "история",
    "sc_make_6": "случайный",
    "sc_make_7": "Дом",
    "sc_make_8": "Пожалуйста войдите",
    "sc_make_9": "Если вы не создавать аккаунта, пожалуйста, войдите в систему после создания учетной записи. Вы можете создать больше иллюстраций после знака в.",
    "sc_make_10": "CREATE ACCOUNT / Вход",
    "sc_make_11": "Вы должны войти в систему для загрузки иллюстраций. Если вы не зарегистрированы, пожалуйста, создайте аккаунт.",
    "sc_make_12": "CREATE ACCOUNT / Вход",
    "sc_make_13": "Вы должны войти в систему для иллюстрации порядка. Если вы не зарегистрированы, пожалуйста, создайте аккаунт."
  },
  "bn": {
    "sc_serif_1": "অনুগ্রহ করে আপনার প্রিয় চরিত্র চয়ন।",
    "sc_serif_2": "কোনো ছবি নির্বাচন চরিত্রের অনুরূপ আঁকে",
    "sc_serif_3": "ধীরে ধীরে চিত্রণ যদি আপনি চান কাছাকাছি পৌঁছে যাবেন",
    "sc_serif_4": "ফটো প্রদর্শিত খুব একে অপরের অনুরূপ।",
    "sc_serif_5": "কাছাকাছি ছবিটি প্রদর্শিত হবে কেন্দ্র, আরো অনুরূপ এটি মূল চিত্রণ হয়।",
    "sc_serif_6": "চার কোণ অক্ষর নকশা একটু ভিন্ন।",
    "sc_serif_7": "এটা অনুরূপ ছবি আঁকা কেন্দ্র চিত্র নির্বাচন করুন।",
    "sc_serif_8": "আমি কনটেন্ট কপিরাইট আইনে পূর্বানুমতি বিভিন্ন প্রকার অধ্যয়নরত করছি।",
    "sc_serif_9": "আপনি ইমেজ ডাউনলোড করার সময়, জলছাপ অদৃশ্য হয়ে যাবে।",
    "sc_serif_10": "ত্রিভুজ প্যাটার্ন এবং আইকন ওয়াটারমার্ক হয়।",
    "sc_serif_11": "এলোমেলো বোতামে ক্লিক করে এলোমেলোভাবে একটি চিত্র অঙ্কন",
    "sc_serif_12": "প্রেস ফিরুন বোতাম পূর্ববর্তী রাজ্যের ফিরে যাওয়ার।",
    "sc_serif_13": "কেউ আমাকে গতি অঙ্কন মধ্যে beats।",
    "sc_serif_14": "আমি কনটেন্ট কপিরাইট আইনে পূর্বানুমতি বেশি 1 মিলিয়ন ধরণের আঁকা।",
    "sc_serif_15": "দয়া করে আমাকে জানি তুমি সেবার জন্য কোনো অনুরোধ আছে যদি যাক।",
    "sc_serif_16": "এই চিত্রণ বাণিজ্যিকভাবে ব্যবহার করা যাবে।",
    "sc_serif_17": "দয়া করে এসএনএস আমার আঁকা ভাগ করুন মুক্ত মনে।",
    "sc_serif_18": "আমি একটি পিসি থেকে এছাড়াও একটি স্মার্টফোন থেকে না শুধুমাত্র কাজ করে।",
    "sc_serif_19": "আমি ভাল আঁকা শিখতে চাই!",
    "sc_serif_20": "আমি এখানে সব কনটেন্ট কপিরাইট আইনে পূর্বানুমতি সৃষ্টি।",
    "sc_serif_21": "আমি কখনও কখনও মজার ছবি আঁকা ...",
    "sc_make_1": "প্রথমত, তিন ইমেজ মধ্য থেকে চয়ন।",
    "sc_make_2": "SAI মূল চিত্রগুলি উত্পন্ন করে। আপনি যদি চান এমন একটি ছবি নির্বাচন করেন তবে এটি একটি অনুরূপ চিত্র তৈরি করবে। আপনি যখন পছন্দ করেন এমন কোনও চরিত্র খুঁজে পান, দয়া করে এটি ডাউনলোড করতে দ্বিধা বোধ করবেন।",
    "sc_make_3": "নির্বাচন করা",
    "sc_make_4": "পূর্বাবস্থায় ফিরুন",
    "sc_make_5": "ইতিহাস",
    "sc_make_6": "এলোমেলো",
    "sc_make_7": "বাড়ি",
    "sc_make_8": "সাইন ইন করুন",
    "sc_make_9": "আপনি যদি এখনো অ্যাকাউন্ট তৈরি করে থাকেন, পরে সাইন ইন অ্যাকাউন্ট তৈরি করুন। আপনি সাইন ইন পর কনটেন্ট কপিরাইট আইনে পূর্বানুমতি তৈরি করতে পারেন।",
    "sc_make_10": "তৈরি করুন অ্যাকাউন্ট / সাইন ইন",
    "sc_make_11": "আপনি ডাউনলোডের কনটেন্ট কপিরাইট আইনে পূর্বানুমতি এ সাইন ইন করতে হবে। আপনি অ্যাকাউন্ট না করে থাকেন, অ্যাকাউন্ট তৈরি করুন।",
    "sc_make_12": "তৈরি করুন অ্যাকাউন্ট / সাইন ইন",
    "sc_make_13": "আপনি যাতে কনটেন্ট কপিরাইট আইনে পূর্বানুমতি এ সাইন ইন করতে হবে। আপনি অ্যাকাউন্ট না করে থাকেন, অ্যাকাউন্ট তৈরি করুন।"
  }
}
</i18n>
