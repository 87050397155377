<template>
  <div id="header" class="shd-m" :class="{'sticky': stayingServicePath === '/'}" v-show="isHeaderVisible">

    <div class="header-container">

      <!-- タイトルロゴ -->
      <div class="left-container">
        <router-link to="/">
          <img class="logo-pc display-pc" src="../assets/img/logo.svg" :alt="$t('svh_10')">
          <img class="logo-sp display-sp ml-3 mr-1" src="../assets/img/home_logo_object.svg" :alt="$t('svh_10')" v-if="stayingServicePath === '/'">
          <img class="logo-sp display-sp ml-3" src="../assets/img/logo.svg" :alt="$t('svh_10')" v-else>
        </router-link>

        <v-tabs slot="extension" slider-color="pink" class="tab" v-if="stayingServicePath === '/'">
          <v-tab class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp}" to="/">
            {{ $t("svh_1") }}
          </v-tab>
          <v-tab class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp}" to="/price">
            {{ $t("svh_2") }}
          </v-tab>
          <v-tab v-if="logined" class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp}" to="/data">
            {{ $t("svh_3") }}
          </v-tab>
        </v-tabs>

      </div>

      <div class="right-container">

        <!-- コンソールにエラーが！！ -->
        <!-- <v-menu offset-y left class="mr-2" v-if="stayingServicePath === '/'">
          <v-btn slot="activator" color="primary" class="text-lowercase account-btn" round outline>
            <font-awesome-icon icon="user" class="mr-2"/>
            {{ this.$store.getters['user/currentGroup'].name }}
          </v-btn>
          <v-list class="py-0">
            <v-subheader class="font-weight-bold mt-2">
              {{ $t("svh_5") }}
            </v-subheader>
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title class="mx-2">
                  {{ this.$store.getters['user/currentGroup'].name }}  ( {{ $t("svh_9") }} )
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-btn to="/my" dark round outline color="blue" class="px-5 mb-4">
              {{ $t("svh_4") }}
            </v-btn>
            <v-divider></v-divider>

            <div v-if="this.$store.getters['user/otherGroups'].length > 0">
              <v-subheader class="font-weight-bold">
                {{ $t("svh_5") }}
              </v-subheader>
              <v-list-tile v-for="(g, idx) in this.$store.getters['user/otherGroups']" :key="idx"
                @click="changeGroupTo(g.id)"
                :style="{cursor: 'pointer'}"
              >
                <v-list-tile-title class="mx-2">{{ g.name }}</v-list-tile-title>
              </v-list-tile>
            </div>
          <v-divider></v-divider>
          <v-layout align-center justify-end row fill-height>
            <v-btn @click="logout" flat round color="error">{{ $t("svh_8") }}</v-btn>
          </v-layout>
          </v-list>
        </v-menu> -->

        <!-- 言語選択ボタン -->
        <v-menu offset-y left full-width class="lang-container">
          <v-btn slot="activator" color="grey darken-1" dark outline round class="lang-btn">
            <v-icon class="mr-1">language</v-icon>
            <v-flex class="lang-now display-pc mr-2 text-none">
              {{ curLang }}
            </v-flex>
            <v-flex class="lang-now display-sp">
              {{ this.$i18n.locale }}
            </v-flex>
          </v-btn>
          <v-card class="langs-card">
            <v-container class="langs-container">
              <h4 class="h4-lang">{{ $t("aph_2") }}</h4>
              <v-layout row wrap>
                  <v-flex xs6>
                    <a class="lang-link" v-for="(lang, index) in langsL" :key="index" :href="frontURLByLang(invertDict[lang])">
                      <v-list-tile class="lang">
                        {{ lang }}
                      </v-list-tile>
                    </a>
                  </v-flex>
                  <v-flex xs6>
                    <a class="lang-link" v-for="(lang, index) in langsR" :key="index" :href="frontURLByLang(invertDict[lang])">
                      <v-list-tile class="lang">
                        {{ lang }}
                      </v-list-tile>
                    </a>
                  </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import {frontBaseURLByLang} from "../util"
export default {
  name: 'AppHeader',
  props: ['auth'],
  data() {
    const localeDict = {
      'ja': '日本語',
      'en': 'English',
      'fr': 'Français',
      'es': 'Español',
      'pt': 'português',
      'de': 'Deutsch',
      'nl': 'Nederlands',
      'tr': 'Türkçe',
      'zh-cn': '简体中文',
      'zh-tw': '繁體中文',
      'sv': 'Svenska',
      'it': 'Italiano',
      'ko': '한국어',
      'ar': 'العربية',
      'th': 'ภาษาไทย',
      'id': 'Bahasa Indonesia',
      'pl': 'Polski',
      'vi': 'Tiếng Việt',
      'hi': 'தமிழ்',
      'ru': 'Русский',
      'bn': 'বাংলা'
    }
    let invertDict = {}
    Object.keys(localeDict).forEach(k => {
      invertDict[localeDict[k]] = k
    })
    return {
      curLang: localeDict[this.$i18n.locale],
      localeDict: localeDict,
      invertDict: invertDict,
      pathName: window.location.pathname,
      drawer: null,
      langsL: [
        localeDict['ja'],
        localeDict['es'],
        localeDict['ar'],
        localeDict['ru'],
        localeDict['ko'],
        localeDict['it'],
        localeDict['id'],
        localeDict['pl'],
        localeDict['vi'],
        localeDict['th'],
        localeDict['bn']
      ],
      langsR: [
        localeDict['zh-cn'],
        localeDict['en'],
        localeDict['pt'],
        localeDict['de'],
        localeDict['fr'],
        localeDict['nl'],
        localeDict['tr'],
        localeDict['zh-tw'],
        localeDict['hi'],
        localeDict['sv']
      ]
    }
  },
  computed: {
    isHeaderVisible() {
      return this.$store.state.screen.isHeaderVisible
    },
    logined() {
      return this.$store.getters['user/logined']
    },
    stayingServicePath() {
      return this.$store.state.user.stayingServicePath || '/'
    }
  },
  methods: {
    frontURLByLang(lang) {
      return frontBaseURLByLang(lang) + this.$route.path
    },
    login(focusedTab) {
      this.auth.login(focusedTab)
    },
    logout() {
      this.auth.logout()
      this.$store.commit('user/logout')
    },
    toMypage() {
      this.$router.push('/my')
    },
    changeGroupTo(groupId) {
      this.$store.commit('user/setCurrentGroup', groupId)
      this.$store.dispatch('user/getMembers')
      this.$router.push('/')
    },
    moveTo(path) {
      this.$router.push(path)
    },
    moveToStayingServiceTop() {
      this.$router.push(this.stayingServicePath)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
// ヘッダー内のv-menu
.v-menu__content {
  border-radius: 4px;
}
#header {
  background-color: white;
  z-index: 200;
  position: relative;
  top: 0px;
  left: 0px;
  right: 0px;

  &.sticky {
    position: sticky;
  }

  .header-container {
    margin: auto;
    max-width: 1040px;
    width: 100%;
    height: 72px;
    @include mq-down(sm) {
      height: 52px;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-container {
      display: flex;
      align-items: center;
      height: 100%;
      overflow: auto;
      .logo-pc {
        width: 216px;
        @include mq-down(cn) {
          margin-left: 16px;
        }
      }
      .logo-sp {
        height: 28px;
        @include mq-up(sm) {
          height: 48px;
        }
      }
      .tab {
        align-self: flex-end;
        font-weight: 500;
        text-decoration: none;
        margin-left: 48px;
        /deep/ .v-tabs__slider {
          height: 4px;
        }
        /deep/ .v-tabs__item {
          &:hover {
            opacity: 1 !important;
            text-decoration: none;
          }
        }
        @include mq-down {
          margin-left: 16px;
        }
      }
      // すべてのAIをみるボタン
      .see-all-ai {
        margin-left: 48px;
        @include mq-down(sm) {
          margin-left: 24px;
        }
      }
    }
    .right-container {
      display: flex;
      align-items: center;
      height: 100%;
      .account-btn {
        height: 32px;
      }
      // 言語選択ボタン
      .lang-container {
        text-align: right;
        .lang-btn {
          // max-width: 160px;
          height: 32px;
          padding: 0px 16px;
          .lang-now {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}

// 言語選択の中身
.h4-lang {
  font-size: 16px;
  color: #C2185B;
  font-weight: 500;
  text-align: center;
}
.langs-card {
  @include mq-up {
    width: 376px;
  }

  .langs-container {
    @include mq-up {
      padding: 24px 16px;
    }
    @include mq-down {
      padding: 8px 4px;
    }

    .lang-link {
      color: #424242;
      text-align: left;

      &:hover {
        text-decoration: none;

        .lang {
          background-color: #F5F5F5;
        }
      }
    }
  }
}

</style>

<i18n>
{
  "ja": {
    "aph_1": "すべてのAIをみる",
    "aph_2": "言語を選択",
    "svh_1": "トップ",
    "svh_2": "価格",
    "svh_3": "データ",
    "svh_4": "マイページ",
    "svh_5": "チーム",
    "svh_8": "ログアウト",
    "svh_9": "ログイン中",
    "svh_10": "画像編集加工AIツール：cre8tiveAI(クリエイティブAI)"
  },
  "en": {
    "aph_1": "Other AI",
    "aph_2": "Select Language",
    "svh_1": "Top",
    "svh_2": "Price",
    "svh_3": "Data",
    "svh_4": "Mypage",
    "svh_5": "Team",
    "svh_8": "Create Account",
    "svh_9": "Sign in",
    "svh_10": "Image and video editing AI tool: cre8tiveAI"
  },
  "fr": {
    "aph_1": "Toutes les IA",
    "aph_2": "Choisir une langue",
    "svh_1": "Accueil ",
    "svh_2": "Tarifs",
    "svh_3": "Données",
    "svh_4": "Ma page",
    "svh_5": "Équipe ",
    "svh_8": "Se déconnecter ",
    "svh_9": "Connecté",
    "svh_10": "Image et outil d'édition vidéo AI : cre8tiveAI"
  },
  "es": {
    "aph_1": "Ver todas las I.A.",
    "aph_2": "Seleccionar el idioma",
    "svh_1": "Página de inicio",
    "svh_2": "Precio",
    "svh_3": "Información",
    "svh_4": "Mi página",
    "svh_5": "Equipo",
    "svh_8": "Cierre de sesión",
    "svh_9": "Disponible",
    "svh_10": "Image y la herramienta de edición de video AI: cre8tiveAI"
  },
  "pt": {
    "aph_1": "Veja todas as IA disponíveis",
    "aph_2": "Selecione o idioma",
    "svh_1": "Topo",
    "svh_2": "Preço",
    "svh_3": "Dados",
    "svh_4": "Minha página",
    "svh_5": "Equipe",
    "svh_8": "SAIr",
    "svh_9": "Atualmente logado",
    "svh_10": "Image e ferramenta de edição de vídeo AI: cre8tiveAI"
  },
  "de": {
    "aph_1": "Übersicht KI",
    "aph_2": "Sprache auswählen",
    "svh_1": "Startseite",
    "svh_2": "Preis",
    "svh_3": "Dateien",
    "svh_4": "Persönliche Seite",
    "svh_5": "Team",
    "svh_8": "Ausloggen",
    "svh_9": "eingeloggt",
    "svh_10": "Image und Videobearbeitung AI-Werkzeug: cre8tiveAI"
  },
  "nl": {
    "aph_1": "andere AI",
    "aph_2": "Selecteer Taal",
    "svh_1": "Top",
    "svh_2": "Prijs",
    "svh_3": "Gegevens",
    "svh_4": "Mijn pagina",
    "svh_5": "Team",
    "svh_8": "Account aanmaken",
    "svh_9": "Log in",
    "svh_10": "Image en video-editing AI-tool: cre8tiveAI"
  },
  "tr": {
    "aph_1": "Diğer AI",
    "aph_2": "Dil Seçin",
    "svh_1": "Üst",
    "svh_2": "Fiyat",
    "svh_3": "Veri",
    "svh_4": "Sayfam",
    "svh_5": "Takım",
    "svh_8": "Hesap oluştur",
    "svh_9": "oturum aç",
    "svh_10": "Görüntü ve video düzenleme AI aracı: cre8tiveAI"
  },
  "zh-cn": {
    "aph_1": "查看所有ＡＩ",
    "aph_2": "选择语言",
    "svh_1": "主页",
    "svh_2": "价格",
    "svh_3": "资料",
    "svh_4": "我的主页",
    "svh_5": "团队",
    "svh_8": "登出",
    "svh_9": "登入中",
    "svh_10": "图像和视频编辑AI工具：cre8tiveAI"
  },
  "zh-tw": {
    "aph_1": "查看所有ＡＩ",
    "aph_2": "選擇語言",
    "svh_1": "主頁",
    "svh_2": "價格",
    "svh_3": "資料",
    "svh_4": "我的主頁",
    "svh_5": "團隊",
    "svh_8": "登出",
    "svh_9": "登入中",
    "svh_10": "圖片和視頻編輯工具AI：cre8tiveAI"
  },
  "sv": {
    "aph_1": "andra AI",
    "aph_2": "Välj språk",
    "svh_1": "Topp",
    "svh_2": "Pris",
    "svh_3": "Data",
    "svh_4": "Min sida",
    "svh_5": "Team",
    "svh_8": "Skapa konto",
    "svh_9": "Logga in",
    "svh_10": "Bild- och videoredigering AI verktyg: cre8tiveAI"
  },
  "it": {
    "aph_1": "Scopri tutte le AI",
    "aph_2": "Seleziona la lingua",
    "svh_1": "Top",
    "svh_2": "Costi",
    "svh_3": "Dati",
    "svh_4": "Mia pagina",
    "svh_5": "Team",
    "svh_8": "Logout",
    "svh_9": "Attiva",
    "svh_10": "Image e strumento AI per l'editing video: cre8tiveAI"
  },
  "ko": {
    "aph_1": "모든 AI를 보기",
    "aph_2": "언어 선택",
    "svh_1": "홈",
    "svh_2": "가격",
    "svh_3": "데이터",
    "svh_4": "마이페이지",
    "svh_5": "팀",
    "svh_8": "로그아웃",
    "svh_9": "로그인중",
    "svh_10": "이미지 및 비디오 편집 AI 도구 : cre8tiveAI"
  },
  "ar": {
    "aph_1": "منظمة العفو الدولية الأخرى",
    "aph_2": "اختار اللغة",
    "svh_1": "أعلى",
    "svh_2": "السعر",
    "svh_3": "البيانات",
    "svh_4": "صفحتي",
    "svh_5": "الفريق",
    "svh_8": "إنشاء حساب",
    "svh_9": "تسجيل الدخول",
    "svh_10": "صورة وتحرير الفيديو أداة AI: cre8tiveAI"
  },
  "th": {
    "aph_1": "ดูAIทั้งหมด",
    "aph_2": "เลือกภาษา",
    "svh_1": "ด้านบน",
    "svh_2": "ราคา",
    "svh_3": "ข้อมูล",
    "svh_4": "หน้าหลัก",
    "svh_5": "ทีม",
    "svh_8": "ออกจากระบบ",
    "svh_9": "เข้าระบบอยู่",
    "svh_10": "รูปภาพและตัดต่อวิดีโอเครื่องมือไอ: cre8tiveAI"
  },
  "id": {
    "aph_1": "AI lainnya",
    "aph_2": "Pilih bahasa",
    "svh_1": "Teratas",
    "svh_2": "Harga",
    "svh_3": "Data",
    "svh_4": "Halamanku",
    "svh_5": "Tim",
    "svh_8": "Buat Akun",
    "svh_9": "Masuk",
    "svh_10": "Gambar dan video editing tool AI: cre8tiveAI"
  },
  "pl": {
    "aph_1": "Inne AI",
    "aph_2": "Wybierz język",
    "svh_1": "Top",
    "svh_2": "Cena £",
    "svh_3": "Dane",
    "svh_4": "Moja strona",
    "svh_5": "Zespół",
    "svh_8": "Utwórz konto",
    "svh_9": "Zaloguj się",
    "svh_10": "Image oraz narzędzie do edycji wideo AI: cre8tiveAI"
  },
  "vi": {
    "aph_1": "AI khác",
    "aph_2": "Chọn ngôn ngữ",
    "svh_1": "Hàng đầu",
    "svh_2": "Giá bán",
    "svh_3": "Dữ liệu",
    "svh_4": "Trang của tôi",
    "svh_5": "Đội",
    "svh_8": "Tạo tài khoản",
    "svh_9": "Đăng nhập",
    "svh_10": "Hình ảnh và chỉnh sửa video công cụ AI: cre8tiveAI"
  },
  "hi": {
    "aph_1": "Sab AI dekhenge",
    "aph_2": "Language ka choose",
    "svh_1": "Top",
    "svh_2": "Price",
    "svh_3": "Data",
    "svh_4": "My Page",
    "svh_5": "Team",
    "svh_8": "Log Out",
    "svh_9": "Log In",
    "svh_10": "छवि और वीडियो संपादन ऐ उपकरण: cre8tiveAI"
  },
  "ru": {
    "aph_1": "Другое А.И.",
    "aph_2": "Выбрать язык",
    "svh_1": "верхний",
    "svh_2": "Цена",
    "svh_3": "Данные",
    "svh_4": "Моя страница",
    "svh_5": "команда",
    "svh_8": "Регистрация",
    "svh_9": "войти в систему",
    "svh_10": "Image и инструмент для редактирования видео AI: Cre8tiveAI"
  },
  "bn": {
    "aph_1": "অন্যান্য এআই",
    "aph_2": "ভাষা নির্বাচন কর",
    "svh_1": "শীর্ষ",
    "svh_2": "মূল্য",
    "svh_3": "উপাত্ত",
    "svh_4": "আমার পাতা",
    "svh_5": "টীম",
    "svh_8": "হিসাব তৈরি কর",
    "svh_9": "সাইন ইন করুন",
    "svh_10": "ভাবমূর্তি এবং ভিডিও সম্পাদনা এআই টুল: cre8tiveAI"
  }
}

</i18n>