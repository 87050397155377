import { Auth0Client } from '@auth0/auth0-spa-js'

import { AUTH_CONFIG } from './auth0-variables'
import EventEmitter from 'eventemitter3'
import router from './../router'
import store from '../store/index'
import i18n from '../i18n'
import { frontBaseURLByLang } from '../util'


const STORAGE_KEY_FOR_URL = 'url_path_before_auth'

export default class AuthService {
  authNotifier = new EventEmitter()

  constructor () {
    this.login = this.login.bind(this)
    this.setSession = this.setSession.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = AuthService.isAuthenticated.bind(this)
    this.guestExec = AuthService.guestExec.bind(this)
  }

  auth0 = new Auth0Client({
    domain: AUTH_CONFIG.domain,
    clientId: AUTH_CONFIG.clientId,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      redirect_uri: AUTH_CONFIG.callbackUrl,
      audience: AUTH_CONFIG.audience,
      scope: 'openid profile',
    },
  })

  async login (focusedTab = 'login', landingPath = router.currentRoute.path) {
    localStorage.setItem(STORAGE_KEY_FOR_URL, landingPath)

    // 旧バージョンでのタブ選択方法
    // NOTE. auth0側で読み出してデフォルト選択状態のタブにする
    //       local開発時はドメインが違うので確認はできない
    //       次のどれか。 login|account(アカウント作成)|password(パスワード再発行)
    // Cookies.set('login_tab', focusedTab, {domain: 'cre8tiveai.com', expires: 365})

    // Lockを使った新バージョンでのタブ選択方法
    // NOTE. auth0側で読み出してデフォルト選択状態のタブにする
    //       次のどれか。 login|signUp(アカウント作成)|forgotPassword(パスワード再発行)
    if (focusedTab === 'account') {
      focusedTab = 'signUp'
    }

    // Auth0で対応していない言語はenにする（加えて、一部言語コードが違うものがあるので修正する）
    // https://auth0.com/docs/customize/internationalization-and-localization/lock-internationalization#provided-languages
    let language = i18n.locale
    const NON_PROVIDED_LIST = ['bn', 'hi', 'th']
    const MAPPING_DICT = {'zh-cn': 'zh'}
    for (let key in MAPPING_DICT) {
      if (language === key) {
        language = MAPPING_DICT[key]
        break
      }
    }
    for (let i = 0; i < NON_PROVIDED_LIST.length; i++) {
      if (language === NON_PROVIDED_LIST[i]) {
        language = 'en'
        break
      }
    }

    // NOTE. promptをつけない場合、以前ログインしたアカウントで自動でログインするが、
    //       別アカウントに切り替えたい場合に困るので必ずログインフォームを表示する
    await this.auth0.loginWithRedirect({
      authorizationParams: {
        prompt: 'login',
        focused_tab: focusedTab,
        language: language
      }
    })
  }

  // auth0でのログイン後に/auth_cb3にアクセスが来てcallされる
  async handleAuthentication () {
    try {
      await this.auth0.handleRedirectCallback()
    } catch (err) {
      router.replace('/')
      if (err.error === 'unauthorized' && err.message === 'Please verify your email before logging in.') {
        // ユーザ登録後、確認メールのリンクを踏まずにログインを試みた場合
        store.commit('screen/showError', {localizeId: 'err_20'})
      } else {
        throw err
      }
      return
    }
    this.setSession()
    // 前いたページに戻る
    const beforePath = localStorage.getItem(STORAGE_KEY_FOR_URL)
    if (beforePath && beforePath !== '/login') {  // 無限ループするのでloginは除く
      localStorage.removeItem(STORAGE_KEY_FOR_URL)
      router.replace(beforePath)
    } else {
      router.replace('/')
    }
  }

  // アクセストークンを取得後、authChangeをemitしてアプリログインを完了させる
  async setSession () {
    const accessToken = await this.getTokenSilently()
    if (!accessToken) {
      localStorage.setItem('login_status', 0)
      this.authNotifier.emit('authChange', { authenticated: false })
      return
    }
    localStorage.setItem('login_status', 1)  // Auth0ログイン完了
    this.authNotifier.emit('authChange', { authenticated: true, accessToken: accessToken })
  }

  async logout () {
    // cre8tiveAI側ログアウト処理
    this.removeLocalStorage()
    this.authNotifier.emit('authChange', false)
    // Auth0側ログアウト処理
    await this.auth0.logout({
      logoutParams: {
        returnTo: frontBaseURLByLang(i18n.locale),  // navigate to the home route
      }
    })
  }

  removeLocalStorage () {
    localStorage.removeItem('current_group_id')
    localStorage.removeItem('login_status')
  }

  async getTokenSilently() {
    try {
      return await this.auth0.getTokenSilently()
    } catch (err) {
      if (err.error !== 'missing_refresh_token') throw err
      if (localStorage.getItem('login_status') != 0) this.removeLocalStorage()
      return null
    }
  }

  // NOTE. routerからもアクセスさせたいのでstaticにした...
  //   これが返すのはAuth0側でログインできているかどうかであり、
  //   アプリ的なログイン完了(=cre8tiveAIのユーザ情報が取得できていてcre8tiveAIの(ログインを要する)APIを叩ける状態にある)は
  //   App.vueでの 1. AuthHeaderのset 2. getUserによるアプリuser情報の取得
  //   を行った時点であることに注意。
  //   アプリ的なログインを同期的に判定したい場合は $store.getters['user/logined']
  //   ログイン状態の変化を通知して欲しい時は $store.state.user.logined$ をsubscribeすることで行える
  //   2022/12追記
  //     Refresh Token Rotation導入後、isAuthenticated()を見直し
  //     今までisAuthenticated()を使用してログイン確認していた箇所は基本的に $store.getters['user/logined'] で確認するようにする
  //     上記の方針では諸々の理由（※）でうまくいかない場合もあるので、localstorageでもログイン状態を記録して一部補助的に使用する
  //       login_status: 0（未ログイン）
  //                     1（Auth0ログイン完了）
  //                     2（アプリログイン完了）
  //       ※ router.jsのbeforeEnterでは $store.getters['user/logined'] での値取得がうまくいかない
  //       ※ auth0.isAuthenticated() でログイン判定するとPromise<boolean>型を返して思ったようにならない
  static isAuthenticated (targetStatus) {
    const loginStatus = localStorage.getItem('login_status') ? parseInt(localStorage.getItem('login_status'), 10) : 0
    return loginStatus >= targetStatus ? true : false
  }

  // NOTE. 上記と同じ概念
  //   ゲストユーザーがアプリ的に実行可能かを同期的に判定したい場合は $store.getters['user/guestExec']
  //   実行可能かの状態の変化を通知して欲しい時は $store.state.user.guestExec$ をsubscribeすることで行える
  static guestExec () {
    if (localStorage.getItem('login_status') != 0) return false
    const execLimit = 3
    const execCount = localStorage.getItem('exec_count') ? parseInt(localStorage.getItem('exec_count'), 10) : execLimit
    return execCount < execLimit ? true : false
  }
}
