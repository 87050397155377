<template>
  <div>
    <service-header :auth="auth"/>
    <div id="sc_plus_top">
      <div class="first-view display-pc">
        <div class="first-view-container base-container">
          <v-img class="fv-chara" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp"></v-img>
          <div class="heading-container">
            <p class="heading-caption">全身イラストをつくる</p>
            <h1 class="saiplus-heading">
              <span class="text-AI">AI</span>
              <span class="cross-icon">×</span>
              <span class="japanese-container">絵師</span>
            </h1>
          </div>
          <div class="fv-text-container">
            <div class="fv-balloon">
              <p class="balloon-text">
                <span v-html='$t("scplus_3")'></span>
              </p>
            </div>
            <div class="fv-face-title">
              <v-img class="fv-face" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp">
                <v-img class="purple-plus" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
              </v-img>
              <div class="fv-service-title">
                <h2 class="plus-title">{{ $t("scplus_1") }}<span class="super">+</span></h2>
                <p class="title-caption">{{ $t("scplus_2") }}</p>
              </div>
            </div>
            <p class="fv-caption">
              <span v-html='$t("scplus_4")'></span>
            </p>
            <div class="fv-formula">
              <p class="text-1">{{ $t("scplus_1") }}</p>
              <span class="plus">+</span>
              <p class="text-2">{{ $t("scplus_2") }}</p>
            </div>
            <div class="btn-container">
              <v-btn color="#D780EB" class="cv-btn text-none shd-m" round dark depressed @click="navigateToMakingFace()">
                {{ $t("scplus_5") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="first-view display-sp">
        <div class="first-view-container base-container">
          <!-- <v-img class="fv-shape" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/hero_big_text.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/hero_big_text.svg"></v-img> -->
          <div class="heading-container">
            <p class="heading-caption">全身イラストをつくる</p>
            <h1 class="saiplus-heading">
              <span class="text-AI">AI</span>
              <span class="cross-icon">×</span>
              <span class="japanese-container">絵師</span>
            </h1>
          </div>
          <v-img class="fv-chara" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp"></v-img>
        </div>
        <div class="fv-text-container base-container">
          <div class="fv-face-title">
            <v-img class="fv-face" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp">
              <v-img class="purple-plus" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
            </v-img>
            <div class="fv-service-title">
              <h2 class="plus-title">{{ $t("scplus_1") }}<span class="super">+</span></h2>
            </div>
          </div>
          <p class="fv-caption">
            <span v-html='$t("scplus_4")'></span>
          </p>
          <div class="btn-container">
            <v-btn color="#D780EB" class="cv-btn cv-btn-fv text-none" round dark depressed @click="navigateToMakingFace()">
              {{ $t("scplus_5") }}
            </v-btn>
          </div>
        </div>
      </div>
      <div class="collaboration">
        <div class="base-container">
          <v-layout align-center justify-center class="balloon-container" column>
            <v-img class="face-with-balloon" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp">
              <div class="purple-plus"></div>
            </v-img>
            <div class="fv-formula hide-md-up">
              <p class="text-1">{{ $t("scplus_1") }}</p>
              <span class="plus">+</span>
              <p class="text-2">{{ $t("scplus_2") }}</p>
            </div>
            <p class="yellow-balloon-column">
              <span v-html='$t("scplus_6")'></span>
            </p>
          </v-layout>
          <h2 class="collaboration-title">
            <span class="japanese-container">
              <span class="text-AI">AI</span>
              <span class="cross-icon">×</span>
              <span class="japanese-illustrator">絵師</span>
            </span>
            <span class="collaboration-subtitle">{{ $t("scplus_9") }}</span>
          </h2>
          <p class="collaboration-paragraph">
            {{ $t("scplus_10") }}
          </p>
        </div>
      </div>
      <div class="service-flow">
        <div class="base-container">
          <v-layout align-center justify-start class="balloon-container">
            <v-img class="face-with-balloon" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp">
              <div class="purple-plus"></div>
            </v-img>
            <p class="yellow-balloon-row">{{ $t("scplus_11") }}↓↓</p>
          </v-layout>
          <hr class="horizontal-dotted">
          <div class="yellow-panel panel-1">
            <h3>
              <span class="panel-num">1.</span>
              <span class="step-title">{{ $t("scplus_1") }}</span>
            </h3>

            <div class="panel-1-left">
              <v-img class="sai-and-pallet" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/saichan_and_palette.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/saichan_and_palette.webp"></v-img>
              <p class="white-balloon">
                <span v-html='$t("scplus_12")'></span>
              </p>
            </div>
            <v-img class="blue-arrow" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg"></v-img>
            <v-img class="sc-made" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/sai_made.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/sai_made.webp"></v-img>

          </div>
          <hr class="horizontal-dotted">
          <div class="yellow-panel panel-2">
            <h3>
              <span class="panel-num">2.</span>
              <span class="step-title">{{ $t("scplus_8") }}</span>
            </h3>
            <div class="panel-2-left">
              <v-img class="tablet-pic display-pc" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_pic_pc.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_pic_pc.webp"></v-img>
              <v-img class="tablet-pic display-sp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_pic_sp.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_pic_sp.webp"></v-img>
              <p class="white-balloon">
                <span v-html='$t("scplus_13")'></span>
              </p>
            </div>
            <v-img class="blue-arrow" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg"></v-img>
            <v-img class="lined-illust" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_line.png" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/making_line.png"></v-img>
          </div>
          <hr class="horizontal-dotted">
          <div class="arrow-down-container hide-md-down">
            <v-img class="blue-arrow-down" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/blue_arrow.svg"></v-img>
            <p class="two-weeks">2weeks later…</p>
          </div>
          <div class="yellow-panel panel-3">
            <h3>
              <span class="panel-num">3.</span>
              <span class="step-title">{{ $t("scplus_14") }}</span>
            </h3>
            <div class="panel-3-container">
              <v-img contain class="finished-product" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp"></v-img>
              <p class="white-balloon">
                {{ $t("scplus_16") }}
              </p>
              <div class="speak-container">
                <v-img class="sai-speak " lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face_nico.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face_nico.webp">
                  <div class="purple-plus"></div>
                </v-img>
                <v-img contain class="speak-line">
                  <p>{{ $t("scplus_15") }}</p>
                </v-img>
              </div>
              <p class="caption-text hide-md-down">{{ $t("scplus_17") }}</p>
            </div>
            <p class="caption-text hide-md-up">{{ $t("scplus_17") }}</p>
          </div>
          <hr class="horizontal-dotted">
          <div class="lighten-yellow-panel">
            <h3 class="step-title-option">
              Option
            </h3>
            <p class="caption-text">
              <span v-html='$t("scplus_19")'></span>
            </p>
            <div class="option-title">
              <p>{{ $t("scplus_20") }}</p>
              <p>+{{ $t("scplus_21") }}</p>
            </div>

            <v-img class="emo-base" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/emotion_base.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/emotion_base.webp"></v-img>
            <p class="plus-icon">+</p>

            <div class="emotion-three">
              <v-img class="emo-pic" v-for="i of 4" :key="i" :lazy-src="'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/emotion_pic_'+i+'.jpg'" :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/emotion_pic_'+i+'.jpg'"></v-img>
            </div>
            <p class="white-balloon-1">{{ $t("scplus_22") }}</p>
            <div class="option-title">
              <p>{{ $t("scplus_23") }}</p>
              <p>+{{ $t("scplus_24") }}</p>
            </div>
            <p class="white-balloon-2">
              <span v-html='$t("scplus_25")'></span>
            </p>
          </div>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="feature">
        <div class="base-container">
          <h2>
            <span class="heading-underline">{{ $t("scplus_26") }}</span>
          </h2>
          <div v-for="i of 3" :key="i" :class="`features-container-${i}`">
            <v-img class="chara-pic" contain :lazy-src="featuresImage[i-1]" :src="featuresImage[i-1]"></v-img>
            <div class="feature-text-container">
              <v-img class="features-num" :src="`https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/04_feature/feature_num_${i}.svg`"></v-img>
              <h3 class="features-title">{{ $t(featuresTitle[i-1]) }}</h3>
              <p class="features-description">
                <span v-html="$t(featuresDescription[i-1])"></span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="onlyone">
        <div class="base-container">
          <v-btn color="#D780EB" class="cv-btn-1 text-none text--white" block round dark depressed @click="navigateToMakingFace()">
            <span v-html='$t("scplus_5a")'></span>
          </v-btn>
          <div class="onlyone-chara-container">
            <v-img class="onlyone-chara display-pc" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/05_onlyone/chara_smile.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/05_onlyone/chara_smile.webp"></v-img>
            <v-img class="onlyone-chara display-sp" contain lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/05_onlyone/chara_smile_sp.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/05_onlyone/chara_smile_sp.webp"></v-img>
            <div class="onlyone-right-container">
              <p class="onlyone-balloon">
                <span class="onlyone-balloon-inside">
                  <span v-html='$t("scplus_33")'></span>
                </span>
              </p>
              <v-btn color="#D780EB" class="cv-btn-2 hide-md-down text-none" round dark depressed @click="navigateToMakingFace()">
                {{ $t("scplus_5") }}
              </v-btn>
              <div class="face-title hide-md-up">
                <v-img class="face" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp">
                  <v-img class="purple-plus" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
                </v-img>
                <p class="plus-title">
                  {{ $t("scplus_1") }}<span class="super">+</span>
                </p>
                <p class="plus-caption">{{ $t("scplus_2") }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="price">
        <div class="base-container">
          <h2 class="price-title">PRICE</h2>
          <div class="price-container">
            <div class="price-formula-container">
              <p class="price-plus-element">{{ $t("scplus_34") }}</p>
              <p class="price-formula-plus">+</p>
              <p class="price-plus-element">{{ $t("scplus_35") }}</p>
            </div>
            <div class="price-result-container">
              <p class="price-result-caption">{{ $t("scplus_36") }}</p>
              <div class="price-result-bottom">
                <v-img class="price-arrow" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg"></v-img>
                <p class="price-result-price">{{ $t("scplus_37") }}</p>
              </div>
            </div>
          </div>
          <h3 class="price-option-title">OPTION</h3>
          <ul class="price-option-formula">
            <li class="price-option-formula-list">
              <div class="price-option-formula-flex">
                <p class="price-option-formula-text">
                  {{ $t("scplus_38") }}
                </p>
                <v-img class="price-option-arrow" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg"></v-img>
                <p class="price-option-result">+{{ $t("scplus_39") }}</p>
              </div>
            </li>
            <li class="price-option-formula-list">
              <div class="price-option-formula-flex">
                <p class="price-option-formula-text">
                  <span v-html='$t("scplus_23")'></span>
                </p>
                <v-img class="price-option-arrow" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/06_price/blue_arrow.svg"></v-img>
                <p class="price-option-result">+{{ $t("scplus_41") }}</p>
              </div>
            </li>
          </ul>
          <p class="price-description">
            {{ $t("scplus_42") }}
          </p>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="usecase">
        <div class="base-container">
          <h2 class="usecase-title">
            <div class="japanese-container usecase-charas">
              <span class="text-AI">AI</span>
              <span class="cross-icon">×</span>
              <span class="japanese-illustrator">絵師</span>
            </div>
            <p class="usecase-title-caption" v-html='$t("scplus_46")'></p>
          </h2>
          <hr class="usecase-dotted">
          <p class="usacase-main-description">
            <span v-html='$t("scplus_47")'></span>
          </p>
        </div>
        <div class="usecase-base-container">
          <h3 class="usecase-subtitle">{{ $t("scplus_48") }}</h3>
          <div class="usecase-flex-container">
            <div :class="`usecase-item-${i}`" v-for="i of 6" :key="i">
              <div class="usecase-image-container">
                <v-img contain class="usecase-image" :src="usecaseImage[i-1]" :lazy-src="usecaseImage[i-1]"></v-img>
              </div>
              <h4 class="usecase-heading">
                <span class="usecase-heading-span" v-html="$t(usecaseHeading[i-1])"></span>
              </h4>
              <p class="usecase-item-description">
                <span v-html="$t(usecaseDescription[i-1])"></span>
              </p>
            </div>
          </div>
          <v-btn color="#898989" class="usecase-collaboration-btn text-none" round dark to="/#g-form">
            {{ $t("scplus_61") }}→
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../router.js'
import ServiceHeader from '@/components/ServiceHeader.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
export default {
  name: 'SaiChanPlusTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    AdResponsive
  },
  data() {
    return {
      featuresImage: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_jk.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_suits.webp'
      ],
      featuresTitle: [
        "scplus_27",
        "scplus_29",
        "scplus_31"
      ],
      featuresDescription: [
        "scplus_28",
        "scplus_30",
        "scplus_32"
      ],
      usecaseImage: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/07_usecase/usecase_pic_1.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/07_usecase/usecase_pic_2.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_jk.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/07_usecase/usecase_pic_3.png',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/07_usecase/usecase_pic_4.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/07_usecase/usecase_pic_5.webp'
      ],
      usecaseHeading: [
        "scplus_49",
        "scplus_51",
        "scplus_53",
        "scplus_55",
        "scplus_57",
        "scplus_59"
      ],
      usecaseDescription: [
        "scplus_50",
        "scplus_52",
        "scplus_54",
        "scplus_56",
        "scplus_58",
        "scplus_60"
      ],
    }
  },
  watch: {
    '$route': function (n, o) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
      console.log('new, old', [n.hash, o.hash])
    }
  },
  methods: {
    navigateToMakingFace() {
      // scMakeのrouting定義に props: trueを指定することで、paramsを component propsとして受け取れるようにしたかったが効かない?
      // https://router.vuejs.org/guide/essentials/passing-props.html#passing-props-to-route-components
      router.push({name: 'scMake', params: {fromSaichanPlus: true}})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";

@mixin container_base($width: 1040px) {
  padding: 0 40px;
  overflow: hidden;

  @include mq-down(md) {
    padding: 0 28px;
  }

  .base-container {
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-bottom: 0;
  }
}

p,h1,h2,h3,h4,h5 {
  color: #424242;
}

@mixin mincho_font {
  font-family: 'Hiragino Mincho ProN', 'yu-mincho-pr6n', 'Yu Mincho', 'kozuka-mincho-pr6n', 'Noto Serif JP', 'Times New Roman', Times, serif;
}

.first-view {
  background-color: #FCEC76;
  @include container_base;

  @include mq-down(lg) {
    padding: 0;
  }
  @include mq-down(md) {
    height: calc(125.36vw + 237.2px);
  }
  @include mq-down(sm) {
    height: calc(123.64vw + 152.4px);
  }
  .first-view-container {
    display: flex;
    justify-content: flex-end;
    height: 650px;
    align-items: flex-start;
    position: relative;
    @include mq-down(md) {
      height: initial;
      justify-content: space-between;
    }
  }
  .fv-chara {
    margin-top: 25px;
    width: 38%;
    min-width: 320px;
    max-width: 385px;
    margin-right: 12px;

    @include mq-down(xl) {
      margin-top: 25px;
    }
    @include mq-down(lg) {
      margin-right: -8px;
      align-self: center;
    }
    @include mq-down(md) {
      max-width: 70%;
      min-width: 70%;
      width: 70%;
      margin-right: 5%;
      margin-top: 8px;
      margin-left: -100%;
      align-self: initial;
    }
  }
  .heading-container {
    display: flex;
    margin-top: 16px;
    margin-left: -28px;
    margin-right: 28px;
    align-items: flex-start;
    color: #424242;
    @include mincho_font;

    @include mq-down(lg) {
      margin-left: 0px;
      margin-right: 0px;
    }

    @include mq-down {
      margin-left: 3%;
    }

    @mixin tategaki {
      writing-mode: vertical-rl;
      text-orientation: upright;
    }

    .saiplus-heading {
      font-size: 130px;
      width: 152px;
      margin-left: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @include mq-down(lg) {
        font-size: 112px;
      }
      @include mq-down {
        width: 70%;
        position: relative;
        z-index: 2;
        margin-left: 0;
        margin-top: -28px;
        font-size: calc(16.11vw + 28.5px);
      }

      .text-AI {
        writing-mode: horizontal-tb;
        margin-bottom: -40px;
      }

      .cross-icon {
        font-family: 'Noto Sans JP', 'Source Han Sans JP', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraginoSans-W3', 'Avenir', Helvetica, Arial, sans-serif;
        font-weight: 300;
        line-height: 1;
      }

      .japanese-container {
        @include tategaki;
      }
    }

    .heading-caption {
      @include tategaki;
      font-family: 'Noto Serif JP';
      width: 72px;
      font-size: 48px;
      margin: 50px 0 0;
      font-weight: 500;
      @include mq-down(lg) {
        font-size: 40px;
      }
      @include mq-down {
        width: 56px;
        font-size: calc(6.26vw + 8px);
        margin-top: calc(7.16vw + -22.9px);
      }
    }
  }

  // .fv-shape {
  //   margin-top: 64px;
  //   width: 20%;
  //   max-width: 200px;
  //   margin-right: 68px;
  //   min-width: 172px;

  //   @include mq-down(xl) {
  //     margin-top: 25px;
  //   }
  //   @include mq-down(lg) {
  //     max-width: 180px;
  //     margin-right: 16px;
  //     margin-top: 48px;
  //   }
  //   @include mq-down(md) {
  //     position: relative;
  //     z-index: 2;
  //     max-width: 37%;
  //     min-width: initial;
  //     width: 37%;
  //     margin-right: 0;
  //     margin-top: 20px;
  //     margin-left: 7.2%;
  //   }
  // }
  .fv-text-container {
    min-width: 360px;
    width: 37.5%;
    height: 100%;

    @include mq-down(md) {
      min-width: initial;
      width: initial;
      display: flex;
      flex-direction: column;
    }

    .fv-balloon {
      margin-top: 45px;
      margin-left: auto;
      width: 164px;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/fv_balloon.svg') center center no-repeat;
      background-size: contain;

      @include mq-down(lg) {
        margin-right: 32px;
        margin-bottom: 16px;
      }

      .balloon-text {
        font-size: 142%;
        line-height: 160%;
        font-weight: 500;
        margin-left: 16px;
      }
    }
    .fv-face-title {
      margin-top: -32px;
      margin-left: -6px;
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include mq-down(md) {
        flex-direction: column;
        margin-top: -24%;
        margin-left: 0;
        margin-right: 3%;
        align-self: flex-end;
      }

      .fv-face {
        @include sai_face(100px);

        @include mq-down(md) {
          @include sai_face(13.3vw);
          border-width: 3px;
          margin-right: 7%;
        }
      }
      .fv-service-title {
        text-align: left;
        margin-left: 14px;
        @include mq-down(md) {
          margin-left: initial;
        }
        .plus-title {
          font-size: 25px;
          line-height: 22px;
          color: #D780EB;
          font-weight: 500;
          @include mq-down(md) {
            font-size: calc(3.56vw + 0.6px);
            line-height: 0.8;
          }
          @include mq-down(sm) {
            font-size: 14px;
          }
        }
        .title-caption {
          font-size: 18px;
          line-height: 22px;
        }
      }
    }
    .fv-caption {
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      text-align: left;
      margin: 20px 16px 0;

      @include mq-down(md) {
        margin-top: calc(8.14vw + -12.5px);
        text-align: center;
        font-size: calc(3.56vw + 4.6px);
        line-height: initial;
      }
    }
    .btn-container {
      position: absolute;
      bottom: 76px;
      right: -20px;

      @include mq-down(xl) {
        right: 0;
      }

      @include mq-down {
        position: static;
      }
    }
  }
}

.cv-btn {
  font-size: 23px;
  line-height: 34px;
  font-weight: 500;
  height: initial;
  padding: 22px;
  border-radius: 200px;
  position: relative;
  align-self: stretch;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.44);

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.44);
  }

  * {
    color: white;
  }

  &::after {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    position: absolute;
    content: '';
    border: dotted 2px white;
    border-radius: 99px;
  }

  @include mq-down(md) {
    margin-top: 24px;
    font-size: calc(1.78vw + 10.3px);
    line-height: initial;
  }
}
.cv-btn-fv {
  @include mq-down(sm) {
    padding: 14px 22px;
  }
}

.fv-formula {
  margin-top: 16px;
  text-align: left;
  @mixin formula_text {
    display: inline;
    border-bottom: solid #424242 1px;
    font-size: 28px;
    padding-bottom: 4px;

    @include mq-down(sm) {
      font-size: calc(7.27vw + -3.3px);
    }
  }
  .text-1 {
    @include formula_text;
    font-weight: 400;
    @include mq-down(md) {
      color: #D780EB;
    }
  }
  .plus {
    font-weight: 700;
    font-size: 30px;
    @include mq-down(md) {
      color: #D780EB;
    }
  }
  .text-2 {
    @include formula_text;
    font-size: 25px;
    font-weight: 700;
    margin-left: 4px;
  }
}

.balloon-container {
  align-items: center;
  margin-bottom: 0;

  @include mq-down(md) {
    margin: 0 -5px;
  }
}
@mixin yellow_balloon {
  color: #424242;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;
  border-radius: 999px;
  position: relative;
  background-color: #FCEC76;
  font-size: 24px;
  line-height: 40px;
  min-height: 128px;
  z-index: 2;

  @include mq-down(sm) {
    padding: 32px 16px 24px;
    align-self: stretch;
  }
  &::after {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }
}

.yellow-balloon-column {
  @include yellow_balloon;
  margin-top: 31px;
  padding: 28px 48px;

  @include mq-down(md) {
    font-size: calc(2.05vw + 8.3px);
    line-height: calc(3.51vw + 13.1px);
  }

  @include mq-down(sm) {
    font-size: calc(5.45vw + -2.5px);
    line-height: calc(7.27vw + 0.7px);
  }
  &::after {
    background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/02_collaboration/yellow_tail.svg);
    height: 36.74px;
    width: 46.6px;
    left: 50%;
    top: -21px;
    transform: translate(-50%, 0);
  }
}

.purple-plus {
  position: absolute;
  top: -27%;
  right: -32%;
  width: 42%;
  height: 42%;
  background-image: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg');
  background-size: cover;
  background-position: center;
}

@mixin horizontal_dotted($weight: 2px, $color: #707070) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: $weight;
    bottom: 0;
    left: 0;
    background-image:
      linear-gradient(
        to right,
        $color,
        $color 4px,
        transparent 0px,
        transparent 0px
      );
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 8px $weight;
    border: none;
  }
}

.collaboration {
  @include container_base(980px);
  padding: 0 32px;
  overflow: visible;
  @include mq-down(xs) {
    padding: 0 16px;
  }
  .balloon-container {
    margin-top: 45px;
    @include mq-down(md) {
      margin-top: calc((12.72vw + 42.3px)/(-2));
    }
  }
  .face-with-balloon {
    @include sai_face(140px);
    position: relative;
    z-index: 20;
    @include mq-down(md) {
      @include sai_face(calc(12.72vw + 42.3px));
    }
  }
  .collaboration-title {
    margin-top: 72px;
    margin-bottom: 32px;
    @include horizontal_dotted(3px);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 16px;
    font-size: 120px;
    @include mq-down(lg) {
      font-size: 88px;
      justify-content: center;
    }
    @include mq-down(md) {
      font-size: calc(11.63vw + 38.8px);
      flex-direction: column;
      align-items: stretch;
      padding-bottom: 0;
      margin-bottom: 16px;
      &::after {
        display: none;
      }
    }

    .japanese-container {
      margin-right: 20px;
      line-height: 0.9;
      @include mq-down {
        margin-right: 0;
        margin-bottom: 8px;
      }

      .text-AI {
        font-size: 1em;
        @include mincho_font;
        margin: 0;
        vertical-align: middle;
      }
      .cross-icon {
        font-size: 0.96em;
        margin: 0 -5px;
        font-weight: 100;
        font-style: normal;
        font-family: 'Noto Sans JP';
        vertical-align: middle;
      }
      .japanese-illustrator {
        @include mincho_font;
        font-size: 0.84em;
        margin: 0;
        vertical-align: middle;
      }
    }
    .collaboration-subtitle {
      font-size: 0.5em;
      font-weight: 400;
      letter-spacing: -2px;
      margin-right: 16px;

      @include mq-down {
        font-size: 0.36em;
      }
    }
  }
  .collaboration-paragraph {
    font-size: 18px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

    @include mq-down(md) {
      font-size: 15px;
      line-height: 28px;
    }
  }
}

.service-flow {
  @include container_base(980px);

  * {
    color: #4E70A7;
  }

  .face-with-balloon {
    @include sai_face(140px);

    @include mq-down(xl) {
      @include sai_face(112px);
    }

    @include mq-down(sm) {
      @include sai_face(105px);
    }
  }

  .balloon-container {
    margin-top: 112px;
    margin-bottom: 16px;

    @include mq-down(md) {
      flex-direction: column;
    }

    .yellow-balloon-row {
      @include yellow_balloon;
      border-radius: 999px;
      padding: 28px 16px;
      margin-left: 30px;
      margin-top: -32px;
      &::after {
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/yellow_tail_side.svg);
        width: 48px;
        height: 44px;
        left: -17px;
        bottom: 0;
        transform: translate(0, -50%);
        z-index: -1;
      }
      @include mq-down(md) {
        text-align: center;
        margin: 20px 0 0;
        &::after {
          background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/yellow_tail_top.svg);
          width: 38.4px;
          height: 23.61px;
          left: 50%;
          top: -16px;
          transform: translate(-50%, 0);
        }
      }
    }
  }
  .horizontal-dotted {
    margin: 10px 0;
    height: 2px;
    background-image:
      linear-gradient(
        to right,
        #4E70A7,
        #4E70A7 5px,
        transparent 0px,
        transparent 0px
      );
    background-repeat: repeat-x;
    background-position: top;
    background-size: 9px 100%;
    border: none;
    @include mq-down {
      margin: 6px 0;
    }
  }
  .yellow-panel {
    background-color: #FCEC76;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    position: relative;

    @include mq-down(md) {
      flex-direction: column;
      align-items: center;
    }

    h3 {
      top: 0;
      left: 16px;
      position: absolute;
      margin: 0;
      white-space: nowrap;
      min-height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 36px;

      .panel-num {
        margin-right: 8px;
        font-size: 90px;
        font-family: 'FranklinGothic URW', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
        line-height: initial;
        font-weight: 500;
      }
      .step-title {
        font-size: 25px;
        font-weight: 500;
      }
      @include mq-down(md) {
        position: static;

        .panel-num {
          font-size: 40px;
          position: absolute;
          top: 4px;
          left: 6px;
        }
        .step-title {
          font-size: 18px;
          line-height: 1.2;
          white-space: normal;
        }
      }
    }
  }
  @mixin white_balloon {
    background-color: white;
    border-radius: 8px;
    position: relative;
    z-index: 3;

    &::after {
      content: '';
      z-index: -1;
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  .panel-1 {
    .panel-1-left {
      display: flex;
      flex-direction: column;
      max-width: 280px;
      align-self: center;
      margin-left: 190px;
      @include mq-down(cn) {
        margin-top: 96px;
        margin-left: 5%;
      }
      @include mq-down(md) {
        margin: 0;
        flex-direction: row;
        justify-content: center;
        align-self: stretch;
        align-items: center;
        max-width: initial;
        width: 100%;
        padding: 0 16px;
      }
      @include mq-down(sm) {
        justify-content: space-between;
      }

      .sai-and-pallet {
        margin-top: 5px;
        margin-left: 40px;
        width: 158px;
        align-self: center;
        @include mq-down(md) {
          max-width: 108px;
          width: 100px;
          margin: 0 24px 0 0;
        }
        @include mq-down(sm) {
          max-width: 31.3%;
          margin: 0;
        }
      }

      .white-balloon {
        @include white_balloon;
        margin-top: 12px;
        font-size: 24px;
        text-align: left;
        padding: 16px 16px;
        margin-bottom: 20px;
        &::after {
          transform: translate(-50%, 0);
          height: 55.72px;
          width: 94.77px;
          top: -32px;
          left: 50%;
          background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/white_tail.svg);
        }
        @include mq-down(md) {
          font-size: 15px;
          margin: 0;
          padding: 12px 8px;
          align-self: center;
          &::after {
            top: 50%;
            left: -19.11px;
            transform: translate(-12px, -50%) rotate(-90deg);
            width: 56.54px;
            height: 33.24px;
          }
        }
      }
    }
    .blue-arrow {
      transform: rotate(-90deg);
      max-width: 117px;
      min-width: 68px;

      @include mq-down(md) {
        transform: initial;
        max-width: initial;
        min-width: initial;
        width: 32px;
      }
    }
    .sc-made {
      max-width: 250px;
      width: 250px;
      margin-right: 64px;
      @include mq-down(md) {
        margin-right: 0;
        max-width: 140px;
        margin-bottom: 16px;
      }
    }
  }
  .panel-2 {
    overflow: hidden;
    .panel-2-left {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      margin-left: 160px;
      align-items: center;
      margin-top: 30px;
      @include mq-down(cn) {
        margin-top: 96px;
        margin-left: 5%;
      }

      @include mq-down(md) {
        margin-top: 8px;
        margin-left: 0;
        flex-direction: row;
      }

      .tablet-pic {
        width: 210px;
        border-radius: 10px;
        @include mq-down(md) {
          width: 118px;
          margin-left: 15px;
        }
      }
      .white-balloon {
        @include white_balloon;
        margin-top: 16px;
        font-size: 18px;
        text-align: left;
        padding: 16px 16px;
        margin-bottom: 20px;
        &::after {
          transform: translate(-50%, 0);
          height: 55.72px;
          width: 94.77px;
          top: -32px;
          left: 50%;
          background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/white_tail.svg);
        }
        @include mq-down(md) {
          font-size: 15px;
          margin: 0 10px;
          padding: 10px 5px;
          width: 60%;
          &::after {
            top: 50%;
            left: -19.11px;
            transform: translate(-12px, -50%) rotate(-90deg);
            width: 56.54px;
            height: 33.24px;
          }
        }
      }
    }
    .blue-arrow {
      transform: rotate(-90deg);
      max-width: 117px;
      min-width: 68px;

      @include mq-down(md) {
        transform: initial;
        max-width: initial;
        min-width: initial;
        width: 32px;
        margin: 16px 0;
      }
    }
    .lined-illust {
      margin-right: 32px;
      max-width: 320px;
      min-width: 230px;
      @include mq-down(cn) {
        margin-right: -16px;
      }
      @include mq-down(md) {
        margin: 0;
        max-width: initial;
        min-width: initial;
        width: 120%;
      }
    }
  }
  .arrow-down-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0 9px;

    .blue-arrow-down {
      max-width: 117px;
    }
    .two-weeks {
      font-weight: 500;
      font-size: 70px;
      text-align: left;
      font-family: 'FranklinGothic URW', 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
      margin-left: 45px;
      margin-bottom: 0;
    }
  }
  .panel-3 {
    justify-content: center;
    @include mq-down(md) {
      align-items: stretch;
    }

    .panel-3-container {
      display: flex;
      flex-direction: column;
      width: 500px;
      margin-left: 22px;
      margin-top: 96px;
      margin-bottom: 60px;
      @include mq-down(cn) {
        margin-top: 96px;
      }

      @include mq-down(md) {
        width: initial;
        margin: 0 20px;
      }

      .finished-product {
        max-height: 500px;
        background-color: white;

        @include mq-down(md) {
          height: calc(100vw - 104px);
        }
      }
      .white-balloon {
        @include white_balloon;
        margin-top: 40px;
        font-size: 50px;
        font-weight: 500;
        text-align: center;
        padding: 16px 16px;
        margin-bottom: 0;
        &::after {
          transform: translate(-50%, 0);
          height: 55.72px;
          width: 94.77px;
          top: -32px;
          left: 50%;
          background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/white_tail.svg);
        }
        @include mq-down(md) {
          font-size: 24px;
          padding: 0 16px;
          margin-top: 24px;
          &::after {
            width: 51.55px;
            height: 30.31px;
            top: -17.88px;
          }
        }
      }
    }
    .caption-text {
      font-size: 18px;
      margin: 18px 0 0;
      font-weight: 500;
      @include mq-down(md) {
        margin: 6px 16px 4px;
        font-size: 15px;
      }
    }
    .speak-container {
      position: absolute;
      right: 48px;
      bottom: 28%;
      display: flex;
      margin-top: 200px;
      align-self: center;
      margin-left: -70px;
      z-index: 5;

      @include mq-down(md) {
        position: static;
        margin: 32px 0 0;
        align-items: center;
      }
      .sai-speak {
        @include sai_face(140px);
        display: flex;
        @include mq-down(cn) {
          @include sai_face(calc(22.06vw + -89.4px));
        }
        @include mq-down(md) {
          @include sai_face(20vw);
          border-width: 4px;
        }
      }
      .speak-line {
        margin-left: 8px;
        width: 103px;
        background-image: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/speaking_line.svg');
        background-size: cover;
        display: flex;
        justify-content: left;
        align-items: center;
        text-align: left;
        overflow: visible;
        position: relative;

        @include mq-down(cn) {
          width: calc(12.13vw + -23.2px);
        }
        @include mq-down(md) {
          width: 74px;
          height: 98px;
          background-size: contain;
        }
        p {
          font-size: 25px;
          text-align: left;
          font-weight: 600;
          color: #424242;
          line-height: 1;
          margin-bottom: 0;
          margin-left: 4px;
          white-space: nowrap;
          @include mq-down(md) {
            margin-left: 4px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .lighten-yellow-panel {
    background-color: #FFFBE0;
    overflow: hidden;
    position: relative;
    margin-bottom: 54px;
    .step-title-option {
      font-size: 72px;
      text-align: left;
      font-weight: 500;
      line-height: 1;
      position: absolute;
      top: 0;
      left: 20px;
      margin: 0;
      @include mq-down(md) {
        font-size: 40px;
        left: 10px;
      }
    }
    .caption-text {
      font-size: 18px;
      margin: 25px 0 30px;
      font-weight: 500;
      padding: 0 256px;

      @include mq-down(cn) {
        margin-top: 96px;
        padding: 0;
      }
      @include mq-down(md) {
        margin-top: 64px;
        font-size: 15px;
      }
    }
    .option-title {
      height: 86px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 45px;
      p {
        font-size: 25px;
        font-weight: 500;
        margin: 0
      }
      @include mq-down(md) {
        margin-bottom: 25px;
      }
    }
    .emo-base {
      max-width: 140px;
      margin: auto;
      @include mq-down(sm) {
        max-width: 120px;
      }
    }
    .plus-icon {
      font-size: 104px;
      font-weight: bold;
      line-height: 1.2;
      @include mq-down(md) {
        line-height: .8;
      }
    }
    .emotion-three {
      display: flex;
      justify-content: center;
      align-items: center;
      .emo-pic {
        max-width: 104px;
        margin: 0 15px;
      }
      @include mq-down(md) {
        flex-wrap: wrap;
        justify-content: center;
        max-width: 440px;
        margin: auto;
        padding: 0 32px;
        .emo-pic {
          margin: 16px;
          max-width: 120px;
          width: 30%;
        }
      }
      @include mq-down(sm) {
        padding: 0 calc(14.55vw + -14.5px);
        .emo-pic {
          margin: 8px 8px;
        }
      }
    }
    .white-balloon-1 {
      @include white_balloon;
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      padding: 25px 16px;
      max-width: 600px;
      margin: 40px auto 58px;
      &::after {
        transform: translate(-50%, 0);
        height: 55.72px;
        width: 94.77px;
        top: -32px;
        left: 50%;
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/white_tail.svg);
      }

      @include mq-down(md) {
        margin: 24px 25px 38px;
        display: inline-block;
        padding: 8px 16px;
        &::after {
          width: 51.55px;
          height: 30.31px;
          top: -17.88px;
        }
      }
    }
    .white-balloon-2 {
      @extend .white-balloon-1;
      padding: 12px 16px;
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 32px;

      @include mq-down(md) {
        font-size: 15px;
        font-weight: 500;
      }
    }
  }
}

.feature {
  @include container_base;
  background-color: #FCEC76;
  padding-top: 48px;
  padding-bottom: 72px;
  @include mq-down(md) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .base-container {
    max-width: 780px;
  }

  .heading-underline {
    @include mincho_font;
    display: inline-block;
    position: relative;
    z-index: 1;
    font-size: 80px;
    font-weight: 500;

    &::after {
      content: '';
      background-color: white;
      width: 100%;
      height: 15px;
      position: absolute;
      left: 0;
      bottom: 12%;
      z-index: -1;
    }

    @include mq-down(md) {
      font-size: 64px;
    }
    @include mq-down(sm) {
      font-size: 40px;
    }
  }

  @mixin features_container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq-down(md) {
      display: block;
    }
  }

  .features-container-1 {
    @include features_container;
    .chara-pic {
      margin-left: -15%;
      @include mq-down(md) {
        width: 58%;
        margin: -48px -36px -112px auto;
      }
    }
  }
  .features-container-2 {
    @include features_container;
    flex-direction: row-reverse;
    .chara-pic {
      margin-right: -5%;
      @include mq-down(md) {
        width: 46%;
        margin: 28px 32px -120px auto;
      }
    }
  }
  .features-container-3 {
    @include features_container;
    .chara-pic {
      margin-right: 15%;
      @include mq-down(md) {
        width: 30%;
        margin: 0 32px -112px auto;
      }
    }
  }

  .feature-text-container {
    width: 418px;
    @include mq-down(md) {
      width: initial;
    }

    .features-num {
      margin-right: auto;
      width: 46px;
      @include mq-down(md) {
        width: 56px;
      }
    }
    .features-title {
      font-size: 50px;
      @include mincho_font;
      text-align: left;
      @include mq-down(md) {
        font-size: 40px;
      }
    }
    .features-description {
      margin-bottom: 0;
      text-align: left;
      font-size: 24px;
      @include mq-down(md) {
        font-size: 15px;
      }
    }
  }
}

.onlyone {
  @include container_base;
  padding: 0 128px;
  .base-container {
    max-width: 980px;
  }
  .cv-btn-1 {
    @extend .cv-btn;
    font-size: 33px;
    margin: 68px auto 0;
    padding: 40px 56px;
    max-width: 680px;
    &::after {
      height: calc(100% - 18px);
      width: calc(100% - 18px);
      border-style: dashed;
    }
     @include mq-down(lg) {
       font-size: calc(3.02vw + 2.8px);
     }
    @include mq-down(md) {
      max-width: 380px;
      font-size: 20px;
      padding: 24px 32px;
      display: flex;
      margin-top: 32px;
      &::after {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        border-style: dotted;
      }
    }
  }

  .onlyone-chara-container {
    display: flex;
    justify-content: space-between;
    margin-top: 118px;
    @include mq-down(md) {
      margin-top: 48px;
      justify-content: center;
    }
  }
  .onlyone-chara {
    margin-top: 10px;
    margin-right: -112px;
    margin-left: -96px;
    position: relative;
    z-index: 10;

    @include mq-down(xl) {
      margin-right: -88px;
    }
    @include mq-down(md) {
      margin-left: -20%;
      margin-right: -5%;
      max-width: 370px;
    }
    @include mq-down(sm) {
      margin-left: -100px;
      margin-right: calc(-28.85vw + 32.3px);
    }
  }
  .onlyone-right-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 80px;
    @include mq-down(xl) {
      padding-bottom: 48px;
    }
    @include mq-down(md) {
      padding-bottom: 8px;
    }
  }
  .onlyone-balloon {
    @include mincho_font;
    max-width: 512px;
    min-height: 440px;
    padding: 15%;
    margin: 0;
    margin-left: -8px;
    background-color: #FCEC76;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    line-height: initial;
    font-weight: 700;
    border-radius: 100%;
    position: relative;
    z-index: 1;

    @include mq-down(xl) {
      min-height: 360px;
      max-width: 360px;
      font-size: 28px;
      align-self: center;
    }

    @include mq-down(md) {
      min-height: initial;
      max-width: 250px;
      margin: 0;
      margin-right: -60px;
      margin-left: 16px;
      font-size: 22px;
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/yellow_tail_side.svg);
      background-repeat: no-repeat;
      height: 40%;
      width: 40%;
      left: 0%;
      top: 2%;
      transform: translate(0, 0%) rotate(60deg);
      z-index: -1;
    }
    .onlyone-balloon-inside {
      display: inline-block;
    }
  }
  .cv-btn-2 {
    @extend .cv-btn;
    font-size: 28px;
    padding: 32px 24px;
    &::after {
      height: calc(100% - 12px);
      width: calc(100% - 12px);
      border-style: dashed;
    }
    @include mq-down(xl) {
      font-size: calc(2.78vw + -5.3px);
      padding: calc(3.7vw + -12.4px) calc(4.63vw + -15.6px);
      width: initial;
    }
  }

  .face-title {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    align-self: center;
    margin-left: 30%;
    z-index: 12;

    @include mq-down(sm) {
      margin-top: 16px;
    }

    .face {
      @include sai_face(16vw);
      border-width: 4px;

      @include mq-down(xl) {
        @include sai_face(11.2vw);
      }

      @include mq-down(sm) {
        @include sai_face(calc(25.45vw + -25.5px));
      }
    }
    .plus-title {
      font-size: 20px;
      line-height: 0.8;
      color: #D780EB;
      font-weight: 500;
      margin-bottom: 4px;
      margin-left: 16px;
      text-align: left;
      align-self: flex-start;
    }
    .plus-caption {
      font-size: 14px;
      margin-left: 16px;
      text-align: left;
      align-self: flex-start;
      margin-bottom: 0;
    }
  }
}

.price {
  @include container_base(980px);
  padding: 0 30px 135px;
  @include mq-down(md) {
    padding-bottom: 40px;
  }
  * {
    color: #4E70A7;
  }

  p {margin-bottom: 0;}
  .price-title {
    margin-left: -30px;
    margin-bottom: 0;
    font-size: 90px;
    text-align: left;
    font-weight: 500;
    @include franklin_font;
    @include mq-down(md) {
      font-size: 40px;
      margin-top: 16px;
      margin-left: 0;
    }
  }
  .price-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mq-down(md) {
      justify-content: space-around;
    }
    @include mq-down(sm) {
      justify-content: space-between;
    }

    .price-formula-container {
      display: flex;
      justify-content: space-between;

      @include mq-down(md) {
        flex-direction: column;
        align-items: stretch;
        max-width: 40%;
        min-width: 40%;
      }
      @include mq-down(sm) {
        max-width: 50%;
        min-width: 50%;
      }
    }

    .price-plus-element {
      width: 300px;
      min-height: 120px;
      font-size: 24px;
      font-weight: 500;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      border: 1px solid #4E70A7;
      border-radius: 20px;
      background-color: #FCEC76;
      @include mq-down(cn) {
        width: calc(44.12vw + -158.8px);
        min-height: calc(8.82vw + 28.2px);
        font-size: calc(1.47vw + 8.7px);
      }
      @include mq-down(md) {
        width: initial;
        min-height: 64px;
        font-size: 18px;
      }
      @include mq-down(sm) {
        font-size: calc(3.64vw + 4.4px);
      }
    }
    .price-formula-plus {
      font-size: 80px;
      font-weight: 600;
      margin: 0 10px;
      @include mq-down(cn) {
        font-size: calc(5.88vw + 18.8px);
        line-height: 1.3;
      }
      @include mq-down(md) {
        font-size: 42px;
        line-height: 1;
      }
    }
    .price-result-container {
      display: flex;
      flex-direction: column;
      position: relative;
      @include mq-down(md) {
        margin-right: 8px;
      }
      @include mq-down(sm) {
        margin-right: -8px;
      }

      .price-result-caption {
        font-size: 18px;
        font-weight: 500;
        align-self: flex-end;
        margin-bottom: -16px;
        margin-right: 24px;
        @include mq-down(cn) {
          font-size: calc(0.74vw + 10.4px);
        }
        @include mq-down(md) {
          font-size: 15;
          margin: 0;
          position: absolute;
          right: 0;
          top: -28px;
        }
        @include mq-down(sm) {
          top: calc(-14.55vw + 26.5px);
          font-size: calc(5.45vw + -5.5px);
        }
      }
      .price-result-bottom {
        display: flex;
        align-items: center;

        .price-arrow {
          width: 72px;
          margin-right: 8px;
          @include mq-down(cn) {
            width: calc(8.82vw + -19.8px);
            margin-right: calc(-5.88vw + 69.2px);
          }
          @include mq-down(md) {
            width: 48px;
            margin-right: 8px;
          }
          @include mq-down(sm) {
            width: calc(14.55vw + -14.5px);
            margin-right: calc(7.27vw + -15.3px);
          }
        }
        .price-result-price {
          max-width: 215px;
          font-size: 50px;
          font-weight: 600;
          margin-top: 12px;
          line-height: 1;
          @include mq-down(cn) {
            font-size: calc(2.21vw + 27.1px);
          }
          @include mq-down(md) {
            font-size: 32px;
          }
          @include mq-down(sm) {
            font-size: calc(7.27vw + -3.3px);
          }
        }
      }
    }
  }
  .price-option-title {
    @include franklin_font;
    text-align: left;
    font-size: 50px;
    font-weight: 500;
    margin: 74px 0 29px;
    line-height: 1;
    @include mq-down(md) {
      font-size: 24px;
      margin: 36px 0 12px;
    }
  }
  .price-option-formula {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 16px;
    @include mq-down(md) {
      font-size: 15px;
    }

    .price-option-formula-list {
      margin-left: 110px;
      margin-bottom: 2px;
      @include mq-down(cn) {
        margin-left: calc(31.62vw + -218.8px);
      }
      @include mq-down(md) {
        margin-left: calc(27.99vw + -119px);
        margin-right: calc(27.99vw + -119px);
      }
      @include mq-down(sm) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .price-option-formula-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include mq-down(sm) {
        justify-content: space-between;
      }
      .price-option-formula-text {
        text-align: left;
        @include mq-down(md) {
          max-width: 240px;
          font-size: calc(1.17vw + 11px);
        }
        @include mq-down(sm) {
          max-width: calc(40vw + 12px);
          font-size: calc(1.82vw + 8.2px);
        }
      }

      .price-option-arrow {
        max-width: 64px;
        min-width: 64px;
        margin-right: 36px;
        margin-left: auto;
        @include mq-down(cn) {
          max-width: calc(11.76vw + -58.4px);
          min-width: calc(11.76vw + -58.4px);
          margin-right: calc(6.62vw + -32.8px);
        }
        @include mq-down(md) {
          max-width: 40px;
          min-width: 40px;
          margin-right: 12px;
        }
        @include mq-down(sm) {
          max-width: calc(14.55vw + -22.5px);
          min-width: calc(14.55vw + -22.5px);
          margin-right: 8px;
        }
      }
      .price-option-result {
        font-size: 45px;
        font-weight: 500;
        @include mq-down(cn) {
          font-size: calc(4.78vw + -4.7px);
        }
        @include mq-down(md) {
          font-size: 32px;
          margin-right: -8px;
        }
        @include mq-down(sm) {
          font-size: calc(10.91vw + -16.9px);
          margin-right: -8px;
        }
      }
    }
  }
  .price-description {
    font-size: 16px;
    text-align: left;
    line-height: 1.8;
    @include mq-down(md) {
      letter-spacing: 0.42px;
      font-size: 14px;
    }
  }
}
.usecase {
  @include container_base(986px);
  background-color: #E2BBEB;
  position: relative;
  padding: 160px 30px 158px;
  overflow: hidden;
  @include mq-down(xl) {
    padding-top: 96px;
  }
  @include mq-down(md) {
    padding-bottom: 40px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .usecase-title {
    font-size: 244px;
    @include mq-down(lg) {
      font-size: 180px;
    }
    @include mq-down {
      font-size: calc(22.45vw + -12.2px);
    }

    .usecase-charas {
      width: 100%;
      overflow: visible;
      position: relative;

      @mixin side_chara($src) {
        content: '';
        background-image: url($src);
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        background-size: contain;
      }

      @include mq-up(xl) {
        &::before {
          @include side_chara('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_jk.webp');
          width: 450px;
          height: 450px;
          top: -154px;
          left: -360px;
          transform: rotate(80deg)
        }
        &::after {
          @include side_chara('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_suits.webp');
          width: 256px;
          height: 512px;
          top: 112px;
          right: -232px;
        }
      }
    }
    .japanese-container {
      line-height: 0.9;

      .text-AI {
        font-size: 1em;
        @include mincho_font;
        margin: 0;
        vertical-align: middle;
      }
      .cross-icon {
        font-size: 0.96em;
        margin: 0 -5px 20px;
        font-weight: 100;
        font-style: normal;
        font-family: 'Noto Sans JP';
        vertical-align: middle;
      }
      .japanese-illustrator {
        font-size: 0.84em;
        @include mincho_font;
        margin: 0;
        vertical-align: middle;
      }
    }
    .usecase-title-caption {
      font-size: 0.15em;
      @include mincho_font;
      font-family: 'Noto Serif JP';
      margin-top: 24px;
      letter-spacing: 2.4px;
      font-weight: 600;
      @include mq-down(md) {
        font-size: 32px;
      }
      @include mq-down(sm) {
        font-size: 0.32em;
      }
    }
  }
  .usecase-dotted {
    margin-top: 20px;
    height: 3px;
    background-image:
      linear-gradient(
        to right,
        #424242,
        #424242 4px,
        transparent 0px,
        transparent 0px
      );
    background-repeat: repeat-x;
    background-position: top;
    background-size: 8px 100%;
    border: none;
  }
  .usacase-main-description {
    margin-top: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    font-size: 18px;
    line-height: 1.8;
    font-weight: 400;
    @include mq-down(md) {
      font-size: 16px;
    }
    @include mq-down(sm) {
      font-size: 15px;
    }
  }
  .usecase-base-container {
    max-width: 1064px;
    margin-left: auto;
    margin-right: auto;
  }
  .usecase-subtitle {
    @include mincho_font;
    margin-top: 80px;
    margin-bottom: 0;
    font-size: 60px;
    text-align: center;
    font-weight: 700;
    @include mq-down(md) {
      font-size: 52px;
    }
    @include mq-down(sm) {
      font-size: calc(7.69vw + 7.4px);
    }
  }
  .usecase-flex-container {
    margin-top: 72px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-content: flex-start;

    @mixin usecase_item {
      margin: 0 17px 50px;
      display: flex;
      flex-direction: column;
      width: 320px;

      @include mq-down(sm) {
        margin: 0 0 28px;
        width: initial;
      }

      .usecase-image-container {
        width: 100%;
        height: 270px;
        position: relative;
        border-radius: 32px;
        background-color: white;
        overflow: hidden;

        @include mq-down(sm) {
          width: 250px;
          height: 210px;
          align-self: center;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 10px solid #FCEC76;
          border-radius: 30px;
          z-index: 10;
          @include mq-down(sm) {
            border-width: 8px;
          }
        }

        .usecase-image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
      .usecase-heading {
        margin-top: 20px;
        font-size: 24px;
        margin-bottom: 0;
        font-weight: 500;
        text-align: left;

        @include mq-down(sm) {
          margin-top: 12px;
          text-align: center;
        }
      }
      .usecase-item-description {
        margin-top: 20px;
        font-size: 16px;
        text-align: left;
        line-height: 1.8;
        @include mq-down(sm) {
          margin-top: 12px;
          font-size: 15px;
        }
      }
    }

    .usecase-item-1 {
      @include usecase_item;
    }
    .usecase-item-2 {
      @include usecase_item;
    }
    .usecase-item-3 {
      @include usecase_item;

      .usecase-heading {
        padding-right: 112px;
      }

      @include mq-down(sm) {
        .usecase-heading {
          text-align: right;
          padding-right: calc(50vw + -64px);
          .usecase-heading-span {
            text-align: right;
          }
        }
      }
      .usecase-image-container {
        overflow: visible;
        .usecase-image {
          top: 5%;
          left: 5%;
          width: 80%;
          height: 125%;
          z-index: 12;

          @include mq-down(sm) {
            top: 5%;
            left: 5%;
            width: 88%;
            height: 123%;
          }
        }
      }
    }
    .usecase-item-4 {
      @include usecase_item;
      .usecase-image-container {
        .usecase-image {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%)
        }
      }
    }
    .usecase-item-5 {
      @include usecase_item;
    }
    .usecase-item-6 {
      @include usecase_item;
      .usecase-image-container {
        overflow: visible;
        .usecase-image {
          top: 55%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 108%;
          height: 108%;
          z-index: 12;
        }
      }
    }
  }

  @include mq-down(md) {
    margin-top: 24px;
    font-size: calc(1.78vw + 10.3px);
    line-height: initial;
  }
  .usecase-collaboration-btn {
    position: relative;
    height: initial;
    line-height: 34px;
    font-weight: 500;
    font-size: 33px;
    margin: 68px auto 0;
    padding: 40px 56px;
    border-radius: 200px;
    min-width: 700px;

    /deep/ .v-btn__content {
      white-space: normal;
    }

    &:hover, &:active, &:focus {
      text-decoration: none;
    }

    &::after {
      height: calc(100% - 18px);
      width: calc(100% - 18px);
      position: absolute;
      content: '';
      border: dashed 2px white;
      border-radius: 99px;
    }

    @include mq-down(lg) {
      font-size: 22px;
      padding: 24px 48px;
      display: inline-flex !important;
    }
    @include mq-down(md) {
      min-width: 250px;
      padding: 24px 32px;
      display: flex;
      margin-top: 32px;
      &::after {
        height: calc(100% - 10px);
        width: calc(100% - 10px);
        border-style: dotted;
      }
    }
    @include mq-down(sm) {
      font-size: calc(3.85vw + 3.7px);
    }
  }
}

</style>

<i18n src="@/locales/sai_chan/plus/top.json"></i18n>
