<template>
  <v-dialog v-model="showDialog" max-width="900" persistent scrollable>
    <v-card class="form-card-container">
      <v-card-text class="scroll-area" id="scroll-target">
        <div class="close-button-container" v-if="currentProgress === PROGRESS_TYPE.PREPARING">
          <v-btn @click="close()" large fab flat>
            <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
          </v-btn>
        </div>

        <!-- ファイル名 + サムネイル -->
        <p class="uploaded-text">{{ $t("fr_confirm1") }}</p>
        <div v-if="targetImageFile" class="uploaded-item">
          <p>{{ targetImageFile.name }}</p>
          <img v-if="targetImageSrc" :src="targetImageSrc" class="uploaded-thumbnail"/>
        </div>

        <!-- 変換中 -->
        <template v-if="currentProgress === PROGRESS_TYPE.PROCESSING">
          <div class="progress-bar-container">
            <v-progress-linear height="15" class="mt-4" color="#EDD4F7" :indeterminate="true"></v-progress-linear>
          </div>
          <p class="processing">{{ $t("fr_confirm4") }}</p>
          <!-- 広告 -->
          <ad-inference></ad-inference>
        </template>

        <!-- 大きい画像 -->
        <img v-if="targetImageSrc" :src="targetImageSrc" class="uploaded-img" :class="{ 'horizontally-long': isHorizontallyLong }" />

        <!-- 高画質化ボタン -->
        <template v-if="currentProgress === PROGRESS_TYPE.PREPARING">
          <v-btn slot="activator" class="draw-btn" dark round large block @click="draw()">
            {{ $t("fr_confirm2") }}
          </v-btn>
          <p class="caution">{{ $t("fr_confirm3") }}</p>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import AdInference from '@/components/GoogleAdsense/AdInference.vue'

// この順番で処理が進む
const PROGRESS_TYPE = {
  PREPARING: 1,
  PROCESSING: 2,
  // 処理が終わったら終わったら結果ページに遷移
}
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: {
    AdInference
  },
  data() {
    return {
      PROGRESS_TYPE: PROGRESS_TYPE,
      currentProgress: PROGRESS_TYPE.PREPARING,
    }
  },
  computed: {
    ...mapGetters("faceRefiner", [
      "targetImageFile",
      "targetImageSrc",
      "isHorizontallyLong"
    ]),
    // <v-dialog v-model="show" とするとdialogを閉じるときに `Avoid mutating a prop directly`
    // が発生するのでクッションとして別propertyを挟む
    showDialog: {
      get() {
        return this.show
      },
      set(/*newVal*/) {
        // dialogを閉じるのはclose()で親に伝えるのでここでは何もしない
      }
    },
  },
  methods: {
    close() {
      this.currentProgress = PROGRESS_TYPE.PREPARING
      this.$emit("close")
    },
    draw() {
      this.$store.dispatch('faceRefiner/draw', this.$i18n.locale)
      this.currentProgress = PROGRESS_TYPE.PROCESSING
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/face_refiner/_variables.scss";
@import "../../assets/scss/sync_process_job/order_modal_container.scss";
</style>
<i18n src="../../locales/face_refiner/order_modal_container.json"></i18n>
