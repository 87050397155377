<template>
  <v-container class="ca-card-container">
    <!-- 選択した画像 -->
    <p class="display-pc uploaded-text">{{ $t("glb_ep_0") }}</p>
    <div v-if="targetImageFile" class="display-pc uploaded-item">
      <p>{{ targetImageFile.name }}</p>
      <img v-if="targetImageSrc" contain :src="targetImageSrc" class="target-img-small"/>
    </div>
    <template v-if="notifyWhenComplete">
      <!-- 混み合っています -->
      <div class="crowded-container">
        <p class="crowded-message" v-html="$t('glb_ep_20', [estimatedMinutes])"></p>
        <!-- router-link は今と同じページ(/ep)への移動(reload)はできないので使わない -->
        <v-btn dark round outline color="#93562F" class="mr-5" @click="moveTop()">{{ $t("glb_ep_14") }}</v-btn>
      </div>
    </template>
    <template v-else>
      <!-- 変換しています -->
      <div class="processing-balloon">
        <p>{{ $t("glb_ep_1") }}</p>
      </div>
      <div class="progress-bar-container">
        <v-progress-linear height="8" class="mt-4" color="#FFAB38" :indeterminate="true"></v-progress-linear>
      </div>
      <!-- 広告 -->
      <ad-inference></ad-inference>
      <div v-if="targetImageSrc">
        <v-img v-if="targetImageSrc" contain :src="targetImageSrc" class="target-img-large"></v-img>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AdInference from '@/components/GoogleAdsense/AdInference.vue'

export default {
  components: {
    AdInference
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters("enpainter", [
      "targetImageFile",
      "targetImageSrc",
      "transferCompleted",
      "requestId",
      "notifyWhenComplete",
      "estimatedMinutes"
    ]),
  },
  methods: {
    moveTop() {
      this.$router.go() // 今がtopページにmodalを表示している状態なのでreloadする
    }
  },
  watch: {
    transferCompleted(completed) {
      if (completed) {
        this.$emit('transferCompleted')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
// ブラウザのdefault style reset
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
button {
  border: none;
}

.ca-card-container {
  //max-width: 790px;
  margin: 0;
  padding: 0;
  max-width: 900px;
  height: 584px;
  position: relative;
  padding-top: 45px;;

  @include mq-down() {
    width: 100%;
    height: unset;
  }

  .uploaded-text {
    font-size: 20px;
    line-height: 29px;
    font-weight: 400;
    //font: Regular 20px/50px Noto Nastaliq Urdu;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 5px;

    @include mq-down(md) {
      margin-top: 0;
      font-size: 15px;
    }
  }
}

.uploaded-item {
  height: 80px;
  margin-left: 75px;
  margin-right: 75px;
  background-color: #FFAB38;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq-down {
    flex-direction: column;
    height: 130px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
  }
  p {
    margin-bottom: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    line-height: 48px;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #424242;

    @include mq-down {
      left: 0;
      padding: 0 16px;
      margin: 0;
      font-size: 16px;
      max-width: 100%;
      line-height: 23px;
      margin-top: 16px;
    }
  }
  .target-img-small {
    position: absolute;
    top: 10px;
    right: 17px;
    height: 60px;

    @include mq-down {
      position: static;
      padding: 0;
      margin-top: 15px;
      max-height: 60px;
    }
  }
}
.crowded-container {
  width: 682px;
  margin: 32px auto;
  background-color: #fff;
  border-radius: 4px;
  padding: 32px 12px;
  .crowded-message {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #424242;
    margin-bottom: 24px;
    @include mq-down(md) {
      margin-top: 0;
      font-size: 14px;
      line-height: 26px;
    }
  }
  @include mq-down() {
    width: 100%;
  }
  // 以下topへのリンクボタン用
  button {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  a {
    text-decoration: none;
  }
}
.processing-balloon {
  position: relative;
  width: 622px;
  min-height: 45px;
  margin-top: 32px;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 4px;
  padding-top: 9.7px;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top: 24px solid #fff;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    color: #424242;
  }
  @include mq-down() {
    width: 100%;
    padding-bottom: 16px;
  }
}
.target-img-large {
  //max-width: 300px;
  max-height: 225px;
  margin: 32px auto 21px auto;

  @include mq-down {
    padding: 0;
    margin: 22px auto 22px auto;
    max-width: calc(100% - 44px);
    max-height: 200px;
  }
}
.progress-bar-container {
  margin-top: 48px;
  @include mq-down {
    margin-top: 89px;
    margin-bottom: 36px;
  }
}

</style>
