<template>
  <div v-if="isAdsenseTarget" align="center" class="ad-container py-5">
    <!-- cre8tiveAI_responsive -->
    <Adsense
      data-ad-client="ca-pub-6827265287349767"
      data-ad-slot="2243187282"
      data-ad-format="auto"
      data-full-width-responsive="yes">
    </Adsense>
  </div>
</template>

<script>
export default {
  name: 'AdResponsive',
  data () {
    return {
    }
  },
  computed: {
    isAdsenseTarget() {
      // prerendering処理時は表示しない
      if (window['langForPrerendering']) {
        return false;
      }
      // 非ログインの場合は広告を表示する
      if (!this.$store.getters['user/logined']) {
        return true
      }
      // エントリープランの場合は広告を表示する
      if (this.currentGroup && this.currentSubscription.grouping_id === 1) {
        return true
      }
      // 有料プランの場合は広告を表示しない
      return false
    },
    currentGroup() {
      return this.$store.getters['user/currentGroup']
    },
    currentSubscription() {
      return this.$store.getters['master/subscriptionById'](this.currentGroup.subscription_id)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.ad-container {
}
</style>
