<template>
  <div v-if="show">
      <face-order-modal
        :showDialog="currentProgress === PROGRESS_TYPE.FACE"
        @faceOrderEdited="faceOrderEdited"
        @close="close()"
      ></face-order-modal>
      <cloth-order-modal
        :showDialog="currentProgress === PROGRESS_TYPE.CLOTH"
        @back="back"
        @clothOrderEdited="clothOrderEdited"
        @close="close()"
      ></cloth-order-modal>
      <pose-order-modal
        :showDialog="currentProgress === PROGRESS_TYPE.POSE"
        @back="back"
        @poseOrderEdited="poseOrderEdited"
        @close="close()"
      ></pose-order-modal>
      <expression-order-modal
        :showDialog="currentProgress === PROGRESS_TYPE.EXPRESSION"
        @back="back"
        @expressionOrderEdited="expressionOrderEdited"
        @close="close()"
      ></expression-order-modal>
      <high-resolution-order-modal
        :showDialog="currentProgress === PROGRESS_TYPE.HIGHRESOLUTION"
        @back="back"
        @highResolutionOrderEdited="highResolutionOrderEdited"
        @close="close()"
      ></high-resolution-order-modal>
      <other-text-modal
        :showDialog="currentProgress === PROGRESS_TYPE.OTHER"
        @back="back"
        @otherTextEdited="otherTextEdited"
        @close="close()"
      ></other-text-modal>
      <precautions-modal
        :showDialog="currentProgress === PROGRESS_TYPE.PRECAUTIONS"
        @back="back"
        @precautionsChecked="precautionsChecked"
        @close="close()"
      ></precautions-modal>
      <confirm-modal
        :showDialog="currentProgress === PROGRESS_TYPE.CONFIRM"
        @back="back"
        @confirmed="confirmed"
        @close="close()"
      ></confirm-modal>
      <payment
        ref="payment"
        :showDialog="currentProgress === PROGRESS_TYPE.PAYMENT"
        @cancel="back"
        @completed="completed"
        @close="close()"
      ></payment>
  </div>
</template>

<script>
import router from '../../../router.js'
import FaceOrderModal from "./FaceOrderModal.vue"
import ClothOrderModal from "./ClothOrderModal.vue"
import PoseOrderModal from "./PoseOrderModal.vue"
import ExpressionOrderModal from "./ExpressionOrderModal.vue"
import HighResolutionOrderModal from "./HighResolutionOrderModal.vue"
import OtherTextModal from "./OtherTextModal.vue"
import PrecautionsModal from "./PrecautionsModal.vue"
import ConfirmModal from "./ConfirmModal.vue"
import Payment from "./Payment.vue"

// この順番で処理が進む
const PROGRESS_TYPE = {
  FACE: 1,
  CLOTH: 2,
  POSE: 3,
  EXPRESSION: 4,
  HIGHRESOLUTION: 5,
  OTHER: 6,
  PRECAUTIONS: 7,
  CONFIRM: 8,
  PAYMENT: 9,
  // payment完了後は別ページに遷移
}
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: {
    FaceOrderModal,
    ClothOrderModal,
    PoseOrderModal,
    ExpressionOrderModal,
    HighResolutionOrderModal,
    OtherTextModal,
    PrecautionsModal,
    ConfirmModal,
    Payment
  },
  data() {
    return {
      currentProgress: PROGRESS_TYPE.FACE,
      PROGRESS_TYPE: PROGRESS_TYPE,
    }
  },
  methods: {
    setup() {
      this.currentProgress = PROGRESS_TYPE.FACE
      this.$store.commit('saichanPlus/reset')
    },
    faceOrderEdited(shouldAdjust) {
      this.$store.commit('saichanPlus/setFaceAdjust', shouldAdjust)
      this.currentProgress++
    },
    back() {
      this.currentProgress--
    },
    clothOrderEdited(payload) {
      this.$store.commit('saichanPlus/setCloth', payload)
      this.currentProgress++
    },
    poseOrderEdited(payload) {
      this.$store.commit('saichanPlus/setPose', payload)
      this.currentProgress++
    },
    expressionOrderEdited(payload) {
      this.$store.commit('saichanPlus/setExpression', payload)
      this.currentProgress++
    },
    highResolutionOrderEdited(willOrder) {
      this.$store.commit('saichanPlus/setHighResolution', willOrder)
      this.currentProgress++
    },
    otherTextEdited(text) {
      this.$store.commit('saichanPlus/setOtherText', text)
      this.currentProgress++
    },
    precautionsChecked() {
      this.currentProgress++
    },
    confirmed() {
      this.currentProgress++
      this.$refs.payment.judgeAndOpenPaymentModal()
    },
    completed() {
      router.push({name: 'scPlusOrderCompleted', params: {fromSaichanPlusOrderPage: true}})
    },
    close() {
      this.$emit("close")
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
</style>