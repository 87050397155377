import Cookies from 'js-cookie'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { SUBDOMAIN_LIST } from './settings'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales/common', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

// NOTE. サブドメインからlocaleを判定し、ログイン等Auth0でホストしているページ用にcookieに保存
function judgeLocale() {
  let judgedLocale = 'en'
  let topLevelDomain = location.hostname
  for (const subdomain of SUBDOMAIN_LIST) {
    if (location.hostname.startsWith(subdomain + '.')) {
      judgedLocale = subdomain
      topLevelDomain = topLevelDomain.replace(subdomain + '.', '')
      break
    }
  }
  // prerendering処理時はvue.config.jsでsetしたlangに強制的に差し替え
  if (window['langForPrerendering']) {
    judgedLocale = window['langForPrerendering']
  }

  // loginページでの言語判定、Auth0メールからの着地にて使う (サブドメインにもcookieで共有したい)
  if (!location.search.includes('redirectByLocale')) {
    Cookies.set('locale', judgedLocale, { domain: topLevelDomain, expires: 365 })
  }
  return judgedLocale
}

export default new VueI18n({
  locale: judgeLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true
})
