<template>
  <div>
    <h2 :class=pluginNameClass>{{ pluginName }}</h2>
    <div :class=powerdByClass>
      <p>{{ powerdBy }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pluginName: String,
    powerdBy: String,
    pluginNameClass: {
      type: String,
      default: 'plugin-name'
    },
    powerdByClass: {
      type: String,
      default: 'powerd-company'
    },
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_anime4k.scss";

h2 {
  @include adobe-font(ubuntu, medium);
  font-size: 50px;
  @include mq-down(lg) {
    font-size: 42px;
  }
  @include mq-down(sm) {
    font-size: 40px;
  }
  @include anime4k-line-height-responsive(59);
  color: $anime4k-color-green;
  margin: 0px;
  text-align: left;
  @include vf-mq-down(sm) {
    text-align: center;
  }
}
.plugin-name {
  margin-top: 48px;
  @include vf-mq-down(sm) {
    margin-top: 0px;
  }
}
.powerd-company {
  color: $anime4k-color-gray;
  text-align: left;
  @include vf-mq-down(sm) {
    text-align: center;
  }
  @include adobe-font(futura-pt, medium);
  p {
    margin-bottom: 0px;
    font-size: 23px;
    @include vf-mq-down(sm) {
      font-size: 20px;
    }
  }
}
.plugin-name-home {
  @extend .plugin-name;
  font-size: 40px;
  @include mq-down(md) {
    font-size: 32px;
  }
  @include mq-down(sm) {
    font-size: 26px;
  }
  margin-top: 0;
  text-align: center;
}
.powerd-company-home {
  @extend .powerd-company;
  color: #262D40;
  text-align: center;
  p {
    @include mq-down(sm) {
      font-size: 16px;
    }
  }
}
</style>
