<template>
  <v-container class="ca-card-container">
    <div class="please-select-balloon">
      <p>{{ $t("st2_1") }}</p>
    </div>
    <!-- ファイル選択/dropエリア -->
    <div class="drop_area width-mv">
      <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area-inner" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
        <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
        <p class="white--text display-pc">{{ $t("st2_2") }}</p>
        <v-btn class="text-none drop_area-button" round outline dark @click="openFileSelect">
          {{ $t("st2_3") }}
        </v-btn>
        <input ref="inputFile" type="file" id="target_image" name="target_image" accept=".jpg, .jpeg, .png" @change="onSelectFile"/>
      </v-layout>

      <v-layout align-center justify-center column fill-height v-else class="drop_area-inner">
        <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
        <v-btn class="text-none drop_area-button" @click="auth.login('login')" round outline dark>
          {{ $t("st2_7") }}
        </v-btn>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ['auth'],
  components: {},
  data() {
    return {
      
    }
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    guestExec() {
      return this.$store.getters['user/guestExec']
    },
  },
  methods: {
    onDropFile(e) {
      if (!this.logined && !this.guestExec) return
      const files = []
      // FileList型はforEachできないためforで回す
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i]
        files.push(file)
      }
      this.$store.commit('enpainter/setTargetImageFile', files)
      this.$emit('fileSelected')
    },
    openFileSelect() {
      this.$refs.inputFile.click()
    },
    onSelectFile(e) {
      const files = []
      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        files.push(file)
      }
      this.$store.commit('enpainter/setTargetImageFile', files)
      this.$emit('fileSelected')
    },
  },
}
</script>

<style lang='scss' scoped>
@import '../../assets/scss/_variables.scss';

.ca-card-container {
  max-width: 790px;
  position: relative;
  padding-top: 45px;
}

.please-select-balloon {
  position: relative;
  margin: 20px 20px;
  background-color: #fff;
  border-radius: 4px;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #fff;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    padding: 14px 14px;
  }
}
// ドロップエリア
.drop_area {
  background: linear-gradient(99deg, #FFAB38, #93562F);
  max-width: 980px;
  margin: 80px auto;
  border-radius: 16px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  bottom: 0;
  height: 288px;
  
  /*&.width-mv {
    margin-top: -6vw;
  }*/

  @include mq-down {
    margin: 32px auto;
    height: 132px;
    padding: 8px;
  }

  p {
    margin-bottom: 0px;

    @include mq-up {
      font-size: calc(0.135vw + 12px);
    }
  }

  .drop_area-inner {
    max-width: 976px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed #fff;
    padding: 8px;
    @include mq-down {
      height: 120px;
    }

    .upload-img {
      height: 48px;
      margin-top: 8px;
      margin-bottom: 8px;

      @include mq-down(md) {
        margin-bottom: 0px;
      }

      @include mq-down(sm) {
        height: 40px;
      }
    }

    .drop_area-button {
      margin: 8px;
      font-size: 14px;

      @include mq-down {
        font-size: 14px;
        padding: 0 32px;
      }
      @include mq-down(sm) {
        font-size: 12px;
      }
    }
  }
  
  input {
    display: none;
  }
}
</style>

<i18n>
{
  "ja": {
    "st2_1": "画像をアップロードしてください",
    "st2_2": "ここに画像をドラッグまたは、",
    "st2_3": "ファイルを選択",
    "st2_4": "選択した画像",
    "st2_5": "どの画家風にしますか？",
    "st2_6": "描く",
    "st2_7": "ログインして利用する"
  },
  "en": {
    "st2_1": "Please upload your image",
    "st2_2": "Drag the image here or",
    "st2_3": "Select File",
    "st2_4": "Selected Images",
    "st2_5": "Which painter style do you select?",
    "st2_6": "Draw",
    "st2_7": "sign in to use"
  },
  "fr": {
    "st2_1": "Veuillez télécharger votre image",
    "st2_2": "Faites glisser l'image ici ou",
    "st2_3": "Sélectionnez un fichier",
    "st2_4": "Images sélectionnées",
    "st2_5": "Quel style de peintre voulez-vous ?",
    "st2_6": "Dessin",
    "st2_7": "Se connecter"
  },
  "es": {
    "st2_1": "Por favor, sube tu imagen",
    "st2_2": "Arrastre la imagen aquí o",
    "st2_3": "Seleccione el archivo...",
    "st2_4": "Imágenes seleccionadas",
    "st2_5": "¿Qué estilo de pintor quieres?",
    "st2_6": "Dibuja",
    "st2_7": "Registrarte y utilizar"
  },
  "pt": {
    "st2_1": "Por favor, carregue a sua imagem",
    "st2_2": "Arraste a imagem para aqui ou",
    "st2_3": "Seleccionar ficheiro",
    "st2_4": "Imagens seleccionadas",
    "st2_5": "Qual o estilo de pintor que pretende?",
    "st2_6": "Desenho",
    "st2_7": "Use após o login"
  },
  "de": {
    "st2_1": "Bitte laden Sie Ihr Bild hoch",
    "st2_2": "Ziehen Sie das Bild hierher oder",
    "st2_3": "Datei auswählen",
    "st2_4": "Ausgewählte Bilder",
    "st2_5": "Welchen malerischen Stil wünschen Sie?",
    "st2_6": "Zeichnen",
    "st2_7": "Einloggen und sofort verwenden"
  },
  "nl": {
    "st2_1": "Gelieve uw afbeelding te uploaden",
    "st2_2": "Sleep de afbeelding hier of",
    "st2_3": "Selecteer Bestand",
    "st2_4": "Geselecteerde afbeeldingen",
    "st2_5": "Welke schilderstijl wil je?",
    "st2_6": "Tekenen",
    "st2_7": "aanmelden bij gebruik"
  },
  "tr": {
    "st2_1": "sizin resmi yükleyin",
    "st2_2": "Resmi buraya sürükleyin veya",
    "st2_3": "Dosya Seç",
    "st2_4": "Seçili Resimler",
    "st2_5": "Hangi ressam tarzı seçilir?",
    "st2_6": "Çizmek",
    "st2_7": "Kullanım oturum"
  },
  "zh-cn": {
    "st2_1": "请上传您的图片",
    "st2_2": "你可以把图像拖拽至此处，或者",
    "st2_3": "选择文件",
    "st2_4": "选择的图像",
    "st2_5": "你想用哪种画家风格？",
    "st2_6": "画",
    "st2_7": "登入使用"
  },
  "zh-tw": {
    "st2_1": "請上傳圖片",
    "st2_2": "在這裏，你可以把圖像拖拽出來，",
    "st2_3": "選擇文件",
    "st2_4": "選擇的圖像",
    "st2_5": "哪種畫家風格？",
    "st2_6": "畫",
    "st2_7": "登入使用"
  },
  "sv": {
    "st2_1": "Ladda upp din bild",
    "st2_2": "Dra bilden här eller",
    "st2_3": "Välj fil",
    "st2_4": "valda bilder",
    "st2_5": "Vilken målare stil ni väljer?",
    "st2_6": "Dra",
    "st2_7": "logga in för att använda"
  },
  "it": {
    "st2_1": "Si prega di caricare la vostra immagine",
    "st2_2": "Trascinare l'immagine qui o",
    "st2_3": "Selezionare il file",
    "st2_4": "Immagini selezionate",
    "st2_5": "Quale stile di pittura vuoi?",
    "st2_6": "Disegnare",
    "st2_7": "Fai il login per utilizzare"
  },
  "ko": {
    "st2_1": "이미지를 업로드하십시오",
    "st2_2": "여기에 이미지를 드래그 또는",
    "st2_3": "파일 선택",
    "st2_4": "선택한 이미지",
    "st2_5": "어떤 화가풍으로 그릴까요?",
    "st2_6": "그리다",
    "st2_7": "로그인 하고 이용하기"
  },
  "ar": {
    "st2_1": "يرجى تحميل الصور الخاصة بك",
    "st2_2": "اسحب صورة هنا أو",
    "st2_3": "حدد ملف",
    "st2_4": "صور مختارة",
    "st2_5": "أي نمط رسام لا تختار؟",
    "st2_6": "رسم",
    "st2_7": "تسجيل الدخول إلى استخدام"
  },
  "th": {
    "st2_1": "โปรดอัปโหลดภาพของคุณ",
    "st2_2": "ลากภาพที่นี่หรือ",
    "st2_3": "เลือกไฟล์",
    "st2_4": "รูปภาพที่ถูกเลือก",
    "st2_5": "แบบไหนที่คุณจิตรกรเลือก?",
    "st2_6": "วาด",
    "st2_7": "ลงชื่อเข้าใช้"
  },
  "id": {
    "st2_1": "Silakan upload gambar Anda",
    "st2_2": "Drag gambar di sini atau",
    "st2_3": "Pilih file",
    "st2_4": "Gambar yang dipilih",
    "st2_5": "gaya pelukis yang Anda pilih?",
    "st2_6": "Seri",
    "st2_7": "masuk ke penggunaan"
  },
  "pl": {
    "st2_1": "Proszę przesłać swoje zdjęcie",
    "st2_2": "Przeciągnij obrazek tutaj lub",
    "st2_3": "Wybierz Plik",
    "st2_4": "Wybrane obrazy",
    "st2_5": "Jaki chcesz styl malarza?",
    "st2_6": "Rysunek",
    "st2_7": "zaloguj się do użytku"
  },
  "vi": {
    "st2_1": "Hãy tải lên hình ảnh của bạn",
    "st2_2": "Kéo hình ảnh đây hoặc",
    "st2_3": "Chọn tập tin",
    "st2_4": "ảnh theo lựa chọn",
    "st2_5": "Những phong cách họa sĩ để bạn chọn?",
    "st2_6": "Vẽ tranh",
    "st2_7": "đăng nhập vào sử dụng"
  },
  "hi": {
    "st2_1": "अपने चित्र अपलोड करें",
    "st2_2": "चित्र यहां खींचें या",
    "st2_3": "फ़ाइल का चयन करें",
    "st2_4": "चुनी गई छवियाँ",
    "st2_5": "कौन चित्रकार शैली आप चयन करते हैं?",
    "st2_6": "खींचना",
    "st2_7": "उपयोग करने के लिए प्रवेश"
  },
  "ru": {
    "st2_1": "Пожалуйста, загрузите ваше изображение",
    "st2_2": "Перетащите изображение сюда или",
    "st2_3": "Выберите Файл",
    "st2_4": "Выбранные изображения",
    "st2_5": "Какой стиль живописи ты хочешь?",
    "st2_6": "Рисунок",
    "st2_7": "войти в использовании"
  },
  "bn": {
    "st2_1": "আপনার ইমেজ আপলোড করুন",
    "st2_2": "চিত্রকে এখানে টেনে আনুন বা",
    "st2_3": "নথি নির্বাচন",
    "st2_4": "নির্বাচিত ছবি",
    "st2_5": "কোনটি চিত্রশিল্পী শৈলী নির্বাচন করবেন?",
    "st2_6": "আঁকা",
    "st2_7": "ব্যবহার করতে সাইন ইন"
  }
}
</i18n>
