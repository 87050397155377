import axios from 'axios'
import moment from 'moment-timezone'
import { BehaviorSubject } from 'rxjs';

import AuthService from '@/auth/AuthService'
import i18n from '../i18n'
import router from '../router'
import { GUEST_KEY } from '../settings'

const initialState = {
  id: null,
  mail: null,
  groups: null,
  tz: null, // time zone
  stayingServicePath: null, // ユーザが今滞在しているサービスのpath
  currentGroupId: null,
  currentGroupMembers: [],
  currentGroupCreditInfo: {},
  currentGroupCreditHistory: [],
  logined$: new BehaviorSubject(false),
  guestExec$: new BehaviorSubject(false),
  deleteToken: null
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    logined(state) {
      return state.logined$.value
    },
    guestExec(state) {
      return state.guestExec$.value
    },
    currentGroup(state) {
      if (!state.groups) {
        return null
      }
      return state.groups.find(g => g.id === state.currentGroupId )
    },
    otherGroups(state) {
      if (!state.groups) {
        return null
      }
      return state.groups.filter(g => g.id !== state.currentGroupId)
    },
    currentGroupUser(state) {
      return state.currentGroupMembers.find(u => u.id === state.id)
    },
    isCurrentGroupAdmin(state, getters) {
      return getters.currentGroupUser && getters.currentGroupUser.role === 1
    }
  },
  mutations: {
    setStayingServicePath(state, servicePath) { // servicePath: /, /sr など
      state.stayingServicePath = servicePath
    },
    setUser(state, data) {
      state.id = data.user.id
      state.mail = data.user.mail
      state.groups = data.groups
      state.tz = moment.tz.guess()
      const currentGroupId = localStorage.getItem('current_group_id')
      if (currentGroupId && data.groups.find(g => g.id == currentGroupId)) {
        state.currentGroupId = currentGroupId
      } else {
        const topGroupId = data.groups[0].id
        state.currentGroupId = topGroupId
      }
      localStorage.setItem('current_group_id', state.currentGroupId)
    },
    setCurrentGroup(state, groupId) {
      state.currentGroupId = groupId
      localStorage.setItem('current_group_id', state.currentGroupId)
    },
    setCurrentGroupMembers(state, data) {
      state.currentGroupMembers = data.members
    },
    setCurrentGroupCreditInfo(state, data) {
      state.currentGroupCreditInfo = {
        total: data.total,
        credits: data.credits,
        checkKey: data.check_key
      }
    },
    setCurrentGroupCreditHistory(state, data) {
      state.currentGroupCreditHistory = data.history
    },
    logout(state) {
      for (let key in state) {
        if (key === 'logined$') {
          state.logined$.next(false)
        } else if (Object.prototype.hasOwnProperty.call(initialState, 'key')) {
          state[key] = initialState[key];
        }
      }
    },
    setGroups(state, groups) {
      state.groups = groups
      const currentGroupId = localStorage.getItem('current_group_id')
      if (currentGroupId && groups.find(g => g.id == currentGroupId)) {
        state.currentGroupId = currentGroupId
      } else {
        const topGroupId = groups[0].id
        state.currentGroupId = topGroupId
      }
      localStorage.setItem('current_group_id', state.currentGroupId)
    },
    setDeleteToken(state, deleteToken) {
      state.deleteToken = deleteToken
    },
    unsetDeleteToken(state) {
      state.deleteToken = null
    }
  },
  actions: {
    getUser({state, commit}) {
      return new Promise((resolve, reject) => {
        const currentGroupId = localStorage.getItem('current_group_id') || ''
        const params = (currentGroupId) ? {group_id: currentGroupId} : {}
        axios.get('/user/', {params: params}).then(res => {
          commit('setUser', res.data)
          localStorage.setItem('login_status', 2)  // アプリログイン完了
          localStorage.removeItem('exec_count')
          state.logined$.next(true)  // この時点でlogin完了状態になったとみなす
          resolve()
        })
        .catch(err => reject(err.response))
      })
    },
    getGuestUser({state, commit, dispatch}) {
      return new Promise((resolve, reject) => {
        const currentGroupId = localStorage.getItem('current_group_id') || ''
        const params = (currentGroupId) ? {group_id: currentGroupId} : {}
        // NOTE: ここに到達した際にrequests.jsの共有のヘッダーセットが完了していない場合があるため、ここでもセットしておく
        axios.get('/user/guest', {params: params, headers: {API_KEY: GUEST_KEY}}).then(res => {
          commit('setUser', res.data)
          const status1 = AuthService.guestExec()
          if (!router.currentRoute.name.match(/Result/)) localStorage.setItem('exec_count', res.data.exec_count)  // 結果ページでは更新しない
          const status2 = AuthService.guestExec()
          state.guestExec$.next(status2)
          if (status1 && !status2) dispatch('showErrorOnScreen', {localizeId: 'err_40'}, {root: true})  // ゲストユーザーの実行上限に達したことを通知する
          resolve()
        })
        .catch(err => reject(err.response))
      })
    },
    getMembers({state, commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`/group/members/${state.currentGroupId}`).then(res => {
          commit('setCurrentGroupMembers', res.data)
        })
        .catch(err => {console.log('## err', err); return reject(err.response)})
      })
    },
    inviteMembers({state}, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/group/invite/${state.currentGroupId}`,{
          members: payload,
          lang: i18n.locale
        }).then(res => {
          return resolve(res.data)
        })
        .catch(err => {console.log('## err', err); return reject(err.response)})
      })
    },
    removeMember({state}, user_id) {
      return new Promise((resolve, reject) => {
        axios.post(`/group/remove_member/${state.currentGroupId}`,{
          user_id: user_id
        }).then(res => {
          return resolve(res.data)
        })
        .catch(err => {console.log('## err', err); return reject(err.response)})
      })
    },
    getCurrentGroupCreditInfo: {
      root: true, // 他のstore moduleからも呼べるようにする
      handler({ state, commit }) {
        if (!state.currentGroupId) {
          return
        }
        return new Promise((resolve, reject) => {
          axios.get(`/credit/${state.currentGroupId}`).then(res => {
            commit('setCurrentGroupCreditInfo', res.data)
            return resolve(true)
          })
          .catch(err => {console.log('## err', err); return reject(err.response)})
        })
      },
    },
    getCurrentGroupCreditHistory({state, commit}) {
      if (!state.currentGroupId) {
        return
      }
      return new Promise((resolve, reject) => {
        axios.get(`/credit/history/${state.currentGroupId}`).then(res => {
          commit('setCurrentGroupCreditHistory', res.data)
        })
        .catch(err => {console.log('## err', err); return reject(err.response)})
      })
    },
    getGroups({commit}) {
      return new Promise((resolve, reject) => {
        axios.get(`/user/groups`).then(res => {
          commit('setGroups', res.data.groups)
          resolve()
        })
        .catch(err => reject(err.response))
      })
    },
    deleteUser({state, commit}) {
      return new Promise((resolve, reject) => {
        // token取得
        if (!state.deleteToken) {
          axios.post(`user/delete_token`, {group_id: state.currentGroupId})
          .then(res => {
            commit('setDeleteToken', res.data.delete_token)
            return resolve(res.data)
          })
          .catch(err => reject(err.response))
        // 削除実行
        } else {
          const data = {
            group_id: state.currentGroupId,
            delete_token: state.deleteToken
          }
          axios.post(`user/delete`, data)
          .then(res => {
            return resolve(res.data)
          })
          .catch(err => reject(err.response))
        }
      })
    }
  },
}
