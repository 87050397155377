<template>
  <div>
    <v-dialog :value="show" max-width="1000px" v-if="!processing" persistent>
      <v-card class="card-container">
        <div class="close-button-container">
          <v-btn flat @click="close()" class="close-button" fab large>
            <v-img class="cross" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg"></v-img>
          </v-btn>
        </div>
        <v-container class="ca-card-container">
          <p class="uploaded-text">{{ $t("prup_1") }}</p>
          <div class="uploaded-item">
            <p>{{ fileName }}</p>
            <div class="uploaded-img" v-ripple="{ class: `white--text` }" @click="showUploaded=true">
              <v-img contain :src="previewSrc" class="img"></v-img>
            </div>
          </div>
          <v-dialog v-model="showUploaded" max-width="100vh">
            <v-card class="uploaded-view blue lighten-5">
              <div class="card-uploaded">
                <div class="close-button-container">
                  <v-btn flat @click="showUploaded=false" class="close-button" fab large>
                    <v-img class="cross" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg"></v-img>
                  </v-btn>
                </div>
                <div class="preview-container">
                  <img class="preview-img" :src="previewSrc">
                </div>
                <p>{{ fileName }}</p>
              </div>
            </v-card>
          </v-dialog>
          <div class="select_type">
            <h3>
              <span class="heading-text">{{ $t("prup_2") }}</span>
            </h3>
            <v-layout align-start justify-space-around>
              <v-flex xs5 md5>
                <p>{{ $t("prup_3") }}</p>
                <div class="select_frame" @click="onSelect(IMAGE_TYPE.PICTURE)" v-ripple="{ class: `blue--text accent-1` }">
                  <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/image_type_1.jpg" aspect-ratio="1" class="img" :class="{selected: selectedImageType === IMAGE_TYPE.PICTURE}"></v-img>
                </div>
                <span class="lead-fr" v-html="$t('prup_3_fr')"></span>
              </v-flex>
              <v-flex xs5 md5>
                <p>{{ $t("prup_4") }}</p>
                <div class="select_frame" @click="onSelect(IMAGE_TYPE.ILLUST)" v-ripple="{ class: `blue--text accent-1` }">
                  <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/image_type_2.jpg" aspect-ratio="1" class="img" :class="{selected: selectedImageType === IMAGE_TYPE.ILLUST}"></v-img>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <v-layout align-center justify-center class="display-pc">
            <v-btn color="info" class="exec-btn white--text" :class="{enabled: execEnabled}" @click="execute" large round>
              <img width="14px" src="../../assets/img/pr/pr_logo-white.svg" class="mr-2">
              {{ $t("prup_9") }}
            </v-btn>
          </v-layout>
          <v-btn color="info" class="exec-btn white--text display-sp" :class="{enabled: execEnabled}" @click="execute" large round>
            <img width="14px" src="../../assets/img/pr/pr_logo-white.svg" class="mr-2">
            {{ $t("prup_9") }}
          </v-btn>
          <p class="caption">{{ $t("prup_10") }}<span class="display-pc">{{ $t("prup_11") }}</span></p>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog :value="show" max-width="1000px" v-else persistent>
      <v-card>
        <div class="uploaded-item">
          <p>{{ fileName }}</p>
          <div class="uploaded-img" v-ripple="{ class: `white--text` }" @click="showUploaded=true">
            <v-img contain :src="previewSrc" class="img"></v-img>
          </div>
        </div>
        <v-container class="ca-card-processing pt-2 pb-1 px-4">
          <v-layout align-center justify-center fill-height column>
            <v-progress-linear height="8" class="mt-4" :indeterminate="true"></v-progress-linear>
            <p>{{ $t("prup_12") }} ...</p>
          </v-layout>
        </v-container>
        <!-- 広告 -->
        <ad-inference></ad-inference>
        <v-container class="ca-card-processing pt-2 pb-3 px-4">
          <v-layout align-center justify-center fill-height column>
            <img :src="previewSrc" ref="processingImg">
          </v-layout>
        </v-container>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import axios from 'axios'
import AdInference from '@/components/GoogleAdsense/AdInference.vue'

const IMAGE_TYPE = {
  PICTURE: 1,
  ILLUST: 2
}
export default {
  name: 'PhotoRefinerUpload',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      required: true
    },
  },
  components: {
    AdInference
  },
  data() {
    return {
      fileReader: new FileReader(),
      previewSrc: "",
      selectedImageType: null,
      execEnabled: false,
      processing: false,
      uploadFinished: false,
      progressLeft: '99%',
      progressBarInterval: null,
      showUploaded: false,
      IMAGE_TYPE: IMAGE_TYPE
    }
  },
  computed: {
    fileName: function() {
      if (this.files && this.files.length > 0) {
        return this.files[0].name
      } else {
        return ''
      }
    }
  },
  watch: {
    files: function(newVal) {
      // preview表示
      this.fileReader.onload = (e) => {
        this.previewSrc = e.target.result
      }
      this.fileReader.readAsDataURL(newVal[0])
    },
    uploadFinished: function(newVal, oldVal) {
      if (oldVal === false && newVal == true) {
        let i = 0;
        this.progressBarInterval = setInterval(() => {
          i = i + 1;
          if (i > 96) { // 進捗96%で止めておく
            clearInterval(this.progressBarInterval);
            return;
          }
          this.progressLeft = 100 -i + '%';
        }, 1000)
      }
    },
    show (val) {
      !val && this.close()
    }
  },
  methods: {
    onSelect(type) {
      this.selectedImageType = type;
      this.execEnabled = true;
    },
    execute() {
      if (this.execEnabled !== true) {
        return;
      }
      this.processing = true;
      let data = new FormData();
      data.append('file', this.files[0])
      data.append('group_id', this.$store.state.user.currentGroupId)
      data.append('image_type', this.selectedImageType)
      const url = (this.$store.getters['user/logined']) ? '/pr/exec' : '/pr/exec/guest'
      axios({
        method: 'post',
        url: url,
        data: data,
        onUploadProgress: (e) => {
          let percent = (e.loaded / e.total) * 100
          this.progressLeft = String(100 - percent) + '%'
          if (percent === 100) {
            this.uploadFinished = true
          }
        }
      }).then((rs) => {
        let requestId = rs.data.request_id
        // ポーリングで進捗をcheck
        let progressFetchInterval = setInterval(() => {
          axios.get(`/pr/progress/${requestId}`).then(rsProgress => {
            if (rsProgress.data.process_status == 3) {
              this.progressLeft = '0%';
              clearInterval(this.progressBarInterval)
              clearInterval(progressFetchInterval)
              // 遷移後のページは動的に高さが変わるので高さが決まってからfooterを表示するために非表示にしておく
              this.$store.commit('screen/footerVisible', false);
              this.$router.push({name: 'prResult', params: {requestId: requestId}});
            }
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('# progress fetch err: ', err)
          })
        }, 3000)
      }).catch(() => {
        // ここに来た時は既に汎用エラーモーダルが表示されているので、uploadモーダルを消すだけ
        this.processing = false
        this.close()
      })
    },
    close() {
      if (this.processing) {
        return
      }
      this.selectedImageType = null;
      this.execEnabled = false;
      this.processing = false;
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/scss/_variables.scss";
/deep/ .v-dialog {
  margin-right: 16px;
  margin-left: 16px;
}
.card-container {
  min-height: 760px;
  @include mq-down {
    min-height: initial;
    height: 77vh;
    overflow-x: scroll;
  }
}
.ca-card-container {
  max-width: 790px;
  position: relative;

  .uploaded-text {
    margin-top: 24px;
    font-size: 20px;
    line-height: 29px;
    font-weight: 300;

    @include mq-down(md) {
      margin-top: 0;
      font-size: 16px;
    }
  }
}

.close-button-container {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 5;
  .close-button {
    .cross {
      width: 40px;
      height: 40px;
    }
  }
  @include mq-down {
    top: 2px;
    left: 2px;
    .close-button {
      width: 40px;
      height: 40px;
      .cross {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.uploaded-item {
  min-height: 112px;
  background-color: #E3F2FD;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq-down {
    flex-direction: column;
    justify-content: center;
  }
  p {
    margin-bottom: 0;
    position: relative;
    font-size: 25px;
    font-weight: 300;
    line-height: 48px;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 40px;

    @include mq-down {
      left: 0;
      padding: 0 16px;
      margin: 0;
      font-size: 16px;
      max-width: 100%;
      line-height: 23px;
      margin-top: 4px;
    }
  }
  .uploaded-img {
    max-width: 112px;
    max-height: 90px;
    margin: 8px 50px 8px 24px;

    &:hover {
      cursor: pointer;
      .img {
        &::before {
          opacity: .2;
        }
      }
    }
    @include mq-down {
      padding: 0;
      margin: 4px 0 8px;
      max-height: 64px;
    }
    .img {
      width: 112px;
      max-height: 90px;
        &::before {
          height: auto;
          width: 100%;
          content: '';
          background-color: white;
          opacity: 0;
          position: relative;
          z-index: 2;
          transition: .1s;
        }
      @include mq-down {
        max-height: 64px;
      }
    }
  }
}

.uploaded-view {
  .close-button-container {
    @include mq-down {
      top: -4px;
      left: -4px;
    }
  }
  .card-uploaded {
    position: relative;
    padding:  96px 16px 0;
    @include mq-down {
      padding: 40px 16px 0;
    }
  }
  .preview-container {
    max-height: 75vh;
    max-width: 100%;
    overflow: scroll;

    .preview-img {
      margin: auto;
    }
  }
  p {
    padding: 20px 0;
    margin: 0;
    font-size: 25px;
    font-weight: 200;
    line-height: 48px;
    @include mq-down {
      padding: 8px 0;
      font-size: 14px;
      line-height: initial;
    }
  }
}

.select_type {
  position: relative;
  margin-top: 24px;
  text-align: left;
  @include mq-down {
    margin-top: 44px;
  }
  h3 {
    margin-bottom: 24px;
    @include mq-up(lg) {
      margin-right: -8px;
      margin-left: -8px;
    }
    @include mq-down {
      display: flex;
      margin: 0 16px;
    }
    .heading-text {
      font-size: 26px;
      font-weight: 400;
      line-height: 48px;
      @include mq-down {
        margin-top: 8px;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
  p {
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 4px;
    @include mq-down(md) {
      font-size: 14px;
      margin-bottom: 0px;
    }
    transition: 0.2s;
  }
  .select_frame {
    cursor: pointer;
    transition: 0.2s;
    border: transparent solid 4px;
    border-radius: 16px;
    .img {
      border-radius: 10px;
      transition: .2s;
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        border: solid 5px transparent;
        transition: .2s;
        border-radius: 9px;
      }
      &:hover, &.selected {
        &::after {
          border-color: #42A5F5;
        }
      }
    }
  }
  .lead-fr {
    display: inline-block;
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    @include mq-down {
      font-size: 10px;
    }
    /deep/ a {
      color: #0D47A1;
      cursor: pointer;
    }
  }
}
.exec-btn {
  margin-top: 24px;
  width: 380px;
  background: linear-gradient(99deg, #CE93D8, #2196F3);
  opacity: .5;
  pointer-events: none;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  height: initial;
  border-radius: 99px;
  padding: 18px 0;
  &.enabled {
    pointer-events: initial;
    cursor: pointer;
    opacity: 1;
  }
  @include mq-down {
    margin-top: 24px;
    width: 100%;
    padding: 12px 24px;
    max-width: 240px;
    white-space: normal;
    word-wrap: break-word;
  }
}
.caption {
  font-size: 14px;
  font-weight: 400;
  margin: 16px 0 0;
  @include mq-down {
    font-size: 12px;
    line-height: initial;
  }
}

.ca-card-processing {
  max-width: 720px;
  img {
    border-radius: 4px;
    max-width: 256px;
  }
  .progress-bar-container {
    max-width: 480px;
    width: 80vw;
    height: 8px;
    border-radius: 4px;
    border: 1px solid #0D47A1;
    background-color: white;
    .progress-bar {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid transparent;
      max-width: 480px;
      width: 80vw;
      height: 6px;
      background: linear-gradient(99deg, #2196F3, #37CAE7);
    }
  }
  p {
    top: 492px;
    font-size: 24px;
    color: #1565C0;
  }
}

/deep/ .v-progress-linear {
  border-radius: 32px;
}
/deep/ .v-progress-linear__bar__indeterminate {
  background: linear-gradient(to left, #EA80FC, rgb(140, 158, 255));
}
</style>

<i18n src="@/locales/photo_refiner/upload.json"></i18n>
