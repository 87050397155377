<template>
  <div id="pr_top">
    <service-header :auth="auth"/>
    <!-- Heroエリア -->
    <div class="hero-container">
      <div class="hero_1_a"></div>
      <div class="hero_1_b">
        <p><span v-html="$t('pr_1')"></span></p>
      </div>
      <div class="hero_2_a"></div>
      <div class="hero_2_b">
        <p><span v-html="$t('pr_2')"></span></p>
      </div>
      <div class="hero_3_a"></div>
      <div class="hero_3_b">
        <p><span v-html="$t('pr_3')"></span></p>
      </div>
    </div>
    <!-- ドロップエリア -->
    <div class="px-3">
      <div class="drop_area_container hero">
        <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
          <img class="upload-img" src="../../assets/img/upload_icon.svg">
          <p class="white--text display-pc">{{ $t("pr_5") }}</p>
          <v-btn class="text-capitalize drop-area-button" round outline dark @click="openFileSelect">
            {{ $t("pr_6") }}
          </v-btn>
          <input ref="inputFile" type="file" id="target_image" name="target_image" :accept="acceptableExtensions" @change="onSelectFile"/>
        </v-layout>

        <v-layout align-center justify-center column fill-height v-else class="drop_area">
          <img class="upload-img" src="../../assets/img/upload_icon.svg">
          <v-btn class="text-capitalize drop-area-button" @click="auth.login('login')" round outline dark :large="$vuetify.breakpoint.smAndUp">
            {{ $t("pr_4") }}
          </v-btn>
        </v-layout>
      </div>
    </div>

    <!-- Face Refinerへの導線 -->
    <div class="lead-other-page px-3">
      <p v-html="$t('pr_6_fr')"></p>
    </div>
    <!-- データページへの導線 -->
    <div v-if="logined" class="lead-other-page px-3">
      <p v-html="$t('glb_10', ['pr'])"></p>
    </div>

    <div class="whatis-container">
      <h1 v-html="$t('pr_7')"></h1>
      <p class="mb-5 main-descriptions d-pad">
        <span class="hide-sm-down" v-html="$t('pr_8a')"></span>
        <span class="hide-sm-up" v-html="$t('pr_8b')"></span>
      </p>
      <div class="intro-movie">
        <div class="intro-movie-container">
          <iframe width="877" height="494" :src="$t('pr_71')" frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <!-- 広告 -->
    <ad-responsive></ad-responsive>

    <div class="container-container">
      <div class="every-can-refine">
        <h2><span v-html="$t('pr_9')"></span></h2>
        <div v-for="(comparison_pic, i) in comparison_pics" :key=i class="comparison-container">
          <div class="comparison-before">
            <p class="text-before">Before</p>
            <div class="before-pic" :style="`background-image:url(${comparison_pic[0]})`"></div>
          </div>
          <div class="arrow-container">
            <v-img class="refine-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/refine_arrow.svg"></v-img>
          </div>
          <div class="comparison-after">
            <p class="text-after">After</p>
            <div class="after-pic" :style="`background-image:url(${comparison_pic[1]})`"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="oversize-images">
      <h2><span v-html="$t('pr_10')"></span></h2>
      <p class="main-descriptions d-pad"><span v-html="$t('pr_11')"></span></p>
      <div class="oversize-illust">
        <v-img class="display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/03_oversize_images/orversize_illust_pc.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/03_oversize_images/orversize_illust_pc.jpg"></v-img>
        <v-img class="display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/03_oversize_images/orversize_illust_sp.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/03_oversize_images/orversize_illust_sp.jpg"></v-img>
      </div>
    </div>

    <!-- 広告 -->
    <ad-responsive></ad-responsive>

    <div class="usage-steps">
      <div class="usage-steps-container white--text">
        <h2>{{ $t("pr_12") }}</h2>
        <div class="three-steps">
          <div class="one-step">
            <v-img class="step-num" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_num_1.svg"></v-img>
            <h3>{{ $t("pr_13") }}</h3>
            <v-img contain class="step-pics step-pic-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_pic_1.svg"></v-img>
            <p>{{ $t("pr_14") }}</p>
          </div>
          <div class="one-step center">
            <v-img class="step-num" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_num_2.svg"></v-img>
            <h3>{{ $t("pr_15") }}</h3>
            <v-img contain class="step-pics step-pic-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_pic_2.png" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_pic_2.png"></v-img>
            <p>{{ $t("pr_16") }}</p>
          </div>
          <div class="one-step">
            <v-img class="step-num" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_num_3.svg"></v-img>
            <h3>{{ $t("pr_17") }}</h3>
            <v-img contain class="step-pics step-pic-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/04_usage_steps/step_pic_3.svg"></v-img>
            <p>{{ $t("pr_18") }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- ドロップエリア -->
    <div class="drop_area">
      <div class="drop_area_container">
        <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
          <img class="upload-img" src="../../assets/img/upload_icon.svg">
          <p class="white--text display-pc" :class="{'subheading': $vuetify.breakpoint.smAndUp}">{{ $t("pr_5") }}</p>
          <v-btn class="text-capitalize subheading" round outline dark :large="$vuetify.breakpoint.smAndUp" @click="openFileSelect">
            {{ $t("pr_6") }}
          </v-btn>
          <input ref="inputFile" type="file" id="target_image" name="target_image" :accept="acceptableExtensions" @change="onSelectFile"/>
        </v-layout>

        <v-layout align-center justify-center column fill-height v-else class="drop_area">
          <img class="upload-img" src="../../assets/img/upload_icon.svg">
          <v-btn class="text-capitalize" @click="auth.login('login')" round outline dark :large="$vuetify.breakpoint.smAndUp">
            {{ $t("pr_4") }}
          </v-btn>
        </v-layout>
      </div>
    </div>

    <!-- 広告 -->
    <ad-responsive></ad-responsive>

    <div class="why-accuracy">
      <div class="why-accuracy-container">
        <h2><span v-html="$t('pr_19')"></span></h2>
        <v-img class="accuracy_num one" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_num_1.svg"></v-img>
        <h3>
          <span class="under-dotted">{{ $t("pr_20") }}</span>
        </h3>
        <p class="main-descriptions d-pad accuracy-descriptions">
          <span v-html="$t('pr_21')"></span>
        </p>
        <div class="three-chat-container">
          <div class="one-cat">
            <p class="blue-balloon">{{ $t("pr_22") }}</p>
            <v-img class="cat-pics-a" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_1a.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_1a.jpg"></v-img>
            <v-img class="cat-pics-b" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_1b.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_1b.jpg">
            </v-img>
          </div>
          <div class="one-cat">
            <p class="blue-balloon">{{ $t("pr_23") }}</p>
            <v-img class="cat-pics-a" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_2a.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_2a.jpg"></v-img>
            <v-img class="cat-pics-b" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_2b.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_2b.jpg"></v-img>
          </div>
          <div class="one-cat">
            <p class="blue-balloon">{{ $t("pr_24") }}</p>
            <v-img class="cat-pics-a" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_3a.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_3a.jpg"></v-img>
            <v-img class="cat-pics-b last-cat" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_3b.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_cat_3b.jpg">
            </v-img>
          </div>
        </div>
        <div class="last-cat-descriptions-container">
          <p class="last-cat-descriptions">{{ $t("pr_25") }}</p>
        </div>
        <v-img class="accuracy_num two" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_num_2.svg"></v-img>
        <h3>
          <span class="under-dotted">{{ $t("pr_26") }}</span>
        </h3>
        <p class="main-descriptions d-pad accuracy-descriptions">
          <span v-html="$t('pr_27')"></span>
        </p>
        <v-img contain class="gpu-illust" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/cloud_gpu_illust.svg"></v-img>
        <v-img class="accuracy_num three" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_num_3.svg"></v-img>
        <h3>
          <span class="under-dotted">
            {{ $t("pr_28") }}
          </span>
        </h3>
        <p class="main-descriptions d-pad accuracy-descriptions pb-3">
          <span v-html="$t('pr_29')"></span>
        </p>
        <h4 class="comparison-heading">1.{{ $t("pr_30") }}</h4>
        <div class="comparison-container">
          <div class="comparison-before">
            <div class="before-pic pic_1"></div>
          </div>
          <div class="arrow-container">
            <v-img class="refine-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/refine_arrow.svg"></v-img>
          </div>
          <div class="comparison-after">
            <div class="after-pic pic_1"></div>
          </div>
        </div>
        <div class="comparison-container">
          <div class="comparison-before">
            <div class="before-pic pic_2"></div>
          </div>
          <div class="arrow-container">
            <v-img class="refine-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/refine_arrow.svg"></v-img>
          </div>
          <div class="comparison-after">
            <div class="after-pic pic_2"></div>
          </div>
        </div>
        <h4 class="comparison-heading">2.{{ $t("pr_31") }}</h4>
        <div class="comparison-container">
          <div class="comparison-before">
            <div class="before-pic pic_3"></div>
          </div>
          <div class="arrow-container">
            <v-img class="refine-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/refine_arrow.svg"></v-img>
          </div>
          <div class="comparison-after">
            <div class="after-pic pic_3"></div>
          </div>
        </div>
        <h4 class="comparison-heading">3.{{ $t("pr_32") }}</h4>
        <div class="comparison-container">
          <div class="comparison-before">
            <div class="before-pic pic_4"></div>
          </div>
          <div class="arrow-container">
            <v-img class="refine-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/refine_arrow.svg"></v-img>
          </div>
          <div class="comparison-after">
            <div class="after-pic pic_4"></div>
          </div>
        </div>
      </div>
      <!-- 広告 -->
      <ad-responsive></ad-responsive>
    </div>
    <div class="solutions">
      <h2>
        <span class="under-yellow-line">{{ $t("pr_33") }}</span>
      </h2>
      <p class="main-descriptions d-pad"><span v-html="$t('pr_34')"></span></p>
      <div class="panel-container panel-1">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_01.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text">{{ $t("pr_35") }}</span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_01.svg"></v-img>
        </h3>
        <div class="solutions-panel reverse">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_01.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_01.jpg"></v-img>
          <p class="yellow-balloon"><span v-html="$t('pr_37')"></span></p>
          <p class="panel-description">{{ $t("pr_36") }}</p>
        </div>
      </div>
      <div class="panel-container panel-2">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_02.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text"><span v-html="$t('pr_38')"></span></span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_02.svg"></v-img>
        </h3>
        <div class="solutions-panel">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_02.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_02.jpg"></v-img>
          <p class="yellow-balloon">{{ $t("pr_40") }}</p>
          <p class="panel-description">{{ $t("pr_39") }}</p>
        </div>
      </div>
      <div class="panel-container panel-3">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_03.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text"><span v-html="$t('pr_41')"></span></span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_03.svg"></v-img>
        </h3>
        <div class="solutions-panel reverse">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_03.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_03.jpg" position="left"></v-img>
          <p class="yellow-balloon"><span v-html="$t('pr_43')"></span></p>
          <p class="panel-description">{{ $t("pr_42") }}</p>
        </div>
      </div>
      <div class="panel-container panel-4">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_04.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text"><span v-html="$t('pr_44')"></span></span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_04.svg"></v-img>
        </h3>
        <div class="solutions-panel">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_04.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_04.jpg"></v-img>
          <p class="yellow-balloon"><span v-html="$t('pr_46')"></span></p>
          <p class="panel-description">{{ $t("pr_45") }}</p>
        </div>
      </div>
      <div class="panel-container panel-5">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_05.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text"><span v-html="$t('pr_47')"></span></span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_05.svg"></v-img>
        </h3>
        <div class="solutions-panel reverse">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_05.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_05.jpg" position="left"></v-img>
          <p class="yellow-balloon"><span v-html="$t('pr_49')"></span></p>
          <p class="panel-description">{{ $t("pr_48") }}</p>
        </div>
      </div>
      <div class="panel-container panel-6">
        <v-img class="title-pic display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_06.svg"></v-img>
        <h3 class="balloon-dotted">
          <span class="balloon-text"><span v-html="$t('pr_50')"></span></span>
          <v-img class="title-pic display-pc" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_icon_06.svg"></v-img>
        </h3>
        <div class="solutions-panel">
          <v-img class="solution-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_06.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/solutions_pic_06.jpg" position="left"></v-img>
          <p class="yellow-balloon"><span v-html="$t('pr_52')"></span></p>
          <p class="panel-description">{{ $t("pr_51") }}</p>
        </div>
      </div>
    </div>
    <!-- 広告 -->
    <ad-responsive></ad-responsive>
    <!-- ailia AI Refiner -->
    <div class="aar-link-area">
      <template v-if="$vuetify.breakpoint.xsOnly">
        <img class="bg-ray-softlight" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/linkarea_ray_softlight_sp.webp">
        <img class="bg-ray-overlay" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/linkarea_ray_overlay_sp.webp">
      </template>
      <template v-else>
        <img class="bg-ray-softlight" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/linkarea_ray_softlight.webp">
        <img class="bg-ray-overlay" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/linkarea_ray_overlay.webp">
      </template>
      <v-container aar-content grid-list-xl text-xs-left>
        <v-layout vf-hide-sm-down mb-3 align-center justify-center column>
          <aar-plugin-name :pluginName='$t("glb_aar_1")' :powerdBy='$t("glb_aar_2")' powerdByClass='powerd-company-center'></aar-plugin-name>
        </v-layout>
        <v-layout row wrap align-start justify-center>
          <v-flex xs12 md6>
            <v-layout justify-center>
              <div class="plugin-release">
                <h3 class="linkarea-message-pr" v-html="$t('pr_72')"></h3>
                <p class="vf-hide-sm-down mt-3" v-html="$t('pr_73')"></p>
              </div>
            </v-layout>
          </v-flex>
          <v-flex xs12 md6>
            <v-layout row wrap align-center>
              <v-flex package-img xs5 offset-xs1 lg5 offset-lg2 pb-0>
                <h3 class="package-photo-pr mb-2">{{ $t("glb_aar_4") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/package-photo.webp">
              </v-flex>
              <v-flex package-img xs5 lg5 pb-0>
                <h3 class="package-anime-pr mb-2">{{ $t("glb_aar_5") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/package-anime.webp">
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 lg10 offset-lg1 pt-0>
                <v-layout justify-center>
                  <img class="collaborate-logo-pr pl-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/logo@3x.webp">
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex vf-display-sm-down xs12>
            <v-layout mb-3 align-center justify-center column>
              <aar-plugin-name class="mb-3" :pluginName='$t("glb_aar_1")' :powerdBy='$t("glb_aar_2")'></aar-plugin-name>
              <div class="plugin-release">
                <p v-html="$t('pr_73')">{{ $t("pr_73") }}</p>
              </div>
            </v-layout>
          </v-flex>
          <aar-button :btnText='$t("glb_aar_3")' btnClass="btn-cv-pr"></aar-button>
        </v-layout>
      </v-container>
    </div>
    <div class="about-api">
      <div class="about-api-container">
        <h2><span class="under-yellow-line">{{ $t("pr_53") }}</span></h2>
        <h3 class="about-api-heading"><span v-html="$t('pr_54')"></span></h3>
        <p class="api-descriptions">{{ $t("pr_55") }}</p>
        <v-img class="main-illust display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_main_illust_pc.svg">
          <p class="p-request" :class="isLongLang ? 'small-text' : ''"><span v-html="$t('pr_56')"></span></p>
          <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("pr_57") }}</p>
          <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("pr_58") }}</p>
        </v-img>
        <v-img class="main-illust display-sp" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_main_illust_sp.svg">
          <p class="p-request" :class="isLongLang ? 'small-text' : ''"><span v-html="$t('pr_56')"></span></p>
          <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("pr_57") }}</p>
          <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("pr_58") }}</p>
        </v-img>
        <v-btn class="api-btn" to="/#g-form">
          <span class="dotted">{{ $t("pr_59") }}</span>
        </v-btn>
        <h3 class="about-api-heading pt-5"><span v-html="$t('pr_60')"></span></h3>
        <p class="api-descriptions"><span v-html="$t('pr_61')"></span></p>
        <v-img class="sub-illust display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_pc.svg">
          <p class="p-0"><span v-html="$t('pr_62')"></span></p>
          <p class="p-1"><span v-html="$t('pr_63')"></span></p>
          <p class="p-2"><span v-html="$t('pr_64')"></span></p>
          <p class="p-3"><span v-html="$t('pr_65')"></span></p>
          <p class="p-4"><span v-html="$t('pr_66')"></span></p>
          <p class="p-5"><span v-html="$t('pr_67')"></span></p>
          <p class="p-6"><span v-html="$t('pr_68')"></span></p>
          <p class="p-7"><span v-html="$t('pr_69')"></span></p>
        </v-img>
        <div class="display-sp text-xs-left">
          <v-img class="sub-illust-sp-0" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_0.svg">
            <p class="paragraph"><span v-html="$t('pr_62')"></span></p>
          </v-img>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-1" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_1.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_63')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-2" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_2.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_64')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-3" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_3.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_65')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-4" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_4.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_66')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-5" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_5.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_67')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-6" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_6.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_68')"></span></p>
          </div>
          <div class="sub-illust-contaienr">
            <v-img class="sub-illust-sp-7" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_details_illust_sp_7.svg"></v-img>
            <p class="paragraph"><span v-html="$t('pr_69')"></span></p>
          </div>
        </div>

        <v-btn class="api-btn" to="/#g-form">
          <span class="dotted">{{ $t("pr_70") }}</span>
        </v-btn>
      </div>
    </div>

    <photo-refiner-upload :show="showUploadModal" @close="closeUploadModal" :files="droppedFiles"></photo-refiner-upload>
  </div>
</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'
import PhotoRefinerUpload from './Upload.vue'
import AarButton from '@/components/aar/Button.vue'
import AarPluginName from '@/components/aar/PluginName.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import AuthService from '@/auth/AuthService'
export default {
  name: 'PhotoRefinerTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    PhotoRefinerUpload,
    AarButton,
    AarPluginName,
    AdResponsive
  },
  data() {
    return {
      showUploadModal: false,
      droppedFiles: [],
      comparison_pics: [
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/1before.jpg","https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/1after.jpg"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/2before.jpg", "https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/2after.jpg"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/3before.png", "https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/02_every-can-refine/3after.png"]
      ],
      acceptableExtensions: '.jpg, .jpeg, .jpe, .png, .webp, .bmp'
    }
  },
  watch: {
    '$route': function (n, o) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
      console.log('new, old', [n.hash, o.hash])
    }
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    guestExec() {
      return this.$store.getters['user/guestExec']
    },
  },
  methods: {
    onDropFile(e) {
      if (!this.logined && !this.guestExec) return
      // FileList型はforEachできないためforで回す
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i]
        this.droppedFiles.push(file);
      }
      this.showUploadModal = true;
    },
    openFileSelect() {
      this.$refs.inputFile.click()
    },
    onSelectFile(e) {
      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        this.droppedFiles.push(file);
      }
      this.showUploadModal = true;
    },
    closeUploadModal() {
      this.$refs.inputFile.value = "";
      this.droppedFiles = [];
      this.showUploadModal = false;
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
    if (!AuthService.isAuthenticated(1)) this.$store.dispatch('user/getGuestUser')
  },
  mounted() {
    // v-htmlでaタグを使用すると非SPA遷移になるので、SPA遷移になるように改良
    // SPA遷移だと遷移スピードも速いし、ServiceHeader経由ですぐサービストップページに戻ることができる
    const targetElems = document.getElementsByClassName('to-data-page')
    if (targetElems.length) {
      const dataPageLink = targetElems[0]
      dataPageLink.addEventListener('click', (event) => {
        event.preventDefault()
        this.$router.push(dataPageLink.getAttribute('href'))
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/common.scss";
@import "../../assets/scss/_aar.scss";

#pr_top {
  background-color: white;
}

// Heroエリア
.hero-container {
  height: 42vw;
  position: relative;
  overflow: hidden;

  @include mq-down {
    height: 400px;
  }

  p {
    margin: 21px 32px 0;
    font-size: calc(1.6vw + 12px);
    font-weight: 400;
    filter: drop-shadow(0 0 2px white);
    -webkit-filter: drop-shadow(0 0 2px white);

    @include mq-down(md) {
      margin: 8px 16px 0;
      font-size: calc(1.6vw + 12px);
    }

    @include mq-down(sm) {
      margin: 8px 16px 0;
      font-size: 18px;
    }
  }

  // ループアニメ全体の時間
  $anime_duration: 14s;

  $play_state: running;
  // $play_state: paused;  // コーディング中、鬱陶しい場合はpausedにしよう

  @keyframes slide_shadow {
    0% {
      left: 0;
      opacity: 1;
    }
    25% {
      left: 110%;
      opacity: 1;
    }
    26% {
      left: 110%;
      opacity: 0;
    }
    27% {
      left: 0;
      opacity: 0;
    }
    33% {
      left: 0;
      opacity: 1;
    }
    58% {
      left: 110%;
      opacity: 1;
    }
    59% {
      left: 110%;
      opacity: 0;
    }
    60% {
      left: 0;
      opacity: 0;
    }
    66% {
      left: 0;
      opacity: 1;
    }
    91% {
      left: 110%;
      opacity: 1;
    }
    92% {
      left: 110%;
      opacity: 0;
    }
    93% {
      left: 0;
      opacity: 0;
    }
  }

  &::after {
    content: '';
    box-shadow: -8px 0 24px 12px white,
    10px 0 3px -8px white inset;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 6;
    animation: slide_shadow $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
  }

  @keyframes slide_image1a {
    0% {
      clip-path: inset(0 0 0 0%);
    }
    25% {
      clip-path: inset(0 0 0 110%);
      opacity: 1;
    }
    26% {
      clip-path: inset(0 0 0 110%);
      opacity: 0;
    }
    27% {
      clip-path: inset(0 0 0 0%);
      opacity: 0;
    }
    97% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes slide_image1b {
    0% {
      opacity: 1;
    }
    30% {
      opacity: 1;
    }
    33% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes slide_image2a {
    0% {
      clip-path: inset(0 0 0 0%);
    }
    33% {
      clip-path: inset(0 0 0 0%);
    }
    58% {
      clip-path: inset(0 0 0 110%);
    }
    100% {
      clip-path: inset(0 0 0 110%);
    }
  }
  @keyframes slide_image2b {
    0% {
      opacity: 1;
    }
    63% {
      opacity: 1;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes slide_image3a {
    0% {
      clip-path: inset(0 0 0 0%);
    }
    66% {
      clip-path: inset(0 0 0 0%);
    }
    91% {
      clip-path: inset(0 0 0 110%);
    }
    100% {
      clip-path: inset(0 0 0 110%);
    }
  }

  @mixin bg_image($src) {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-image: url($src);
    background-position: top center;
  }

  .hero_1_a {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_1_a.jpg');
    z-index: 5;
    animation: slide_image1a $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
    @include mq-up(md) {
      background-position: center 32%;
    }
  }
  .hero_1_b {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_1_b.jpg');
    z-index: 4;
    animation: slide_image1b $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
    @include mq-up(md) {
      background-position: center 32%;
    }
  }

  .hero_2_a {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_2_a.jpg');
    z-index: 3;
    animation: slide_image2a $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
    @include mq-up(md) {
      background-position: center 30%;
    }
  }
  .hero_2_b {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_2_b.jpg');
    z-index: 2;
    animation: slide_image2b $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
    @include mq-up(md) {
      background-position: center 30%;
    }
  }

  .hero_3_a {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_3_a.jpg');
    z-index: 1;
    animation: slide_image3a $anime_duration ease-in-out 1s infinite;
    animation-play-state: $play_state;
    @include mq-up(md) {
      background-position: center calc(20% + 16px);
    }
    @include mq-down(md) {
      background-position: right top;
    }
    @include mq-down(sm) {
      background-position: center center;
    }
  }
  .hero_3_b {
    @include bg_image('https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/01_hero/hero_3_b.jpg');
    z-index: 0;
    @include mq-up(md) {
      background-position: center calc(20% + 16px);
    }
    @include mq-down(md) {
      background-position: right top;
    }
    @include mq-down(sm) {
      background-position: center center;
    }
  }
}

// ドロップエリア
.drop_area {
  padding: 0 14px;
}
.drop_area_container {
  background: linear-gradient(99deg, #CE93D8, #2196F3);
  max-width: 980px;
  margin: 40px auto;
  height: 240px;
  border-radius: 16px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;

  @include mq-down(md) {
    padding: 10px;
  }

  @include mq-down(sm) {
    height: 120px;
  }

  &.hero {
    position: relative;
    bottom: 0;
    margin-top: -9%;
    margin-bottom: 0;
    min-height: 180px;
    max-height: 180px;
    height: 180px;

    @include mq-up(xl) {
      margin-top: -7%;
      max-height: 200px;
    }

    @include mq-up(sm) {
      min-height: 172px;
    }

    @include mq-down(md) {
      margin-top: -96px;
      min-height: 140px;
    }
    @include mq-down(sm) {
      margin-top: -64px;
      min-height: initial;
      height: 120px;
    }
    .upload-img {
      margin-bottom: 8px;
      height: 40px;
      margin-bottom: 0px;
    }

    p {
      margin-bottom: 0px;

      @include mq-up {
        font-size: calc(0.135vw + 12px);
      }
    }

    .drop-area-button {
      font-size: 14px;
    }
  }

  .drop_area {
    max-width: 976px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed #fff;
  }
  .upload-img {
    height: 64px;
    margin-bottom: 24px;
    @include mq-down(sm) {
      height: 40px;
      margin-bottom: 0px;
    }
  }
  input {
    display: none;
  }
}

@mixin container_base {
  max-width: 1040px;
  margin: 96px auto 0;
  color: #424242;

  @include mq-down {
    margin-top: 64px;
  }

  h1, h2 {
    font-size: 48px;
    font-weight: 400;
    margin-bottom: 36px;

    @include mq-down(xxl) {
      font-size: calc(3.33vw + 0px);
      margin-bottom: calc(1.67vw + 12px);
    }

    @include mq-down(xl) {
      font-size: 40px;
      padding: 0 24px;
      margin-bottom: 32px;
    }

    @include mq-down(lg) {
      font-size: 32px;
      padding: 0 24px;
      margin-bottom: 32px;
      font-weight: 500;
    }

    @include mq-down(md) {
      font-size: calc(7vw - 3px);
      margin-bottom: 24px;
      padding: 0 24px;
      font-weight: 500;
    }
  }

  .main-descriptions {
    font-size: 24px;
    line-height: 48px;
    font-weight: 500;

    @include mq-down(xxl) {
      font-size: calc(2.5vw + -12px);
      line-height: calc(5.83vw + -36px);
    }

    @include mq-down(xl) {
      font-size: calc(0.6vw + 12px);
      line-height: calc(1vw + 24px);
    }

    @include mq-down(md) {
      font-size: 18px;
      line-height: 32px;
    }

    @include mq-down(sm) {
      font-size: 14px;
      line-height: 1.6;
    }
  }

  .d-pad {
    padding: 0 40px;
    @include mq-down {
      padding: 0 24px;
    }
  }
}

// Face Refiner、データページへの導線
.lead-other-page {
  @include container_base;
  margin-top: 10px;
  @include mq-down {
    margin-top: 5px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    @include mq-down {
      font-size: 12px;
    }
    @include mq-down(sm) {
      font-size: 10px;
    }
    /deep/ a {
      color: #0D47A1;
      cursor: pointer;
    }
  }
}

.whatis-container {
  @include container_base;
  margin-top: 32px;
  padding: 40px 0px 0;

  @include mq-down {
    margin-top: 0;
  }

  .intro-movie {
    @include mq-down(xxl) {
      margin: 0 calc(-16.67vw + 240px);
    }
    @include mq-down(xl) {
      margin: 0 40px;
    }
    @include mq-down(md) {
      margin: 0 24px;
    }

    .intro-movie-container {
      position: relative;
      padding-top: 56.25%; /*アスペクト比 16:9の場合の縦幅*/
      overflow: hidden;
      box-sizing: border-box;
    }

    .intro-movie-container iframe {
      position: absolute;
      top: 0;
      right: 0;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.container-container {
  @include mq-up(md) {
    padding: 0 56px;
  }

  .every-can-refine {
    @include container_base;
    margin-top: 96px;

    h2 {
      margin-bottom: 120px;

      @include mq-down(md) {
        margin-bottom: initial;
      }
    }

    .comparison-container {
      margin-top: 108px;
      position: relative;

      // @include mq-down(cn) {
      //   margin: 108px auto 0;
      //   max-width: 834px;
      // }
      @include mq-down(md) {
        margin: 68px 0 0;
        padding: 0 24px;
      }
    }

    @mixin text_before_after {
      position: absolute;
      font-size: 40px;
      display: inline-block;
      top: -60px;

      @include mq-down(md) {
        font-size: 30px;
        top: -40px;
        left: 0;
        right: initial;
        transform: none;
      }
    }
    .text-before {
      transform: translateX(-50%);
      left: 180px;
      @include mq-down(lg) {
        left: 120px;
      }
      @include text_before_after;
      font-weight: 400;
    }
    .text-after {
      transform: translateX(50%);
      right: 180px;
      @include mq-down(lg) {
        right: 120px;
      }
      @include text_before_after;
      font-weight: 500;
    }

    @mixin comparison_pic {
      background-size: cover;
      background-repeat: no-repeat;
      max-height: 336px;
      max-width: 560px;
      height: 24vw;
      width: calc(53.8vw - 60px);

      @include mq-down(md) {
        height: 40vw;
        max-width: initial;
        width: initial;
      }
    }
    .comparison-before {
      position: relative;
      top: 0;
      left: 0;

      .before-pic {
        @include comparison_pic;
        background-position-x: right;

        clip-path: polygon(0% 0%, 99% 0%, 85.5% 100%, 0% 100%);
        @include mq-down(md) {
          clip-path: none;
        }
      }
    }

    .comparison-after {
      position: absolute;
      top: 0;
      right: 0;

      @include mq-down(md) {
        position: relative;
        margin-top: 40px;
      }

      .after-pic {
        @include comparison_pic;
        background-position-x: left;

        clip-path: polygon(14.5% 0%, 100% 0%, 100% 100%, 1% 100%);
        @include mq-down(md) {
          clip-path: none;
        }
      }
    }

    .arrow-container {
      background-color: white;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      position: absolute;
      bottom: 118px;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(black, .16);

      @include mq-down(md) {
        top: initial;
        transform: rotate(90deg) translateX(50%);
        height: 18vw;
        width: 18vw;
        bottom: 50%;
      }

      .refine-arrow {
        margin: 24% 16% 0 20%;
      }
    }
  }
}

.oversize-images {
  @include container_base;
  margin-top: 192px;

  @include mq-down {
    margin-top: 64px;
  }

  .oversize-illust {
    margin-top: 112px;

    @include mq-down(xxl) {
      margin: 112px calc(-32vw + 460.8px) 0;
    }
    @include mq-down(cn) {
      margin: 112px 128px 0;
    }
    @include mq-down {
      margin: 40px 12px 0;
    }
  }
}

.usage-steps {
  background-color: #42A5F5;
  margin-top: 108px;
  overflow: hidden;
  @include mq-down(lg) {
    margin-top: 48px;
  }

  .usage-steps-container {
    @include container_base;
    padding-top: 54px;
    padding-bottom: 128px;
    @include mq-down(md) {
      padding-top: 38px;
      padding-bottom: 32px;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .three-steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include mq-down(xxl) {
      margin: 0 calc(-13.33vw + 192px);
    }
    @include mq-down(xl) {
      margin: 0 32px;
    }
    @include mq-down(md) {
      margin: 0;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .one-step {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    &.center {
      @include mq-up(md) {
        margin: 0 56px;
      }
    }

    .step-num {
      width: 48px;
      height: 48px;
      margin: auto;

      @include mq-down(md) {
        height: 40px;
        width: 40px;
        margin-top: 48px;
      }
    }

    h3 {
      margin-top: 2px;
      font-size: calc(1.25vw + 9px);
      font-weight: 500;
      height: 104px;
      margin-bottom: 0;

      @include mq-down(xl) {
        font-size: 24px;
        margin-bottom: 5px;
        margin-left: -24px;
        margin-right: -24px;
      }

      @include mq-down (cn) {
        font-size: 2.2vw;
        transform: translateY(30%);
        height: 7vw;
      }

      @include mq-down(md) {
        height: initial;
        font-size: 24px;
        margin-left: initial;
        margin-right: initial;
      }
    }

    .step-pics {
      margin-top: 16px;
      height: 208px;
      @include mq-down (cn) {
        height: 9vw;
      }
      @include mq-down(md) {
        height: 180px;
      }

      &.step-pic-1 {
        margin: 16px 40px 0;
        @include mq-down(md) {
          margin: 16px 96px 0;
        }
      }

      &.step-pic-2 {
        // max-width: 351px;
        padding: 0 0;
        margin: 16px -16px 0;
        @include mq-down(md) {
          margin: 16px 24px 0;

        }
      }

      &.step-pic-3 {
        margin: 16px 8px 0;
        @include mq-down(md) {
          margin-right: 104px;
          margin-left: 56px;
        }
      }
    }

    p {
      margin-top: 32px;
      font-size: 20px;
      font-weight: 500;
      line-height: 36px;
      max-width: 300px;
      text-align: left;
      margin: 32px auto 0;

      @include mq-down(xl) {
        font-size: 16px;
        line-height: 32px;
      }

      @include mq-down(md) {
        padding: 0 24px;
        max-width: 512px;
        font-size: 14px;
        letter-spacing: 0.28px;
        line-height: 28px;
      }
    }
  }
}

.why-accuracy {
  background-color: #D5EDFF;

  .why-accuracy-container {
    @include container_base;
    padding-top: 96px;
    padding-bottom: 138px;
    @include mq-down {
      padding-top: 25px;
      padding-bottom: 50px;
    }
    margin-top: 0;
    h2 {
      font-size: 60px;
      color: #42A5F5;
      @include mq-down(xxl) {
        font-size: 56px;
      }
      @include mq-down(lg) {
        font-size: 5vw;
      }
      @include mq-down {
        font-size: 25px;
        padding: 0 64px;
      }
    }
    .accuracy-descriptions {
      font-size: 20px;
      line-height: 40px;
      @include mq-down(xl) {
        font-size: 18px;
        line-height: 36px;
      }
      @include mq-down(lg) {
        font-size: calc(0.86vw + 7.4px);
        line-height: calc(2.59vw + 6.1px);
      }
      @include mq-down {
        font-size: 14px;
        line-height: 1.6;
        text-align: left;
        margin-bottom: 18px;
      }
    }
  }
  .accuracy_num {
    width: 72px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    background-color: white;
    border-radius: 50%;
    @include mq-down {
      margin-bottom: 0;
      width: 38px;
    }
    &.one {
      margin-top: 100px;
      @include mq-down {
        margin-top: 48px;
      }
    }
    &.two {
      margin-top: 12px;
      @include mq-down {
        margin-top: 48px;
      }
    }
    &.three {
      margin-top: 68px;
      @include mq-down {
        margin-top: 48px;
      }
    }
  }
  h3 {
    position: relative;
    padding: 0 40px;
    font-size: 30px;
    display: inline-flex;
    margin-bottom: -4px;
    @include mq-down(lg) {
      font-size: 27px;
    }
    @include mq-down {
      display: block;
      font-size: 20px;
      margin-bottom: -4px;
      padding: 0 24px;
    }
    .under-dotted {
      display: inline-block;
      width: 100%;
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/accuracy_h3_dotted.svg);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: auto 24px;
      padding-bottom: 32px;
      @include mq-down {
        padding-bottom: 44px;
      }
    }
  }

  .three-chat-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @include mq-down(md) {
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      margin: 0 24px;
    }
  }
  .one-cat {
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: stretch;
    width: 320px;
    position: relative;
    @include mq-down(xl) {
      margin: 0 24px;
    }

    @include mq-down(md) {
      margin-top: 64px;
      padding: 0 32px;
      width: initial;
    }

    @include mq-down(sm) {
      margin: 0;
      padding: 0;
      &:not(:last-child) {
        margin: 0 0 52px;
      }
    }

    .blue-balloon {
      background-color: #51AAF2;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 1.8vw;
      font-weight: 500;
      margin-bottom: 38px;
      position: relative;
      padding: 11px 14px;
      line-height: 32px;

      @include mq-up(xl) {
        font-size: 20px;
      }

      @include mq-down {
        font-size: 20px;
      }

      &::after {
        content: '';
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/blue_balloon_tail.svg);
        background-position: center;
        background-repeat: no-repeat;
        height: 30px;
        width: 38.84px;
        position: absolute;
        bottom: -26.5px;
        transform: translateX(-50%);
        left: 50%;
      }
    }
    .cat-pics-a {
      margin-bottom: 56px;
    }
    .cat-pics-b {
      position: relative;
      overflow: visible;
      &:before {
        content: '';
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/expansion_dotted_line.svg);
        background-position: bottom center;
        background-repeat: no-repeat;
        height: 100%;
        width: 103%;
        left: -1%;
        margin: auto;
        position: absolute;
        bottom: 99%;
      }

      &.last-cat {
        @include mq-up(md) {
          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border: solid 6px red;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  .last-cat-descriptions-container {
    display: flex;
    justify-content: flex-end;
  }
  .last-cat-descriptions {
    margin-top: 8px;
    width: 320px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    @include mq-down(cn) {
      width: 27%;
      margin-right: 24px;
      font-size: 18px;
      line-height: 26px;
    }
    @include mq-down(md) {
      margin-right: 0;
      padding: 0 80px;
      width: initial;
      float: initial;
      font-size: 16px;
      line-height: 24px;
    }
    @include mq-down(sm) {
      padding: 0 24px;
    }
  }
  .gpu-illust {
    max-width: 920px;
    padding: 0 32px;
    margin: 48px auto 0;
    @include mq-down {
      margin-top: 16px;
    }
  }
  .comparison-heading {
    margin-top: 64px;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;

    @include mq-down {
      margin-top: 20px;
      font-size: 20px;
    }
  }
  .comparison-container {
    position: relative;
    background-color: white;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @include mq-up(md) {
      margin-right: 32px;
      margin-left: 32px;
    }

    @include mq-down(md) {
      padding: 0 24px;
      background-color: initial;
    }
  }

  @mixin comparison_pic {
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 280px;
    height: 27vw;
    max-width: 560px;
    width: 560px;
    width: calc(57vw - 33px);
    @include mq-down(md) {
      max-height: initial;
      max-width: initial;
      height: 32vw;
      width: initial;
    }
  }

  .comparison-before {
    position: relative;
    top: 0;
    left: 0;

    .before-pic {
      @include comparison_pic;
      background-position-x: right;
      clip-path: polygon(0% 0%, 90.5% 0%, 82.5% 100%, 0% 100%);

      @include mq-down(md) {
        clip-path: none;
      }
      &.pic_1 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_1a.jpg);
      }
      &.pic_2 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_2a.jpg);
        @include mq-down {
          background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_2a_sp.jpg);
        }
      }
      &.pic_3 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_3a.jpg);
      }
      &.pic_4 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_4a.jpg);
      }
    }
  }
  .comparison-after {
    position: absolute;
    top: 0;
    right: 0;

    @include mq-down(md) {
      position: relative;
      margin-top: 5px;
    }

    .after-pic {
      @include comparison_pic;
      background-position-x: left;
      clip-path: polygon(17.5% 0%, 100% 0%, 100% 100%, 9.5% 100%);

      @include mq-down(md) {
        clip-path: none;
      }
      &.pic_1 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_1b.jpg);
      }
      &.pic_2 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_2b.jpg);
        @include mq-down {
          background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_2b_sp.jpg);
        }
      }
      &.pic_3 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_3b.jpg);
      }
      &.pic_4 {
        background-image:url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/05_why_accuracy/compare_noise_4b.jpg)
      }
    }
  }
  .arrow-container {
    background-color: white;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    position: absolute;
    bottom: 50%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgba(black, .16);
    transform: translateY(50%);

    @include mq-down(md) {
      transform: rotate(90deg) translateX(50%);
      height: 13vw;
      width: 13vw;
      bottom: 50%;
    }

    .refine-arrow {
      margin: 24% 16% 0 20%;
    }
  }
}

.under-yellow-line {
  font-weight: 600;
  background: linear-gradient(transparent 75%, #FFFF12 25%);
}

.solutions {
  @include container_base;
  margin-bottom: 164px;

  @include mq-down(md) {
    margin-bottom: 0px;
  }
  .balloon-dotted {
    position: relative;
    display: block;
    padding: 32px 32px;
    margin: 136px 0 25px;
    min-width: 120px;
    max-width: 100%;
    color: #424242;
    border: dashed 3px #424242;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: white;
    z-index: 3;
    @include mq-down(md) {
      padding: 8px 16px;
      margin-top: 15px;
    }
    &:before {
      content: "";
      height: 50px;
      width: 60px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      background-color: white;
      transform: translateX(-50%);
      z-index: 1;
      @include mq-down(md) {
        z-index: -1;
        height: 30px;
      }
    }
    &:after {
      content: '';
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/balloondotted_tail.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 51.01px;
      height: 69.8px;
      transform: translateX(-50%);
      left: 50%;
      position: absolute;
      bottom: -47.5px;
      z-index: 0;
      @include mq-down(md) {
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/balloondotted_tail_sp.svg);
        bottom: -20px;
        width: 25px;
        height: 32px;
        z-index: -2;
      }
    }
    .balloon-text {
      font-size: 32px;
      line-height: 48px;
      font-weight: 600;
      position: relative;
      z-index: 3;
      @include mq-down(lg) {
        font-size: 26px;
        line-height: 38px;
      }
      @include mq-down(sm) {
        font-size: 17px;
        line-height: 13px;
      }
    }
  }
  .panel-container {
    padding: 0 30px;
    @include mq-down(md) {
      padding: 0 24px;
    }
  }
  .solutions-panel {
    position: relative;
    display: flex;

    box-shadow: 0px 3px 10px rgba(black, .25);
    @include mq-down {
      flex-direction: column;
      margin: 0 -24px;
    }
    &.reverse {
      @include mq-up {
        flex-direction: row-reverse;
      }
    }
    .panel-description {
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      line-height: 40px;
      padding: 32px 25px;
      margin: 0;
      flex: 1;
      @include mq-down(xxl) {
        font-size: 18px;
        line-height: 36px;
      }
      @include mq-down(lg) {
        font-size: calc(0.86vw + 7.4px);
        line-height: calc(1.72vw + 14.8px);
      }
      @include mq-down(md) {
        font-size: 14px;
        line-height: 1.7;
        padding: 13px 24px 24px;
      }
    }
  }
  @mixin yellow_balloon {
    position: absolute;
    background: #FFFF00;
    border-radius: 4px;
    padding: 16px 20px;
    margin: 0;
    min-height: 80px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    max-width: 400px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    z-index: 5;
    &::before {
      content: '';
      background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/06_solutions/yellow-balloon-tail.svg);
      background-size: cover;
      height: 35px;
      width: 28.86px;
      position: absolute;
      z-index: -1;
    }
    @include mq-down(md) {
      padding: 8px 32px;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      position: relative;
      top: initial;
      left: initial;
      bottom: initial;
      right: initial;
      margin: 16px auto 0;
      justify-content: center;
      min-height: 50px;
      &::before {
        transform: translateX(-50%) rotate(180deg);
        top: -30px;
        left: 50%;
      }
    }
    @include mq-down(sm) {
      margin: 16px 24px 0;
    }
  }
  .solution-pic {
    flex: 0 490px;
    @include mq-down(lg) {
      flex: 1;
    }
    @include mq-down(md) {
      flex: initial;
      margin: 20px 24px 0;
    }
  }
  @mixin title_pic {
    position: absolute;

    @include mq-down(md) {
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: 32px auto 0;
    }
  }
  .panel-1 {
    .title-pic {
      @include title_pic;
      width: 9.8%;
      left: 6%;
      bottom: 24px;
      @include mq-down(md) {
        width: 98px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        bottom: -14px;
        right: 44%;
        &::before {
          transform: translateY(-50%) rotate(-90deg);
          right: -27px;
          top: 50%;
        }
      }
    }
    .panel-description {
      @include mq-up(md) {
        padding-bottom: 70px;
      }
    }
  }
  .panel-2 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-right: 128px;
        padding-left: 128px;
      }
      @include mq-up(lg) {
        padding-right: 156px;
        padding-left: 156px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 17.6%;
      right: -27px;
      bottom: 41px;
      @include mq-down(md) {
        width: 168px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        top: 32px;
        left: -30px;
        &::before {
          transform: translateX(-50%);
          bottom: -30px;
          left: 50%;
        }
      }
    }
  }
  .panel-3 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-left: 64px;
        padding-right: 64px;
      }
      @include mq-up(lg) {
        padding-left: 96px;
        padding-right: 96px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 11.4%;
      left: -30px;
      bottom: 24px;
      @include mq-down(md) {
        width: 98px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        bottom: 32px;
        right: 38%;
        &::before {
          transform: translateY(-50%) rotate(-90deg);
          right: -27px;
          top: 50%;
        }
      }
    }
    .panel-description {
      @include mq-up(md) {
        padding-bottom: 110px;
      }
    }
  }
  .panel-4 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-right: 96px;
        padding-left: 96px;
      }
      @include mq-up(lg) {
        padding-right: 112px;
        padding-left: 112px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 14.4%;
      right: -3%;
      bottom: 36px;
      @include mq-down(md) {
        width: 156px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        max-width: 300px;
        bottom: 22px;
        left: 51.5%;
        &::before {
          transform: translateY(-50%) rotate(90deg);
          left: -27px;
          top: 50%;
        }
      }
    }
    .panel-description {
      @include mq-up(md) {
        padding-bottom: 120px;
      }
    }
  }
  .panel-5 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-left: 128px;
        padding-right: 128px;
      }
      @include mq-up(lg) {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 150px;
      left: -30px;
      bottom: 32px;
      @include mq-down(md) {
        width: 170px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        max-width: 260px;
        bottom: 28px;
        left: 71%;
        &::before {
          transform: translateY(-50%) rotate(90deg);
          left: -27px;
          top: 50%;
        }
      }
    }
  }
  .panel-6 {
    .balloon-dotted {
      @include mq-up(md) {
        padding-right: 156px;
        padding-left: 156px;
      }
      @include mq-up(lg) {
        padding-right: 152px;
        padding-left: 152px;
      }
    }
    .title-pic {
      @include title_pic;
      width: 150px;
      right: 0;
      bottom: 50%;
      @include mq-down(md) {
        width: 160px;
      }
    }
    .yellow-balloon {
      @include yellow_balloon;
      @include mq-up(md) {
        max-width: 340px;
        bottom: 16px;
        left: 35%;
        &::before {
          transform: translateY(-50%) rotate(90deg);
          left: -27px;
          top: 50%;
        }
      }
    }
    .panel-description {
      @include mq-up(md) {
        padding-bottom: 100px;
      }
      letter-spacing: -0.4px;
    }
  }
}
.about-api {
  background-color: #DEDEDE;
  margin-top: 0;
  overflow: hidden;

  .about-api-container {
    @include container_base;
    padding-top: 96px;
    padding-bottom: 138px;
    @include mq-down(md) {
      margin-top: 0;
      padding-top: 25px;
      padding-bottom: 50px;
      h2 {
        margin-top: 0;
        padding: 0 20px;
      }
    }
    margin-top: 0;
  }
  .about-api-heading {
    margin-top: 72px;
    font-size: 48px;
    font-weight: 600;
    line-height: 1.6;
    padding: 0 58px;
    @include mq-down(xxl) {
      font-size: calc(2.38vw + 13.7px);
    }
    @include mq-down(md) {
      margin-top: 0px;
      font-size: 25px;
      line-height: 28px;
      padding: 0 50px;
    }
  }
  .api-descriptions {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    padding: 0 58px;
    @include mq-down(xxl) {
      font-size: calc(0.89vw + 7.1px);
    }
    @include mq-down(md) {
      margin-top: 24px;
      font-size: 16px;
      line-height: 32px;
      padding: 0 24px;
    }
  }
  .main-illust {
    margin: 104px 40px 0;
    position: relative;
    overflow: visible;
    @include mq-down(xxl) {
      margin: 104px calc(-8.93vw + 168.6px) 0;
    }
    @include mq-down(md) {
      margin: 56px 15px 0;
      padding: 0;
    }

    @mixin illust_text {
      position: absolute;
      font-size: 27px;
      font-weight: 600;
      line-height: 1.6;
      left: 50%;
      margin: 0;
      max-width: 380px;
      @include mq-down(xxl) {
        font-size: calc(1.82vw + 0.8px);
      }
      @include mq-down(lg) {
        font-size: calc(2.59vw + 2.1px);
        line-height: 1.6;
        max-width: 280px;
      }
      @include mq-down(md) {
        font-size: 14px;
        line-height: 18px;
        max-width: 110px;
      }
    }
    @mixin small-text-template {
      font-size: 21px;
      @include mq-down(xxl) {
        font-size: calc(1.32vw + 0.8px);
      }
      @include mq-down(md) {
        font-size: 12px;
      }
    }
    .p-request {
      @include illust_text;
      transform: translate(-50%, -100%);
      top: 20%;
      @include mq-down(md) {
        top: 38%;
      }
      &.small-text {
        @include small-text-template;
      }
    }
    .p-get {
      @include illust_text;
      transform: translate(-50%, 0%);
      top: 72%;
      @include mq-down(md) {
        top: 72%;
      }
      &.small-text {
        @include small-text-template;
      }
    }
    .p-service {
      @include illust_text;
      font-size: 170%;
      line-height: 32px;
      letter-spacing: 0.96px;
      transform: translate(50%, 0%);
      width: 50%;
      top: 86%;
      left: initial;
      right: 15%;
      max-width: 40%;
      @include mq-down(lg) {
        font-size: 2.2vw;
      }
      @include mq-down(md) {
        font-size: 2.2vw;
        line-height: 20px;
        top: 84%;
        left: initial;
        right: 16%;
      }
      &.small-text {
        @include small-text-template;
      }
    }
  }
  .api-btn {
    margin-top: 32px;
    width: 900px;
    height: 184px;
    font-size: 45px;
    font-weight: 500;
    line-height: 43px;
    border-radius: 999px;
    color: white;
    background-color: #707070 !important;
    .dotted {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px dashed white;
      border-radius: 99px;
      height: 164px;
      width: 880px;
    }
    @include mq-down(lg) {
      width: 327px;
      height: 80px;
      font-size: 16px;
      line-height: 14px;
      font-weight: 500;
      .dotted {
        border: 2px dashed white;
        width: 317px;
        height: 70px;
      }
    }
    @media screen and (max-width:350px) {
      width: 290px;
      max-width: 82vw;
      font-size: 11px;
      .dotted {
        width: 280px;
        max-width: 78vw;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
  .sub-illust {
    margin: 96px 0px 0;
    position: relative;
    overflow: visible;
    @include mq-down(lg) {
      margin: 0 58px;
    }
    @include mq-down(md) {
      margin: 56px 15px 0;
    }

    @mixin illust_text {
      position: absolute;
      font-size: 160%;
      font-weight: 600;
      line-height: 28px;
      margin: 0;
      max-width: 380px;
      @include mq-down(lg) {
        font-size: 2vw;
        line-height: 2.4vw;
      }
    }
    .p-0 {
      @include illust_text;
      line-height: 28px;
      font-size:  250%;
      line-height: 40px;
      transform: translate(-50%, -50%);
      max-width: 175px;
      top: 44%;
      left: 53.2%;
      @include mq-down(lg) {
        font-size: 3vw;
        max-width: 15vw;
        line-height: 3vw;
      }
    }
    .p-1 {
      @include illust_text;
      transform: translate(-50%, -50%);
      max-width: 280px;
      top: 15.5%;
      left: 53.4%;
    }
    .p-2 {
      @include illust_text;
      transform: translate(100%, -50%);
      max-width: 280px;
      top: 24%;
      right: 35%;
      text-align: left;
    }
    .p-3 {
      @include illust_text;
      transform: translate(100%, -100%);
      max-width: 20%;
      top: 52%;
      text-align: left;
      right: 20%;
    }
    .p-4 {
      @include illust_text;
      transform: translate(100%, -50%);
      max-width: 250px;
      top: 71.4%;
      text-align: left;
      right: 39%;
    }
    .p-5 {
      @include illust_text;
      transform: translate(-100%, -50%);
      max-width: 250px;
      top: 71.4%;
      text-align: right;
      left: 44%;
    }
    .p-6 {
      @include illust_text;
      transform: translate(-100%, -100%);
      max-width: 160px;
      top: 49%;
      text-align: right;
      left: 28%;
      @include mq-down(lg) {
        max-width: 17vw;
      }
    }
    .p-7 {
      @include illust_text;
      transform: translate(-100%, -50%);
      max-width: 210px;
      top: 28%;
      left: 31%;
      text-align: right;
      @include mq-down(lg) {
        max-width: 20vw;
      }
    }
  }
  .sub-illust-sp-0 {
    margin: 0 24px 16px;
    .paragraph {
      position: absolute;
      font-size: 3.8vw;
      font-weight: 600;
      line-height: 4.2vw;
      margin: -8px 0 0;
      max-width: 21vw;
      transform: translate(-50%, -50%);
      top: 30%;
      left: 50%;
      text-align: center;
    }
  }
  .sub-illust-contaienr {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    overflow: hidden;

    @mixin sub_illust_p {
      margin-top: 12px;
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      padding: 0 3px;
      text-align: center;
    }
    @mixin sub_illust_sp {
      margin: auto;
    }
    .sub-illust-sp-1 {
      @include sub_illust_sp;
      width: 90px;
      height: 140px;
    }
    .sub-illust-sp-2 {
      @include sub_illust_sp;
      width: 136px;
      height: 140px;
    }
    .sub-illust-sp-3 {
      @include sub_illust_sp;
      width: 130px;
      height: 160px;
    }
    .sub-illust-sp-4 {
      @include sub_illust_sp;
      width: 165px;
      height: 160px;
    }
    .sub-illust-sp-5 {
      @include sub_illust_sp;
      width: 165px;
      height: 150px;
    }
    .sub-illust-sp-6 {
      @include sub_illust_sp;
      width: 94px;
      height: 150px;
    }
    .sub-illust-sp-7 {
      @include sub_illust_sp;
      width: 130px;
      height: 145px;
    }
    .paragraph {
      @include sub_illust_p;
    }

  }

}

</style>

<i18n src="@/locales/photo_refiner/top.json"></i18n>
