import Cookies from 'js-cookie'
import Vue from 'vue'
import Router from 'vue-router'

import AuthCallback from './components/AuthCallback'
import AuthService from '@/auth/AuthService'
import ColorBoosterTop from './views/ColorBooster/Top.vue'
import FrameExtenderTop from './views/FrameExtender/Top.vue'
// import FocusCorrectorTop from './views/FocusCorrector/Top.vue'
import Home from './views/Home.vue'
import InpainterTop from './views/Inpainter/Top.vue'
import LayerDecomposerTop from './views/LayerDecomposer/Top.vue'
import Login from './views/Login.vue'
import LineDrawerTop from './views/LineDrawer/Top.vue'
import MonoPainterTop from './views/MonoPainter/Top.vue'
import PortraitDrawerTop from './views/PortraitDrawer/Top.vue'
import AnimeArtPainterTop from './views/AnimeArtPainter/Top.vue'
import FaceRefinerTop from './views/FaceRefiner/Top.vue'
import MovieRefinerTop from './views/MovieRefiner/Top.vue'
import MovingPhotoMakerTop from './views/MovingPhotoMaker/Top.vue'
import MovingPhotoMakerSamples from './views/MovingPhotoMaker/Samples.vue'
import OutlineClipperTop from './views/OutlineClipper/Top.vue'
import PhotoRefinerTop from './views/PhotoRefiner/Top.vue'
import PngSmallifyTop from './views/PngSmallify/Top.vue'
import Price from './views/Price.vue'
import Privacy from './views/Privacy.vue'
import SaiChanTop from './views/SaiChan/Top.vue'
import SaiChanMake from './views/SaiChan/Make.vue'
import SaiChanPlusTop from './views/SaiChan/Plus/Top.vue'
import SaiScrollMagic from './views/SaiChan/SaiScrollMagic.vue'
import AiliaAiRefinerTop from './views/AiliaAiRefiner/Top.vue'
import Anime4kPlayerTop from './views/Anime4kPlayer/Top.vue'
import store from './store/index'
import EnpainterTop from './views/Enpainter/Top.vue'
import Terms from './views/Terms.vue'
import Commercial from './views/Commercial.vue'
import License from './views/License.vue'
import Welcome from './views/Welcome.vue'
import NotFound from './views/NotFound.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/')
        next()
      }
    },
    {
      path: '/auth_cb',
      name: 'authCb',
      component: AuthCallback
    },
    { // Googleのedge cacheに /auth_cbが半日キャッシュされているので防ぐために別URLにしたい
      path: '/auth_cb2',
      name: 'authCb2',
      component: AuthCallback
    },
    {
      path: '/auth_cb3',
      name: 'authCb3',
      component: AuthCallback
    },
    {
      path: '/pr',
      name: 'pr',
      component: PhotoRefinerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/pr')
        next()
      }
    },
    { // Googleのedge cache対策
      path: '/pr2',
      name: 'pr2',
      component: PhotoRefinerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/pr')
        next()
      }
    },
    {
      path: '/sc',
      name: 'sc',
      component: SaiChanTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/sc')
        next()
      }
    },
    {
      path: '/sc/make',
      name: 'scMake',
      component: SaiChanMake,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/sc/make')
        next()
      }
    },
    {
      path: '/sc/ssm',
      name: 'SaiScrollMagic',
      component: SaiScrollMagic,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/sc/ssm')
        next()
      }
    },
    { // 当初 /sc/plusにしようとしたが、SEO観点からscplusに変更
      path: '/scplus',
      name: 'scPlus',
      component: SaiChanPlusTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/scplus')
        next()
      }
    },
    {
      // このページはプリレンダリング対象外
      path: '/scplus/complete',
      name: 'scPlusOrderCompleted',
      component: () => import('./views/SaiChan/Plus/OrderCompleted.vue'),
      beforeEnter: function (to, from, next) {
        if (to.params.fromSaichanPlusOrderPage) {
          // 彩ちゃん+のクレジット決済が入ったら、決済完了後ここを通る
          next()
        } else {
          store.commit('saichan/restore')
          if (store.state.saichan.restored) {
            store.commit('saichan/unsetRestored')
            // 彩ちゃん+のStripe決済完了後に遷移してきた場合
            next()
            // ログイン完了を待ってgtag event発火
            store.state.user.logined$.subscribe({
              next: (logined) => {
                if (logined) {
                  const group = store.getters['user/currentGroup']
                  /*global gtag*/
                  gtag('event', 'payment_complete', {
                    'event_category': 'payment',
                    'event_label': 'pay_as_you_go_scplus',
                    'value': group.subscription_id
                  })
                }
              }
            })
          } else {
            // URL直叩きで来た時はtopに遷移させる
            next('/')
          }
        }
      }
    },
    {
      path: '/ep',
      name: 'ep',
      component: EnpainterTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/ep')
        next()
      }
    },
    {
      path: '/ep/:requestId',
      name: 'epResult',
      component: () => import('./views/Enpainter/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            // NOTE. nextには router.pushと同じオプションを指定できる
            //  少し待ってから遷移しないと、App.vueの getMasterDataで謎のNetwork Errorが出てエラーダイアログが表示されることがある
            //  (loginへの遷移動作or prerenderingのHTMLをi18nの言語で再renderingするのに干渉している？)
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/lid',
      name: 'lid',
      component: LineDrawerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/lid')
        next()
      }
    },
    {
      path: '/lid/:requestId',
      name: 'lidResult',
      component: () => import('./views/LineDrawer/Result.vue'),
      beforeEnter: function (to, from, next) {
        // epResultと同じ内容
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/mpm',
      name: 'mpm',
      component: MovingPhotoMakerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/mpm')
        next()
      }
    },
    {
      path: '/mpm/samples',
      name: 'mpmSamples',
      component: MovingPhotoMakerSamples,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/mpm/samples')
        next()
      }
    },
    {
      path: '/mpm/complete',
      name: 'mpmComplete',
      component: () => import('./views/MovingPhotoMaker/Complete.vue'),
      beforeEnter: function (to, from, next) {
        // epResultと内容はほぼ同じ
        if (AuthService.isAuthenticated(2) && to.query.req) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/mp',
      name: 'mp',
      component: MonoPainterTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/mp')
        next()
      }
    },
    {
      path: '/mp/:requestId',
      name: 'mpResult',
      component: () => import('./views/MonoPainter/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/pd',
      name: 'pd',
      component: PortraitDrawerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/pd')
        next()
      }
    },
    {
      path: '/pd/:requestId',
      name: 'pdResult',
      component: () => import('./views/PortraitDrawer/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/aap',
      name: 'aap',
      component: AnimeArtPainterTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/aap')
        next()
      }
    },
    {
      path: '/aap/:requestId',
      name: 'aapResult',
      component: () => import('./views/AnimeArtPainter/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/ps',
      name: 'ps',
      component: PngSmallifyTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/ps')
        next()
      }
    },
    {
      path: '/ps/:requestId',
      name: 'psResult',
      component: () => import('./views/PngSmallify/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/fr',
      name: 'fr',
      component: FaceRefinerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/fr')
        next()
      }
    },
    {
      path: '/fr/:requestId',
      name: 'frResult',
      component: () => import('./views/FaceRefiner/Result.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/mr',
      name: 'mr',
      component: MovieRefinerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/mr')
        next()
      }
    },
    {
      path: '/ip',
      name: 'ip',
      component: InpainterTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/ip')
        next()
      }
    },
    {
      path: '/oc',
      name: 'oc',
      component: OutlineClipperTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/oc')
        next()
      }
    },
    {
      path: '/cb',
      name: 'cb',
      component: ColorBoosterTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/cb')
        next()
      }
    },
    {
      path: '/fe',
      name: 'fe',
      component: FrameExtenderTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/fe')
        next()
      }
    },
    // {
    //   path: '/fc',
    //   name: 'fc',
    //   component: FocusCorrectorTop,
    //   beforeEnter: function (to, from, next) {
    //     store.commit('user/setStayingServicePath', '/fc')
    //     next()
    //   }
    // },
    {
      path: '/ld',
      name: 'ld',
      component: LayerDecomposerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/ld')
        next()
      }
    },
    {
      path: '/aar',
      name: 'aar',
      component: AiliaAiRefinerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/aar')
        next()
      }
    },
    {
      path: '/anime4k',
      name: 'anime4k',
      component: Anime4kPlayerTop,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/anime4k')
        next()
      }
    },
    {
      path: '/anime4k/complete/:saleId',
      name: 'aninme4kPurchaseCompleted',
      component: () => import('./views/Anime4kPlayer/PurchaseCompleted.vue'),
      beforeEnter: function (to, from, next) {
        if (AuthService.isAuthenticated(2) && to.params.saleId) {
          next()
        } else {
          if (!AuthService.isAuthenticated(2)) {
            setTimeout(() => { next({name: 'login', params: {landingPath: to.path}}) }, 350)
          } else {
            next('/')
          }
        }
      }
    },
    {
      path: '/pr/:requestId',
      name: 'prResult',
      component: () => import('./views/PhotoRefiner/Success.vue'),
      beforeEnter: function (to, from, next) {
        if ((AuthService.isAuthenticated(2) && to.params.requestId) || (AuthService.guestExec() && to.params.requestId)) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/price',
      name: 'price',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      //component: () => import(/* webpackChunkName: "about" */ './views/Price.vue')
      component: Price
    },
    {
      path: '/data',
      name: 'dataPR',
      component: () => import('./views/Data/Top.vue'),
      beforeEnter: function (to, from, next) {
        if (AuthService.isAuthenticated(2)) {
          next('/data/pr') // /data/:serviceNameの方で処理される
        } else {
          next('/')
        }
      }
    },
    { // /data/pr もこちらで処理される
      path: '/data/:serviceName',
      name: 'data',
      component: () => import('./views/Data/Top.vue'),
      beforeEnter: function (to, from, next) {
        console.log(' data/:service:' + to.params.serviceName)
        if (!['pr', 'sc', 'ep', 'mpm', 'lid', 'mp', 'pd', 'aap', 'ps', 'fr', 'anime4k'].includes(to.params.serviceName)) {
          next('/')
          return
        }
        if (AuthService.isAuthenticated(2)) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/my',
      name: 'my',
      component: () => import('./views/MyPage.vue'),
      beforeEnter: function (to, from, next) {
        if (AuthService.isAuthenticated(2)) {
          next()
        } else {
          next('/')
        }
      }
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/commercial',
      name: 'commercial',
      component: Commercial
    },
    {
      path: '/license',
      name: 'license',
      component: License
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome
    },
    {
      path: '*',
      name: 'notFound',
      component: NotFound,
      beforeEnter: function (to, from, next) {
        store.commit('user/setStayingServicePath', '/notfound')
        next()
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  window.scroll(0, 0)
  // Auth0のメールから遷移してきたときには、redirectByLocale=trueでTLDに来るので、
  // (ログイン前に)cookieに保存したサブドメインにredirectさせる
  if (to.query.redirectByLocale) {
    const locale = Cookies.get('locale')
    if (locale) {
      if (locale != 'en') {
        location.replace(`${location.protocol}//${locale}.${location.host}${location.pathname}`)
      } else {
        location.replace(`${location.protocol}//${location.host}${location.pathname}`)
      }
      return
    }
  }

  if (gtag) {
    gtag('event', 'screen_view', { 'screen_name': to.name })
  }
  next()
})

export default router
