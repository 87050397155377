<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t("glb_scp_2") }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t("glb_scp_3") }}</h3>

      <div class="select-container">
        <div class="selection-item" for="select1" @click="select(false)">
          {{ $t('glb_scp_4') }}
        </div>
        <div class="selection-item" for="select2" @click="select(true)">
          {{ $t('glb_scp_5') }}
        </div>
      </div>
      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      shouldAdjust: this.$store.state.saichanPlus.faceAdjust
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    select(value) {
      this.shouldAdjust = value
      this.$emit('faceOrderEdited', this.shouldAdjust)
    },
    close() {
      this.$emit('close');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_sc-plus-form.scss";

.selection-item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin: 0;
  background-color: white;
  border-radius: 99px;
  width: 280px;
  height: 72px;

  &::before {
    border-radius: 99px;
  }

  @include mq-down(md) {
    width: initial;
    font-size: 14px;
    padding: 20px 12px;
    &:first-child {
      margin-bottom: 16px;
    }
  }

  @include mq-down(sm) {
    height: initial;
  }
}

.form-heading {
  margin-top: 44px;
  margin-bottom: 80px;

  @include mq-down {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.slick-dot-1 {
  background-color: #8A8A8A;
}
</style>