import Vue from 'vue'
import Vuetify from 'vuetify'
import Toasted from 'vue-toasted';
import Ads from 'vue-google-adsense'

import App from './App.vue'
import router from './router'
import store from './store/index'
import { } from './requests'
import 'vuetify/dist/vuetify.min.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck, faCaretDown, faChevronRight, faChevronDown, faPlus, faUser, faArrowDown, faSignInAlt, faUserPlus, faUndoAlt, faDice, faSearchPlus, faEllipsisH, faEllipsisV, faHome, faHistory, faCircle } from '@fortawesome/free-solid-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './i18n'
import execDownload from './mixins/execDownload'
import langUtil from './mixins/langUtil'

Vue.config.productionTip = false
Vue.use(Toasted)
Vue.use(Vuetify)
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)
Vue.mixin(execDownload)
Vue.mixin(langUtil)

library.add(faCheck, faTwitter, faCaretDown, faChevronRight, faChevronDown, faPlus, faUser, faArrowDown, faSignInAlt, faUserPlus, faUndoAlt, faDice, faSearchPlus, faEllipsisH, faEllipsisV, faHome, faHistory, faCircle)
Vue.component('font-awesome-icon', FontAwesomeIcon)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  mounted() {
    // このeventをhookにprerenderingが行われる (npm run build-prod or build-stage 実行時)
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
