<template>
  <div id="service_header">
    <div class="specific shd-s">
      <div class="header-container">
        <div class="left-container">
          <!-- 滞在中サービスが無い時(総合TOPを踏んだ後)は、サービスロゴと同じサイズのelementを表示することでタブの位置ずれを防止する -->
          <img class="transparent-dummy-logo" v-if="stayingServicePath === '/'" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">
          <!-- 他AIショートカット -->
          <v-menu offset-y transition="slide-y-transition" v-else>
            <div slot="activator" class="py-3 ml-2 pl-1 pr-3 title-container" v-ripple="{ class: `blue--text text--accent-1` }">
              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/pr'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/pr_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/pr'" width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/pr_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/fr'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fr/fr_title.svg" height="24">
              <img class="ml-1 mr-3 display-sp" v-if="stayingServicePath === '/fr'" width="32" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fr/fr_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/sc'" :src='$t("svh_30")' height="24">
              <img class="ml-1 mr-3 display-sp sai-chan" v-if="stayingServicePath === '/sc'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/scplus'" :src='$t("svh_33")' height="29">
              <v-img class="ml-1 mr-3 display-sp sai-chan-plus" v-if="stayingServicePath === '/scplus'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
                <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
              </v-img>

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/ep'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/ep_title.svg" height="30">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/ep'" width="18" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath.startsWith('/mpm')" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/mpm_title.svg" height="28">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath.startsWith('/mpm')" width="26" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/mpm_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/lid'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/lid/lid_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/lid'" width="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/lid/icon/lid_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/mp'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/mp_title_new.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/mp'" width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/icon/mp_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/pd'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pd/pd_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/pd'" width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pd/icon/pd_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/aap'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aap/aap_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/aap'" width="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aap/aap_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/ps'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ps/ps_title.svg" height="28">
              <img class="ml-1 mr-3 display-sp" v-if="stayingServicePath === '/ps'" width="36" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ps/ps_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/mr'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/mr'" width="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/ip'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ip/ip_title.svg" height="30">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/ip'" height="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ip/ip_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/oc'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_title.svg" height="30">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/oc'" height="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/oc/oc_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/cb'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/cb/cb_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/cb'" width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/cb/cb_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/fe'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fe/fe_title.svg" height="24">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/fe'" width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fe/fe_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/ld'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ld/ld_title.svg" height="26">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/ld'" width="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ld/ld_logo.svg">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/aar'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/aar_title.svg" height="26">
              <img class="mr-3 display-sp" v-if="stayingServicePath === '/aar'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/aar_title_sp.svg" height="24">

              <img class="service-title mr-3 display-pc" v-if="stayingServicePath === '/anime4k'" src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/anime4k_title.svg" height="26">
              <img class="ml-2 mr-3 display-sp" v-if="stayingServicePath === '/anime4k'" width="26" src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/anime4k_logo.svg">
              <font-awesome-icon icon="chevron-down" class="title mt-2"/>
            </div>
            <v-card class="menu-container py-3 px-2">
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <router-link to="/pr">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/pr_logo.svg" :alt="$t('svh_10')">
                        </div>
                        <h3 class="pr">{{ $t("svh_10") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_20") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/fr">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="29" src="https://storage.googleapis.com/cre8tiveai_public_files/img/fr/fr_logo.svg" :alt="$t('svh_42')">
                        </div>
                        <h3 class="fr">{{ $t("svh_46") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_47") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/sc">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img class="saichan" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" :alt="$t('svh_29')">
                        </div>
                        <h3 class="sc">{{ $t("svh_29") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_31") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <!-- 彩ちゃん＋ -->
                <v-flex xs12 sm6>
                  <router-link to="/scplus">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <v-img class="saichan-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" :alt="$t('svh_29') + '+'">
                            <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg" :alt="$t('svh_29') + '+'"></v-img>
                          </v-img>
                        </div>
                        <h3 class="sc-plus">
                          {{ $t("svh_29") }}
                          <span class="super">+</span>
                        </h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_32") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/ep">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="17" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg">
                        </div>
                        <h3 class="st">{{ $t("svh_34") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_35") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/mpm">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="26" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/mpm_logo.svg">
                        </div>
                        <h3 class="mpm">{{ $t("svh_36") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_37") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/lid">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="24" src="https://storage.googleapis.com/cre8tiveai_public_files/img/lid/icon/lid_logo.svg">
                        </div>
                        <h3 class="lid">{{ $t("svh_38") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_39") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/mp">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="16" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/icon/mp_logo.svg" :alt="$t('svh_16')">
                        </div>
                        <h3 class="mp">{{ $t("svh_16") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_26") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/pd">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="17" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pd/icon/pd_logo.svg" :alt="$t('svh_40')">
                        </div>
                        <h3 class="pd">{{ $t("svh_40") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_41") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/aap">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="22" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aap/aap_logo.svg" :alt="$t('svh_42')">
                        </div>
                        <h3 class="aap">{{ $t("svh_42") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_43") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/ps">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="32" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ps/ps_logo.svg" :alt="$t('svh_44')">
                        </div>
                        <h3 class="ps">{{ $t("svh_44") }}</h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_45") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/mr">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="24" src="../assets/img/SM-logo.svg" :alt="$t('svh_11')">
                        </div>
                        <h3 class="mr">{{ $t("svh_11") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_21") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <!-- <v-flex xs12 sm6>
                  <router-link to="/fc">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="32" src="../assets/img/fc/fc_logo.svg">
                        </div>
                        <h3 class="fc">{{ $t("svh_18") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_28") }}</p>
                    </div>
                  </router-link>
                </v-flex> -->
                <v-flex xs12 sm6>
                  <router-link to="/ip">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img height="24" src="../assets/img/ip/ip_logo.svg" :alt="$t('svh_12')">
                        </div>
                        <h3 class="ip">{{ $t("svh_12") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_22") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/oc">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img height="24" src="../assets/img/oc/oc_logo.svg" :alt="$t('svh_13')">
                        </div>
                        <h3 class="oc">{{ $t("svh_13") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_23") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/cb">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="16" src="../assets/img/cb/cb_logo.svg" :alt="$t('svh_14')">
                        </div>
                        <h3 class="cb">{{ $t("svh_14") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_24") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/fe">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="16" src="../assets/img/fe/fe_logo.svg" :alt="$t('svh_15')">
                        </div>
                        <h3 class="fe">{{ $t("svh_15") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_25") }}</p>
                    </div>
                  </router-link>
                </v-flex>
                <v-flex xs12 sm6>
                  <router-link to="/ld">
                    <div class="service-container" v-ripple>
                      <div class="service-name">
                        <div class="image-container">
                          <img width="24" src="../assets/img/ld/ld_logo.svg" :alt="$t('svh_17')">
                        </div>
                        <h3 class="ld">{{ $t("svh_17") }} <span>[{{ $t("svh_19") }}]</span></h3>
                      </div>
                      <p class="display-pc">{{ $t("svh_27") }}</p>
                    </div>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-card>
          </v-menu>

          <template v-if="stayingServicePath !== '/aar'">
            <v-tabs v-model="selectedTab" slot="extension" slider-color="pink" class="tab">
              <template v-if="stayingServicePath === '/anime4k'">
                <v-tab v-if="logined" class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp, 'pb-2':$vuetify.breakpoint.smAndDown}" @click="moveToStayingServiceTop">
                  {{ $t("svh_1") }}
                </v-tab>
              </template>
              <template v-else>
                <v-tab class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp, 'pb-2':$vuetify.breakpoint.smAndDown}" @click="moveToStayingServiceTop">
                  {{ $t("svh_1") }}
                </v-tab>
                <v-tab class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp, 'pb-2':$vuetify.breakpoint.smAndDown}" to="/price">
                  {{ $t("svh_2") }}
                </v-tab>
              </template>
              <v-tab v-if="logined && stayingServicePath !== '/scplus'" class="subheading blue--text text--darken-4" :class="{'mx-3': $vuetify.breakpoint.smAndUp, 'pb-2':$vuetify.breakpoint.smAndDown}" :to="`/data${stayingServicePath}`">
                {{ $t("svh_3") }}
              </v-tab>
            </v-tabs>
          </template>
        </div>

        <div class="account">
          <v-layout v-if="!logined">

            <v-btn class="create_account px-4 display-pc" @click="login('account')" round dark>
              {{ $t("svh_6") }}
            </v-btn>
            <v-btn class="display-pc" @click="login('login')" flat color="indigo darken-3">
              {{ $t("svh_7") }}
            </v-btn>

            <v-btn class="display-sp mr-2 text-capitalize" @click="login('login')" color="blue accent-3" dark round>
              <font-awesome-icon icon="sign-in-alt" class="mr-2"/>
              {{ $t("svh_7") }}
            </v-btn>
          </v-layout>
          <div v-else>
            <v-menu offset-y left>

              <v-btn slot="activator" color="primary" class="display-pc text-lowercase" round outline >
                <font-awesome-icon icon="user" class="mr-2"/>
                {{ this.$store.getters['user/currentGroup'].name }}
              </v-btn>
              <v-btn slot="activator" color="primary" class="display-sp mr-3 headline" fab small flat>
                <font-awesome-icon icon="user"/>
              </v-btn>

              <v-list class="py-0">
                <v-subheader class="font-weight-bold mt-2">
                  {{ $t("svh_5") }}
                </v-subheader>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title class="mx-2">
                      {{ this.$store.getters['user/currentGroup'].name }}  ( {{ $t("svh_9") }} )
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-btn @click="toMypage" dark round outline color="blue" class="px-5 mb-4">
                  {{ $t("svh_4") }}
                </v-btn>
                <v-divider></v-divider>

                <div v-if="this.$store.getters['user/otherGroups'].length > 0">
                  <v-subheader class="font-weight-bold">
                    {{ $t("svh_5") }}
                  </v-subheader>
                  <v-list-tile v-for="(g, idx) in this.$store.getters['user/otherGroups']" :key="idx"
                    @click="changeGroupTo(g.id)"
                    :style="{cursor: 'pointer'}"
                  >
                    <v-list-tile-title class="mx-2">{{ g.name }}</v-list-tile-title>
                  </v-list-tile>
                </div>
              <v-divider></v-divider>
              <v-layout align-center justify-end row fill-height>
                <v-btn @click="logout" flat round color="error">{{ $t("svh_8") }}</v-btn>
              </v-layout>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '../router'
export default {
  name: 'ServiceHeader',
  props: {
    auth: Object,
    navtab: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      pathName: window.location.pathname,
      selectedTab: this.navtab
    }
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    stayingServicePath() {
      if (this.$store.state.user.stayingServicePath == '/sc/make') {
        return '/sc'
      }
      return this.$store.state.user.stayingServicePath || '/'
    }
  },
  methods: {
    login(focusedTab) {
      this.auth.login(focusedTab)
    },
    logout() {
      this.auth.logout()
      this.$store.commit('user/logout')
    },
    toMypage() {
      router.push('/my')
    },
    changeGroupTo(groupId) {
      this.$store.commit('user/setCurrentGroup', groupId)
      this.$store.dispatch('user/getMembers')
      router.push('/')
    },
    moveTo(path) {
      router.push(path)
    },
    moveToStayingServiceTop() {
      router.push(this.stayingServicePath)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
#service_header {
  position: sticky;
  top: 0;
  z-index: 200;
  .specific {
    height: 56px;
    background-color: #ffffff;
    position: relative;

    .header-container {
      max-width: 1040px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      /deep/ .v-tabs__slider {
        height: 4px;
      }
      .left-container {
        height: 56px;
        display: flex;
        align-items: center;
        overflow: auto;

        .transparent-dummy-logo {
          height: 24px;
          width: 212px;
        }
        .title-container {
          border-radius: 8px;
          display: flex;
          align-items: center;
          max-height: 56px;
          .service-title {
            cursor: pointer;
          }
          color: #E0E0E0;
          transition: .2s;
          &:hover {
            color: #E91E63;
            background-color: #E3F2FD;
          }
          .sai-chan {
            margin-left: -16px;
            border-radius: 99px;
            width: 48px;
            border: solid 2px #E91E63;
          }
        }
        .tab {
          // overflow: scroll;
          align-self: flex-end;
          font-weight: 500;
          text-decoration: none;
          margin-left: 48px;
          @include mq-down {
            margin-left: 0px;
          }
        }
      }
      .account {
        .create_account {
          background: linear-gradient(90deg, #37CAE7, #234EB5);
          font-weight: bold;
        }
        .logout-btn {
          position: absolute;
          right: 0px;
        }
      }
    }
  }
}
.sai-chan-plus {
  @include sai_face($width: 48px);
  margin-left: -16px;
  box-sizing: border-box;
  border-color: #D780EB;
  border-width: 3px !important;
  /deep/ &>.v-responsive__content,
  /deep/ &>.v-image__image {
    border-radius: 50% !important;
  }
  .purple-plus {
    top: -12%;
    right: -42%;
    width: 40%;
    height: 40%;
  }
}
/deep/ .v-tabs__item {
  &:hover {
    opacity: 1 !important;
    text-decoration: none;
  }
}
// 他AIショートカットメニュー
.menu-container {
  max-width: 624px;
  margin-left: auto;
  margin-right: auto;

  a {
    text-decoration: none;
  }

  .service-container {
    max-width: 292px;
    // min-height: 56px;
    width: 100%;
    padding: 12px 12px;
    margin: 4px;
    border-radius: 4px;
    transition: 0.2s;
    vertical-align: center;

    @include mq-down {
      white-space: normal;
      padding: 8px 8px;
      margin: 0px;
    }

    &:hover {
      background-color: #EEEEEE;
    }

    .service-name {
      display: flex;
      text-align: left;

      .image-container {
        min-width: 32px;
        height: 32px;
        vertical-align: middle;
        text-align: center;
        .saichan {
          width: 32px;
          border-radius: 99px;
          border: solid 2px #EC407A;
        }
        .saichan-plus {
          @include sai_face(32px);
          border-width: 2px !important;
          border-color: #D780EB;
          box-sizing: border-box;
          /deep/ &>.v-responsive__content,
          /deep/ &>.v-image__image {
            border-radius: 50%;
          }

          .purple-plus {
            position: absolute;
            top: -27%;
            right: -32%;
            width: 42%;
            height: 42%;
            background-image: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg');
            background-size: cover;
            background-position: center;
          }
        }
      }
      h3 {
        display: inline-block;
        vertical-align: middle;
        margin: 0px 0px 0px 8px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.4;
        &.sc {
          line-height: 1.9;
        }

        span {
          white-space: nowrap;
        }

      }
    }
    p {
      margin: 8px;
      font-size: 13px;
      color: #616161;
    }
  }
}
</style>

<i18n src="@/locales/components/service_header.json"></i18n>
