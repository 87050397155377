import {SUBDOMAIN_LIST} from '../settings'

// HTMLはTLD or サブドメインから取得されるが、
// 取得したのと同じドメインに着地させる
// とり得るurlは auth0ダッシュボードのApplications > frontを選択 > Allowed Callback URLsに登録する
function callbackURLBySubdomain() {
  let url = process.env.VUE_APP_AUTH0_CALL_BACK_URL_TEMPLATE.replace(/:\/\/{}/, '://')
  for (const subdomain of SUBDOMAIN_LIST) {
    if (location.hostname.startsWith(subdomain + '.')) {
      url = process.env.VUE_APP_AUTH0_CALL_BACK_URL_TEMPLATE.replace(/:\/\/{}/, `://${subdomain}.`)
      break
    }
  }
  return url
}

export const AUTH_CONFIG = {
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  audience: process.env.VUE_APP_AUTH0_AUDIENCE,
  callbackUrl: callbackURLBySubdomain()
}
