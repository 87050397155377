<template>
  <div v-if="isAdsenseTarget" align="center" class="ad-container pt-3 pb-1">
    <!-- cre8tiveAI_inference（カスタム） -->
    <Adsense
      ins-style="display:inline-block;width:100%;"
      data-ad-client="ca-pub-6827265287349767"
      data-ad-slot="5374216263">
    </Adsense>
  </div>
</template>

<script>
export default {
  name: 'AdInference',
  data () {
    return {
    }
  },
  computed: {
    isAdsenseTarget() {
      // 非ログインの場合は広告を表示する
      if (!this.$store.getters['user/logined']) {
        return true
      }
      // エントリープランの場合は広告を表示する
      if (this.currentGroup && this.currentSubscription.grouping_id === 1) {
        return true
      }
      // 有料プランの場合は広告を表示しない
      return false
    },
    currentGroup() {
      return this.$store.getters['user/currentGroup']
    },
    currentSubscription() {
      return this.$store.getters['master/subscriptionById'](this.currentGroup.subscription_id)
    },
    stayingServicePath() {
      return this.$store.state.user.stayingServicePath || '/'
    },
  },
  mounted() {
    // 推論モーダル内に広告が表示されたら、プログレスバーの位置（進捗と広告の両方が見える位置）に移動する
    setTimeout(() => {
      const targetClass = this.stayingServicePath === '/ep' ? 'processing-balloon' : 'progress-bar-container'
      const targetElems = document.getElementsByClassName(targetClass)
      if (targetElems.length) {
        targetElems[0].scrollIntoView()
      }
    }, 100)
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.ad-container {
}
</style>
