<template>
  <v-btn v-if="btnType === 1" dark round class="button btn-cv text-none" :class="btnClass" :to="toLink">
    <span>
      {{ btnText }}
    </span>
  </v-btn>
  <v-btn v-else-if="btnType === 2" dark class="button btn-cv text-none" :class="btnClass" :href="toLink">
    <span class="anime4k-btn">
      {{ btnText }}
    </span>
  </v-btn>
  <v-btn v-else-if="btnType === 3 && !logined" dark class="button btn-cv text-none" :class="btnClass" @click="auth.login('login')">
    <span class="anime4k-purchase-btn" :class="isLongLang ? 'small-text' : ''">
      {{ btnText }}
    </span>
  </v-btn>
  <v-btn v-else-if="btnType === 3 && logined" dark class="button btn-cv text-none" :class="btnClass" @click="openStripe(osType)">
    <span class="anime4k-purchase-btn" :class="isLongLang ? 'small-text' : ''">
      {{ btnText }}
    </span>
  </v-btn>
  <v-btn v-else-if="btnType === 4" dark class="button btn-cv text-none" :class="btnClass" @click="execDownload()">
    <span class="anime4k-btn">
      {{ btnText }}
    </span>
  </v-btn>
</template>

<script>
export default {
  props: {
    auth: {
      type: Object,
      default: () => ({})
    },
    btnText: String,
    btnClass: {
      type: String,
      default: 'btn-cv-anime4k'
    },
    toLink: {
      type: String,
      default: '/anime4k'
    },
    btnType: Number,
    osType: {
      type: String,
      default: ''
    },
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
  },
  methods: {
    openStripe(osType) {
      this.$store.dispatch('anime4kPlayer/openStripe', {
        lang: this.$i18n.locale == 'ja' ? 'ja' : 'en',
        osType: osType
      })
    },
    execDownload() {
      this.$store.dispatch('anime4kPlayer/execDownload')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/_anime4k.scss";

@mixin btn-bg-green {
  background: linear-gradient(72deg, $anime4k-color-green 0%, #96B9C1 80%, $anime4k-color-gray 100%);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .25) !important;
  &:hover {
    box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .5) !important;
    &::before {
      background: linear-gradient(72deg, #78ADB2, $anime4k-color-gray);
      opacity: .5;
    }
  }
}

@mixin btn-bg-blue {
  background: linear-gradient(72deg, #395F85 0%, #8FA3B9 80%, $anime4k-color-gray 100%);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .25) !important;
  &:hover {
    box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .5) !important;
    &::before {
      background: linear-gradient(72deg, #4C6F91, $anime4k-color-gray);
      opacity: .5;
    }
  }
}

@mixin btn-common {
  width: 425px;
  height: 100px;
  max-width: 88vw;
  white-space: normal;
  margin: 24px auto 64px auto;
  border-radius: 99px;
  @include mq-down(lg) {
    height: 74px;
    margin: 12px auto 24px auto;
    min-width: 0px;
    max-width: 380px;
    width: 65vw;
  }
  @include vf-mq-down(sm) {
    height: 64px;
    font-size: 18px;
    min-width: 0px;
    max-width: 380px;
    width: 75vw;
  }
  /deep/ .v-btn__content {
    width: 100%;
  }
}

.btn-cv-anime4k {
  @include btn-bg-green;
  @include btn-common;
}
.btn-cv-tolink {
  @include btn-bg-green;
  margin-left: 30px;
  margin-right: 30px;
  @include mq-down {
    margin-left: 8px;
    margin-right: 8px;
  }
  span {
    color: white;
  }
}
.btn-cv-purchase {
  @include btn-bg-blue;
  @include btn-common;
  margin-bottom: 36px;
}
.anime4k-btn {
  color: white;
  font-size: 30px;
  font-weight: 400;
  @include mq-down(lg) {
    font-size: 22px;
  }
  @include vf-mq-down(sm) {
    font-size: 18px;
  }
  @include mq-down(xs) {
    font-size: 16px;
  }
}
.anime4k-purchase-btn {
  color: white;
  font-size: 24px;
  font-weight: 400;
  @include mq-down(lg) {
    font-size: 22px;
  }
  @include vf-mq-down(sm) {
    font-size: 18px;
  }
  @include mq-down(sm) {
    font-size: 16px;
  }
  @include mq-down(xs) {
    font-size: 12px;
  }
  &.small-text {
    font-size: 23px;
    @include mq-down(lg) {
      font-size: 22px;
    }
    @include vf-mq-down(sm) {
      font-size: 18px;
    }
    @include mq-down(sm) {
      font-size: 15px;
    }
    @include mq-down(xs) {
      font-size: 11px;
    }
  }
}
a {
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
</style>
