// 対応言語は 以下のサブドメイン + TDL(英語)
const SUBDOMAIN_LIST = [
  'ar', 'bn', 'de', 'es', 'fr', 'hi', 'id', 'it', 'ja', 'ko', 'nl', 'pl', 'pt',
  'ru', 'sv', 'th', 'tr', 'vi', 'zh-cn', 'zh-tw'
]

// 前提として、言語とサブドメインは1:1に固定している。(ja.cre8tiveai.comから返るHTMLは日本語でprerenderingされたHTML)
// サーバサイドのAPIがStripeからの戻りURLを指定するのもAPIエンドポイントのサブドメインによって決定するため、
// APIエンドポイントはHTMLを取得したサブドメインと揃える
function apiURLBySubdomain() {
  // NOTE. envについて: https://cli.vuejs.org/guide/mode-and-env.html#modes
  let url = process.env.VUE_APP_API_BASE_URL.replace(/{}/, '')
  for (const subdomain of SUBDOMAIN_LIST) {
    if (location.hostname.startsWith(subdomain + '.')) {
      url = process.env.VUE_APP_API_BASE_URL.replace(/{}/, `${subdomain}.`)
      break
    }
  }
  return url
}

const API_BASE_URL = apiURLBySubdomain()
const FRONT_BASE_URL_TEMPLATE = process.env.VUE_APP_FRONT_BASE_URL_TEMPLATE
const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY
const GUEST_KEY = process.env.VUE_APP_GUEST_KEY

const CREDIT_CONSUME_TYPE = {
  CONSUME: 1,
  GIVEN: 2
}
const SERVICE_ID = {
  PR: 1,       // PhotoRefiner: 高解像度
  SC: 2,       // SaiChan: 彩ちゃん
  SC_PLUS: 3,  // SaiChanPlus: 彩ちゃん+
  EP: 4,       // Enpainter
  MPM: 5,      // Moving Photo Maker
  LID: 6,      // Line Drawer
  MP: 7,       // Mono Painter
  PD: 8,       // Portrait Painter
  AAP: 9,      // Anime Art Painter
  PS: 10,      // PNG Smallify
  FR: 11,      // Face Refiner
  ANIME4K: 12, // Anime4K Player
}

const SUBSCRIPTION_ID = {
  // valueはsubscription_mastersのgrouping_idであることに注意
  'ENTRY': 1,
  'LIGHT': 2,
  'STANDARD': 3,
  'PRO': 4,
  'ULTRA': 5,
  'MEGA': 6
}

// NOTE. 以下の決済にまつわる設定は、仮にクライアント側で書き換えられたとしても
//   クレジット決済: サーバ側の設定値に基づいてクレジット消費
//   都度課金: サーバ側でトークンを検証する際に意図した決済額かを確認 している
const SAI_CHAN_UNIT_CREDIT_FOR_DL = 5
// 彩ちゃん+依頼クレジット単価
const SAI_CHAN_PLUS_UNIT_CREDIT = 3000
// 追加オプションのクレジット単価
const SAI_CHAN_PLUS_UNIT_CREDIT_FOR_POSE_MODELSHEET = 2000
const SAI_CHAN_PLUS_UNIT_CREDIT_FOR_EXPRESSION = 300
const SAI_CHAN_PLUS_UNIT_CREDIT_FOR_HIGHRESOLUTION = 10

const ST_UNIT_CREDIT_FOR_DL = 10

const SAI_CHAN_PLUS_UNIT_PRICE_JPY = 33000
const SAI_CHAN_PLUS_UNIT_PRICE_USD = 30000 // USDは単位がcentであることに注意!!
const SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_POSE_MODELSHEET = 20000
const SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_POSE_MODELSHEET = 20000
const SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_EXPRESSION = 5000
const SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_EXPRESSION = 5000
const SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_HIGHRESOLUTION = 1000
const SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_HIGHRESOLUTION = 1000

// EP
const EP_UNIT_PRICE_JPY = 480
const EP_UNIT_PRICE_USD = 499

// MPM
const MPM_UNIT_CREDIT_FOR_ORDER = 50
const MPM_FHD_UNIT_CREDIT_FOR_ORDER = 100
const MPM_UNIT_PRICE_JPY = 2400
const MPM_UNIT_PRICE_USD = 2499

// LineDrawer
const LID_UNIT_CREIDT_FOR_DL = 10
const LID_UNIT_PRICE_JPY = 480
const LID_UNIT_PRICE_USD = 499

// MonoPainter
const MP_UNIT_CREIDT_FOR_DL = 10
const MP_UNIT_PRICE_JPY = 480
const MP_UNIT_PRICE_USD = 499

// PortraitDrawer
const PD_UNIT_CREIDT_FOR_DL = 10
const PD_UNIT_PRICE_JPY = 480
const PD_UNIT_PRICE_USD = 499

// AnimeArtPainter
const AAP_UNIT_CREIDT_FOR_DL = 10
const AAP_UNIT_PRICE_JPY = 480
const AAP_UNIT_PRICE_USD = 499

// PngSmallify
const PS_UNIT_PRICE_JPY = 0
const PS_UNIT_PRICE_USD = 0

// FaceRefiner
const FR_UNIT_CREIDT_FOR_DL = 10
const FR_UNIT_PRICE_JPY = 480
const FR_UNIT_PRICE_USD = 499

const CONTACT_MAIL_ADDRESS = 'info@radius5.co.jp'
const SERIAL_NUMBER_LENGTH = 20

// ダウンロードファイル名の suffix
// request_file_name と result_file_name が同じになるものは変更する
const PNG_SMALLIFY_SUFFIX = 'PngSmallify'
const FACE_REFINER_SUFFIX = 'FaceRefiner'

export {
  API_BASE_URL, FRONT_BASE_URL_TEMPLATE, STRIPE_KEY, CREDIT_CONSUME_TYPE, SERVICE_ID, SUBSCRIPTION_ID,
  SAI_CHAN_UNIT_CREDIT_FOR_DL, SAI_CHAN_PLUS_UNIT_CREDIT, SAI_CHAN_PLUS_UNIT_CREDIT_FOR_POSE_MODELSHEET,
  SAI_CHAN_PLUS_UNIT_CREDIT_FOR_EXPRESSION, SAI_CHAN_PLUS_UNIT_CREDIT_FOR_HIGHRESOLUTION,
  ST_UNIT_CREDIT_FOR_DL,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY, SAI_CHAN_PLUS_UNIT_PRICE_USD,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_POSE_MODELSHEET, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_POSE_MODELSHEET,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_EXPRESSION, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_EXPRESSION,
  SAI_CHAN_PLUS_UNIT_PRICE_JPY_FOR_HIGHRESOLUTION, SAI_CHAN_PLUS_UNIT_PRICE_USD_FOR_HIGHRESOLUTION,
  EP_UNIT_PRICE_JPY, EP_UNIT_PRICE_USD,
  MPM_UNIT_CREDIT_FOR_ORDER, MPM_FHD_UNIT_CREDIT_FOR_ORDER, MPM_UNIT_PRICE_JPY, MPM_UNIT_PRICE_USD,
  SUBDOMAIN_LIST, CONTACT_MAIL_ADDRESS, SERIAL_NUMBER_LENGTH,
  LID_UNIT_CREIDT_FOR_DL, LID_UNIT_PRICE_JPY, LID_UNIT_PRICE_USD,
  MP_UNIT_CREIDT_FOR_DL, MP_UNIT_PRICE_JPY, MP_UNIT_PRICE_USD,
  PD_UNIT_CREIDT_FOR_DL, PD_UNIT_PRICE_JPY, PD_UNIT_PRICE_USD,
  AAP_UNIT_CREIDT_FOR_DL, AAP_UNIT_PRICE_JPY, AAP_UNIT_PRICE_USD,
  PS_UNIT_PRICE_JPY, PS_UNIT_PRICE_USD,
  FR_UNIT_CREIDT_FOR_DL, FR_UNIT_PRICE_JPY, FR_UNIT_PRICE_USD,
  PNG_SMALLIFY_SUFFIX, FACE_REFINER_SUFFIX,
  GUEST_KEY,
}
