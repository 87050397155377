import axios from 'axios'
import { API_BASE_URL, STRIPE_KEY } from '../settings'
import { isMobile } from '../views/util'
import execDownload from '../mixins/execDownload'

function initialState() {
  return {
    downloadUrl: null,
    showConfirmDialogForMobile: false,
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    downloadUrl(state) {
      return state.downloadUrl
    },
    showConfirmDialogForMobile(state) {
      return state.showConfirmDialogForMobile
    },
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    resetShowDialog(state) {
      state.showConfirmDialogForMobile = false
    },
  },
  actions: {
    async openStripe({ dispatch, rootGetters }, payload) {
      const group = rootGetters['user/currentGroup']
      const param = {
        group_id: group.id,
        lang: payload.lang,
        os_type: payload.osType
      }
      const res = await axios.post(
        `${API_BASE_URL}/payment/v3/anime4k/prepare`,
        param
      )
      if (res.status != 200) {
        dispatch('showErrorOnScreen', {response: res}, {root: true})
        return
      }
      /*global gtag*/
      gtag('event', 'show_payment_screen', {
        event_category: 'payment',
        event_label: 'pay_as_you_go_anime4k',
        value: group.subscription_id
      })
      /*global Stripe*/
      const stripe = Stripe(STRIPE_KEY)
      const { error } = await stripe.redirectToCheckout({
        sessionId: res.data.session_id
      })
      if (error) {
        dispatch('showErrorOnScreen', {response: error}, {root: true})
      }
    },
    async getDLURL({ commit, state, dispatch, rootGetters }, payload) {
      commit('reset')
      const res = await axios.get(`${API_BASE_URL}/anime4k/dl_url/${payload.saleId}`)
      if (res.status !== 200) {
        dispatch('showErrorOnScreen', {response: res}, {root: true})
      }
      state.downloadUrl = res.data.download_url
      // 決済済みなら dl=true で遷移してくるのでGAのevent発火
      if (payload.isDownload) {
        const group = rootGetters['user/currentGroup']
        gtag('event', 'payment_complete', {
          event_category: 'payment',
          event_label: 'pay_as_you_go_anime4k',
          value: group.subscription_id
        })
        dispatch('execDownload')
      }
    },
    async execDownload({ state }) {
      if (!state.downloadUrl) {
        console.log('download url not found')
        return
      }
      if (isMobile(navigator.userAgent)) {
        // レンダリング前・後の見た目の差異を無視するため、ページ上部に戻す
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        })
        state.showConfirmDialogForMobile = true
        return
      }
      const dlName = state.downloadUrl.split('/')[4].split('?')[0]
      execDownload.methods.downloadZipPc(state.downloadUrl, dlName)
    },
  },
}
