<template>
  <div class="home">
    <!-- 背景付きヘッダー -->
    <v-parallax src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/home_head_parallax.jpg" height="696" class="parallax">
      <v-layout align-center column justify-center>
        <img src="../assets/img/home_logo_object.svg" class="home-logo">
        <h1 class="h-top"><span v-html="$t('home_6')"></span></h1>
      </v-layout>
        <home-cv-button :btnText='$t("home_7")'></home-cv-button>
        <div class="sns-icon">
          <!-- <img class="twtter-icon shd-m" src="../assets/img/Twitter.svg">
          <img class="fb-icon shd-m" src="../assets/img/Facebook.svg"> -->
        </div>
    </v-parallax>

    <!-- ボディ -->
    <div class="descriptions">
      <!-- 様々なAIツールをワンストップで利用できます -->
      <div class="upper-shd bg-gray-light">
        <div class="item-container">
          <h2 class="under-head">{{ $t("home_8") }}</h2>
          <div class="added-description">
            <p class="texts">{{ $t("home_9") }}</p>
          </div>
          <services :isHeading=false :isNextAI=true></services>
          <home-cv-button :btnText='$t("home_7")'></home-cv-button>
        </div>
        <!-- 広告 -->
        <ad-responsive></ad-responsive>
      </div>
      <!-- 姉妹サービス -->
      <div class="bg-blue-light">
        <div class="related-service-area item-container">
          <h2 class="h2-small">{{ $t('home_82') }}</h2>
          <v-container related-card-container fluid grid-list-lg>
            <v-layout row wrap align-center justify-start>
              <v-flex xs12 md6>
                <div class="related-card">
                  <router-link to="/aar" class="router-link">
                    <aar-plugin-name :pluginName='$t("glb_aar_1")' :powerdBy='$t("glb_aar_2")' pluginNameClass="plugin-name-home" powerdByClass="powerd-company-home"></aar-plugin-name>
                    <p>{{ $t('home_83') }}</p>
                  </router-link>
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <div class="related-card">
                  <router-link to="/anime4k" class="router-link">
                    <anime4k-plugin-name :pluginName='$t("glb_anime4k_1")' :powerdBy='$t("glb_anime4k_2")' pluginNameClass="plugin-name-home" powerdByClass="powerd-company-home"></anime4k-plugin-name>
                    <p>{{ $t('home_84') }}</p>
                  </router-link>
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <div class="related-card">
                  <a :href='$t("glb_animesr_link")' target="_blank" rel="noopener" class="router-link">
                    <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/related/icon_AnimeSR.svg">
                    <p>{{ $t('home_87') }}</p>
                  </a>
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <div class="related-card">
                  <a :href='$t("glb_ooh_link")' target="_blank" rel="noopener" class="router-link">
                    <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/related/icon_OOH.svg">
                    <p>{{ $t('home_86') }}</p>
                  </a>
                </div>
              </v-flex>
              <v-flex xs12 md6>
                <div class="related-card">
                  <a :href='$t("glb_animerefiner_link")' target="_blank" rel="noopener" class="router-link">
                    <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/related/icon_AnimeRefiner.svg">
                    <p>{{ $t('home_85') }}</p>
                  </a>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
      <!-- cre8tiveAIの3つの特徴 -->
      <div class="bg-white">
        <div class="item-container">
          <h2>{{ $t("home_10") }}</h2>
            <div class="added-description">
              <p class="texts">{{ $t("home_11") }}</p>
            </div>
          <div class="three-container">
            <div class="icon-container">
              <img class="icon-coin" src="../assets/img/home-coin.svg">
            </div>
            <h3>{{ $t("home_12") }}</h3>
            <p>{{ $t("home_13") }}</p>
          </div>
          <div class="three-container">
            <div class="icon-container">
              <img class="icon-finger" src="../assets/img/home-finger.svg">
            </div>
            <h3>{{ $t("home_14") }}</h3>
            <p>{{ $t("home_15") }}</p>
          </div>
          <div class="three-container">
            <div class="icon-container">
              <img class="icon-brain" src="../assets/img/home-shield.svg">
            </div>
            <h3>{{ $t("home_16") }}</h3>
            <p>{{ $t("home_17") }}</p>
          </div>
        </div>
      </div>
      <!-- PRODUCT MISSION -->
      <div class="bg-gray-light">
        <div class="item-container">
          <h2>
            {{ $t("home_18") }}<br>
            <p class="h2-sub mx-3">{{ $t("home_19") }}</p>
          </h2>
          <div class="pm-text">
            <p>
              <span v-html=" $t('home_21') "></span>
            </p>
            <p>
              <span v-html=" $t('home_22') "></span>
            </p>
          </div>
          <home-cv-button :btnText='$t("home_7")'></home-cv-button>
        </div>
        <!-- 広告 -->
        <ad-responsive></ad-responsive>
      </div>
      <!-- シンプルなサブスクリプションプラン -->
      <div class="bg-gray">
        <div class="item-container subscriptions" v-if="subscriptions.length > 0">
          <h2 class="h2-small">{{ $t("home_23") }}</h2>
          <div class="added-description">
            <p class="texts">{{ $t("home_50") }}</p>
          </div>
          <!-- サブスクリプション3つ -->
          <div>
            <!-- お試し -->
            <div  class="plan-boad shd-m" id="trial">
              <h4>{{ subscriptions[0].name }}</h4>
              <h5>{{ $t("glb_price_1") }}</h5>
              <!-- 月額の値段 -->
              <p>
                {{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[0]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span>
              </p>
              <!-- キャプション -->
              <p class="addition">
                Photo Refiner {{ $t("glb_price_5", [formatUnitPrice(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_9") }} {{ $t("glb_price_5", [formatUnitPriceFR(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7") }} {{ $t("glb_price_5", [formatUnitPriceSc(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_2") }} {{ $t("glb_price_5", [formatUnitPriceEp(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_3") }} {{ $t("glb_price_5", [formatUnitPriceMPM(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_4") }} {{ $t("glb_price_5", [formatUnitPriceLID(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_5") }} {{ $t("glb_price_5", [formatUnitPriceMP(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_6") }} {{ $t("glb_price_5", [formatUnitPricePD(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_7") }} {{ $t("glb_price_5", [formatUnitPriceAAP(subscriptions[0])]) }}
              </p>
              <p class="addition">
                {{ $t("glb_price_7_8") }} {{ $t("glb_price_5", [formatUnitPricePS(subscriptions[0])]) }}
              </p>
              <ul>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_10") }}</li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_12") }}</li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_14") }}</li>
                <li>
                  <font-awesome-icon icon="check" class="check-icon"/>
                  {{ $t("glb_price_17", [subscriptions[0].store_duration]) }}
                </li>
                <li class="hidden"><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
              </ul>
            </div>
            <!-- ライト -->
            <div class="plan-boad shd-m" id="lite">
              <h4>{{ subscriptions[1].name }}</h4>
              <h5>
                {{subscriptions[1].credit_grant_monthly}}{{ $t("glb_price_2") }}
              </h5>
              <p>
                {{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[1]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span>
              </p>
              <p class="addition">{{ $t("glb_price_8") }}</p>
              <p class="addition">{{ $t("glb_price_27") }}</p>
              <p class="addition">{{ $t("glb_price_9") }}</p>
              <p class="addition">{{ $t("glb_price_20") }}</p>
              <p class="addition">{{ $t("glb_price_21") }}</p>
              <p class="addition">{{ $t("glb_price_22") }}</p>
              <p class="addition">{{ $t("glb_price_23") }}</p>
              <p class="addition">{{ $t("glb_price_24") }}</p>
              <p class="addition">{{ $t("glb_price_25") }}</p>
              <p class="addition">{{ $t("glb_price_26") }}</p>
              <ul>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_11") }}</li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_12") }}</li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_15") }}</li>
                <li>
                  <font-awesome-icon icon="check" class="check-icon"/>
                  {{ $t("glb_price_17", [subscriptions[1].store_duration]) }}
                </li>
                <li class="hidden"><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
              </ul>
            </div>
            <!-- スタンダード -->
            <div class="plan-boad shd-m" id="standard">
              <h4>{{ subscriptions[2].name }}</h4>
              <h5>{{subscriptions[2].credit_grant_monthly}}{{ $t("glb_price_2") }}</h5>
              <p>
                {{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[2]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span>
              </p>
              <p class="addition">{{ $t("glb_price_8") }}</p>
              <p class="addition">{{ $t("glb_price_27") }}</p>
              <p class="addition">{{ $t("glb_price_9") }}</p>
              <p class="addition">{{ $t("glb_price_20") }}</p>
              <p class="addition">{{ $t("glb_price_21") }}</p>
              <p class="addition">{{ $t("glb_price_22") }}</p>
              <p class="addition">{{ $t("glb_price_23") }}</p>
              <p class="addition">{{ $t("glb_price_24") }}</p>
              <p class="addition">{{ $t("glb_price_25") }}</p>
              <p class="addition">{{ $t("glb_price_26") }}</p>
              <ul>
                <li><font-awesome-icon icon="check" class="check-icon"/><span v-html=" $t('glb_price_11') "></span></li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_12") }}</li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_15") }}</li>
                <li>
                  <font-awesome-icon icon="check" class="check-icon"/>
                  {{ $t("glb_price_17", [subscriptions[2].store_duration]) }}
                </li>
                <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
              </ul>
            </div>
          </div>
          <!-- サブスクリプションうしろの3つ（初期は非表示） -->
          <v-slide-y-transition>
            <div v-show="showToggle">
              <div class="plan-boad shd-m" id="pro">
                <h4>{{ subscriptions[3].name }}</h4>
                <h5>{{subscriptions[3].credit_grant_monthly}}{{ $t("glb_price_2") }}</h5>
                <p>{{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[3]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span></p>
                <p class="addition">{{ $t("glb_price_8") }}</p>
                <p class="addition">{{ $t("glb_price_27") }}</p>
                <p class="addition">{{ $t("glb_price_9") }}</p>
                <p class="addition">{{ $t("glb_price_20") }}</p>
                <p class="addition">{{ $t("glb_price_21") }}</p>
                <p class="addition">{{ $t("glb_price_22") }}</p>
                <p class="addition">{{ $t("glb_price_23") }}</p>
                <p class="addition">{{ $t("glb_price_24") }}</p>
                <p class="addition">{{ $t("glb_price_25") }}</p>
                <p class="addition">{{ $t("glb_price_26") }}</p>
                <ul>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_11") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_13") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_16") }}</li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>
                    {{ $t("glb_price_17", [subscriptions[3].store_duration]) }}
                  </li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>{{ subscriptions[4].account_limit_num }} {{ $t("glb_price_18") }}
                  </li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
                </ul>
              </div>
              <div class="plan-boad shd-m" id="ultra">
                <h4>{{ subscriptions[4].name }}</h4>
                <h5>{{subscriptions[4].credit_grant_monthly}}{{ $t("glb_price_2") }}</h5>
                <p>{{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[4]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span></p>
                <p class="addition">{{ $t("glb_price_8") }}</p>
                <p class="addition">{{ $t("glb_price_27") }}</p>
                <p class="addition">{{ $t("glb_price_9") }}</p>
                <p class="addition">{{ $t("glb_price_20") }}</p>
                <p class="addition">{{ $t("glb_price_21") }}</p>
                <p class="addition">{{ $t("glb_price_22") }}</p>
                <p class="addition">{{ $t("glb_price_23") }}</p>
                <p class="addition">{{ $t("glb_price_24") }}</p>
                <p class="addition">{{ $t("glb_price_25") }}</p>
                <p class="addition">{{ $t("glb_price_26") }}</p>
                <ul>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_11") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_13") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_16") }}</li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>
                    {{ $t("glb_price_17", [subscriptions[4].store_duration]) }}
                  </li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>{{ subscriptions[4].account_limit_num }} {{ $t("glb_price_18") }}
                  </li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
                </ul>
              </div>
              <div class="plan-boad shd-m" id="mega">
                <h4>{{ subscriptions[5].name }}</h4>
                <h5>{{subscriptions[5].credit_grant_monthly}}{{ $t("glb_price_2") }}</h5>
                <p>{{ $t("glb_price_3", [addComma(formatPriceMonthly(subscriptions[5]))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span></p>
                <p class="addition">{{ $t("glb_price_8") }}</p>
                <p class="addition">{{ $t("glb_price_27") }}</p>
                <p class="addition">{{ $t("glb_price_9") }}</p>
                <p class="addition">{{ $t("glb_price_20") }}</p>
                <p class="addition">{{ $t("glb_price_21") }}</p>
                <p class="addition">{{ $t("glb_price_22") }}</p>
                <p class="addition">{{ $t("glb_price_23") }}</p>
                <p class="addition">{{ $t("glb_price_24") }}</p>
                <p class="addition">{{ $t("glb_price_25") }}</p>
                <p class="addition">{{ $t("glb_price_26") }}</p>
                <ul>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_11") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_13") }}</li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_16") }}</li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>
                    {{ $t("glb_price_17", [subscriptions[5].store_duration]) }}
                  </li>
                  <li>
                    <font-awesome-icon icon="check" class="check-icon"/>{{ subscriptions[5].account_limit_num }} {{ $t("glb_price_18") }}
                  </li>
                  <li><font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}</li>
                </ul>
              </div>
            </div>
          </v-slide-y-transition>
          <p class="see-more" v-on:click="showToggle=true" v-if="!showToggle">
            more plan... <font-awesome-icon icon="caret-down"/>
          </p>
          <v-btn color="white" class="button btn-more">
            <router-link to="/price" class="grey--text text--darken-2 no-deco text-none"><font-awesome-icon icon="chevron-right" class="mr-2"/>{{ $t("home_25") }}</router-link>
          </v-btn>
        </div>
        <!-- 広告 -->
        <ad-responsive></ad-responsive>
      </div>
      <!-- cre8tveAI Gallery -->
      <div class="bg-white py-0">
        <div class="gallery-link-area">
          <div class="gallery-parent-container">
            <div class="gallery-child-container item-container">
              <p class="summary" :class="$i18n.locale === 'ja' ? 'lang-ja' : ''" v-html="$t('home_79')"></p>
              <v-btn dark class="button btn-gallery-pc white--text no-deco text-none display-pc" :href='$t("glb_gallery_link_1")' target="_blank" rel="noopener">
                {{ $t("home_80") }}
              </v-btn>
              <img class="main-banner" src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/gallery/main_banner@2x.webp">
              <img class="logo-banner" src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/gallery/logo_banner.svg">
            </div>
          </div>
          <v-btn dark class="button btn-gallery-sp white--text no-deco text-none display-sp" :href='$t("glb_gallery_link_1")' target="_blank" rel="noopener">
            {{ $t("home_80") }}
          </v-btn>
        </div>
      </div>
      <!-- ローンチカスタマー・掲載メディア -->
      <div class="bg-white">
        <div class="launch-customer">
          <h2 class="h2-small">{{ $t("home_26") }}</h2>
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/launchCustomer.png" alt="ローンチカスタマーのロゴ">
          <h2 class="h2-small pt-5">{{ $t("home_27") }}</h2>
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/home/publication.jpg" alt="掲載メディアのロゴ">
          <home-cv-button :btnText='$t("home_7")'></home-cv-button>
        </div>
      </div>
      <!-- お知らせ -->
      <div class="bg-white pt-0">
        <div class="app-news item-container">
          <h2 class="h2-small mt-0">{{ $t("home_51") }}</h2>
          <div class="added-description">
            <p class="texts">{{ $t("home_52") }}</p>
          </div>
          <div class="table mt-5">
            <template v-for="(galleryInfo, idx) in galleryInfos">
              <div class="tr" :key="'tr-' + idx">
                <div class="left-row">
                  <p class="notice">{{ $t("home_54") }}</p>
                  <p class="mb-0 date">{{ $t("home_55", [galleryInfo.year, galleryInfo.month, galleryInfo.day]) }}</p>
                </div>
                <div class="text-xs-left details">
                  <a :href="galleryInfo.link" target="_blank" rel="noopener">
                    {{ galleryInfo.title }}
                  </a>
                </div>
              </div>
              <v-divider v-if="idx !== 2" class="news-divider" :key="'divider-' + idx"></v-divider>
            </template>
            <div class="see-more">
              <a :href='$t("glb_gallery_link_2")' target="_blank" rel="noopener">
                {{ $t("home_81") }}
              </a>
            </div>
          </div>
        </div>
        <!-- 広告 -->
        <ad-responsive></ad-responsive>
      </div>
      <!-- お問い合わせ -->
      <div class="bg-white" id="g-form-area">
        <!-- <div class="google-form"> -->
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlseHfWdpQ6qcWFXikxBl31FaRuWR5J1le69JMCJyQwMYJ2w/viewform?embedded=true" id="g-form" width="94%" height="1160px" frameborder="0" marginheight="0" marginwidth="0" class="google-form">now loading ...</iframe>
        <!-- </div> -->
        <!-- <div class="contact-us item-container">
          <h2 class="h2-small">お問い合わせ</h2>
          <v-layout justify-center>
            <v-form ref="form" v-model="valid" class="indigo--text"> -->
              <!-- ラジオボタン -->
              <!-- <v-radio-group v-model="inquiryAbout" class="mb-4" row>
                <label slot="label" class="grey--text text--darken-3">お問い合わせ種別：</label>
                <v-radio value="radio-1" color="indigo">
                  <label slot="label" class="grey--text text--darken-3">ご意見・ご要望</label>
                </v-radio>
                <v-radio value="radio-2" color="indigo">
                  <label slot="label" class="grey--text text--darken-3">サービスの利用方法</label>
                </v-radio>
                <v-radio value="radio-3" color="indigo">
                  <label slot="label" class="grey--text text--darken-3">その他</label>
                </v-radio>
              </v-radio-group> -->
              <!-- テキスト入力エリア -->
              <!-- <v-text-field label="社名" v-model="company" :rules="companyRules"></v-text-field>
              <v-text-field label="名前" v-model="name" :rules="nameRules"></v-text-field>
              <v-text-field label="メールアドレス" v-model="email" :rules="emailRules"></v-text-field>
              <v-text-field label="電話番号" v-model="telnum" :rules="telnumRules"></v-text-field>
              <v-textarea label="問い合わせ内容" v-model="content" :rules="contentRules" counter></v-textarea>
              <v-layout justify-end mt-3 mr-2>
                <v-btn outline round color="grey darken-1" justfy-end>内容を確認する</v-btn>
              </v-layout>
            </v-form>
          </v-layout>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeCvButton from '@/components/HomeCvButton.vue'
import Services from '@/components/Services.vue'
import AarPluginName from '@/components/aar/PluginName.vue'
import Anime4kPluginName from '@/components/anime4k/PluginName.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import {
  formatPriceMonthly, formatUnitPrice, formatUnitPriceSc, formatUnitPriceEp,
  formatUnitPriceMPM, formatUnitPriceLID, formatUnitPriceMP, formatUnitPricePD,
  formatUnitPriceAAP, formatUnitPricePS, formatUnitPriceFR
} from './util'

export default {
  name: 'Home',
  components: {
    HomeCvButton,
    Services,
    AarPluginName,
    Anime4kPluginName,
    AdResponsive
  },
  data () {
    return {
      valid: true,
      showToggle: false,
      // フォームはGoogleFormを使うので以下不採用
      // company: '',
      // companyRules: [
      //   v => !!v || '社名を入力してください',
      // ],
      // name: '',
      // nameRules: [
      //   v => !!v || '名前を入力してください',
      // ],
      // email: '',
      // emailRules: [
      //   v => !!v || 'メールアドレスを入力してください',
      //   v => /.+@.+/.test(v) || 'メールアドレスを正しく入力してください'
      // ],
      // telnum: '',
      // telnumRules: [
      //   v => !!v || '電話番号を入力してください',
      // ],
      // content: '',
      // contentRules: [
      //   v => !!v || 'お問い合わせ内容を入力してください',
      // ],
    }
  },
  computed: {
    subscriptions() {
      // 言語に合わせた通貨を表示する
      const lang = this.$i18n.locale === 'ja' ? 'ja' : 'en'
      return this.$store.getters['master/changeableSubscriptionsByLang'](lang)
    },
    galleryInfos() {
      return this.$store.state.gallery.galleryInfos
    },
    ...mapGetters('user', [
      'isCurrentGroupAdmin'
    ])
  },
  methods: {
    formatPriceMonthly(subsc) {
      return formatPriceMonthly(subsc)
    },
    formatUnitPrice(subsc) {
      return formatUnitPrice(subsc)
    },
    formatUnitPriceSc(subsc) {
      return formatUnitPriceSc(subsc)
    },
    formatUnitPriceEp(subsc) {
      return formatUnitPriceEp(subsc)
    },
    formatUnitPriceMPM(subsc) {
      return formatUnitPriceMPM(subsc)
    },
    formatUnitPriceLID(subsc) {
      return formatUnitPriceLID(subsc)
    },
    formatUnitPriceMP(subsc) {
      return formatUnitPriceMP(subsc)
    },
    formatUnitPricePD(subsc) {
      return formatUnitPricePD(subsc)
    },
    formatUnitPriceAAP(subsc) {
      return formatUnitPriceAAP(subsc)
    },
    formatUnitPricePS(subsc) {
      return formatUnitPricePS(subsc)
    },
    formatUnitPriceFR(subsc) {
      return formatUnitPriceFR(subsc)
    },
    addComma(num) {
      return num.toLocaleString()
    }
  },
  created() {
    this.$store.dispatch('gallery/getGalleryData', this.$i18n.locale)
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
  },
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
@import "../assets/scss/common.scss";
@import "../assets/scss/_aar.scss";
// ページ内共通
h2 {
  font-size: 42px;
  font-weight: 400;
  color: #424242;
  margin-top: 64px;
  margin-bottom: 12px;
  line-height: 64px;
  @include mq-down {
    font-size: 32px;
    margin-right: 16px;
    margin-left: 16px;
    line-height: normal;
  }
}
.h2-small {
  font-size: 32px;
  margin-bottom: 64px;
  @include mq-down {
    font-size: 24px;
    margin-right: 16px;
    margin-left: 16px;
    line-height: normal;
  }
}
.upper-shd {
  box-shadow: 0px -3px 6px 0px rgba(0,0,0,0.24);
}
.bg-gray-light {
  background-size: cover;
  background-color: #FAFAFA;
  padding-top: 64px;
  padding-bottom: 64px;
}
.bg-gray {
  background-size: cover;
  background-color: #EEEEEE;
  padding-top: 64px;
  padding-bottom: 64px;
}
.bg-white {
  padding-top: 64px;
  padding-bottom: 64px;
  background: white;
}
.bg-blue-light {
  padding-top: 64px;
  padding-bottom: 64px;
  background: #BBCDD5;
}
.under-head {
  margin-top: 32px;
}
.item-container {
  margin: auto;
  max-width: 1104px;
}
.added-description {
  font-size: 18px;
  line-height: 32px;
  margin-top: 84px;
  margin-right: 32px;
  margin-left: 32px;
  .texts {
    margin-bottom: 32px !important;
    line-height: 1.75;
  }
  @include mq-down {
    margin-top: 48px;
    margin-right: 16px;
    margin-left: 16px;
  }
  @include mq-down(sm) {
    margin-top: 32px;
    margin-bottom: 48px;
    font-size: 14px;
    line-height: 1.6;
  }
}
// ヒーローヘッダー
.parallax {
  .home-logo {
    margin-top: 112px;
    width: 88px;
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.6));
    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }
  .h-top {
    font-size: 48px;
    font-weight: 400;
    color: white;
    margin-top: 64px;
    margin-bottom: 0px;
    @media screen and (max-width: 767px) {
      font-size: 28px;
    }
  }
  .sns-icon {
    padding-bottom: 64px;
    .twtter-icon {
      margin-right: 64px;
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 0px 15px rgba(0, 183, 255, 0.5);
        text-decoration: none;
      }
    }
    .fb-icon {
      transition: 0.3s;
      &:hover {
        box-shadow: 0px 0px 15px rgba(36, 138, 255, 0.5);
        text-decoration: none;
      }
    }
  }
}

// AI一覧
.next {
  color: #616161;
}

// 姉妹サービス
.related-service-area {
  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }
  a {
    color: #262D40;
    cursor: pointer;
    text-decoration: none;
  }
  p {
    font-size: 14px;
    margin-top: 14px;
    @include mq-down(sm) {
      font-size: 12px;
      margin-top: 10px;
    }
  }
  .related-card {
    background: linear-gradient(-120deg, rgba(255, 255, 255, .7), rgba(63, 139, 146, .3));
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .24);
    &:hover {
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, .24);
      background: linear-gradient(-120deg, rgba(255, 255, 255, .7), rgba(101, 162, 167, .3));
    }
    height: 200px;
    border-radius: 5px;
    margin: 5px;
    @include vf-mq-down(sm) {
      height: 180px;
      margin: 5px 80px;
    }
    @include mq-down(md) {
      margin: 2px 0;
    }
    @include mq-down(sm) {
      height: initial;
    }
    img {
      height: 70px;
      @include mq-down(md) {
        height: 50px;
      }
      @include mq-down(sm) {
        height: 40px;
        margin-top: 0px;
      }
      margin-top: 14px;
      object-fit: scale-down;
    }
    .router-link {
      display: block;
      height: 100%;
      padding: 20px 63px;
      @include mq-down(md) {
        padding: 15px 63px;
      }
      @include mq-down(sm) {
        padding: 10px 23px;
      }
    }
  }
}

// cre8tiveAIの3つの特徴
.three-container {
  vertical-align: top;
  max-width: 288px;
  display: inline-block;
  color: #424242;
  margin: 104px 32px 134px 32px;
  @include mq-down(lg) {
    margin: 32px 16px;
  }
  @include mq-down() {
    width: initial;
    max-width: initial;
    display: block;
    margin: 32px 16px;
  }
  .icon-container {
    height: 160px;
    width: 160px;
    display: inline-block;
    vertical-align: center;
    position:relative;

    img {
      margin: auto;
    }
    .icon-coin {
      height: 128px;
    }
    .icon-finger {
      height: 128px;
    }
    .icon-brain {
      height: 128px;
    }
    h3 {
      font-size: 20px;
      white-space: nowrap;
    }
  }
  p {
    line-height: 24px;
  }
}
// PRODUCT MISSION〜誰もがAIの恩恵を受けられるプラットフォームになる〜
.h2-sub {
  font-size: 32px;
  color: #234EB5;
  @media screen and (max-width: 767px) {
    font-size: 24px;
  }
}
.pm-text {
  font-size: 18px;
  line-height: 32px;
  margin-top: 84px;
  p {
    margin-bottom: 32px !important;
  }
  @include mq-down(cn) {
    margin-right: 16px;
    margin-left: 16px;
  }
  @include mq-down(sm) {
    font-size: 14px;
    line-height: 1.6;
  }
}
// シンプルなサブスクリプションプラン
.subscriptions {
  h4 {
    margin-top: 12px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 24px;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 24px;
  }
  .plan-boad {
    width: 304px;
    min-height: 464px;
    display: inline-block;
    position: relative;
    background-clip: padding-box;
    z-index: 5;
    margin: 48px 24px 16px 24px;
    padding: 20px;
    border-radius: 4px;
    vertical-align: top;
    &:after {
      content: '';
      position: absolute;
      top: 8px; bottom: 8px;
      left: 0px; right: 0px;
      background: white;
      z-index: -9;
    }

    h5 {
      margin-bottom: 8px;
    }

    p {
      font-size: 38px;
      &.addition {
        font-size: 12px;
        margin-bottom: 2px;
      }
    }
    ul {
      padding-left: 0px;
      text-align: left;
      list-style: none;
      margin-top: 24px;
      font-size: 12px;
      line-height: 28px;
      color: #424242;
      .check-icon {
        vertical-align: top;
        margin-right: 8px;
        margin-top: 6px;
      }
      li {
        margin-bottom: 10px;
        display: flex;

        &.hidden {
          visibility: hidden;
        }
      }
    }
    @include mq-down(sm) {
      width: 93vw;
      margin-top: 32px;
      margin-bottom: 24;
      margin-right: auto;
      margin-left: auto;
      .check-icon {
        margin-right: 6px;
      }
      ul {
        font-size: 14px;
      }
    }
  }
  @mixin plan-colors($light, $dark, $middle){
    color: $dark;
    background: linear-gradient(to right, $light, $dark);
    .check-icon {
      color: $middle;
    }
  }
  #trial {
    @include plan-colors(#00E676, #43A047, #4CAF50);
  }
  #lite {
    @include plan-colors(#37CAE7, #234EB5, #2196F3);
  }
  #standard {
    @include plan-colors(#D500F9, #C2185B, #E91E63);
  }
  #pro {
    @include plan-colors(#7C4DFF, #9C27B0, #673AB7);
  }
  #ultra {
    @include plan-colors(#FFD740, #FF5722, #FFA000);
  }
  #mega {
    @include plan-colors(#ff1744, #880E4F, #C51162);
  }
  .see-more {
    font-size: 14px;
    color: #0D47A1;
    text-align: right;
    margin-right: 24px;
    cursor: pointer;
  }
  .btn-more {
    font-size: 24px;
    padding: 0px 32px !important;
    min-height: 56px;
    margin-top: 32px;
    margin-bottom: 32px;
    font-weight: 400;
    @include mq-down {
      margin-top: 24px;
      max-width: 96vw;
      font-size: 18px;
      min-height: 48px;
    }
    &:hover {
      color: #424242 !important;
      &::before {
        background-color: white;
      }
    }
    .no-deco {
      text-decoration: none !important;
    }
  }
}
// cre8tiveAI Gallery
.gallery-link-area {
  @mixin btn-gallery-base {
    color: white;
    width: 448px;
    max-width: 88vw;
    white-space: normal;
    min-height: 64px;
    font-size: 28px;
    font-weight: 400;
    margin: 32px auto 0px;
    border-radius: 99px;
    background: linear-gradient(170deg, #D26EFF 0%, #B6A6E7 63%, #4CF8FF 100%);
    box-shadow: 0 2px 10px rgba(0,0,0,0.4) !important;
    &:hover {
      box-shadow: 1px 6px 15px rgba(0,0,0,0.4) !important;
      &::before {
        background: linear-gradient(170deg, #AF47DE 0%, #B6A6E7 63%, #85FAFF 100%);
        opacity: .5;
      }
    }
    @include mq-down(sm) {
      min-width: 0px;
      max-width: 448px;
      width: 88vw;
      font-size: 23px;
    }
  }
  .gallery-parent-container {
    background: linear-gradient(108deg, #FF6ED8 0%, #FC6FD8 25%, #F376DA 40%, #E581DD 52%, #D091E2 64%, #B6A6E7 74%, #95BFEF 84%, #6EDDF7 93%, #4CF8FF 100%);
    width: 100%;
    .gallery-child-container {
      position: relative;
      .summary {
        position: absolute;
        left: 40px;
        top: 20px;
        color: white;
        font-size: 19px;
        text-align: left;
        width: 54%;
        @include mq-down(xl) {
          left: 2.4vw;
        }
        @include mq-down(lg) {
          top: 5px;
          font-size: 14px;
        }
        @include mq-down {
          left: 2vw;
        }
        @include mq-down(sm) {
          font-size: 10px;
        }
        @include mq-down(xs) {
          top: 3px;
          width: 63%;
        }
        &.lang-ja {
          font-size: 21px;
          width: auto;
          @include mq-down(lg) {
            font-size: 16px;
          }
          @include mq-down {
            top: 10px;
            font-size: 14px;
          }
          @include mq-down(sm) {
            font-size: 10px;
          }
          @include mq-down(xs) {
            top: 3px;
            width: auto;
          }
        }
      }
      .btn-gallery-pc {
        @include btn-gallery-base;
        position: absolute;
        left: 40px;
        top: 100px;
        font-size: 25px;
        width: 24vw;
        max-width: 308px;
        margin: auto;
        @include mq-down(xl) {
          left: 2.4vw;
          font-size: 22px;
        }
        @include mq-down(lg) {
          top: 65px;
          font-size: 16px;
          min-height: 44px;
        }
      }
      .main-banner {
        width: 100%;
        height: auto;
      }
      .logo-banner {
        position: absolute;
        right: 22px;
        bottom: 6px;
        width: 26vw;
        height: auto;
        max-width: 288px;
        @include mq-down(xl) {
          right: 1.5vw;
        }
        @include mq-down(sm) {
          bottom: 3px;
        }
      }
    }
  }
  .btn-gallery-sp {
    @include btn-gallery-base;
  }
}
// ローンチカスタマー・掲載メディア
.launch-customer {
  .h2-small {
    margin-bottom: 0px;
    @include mq-down {
      margin-bottom: 32px;
    }
  }
  img {
    display: block;
    margin: auto;
    max-width: 1280px;
    width: 100vw;
  }
}
// 掲載メディア
.publication {

}
// cre8tiveAIに関するお知らせ
.app-news {
  .table {
    margin: auto;
    width: 92vw;
    max-width: 960px;
    // border: 1px solid #BDBDBD;
    border-radius: 4px;
    .tr {
      display: flex;
      padding: 16px 0;
      @include mq-down {
        flex-direction: column;
      }
      .left-row {
        min-width: 215px;
        padding: 0px;
        margin-left: 0;
        margin-right: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .date {
          color: #616161;
        }
        @mixin tags {
          border-radius: 8px;
          padding: 2px 12px;
          display: inline;
          font-weight: 400;
          margin-bottom: 0;
          margin-right: 16px;
        }
        .trouble {
          @include tags;
          background-color: #CFD8DC;
        }
        .notice {
          @include tags;
          background-color: #C8E6C9;
        }
      }
      .details {
        width: 100%;
        margin: 0px 0px 0px 16px;
        display: flex;
        align-items: center;
        @include mq-down {
          margin: 8px 0px 0px 0px;
        }
        a {
          color: #0D47A1;
          cursor: pointer;
        }
      }
    }
    .news-divider {
      margin: 0 16px;
      @include mq-down {
        margin: 0;
      }
    }
    .see-more {
      text-align: right;
      margin-top: 8px;
      a {
        color: #0D47A1;
        cursor: pointer;
      }
    }
  }
}
// お問い合わせ
.contact-us {
  margin-bottom: 58px;
  h2 {
    margin-top: 0px;
    margin-bottom: 80px;
  }
  label {
    margin-bottom: 0px;
  }
  .v-btn {
    padding-right: 24px;
    padding-left: 24px;
  }
}
.google-form {
  @include mq-down {
    // display: none !important;
    width: 94% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>

<i18n src="@/locales/home.json"></i18n>
