<template>
  <div id="mp">
    <service-header :auth="auth"/>
    <div id="mp-body">
      <div class="mainvisual">
        <img class="mainvisual-bg display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/hero_bg@2x.webp"/>
        <img class="mainvisual-bg display-sp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/hero_bg@2x_sp.webp"/>
        <div class="catch-copy-container">
          <p class="catch-copy" v-html="$t('mp_hero')"></p>
          <img class="logo-type-and-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/logo_type_and_mark.svg">
        </div>
      </div>

      <!-- ドロップエリア -->
      <div class="drop_area width-mv">
        <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area-inner" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <p class="white--text display-pc">{{ $t("glb_upload2") }}</p>
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" round outline dark @click="openFileSelect">
            {{ $t("glb_upload3") }}
          </v-btn>
          <input ref="inputFile" type="file" id="target_image" name="target_image" :accept="acceptableExtensions" @change="onSelectFile"/>
        </v-layout>

        <v-layout align-center justify-center column fill-height v-else class="drop_area-inner">
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" @click="auth.login('login')" round outline dark>
            {{ $t("glb_upload1") }}
          </v-btn>
        </v-layout>
      </div>

      <!-- データページへの導線 -->
      <div v-if="logined" class="lead-other-page px-3">
        <p v-html="$t('glb_10', ['mp'])"></p>
      </div>

      <div class="aboutsf">
        <div class="base-container">
          <div class="display-pc brand-container">
            <v-img class="brand-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/icon/mp_logo.svg"></v-img>
          </div>
          <!-- Mono Painterとは？ -->
          <h1 class="aboutsf-heading" v-html="$t('mp_head1')"></h1>
          <p class="aboutsf-paragraph" v-html="$t('mp_explanation1')"></p>

          <!-- 映像の着彩問い合わせ先 -->
          <v-btn class="to-form" round outline color="#02A4B6">
            <router-link to="/#g-form-area" class="no-deco text-none">{{ $t("mp_explanation2") }}</router-link>
          </v-btn>

          <!-- 着彩化ができる -->
          <h3 class="aboutsf-subheading">
            {{ $t("mp_head2") }}
          </h3>
          <!-- 変換前後の比較例5つ -->
          <div class="display-pc compare-container">
            <div v-for="(pic, i) in comparePics" :key=i>
              <h3 class="style-title">{{ $t(comparePicsI18NIds[i]) }}</h3>
              <div class="compare-box">
                <div class="compare-before">
                  <div class="before-pic" :style="`background-image:url(${pic[0]})`"></div>
                </div>
                <div class="arrow-container">
                  <v-img class="transfer-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/arrow.svg"></v-img>
                </div>
                <div class="compare-after">
                  <div class="after-pic" :style="`background-image:url(${pic[1]})`"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="display-sp compare-container">
            <div v-for="(pic, i) in comparePicsSP" :key=i>
              <h3 class="style-title">{{ $t(comparePicsI18NIds[i]) }}</h3>
              <div class="compare-box">
                <div class="compare-before">
                  <div class="before-pic" :style="`background-image: url(${pic[0]}); height: ${i === 0 ? spComparePicHeight0 : spComparePicHeight1and2}px;`"></div>
                </div>
                <div class="arrow-container">
                  <v-img class="transfer-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/arrow.svg"></v-img>
                </div>
                <div class="compare-after">
                  <div class="after-pic" :style="`background-image: url(${pic[1]}); height: ${i === 0 ? spComparePicHeight0 : spComparePicHeight1and2}px;`"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <!-- 6種類提供します -->
      <div class="offer">
        <div class="base-container">
          <!-- 仕様 -->
          <div class="spec-container">
            <spec></spec>
          </div>
        </div>
      </div>

      <!-- 支える技術 -->
      <div class="about-technology">
        <div class="base-container">
          <h2 class="about-technology-h2">
            {{ $t("mp_head4") }}
          </h2>
          <p class="technology-description">{{ $t("mp_tech") }}</p>
        </div>
      </div>

      <!-- ドロップエリア2 -->
      <div class="drop_area2_container">
        <div class="drop_area">
          <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area-inner" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
            <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
            <p class="white--text display-pc">{{ $t("glb_upload2") }}</p>
            <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" round outline dark @click="openFileSelect">
              {{ $t("glb_upload3") }}
            </v-btn>
            <input ref="inputFile" type="file" id="target_image" name="target_image" :accept="acceptableExtensions" @change="onSelectFile"/>
          </v-layout>

          <v-layout align-center justify-center column fill-height v-else class="drop_area-inner">
            <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
            <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" @click="auth.login('login')" round outline dark>
              {{ $t("glb_upload1") }}
            </v-btn>
          </v-layout>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <!-- 利用のSTEP -->
      <div class="usage_steps">
        <h2 class="usage_steps-heading">{{ $t("mp_head5") }}</h2>
        <div class="base-container">
          <div class="steps-flex">
            <div class="one-step">
              <p class="step-number">①</p>
              <h3 class="step-title">
                <span>{{ $t("mp_step1") }}</span>
              </h3>
              <div class="upload-pic-container">
                <img class="upload-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/step1_cloud.svg"/>
                <img class="original-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/step1@2x.webp"/>
              </div>
              <p class="step-description">
                {{ $t("mp_step1_txt") }}
              </p>
            </div>
            <div class="one-step">
              <p class="step-number">②</p>
              <h3 class="step-title">
                <span>{{ $t("mp_step2") }}</span>
              </h3>
              <div class="confirm-container">
                <v-img class="confirm-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/step2@2x.webp"></v-img>
              </div>
              <p class="step-description">
                {{ $t("mp_step2_txt") }}
              </p>
            </div>
            <div class="one-step">
              <p class="step-number">③</p>
              <h3 class="step-title">
                <span>{{ $t("mp_step3") }}</span>
              </h3>
              <div class="result-container">
                <v-img class="dl-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/step3.svg"></v-img>
              </div>
              <p class="step-description">
                {{ $t("mp_step3_txt") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 着彩化してみるボタン -->
      <v-btn dark class="button btn-cv text-none" @click.stop="onTapExecButton">
        {{ $t("mp_exec") }}
      </v-btn>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <!-- Price -->
      <div id="price">
        <h2 class="price-heading">
            {{ $t("mp_head6") }}
        </h2>
        <p class="price-caution">{{ $t("mp_price") }}</p>
        <div class="base-container">
          <div class="price-container">
            <!-- エントリー -->
            <div class="price-item">
              <div class="plan-container">
                <p class="plan-name">{{ $t("mp_plan1") }}</p>
                <p class="monthly-price">{{ $t("mp_plan1_sub") }}</p>
              </div>
              <div class="mp-price-container">
                <!--  翻訳マスタにHTML及びスタイルを埋め込む -->
                <div>
                  <span v-html="$t('mp_plan1_type')"/>
                  <span v-html="$t('mp_plan1_price')"/>
                </div>
              </div>
            </div>
            <!-- ライト -->
            <div class="price-item">
              <div class="plan-container">
                <p class="plan-name">{{ $t("mp_plan2") }}</p>
                <p class="monthly-price">{{ $t("mp_plan2_sub") }}</p>
              </div>
              <div class="mp-price-container">
                <!--  翻訳マスタにHTML及びスタイルを埋め込む -->
                <div>
                  <span v-html="$t('mp_plan2_type')"/>
                  <span v-html="$t('mp_plan2_price')"/>
                </div>
                <p class="real-price">{{ $t("mp_plan2_real") }}</p>
              </div>
            </div>
            <!-- メガ -->
            <div class="price-item">
              <div class="plan-container">
                <p class="plan-name">{{ $t("mp_plan3") }}</p>
                <p class="monthly-price">{{ $t("mp_plan3_sub") }}</p>
              </div>
              <div class="mp-price-container">
                <!--  翻訳マスタにHTML及びスタイルを埋め込む -->
                <div>
                  <span v-html="$t('mp_plan3_type')"/>
                  <span v-html="$t('mp_plan3_price')"/>
                </div>
                <p class="real-price">{{ $t("mp_plan3_real") }}</p>
              </div>
            </div>
          </div>
          <!-- 価格を見る -->
          <v-btn class="to-price no-deco text-none" round outline color="#0285D1" to="/price">
            {{ $t("mp_to_price") }}
          </v-btn>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="spec2-container">
        <div class="base-container">
          <spec></spec>
        </div>
      </div>

      <!-- API連携 -->
      <div class="about-api">
        <div class="base-container">
          <h2 class="about-api-h2">
            <span class="maker-line for-api">
              cre8tiveAI
            </span>
            <br class="display-sp">
            <span class="maker-line for-api upper-sp">
              Mono Painter API
            </span>
          </h2>
          <h3 class="about-api-heading">
            <span>{{ $t("mp_api1") }}</span>
          </h3>
          <p class="api-descriptions">
            {{ $t("mp_api2") }}
          </p>
          <v-img class="main-illust display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/api_main_illust_pc.svg">
            <p class="p-request" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api3") }}</p>
            <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api4") }}</p>
            <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api5") }}</p>
          </v-img>
          <v-img class="main-illust display-sp" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_main_illust_sp.svg">
            <p class="p-request" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api3") }}</p>
            <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api4") }}</p>
            <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("mp_api5") }}</p>
          </v-img>
          <v-btn class="api-btn" to="/#g-form-area">
            <span class="dotted">{{ $t("mp_api6") }}</span>
          </v-btn>
        </div>
      </div>

      <!-- 警告モーダル(OKで次に進める) -->
      <v-dialog v-model="showWarnModal" persistent width="350">
        <v-card>
          <!-- <v-card-title class="title lighten-2 px-5" primary-title>
            {{ $t("err_1") }}
          </v-card-title> -->
          <v-card-text>
            <span>{{ $t(targetImageWarnModalI18NId) }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="grey darken-1"
              flat="flat"
              @click="cancelWithWarnConfirmed"
            >{{ $t("glb_5") }}</v-btn>
            <v-btn color="blue" flat @click="proceedWithWarnConfirmed" class="mx-3 my-2">
              {{ $t("glb_6")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- エラーモーダル -->
      <v-dialog v-model="showErrorModal" persistent width="350">
        <v-card>
          <!-- <v-card-title class="title lighten-2 px-5" primary-title>
            {{ $t("err_1") }}
          </v-card-title> -->
          <v-card-text>
            <!-- <span v-html="$t(targetImageErrorModalI18NId)"></span> -->
            <span>{{ $t(targetImageErrorModalI18NId) }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" flat @click="closeErrorModal" class="mx-3 my-2">
              {{ $t("glb_1") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 依頼モーダル -->
      <order-modal-container
        ref="orderModalRef"
        :show="showOrderModal"
        @close="closeOrderModal"
      >
      </order-modal-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spec from "./Spec.vue"
import OrderModalContainer from "./OrderModalContainer.vue"
import ServiceHeader from '@/components/ServiceHeader.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import AuthService from '@/auth/AuthService'

export default {
  name: 'MonoPainterTop',
  props: ['auth'],
  components: {
    Spec,
    OrderModalContainer,
    ServiceHeader,
    AdResponsive
  },
  data() {
    return {
      showWarnModal: false,
      showErrorModal: false,
      comparePics: [
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before1@2x.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after1@2x.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before2@2x.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after2@2x.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before3@2x.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after3@2x.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before4@2x.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after4@2x.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before5@2x.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after5@2x.webp"],
      ],
      comparePicsSP: [
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before1@2x_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after1@2x_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before2@2x_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after2@2x_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before3@2x_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after3@2x_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before4@2x_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after4@2x_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/mp/before5@2x_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/mp/after5@2x_sp.webp"],
      ],
      comparePicsI18NIds: [
        "mp_ex1",
        "mp_ex2",
        "mp_ex3",
        "mp_ex4",
        "mp_ex5"
      ],
      spExampleImgHeight: null,
      // tif, tiff, tgaも入れたかったが、img.onload が走らずサイズ判定が出来ないので対象外とした
      acceptableExtensions: '.jpg, .jpeg, .jpe, .png, .heic, .heif, .webp, .bmp'
    }
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    guestExec() {
      return this.$store.getters['user/guestExec']
    },
    currentGroup() {
      return this.$store.getters['user/currentGroup']
    },
    currentSubscription() {
      return this.$store.getters['master/subscriptionById'](this.currentGroup.subscription_id)
    },
    ...mapGetters("monoPainter", [
      "targetImageSrc",
      "targetImageWarnModalI18NId",
      "targetImageErrorModalI18NId",
      "requestId",
      "execCompleted"
    ]),
    // cssで height: calc((100% - 48px) * 600/654);
    // としたらうまくいかなかったのでJSで計算
    spComparePicHeight0() {
      // 元画像サイズは654x600px
      const sidePadding = 24 * 2
      const height = (window.innerWidth - sidePadding) * 600 / 654
      return height
    },
    spComparePicHeight1and2() {
      // 元画像サイズは654x320px
      const sidePadding = 24 * 2;
      const height = (window.innerWidth - sidePadding) * 320 / 654;
      return height
    },
    showOrderModal() {
      return this.targetImageSrc && !this.showWarnModal
    }
  },
  methods: {
    onDropFile(e) {
      // モーダルを開く前にstoreをreset (変換完了して戻ってきた場合でも確実にresetする)
      this.$store.commit('monoPainter/reset')

      if (!this.logined && !this.guestExec) return
      const files = []
      // FileList型はforEachできないためforで回す
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i]
        files.push(file)
      }

      this.$store.commit('monoPainter/setTargetImageFile', {
        files: files,
        subsc_grouping_id: this.currentSubscription.grouping_id
      })
    },
    openFileSelect() {
      this.$refs.inputFile.click()
    },
    onSelectFile(e) {
      this.$store.commit('monoPainter/reset')

      const files = []
      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        files.push(file)
      }
      this.$store.commit('monoPainter/setTargetImageFile', {
        files: files,
        subsc_grouping_id: this.currentSubscription.grouping_id
      })
    },
    // 着彩化してみるボタン押下
    onTapExecButton() {
      if (this.logined || this.guestExec) {
        this.openFileSelect()
        return
      } else {
        this.auth.login('login')
      }
    },
    proceedWithWarnConfirmed() {
      this.showWarnModal = false
    },
    cancelWithWarnConfirmed() {
      this.$store.commit('monoPainter/cleartargetImageFile')
      this.showWarnModal = false
      this.$refs.inputFile.value = ''
    },
    closeOrderModal() {
      this.$store.commit('monoPainter/cleartargetImageFile')
      this.$refs.inputFile.value = ''
    },
    closeErrorModal() {
      this.$store.commit('monoPainter/cleartargetImageFile')
      this.showErrorModal = false
      this.$refs.inputFile.value = ''
    }
  },
  watch: {
    targetImageWarnModalI18NId(id) {
      this.showWarnModal = id != null
    },
    targetImageErrorModalI18NId(id) {
      this.showErrorModal = id != null
    },
    execCompleted(completed) {
      if (completed) {
        this.$store.commit('screen/footerVisible', false)  // 遷移後のページは動的に高さが変わるので高さが決まってからfooterを表示するために非表示にしておく
        this.$router.push({name: 'mpResult', params: {requestId: this.requestId}})
      }
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
    this.$store.commit('monoPainter/reset')
    if (!AuthService.isAuthenticated(1)) this.$store.dispatch('user/getGuestUser')
  },
  mounted() {
    // v-htmlでaタグを使用すると非SPA遷移になるので、SPA遷移になるように改良
    // SPA遷移だと遷移スピードも速いし、ServiceHeader経由ですぐサービストップページに戻ることができる
    const targetElems = document.getElementsByClassName('to-data-page')
    if (targetElems.length) {
      const dataPageLink = targetElems[0]
      dataPageLink.addEventListener('click', (event) => {
        event.preventDefault()
        this.$router.push(dataPageLink.getAttribute('href'))
      })
    }
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/_variables.scss";

.mp-price-container {
  // 翻訳マスタに入れるHTMLから参照するクラス
  //   scopedだと自動で属性(data-v-NNNN) がつくが、翻訳マスタに入れるHTMLにはつかないため
  //   非scopedなcssとしてここに定義
  .string {
    font-size: 28px;
    color: #424242;
    @include mq-down {
      font-size: 18px;
      font-weight: 500;
    }
  }
  .number {
    font-size: 64px;
    font-weight: 500;
    color: #0285D1;
    @include mq-down {
      font-size: 58px;
    }
    @include mq-down(sm) {
      font-size: 40px;
    }
  }
  .left-margin {
    margin-left: 50px;
    @include mq-down {
      margin-left: 20px;
    }
  }
  .left-margin-small {
    margin-left: 34px;
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/mono_painter/_variables.scss";
@import "../../assets/scss/mono_painter/top.scss";
</style>
<i18n src="../../locales/mono_painter/top.json"></i18n>
