<template>
  <v-dialog v-model="showd" width="736">
    <v-card class="dl-dialog"
    :class="{'dl-dialog__make':stayingServicePath === '/sc/make'}">

      <v-layout justify-center align-center class="face-header mx-3 my-0">
        <v-img class="face-img elevation-2 ml-0" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg"></v-img>
        <div class="balloon balloon--left_top white mr-0">
          <p class="pink--text mb-0 mx-auto">{{ $t("sc_dl_1a") }}</p>
        </div>
      </v-layout>

      <div class="dl-images">
        <v-img :src="selectedImgSrc" class="dl-img mx-auto">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/wood_frame.webp" class="img-front ma-auto"></v-img>
        </v-img>
        <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/dl-image-back.webp" class="img-back ma-auto"></v-img>
      </div>

      <!-- ===== ボタン３パターンの分岐 ===== -->

      <!-- 課金･クレジット支払い済orプレゼントor都度課金 -->
      <div v-if="stayingServicePath === '/sc' || is_dl_done || !canDLByCredit">
        <v-btn v-if="showDrawBodyOrderButton" round color="#FCEC76" large @click="proceedToOrder" class="sc-plus-btn--large text-none">
          {{ $t("sc_dl_15") }}
        </v-btn>
        <v-btn @click="onClickDownload" class="dl-btn" dark round large block>
          {{ $t("sc_dl_2") }}
        </v-btn>
      </div>
      <!-- DLボタンとダイアログ(クレジット消費) -->
      <div v-else class="dlbtn-and-dialog">
        <v-btn v-if="showDrawBodyOrderButton" round color="#FCEC76" large @click="proceedToOrder" class="sc-plus-btn--large">
          {{ $t("sc_dl_15") }}
        </v-btn>
        <v-dialog v-model="confirmDialog" width="500">
          <!-- DLダイアログを呼ぶDLボタン -->
          <v-btn slot="activator" class="dl-btn" dark round large block @click="fireShowCreditModalEvent">
            {{ $t("sc_dl_2") }}
          </v-btn>
          <!-- DLダイアログ -->
          <v-card>
            <v-card-title class="title lighten-2 px-5 font-weight-bold" primary-title>
              {{ $t("sc_dl_2") }}
            </v-card-title>
            <v-card-text class="text-xs-left px-5">
              {{ $t("sc_dl_11", ["5"]) }} <br>{{ $t("sc_dl_12") }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat="flat" @click="confirmDialog = false">
                {{ $t("sc_dl_13") }}
              </v-btn>
              <v-btn color="primary" flat @click="onClickDownload()">
                {{ $t("sc_dl_14") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <!-- ボタン下のキャプション -->
      <div class="pink--text" :class="{ hidden: stayingServicePath === '/sc' || is_dl_done }">
        <div v-if="canDLByCredit">
          <p class="credit" >{{ $t("sc_dl_8", ["5"]) }}（{{ $t("sc_dl_10", [holding_credit]) }}）</p>
        </div>
        <div v-else>
          <p class="payment">{{ $t("sc_dl_6") }}<br>{{ $t("sc_dl_7") }}</p>
        </div>
      </div>

      <v-btn v-if="!showDrawBodyOrderButton" @click="proceedToOrder" round color="#FCEC76" class="sc-plus-btn my-2">
        {{ $t("sc_dl_15") }}
      </v-btn>

      <div v-if="stayingServicePath === '/sc/make'">

        <v-layout align-center justify-center fill-height class="pink--text" :class="{'mt-3': $vuetify.breakpoint.smAndUp, 'mt-1':$vuetify.breakpoint.smAndDown}">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/common/share-slash.svg" alt="slash" class="slash ">
          <p class="share-text mb-0 mx-4">{{ $t("sc_dl_3") }}</p>
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/common/share-slash.svg" alt="slash" class="slash reverse">
        </v-layout>

        <v-layout align-center justify-center :class="{'mt-3': $vuetify.breakpoint.smAndUp, 'mt-1':$vuetify.breakpoint.smAndDown}">
          <a class="sns-link" :href="'https://www.facebook.com/share.php?u=' + encodedURLForShare" rel="nofollow" target="_blank">
            <v-img
            src="https://storage.googleapis.com/cre8tiveai_public_files/img/common/fb_circle.png" class="icon-circle"
            v-ripple="{ class: `white--text` }"></v-img>
          </a>

          <a class="sns-link" :class="{'mx-5': $vuetify.breakpoint.mdAndUp, 'mx-4':$vuetify.breakpoint.xs}" :href="'https://social-plugins.line.me/lineit/share?url=' + encodedURLForShare" target="_blank">
            <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/common/line_circle.png"
            class="icon-circle"
            v-ripple="{ class: `white--text` }"></v-img>
          </a>

          <a class="sns-link" :href="'https://twitter.com/share?hashtags=cre8tiveAI,SAI&text=AIに顔イラストの制作をお願いしよう！%20/%20Let’s%20ask%20AI%20to%20make%20face%20illustrations!&url=' + encodedURLForShare" target="_blank">
            <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/common/twitter_circle.png"
            class="icon-circle"
            v-ripple="{ class: `white--text` }"></v-img>
          </a>
        </v-layout>

        <rating serviceName='sai_chan' :requestId="imageHashValue"></rating>

        <div class="mt-3">
          <v-btn dark round outline active-class color="pink" :class="{'mr-5': logined}" to="/sc">
            {{ $t("sc_dl_4") }}
          </v-btn>
          <v-btn v-if="logined" dark round outline color="pink" to="/data/sc">
            {{ $t("sc_dl_5") }}
          </v-btn>
        </div>

        <services></services>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import { SAI_CHAN_UNIT_CREDIT_FOR_DL } from "../../settings";
import Rating from "@/components/Rating";
import Services from '@/components/Services.vue'

export default {
  data () {
    return {
      showd: this.showDialog,
      confirmDialog: false,
      creditShortage: false
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    selectedImgSrc: {
      type: String,
    },
    selectedImgIdx: {
      type: String,
    },
    holding_credit: {
      type: Number,
    },
    is_dl_done: {
      type: Boolean,
    },
    current_plan_id: {
      type: Number,
    },
    showDrawBodyOrderButton: {
      type: Boolean,
    }
  },
  components: {
    Rating,
    Services
  },
  watch: {
    showDialog (val) {
      this.showd = val
    },
    showd (val) {
      !val && this.$emit('close');
    },
    holding_credit (val) {
      if (val < SAI_CHAN_UNIT_CREDIT_FOR_DL) {
        this.creditShortage = true
      }
    },
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    stayingServicePath() {
      return this.$store.state.user.stayingServicePath || '/'
    },
    canDLByCredit() {
      if (this.holding_credit >= SAI_CHAN_UNIT_CREDIT_FOR_DL) {
        return true;
      }
      return false;
    },
    imageHashValue() {
      // eslint-disable-next-line no-useless-escape
      let regex = new RegExp('^https.+/([a-zA-Z0-9]+)\.png$')
      let matched = regex.exec(this.selectedImgSrc)
      if (matched) {
        return matched[1]
      } else {
        return ''
      }
    },
    encodedURLForShare() {
      // ハッシュ値だけ抜き出して、sc?imgid=xxx でシェア
      let hashVal = this.imageHashValue
      if (hashVal !== '') {
        return encodeURIComponent('https://cre8tiveai.com/sc?imgid=' + hashVal)
      } else {
        // 基本的に来ないはず
        return encodeURIComponent('https://cre8tiveai.com/sc')
      }
    },
  },
  methods: {
    onClickDownload() {
      this.$emit('onClickDownload')
      this.confirmDialog = false
    },
    proceedToOrder() {
      this.$emit('proceedToOrder', {src: this.selectedImgSrc, idx: this.selectedImgIdx})
    },
    fireShowCreditModalEvent() {
      const group = this.$store.getters['user/currentGroup']
      /*global gtag*/
      gtag('event', 'show_credit_modal', {
        'event_category': 'credit',
        'event_label': 'saichan',
        'value': group.subscription_id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
$accent-color: #FF5891;
a {
  &:hover {
    text-decoration: none;
  }
}
.face-header {
  max-width: 1040px;
  margin-top: 16px;
  margin-bottom: 16px;

  @include mq-down {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .face-img {
    width: 100%;
    max-width: 72px;
    border-radius: 99px;
    border: solid 2px $accent-color;
    @include mq-down {
      max-width: 40px;
      max-height: 40px;
    }
  }

  @mixin balloon {
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    padding: 8px 16px;
    margin-left: 20px;
    border-radius: 16px;
    p {
      font-size: 24px;
    }
    @include mq-down {
      padding: 8px 0px;
      border-radius: 8px;
      margin-left: 12px;
      width: 100%;
      p {
        font-size: 4.2vw;
      }
    }
  }

  .balloon {
    @include balloon;
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 20px; height: 20px; /* 吹き出しサイズ */
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0; left: 0;
      width: 100%; height: 100%;
      border-radius: 16px;
      @include mq-down {
        border-radius: 8px;
      }
    }
    &>* {
      position: relative;
      z-index: 3;
      background: white;
    }
  }
  .balloon,
  .balloon::before {
    box-shadow: 0 1px 3px 0 rgba(black,0.3); /* ぼかし距離(第3引数)，拡散距離(第4引数)，色(第5引数) */
  }
  .balloon,
  .balloon::before,
  .balloon::after {
    background: white; /* 背景色 */
  }

  .balloon--left_top::before {
    left: -6px; top: 50%; /* 位置 */
    transform: translateY(-50%) rotate(45deg) skew(-12deg,-12deg); /* 傾斜角(skew) */
    @include mq-down {
      left: -2px;
    }
  }
}
.dl-dialog {
  padding: 48px 0px;

  @include mq-down {
    padding: 32px 0px;
  }

  &.dl-dialog__make {
    padding: 32px 0px;

    @include mq-down {
      padding: 16px 0px;
    }
  }

  .dl-images {
    position: relative;
    margin: 48px 0px;

    @include mq-down {
      margin: 28px 0px;
    }


    .img-back {
      position: absolute;
      width: 432px;
      height: 432px;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      @include mq-down {
        width: 82vw;
        height: 82vw;
      }
    }

    .dl-img {
      width: 256px;
      position: relative;
      z-index: 20;
      overflow: visible;

      @include mq-down {
        width: 45vw;
      }

      .img-front {
        position: absolute;
        width: 300px;
        height: 300px;
        z-index: 50;
        top: 0;
        bottom: 0;
        left: -8%;
        // right: 20%;

        @include mq-down {
          width: 117%;
          height: 117%;
        }
      }
    }
  }

  .dlbtn-and-dialog {
    /deep/ .v-dialog__container {
      display: block !important;
    }
  }

  .dl-btn {
    background: linear-gradient(120deg, #FF8A80, #E91E63);
    font-size: 20px;
    font-weight: 500;
    // padding-top: 28px;
    // padding-bottom: 28px;
    height: 56px;
    border-radius: 99px;
    max-width: 320px;
    margin-right : auto;
    margin-left : auto;
    display: flex;

    @include mq-down {
      display: inline-flex;
      padding-top: initial;
      padding-bottom: initial;
      padding-left: 48px;
      padding-right: 48px;
      font-size: initial;
      height: 44px;
      max-width: 60vw;
    }
  }

  .hidden {
    visibility: hidden;
  }

  .share-text {
    font-size: 16px;

    @include mq-down {
      font-size: 14px;
    }
  }

  .slash {
    width: 16px;
    &.reverse {
      transform: scale(-1, 1);
    }
  }

  .sns-link {
    width: 48px;

    @include mq-down(sm) {
      width: 40px;
    }
  }

  @mixin override($color:white) {
    content: '';
    background-color: $color;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: .1s;
    z-index: 50;
  }

  .icon-circle {
    max-width: 48px;
    position: relative;
    cursor: pointer;
    transition: .2s;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0px 0.5px 1px 0px rgba(black,0.4);

    @include mq-down(sm) {
      max-width: 40px;
    }

    &::after {
      @include override;
      opacity: 0;
    }
    &:hover {
      box-shadow: 0px 2px 4px 0px rgba(black,0.4);
      position: relative;
      &::after {
        @include override;
        opacity: .1;
      }
    }
  }
  p.credit, p.payment {
    font-size: 12px;
  }
}
.sc-plus-btn, .sc-plus-btn--large {
  color: #424242;
}

.sc-plus-btn--large {
  margin-top: 12px;
  margin-bottom: 16px;
  @include mq-up {
    font-size: 20px;
    padding: 16px 40px;
    height: initial;
    margin-bottom: 32px;
  }
}
</style>

<i18n src="@/locales/sai_chan/download_modal.json"></i18n>
