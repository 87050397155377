<template>
  <div>
    <p class="spec-title">{{ $t("ps_spec_title")}}</p>
    <div class="spec-container">
      <div class="item">
        <p class="spec-title">{{ $t("ps_spec_key1") }}</p>
        <p class="spec-value">{{ $t("ps_spec_val1") }}</p>
      </div>
      <div class="item">
        <p class="spec-title">{{ $t("ps_spec_key2") }}</p>
        <p class="spec-value">{{ $t("ps_spec_val2") }}</p>
      </div>
      <div class="item">
        <p class="spec-title">{{ $t("ps_spec_key3") }}</p>
        <p class="spec-value">{{ $t("ps_spec_val3") }}</p>
      </div>
      <div class="item">
        <p class="spec-title">{{ $t("ps_spec_key4") }}</p>
        <p class="spec-value">{{ $t("ps_spec_val4") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/png_smallify/_variables.scss";
@import "../../assets/scss/sync_process_job/spec.scss";
</style>
<i18n src="../../locales/png_smallify/spec.json"></i18n>
