<template>
  <div>
    <service-header :auth="auth" v-if="stayingServicePath !== '/'"/>
    <div class="item-container">
      <div class="plan">
        <div class="caption">
          <h1>{{ $t("price_1") }}</h1>
          <p>{{ $t("price_2") }}</p>
        </div>
        <!-- ↓プランの板↓ -->
        <div v-for="subsc in subscriptions" :key="subsc.id" class="plan-boad shd-m">
          <h4>{{ subsc.name }}</h4>
          <h5 v-if="subsc.price_monthly > 0">
            {{subsc.credit_grant_monthly}}{{ $t("glb_price_2") }}
          </h5>
          <h5 v-else>{{ $t("glb_price_1") }}</h5>
          <p>
            {{ $t("glb_price_3", [addComma(formatPriceMonthly(subsc))]) }}<span class="title font-weight-normal">{{ $t("glb_price_4") }}</span>
          </p>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              Photo Refiner&nbsp;{{ $t("glb_price_5", [formatUnitPrice(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_8") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_9") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceFR(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_27") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceSc(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_9") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_2") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceEp(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_20") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_3") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceMPM(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_21") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_4") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceLID(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_22") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_5") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceMP(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_23") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_6") }}&nbsp;{{ $t("glb_price_5", [formatUnitPricePD(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_24") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_7") }}&nbsp;{{ $t("glb_price_5", [formatUnitPriceAAP(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_25") }}
            </div>
          </div>
          <div class="addition">
            <div v-if="subsc.grouping_id == 1">
              {{ $t("glb_price_7_8") }}&nbsp;{{ $t("glb_price_5", [formatUnitPricePS(subsc)]) }}
            </div>
            <div v-else>
              {{ $t("glb_price_26") }}
            </div>
          </div>
          <ul>
            <li v-if="subsc.credit_roll_over == 0">
              <font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_10") }}
            </li>
            <li v-else>
              <font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_11") }}
            </li>
            <li>
              <font-awesome-icon icon="check" class="check-icon"/>
              <span v-if="subsc.image_size_limit == 1">{{ $t("glb_price_12") }}</span>
              <span v-else>{{ $t("glb_price_13") }}</span>
            </li>
            <li>
              <font-awesome-icon icon="check" class="check-icon"/>
              <span v-if="subsc.grouping_id == 1">{{ $t("glb_price_14") }}</span>
              <span v-else-if="subsc.grouping_id < 4">{{ $t("glb_price_15") }}</span>
              <span v-else>{{ $t("glb_price_16") }}</span>
            </li>
            <li>
              <font-awesome-icon icon="check" class="check-icon"/>
              {{ $t("glb_price_17", [subsc.store_duration]) }}
            </li>
            <li v-if="subsc.account_limit_num > 1">
              <font-awesome-icon icon="check" class="check-icon"/>{{ subsc.account_limit_num }} {{ $t("glb_price_18") }}
            </li>
            <!-- zipでまとめて処理機能(スタンダードプラン以上) -->
            <li :class="{'hidden': subsc.id < 3}">
              <font-awesome-icon icon="check" class="check-icon"/>{{ $t("glb_price_19") }}
            </li>
          </ul>

          <!-- ログイン中に表示される -->
          <div class="bottom-btns mb-3">
            <!-- grouping_idで比較することで、通貨が違ってもマスタのプランとユーザ(グループ)の加入中プランを同定できる。 -->
            <v-btn v-if="currentGroup && currentSubscription.grouping_id == subsc.grouping_id && currentSubscription.plan_changeable === 1" class="plan-now px-5" dark disabled>
              <!-- 利用中 -->
              {{ $t("price_3") }}
            </v-btn>
            <div v-else-if="currentGroup && currentGroup.subscription_id && isCurrentGroupAdmin">
              <div v-if="currentGroup.will_cancel_subscription">
                <v-btn class="plan-btn" dark outline round>
                  <p v-if="subsc.price_monthly == 0">
                    <!-- {{ unixToStr(currentGroup.subscription_current_period_end) }}に解約しお試しプランに戻ります -->
                    {{ $t("price_4") }}
                  </p>
                </v-btn>
              </div>
              <div v-else>
                <v-btn v-if="subsc.price_monthly == 0" :disabled="!canChangeContract" @click="confirmDowngrade(subsc)" class="plan-btn px-4" :class="{disabled: !canChangeContract}" dark outline round>
                  <!-- サブスクリプション解除 -->
                  {{ $t("price_5") }}
                </v-btn>
                <v-btn v-else-if="currentSubscription.price_monthly == 0" :disabled="!canChangeContract" @click="applyPlan(subsc.id)" class="plan-btn px-4" :class="{disabled: !canChangeContract}" dark outline round>
                  <!-- 申し込む -->
                  {{ $t("price_6") }}
                </v-btn>
                <v-btn v-else-if="subsc.id > currentGroup.subscription_id" :disabled="!canChangeContract" @click="confirmUpgrade(subsc)" class="plan-btn" :class="{disabled: !canChangeContract}" px-4 dark outline round>
                  <!-- アップグレード -->
                  {{ $t("price_7") }}
                </v-btn>
                <v-btn v-else :disabled="!canChangeContract" @click="confirmDowngrade(subsc)" class="plan-btn px-4" :class="{disabled: !canChangeContract}" dark outline round>
                  <!-- ダウングレード -->
                  {{ $t("price_4") }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <!-- ↑プランの板↑ -->
        <!-- upgrade確認 -->
        <v-dialog v-model="showUpgradeConfirm" width="704">
          <v-card>
            <v-card-title class="title lighten-2 px-5 font-weight-bold" primary-title>
              {{ $t("price_8") }}
            </v-card-title>
            <v-card-text class="text-xs-left px-5 py-2" v-if="confirmTargetSubsc">
              <h3>{{ $t("price_9") }}</h3>
              <h5 class="mt-4">{{ $t("price_10") }}</h5>
              <p class="ml-2">
                {{ currentSubscription.name }} ({{ currentSubscription.credit_grant_monthly }}{{ $t("price_11") }}, <span v-if="this.currentSubscription.currency === 'jpy'">{{ formatPriceMonthly(this.currentSubscription) }}円/月</span><span v-else>US${{ formatPriceMonthly(this.currentSubscription) }}/month</span>, {{ currentSubscription.account_limit_num }}{{ $t("price_12") }})
              </p>
            </v-card-text>
            <v-flex xs8>
              <font-awesome-icon icon="arrow-down" class="title blue--text text--lighten-2"/>
            </v-flex>
            <v-card-text class="text-xs-left px-5 py-2" v-if="confirmTargetSubsc">
              <h5>{{ $t("price_13") }}</h5>
              <p class="ml-2">
                {{ confirmTargetSubsc.name }} ({{ confirmTargetSubsc.credit_grant_monthly }}{{ $t("price_11") }}, {{ $t("price_14",[formatPriceMonthly(confirmTargetSubsc)]) }}, {{ confirmTargetSubsc.account_limit_num }}{{ $t("price_12") }})
              </p>
            </v-card-text>
            <v-layout class="caption-container mx-4">
              <v-card-text class="text-xs-left px-3 caption grey lighten-4 caption-container">
              {{ $t("price_15") }}<br>
              {{ $t("price_16") }}<br>
              {{ $t("price_22") }}
              </v-card-text>
            </v-layout>

            <v-card-actions v-if="confirmTargetSubsc">
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="showUpgradeConfirm = false">
                {{ $t("price_17") }}
              </v-btn>
              <v-btn color="blue" dark round @click="upgradePlan(confirmTargetSubsc.id)" :disabled="processing" class="mx-3 my-2">
                {{ $t("price_8") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- downgrade確認 -->
        <v-dialog v-model="showDowngradeConfirm" width="704">
          <v-card>
            <v-card-title class="title lighten-2 px-5 font-weight-bold" primary-title>
              {{ $t("price_8") }}
            </v-card-title>
            <v-card-text class="text-xs-left px-5 py-2" v-if="confirmTargetSubsc">
              <h3>{{ $t("price_9") }}</h3>
              <h5 class="mt-4">{{ $t("price_10") }}</h5>
              <p class="ml-2">
                {{ currentSubscription.name }} ({{ currentSubscription.credit_grant_monthly }}{{ $t("price_18") }}, <span v-if="this.currentSubscription.currency === 'jpy'">{{ formatPriceMonthly(this.currentSubscription) }}円/月</span><span v-else>US${{ formatPriceMonthly(this.currentSubscription) }}/month</span>, {{ currentSubscription.account_limit_num }}{{ $t("price_12") }})
              </p>
            </v-card-text>
            <v-flex xs8>
              <font-awesome-icon icon="arrow-down" class="title blue--text text--lighten-2"/>
            </v-flex>
            <v-card-text class="text-xs-left px-5 py-2" v-if="confirmTargetSubsc">
              <h5>{{ $t("price_13") }}</h5>
              <p class="ml-2">
                {{ confirmTargetSubsc.name }} ({{ confirmTargetSubsc.credit_grant_monthly }}{{ $t("price_11") }}, {{ $t("price_14",[formatPriceMonthly(confirmTargetSubsc)]) }}, {{ confirmTargetSubsc.account_limit_num }}{{ $t("price_12") }})
              </p>
            </v-card-text>
            <v-layout class="caption-container mx-4">
              <v-card-text class="text-xs-left px-3 caption grey lighten-4 caption-container">
                {{ $t("price_19") }}<br>
                {{ $t("price_20") }}<br>
                {{ $t("price_21") }}<br>
                {{ $t("price_22") }}<br>
                {{ $t("price_23") }}
              </v-card-text>
            </v-layout>

            <v-card-actions v-if="confirmTargetSubsc">
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" flat @click="showDowngradeConfirm = false">
                {{ $t("price_17") }}
              </v-btn>
              <v-btn color="blue" dark round @click="downgradePlan(confirmTargetSubsc.id)" :disabled="processing" class="mx-3 my-2">
                {{ $t("price_8") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <p class="text-xs-right">
        <router-link to="/#g-form">{{ $t("price_24") }}</router-link>
      </p>

      <!-- Q&A -->
      <div class="faq-container">
        <h3>Q&A</h3>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_25") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_26") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_27") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_28") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_29") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_30") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_31") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_32") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_33") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_34") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_35") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_36") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_37") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_38") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_39") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_40") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_41") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_42") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_43") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_44") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_45") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_46") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_47") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_48") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_49") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_50") }}
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("price_51") }}</h4>
          <p>
            <span>A.</span>{{ $t("price_52") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'


import {API_BASE_URL, STRIPE_KEY} from '../settings'
import ServiceHeader from '@/components/ServiceHeader.vue'
import {
  unixToStr, formatPriceMonthly, formatUnitPrice,
  formatUnitPriceSc, formatUnitPriceEp, formatUnitPriceMPM,
  formatUnitPriceLID, formatUnitPriceMP, formatUnitPricePD,
  formatUnitPriceAAP, formatUnitPricePS, formatUnitPriceFR
} from './util'

export default {
  name: 'Price',
  props: ['auth'],
  components: {
    ServiceHeader
  },
  data() {
    return {
      applySubscriptionId: null,
      processing: false, // 通信中
      showUpgradeConfirm: false,
      showDowngradeConfirm: false,
      confirmTargetSubsc: null,
    }
  },
  computed: {
    subscriptions() {
      // 言語に合わせた通貨を表示する
      const lang = this.$i18n.locale === 'ja' ? 'ja' : 'en'
      return this.$store.getters['master/changeableSubscriptionsByLang'](lang)
    },
    stayingServicePath() {
      return this.$store.state.user.stayingServicePath || '/'
    },
    currentGroup() {
      return this.$store.getters['user/currentGroup']
    },
    currentSubscription() {
      return this.$store.getters['master/subscriptionById'](this.currentGroup.subscription_id)
    },
    canChangeContract() {
      // エントリープランの場合、いつでもプラン変更可能
      if (this.currentSubscription.grouping_id == 1) {
        return true
      }
      // 現行プランがプラン変更不可であれば常にfalse
      if (this.currentSubscription.plan_changeable == 0) {
        return false
      }
      // プラン変更から32日経たないと(=決済をまたがないと)変更不可
      if (!this.currentGroup.subscription_contract_updated_at) {
        return true
      }
      const canChangeFrom = moment(this.currentGroup.subscription_contract_updated_at).add(32, 'days')
      const now = moment()
      if (now > canChangeFrom) {
        return true
      }
      return false
    },
    ...mapGetters('user', [
      'isCurrentGroupAdmin'
    ])
  },
  methods: {
    formatPriceMonthly(subsc) {
      return formatPriceMonthly(subsc)
    },
    formatUnitPrice(subsc) {
      return formatUnitPrice(subsc)
    },
    formatUnitPriceSc(subsc) {
      return formatUnitPriceSc(subsc)
    },
    formatUnitPriceEp(subsc) {
      return formatUnitPriceEp(subsc)
    },
    formatUnitPriceMPM(subsc) {
      return formatUnitPriceMPM(subsc)
    },
    formatUnitPriceLID(subsc) {
      return formatUnitPriceLID(subsc)
    },
    formatUnitPriceMP(subsc) {
      return formatUnitPriceMP(subsc)
    },
    formatUnitPricePD(subsc) {
      return formatUnitPricePD(subsc)
    },
    formatUnitPriceAAP(subsc) {
      return formatUnitPriceAAP(subsc)
    },
    formatUnitPricePS(subsc) {
      return formatUnitPricePS(subsc)
    },
    formatUnitPriceFR(subsc) {
      return formatUnitPriceFR(subsc)
    },
    unixToStr(unixtime) {
      return unixToStr(unixtime, this.$i18n.locale, this.$store.state.user.tz)
    },
    cancelPlan() {
      if (this.currentGroup === undefined) {
        return
      }
      this.processing = true
      let requestParam = {
        group_id: this.currentGroup.id,
        locale: this.$i18n.locale
      }
      axios.post(`${API_BASE_URL}/payment/v3/subscription/cancel`, requestParam)
        .then((rs) => {
          this.$store.commit('user/setUser', rs.data)
          this.processing = false
          this.showDowngradeConfirm = false
          /*global gtag*/
          gtag('event', 'change_subscription_complete', {
            'event_category': 'subscription',
            'event_label': 'downgrade',
            'value': 1,
          })
        })
        .catch(() => {
          this.processing = false
          this.showDowngradeConfirm = false
        })
    },
    confirmUpgrade(subsc) {
      this.showUpgradeConfirm = true
      this.confirmTargetSubsc = subsc
      gtag('event', 'show_change_subscription_modal', {
        'event_category': 'subscription',
        'event_label': 'upgrade',
        'value': subsc.id,
      })
    },
    upgradePlan(id) {
      if (this.currentGroup === undefined) {
        return
      }
      this.processing = true
      const requestParam = {
        group_id: this.currentGroup.id,
        subscription_id: id,
        locale: this.$i18n.locale
      }
      axios.post(`${API_BASE_URL}/payment/v3/subscription/upgrade`, requestParam)
        .then((rs) => {
          this.$store.commit('user/setUser', rs.data)
          this.processing = false
          this.showUpgradeConfirm = false
          gtag('event', 'change_subscription_complete', {
            'event_category': 'subscription',
            'event_label': 'upgrade',
            'value': id,
          })
        })
        .catch(() => {
          this.processing = false
          this.showUpgradeConfirm = false
        })
    },
    confirmDowngrade(subsc) {
      this.showDowngradeConfirm = true
      this.confirmTargetSubsc = subsc
      gtag('event', 'show_change_subscription_modal', {
        'event_category': 'subscription',
        'event_label': 'downgrade',
        'value': subsc.id,
      })
    },
    downgradePlan(id) {
      if (this.currentGroup === undefined) {
        return
      }
      if (this.confirmTargetSubsc.price_monthly == 0) {
        this.cancelPlan()
        return
      }

      this.processing = true
      const requestParam = {
        group_id: this.currentGroup.id,
        subscription_id: id,
        locale: this.$i18n.locale
      }
      axios.post(`${API_BASE_URL}/payment/v3/subscription/downgrade`, requestParam)
        .then((rs) => {
          this.$store.commit('user/setUser', rs.data)
          this.processing = false
          this.showDowngradeConfirm = false
          gtag('event', 'change_subscription_complete', {
            'event_category': 'subscription',
            'event_label': 'downgrade',
            'value': id,
          })
        })
        .catch(() => {
          this.processing = false
          this.showDowngradeConfirm = false
        })
    },
    applyPlan(id) {
      if (this.currentGroup === undefined) {
        return
      }
      let subsc = this.subscriptions.find(s => s.id === id )
      if (subsc) {
        this.applySubscriptionId = id
        this.paymentCheckout(subsc)
      }
    },
    // サブスク加入課金
    async paymentCheckout(subsc) {
      if (subsc.price_monthly < 1) return

      let reqParam = {
        group_id: this.currentGroup.id,
        subscription_id: subsc.id,
        lang: subsc.language,
        locale: this.$i18n.locale
      }
      const res = await axios.post(`${API_BASE_URL}/payment/v3/subscription/prepare`, reqParam)
      gtag('event', 'show_payment_screen', {
        'event_category': 'payment',
        'event_label': 'subscription',
        'value': subsc.id,
      })
      /*global Stripe*/
      const stripe = Stripe(STRIPE_KEY)
      const {error} = await stripe.redirectToCheckout({
        sessionId: res.data.session_id
      })
      if (error) {
        this.$store.commit('screen/showError', error)
        console.log('[ERROR]', error)
      }
    },
    addComma(num) {
      return num.toLocaleString()
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)

    // 決済完了して遷移してきた場合にgtagのevent発火 (request paramがいじられることもあるので参考値)
    if (this.$route.query.done) {
      this.$store.state.user.logined$.subscribe({
        next: (logined) => {
          if (logined) {
            gtag('event', 'payment_complete', {
              'event_category': 'payment',
              'event_label': 'subscription',
              'value': this.currentSubscription.id
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.item-container {
  margin: 64px auto;
  max-width: 1056px;
  .caption {
    text-align: left;
    margin-left: 16px;
    margin-bottom: 16px;
    h1 {
      font-size: 32px;
      font-weight: 400;
    }
    p {
      font-size: 16px;
      margin-top: 17px;
    }
  }
  .plan-boad {
    width: 304px;
    display: inline-block;
    position: relative;
    background-clip: padding-box;
    z-index: 5;
    margin: 48px 24px 16px 24px;
    padding: 8px;
    border-radius: 4px;
    vertical-align: top;
    &:after {
      content: '';
      position: absolute;
      top: 8px; bottom: 8px;
      left: 0px; right: 0px;
      background: white;
      z-index: -9;
    }
    h4 {
      margin-top: 12px;
      margin-bottom: 16px;
      font-weight: 400;
      font-size: 24px;
    }
    h5 {
      font-size: 16px;
      margin-bottom: 24px;
    }
    p {
      font-size: 38px;
      &.addition {
        font-size: 14px;
        margin-bottom: 2px;
      }
      .by-month {
        font-size: 12px;
      }
    }
    ul {
      text-align: left;
      list-style: none;
      margin-top: 24px;
      font-size: 12px;
      line-height: 28px;
      color: #424242;
      .check-icon {
        vertical-align: top;
        margin-right: 12px;
        margin-top: 6px;
        font-size: 12px;
      }
      li {
        margin-bottom: 10px;

        &.hidden {
          visibility: hidden;
        }
      }
    }
    .bottom-btns {
      margin: auto;
    }
  }

  @mixin plan-colors($light, $dark, $middle){
    color: $dark;
    background: linear-gradient(to right, $light, $dark);
    .check-icon {
      color: $middle;
    }
    .plan-btn {
      color: $dark;
      font-size: 16px;
      &.disabled {
        color: darkgray !important;
      }
    }
    .plan-now {
      color: white !important;
      background: linear-gradient(120deg, $light, $dark);
      font-size: 18px;
      font-weight: 500;
      border-radius: 6px;
    }
  }
  .plan-boad:nth-child(2) {
    @include plan-colors(#00E676, #43A047, #4CAF50);
  }
  .plan-boad:nth-child(3) {
    @include plan-colors(#37CAE7, #234EB5, #2196F3);
  }
  .plan-boad:nth-child(4) {
    @include plan-colors(#D500F9, #C2185B, #E91E63);
  }
  .plan-boad:nth-child(5) {
    @include plan-colors(#7C4DFF, #9C27B0, #673AB7);
  }
  .plan-boad:nth-child(6) {
    @include plan-colors(#FFD740, #FF5722, #FFA000);
  }
  .plan-boad:nth-child(7) {
    @include plan-colors(#ff1744, #880E4F, #C51162);
  }

  .see-more {
    font-size: 14px;
    color: #0D47A1;
    margin-right: 24px;
    cursor: pointer;
  }

  .faq-container {
    margin-left: 16px;
    margin-top: 96px;
    text-align: left;
    max-width: 1040px;
    h3 {
      font-size: 32px;
      font-weight: 400;
    }
    .qa-container {
      // max-width: 720px;
      margin-top: 64px;
      h4 {
        margin-bottom: 0px;
        span {
          margin-right: 4px;
          font-size: 28px;
          font-weight: 500;
          font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
        font-size: 20px;
        font-weight: 500;
        color: #234EB5;
      }
      p {
        font-size: 16px;
        span {
          color: #E91E63;
          margin-right: 4px;
          font-size: 28px;
          font-weight: 500;
          font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }
    }
  }
}
.caption-container {
  border-radius: 4px;
}
</style>

<i18n src="@/locales/price.json"></i18n>
