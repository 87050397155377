<template>
  <div class="container" :class="{large: large}">
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec1") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec1v") }}</p>
    </div>
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec2") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec2v") }}</p>
    </div>
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec3") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec3v") }}</p>
    </div>
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec4") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec4v") }}</p>
    </div>
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec5") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec5v") }}</p>
    </div>
    <div class="item">
      <p class="spec-title">{{ $t("glb_mpm_spec6") }}</p>
      <p class="spec-value">{{ $t("glb_mpm_spec6v") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // true: Top.vueでの利用を想定した大きめ表示, false: ConfirmModal用
    large: Boolean
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.container {
  max-width: 746px;
  background-color: #22B56A;
  padding: 37.7px 42px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include mq-down {
    width: 100%;
    padding: 45px 21px;
  }

  .item {
    &:not(:last-child) {
      margin-bottom: 22px;
      @include mq-down {
        margin-bottom: 46px;
      }
    }
    //text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start; //space-between;
    align-items: baseline;
    @include mq-down {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .spec-title {
      display: inline-block;
      text-align: left;
      color: rgba(255, 255, 255, 0.8);
      font-size: 17px;
      line-height: 28px;
      min-width: 190px;
      margin: 0;
      @include mq-down {
        display: block;
        min-width: unset;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .spec-value {
      display: inline-block;
      text-align: left;
      color: white;
      font-size: 17px;
      font-weight: 500;
      //width: 491px;
      margin: 0;// 0 0 38px;
      @include mq-down {
        display: block;
        text-align: center;
        margin-top: 16px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        width: 100%;
      }
    }
  }

  // 大きめ表示
  &.large {
    max-width: 1040px;
    width: auto;
    padding: 59px 55px;
    @include mq-down {
      width: 100%;
      padding: 65px 20px;
    }
    .item {
      &:not(:last-child) {
        margin-bottom: 38px;
        @include mq-down {
          margin-bottom: 46px;
        }
      }
      .spec-title {
        font-size: 24px;
        min-width: 240px;
        @include mq-down {
          display: block;
          min-width: unset;
          text-align: center;
          font-size: 18px;
          line-height: 24px;
          font-weight: 400;
        }
      }
      .spec-value {
        font-size: 24px;
        font-weight: 400;
        @include mq-down {
          width: unset;
          display: block;
          text-align: center;
          margin-top: 16px;
          font-size: 18px;
          line-height: 28px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>