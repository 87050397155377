<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t('glb_scp_17') }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t('glb_scp_18') }}</h3>
      <h4 class="form-subheading">
        {{ $t('glb_scp_19') }}
      </h4>

      <div class="select-container">
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_20') }}</p>
          <div class="item-balloon-container">
            <div class="selection-item" for="select1" @click="select(true)">
              <v-img class="cloth-image-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/expression_select_1.webp"></v-img>
            </div>
            <p class="mini-balloon">{{ $t('glb_scp_22') }}</p>
          </div>
        </div>
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_21') }}</p>
          <div class="item-balloon-container">
            <div class="selection-item" for="select2" @click="select(false)">
              <v-img class="cloth-image-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/expression_select_2.webp"></v-img>
            </div>
            <p class="mini-balloon">{{ $t('glb_scp_23') }}</p>
          </div>
        </div>
      </div>
      <div class="back-button-container">
        <v-btn class="back-button" flat @click="back()">
          <div class="back-container text-none">
            <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
            <p>back</p>
          </div>
        </v-btn>
      </div>
      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      willChangeExpression: this.$store.state.saichanPlus.changeExpression,
      text: this.$store.state.saichanPlus.expressionText,
      textFieldRules: {
        counter: value => value.length <= 80 || this.$t('glb_scp_9', [80])
      },
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    select(val) {
      this.text = ""
      this.willChangeExpression = val
      this.$emit('expressionOrderEdited', {willChange: this.willChangeExpression, text: this.text})
    },
    close() {
      this.$emit('close');
    },
    next() {
      if (!this.willChangeExpression) {
        this.text = ""
      }
      this.$emit('expressionOrderEdited', {willChange: this.willChangeExpression, text: this.text})
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_sc-plus-form.scss";

.form-heading {
  margin-top: 20px;
  margin-bottom: 0px;
}

.form-subheading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 32px;
}

.select-container {
  max-width: 440px;

  .selection-item-container {
    display: flex;
    flex-direction: column;
    width: 164px;
    align-items: stretch;
    @include mq-down {
      width: 100%;
      justify-content: center;
      margin-bottom: 24px;
    }

    .selection-label {
      font-size: 18px;
      margin-bottom: 10px;
      @include mq-down {
        margin-bottom: 4px;
      }
    }

    .item-balloon-container {
      display: flex;
      flex-direction: column;

      @include mq-down {
        flex-direction: row;
        justify-content: center;
      }
    }

    .selection-item {
      border-radius: 8px;
      background-color: white;
      @include mq-down {
        min-width: 128px;
      }

      .cloth-image-1 {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }

      .cloth-image-2 {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
      }
    }
  }
}

.mini-balloon {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  background-color: white;
  border-radius: 4px;
  position: relative;
  margin-top: 24px;
  padding: 4px 4px;
  z-index: 2;
  
  &::after {
    content: '';
    position: absolute;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transform: translate(-50%, 0);
    height: 34px;
    width: 28px;
    top: -20px;
    left: 50%;
    z-index: -1;
    background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/white_tail.svg);
  }
  @include mq-down(md) {
    font-size: 15px;
    margin: 0;
    padding: 12px 8px;
    align-self: center;
    width: 100%;
    margin-left: 20px;
    &::after {
      top: 50%;
      left: -6px;
      transform: translate(-12px, -50%) rotate(-90deg);
      width: 32px;
      height: 24px;
    }
  }
}

.slick-dot-4 {
  background-color: #8A8A8A;
}

</style>