import axios from 'axios'

export default {
  methods: {
    async downloadZipPc(zipUrl, downloadName) {
      try {
        const axios2 = axios.create()
        axios2.interceptors.request.use(config => {
          config.headers.common = { Accept: "application/json, text/plain, */*" };
          return config
        })
        const result = await axios2.get(zipUrl, { responseType: "arraybuffer" })
        const blob = new Blob([result.data], { type: "application/zip" })
        const objURL = URL.createObjectURL(blob)
        const dlLink = document.createElement("a")
        dlLink.href = objURL
        dlLink.download = downloadName
        dlLink.click()
        // objURLはhrefにsetした段階でcloneされるためすぐrevokeしてokらしい
        // https://github.com/whatwg/html/issues/954
        window.URL.revokeObjectURL(objURL)
      } catch(err) {
        // eslint-disable-next-line no-console
        console.log("err", err)
      }
    },
    async downloadZip(zipUrl, downloadName, logValue, isMobile) {
      /*global gtag*/
      gtag("event", "dl", {
        event_category: "data",
        event_label: "zip",
        value: logValue,
      })
      if (isMobile) {
        // 別タブで開く
        const link = document.createElement("a")
        link.href = zipUrl
        link.target = "_blank"
        link.click()
        return
      }

      this.downloadZipPc(zipUrl, downloadName)
    },
    // PCでの画像ダウンロード処理
    // モバイルではサービスによって処理がマチマチなので共通化しない
    async downloadImagePc(imageURL, downloadName) {
      try {
        const axios2 = axios.create()
        axios2.interceptors.request.use(config => {
          config.headers.common = { Accept: "application/json, text/plain, */*" }
          return config
        })
        const imageResult = await axios2.get(imageURL, { responseType: "arraybuffer" })
        const blob = new Blob([imageResult.data])
        const objURL = URL.createObjectURL(blob)
        const dlLink = document.createElement("a")
        dlLink.href = objURL
        dlLink.download = downloadName
        dlLink.click()
        window.URL.revokeObjectURL(objURL)
      } catch(err) {
        // eslint-disable-next-line no-console
        console.log("err", err)
      }
    },
  }
}
