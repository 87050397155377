<template>
  <div class="login">
    <!--
    <p>TODO: loading indicator</p>
    -->
  </div>
</template>

<script>
export default {
  name: 'Login',
  props: ['auth'],
  mounted() {
    if (this.$route.params.landingPath) {
      this.auth.login('login', this.$route.params.landingPath)
    } else {
      this.auth.login('login')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>