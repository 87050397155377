import axios from 'axios'

// cre8tiveAI Galleryからパースした情報でお知らせの表示に使う
const initialState = {
  galleryInfos: []
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  mutations: {
    setGalleryData(state, data) {
      state.galleryInfos = data.gallery_infos
    },
  },
  actions: {
    getGalleryData({commit}, locale) {
      return new Promise(() => {
        axios.get(`/gallery/`, {
          params: {
            locale: locale,
          }
        }).then(res => {
          commit('setGalleryData', res.data)
        })
        // requests.jsの axios.interceptors.response でエラーキャッチ + dialog表示を行っている
        // .catch(err => reject(err.response))
      })
    }
  },
}
