import { render, staticRenderFns } from "./Top.vue?vue&type=template&id=54e4086c&scoped=true&"
import script from "./Top.vue?vue&type=script&lang=js&"
export * from "./Top.vue?vue&type=script&lang=js&"
import style0 from "./Top.vue?vue&type=style&index=0&id=54e4086c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54e4086c",
  null
  
)

/* custom blocks */
import block0 from "@/locales/sai_chan/plus/top.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcreative_ai_front%2Fapp%2Fsrc%2Fviews%2FSaiChan%2FPlus%2FTop.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports