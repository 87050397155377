import axios from 'axios'

import { API_BASE_URL, SUBSCRIPTION_ID } from '../settings'

/*const TARGET_IMAGE_ERROR_TYPE = {
  'TOO_LARGE_FOR_UNDER_PRO': 1,
  'TOO_LARGE_FOR_ALL': 2,
}*/

function initialState() {
  return {
    targetImageFile: null,
    targetImageSrc: null,
    isHorizontallyLong: null, // 横長画像か？
    drawingFinished: false,
    //TARGET_IMAGE_ERROR_TYPE: TARGET_IMAGE_ERROR_TYPE,
    //targetImageErrorType: null,
    targetImageWarnModalI18NId: null, // 画像チェックで警告なら翻訳IDが入る
    targetImageErrorModalI18NId: null, // 画像チェックでエラーなら翻訳IDが入る
    dlCountPerDay: 0, // 対象ユーザーのその日のダウンロード数

    // 結果
    requestId: null,
    notifyWhenComplete: false, // リリース時は使わない
    estimatedMinutes: null,    //  〃
    execCompleted: false, // 変換が終わったらtrue
    isReleased: false,
    paidPlanSubscribed: null,
    creditAmount: null,
    unitCredit: null,
    unitPrice: null,
    language: null,
    currency: null,
    fileNamePrefix: "", // 透かし解除ファイルは {fileNamePrefix}_{ID(1-N)}.ext(拡張子) という名前でDLさせる
    ext: "",
    thumbnailImages: [], // N枚の透かし付きサムネイル
    wmImages: [], // N枚の透かし付き結果画像
    subInfo: {}, // 結果ページの表示に使用する情報
    // DL
    imageUrl: null,
  }
}


export default {
  namespaced: true,
  state: initialState,
  getters: {
    targetImageFile(state) {
      return state.targetImageFile
    },
    targetImageSrc(state) {
      return state.targetImageSrc
    },
    isHorizontallyLong(state) {
      return state.isHorizontallyLong
    },
    targetImageWarnModalI18NId(state) {
      return state.targetImageWarnModalI18NId
    },
    targetImageErrorModalI18NId(state) {
      return state.targetImageErrorModalI18NId
    },
    execCompleted(state) {
      return state.execCompleted
    },
    requestId(state) {
      return state.requestId
    },
    thumbnailImages(state) {
      return state.thumbnailImages
    },
    // wm = watermark(透かし)
    wmImages(state) {
      return state.wmImages
    },
    subInfo(state) {
      return state.subInfo
    },
    imageUrl(state) {
      return state.imageUrl
    },
    creditAmount(state) {
      return state.creditAmount
    }
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        if (key === 'targetImageSrc' && state[key]) {
          URL.revokeObjectURL(state[key])
        }
        state[key] = s[key]
      })
    },
    async setTargetImageFile(state, payload) {
      // 複数枚のfileがdropされたとしてもtopの1枚だけ扱う
      state.targetImageFile = payload.files[0]

      // PNG Smallify特有のバリデーション
      const acceptableMimetypes = ['image/png']
      const groupingId = payload.subsc_grouping_id
      const targetImageFileSize = state.targetImageFile.size
      const dlCountPerDay = state.dlCountPerDay
      // 拡張子チェック
      if (!(acceptableMimetypes.includes(state.targetImageFile.type.toLowerCase()))) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_ext'
        return
      }
      // 容量チェック
      // エントリープラン（1ファイル5MBまで）
      if (groupingId === SUBSCRIPTION_ID['ENTRY'] && targetImageFileSize > 5000000) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_size_1'
        return
      }
      // 有料プラン（1ファイル32MBまで）
      if (groupingId >= SUBSCRIPTION_ID['LIGHT'] && targetImageFileSize > 32000000) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_size_2'
        return
      }
      // 枚数チェック
      // エントリープラン（1日10枚まで）
      if (groupingId === SUBSCRIPTION_ID['ENTRY'] && dlCountPerDay >= 10) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_count'
        return
      }
      // ライトプラン・スタンダードプラン（1日100枚まで）
      if (groupingId <= SUBSCRIPTION_ID['STANDARD'] && dlCountPerDay >= 100) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_count'
        return
      }
      // プロプラン以上（1日1000枚まで）
      if (groupingId >= SUBSCRIPTION_ID['PRO'] && dlCountPerDay >= 1000) {
        state.targetImageErrorModalI18NId = 'glb_ps_err_file_count'
        return
      }

      // FileReaderよりURL.createObjectURLの方が消費メモリ的に有利そうなのでそちらを使う
      // https://stackoverflow.com/questions/31742072/filereader-vs-window-url-createobjecturl
      if (state.targetImageSrc) {
        URL.revokeObjectURL(state.targetImageSrc)
      }

      let imgURL = URL.createObjectURL(state.targetImageFile)
      // 画像サイズチェック
      // 10000*10000以上は不可
      const img = new Image()
      img.onload = () => {
        const squareSize = img.naturalWidth * img.naturalHeight
        if (squareSize > 10000 * 10000) {
          // グローバルな翻訳ID (サーバサイドでもチェックして同じエラーを返す場合に共通化できるので)
          state.targetImageErrorModalI18NId = 'glb_ps_err_file'
          URL.revokeObjectURL(imgURL)
          return
        }
        // オーダー可能なので画像urlをセット
        state.targetImageSrc = imgURL
        state.isHorizontallyLong = img.naturalWidth > img.naturalHeight
      }
      img.src = imgURL
    },
    warningConfirmed(state) {
      state.targetImageWarnModalI18NId = null
    },
    cleartargetImageFile(state) {
      state.targetImageFile = null
      if (state.targetImageSrc) {
        URL.revokeObjectURL(state.targetImageSrc)
      }
      state.targetImageSrc = null
    },
    setExecResponse(state, data) {
      state.requestId = data.request_id
      state.notifyWhenComplete = data.notify_when_complete
      state.estimatedMinutes = data.estimated_minutes
    },
    setExecCompleted(state) {
      state.execCompleted = true
    },
    setDLStatus(state, apiResult) {
      state.requestId = apiResult.request_id
      state.isReleased = apiResult.is_released
      state.paidPlanSubscribed = apiResult.paid_plan_subscribed
      state.creditAmount = apiResult.credit_amount
      state.unitCredit = apiResult.unit_credit
      state.unitPrice = apiResult.unit_price
      state.language = apiResult.language
      state.currency = apiResult.currency
      state.fileNamePrefix = apiResult.request_file_name
      state.ext = apiResult.ext
      state.thumbnailImages = apiResult.thumbnail_image_list
      state.wmImages = apiResult.wm_image_list
      state.subInfo = apiResult.sub_info
    },
    setDLCountPerDay(state, data) {
      state.dlCountPerDay = data.dl_count_per_day
    }
  },
  actions: {
    async draw({ commit, state, dispatch, rootGetters }, locale) {
      const group = rootGetters['user/currentGroup']
      let data = new FormData();
      // 生ファイルの方をuploadすることに注意
      data.append('file', state.targetImageFile)
      data.append('group_id', group.id)
      data.append('locale', locale)

      const url = (rootGetters['user/logined']) ? '/ps/exec' : '/ps/exec/guest'
      const result = await axios({
        method: 'post',
        url: url,
        data: data,
      })
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      commit('setExecResponse', result.data)

      // ポーリングで進捗をcheck
      let progressFetchInterval = setInterval(() => {
        axios.get(`/ps/progress/${state.requestId}`).then(rsProgress => {
          if (rsProgress.data.process_status == 3) {
            clearInterval(progressFetchInterval)
            commit('setExecCompleted') // => Top.vue側でResultにページ遷移
          }
        })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('# progress fetch err: ', err)
          })
      }, 3000)
    },
    async getDLStatus({ commit, dispatch, rootGetters }, requestId) {
      const logined = rootGetters['user/logined']
      const relativePath = (logined) ? '/ps/dl_status/' : '/ps/dl_status/guest/'
      const params = (!logined) ? {group_id: rootGetters['user/currentGroup'].id} : {}
      const response = await axios.get(`${API_BASE_URL}${relativePath}${requestId}`, {params: params})
      if (response.status !== 200) {
        dispatch('showErrorOnScreen', {response: response}, {root: true})
        return
      }
      commit('setDLStatus', response.data)
    },
    async getDLURL({ state, dispatch }) {
      if (!state.isReleased) return
      const result = await axios.get(`${API_BASE_URL}/ps/dl_url/${state.requestId}`)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      state.imageUrl = result.data.result_file_url
    },
    async released({ state, dispatch, rootGetters }) {
      const group = rootGetters['user/currentGroup']
      let requestParams = {
        "group_id": group.id,
        "request_id": state.requestId,
      }
      const result = await axios.post(`${API_BASE_URL}/ps/released`, requestParams)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
      } else {
        /*global gtag*/
        gtag('event', 'download_complete', {
          'event_category': 'download',
          'event_label': 'ps',
          'value': group.subscription_id
        })
      }
      // view側でexecDownloadが呼ばれる
    },
    getDLCountPerDay({ commit, rootGetters }, payload) {
      if (!rootGetters['user/logined']) {
        commit('setTargetImageFile', payload)
        return
      }
      const group = rootGetters['user/currentGroup']
      return new Promise(() => {
        axios.get(`${API_BASE_URL}/ps/dl_count/${group.id}`).then(res => {
          commit('setDLCountPerDay', res.data)
          commit('setTargetImageFile', payload)
        })
        // requests.jsの axios.interceptors.response でエラーキャッチ + dialog表示を行っている
        // .catch(err => reject(err.response))
      })
    },
  },
}
