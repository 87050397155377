<template>
  <div id="aar_top">
    <service-header :auth="auth"/>
    <!-- Heroエリア -->
    <div class="hero-container">
      <template v-if="$vuetify.breakpoint.xsOnly">
        <img class="bg-ray-softlight" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/hero_ray_softlight_sp.webp">
        <img class="bg-ray-overlay" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/hero_ray_overlay_sp.webp">
      </template>
      <template v-else>
        <img class="bg-ray-softlight" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/hero_ray_softlight.webp">
        <img class="bg-ray-overlay" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/hero_ray_overlay.webp">
      </template>
      <v-container hero-content grid-list-xl text-xs-left>
        <v-layout align-center justify-center>
          <h1 class="h-top"><span>{{ $t('aar_1') }}</span></h1>
        </v-layout>
        <v-layout row wrap align-center justify-center>
          <v-flex vf-hide-sm-down xs12 md6 lg5 offset-lg1>
            <aar-plugin-name :pluginName='$t("glb_aar_1")' :powerdBy='$t("glb_aar_2")'></aar-plugin-name>
            <v-flex class="my-3"></v-flex>
            <aar-button :btnText="$t('aar_2')" toLink='#g-form'></aar-button>
          </v-flex>
          <v-flex xs12 md5 lg6>
            <v-layout row wrap align-center>
              <v-flex package-img xs5 offset-xs1 lg5 offset-lg1 pb-0>
                <h3 class="package-photo mb-2">{{ $t("glb_aar_4") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/package-photo.webp">
              </v-flex>
              <v-flex package-img xs5 lg5 pb-0>
                <h3 class="package-anime mb-2">{{ $t("glb_aar_5") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/package-anime.webp">
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <v-flex xs12 lg10 offset-lg1 pt-0>
                <v-layout justify-center>
                  <img class="collaborate-logo pl-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/logo@3x.webp">
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex vf-display-sm-down xs12>
            <v-layout mb-3 align-center justify-center column>
              <aar-plugin-name class="mb-3" :pluginName='$t("glb_aar_1")' :powerdBy='$t("glb_aar_2")'></aar-plugin-name>
            <aar-button :btnText="$t('aar_2')" toLink='#g-form'></aar-button>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout what-container align-center justify-center column>
          <p class="section-title">{{ $t('aar_3') }}</p>
          <p class="section-description" v-html="$t('aar_4')"></p>
        </v-layout>
      </v-container>
      <!-- 広告 -->
      <ad-responsive></ad-responsive>
    </div>
    <!-- 詳細説明 -->
    <div class="detail">
      <!-- 高解像度例  -->
      <div class="every-can-refine">
        <h2>{{ $t('aar_5') }}</h2>
        <div class="package-type">
          <h2 class="package-photo">{{ $t("glb_aar_4") }}</h2>
        </div>
        <div class="refine-divider">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/photo_divider.svg">
        </div>
        <div class="comparison-container">
          <v-container comparison-title fluid grid-list-md>
            <v-layout row>
              <v-flex xs6 sm4 offset-sm2 md6 offset-md0>
                <v-layout justify-center>
                  <div class="text-before">{{ $t('aar_8') }}</div>
                </v-layout>
              </v-flex>
              <v-flex xs6 sm4 md6>
                <v-layout justify-center>
                  <div class="text-after">{{ $t('aar_9') }}</div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          <v-layout comparison-photo align-center justify-center>
            <div class="refine-arrow">
              <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/refine_arrow.svg">
            </div>
          </v-layout>
        </div>
        <v-layout align-center justify-center>
          <div class="package-description">
            <p v-html="$t('aar_6')"></p>
          </div>
        </v-layout>
        <div class="package-type">
          <h2 class="package-anime">{{ $t("glb_aar_5") }}</h2>
        </div>
        <div class="refine-divider">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/anime_divider.svg">
        </div>
        <div class="comparison-container">
          <v-container comparison-title fluid grid-list-md>
            <v-layout row>
              <v-flex xs6 sm4 offset-sm2 md6 offset-md0>
                <v-layout justify-center>
                  <div class="text-before">{{ $t('aar_8') }}</div>
                </v-layout>
              </v-flex>
              <v-flex xs6 sm4 md6>
                <v-layout justify-center>
                  <div class="text-after">{{ $t('aar_9') }}</div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          <v-layout comparison-anime align-center justify-center>
            <div class="refine-arrow">
              <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/aar/refine_arrow.svg">
            </div>
          </v-layout>
        </div>
        <v-layout align-center justify-center>
          <div class="package-description">
            <p v-html="$t('aar_7')"></p>
          </div>
        </v-layout>
      </div>
      <!-- スペック表  -->
      <div class="spec-container">
        <h2>{{ $t('aar_10') }}</h2>
        <h3>{{ $t('aar_11') }}</h3>
        <h4 class="package-spec-container">{{ $t('aar_12') }}</h4>
        <div class="package-spec mt-3">
          <v-container description fluid grid-list-md>
            <v-layout row wrap>
              <v-flex name xs3>{{ $t('aar_13') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_14') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_15') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_16') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_17') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_18') }}</v-flex>
            </v-layout>
          </v-container>
        </div>
        <h4 class="env-spec-container">{{ $t('aar_19') }}</h4>
        <div class="env-spec mt-3">
          <v-container description fluid grid-list-md>
            <h5>{{ $t('aar_20') }}</h5>
            <v-layout row wrap>
              <v-flex name xs3>{{ $t('aar_21') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_22') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_23') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_24') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_25') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_26') }}</v-flex>
            </v-layout>
            <h5>{{ $t('aar_27') }}</h5>
            <v-layout row wrap>
              <v-flex name xs3>{{ $t('aar_21') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_22') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_23') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_24') }}</v-flex>
              <v-flex name xs3>{{ $t('aar_25') }}</v-flex>
              <v-flex content xs9>{{ $t('aar_28') }}</v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>
      <!-- 価格表  -->
      <div id="price" class="price-container">
        <h2>{{ $t('aar_29') }}</h2>
        <h3>{{ $t('aar_30') }}</h3>
        <v-container price-card-container fluid grid-list-lg>
          <v-layout row wrap align-center justify-center>
            <v-flex xs12 sm6 md4>
              <div class="package-card package-photo">
                <h4>{{ $t('glb_aar_1') }}</h4>
                <h5 class="mb-5">{{ $t("glb_aar_4") }}</h5>
                <p>{{ $t('aar_31') }}</p>
                <p>{{ $t('aar_32') }}</p>
              </div>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <div class="package-card package-anime">
                <h4>{{ $t('glb_aar_1') }}</h4>
                <h5 class="mb-5">{{ $t("glb_aar_5") }}</h5>
                <p>{{ $t('aar_31') }}</p>
                <p>{{ $t('aar_32') }}</p>
              </div>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <div class="package-card package-double">
                <h4>{{ $t('glb_aar_1') }}</h4>
                <h5 class="mb-5">{{ $t('aar_33') }}</h5>
                <p>{{ $t('aar_34') }}</p>
                <p>{{ $t('aar_32') }}</p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="trademark">
          <p v-html="$t('aar_35')"></p>
          <p v-html="$t('aar_36')"></p>
          <p v-html="$t('aar_37')"></p>
        </div>
      </div>
      <!-- 広告 -->
      <ad-responsive></ad-responsive>
      <!-- お問い合わせ -->
      <div id="g-form" class="contact-container">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdyxSiDRdLpmslRIvwn3wATPqEzPcrV3hXvn88ZPjHiL0GjEQ/viewform?embedded=true" frameborder="0" marginheight="0" marginwidth="0">{{ $t('aar_38') }}</iframe>
      </div>
    </div>
  </div>

</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'
import AarButton from '@/components/aar/Button.vue'
import AarPluginName from '@/components/aar/PluginName.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
export default {
  name: 'AiliaAiRefinerTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    AarButton,
    AarPluginName,
    AdResponsive
  },
  watch: {
    '$route': function (n) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/common.scss";
@import "../../assets/scss/_aar.scss";
</style>

<i18n src="@/locales/ailia_ai_refiner/top.json"></i18n>
