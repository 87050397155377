<template>
  <div id="mpm-samples">
    <service-header :auth="auth"/>
    <v-img class="brand-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/logo_circle.svg"></v-img>
    <p class="page-title">
      {{ $t("glb_sample_videos") }}
    </p>
    <div class="sample-list-container">
      <div v-for="n in samplesNum" :key="n" class="sample-container">
        <p class="sample-title">{{ $t(sampleMovieI18nNameId(n))}}</p>
        <img class="chevron" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/chevron.svg"/>
        <div class="thumbnail-container display-pc">
          <v-img :src="sampleThumbnailURL(n, 1)" @click="playSampleVideoPC(n, 1)" class="thumbnail" max-width="120px" />
          <v-img :src="sampleThumbnailURL(n, 2)" @click="playSampleVideoPC(n, 2)" class="thumbnail" max-width="120px" />
          <v-img :src="sampleThumbnailURL(n, 3)" @click="playSampleVideoPC(n, 3)" class="thumbnail" max-width="120px" />
        </div>
        <!-- Mobileはサムネイルタップでiframeに差し替えて、再タップで強制全画面で動画が再生される -->
        <div class="thumbnail-container display-sp">
          <template v-for="p in [1,2,3]">
            <img v-if="selectedMovieIdSP != n || selectedMovieIndexSP != p" :src="sampleThumbnailURL(n, p)" :key="n * 10 + p" @click="playSampleVideoSP(n, p)"
              class="thumbnail" :style="{width: thumbnailWidthSP + 'px', height: thumbnailHeightSP + 'px'}" />
            <iframe v-if="selectedMovieIdSP == n && selectedMovieIndexSP == p" :key="n * 10 + p + 5" class="movie-player-sp" :src="selectedMovieURL"
              :width="thumbnailWidthSP"
              :height="thumbnailHeightSP"
              style='border: none;'
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
              allowfullscreen
            ></iframe>
          </template>
        </div>
      </div>
    </div>
    <div class="btn-container">
      <v-btn dark round outline active-class color="#45BA4D" class="mr-5" to="/mpm">
        {{ $t("glb_7") }}
      </v-btn>
    </div>

    <!-- 動画プレイヤー
      NOTE. attachを指定しなければ #app直下にdialog要素が挿入されるが、影響範囲を絞ったstyleを指定しやすくするため#mpm-samplesを指定 -->
    <v-dialog v-model="showVideoPlayer" width="640" height="480" content-class="video-player" attach="#mpm-samples">
      <iframe :src="selectedMovieURL"
        width="640"
        height="480"
        style='border: none;'
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
        allowfullscreen
      >
      </iframe>
    </v-dialog>
  </div>
</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'

export default {
  name: 'MovingPhotoMakerSamples',
  props: ['auth'],
  components: {
    ServiceHeader
  },
  data() {
    const thumbnailWidthSP = (window.innerWidth - 2 * 24/* 画面両端のpadding */) / 3 - (22 * 2 / 3) /* 要素同士の間隔*/
    const thumbnailHeightSP = thumbnailWidthSP * 480 / 640
    return {
      samplesNum: this.$store.state.movingPhotoMaker.samplesNum,
      showVideoPlayer: false,
      selectedMovieIdSP: null,
      selectedMovieIndexSP: null,
      selectedMovieURL: '',
      thumbnailWidthSP: thumbnailWidthSP,
      thumbnailHeightSP: thumbnailHeightSP
    }
  },
  computed: {

  },
  methods: {
    sampleMovieI18nNameId(id) {
      return this.$store.getters['movingPhotoMaker/sampleI18nNameId'](id)
    },
    sampleThumbnailURL(id, index) {
      return this.$store.getters['movingPhotoMaker/sampleThumbNailURLForSamplePage'](id, index)
    },
    playSampleVideoPC(id, index) {
      this.showVideoPlayer = true
      this.selectedMovieURL = this.$store.getters['movingPhotoMaker/sampleMovieURLForSamplePage'](id, index)
    },
    playSampleVideoSP(id, index) {
      this.showVideoPlayer = false
      this.selectedMovieIdSP = id
      this.selectedMovieIndexSP = index
      this.selectedMovieURL = this.$store.getters['movingPhotoMaker/sampleMovieURLForSamplePage'](id, index)
    }
  },
  created() {
    this.$store.commit("screen/headerVisible", true);
    this.$store.commit("screen/footerVisible", true);
  }
}

</script>

<style lang="scss">
// こちらはglobal scopeなことに注意!
// vuetifyにより生成/挿入される要素(dialog)は、scopedなcssではstyleを指定できないのでこちらで指定
// (なぜなら .aaa[data-v-3d74eb98] のような乱数の属性が自動指定される(ことでscopeを区切っている)ため)
#mpm-samples .video-player {
  background-color: black;
  // vuetify側のデフォルトshadowでは下端が灰色の線のように見えてしまうため上書き
  box-shadow: none;
}
</style>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import url(https://use.typekit.net/ddm1tzj.css);

.brand-icon {
  width: 80px;
  height: 80px;
  margin: 50px auto 38px;
  @include mq-down(lg) {
    width: 60px;
    height: 60px;
    margin: 74px auto 24px;
  }
}
.page-title {
  margin: 0 auto 112px;
  font-size: 40px;
  font-weight: 400;
  color: #424242;
  @include mq-down(lg) {
    font-size: 25px;
    margin-bottom: 67px;
  }
}

.sample-list-container {
  width: 1024px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  @include mq-down(lg) {
    width: 100%;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px;
  }

  .sample-container {
    margin-bottom: 70px;
    @include mq-down(lg) {
      margin-bottom: 35px;
    }

    .sample-title {
      font-size: 30px;
      font-weight: 500;
      color: #45BA4D;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 25px;
      @include mq-down(lg) {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
    .chevron {
      margin-left: auto;
      margin-right: auto;
      width: 75.4px;
      height: 39px;
      margin-bottom: 28px;
      @include mq-down(lg) {
        width: 45px;
        height: 23.3px;
        margin-bottom: 17px;
      }
    }

    .thumbnail-container {
      width: 416px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @include mq-down(lg) {
        width: 100%;
      }

      .thumbnail {
        box-sizing: border-box;
        width: 120px;
        height: 90px;
        @include mq-down(lg) {
          &:nth-child(1) {
            margin-right: calc(44px / 3);
          }
          &:nth-child(2) {
            margin-left: calc(44px / 6);
            margin-right: calc(44px / 6);
          }
          &:nth-child(3) {
            margin-left: calc(44px / 3);
          }
        }
      }
      .movie-player-sp {
        &:nth-child(1) {
          margin-right: calc(44px / 3);
        }
        &:nth-child(2) {
          margin-left: calc(44px / 6);
          margin-right: calc(44px / 6);
        }
        &:nth-child(3) {
          margin-left: calc(44px / 3);
        }
      }
    }
  }
}
.btn-container {
  margin: 48px auto 64px;
  button {
    margin-left: auto;
    margin-right: auto !important;
  }
  a {
    text-decoration: none;
  }
}
</style>