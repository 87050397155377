import axios from 'axios'
import heic2any from 'heic2any'

import { API_BASE_URL, SUBSCRIPTION_ID } from '../settings'

/*const TARGET_IMAGE_ERROR_TYPE = {
  'TOO_LARGE_FOR_UNDER_PRO': 1,
  'TOO_LARGE_FOR_ALL': 2,
}*/

function initialState() {
  return {
    targetImageFile: null,
    // uploadする生ファイル。 targetImageFileはpng化したheicが入ることがある場合にこちらはheicが入る
    _targetImageFileForUpload: null,
    targetImageSrc: null,
    isHorizontallyLong: null, // 横長画像か？
    drawingFinished: false,
    //TARGET_IMAGE_ERROR_TYPE: TARGET_IMAGE_ERROR_TYPE,
    //targetImageErrorType: null,
    targetImageWarnModalI18NId: null, // 画像チェックで警告なら翻訳IDが入る
    targetImageErrorModalI18NId: null, // 画像チェックでエラーなら翻訳IDが入る

    // 結果
    requestId: null,
    notifyWhenComplete: false, // リリース時は使わない
    estimatedMinutes: null,    //  〃
    execCompleted: false, // 変換が終わったらtrue
    isReleased: false,
    paidPlanSubscribed: null,
    creditAmount: null,
    creditKey: null,
    unitCredit: null,
    unitPrice: null,
    language: null,
    currency: null,
    fileNamePrefix: "", // 透かし解除ファイルは {fileNamePrefix}_{ID(1-N)}.ext(拡張子) という名前でDLさせる
    ext: "",
    thumbnailImages: [], // N枚の透かし付きサムネイル
    wmImages: [], // N枚の透かし付き結果画像
    // DL
    zipUrl: null,
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    targetImageFile(state) {
      return state.targetImageFile
    },
    targetImageSrc(state) {
      return state.targetImageSrc
    },
    isHorizontallyLong(state) {
      return state.isHorizontallyLong
    },
    targetImageWarnModalI18NId(state) {
      return state.targetImageWarnModalI18NId
    },
    targetImageErrorModalI18NId(state) {
      return state.targetImageErrorModalI18NId
    },
    execCompleted(state) {
      return state.execCompleted
    },
    requestId(state) {
      return state.requestId
    },
    thumbnailImages(state) {
      return state.thumbnailImages
    },
    // wm = watermark(透かし)
    wmImages(state) {
      return state.wmImages
    },
    zipUrl(state) {
      return state.zipUrl
    },
    creditAmount(state) {
      return state.creditAmount
    }
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        if (key === 'targetImageSrc' && state[key]) {
          URL.revokeObjectURL(state[key])
        }
        state[key] = s[key]
      })
    },
    async setTargetImageFile(state, payload) {
      // 複数枚のfileがdropされたとしてもtopの1枚だけ扱う
      state.targetImageFile = payload.files[0]
      state._targetImageFileForUpload = payload.files[0]

      // https://ja.wikipedia.org/wiki/High_Efficiency_Image_File_Format
      const heifMimetypes = [
        'image/heic', 'image/heif', 'image/heif-sequence', 'image/heic-sequence'
      ]

      // 2021年2現在はブラウザでの表示に対応していないためheic2anyを挟む
      if (heifMimetypes.includes(state.targetImageFile.type.toLowerCase())) {
        // Blobで返ってくるので、file name等がとれるようにFile型にしてやる必要がある
        const conversionResult = await heic2any({blob: state.targetImageFile})
        state.targetImageFile = new File([conversionResult], state.targetImageFile.name)
      }

      // FileReaderよりURL.createObjectURLの方が消費メモリ的に有利そうなのでそちらを使う
      //   https://stackoverflow.com/questions/31742072/filereader-vs-window-url-createobjecturl
      if (state.targetImageSrc) {
        URL.revokeObjectURL(state.targetImageSrc)
      }
      const imgURL = URL.createObjectURL(state.targetImageFile)
      // 画像サイズチェック
      // 10000*10000以上は不可、プロ未満は2000*2000未満、プロ以上は長辺が5000pxに縮小される旨を表示
      const img = new Image()
      img.onload = () => {
        const squareSize = img.naturalWidth * img.naturalHeight
        if (squareSize > 10000 * 10000) {
          // グローバルな翻訳ID (サーバサイドでもチェックして同じエラーを返す場合に共通化できるので)
          state.targetImageErrorModalI18NId = 'glb_lid_err_file'
          URL.revokeObjectURL(imgURL)
          return
        }

        // この時点でオーダー可能なので画像urlをセット
        state.targetImageSrc = imgURL
        state.isHorizontallyLong = img.naturalWidth > img.naturalHeight
        if (payload.subsc_grouping_id < SUBSCRIPTION_ID['PRO'] && squareSize > 2000 * 2000) {
          state.targetImageWarnModalI18NId = 'lid_warn_2000'
          return
        }
        if (squareSize > 5000 * 5000) {
          state.targetImageWarnModalI18NId = 'lid_warn_5000'
          return
        }
      }
      img.src = imgURL
    },
    warningConfirmed(state) {
      state.targetImageWarnModalI18NId = null
    },
    cleartargetImageFile(state) {
      state.targetImageFile = null
      if (state.targetImageSrc) {
        URL.revokeObjectURL(state.targetImageSrc)
      }
      state.targetImageSrc = null
    },
    setExecResponse(state, data) {
      state.requestId = data.request_id
      state.notifyWhenComplete = data.notify_when_complete
      state.estimatedMinutes = data.estimated_minutes
    },
    setExecCompleted(state) {
      state.execCompleted = true
    },
    setDLStatus(state, apiResult) {
      state.requestId = apiResult.request_id
      state.isReleased = apiResult.is_released
      state.paidPlanSubscribed = apiResult.paid_plan_subscribed
      state.creditAmount = apiResult.credit_amount
      state.creditKey = apiResult.credit_key
      state.unitCredit = apiResult.unit_credit
      state.unitPrice = apiResult.unit_price
      state.language = apiResult.language
      state.currency = apiResult.currency
      state.fileNamePrefix = apiResult.request_file_name
      state.ext = apiResult.ext
      state.thumbnailImages = apiResult.thumbnail_image_list
      state.wmImages = apiResult.wm_image_list
    }
  },
  actions: {
    async draw({ commit, state, dispatch, rootGetters }, locale) {
      const group = rootGetters['user/currentGroup']
      let data = new FormData();
      // 生ファイルの方をuploadすることに注意
      data.append('file', state._targetImageFileForUpload)
      data.append('group_id', group.id)
      data.append('locale', locale)

      const url = (rootGetters['user/logined']) ? '/lid/exec' : '/lid/exec/guest'
      const result = await axios({
        method: 'post',
        url: url,
        data: data,
      })
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      commit('setExecResponse', result.data)

      // ポーリングで進捗をcheck
      let progressFetchInterval = setInterval(() => {
        axios.get(`/lid/progress/${state.requestId}`).then(rsProgress => {
          if (rsProgress.data.process_status == 3) {
            clearInterval(progressFetchInterval)
            commit('setExecCompleted') // => Top.vue側でResultにページ遷移
          }
        })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('# progress fetch err: ', err)
          })
      }, 3000)
    },
    async getDLStatus({ commit, dispatch, rootGetters }, requestId) {
      const logined = rootGetters['user/logined']
      const relativePath = (logined) ? '/lid/dl_status/' : '/lid/dl_status/guest/'
      const params = (!logined) ? {group_id: rootGetters['user/currentGroup'].id} : {}
      const response = await axios.get(`${API_BASE_URL}${relativePath}${requestId}`, {params: params})
      if (response.status !== 200) {
        dispatch('showErrorOnScreen', {response: response}, {root: true})
        return
      }
      commit('setDLStatus', response.data)
    },
    async consumeCredits({ state, dispatch, rootGetters }) {
      const group = rootGetters['user/currentGroup']
      let requestParams = {
        "group_id": group.id,
        "token": state.creditKey,
        "request_id": state.requestId,
      }
      const result = await axios.post(`${API_BASE_URL}/credit/consume/lid`, requestParams)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
      } else {
        /*global gtag*/
        gtag('event', 'credit_consume_complete', {
          'event_category': 'credit',
          'event_label': 'lid',
          'value': group.subscription_id
        })
      }
      // view側でexecDownloadが呼ばれる
    },
    async getDLURL({ state, dispatch }) {
      if (!state.isReleased) return
      const result = await axios.get(`${API_BASE_URL}/lid/dl_url/${state.requestId}`)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      state.zipUrl = result.data.released_zip_url
    },
    /* リリース時点では提供しないかも
    async share({state, dispatch, rootGetters}) {
      const group = rootGetters['user/currentGroup']
      let requestParams = {
        "group_id": group.id,
        "request_id": state.requestId,
      }
      const result = await axios.post(`${API_BASE_URL}/ep/share`, requestParams)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      return true
    }*/
  },
}
