import axios from 'axios'
import { API_BASE_URL } from '../settings'

// 画家情報のマスタデータ
// https://docs.google.com/spreadsheets/d/1PsOg4fpUFXdmJBrS50y6q-KIgsgUzjzPzK1M7WLNXCc/edit#gid=2009564143
// NOTE. リリース時はジャンルの区別はなく全画家を1ジャンルとして扱う
const defaultI18nNameId = (genreId, painterId) => `glb_ep_g${genreId}_p${painterId}_1`
const defaultI18nSubtitleId = (genreId, painterId) => `glb_ep_g${genreId}_p${painterId}_2`
const defaultI18nDescriptionId = (genreId, painterId) => `glb_ep_g${genreId}_p${painterId}_3`
// NOTE. ブラウザの画像キャッシュを使わせたいので、画家選択モーダルのデザインサイズで書き出した画像(=/painter/modal配下) をtop/PainterSelectの両方で使うことにした
const defaultEyeCathingImageURL = (genreId, painterId) => `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_pic_modal.webp`
const defaultBeforeImageURLs = (genreId, painterId) => [
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_1b.webp`,
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_2b.webp`,
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_3b.webp`
  ]
const defaultAfterImageURLs = (genreId, painterId) => [
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_1a.webp`,
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_2a.webp`,
    `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/modal/g${genreId}_p${painterId}_3a.webp`
  ]
const defaultFaceImageURL = (genreId, painterId) => `https://storage.googleapis.com/cre8tiveai_public_files/img/ep/painter/g${genreId}_p${painterId}_face.webp`
// NOTE. 以下を修正する時は、サーバサイドの app_relay/app/api/enpainter.pyも同時に修正する
const paintersMaster = {
  genres: [
    {
      genreId: 1,
      i18nNameId: "", // リリース時は1ジャンルしかないためジャンル名は表示しないことになった
      painters: [
        {
          artistId: 15,
          i18nNameId: defaultI18nNameId(1, 1),
          i18nSubtitleId: defaultI18nSubtitleId(1, 1),
          i18nDescriptionId: defaultI18nDescriptionId(1, 1),
          autonym: "Vincent van Gogh",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 1),
          beforeImageURLs: defaultBeforeImageURLs(1, 1),
          afterImageURLs: defaultAfterImageURLs(1, 1),
          faceImageURL: defaultFaceImageURL(1, 1),
        },
        {
          artistId: 10,
          i18nNameId: defaultI18nNameId(1, 2),
          i18nSubtitleId: defaultI18nSubtitleId(1, 2),
          i18nDescriptionId: defaultI18nDescriptionId(1, 2),
          autonym: "Pablo Picasso",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 2),
          beforeImageURLs: defaultBeforeImageURLs(1, 2),
          afterImageURLs: defaultAfterImageURLs(1, 2),
          faceImageURL: defaultFaceImageURL(1, 2),
        },
        {
          artistId: 3,
          i18nNameId: defaultI18nNameId(1, 3),
          i18nSubtitleId: defaultI18nSubtitleId(1, 3),
          i18nDescriptionId: defaultI18nDescriptionId(1, 3),
          autonym: "Edvard Munch",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 3),
          beforeImageURLs: defaultBeforeImageURLs(1, 3),
          afterImageURLs: defaultAfterImageURLs(1, 3),
          faceImageURL: defaultFaceImageURL(1, 3),
        },
        {
          artistId: 6,
          i18nNameId: defaultI18nNameId(1, 4),
          i18nSubtitleId: defaultI18nSubtitleId(1, 4),
          i18nDescriptionId: defaultI18nDescriptionId(1, 4),
          autonym: "Jackson Pollock",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 4),
          beforeImageURLs: defaultBeforeImageURLs(1, 4),
          afterImageURLs: defaultAfterImageURLs(1, 4),
          faceImageURL: defaultFaceImageURL(1, 4),
        },
        {
          artistId: 8,
          i18nNameId: defaultI18nNameId(1, 5),
          i18nSubtitleId: defaultI18nSubtitleId(1, 5),
          i18nDescriptionId: defaultI18nDescriptionId(1, 5),
          autonym: "Claude Monet",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 5),
          beforeImageURLs: defaultBeforeImageURLs(1, 5),
          afterImageURLs: defaultAfterImageURLs(1, 5),
          faceImageURL: defaultFaceImageURL(1, 5),
        },
        {
          artistId: 9,
          i18nNameId: defaultI18nNameId(1, 6),
          i18nSubtitleId: defaultI18nSubtitleId(1, 6),
          i18nDescriptionId: defaultI18nDescriptionId(1, 6),
          autonym: "Николай Рёрих",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 6),
          beforeImageURLs: defaultBeforeImageURLs(1, 6),
          afterImageURLs: defaultAfterImageURLs(1, 6),
          faceImageURL: defaultFaceImageURL(1, 6),
        },
        {
          artistId: 12,
          i18nNameId: defaultI18nNameId(1, 7),
          i18nSubtitleId: defaultI18nSubtitleId(1, 7),
          i18nDescriptionId: defaultI18nDescriptionId(1, 7),
          autonym: "Paul Gauguin",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 7),
          beforeImageURLs: defaultBeforeImageURLs(1, 7),
          afterImageURLs: defaultAfterImageURLs(1, 7),
          faceImageURL: defaultFaceImageURL(1, 7),
        },
        {
          artistId: 18,
          i18nNameId: defaultI18nNameId(1, 8),
          i18nSubtitleId: defaultI18nSubtitleId(1, 8),
          i18nDescriptionId: defaultI18nDescriptionId(1, 8),
          autonym: "雪舟",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 8),
          beforeImageURLs: defaultBeforeImageURLs(1, 8),
          afterImageURLs: defaultAfterImageURLs(1, 8),
          faceImageURL: defaultFaceImageURL(1, 8),
        },
        {
          artistId: 11,
          i18nNameId: defaultI18nNameId(1, 9),
          i18nSubtitleId: defaultI18nSubtitleId(1, 9),
          i18nDescriptionId: defaultI18nDescriptionId(1, 9),
          autonym: "Paul Cézanne",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 9),
          beforeImageURLs: defaultBeforeImageURLs(1, 9),
          afterImageURLs: defaultAfterImageURLs(1, 9),
          faceImageURL: defaultFaceImageURL(1, 9),
        },
        {
          artistId: 2,
          i18nNameId: defaultI18nNameId(1, 10),
          i18nSubtitleId: defaultI18nSubtitleId(1, 10),
          i18nDescriptionId: defaultI18nDescriptionId(1, 10),
          autonym: "Berthe Morisot",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 10),
          beforeImageURLs: defaultBeforeImageURLs(1, 10),
          afterImageURLs: defaultAfterImageURLs(1, 10),
          faceImageURL: defaultFaceImageURL(1, 10),
        },
        {
          artistId: 16,
          i18nNameId: defaultI18nNameId(1, 11),
          i18nSubtitleId: defaultI18nSubtitleId(1, 11),
          i18nDescriptionId: defaultI18nDescriptionId(1, 11),
          autonym: "Василий Кандинский",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 11),
          beforeImageURLs: defaultBeforeImageURLs(1, 11),
          afterImageURLs: defaultAfterImageURLs(1, 11),
          faceImageURL: defaultFaceImageURL(1, 11),
        },
        {
          artistId: 5,
          i18nNameId: defaultI18nNameId(1, 12),
          i18nSubtitleId: defaultI18nSubtitleId(1, 12),
          i18nDescriptionId: defaultI18nDescriptionId(1, 12),
          autonym: "Ernst Ludwig Kirchner",
          eyeCatchingImageURL: defaultEyeCathingImageURL(1, 12),
          beforeImageURLs: defaultBeforeImageURLs(1, 12),
          afterImageURLs: defaultAfterImageURLs(1, 12),
          faceImageURL: defaultFaceImageURL(1, 12),
        },
      ]
    }
  ]
}

function initialState() {
  return {
    paintersMaster: paintersMaster,
    targetImageFile: null,
    targetImageSrc: null,
    selectedArtistId: null, // これから変換するのに使う画家
    resultArtistId: null,   // 結果画面での変換に使われた画家特定用
    transFinished: false,
    transferCompleted: false,
    // 結果
    requestId: null,
    notifyWhenComplete: false,
    estimatedMinutes: null,
    transCompleted: false, // 変換が終わったらtrue
    isReleased: false,
    paidPlanSubscribed: null,
    creditAmount: null,
    creditKey: null,
    unitCredit: null,
    unitPrice: null,
    language: null,
    currency: null,
    fileNamePrefix: "", // 透かし解除ファイルは {fileNamePrefix}_{ID(1-2)}.ext(拡張子) という名前でDLさせる
    ext: "",
    resultImages: [], // 2枚の透かし付き結果画像
    // DL
    downloadImages: [], // 2枚の透かし"無し"画像URL
  }
}

const fileReader = new FileReader()
export default {
  namespaced: true,
  state: initialState,
  getters: {
    paintersMaster(state) {
      return state.paintersMaster
    },
    painterById(state) {
      return (id) => {
        for (const genre of state.paintersMaster.genres) {
          for (const painter of genre.painters) {
            if (painter.artistId === id) {
              return painter
            }
          }
        }
        return null
      }
    },
    targetImageFile(state) {
      return state.targetImageFile
    },
    targetImageSrc(state) {
      return state.targetImageSrc
    },
    selectedArtistId(state) {
      return state.selectedArtistId
    },
    transferCompleted(state) {
      return state.transferCompleted
    },
    requestId(state) {
      return state.requestId
    },
    notifyWhenComplete(state) {
      return state.notifyWhenComplete
    },
    estimatedMinutes(state) {
      return state.estimatedMinutes
    },
    resultArtistId(state) {
      return state.resultArtistId
    },
    resultImages(state) {
      return state.resultImages
    },
    downloadImages(state) {
      return state.downloadImages
    },
    creditAmount(state) {
      return state.creditAmount
    }
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setTargetImageFile(state, files) {
      // 複数枚のfileがdropされたとしてもtopの1枚だけ扱う
      state.targetImageFile = files[0]
      fileReader.onload = e => {
        // <img src に指定して表示できるようになる
        state.targetImageSrc = e.target.result;
      }
      fileReader.readAsDataURL(state.targetImageFile)
    },
    cleartargetImageFile(state) {
      state.targetImageFile = null
      state.targetImageSrc = null
    },
    setArtist(state, artistId) {
      state.selectedArtistId = artistId
      state.transFinished = false
    },
    setExecResponse(state, data) {
      state.requestId = data.request_id
      state.notifyWhenComplete = data.notify_when_complete
      state.estimatedMinutes = data.estimated_minutes
    },
    setTransferCompleted(state) {
      state.transferCompleted = true
    },
    setDLStatus(state, apiResult) {
      state.requestId = apiResult.request_id
      state.isReleased = apiResult.is_released
      state.paidPlanSubscribed = apiResult.paid_plan_subscribed
      state.creditAmount = apiResult.credit_amount
      state.creditKey = apiResult.credit_key
      state.unitCredit = apiResult.unit_credit
      state.unitPrice = apiResult.unit_price
      state.language = apiResult.language
      state.currency = apiResult.currency
      state.fileNamePrefix = apiResult.file_name_before
      state.ext = apiResult.ext
      state.resultImages = apiResult.wm_image_list
      state.resultArtistId = apiResult.artist_id
    }
  },
  actions: {
    async draw({ commit, state, dispatch, rootGetters }, locale) {
      const group = rootGetters['user/currentGroup']
      let data = new FormData();
      data.append('file', state.targetImageFile)
      data.append('group_id', group.id)
      data.append('artist_id', state.selectedArtistId)
      data.append('locale', locale)

      const url = (rootGetters['user/logined']) ? '/ep/exec' : '/ep/exec/guest'
      const result = await axios({
        method: 'post',
        url: url,
        data: data,
      })
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      commit('setExecResponse', result.data)

      // ポーリングで進捗をcheck
      let progressFetchInterval = setInterval(() => {
        axios.get(`/ep/progress/${state.requestId}`).then(rsProgress => {
          if (rsProgress.data.process_status == 3) {
            clearInterval(progressFetchInterval)
            commit('setTransferCompleted') // => ProcessingModalContainer側でResultにページ遷移
          }
        })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.log('# progress fetch err: ', err)
          })
      }, 3000)
    },
    async getDLStatus({ commit, dispatch, rootGetters }, requestId) {
      const logined = rootGetters['user/logined']
      const relativePath = (logined) ? '/ep/dl_status/' : '/ep/dl_status/guest/'
      const params = (!logined) ? {group_id: rootGetters['user/currentGroup'].id} : {}
      const response = await axios.get(`${API_BASE_URL}${relativePath}${requestId}`, {params: params})
      if (response.status !== 200) {
        dispatch('showErrorOnScreen', {response: response}, {root: true})
        return
      }
      commit('setDLStatus', response.data)
    },
    async consumeCredits({ state, dispatch, rootGetters }) {
      const group = rootGetters['user/currentGroup']
      let requestParams = {
        "group_id": group.id,
        "token": state.creditKey,
        "request_id": state.requestId,
      }
      const result = await axios.post(`${API_BASE_URL}/credit/consume/ep`, requestParams)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
      } else {
        /*global gtag*/
        gtag('event', 'credit_consume_complete', {
          'event_category': 'credit',
          'event_label': 'ep',
          'value': group.subscription_id
        })
      }
      // view側でexecDownloadが呼ばれる
    },
    async getDLURL({ state, dispatch }) {
      if (!state.isReleased) return
      const result = await axios.get(`${API_BASE_URL}/ep/dl_url/${state.requestId}`)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }

      state.downloadImages = result.data.released_image_url_list
    },
    async share({state, dispatch, rootGetters}) {
      const group = rootGetters['user/currentGroup']
      let requestParams = {
        "group_id": group.id,
        "request_id": state.requestId,
      }
      const result = await axios.post(`${API_BASE_URL}/ep/share`, requestParams)
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', {response: result}, {root: true})
        return
      }
      return true
    }
  },
}
