<template>
  <div>
    <service-header :auth="auth"/>
    <div id="mr_top">
      <v-app>
        <!-- アイキャッチ -->
        <div class="eye_catch_container">
          <v-layout align-center justify-center row>
            <div class="icon_container">
              <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_logo.svg">
            </div>
            <img class="service-title display-sp ml-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_title.svg">
          </v-layout>
          <h1 class="explanation_1 mb-4">{{ $t("mr_1") }}</h1>
          <!-- TODO: 画像差し替え -->
          <div class="illust-container mb-5 mx-3">
            <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/image_mr_bf.png'" class="img shd-m"></v-img>
          </div>
          <p class="note display-pc">{{ $t("mr_2") }}</p>
        </div>
        <!-- ドロップエリア -->
        <div class="drop_area_container">
          <v-layout align-center justify-center column fill-height class="drop_area pa-3">
            <p class="text-preparing white--text mb-0">{{ $t("mr_3") }}</p>
            <p class="white--text text-annotation">{{ $t("mr_23") }}</p>
            <p class="quote-link">
              <router-link to="/#g-form" class="white--text">
                {{ $t("mr_24") }}
              </router-link>
            </p>
          </v-layout>
        </div>

        <div class="intro_area_container">
          <h2 class="how_to">{{ $t("mr_4") }}<br>{{ $t("mr_5") }}</h2>
          <p>{{ $t("mr_6") }}</p>
          <!-- TODO: youtube埋め込み -->
          <img class="movie" :src='$t("mr_21")'>
          <!-- <img class="movie display-sp" :src='$t("mr_22")'> -->

          <!-- 広告 -->
          <ad-responsive></ad-responsive>

          <h2 class="tech">{{ $t("mr_7") }}</h2>
          <div class="ex_container">
            <v-layout align-start justify-space-around wrap>
              <v-flex sm3 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/image_mr_antenna.jpg'" aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("mr_8") }}</p>
              </v-flex>
              <v-flex sm3 offset-sm1 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/image_mr_disc.jpg'"  aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("mr_9") }}</p>
              </v-flex>
              <v-flex sm3 offset-sm1 xs8>
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/image_mr_oldmovie.jpg'" aspect-ratio="1" class="img"></v-img>
                <p>{{ $t("mr_10") }}</p>
              </v-flex>
            </v-layout>
          </div>

          <!-- 最先端のAI技術を使用！ -->
          <div class="times-container">
            <h2>
              <span v-html=" $t('mr_11') "></span>
            </h2>
            <v-layout row wrap class="mt-4">
              <v-flex md5 xs12 :class="{'mx-4': $vuetify.breakpoint.smAndDown}">
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_ex_movie.jpg'" class="eg-pic"></v-img>
                <p>{{ $t("mr_12") }}</p>
              </v-flex>
              <v-flex md5 offset-md2 xs12 :class="{'mx-4': $vuetify.breakpoint.smAndDown}">
                <v-img :src="'https://storage.googleapis.com/cre8tiveai_public_files/img/mr/mr_ex_anime.jpg'" class="eg-pic"></v-img>
                <p>{{ $t("mr_13") }}</p>
              </v-flex>
            </v-layout>
          </div>
          <!-- Q&A -->
          <div class="faq-container">
            <h3>Q&A</h3>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("mr_14") }}</h4>
              <p>
                <span>A.</span>{{ $t("mr_15") }}
              </p>
            </div>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("mr_16") }}</h4>
              <p>
                <span>A.</span>{{ $t("mr_17") }}
              </p>
            </div>
            <div class="qa-container">
              <h4><span>Q.</span>{{ $t("mr_18") }}</h4>
              <p>
                <span>A.</span>{{ $t("mr_19") }}
              </p>
            </div>
          </div>
          <!-- 広告 -->
          <ad-responsive></ad-responsive>
          <!-- 他のクリエイティブなAIを利用する -->
          <services :isReleasedOnly=false :isNextAI=true></services>
        </div>
      </v-app>
    </div>
  </div>
</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'
import Services from '@/components/Services.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
export default {
  name: 'MovieRefinerTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    Services,
    AdResponsive
  },
  data() {
    return {
      showUploadModal: false,
      droppedFiles: []
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

#mr_top {
  background-color: #FAFAFA;

  .eye_catch_container {
    max-width: 608px;
    text-align: center;
    margin: auto;
    position: relative;

    .icon_container {
      background-color: #fff;
      width: 80px;
      height: 80px;
      border-radius: 99px;
      box-shadow: 1px 3px 4px 2px rgba(0,0,0,0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 32px auto;

      img {
        height: 72%;
        margin-left: 10px;
      }

      @include mq-down {
        width: 56px;
        height: 56px;
        margin: 16px 0px;
      }
    }

    .service-title {
      max-height: 24px;
    }

    h1.explanation_1 {
      font-size: 24px;
      color: #7E57C2;
      font-weight: 400;
    }
    .illust-container {
      display: flex;
      justify-content: center;
      align-items: center;

      .img {
        max-width: 384px;
        width: 100%;
        border-radius: 12px;
      }
    }
    p.note {
      margin:auto;
      font-size: 14px;
      color: #311B92;
    }
  }
  // ドロップエリア
  .drop_area_container {
    background: linear-gradient(99deg, #F48FB1, #536DFE);
    height: 296px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    box-shadow: 0 0px 4px 0 rgba(0,0,0,0.4) inset;
    @include mq-down(sm) {
      padding: 10px;
      min-height: 192px;
      height: initial;
    }

    .drop_area {
      max-width: 976px;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 2px dashed #fff;
      @include mq-down(sm) {
        border-radius: 6px;
      }

      .text-preparing {
        font-size: 34px;

        @include mq-down {
          font-size: 26px;
        }
      }

      .text-annotation {
        font-size: 14px;
        margin: 16px 0 0;
      }

      .quote-link {
        font-size: 14px;
        margin: 16px 0 8px;
        text-decoration: underline;
      }
    }
  }
  // イントロエリア
  .intro_area_container {
    background-color: #fff;
    justify-content: center;

    .how_to {
      margin-top: 96px;
      text-align: center;
      font-size: 28px;
      color: #424242;
      justify-content: center;
    }
    .movie {
      margin-top: 64px;
      max-width: 960px;
      width: 100%;
    }
    .tech {
      margin-top: 128px;
      text-align: center;
      font-size: 32px;
      color: #424242;
      font-weight: 400;
    }
    .ex_container {
      max-width: 1040px;
      margin: auto;
      margin-top: 80px;

      .img {
        border-radius: 16px;
      }
      p {
        margin-top: 16px;
        font-size: 14px;
        color: #424242;
        text-align: left;
      }
    }
    h2 {
      font-size: 28px;
      color: #424242;
      font-weight: 400;
    }
    .times-container {
      max-width: 1040px;
      margin: 128px auto 0px auto;
      .eg-pic {
        border: solid #09153A 8px;
        border-radius: 20px;
        @include mq-down(md) {
          border-width: 6px;
        }
        @include mq-down(sm) {
          border-width: 4px;
        }
      }
      p {
        margin-top: 16px;
        text-align: left;
      }
    }
    .tenthousand-container {
      margin-top: 128px;
      .picture-container {
        width: 696px;
        margin: 56px auto;
      }
    }
    .faq-container {
      margin-top: 96px;
      h3 {
        font-size: 32px;
        font-weight: 400;
      }
      .qa-container {
        max-width: 720px;
        text-align: left;
        margin: 64px auto 0px auto;
        @include mq-down {
          margin: 64px 16px 0px 16px;
        }
        h4 {
          margin-bottom: 0px;
          span {
            margin-right: 4px;
            font-size: 28px;
            font-weight: 500;
            font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
          font-size: 20px;
          font-weight: 500;
          color: #234EB5;
        }
        p {
          font-size: 16px;
          span {
            color: #E91E63;
            margin-right: 4px;
            font-size: 28px;
            font-weight: 500;
            font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          }
        }
      }
    }
  }
}
</style>

<i18n src="@/locales/movie_refiner/top.json"></i18n>
