import moment from 'moment-timezone'
import {
  EP_UNIT_PRICE_JPY, EP_UNIT_PRICE_USD,
  MPM_UNIT_PRICE_JPY, MPM_UNIT_PRICE_USD,
  LID_UNIT_PRICE_JPY, LID_UNIT_PRICE_USD,
  MP_UNIT_PRICE_JPY, MP_UNIT_PRICE_USD,
  PD_UNIT_PRICE_JPY, PD_UNIT_PRICE_USD,
  AAP_UNIT_PRICE_JPY, AAP_UNIT_PRICE_USD,
  PS_UNIT_PRICE_JPY, PS_UNIT_PRICE_USD,
  FR_UNIT_PRICE_JPY, FR_UNIT_PRICE_USD,
} from '../settings'

function unixToStr(unixtime, lang, tz, withHour) {
  let date = moment(unixtime * 1000) // moment側はmilli secを受け付ける
  return formattedDateStr(date, lang, tz, withHour)
}

// 2019-01-31T03:56:15 形式の文字列 or moment objectをlocaleに応じて変換
function formattedDateStr(dateOrStr, lang, tz, withHour) {
  let date
  if (typeof dateOrStr === 'string') {
    date = moment(dateOrStr)
  } else {
    date = dateOrStr
  }
  date.locale(lang)
  const fmt = withHour ? 'lll' : 'll'
  return date.tz(tz).format(fmt)
}

function formatPriceMonthly(subsc) {
  return subsc.currency == 'jpy' ? subsc.price_monthly : subsc.price_monthly/100
}

function formatUnitPrice(subsc) {
  return subsc.currency == 'jpy' ? subsc.unit_price : subsc.unit_price/100
}

function formatUnitPriceSc(subsc) {
  const harf = formatUnitPrice(subsc)/2
  // 小数点第2位で切り上げ
  return subsc.currency == 'jpy' ? (Math.ceil(harf * 1) / 1) : (Math.ceil(harf * 100) / 100)
}

function formatUnitPriceEp(subsc) {
  return subsc.currency == 'jpy' ? EP_UNIT_PRICE_JPY : EP_UNIT_PRICE_USD / 100
}

function formatUnitPriceMPM(subsc) {
  return subsc.currency == 'jpy' ? MPM_UNIT_PRICE_JPY : MPM_UNIT_PRICE_USD / 100
}

function formatUnitPriceLID(subsc) {
  return subsc.currency == 'jpy' ? LID_UNIT_PRICE_JPY : LID_UNIT_PRICE_USD / 100
}

function formatUnitPriceMP(subsc) {
  return subsc.currency == 'jpy' ? MP_UNIT_PRICE_JPY : MP_UNIT_PRICE_USD / 100
}

function formatUnitPricePD(subsc) {
  return subsc.currency == 'jpy' ? PD_UNIT_PRICE_JPY : PD_UNIT_PRICE_USD / 100
}

function formatUnitPriceAAP(subsc) {
  return subsc.currency == 'jpy' ? AAP_UNIT_PRICE_JPY : AAP_UNIT_PRICE_USD / 100
}

function formatUnitPricePS(subsc) {
  return subsc.currency == 'jpy' ? PS_UNIT_PRICE_JPY : PS_UNIT_PRICE_USD / 100
}

function formatUnitPriceFR(subsc) {
  return subsc.currency == 'jpy' ? FR_UNIT_PRICE_JPY : FR_UNIT_PRICE_USD / 100
}

// iOS13以降ではuaにiPadは含まれないため非推奨
function isMobile(ua) {
  if( ua.match(/Android/i)
   || ua.match(/webOS/i)
   || ua.match(/iPhone/i)
   || ua.match(/iPad/i)
   || ua.match(/iPod/i)
   || ua.match(/BlackBerry/i)
   || ua.match(/Windows Phone/i)
  ) {
    return true;
  } else {
    return false;
  }
}

// iOS13以降ではuaにiPadは含まれないため、厳密ではないがwindowSizeで判定する
// https://qiita.com/tomohisaota/items/f8857d01f328e34fb551
function judgeIPadByWindowSize() {
  const short = window.outerWidth < window.outerHeight ? window.outerWidth : window.outerHeight
  const long  = window.outerWidth < window.outerHeight ? window.outerHeight : window.outerWidth
  const inchToResolution = {
    '7.9': [768, 1024],
    //'9.7': [768, 1024], // 7.9と同じなので7.9でのチェックでok
    '10.2': [810, 1080],
    '10.5': [834, 1112],
    '10.9': [820, 1180],
    '11.0': [834, 1194],
    '12.9': [1024, 1366]
  }
  for (const resolution of Object.values(inchToResolution)) {
    if (resolution[0] === short && resolution[1] === long) {
      return true
    }
  }
  return false
}

export {
  unixToStr, formattedDateStr, formatPriceMonthly, formatUnitPrice,
  formatUnitPriceSc, formatUnitPriceEp, formatUnitPriceMPM, formatUnitPriceLID,
  formatUnitPriceMP, formatUnitPricePD, formatUnitPriceAAP, formatUnitPricePS,
  formatUnitPriceFR, isMobile, judgeIPadByWindowSize
}
