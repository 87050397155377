import { render, staticRenderFns } from "./License.vue?vue&type=template&id=8b929cd2&scoped=true&"
var script = {}
import style0 from "./License.vue?vue&type=style&index=0&id=8b929cd2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b929cd2",
  null
  
)

/* custom blocks */
import block0 from "@/locales/license.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcreative_ai_front%2Fapp%2Fsrc%2Fviews%2FLicense.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports