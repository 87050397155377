<template>
  <div id="anime4k-top">
    <service-header :auth="auth"/>
    <!-- Heroエリア -->
    <div class="hero-container">
      <v-container hero-content grid-list-xl text-xs-left>
        <v-layout align-center justify-center>
          <h1 class="h-top"><span>{{ $t('anime4k_1') }}</span></h1>
        </v-layout>
        <v-layout row wrap align-center justify-center>
          <v-flex vf-hide-sm-down xs12 md6 lg5 offset-lg1>
            <anime4k-plugin-name :pluginName='$t("glb_anime4k_1")' :powerdBy='$t("glb_anime4k_2")'></anime4k-plugin-name>
            <v-flex class="my-3"></v-flex>
            <anime4k-button :btnText="$t('anime4k_2')" toLink="#price" :btnType=2></anime4k-button>
          </v-flex>
          <v-flex vf-hide-sm-down xs12 md5 lg6>
            <v-layout wrap align-center>
              <v-flex package-img xs12 lg10 offset-lg1>
                <h3 class="package-os mb-2">{{ $t("glb_anime4k_3") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/package01@2x.webp">
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex vf-display-sm-down xs12>
            <v-layout mb-3 align-center justify-center column>
              <v-flex package-img xs12 lg10 offset-lg1>
                <h3 class="package-os mb-2">{{ $t("glb_anime4k_3") }}</h3>
                <img class="package" src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/package01_mob@2x.webp">
              </v-flex>
              <anime4k-plugin-name class="mb-3" :pluginName='$t("glb_anime4k_1")' :powerdBy='$t("glb_anime4k_2")'></anime4k-plugin-name>
              <anime4k-button :btnText="$t('anime4k_2')" toLink="#price" :btnType=2></anime4k-button>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout what-container align-center justify-center column>
          <p class="section-title">{{ $t('anime4k_3') }}</p>
          <p class="section-description" v-html="$t('anime4k_4')"></p>
        </v-layout>
      </v-container>
      <!-- 広告 -->
      <ad-responsive></ad-responsive>
    </div>
    <!-- 詳細説明 -->
    <div class="detail">
      <!-- Sample Anime  -->
      <div class="sample-video">
        <h2>{{ $t("anime4k_5") }}</h2>
        <div class="divider-container video-divider">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/video_divider.svg">
        </div>
        <div class="comparison-container">
          <v-container comparison-title fluid grid-list-md>
            <v-layout row>
              <v-flex xs6 sm4 offset-sm2 md6 offset-md0>
                <v-layout justify-center>
                  <div class="text-before">{{ $t('anime4k_6') }}</div>
                </v-layout>
              </v-flex>
              <v-flex xs6 sm4 md6>
                <v-layout justify-center>
                  <div class="text-after">{{ $t('anime4k_7') }}</div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
          <div class="comparison-video">
            <div class="comparison-video-container">
              <iframe width="877" height="494" src="https://www.youtube.com/embed/_9lRSrw6fUU" frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>
      <!-- スペック表  -->
      <div class="spec-container">
        <h2>{{ $t('anime4k_8') }}</h2>
        <h3>{{ $t('anime4k_9') }}</h3>
        <div class="divider-container white-divider">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/white_divider.svg">
        </div>
        <div class="package-spec-container">
          <h4 class="package-spec-container">{{ $t('anime4k_10') }}</h4>
          <div class="package-spec mt-3">
            <v-container description fluid grid-list-md>
              <v-layout row wrap>
                <v-flex name xs3>{{ $t('anime4k_11') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_12') }}</v-flex>
                <v-flex name xs3>{{ $t('anime4k_13') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_14') }}</v-flex>
              </v-layout>
            </v-container>
          </div>
          <h4 class="env-spec-container">{{ $t('anime4k_15') }}</h4>
          <div class="env-spec mt-3">
            <v-container description fluid grid-list-md>
              <p>{{ $t('anime4k_15_a') }}</p>
              <h5>{{ $t('anime4k_16') }}</h5>
              <v-layout row wrap>
                <v-flex name xs3>{{ $t('anime4k_17') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_18') }}</v-flex>
                <v-flex name xs3>{{ $t('anime4k_19') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_20') }}</v-flex>
                <v-flex name xs3>{{ $t('anime4k_21') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_22') }}</v-flex>
              </v-layout>
              <h5>{{ $t('anime4k_23') }}</h5>
              <v-layout row wrap>
                <v-flex name xs3>{{ $t('anime4k_17') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_18') }}</v-flex>
                <v-flex name xs3>{{ $t('anime4k_19') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_20') }}</v-flex>
                <v-flex name xs3>{{ $t('anime4k_21') }}</v-flex>
                <v-flex content xs9>{{ $t('anime4k_24') }}</v-flex>
              </v-layout>
            </v-container>
          </div>
        </div>
      </div>
      <!-- 価格表  -->
      <div id="price" class="price-container">
        <h2>{{ $t('anime4k_25') }}</h2>
        <div class="divider-container white-divider">
          <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/anime4k/white_divider.svg">
        </div>
        <v-container price-card-container fluid grid-list-lg>
          <v-layout wrap align-center justify-center>
            <div class="package-card package-os">
              <h4>{{ $t('anime4k_26') }}</h4>
              <h5 class="mb-5">{{ $t("anime4k_27") }}</h5>
              <p>{{ $t('anime4k_28') }}</p>
            </div>
          </v-layout>
        </v-container>
        <anime4k-button :auth="auth" :btnText="$t('anime4k_29')" btnClass="btn-cv-purchase" :btnType=3 osType="win"></anime4k-button>
        <v-flex></v-flex>
        <anime4k-button :auth="auth" :btnText="$t('anime4k_30')" btnClass="btn-cv-purchase" :btnType=3 osType="mac"></anime4k-button>
        <div class="trademark">
          <p v-html="$t('anime4k_32')"></p>
          <p v-html="$t('anime4k_33')"></p>
          <p v-html="$t('anime4k_34')"></p>
          <hr class="trademark-dotted">
        </div>
        
      </div>
      
      <!-- 「ミルキーパニック -twelve-」について -->
      <v-layout milky-container align-center justify-center column>
        <p class="milky-title" v-html="$t('anime4k_36')"></p>
        <p class="milky-description" v-html="$t('anime4k_37')"></p>
        <p class="milky-description" v-html="$t('anime4k_38')"></p>
        <p class="milky-description" v-html="$t('anime4k_39')"></p>
      </v-layout>
      <!-- 広告 -->
      <ad-responsive></ad-responsive>
    </div>
  </div>
</template>

<script>
import ServiceHeader from '@/components/ServiceHeader.vue'
import Anime4kButton from '@/components/anime4k/Button.vue'
import Anime4kPluginName from '@/components/anime4k/PluginName.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
export default {
  name: 'Anime4kPlayerTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    Anime4kButton,
    Anime4kPluginName,
    AdResponsive
  },
  data() {
    return {
    }
  },
  watch: {
    '$route': function (n) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
    }
  },
  created() {
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import "../../assets/scss/common.scss";
@import "../../assets/scss/_anime4k.scss";
</style>

<i18n src="@/locales/anime4k_player/top.json"></i18n>
