<template>
  <div>
    <!-- クレジットによる注文 -->
    <!-- リリース時はクレジット決済のみ
    <v-dialog v-model="showCreditDialog" width="500">
      <v-card>
        <v-card-title class="title lighten-2 px-5 font-weight-bold" primary-title>
          {{ $t('glb_scp_21') }}
        </v-card-title>
        <v-card-text class="text-xs-left px-5">
          {{ $t('glb_scp_22', [requiredCredits] ) }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat="flat" @click="onCancel">
            {{ $t('glb_5') }}
          </v-btn>
          <v-btn color="primary" flat @click="onConfirmCredit">
            {{ $t('glb_6') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->
    <!-- 課金による注文はStripe側のページに遷移させる -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'

import { API_BASE_URL, STRIPE_KEY } from '../../../settings'

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      groupCreditFetched: false,
      showCreditDialog: false,
    }
  },
  computed: {
    ...mapGetters('saichanPlus', [
      'requiredCredits',
      'requiredJPY',
      'requiredUSD'
    ]),
    ...mapState('saichanPlus', {
      // 表情差分の依頼をするなら追加クレジット/料金
      changeExpression: state => state.changeExpression,
      orderHighResolution: state => state.orderHighResolution,
    })
  },
  methods: {
    judgeAndOpenPaymentModal() {
      // 2019/12/10 価格戦略等の関係で、リリース時はstripe決済のみ
      this.groupCreditFetched = true
      this.openStripe()
      /*
      // 所持クレジット数を取得し、クレジット or 都度課金のモーダルを出し分ける
      this.$store.dispatch('getCurrentGroupCreditInfoOfUser')
        .then(() => {
          const havingCredits = this.$store.state.user.currentGroupCreditInfo.total
          this.groupCreditFetched = true
          if (havingCredits >= this.requiredCredits) {
            this.showCreditDialog = true
          } else {
            this.openStripe()
          }
        })*/
    },
    async openStripe() {
      const param = this.createRequestParam()
      param['lang'] = this.$i18n.locale == "ja" ? "ja" : "en"

      this.$store.commit('saichan/dump')
      const res = await axios.post(`${API_BASE_URL}/payment/v3/scplus/prepare`, param)
      const group = this.$store.getters['user/currentGroup']
      /*global gtag*/
      gtag('event', 'show_payment_screen', {
        'event_category': 'payment',
        'event_label': 'pay_as_you_go_scplus',
        'value': group.subscription_id,
      })
      /*global Stripe*/
      const stripe = Stripe(STRIPE_KEY)
      const {error} = await stripe.redirectToCheckout({
        sessionId: res.data.session_id
      })
      if (error) {
        this.$store.commit('screen/showError', error)
        console.log('[ERROR]', error)
      }
    },
    onCancel() {
      this.$emit('cancel')
    },
    createRequestParam() {
      const param = {}
      const group = this.$store.getters['user/currentGroup']
      param['group_id'] = group.id
      param['user_id'] = this.$store.state.user.id
      param['order_image_url'] = this.$store.state.saichanPlus.orderImageSrc
      param['order_image_index'] = this.$store.state.saichanPlus.orderImageIdx
      param['face_adjust'] = this.$store.state.saichanPlus.faceAdjust
      param['cloth_type'] = this.$store.state.saichanPlus.clothType
      param['cloth_text'] = this.$store.state.saichanPlus.clothText
      param['pose_type'] = this.$store.state.saichanPlus.poseType
      param['pose_text'] = this.$store.state.saichanPlus.poseText
      param['change_expression'] = this.$store.state.saichanPlus.changeExpression
      param['order_high_resolution'] = this.$store.state.saichanPlus.orderHighResolution
      param['expression_text'] = this.$store.state.saichanPlus.expressionText
      param['other_text'] = this.$store.state.saichanPlus.otherText
      return param
    },
    onConfirmCredit() {
      const param = this.createRequestParam()
      param['lang'] = this.$i18n.locale
      axios.post(`${API_BASE_URL}/credit/consume/scplus`, param)
        .then((res) => {
          this.showCreditDialog = false
          this.completed()
          gtag('event', 'credit_consume_complete', {
            'event_category': 'credit',
            'event_label': 'saichan_plus',
            'value': res.data.consumed_credits
          })
        })
        .catch((err) => {
          console.log('# credit consume err:', err)
        })
    },
    completed() {
      this.$emit('completed')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";

</style>