<template>
  <v-container class="ca-card-container" ref="container">
    <!-- 選択した画像 -->
    <p class="uploaded-text">{{ $t("st2_4") }}</p>
    <div v-if="targetImageFile" class="uploaded-item">
      <p>{{ targetImageFile.name }}</p>
      <img v-if="targetImageSrc" :src="targetImageSrc" class="uploaded-img"/>
    </div>
    <!-- どの画家風にしますか？ -->
    <div class="please-select-balloon">
      <p>{{ $t("st2_5") }}</p>
    </div>
    <template v-for="(genre, genreIdx) in paintersMaster.genres">
      <!-- リリース時はジャンルの区別は無いためジャンル名非表示
      <p :key="'genre-name-' + genreIdx">{{$t(genre.i18nNameId) }}</p>
      -->
      <div class="painter-list-container" :key="'painter-list-container-' + genreIdx">
        <template v-for="(painter, idx) in genre.painters">
          <!-- 画像がクリックされた時、orderを動的に切り替えて詳細情報エリアを挿入する -->
          <div
            class="painter-container"
            :key="'painter-' + idx"
            :style="{ order: calcPainterDisplayOrder(genreIdx, idx)}"
            @click="selectPainter(genreIdx, idx)"
          >
            <img :src="painter.eyeCatchingImageURL" />
            <div class="painter-name-container">
              <p class="painter-name">{{ $t(painter.i18nNameId) }}</p>
            </div>
          </div>
        </template>
        <!-- 詳細情報エリア -->
        <div
          v-if="selectedGenreIdx == genreIdx && detailInfoDisplayIdx"
          class="painter-detail"
          :style="{ order: detailInfoDisplayIdx}"
        >
          <div class="painter-detail-balloon" :style="{left: detailBaloonLeft + 'px'}"></div>
          <!-- 移動 or 閉じるヘッダー -->
          <div class="painter-detail-header">
            <div class="left-button-container">
              <v-btn @click="moveLeft()" small fab flat :disabled="selectedPainterIdx === 0">
                <img v-if="selectedPainterIdx === 0" class="left-button" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/move-left-disabled.webp"/>
                <img v-else class="left-button" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/move-left.webp"/>
              </v-btn>
            </div>
            <div class="right-button-container">
              <v-btn @click="moveRight()" small fab flat :disabled="selectedPainterIdx === (paintersMaster.genres[selectedGenreIdx].painters.length - 1)">
                <img v-if="selectedPainterIdx === (paintersMaster.genres[selectedGenreIdx].painters.length - 1)" class="right-button" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/move-right-disabled.webp"/>
                <img v-else class="right-button" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/move-right.webp"/>
              </v-btn>
            </div>
            <div class="close-button-container">
              <v-btn @click="close()" small fab flat>
                <img
                  class="close-button"
                  src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/cancel-select.webp"
                />
              </v-btn>
            </div>
          </div>
          <!-- 元写真 & 変換例 -->
          <div class="example-image-container">
            <!--  v-carousel は外から任意の位置にscrollできなさそうなので使うのを断念した -->
            <div class="after-image-carousel" ref="afterImageCarousel" :style="{ height: afterImageHeight + 'px', width: afterImageWidth + 'px' }">
              <img v-for="(url, idx) in selectedPainter.afterImageURLs"
                :key="idx" :src="url" class="after-example-image" :style="{ height: afterImageHeight + 'px', width: afterImageWidth + 'px' }"/>
            </div>
            <div class="before-example-image-container" :style="{ width: afterImageWidth + 'px' }">
              <div class="before-example-image" :class="{focused: focusedExampleIdx === 0}" @click="onSelectExampleImage(0)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="selectedPainter.beforeImageURLs[0]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
              <div class="before-example-image" :class="{focused: focusedExampleIdx === 1}" @click="onSelectExampleImage(1)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="selectedPainter.beforeImageURLs[1]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
              <div class="before-example-image" :class="{focused: focusedExampleIdx === 2}" @click="onSelectExampleImage(2)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="selectedPainter.beforeImageURLs[2]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
            </div>
          </div>

          <!-- 画家の説明 + 描くボタン -->
          <div class="painter-description-container">
            <p class="painter-name">{{ $t(selectedPainter.i18nNameId)}}</p>
            <p class="painter-autonym">{{ selectedPainter.autonym }}</p>
            <!-- SP -->
            <div class="display-sp painter-face-container">
              <img class="painter-face" :src="selectedPainter.faceImageURL"/>
            </div>
            <div class="display-sp painter-title-container">
              <p :class="isLongLang ? 'small-text' : ''">{{ $t(selectedPainter.i18nSubtitleId )}}</p>
            </div>
            <!-- SPここまで -->

            <!-- PC -->
            <div class="display-pc painter-face-and-title-container">
              <div class="painter-face-container">
                <img class="painter-face" :src="selectedPainter.faceImageURL"/>
              </div>
              <div class="painter-title-container">
                <p :class="isLongLang ? 'small-text' : ''">{{ $t(selectedPainter.i18nSubtitleId )}}</p>
              </div>
            </div>
            <!-- PCここまで -->

            <div class="painter-text-container">
              <p :class="isLongLang ? 'small-text' : ''">{{ $t(selectedPainter.i18nDescriptionId)}}</p>
            </div>

            <div class="display-sp btn-container">
              <v-btn slot="activator" class="draw-btn" dark round large block @click="draw()">
                {{ $t("st2_6") }}
              </v-btn>
            </div>
            <v-btn slot="activator" class="display-pc draw-btn" dark round large block @click="draw()">
              {{ $t("st2_6") }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedGenreIdx: null,
      selectedPainterIdx: null,
      detailInfoDisplayIdx: null, // 詳細情報のpainter-container内での表示位置
      selectedPainter: null,
      focusedExampleIdx: 0, // ある画家を選択した時のフォーカス中の変換例(0-2)
      afterImageHeight: 280, // 変換後の画像の高さ(タブレットサイズ以下は動的に調整する)
      afterImageWidth: 450,
      beforeImageHeight: 97.4, // 変換前の画像の高さ(同上)
      beforeImageWidth: 148
    };
  },
  computed: {
    ...mapGetters("enpainter", [
      "paintersMaster",
      "targetImageFile",
      "targetImageSrc",
    ]),
    detailBaloonLeft() {
      if (this.selectedPainterIdx === null) {
        return 0
      }
      if (this.painterNumInARow === 4) {
        return 81 + 200 * (this.selectedPainterIdx % 4)
      } else {
        let elementWidth = 170 // 仮
        // SPは端末によってモーダルの幅にばらつきがあるので動的にサイズを取得する
        if (this.$refs.afterImageCarousel && this.$refs.afterImageCarousel[0]) {
          elementWidth = this.$refs.afterImageCarousel[0].clientWidth / 2
        }
        return 32 + elementWidth * (this.selectedPainterIdx % 2)
      }
    },
    // 1行に何人の画家を表示するか？
    painterNumInARow() {
      // TODO: https://bitbucket.org/k-sugawara_radius5/creative_ai/pull-requests/209 で話した改修を行ったら $vuetifyを使うよう修正したい
      //return window.innerWidth < this.$vuetify.breakpoint.thresholds.sm ? 2 : 4
      return window.innerWidth < 767 ? 2 : 4
    }
  },
  methods: {
    selectPainter(genreIdx, painterIdx) {
      this.selectedGenreIdx = genreIdx;
      this.selectedPainterIdx = painterIdx;
      this.detailInfoDisplayIdx = painterIdx + this.painterNumInARow - (painterIdx % this.painterNumInARow);
      this.selectedPainter = this.paintersMaster.genres[genreIdx].painters[painterIdx];
      // 0番目にジャンプ
      this.resetCarouselPosition()
      this.calcAfterImageHeight()
    },
    resetCarouselPosition() {
      this.focusedExampleIdx = 0
      if (this.$refs.afterImageCarousel && this.$refs.afterImageCarousel[0]) {
        this.$refs.afterImageCarousel[0].scrollTo({
          left: 0,
          top: 0,
          behavior: 'auto'
        })
      }
    },
    calcAfterImageHeight() {
      // SPであれば動的に計算
      // TODO: https://bitbucket.org/k-sugawara_radius5/creative_ai/pull-requests/209 で話した改修を行ったら $vuetifyを使うよう修正したい
      //if (window.innerWidth < this.$vuetify.breakpoint.thresholds.sm) {
      if (window.innerWidth < 767) {
        if (this.$refs.container) {
          // 40: painter-detailが両端を20pxずつ広げている分
          this.afterImageHeight = 280 / 450 * (this.$refs.container.clientWidth + 40)
          this.afterImageWidth = window.innerWidth - 46 // 両端のマージンを引く
          // スクリーン横幅から両端のマージン(24pxちょいずつ)とborder分(=4/3)を引いた領域の3分割が1画像あたりの横幅
          this.beforeImageWidth = ((window.innerWidth - 48) / 3) - 4/3
          this.beforeImageHeight = 98 / 150 * this.beforeImageWidth
        }
      }
    },
    calcPainterDisplayOrder(genreIdx, painterIdx) {
      if (this.selectedGenreIdx !== genreIdx) {
        return painterIdx;
      }
      // 詳細表示中のジャンルでは、詳細表示位置移行の要素を後ろにずらす
      if (painterIdx >= this.detailInfoDisplayIdx) {
        return painterIdx + 1;
      } else {
        return painterIdx;
      }
    },
    moveLeft() {
      this.selectedPainterIdx -= 1
      this.detailInfoDisplayIdx = this.selectedPainterIdx + this.painterNumInARow - (this.selectedPainterIdx % this.painterNumInARow)
      this.selectedPainter = this.paintersMaster.genres[this.selectedGenreIdx].painters[this.selectedPainterIdx]
      this.resetCarouselPosition()
    },
    moveRight() {
      this.selectedPainterIdx += 1
      this.detailInfoDisplayIdx = this.selectedPainterIdx + this.painterNumInARow - (this.selectedPainterIdx % this.painterNumInARow)
      this.selectedPainter = this.paintersMaster.genres[this.selectedGenreIdx].painters[this.selectedPainterIdx]
      this.resetCarouselPosition()
    },
    close() {
      this.selectedGenreIdx = null
      this.selectedPainterIdx = null
      this.detailInfoDisplayIdx = null
      this.selectedPainter = null
    },
    onSelectExampleImage(index) {
      this.focusedExampleIdx = index
      // 選択された画像が表示されるようなめらかにスクロール (カルーセル画像の幅はSPだと端末によって違うので動的に取得)
      //let carouselImageWidth = this.$refs.afterImageCarousel[0].clientWidth
      this.$refs.afterImageCarousel[0].scrollTo({
        left: this.afterImageWidth * index,
        top: 0,
        behavior: 'smooth'
      })
    },
    draw() {
      this.$store.commit('enpainter/setArtist', this.selectedPainter.artistId)
      this.$emit('painterSelected')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
// ブラウザのdefault style reset
p {
  margin-block-start: 0;
  margin-block-end: 0;
}
button {
  border: none;
}

.ca-card-container {
  margin: 0;
  padding: 0;
  max-width: 900px;
  padding-top: 45px;

  .uploaded-text {
    font-size: 20px;
    line-height: 29px;
    font-weight: 400;
    //font: Regular 20px/50px Noto Nastaliq Urdu;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 5px;

    @include mq-down(md) {
      margin-top: 0;
      font-size: 15px;
    }
  }
}
.uploaded-item {
  height: 80px;
  margin-left: 75px;
  margin-right: 75px;
  background-color: #FFAB38;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq-down {
    flex-direction: column;
    height: 130px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
  }
  p {
    margin-bottom: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    line-height: 48px;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #424242;

    @include mq-down {
      left: 0;
      padding: 0 16px;
      margin: 0;
      font-size: 16px;
      max-width: 100%;
      line-height: 23px;
      margin-top: 16px;
    }
  }
  .uploaded-img {
    position: absolute;
    top: 10px;
    right: 17px;
    height: 60px;

    @include mq-down {
      position: static;
      padding: 0;
      margin-top: 15px;
      max-height: 60px;
    }
    /*.img {
      width: 128px;
      max-height: 108px;
      &::before {
        height: auto;
        width: 100%;
        content: "";
        background-color: white;
        opacity: 0;
        position: relative;
        z-index: 2;
        transition: 0.1s;
      }
      @include mq-down {
        max-height: 64px;
      }
    }*/
  }
}
.please-select-balloon {
  position: relative;
  max-width: 622px;
  min-width: 306px;
  min-height: 45px;
  margin-top: 32px;
  margin-bottom: 48px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  border-radius: 4px;
  padding-top: 9.7px;
  &::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -10px;
    border: 10px solid transparent;
    border-top: 24px solid #fff;
  }
  p {
    font-size: 18px;
    font-weight: 500;
    color: #424242;
  }
  @include mq-down {
    max-width: unset;
    min-width: unset;
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 8px;
  }
}
.painter-genre {
  margin-top: 12px;
}
.painter-list-container {
  display: flex;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  flex-flow: row wrap;
  justify-content: flex-start;
  .painter-container {
    margin: 10px 10px;
    @include mq-down {
      margin-bottom: 0px;
      width: calc(100% / 2 - 3.5px);
      &:nth-child(odd) {
        margin-left: 0px;
        margin-right: 3.5px;
      }
      &:nth-child(even) {
        margin-left: 3.5px;
        margin-right: 0px;
      }
    }
    img {
      max-width: 180px;
      height: auto; /*160px;*/
      @include mq-down {
        width: 100%;
      }
    }
    .painter-name-container {
      .painter-name {
        width: 180px;
        height: 52px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        margin-top: 5px;
        @include mq-down {
          width: 100%;
          height: 46px;
        }
      }
    }
  }
  .painter-detail {
    position: relative;
    width: 900px;
    // modalの横幅いっぱいに広げて表示する
    margin-left: -50px;
    margin-right: -50px;
    height: 450px;
    background-color: #FFC77C;
    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    @include mq-down {
      overflow-x: hidden; // afterImageWidthの結果によっては若干はみ出してscrollの余地ができるようなので潰す
      width: calc(100% + 40px); // 親要素の幅 + scroll-areaのpadding分
      margin-left: -20px; // scroll-areaのpadding分 ※ 修正するときは afterImageHeight()も合わせて修正する
      margin-right: -20px;
      height: unset;
      flex-flow: column;
      justify-content: center;
    }

    // 吹出し口 一般的にはbefore疑似要素でやることが多いと思われるが、js側から値を操作できないので独立した要素にした
    .painter-detail-balloon {
      content: "";
      position: absolute;
      top: -30px;
      //left: 50%; detailBaloonLeftで計算
      margin-left: -15px;
      border: 15px solid transparent;
      border-bottom: 16px solid #FFC77C;
      transition: left 0.3s ease-out;
    }

    .painter-detail-header {
      position: absolute;
      top: 10px;
      right: 35px;
      height: 52px;
      @include mq-down {
        top: 0px;
        height: 40px;
        right: 10px;
      }
      .left-button,
      .right-button,
      .close-button {
        width: 20px;
        height: 20px;
      }
      // vuetifyにより自動設定されるpaddingを消したい
      .v-btn {
        margin: 0;
      }
      .left-button-container,
      .close-button-container {
        display: inline-block;
      }
      .right-button-container {
        display: inline-block;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
    .painter-description-container {
      position: relative;
      margin-top: 50px;
      margin-left: 61px;
      width: 284px;
      text-align: left;
      @include mq-down {
        width: 100%;
        margin: 0px;
        padding-left: 30px;
        padding-right: 30px;
      }
      .painter-name, .painter-autonym {
        color: #93562F;
        font-size: 18px;
        font-weight: bold;
        @include mq-down {
          font-size: 15px;
          font-weight: 600;
        }
      }
      p {
        color: #424242;
        font-weight: 500;
        @include mq-down {
          font-size: 15px;
        }
      }
      .display-sp.painter-face-container {
        position: absolute;
        top: 2px;
        right: 15px;
        .painter-face {
          width: 60px;
          height: 60px;
        }
      }
      .display-sp.painter-title-container {
        p {
          max-width: calc(100% - 80px);
          font-size: 14px;
          color: #93562F;
          &.small-text {
            font-size: 13px;
          }
        }
      }

      .display-pc.painter-face-and-title-container {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        margin-bottom: 5px;
        .painter-face-container {
          margin-top: 11px;
          .painter-face {
            width: 76px;
            height: 76px;
          }
        }
        .painter-title-container {
          margin-left: 23px;
          width: 154px;
          p {
            word-wrap: break-word;
            font-size: 14px;
            color: #93562F;
            &.small-text {
              font-size: 13px;
            }
          }
        }
      }
      .painter-text-container {
        height: 81px;
        @include mq-down {
          margin-top: 8px;
          height: unset;
          p {
            font-size: 14px;
            line-height: 28px;
          }
        }
        .small-text {
          font-size: 13px;
        }
      }
      // SPで描くボタンをcenterに表示させるのに使う
      .display-sp.btn-container {
        width: 100%;
        text-align: center;
      }
      .draw-btn {
        display: inline-block;
        background: linear-gradient(120deg, #FFAB38, #93562F);
        font-size: 20px;
        font-weight: 500;
        margin-top: 88px;
        height: 55px;
        border-radius: 99px;
        max-width: 185px;

        @include mq-down {
          display: inline-flex;
          margin: 8px auto 24px auto;
          padding: 0;
          font-size: initial;
          height: 44px;
          width: 285px;
        }
        /deep/ .v-btn__content {
          width: 100%;
        }
      }
    }
    .example-image-container {
      position: relative;
      max-width: 456px;
      height: 391px;
      margin-top: 30px;
      margin-left: 60px;
      text-align: left;
      @include mq-down {
        width: 100%;
        height: initial;
        margin-top: 40px;
        margin-left: 0px;
      }
      .before-example-image-container {
        @include mq-down {
          width: 100%;
        }
        .before-example-image {
          display: inline-block;
          margin-bottom: 20px;
          &.focused {
            outline: 3px solid #42A5F5;
            margin-right: 3px; // outlineが右隣の画像の下に回り込んでしまうのを防ぐ
            @include mq-down {
              outline: 2px solid #42A5F5;
              margin-right: 2px;
            }
          }
          img {
            box-sizing: border-box;
            width: 148px;
            //height: 98px;
            height: auto;
            @include mq-down {
              width: 100%;
              //height: initial;
            }
          }
        }
      }

      .after-image-carousel {
        overflow-x: hidden;
        overflow-y: hidden;
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        width: 450px;
        //height: 280px;
        margin-bottom: 10px;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          display: none;
        }
        @include mq-down {
          width: 100%;
          //height: initial;
        }
      }
      .after-example-image {
        width: 450px;
        height: auto;
        position: relative;
        //object-fit: contain;
        object-fit: fill;
        //display: inline;
        flex: 0 0 auto;
        @include mq-down {
          width: 100%;
          // NOTE. 実機だと高さが280px(=PCでのアスペクト比を崩さない画像高さ)になってしまって
          // object-fit: contain でアスペクト比を崩さず表示すると画像の上下にあまりがでてしまう・・
          // (PCで画面を狭めたときは問題ない) => 仕方がないのでjsでheightを設定した
        }
      }
    }
  }
}
</style>

<i18n>
{
  "ja": {
    "st2_1": "$画家名$風に変換したい画像をアップロードしてください。",
    "st2_2": "ここに画像をドラッグまたは、",
    "st2_3": "ファイルを選択",
    "st2_4": "選択した画像",
    "st2_5": "どの画家風にしますか？",
    "st2_6": "描く"
  },
  "en": {
    "st2_1": "Please upload your image.",
    "st2_2": "Drag the image here or",
    "st2_3": "Select File",
    "st2_4": "Selected Images",
    "st2_5": "Which painter style do you select?",
    "st2_6": "Draw"
  },
  "fr": {
    "st2_1": "Veuillez télécharger votre image.",
    "st2_2": "Faites glisser l'image ici ou",
    "st2_3": "Sélectionnez un fichier",
    "st2_4": "Images sélectionnées",
    "st2_5": "Quel style de peintre voulez-vous ?",
    "st2_6": "Dessin"
  },
  "es": {
    "st2_1": "Por favor, sube tu imagen.",
    "st2_2": "Arrastre la imagen aquí o",
    "st2_3": "Seleccione el archivo...",
    "st2_4": "Imágenes seleccionadas",
    "st2_5": "¿Qué estilo de pintor quieres?",
    "st2_6": "Dibuja"
  },
  "pt": {
    "st2_1": "Por favor, carregue a sua imagem.",
    "st2_2": "Arraste a imagem para aqui ou",
    "st2_3": "Seleccionar ficheiro",
    "st2_4": "Imagens seleccionadas",
    "st2_5": "Qual o estilo de pintor que pretende?",
    "st2_6": "Desenho"
  },
  "de": {
    "st2_1": "Bitte laden Sie Ihr Bild hoch.",
    "st2_2": "Ziehen Sie das Bild hierher oder",
    "st2_3": "Datei auswählen",
    "st2_4": "Ausgewählte Bilder",
    "st2_5": "Welchen malerischen Stil wünschen Sie?",
    "st2_6": "Zeichnen"
  },
  "nl": {
    "st2_1": "Gelieve uw afbeelding te uploaden.",
    "st2_2": "Sleep de afbeelding hier of",
    "st2_3": "Selecteer Bestand",
    "st2_4": "Geselecteerde afbeeldingen",
    "st2_5": "Welke schilderstijl wil je?",
    "st2_6": "Tekenen"
  },
  "tr": {
    "st2_1": "senin resim yükle.",
    "st2_2": "Resmi buraya sürükleyin veya",
    "st2_3": "Dosya Seç",
    "st2_4": "Seçili Resimler",
    "st2_5": "Hangi ressam tarzı seçilir?",
    "st2_6": "Çizmek"
  },
  "zh-cn": {
    "st2_1": "上传想要转换成$画家名$风的图像。",
    "st2_2": "你可以把图像拖拽至此处，或者",
    "st2_3": "选择文件",
    "st2_4": "选择的图像",
    "st2_5": "你想用哪种画家风格？",
    "st2_6": "画"
  },
  "zh-tw": {
    "st2_1": "上傳想要轉換成$畫家名$風的圖像。",
    "st2_2": "在這裏，你可以把圖像拖拽出來，",
    "st2_3": "選擇文件",
    "st2_4": "選擇的圖像",
    "st2_5": "哪種畫家風格？",
    "st2_6": "畫"
  },
  "sv": {
    "st2_1": "Ladda upp din bild.",
    "st2_2": "Dra bilden här eller",
    "st2_3": "Välj fil",
    "st2_4": "valda bilder",
    "st2_5": "Vilken målare stil ni väljer?",
    "st2_6": "Dra"
  },
  "it": {
    "st2_1": "Si prega di caricare la vostra immagine.",
    "st2_2": "Trascinare l'immagine qui o",
    "st2_3": "Selezionare il file",
    "st2_4": "Immagini selezionate",
    "st2_5": "Quale stile di pittura vuoi?",
    "st2_6": "Disegnare"
  },
  "ko": {
    "st2_1": "$ 화가명 $풍으로 바꾸고 싶은 이미지를 올려보세요.",
    "st2_2": "여기에 이미지를 드래그 또는",
    "st2_3": "파일 선택",
    "st2_4": "선택한 이미지",
    "st2_5": "어떤 화가풍으로 그릴까요?",
    "st2_6": "그리다"
  },
  "ar": {
    "st2_1": "يرجى تحميل الصور الخاصة بك.",
    "st2_2": "اسحب صورة هنا أو",
    "st2_3": "حدد ملف",
    "st2_4": "صور مختارة",
    "st2_5": "أي نمط رسام لا تختار؟",
    "st2_6": "رسم"
  },
  "th": {
    "st2_1": "โปรดอัปโหลดภาพของคุณ",
    "st2_2": "ลากภาพที่นี่หรือ",
    "st2_3": "เลือกไฟล์",
    "st2_4": "รูปภาพที่ถูกเลือก",
    "st2_5": "แบบไหนที่คุณจิตรกรเลือก?",
    "st2_6": "วาด"
  },
  "id": {
    "st2_1": "Silakan upload gambar Anda.",
    "st2_2": "Drag gambar di sini atau",
    "st2_3": "Pilih file",
    "st2_4": "Gambar yang dipilih",
    "st2_5": "gaya pelukis yang Anda pilih?",
    "st2_6": "Seri"
  },
  "pl": {
    "st2_1": "Proszę przesłać swoje zdjęcie.",
    "st2_2": "Przeciągnij obrazek tutaj lub",
    "st2_3": "Wybierz Plik",
    "st2_4": "Wybrane obrazy",
    "st2_5": "Jaki chcesz styl malarza?",
    "st2_6": "Rysunek"
  },
  "vi": {
    "st2_1": "Hãy tải lên hình ảnh của bạn.",
    "st2_2": "Kéo hình ảnh đây hoặc",
    "st2_3": "Chọn tập tin",
    "st2_4": "ảnh theo lựa chọn",
    "st2_5": "Những phong cách họa sĩ để bạn chọn?",
    "st2_6": "Vẽ tranh"
  },
  "hi": {
    "st2_1": "अपने चित्र अपलोड करें।",
    "st2_2": "चित्र यहां खींचें या",
    "st2_3": "फ़ाइल का चयन करें",
    "st2_4": "चुनी गई छवियाँ",
    "st2_5": "कौन चित्रकार शैली आप चयन करते हैं?",
    "st2_6": "खींचना"
  },
  "ru": {
    "st2_1": "Пожалуйста, загрузите ваше изображение.",
    "st2_2": "Перетащите изображение сюда или",
    "st2_3": "Выберите Файл",
    "st2_4": "Выбранные изображения",
    "st2_5": "Какой стиль живописи ты хочешь?",
    "st2_6": "Рисунок"
  },
  "bn": {
    "st2_1": "আপনার ইমেজ আপলোড করুন।",
    "st2_2": "চিত্রকে এখানে টেনে আনুন বা",
    "st2_3": "নথি নির্বাচন",
    "st2_4": "নির্বাচিত ছবি",
    "st2_5": "কোনটি চিত্রশিল্পী শৈলী নির্বাচন করবেন?",
    "st2_6": "আঁকা"
  }
}
</i18n>
