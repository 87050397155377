<template>
  <div id="not-found">
    <h1>404</h1>
    <div class="sai-chan">
      <div class="base-container">
        <v-layout align-center justify-center class="balloon-container">
          <v-img class="face-with-balloon" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/plus_sai_face.webp"></v-img>
          <p class="balloon-row" :class="isLongLang ? 'small-text' : ''" v-html="$t('notfound_1')"></p>
        </v-layout>
      </div>
    </div>
    <v-btn dark class="button btn-home white--text no-deco text-none" @click.native="$router.go(0)" to="/">
      {{ $t("notfound_2") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  created() {
    this.$store.commit('screen/headerVisible', false)
    this.$store.commit('screen/footerVisible', false)
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
#not-found {
  min-height: 100vh;
}
h1 {
  font-family: 'FranklinGothic URW';
  font-size: 150px;
  font-style: italic;
  font-weight: 300;
  line-height: 1.72;
  margin-left: calc(319 / 1440 * 100vw);
  text-align: left;
  @include mq-down(md) {
    font-size: 70px;
    line-height: 2.09;
    margin-left: 38px;
  }
}
@mixin container_base($width: 1040px) {
  padding: 0 40px;
  overflow: hidden;
  @include mq-down(md) {
    padding: 0 28px;
  }
  .base-container {
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
  }
  p {
    margin-bottom: 0;
  }
}
.balloon-container {
  align-items: center;
  margin-bottom: 0;
  @include mq-down(md) {
    margin: 0 -5px;
  }
}
@mixin balloon {
  color: #424242;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: 'FranklinGothic URW';
  font-weight: 300;
  text-align: left;
  border-radius: 999px;
  position: relative;
  background-color: #FCEC76;
  font-size: 47px;
  line-height: 1.5;
  min-height: 128px;
  z-index: 2;
  &::after {
    content: '';
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }
}
.sai-chan {
  @include container_base(980px);
  * {
    color: #4E70A7;
  }
  .face-with-balloon {
    @include sai_face(240px);
    border: 1px solid black;
    @include mq-down(sm) {
      border-width: 1px;
    }
    @include mq-down(xl) {
      @include sai_face(192px);
      border: 1px solid black;
      @include mq-down(sm) {
        border-width: 1px;
      }
    }
    @include mq-down(md) {
      @include sai_face(120px);
      border: 1px solid black;
      @include mq-down(sm) {
        border-width: 1px;
      }
    }
    @include mq-down(xs) {
      @include sai_face(100px);
      border: 1px solid black;
      @include mq-down(sm) {
        border-width: 1px;
      }
    }
  }
  .balloon-container {
    margin-top: 30px;
    .balloon-row {
      @include balloon;
      border-radius: 999px;
      padding: 6px 43px 23px 93px;
      margin-left: 30px;
      margin-top: -32px;
      &::after {
        background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/03_service-flow/yellow_tail_side.svg);
        width: 48px;
        height: 44px;
        left: -7px;
        bottom: 0;
        transform: translate(0, -50%);
        z-index: -1;
      }
      @include mq-down(xl) {
        font-size: 28px;
        padding: 4px 32px 17px 69px;
      }
      @include mq-down(md) {
        font-size: 20px;
        line-height: 1.44;
        padding: 3px 21px 11px 46px;
      }
      @include mq-down(sm) {
        font-size: 15px;
        margin-left: 15px;
        padding: 1px 10px 5px 23px;
      }
      @include mq-down(xs) {
        font-size: 10px;
      }
      &.small-text {
        font-size: 42px;
        @include mq-down(xl) {
          font-size: 28px;
        }
        @include mq-down(md) {
          font-size: 20px;
        }
        @include mq-down(sm) {
          font-size: 15px;
        }
        @include mq-down(xs) {
          font-size: 10px;
        }
      }
    }
  }
}
.btn-home {
  color: white;
  width: 448px;
  max-width: 88vw;
  white-space: normal;
  min-height: 64px;
  font-size: 28px;
  font-weight: 400;
  margin: 40px auto;
  border-radius: 99px;
  background: linear-gradient(120deg, #37CAE7, #234EB5);
  box-shadow: 0 2px 10px rgba(0,0,0,0.4) !important;
  &:hover {
    box-shadow: 1px 6px 15px rgba(0,0,0,0.4) !important;
    &::before {
      background: linear-gradient(120deg, #00E5FF, #651FFF);
      opacity: .5;
    }
  }
  @include mq-down(sm) {
    font-size: 20px;
    min-width: 0px;
    max-width: 448px;
    width: 88vw;
  }
}
</style>
<i18n src="@/locales/notfound.json"></i18n>
