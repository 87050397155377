<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t("glb_scp_35") }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t("glb_scp_36") }}</h3>

      <ul class="precautions-ul">
        <li>{{ $t("glb_scp_37") }}</li>
        <li>{{ $t("glb_scp_38") }}</li>
        <li>{{ $t("glb_scp_39") }}<br>
          {{ $t("glb_scp_40") }}</li>
        <li>{{ $t("glb_scp_41") }}</li>
        <li><span v-html='$t("glb_scp_42")'></span></li>
        <li>{{ $t("glb_scp_43") }}</li>
        <li>{{ $t("glb_scp_44") }}</li>
      </ul>

      <div class="next-button-container">
        <v-btn class="next-button" flat @click="next()">
          <div class="next-container text-none">
            <v-img contain class="next-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/next-arrow.svg"></v-img>
            <p>next</p>
          </div>
        </v-btn>
      </div>

      <div class="back-button-container">
        <v-btn class="back-button" flat @click="back()">
          <div class="back-container text-none">
            <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
            <p>back</p>
          </div>
        </v-btn>
      </div>

      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`">
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    close() {
      this.$emit('close');
    },
    next() {
      this.$emit('precautionsChecked')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_sc-plus-form.scss";


.form-heading {
  margin-top: 16px;
  margin-bottom: 8px;
}

.precautions-ul {
  max-width: 600px;
  margin: auto;
  font-size: 16px;
  line-height: 1.8;
  text-align: left;
  position: relative;
  left: 48px;

  @include mq-down {
    position: static;
    font-size: 14px;
    line-height: 1.5;
    li {
      margin-bottom: 8px;
    }
  }
}

.next-button-container {
  margin-top: 0;
  @include mq-down {
    padding-bottom: 128px;
  }

  .next-button {
    padding: 8px 4px 0;
    height: initial;
    width: initial;

    .next-container {
      @include franklin_font;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      transform-origin: 50% 50%; 
      @include mq-down {
        font-size: 24px;
        flex-direction: row-reverse;
        @include moving_image;
      }

      .next-button-image {
        width: 64px;
        margin-bottom: 12px;
        @include mq-up {
          @include moving_image;
        }
        @include mq-down {
          width: 36px;
          margin-left: 12px;
        }
      }
    }
  }
}

.slick-dot-7 {
  background-color: #8A8A8A;
}
</style>