<template>
  <div id="reset_target">
    <service-header :auth="auth"/>
    <div id="sa_top" class="pb-5">

      <div class="hero">
        <v-layout justify-center align-center column class="hero-container py-4 mx-auto">
          <v-img class="face-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp"></v-img>
          <div class="balloon balloon--top_center white mx-3">
            <p class="sc-speech pink--text mb-0 mx-auto">{{ $t("sai_1a") }}
              <br>{{ $t("sai_1b") }}</p>
          </div>
          <please-draw-btn class="draw-btn" :btnText='$t("sai_2")'></please-draw-btn>
        </v-layout>
        <span v-if="isSP" class="hero-pic"></span>
        <video v-else class="hero-video" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_hero_movie_2K_light.mp4" loop playsinline autoplay></video>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="scroll-anime pt-5">
        <sai-scroll-magic></sai-scroll-magic>
      </div>

      <div class="sc-about">
        <div class="sc-about-container px-2">
          <h1 class="heading mx-3">{{ $t("sai_7") }}</h1>
          <p class="mb-5"><span v-html='$t("sai_8")'></span></p>
          <please-draw-btn class="draw-btn" :btnText='$t("sai_2")'></please-draw-btn>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="sc-great">
        <div class="sc-great-container">
          <h2 class="heading mx-3">{{ $t("sai_10") }}</h2>
          <v-layout wrap>
            <v-flex xs12 sm4>
              <v-img class="great-imgs mb-4" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_great_1.svg"></v-img>
              <h3 class="mb-3">{{ $t("sai_11") }}</h3>
              <p>{{ $t("sai_12") }}</p>
            </v-flex>
            <v-flex xs12 sm4>
              <v-img class="great-imgs mb-4" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_great_2.svg"></v-img>
              <h3 class="mb-3">{{ $t("sai_13") }}</h3>
              <p><span v-html='$t("sai_14")'></span></p>
            </v-flex>
            <v-flex xs12 sm4>
              <v-img class="great-imgs mb-4" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_great_3.svg"></v-img>
              <h3 class="mb-3">{{ $t("sai_15") }}</h3>
              <p>{{ $t("sai_16") }}</p>
            </v-flex>
          </v-layout>
        </div>
      </div>

      <div class="sc-present pink lighten-5">

        <h2 class="heading pink--text mx-3">
          <v-img class="heading-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/gift-box.svg"></v-img>
          <span>{{ $t("sai_17") }}</span>
        </h2>

        <v-layout justify-center align-center class="face-header mx-3">
          <v-img class="face-img elevation-2 mr-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp"></v-img>
          <div class="balloon balloon--left_top white">
            <p class="sc-speech pink--text mb-0">{{ $t("sai_18a") }}<br class="display-pc">
              {{ $t("sai_18b") }}</p>
          </div>
        </v-layout>

        <v-container
          :grid-list-xl="$vuetify.breakpoint.smAndUp"
          :grid-list-md="$vuetify.breakpoint.xs"
          class="presents-container">
          <v-layout wrap>
            <v-flex v-for="(present, i) in presents" class="sc-present__items" :key=i>
              <v-img :src="present" :lazy-src="present" class="item-img elevation-2" alt="lorem" width="100%" height="100%" v-ripple="{ class: `white--text text--accent-1` }" @click="openDownload(present)"></v-img>
            </v-flex>
          </v-layout>
        </v-container>

        <please-draw-btn class="draw-btn my-5" :btnText='$t("sai_2")'></please-draw-btn>
        <v-img class="sc-bg-splash" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-bg-splash"></v-img>
        <download-modal :showDialog="showDl" @onClickDownload="download_present"  :selectedImgSrc="dlSrc" @close="showDl = false"></download-modal>
      </div>

      <div class="sc-tech px-3">
        <div class="sc-tech-container">
          <h2 class="heading mx-3">{{ $t("sai_19") }}</h2>
          <p class="subheading mb-5 text-xs-left">
            <span v-html='$t("sai_20")'></span>
          </p>
          <v-img class="sc-tech-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_1.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_1.jpg" width="100%" height="100%"></v-img>
          <div class="text-xs-left">
            <h4><font-awesome-icon icon="circle" class="mr-1 caption"/>{{ $t("sai_21") }}</h4>
            <p class="mb-4 mx-3">
              <span v-html='$t("sai_22")'></span>
            </p>
            <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_2.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_2.webp" width="100%" height="100%"></v-img>
            <h4><font-awesome-icon icon="circle" class="mr-1 caption"/>{{ $t("sai_23") }}</h4>
            <p class="mb-4 mx-3">
              <span v-html='$t("sai_24")'></span>
            </p>
            <v-container
              :grid-list-md="$vuetify.breakpoint.smAndUp"
              :grid-list-xs="$vuetify.breakpoint.xs">
              <v-layout wrap>
                <v-flex v-for="n in 24" :key="n" class="sc-tech3__items">
                  <v-img class="sc-tech-img" :src="`https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_3/${n}.webp`" :lazy-src="`https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_3/${n}.webp`"></v-img>
                </v-flex>
              </v-layout>
            </v-container>
            <h4><font-awesome-icon icon="circle" class="mr-1 caption"/>{{ $t("sai_25") }}</h4>
            <p class="mb-4 mx-3">
              <span v-html='$t("sai_26")'></span>
            </p>
            <v-img class="sc-tech-img mb-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_4_a.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_4_a.webp"></v-img>
            <v-img class="sc-tech-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_4_b.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_tech_4_b.webp"></v-img>
            <h4><font-awesome-icon icon="circle" class="mr-1 caption"/>{{ $t("sai_27") }}</h4>
            <p class="mb-4 ma-0 mx-3">
              <span v-html='$t("sai_28")'></span>
            </p>
            <p class="text-xs-center mb-4">{{ $t("sai_29") }}</p>
          </div>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="sc-example indigo--text text--darken-4">
        <div class="sc-example-contaienr">
          <h2 class="heading">{{ $t("sai_31") }}</h2>
          <v-container grid-list-xl text-center class="text-xs-left">
            <v-layout wrap class="mt-5">
              <v-flex sm4 offset-md0 xs12>
                <v-img class="sc-example__img elevation-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg1.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg1.jpg"></v-img>
              </v-flex>
              <v-flex sm8 xs12>
                <h3 class="sc-example__title">{{ $t("sai_32") }}</h3>
                <p class="sc-example__prph grey--text text--darken-4">
                  <span v-html='$t("sai_33")'></span>
                </p>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-5">
              <v-flex sm4 offset-md0 xs12>
                <v-img class="sc-example__img elevation-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg2.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg2.jpg"></v-img>
              </v-flex>
              <v-flex sm8 xs12>
                <h3 class="sc-example__title">{{ $t("sai_34") }}</h3>
                <p class="sc-example__prph grey--text text--darken-4">
                  <span v-html='$t("sai_35")'></span>
                </p>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-5">
              <v-flex sm4 offset-md0 xs12>
                <v-img class="sc-example__img elevation-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg3.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg3.jpg"></v-img>
              </v-flex>
              <v-flex sm8 xs12>
                <h3 class="sc-example__title">{{ $t("sai_36") }}</h3>
                <p class="sc-example__prph grey--text text--darken-4">
                  <span v-html='$t("sai_37a")'></span>
                  <router-link to="/#g-form">{{ $t("sai_37b") }}</router-link>
                  <span v-html='$t("sai_37c")'></span>
                </p>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-5">
              <v-flex sm4 offset-md0 xs12>
                <v-img class="sc-example__img elevation-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg4.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg4.jpg"></v-img>
              </v-flex>
              <v-flex sm8 xs12>
                <h3 class="sc-example__title">{{ $t("sai_38") }}</h3>
                <p class="sc-example__prph grey--text text--darken-4">
                  <span v-html='$t("sai_39")'></span>
                </p>
              </v-flex>
            </v-layout>
            <v-layout wrap class="mt-5">
              <v-flex sm4 offset-md0 xs12>
                <v-img class="sc-example__img elevation-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg5.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-example/sc_eg5.webp"></v-img>
              </v-flex>
              <v-flex sm8 xs12>
                <h3 class="sc-example__title">{{ $t("sai_40") }}</h3>
                <p class="sc-example__prph grey--text text--darken-4">
                  <span v-html='$t("sai_41")'></span>
                </p>
              </v-flex>
            </v-layout>
          </v-container>
        </div>
      </div>

      <div class="sc-api">
        <div class="sc-api-container">
          <h2 class="heading">{{ $t("sai_42") }}</h2>
          <v-container grid-list-xs text-center class="text-xs-left">
            <v-layout wrap class="mt-5">
              <v-flex sm6 xs12>
                <v-img class="sc-api-img text-xs-center" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-api.svg">
                  <h4 class="sc-api-request">{{ $t("sai_43a") }}</h4>
                  <h5 class="sc-api-service text-xs-left">{{ $t("sai_43b") }}</h5>
                  <h5 class="sc-api-saiapi text-xs-right">{{ $t("sai_43c") }}</h5>
                  <h4 class="sc-api-send">{{ $t("sai_43d") }}</h4>
                </v-img>
              </v-flex>
              <v-flex sm5 offset-sm1 xs12>
                <p class="sc-api-description grey--text text--darken-4">
                  {{ $t("sai_44") }}
                </p>
              </v-flex>
            </v-layout>
          </v-container>
          <v-btn color="blue lighten-1" to="/#g-form" dark class="button btn-inquiry no-deco text-none">
            <font-awesome-icon icon="chevron-right" class="mr-2"/>
            {{ $t("sai_45") }}
          </v-btn>
        </div>
      </div>

      <div class="sc-possibility">
        <h2 class="heading">{{ $t("sai_46") }}</h2>
        <p class="px-3"><span v-html='$t("sai_47")'></span></p>
        <v-layout align-center justify-center fill-height class="px-3">
          <v-img class="sc-possibility-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_1.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_1.webp"></v-img>
          <v-img class="sc-possibility-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_2.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_2.webp"></v-img>
          <v-img class="sc-possibility-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_3.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_3.webp"></v-img>
        </v-layout>
        <v-layout align-center justify-center fill-heigh class="px-3">
          <v-img class="sc-possibility-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_4.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_4.webp"></v-img>
          <v-img class="sc-possibility-img elevation-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_5.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc-possiblity/sc_psbl_5.webp"></v-img>
        </v-layout>
        <v-btn to="/#g-form" dark class="button btn-inquiry no-deco text-none">
          <font-awesome-icon icon="chevron-right" class="mr-2"/>
          {{ $t("sai_48") }}
        </v-btn>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="faq-container">
        <h3>Q&A</h3>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("sai_49") }}</h4>
          <p>
            <span class="faq-a">A.</span>
            <span v-html='$t("sai_50")'></span>
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("sai_51") }}</h4>
          <p>
            <span class="faq-a">A.</span>
            <span v-html='$t("sai_52")'></span>
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("sai_53") }}</h4>
          <p>
            <span class="faq-a">A.</span>
            <span v-html='$t("sai_54")'></span>
          </p>
        </div>
        <div class="qa-container">
          <h4><span>Q.</span>{{ $t("sai_55") }}</h4>
          <p>
            <span class="faq-a">A.</span>
            <span v-html='$t("sai_56a")'></span>
            <span v-html='$t("sai_56b")'></span>
          </p>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <!-- 他のクリエイティブなAIを利用する -->
      <services></services>
    </div>

    <!-- MobileでのDL前に注意Dialogを表示 -->
    <v-dialog v-model="showConfirmDialogForMobile" persistent width="350">
      <v-card>
        <v-card-title class="title lighten-2 px-5" primary-title>{{ $t("glb_100") }}</v-card-title>
        <v-card-text>{{ $t("glb_102") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" flat @click="navigateToImageTabForMobile" class="mx-3 my-2">{{ $t("glb_1") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import ServiceHeader from '@/components/ServiceHeader.vue'
import Services from '@/components/Services.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import PleaseDrawBtn from './PleaseDrawBtn.vue'
import DownloadModal from './DownloadModal.vue'
import {isMobile} from '@/views/util'
import SaiScrollMagic from './SaiScrollMagic.vue'
import {API_BASE_URL} from '../../settings'
export default {
  name: 'SaiChanTop',
  props: ['auth'],
  components: {
    ServiceHeader,
    Services,
    AdResponsive,
    PleaseDrawBtn,
    DownloadModal,
    SaiScrollMagic
  },
  data() {
    return {
      isSP: window.innerWidth < 768, // iPad未満
      isUpping:false,
      prePoint: 0,
      scrollPoint: 0,
      showUploadModal: false,
      droppedFiles: [],
      showDl: false,
      dlSrc: "",
      presents: false,
      slidePage: 0,
      slides: [
        { title: 'sai_3', pic: 'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_slides/sc_slide_1.webp' },
        { title: 'sai_4', pic: 'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_slides/sc_slide_2.webp' },
        { title: 'sai_5', pic: 'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_slides/sc_slide_3.webp' },
        { title: 'sai_6', pic: 'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_slides/sc_slide_4.webp' },
      ],
      showConfirmDialogForMobile: false,
    }
  },
  methods: {
    openDownload: function (pic) {
      this.dlSrc = pic;
      this.showDl = true;
    },
    repeatEgId: function (id, n, skip=1) {
      var new_num = parseInt(id.split("_")[1], 10) + n*skip;
      var new_id = eval('this.$t("sai_' + new_num + '")');
      return new_id
    },

    download_present: function () {
      // Mobileは別タブで画像を開き、画像長押しでDLしてもらう
      if (isMobile(navigator.userAgent)) {
        this.showConfirmDialogForMobile = true
        return
      }
      // PCは自動でDL
      const objURL = this.dlSrc
      const dlLink = document.createElement('a')
      dlLink.href = objURL
      dlLink.download = objURL
      dlLink.click()
      window.URL.revokeObjectURL(objURL)
    },
    // Mobile用にダイアログ表示後別タブで画像を開く
    navigateToImageTabForMobile() {
      const link = document.createElement("a")
      link.href = this.dlSrc
      link.target = "_blank"
      link.click()
      this.showConfirmDialogForMobile = false
      this.downloading = false
    },

    present_img_data: function () {
      axios.get(`${API_BASE_URL}/sc/get_present_images`
      ).then(response => {
        this.presents = [];
        for (var i = 0; i < 15; i++) {
          this.presents.push(response.data['present_url'][i]);
        }
      })
    },

    slideTitle: function (title) {
      return eval('this.$t("' + title +'")')
    },

    // scrollMagicのリセット
    getOffset(targetID) {
      let target = document.getElementById(targetID)
      let targetRect = target.getBoundingClientRect()
      return window.pageYOffset + targetRect.top
    },
    scrollTo(targetID,offset=0) {
      window.scrollTo({
        top: this.getOffset(targetID) + offset,
        behavior: "instant"
      })
    },
    scrollReset() {
      var scrollStart = 'reset_start'
      this.scrollPoint = window.scrollY
      if (this.prePoint > window.scrollY) {
        this.isUpping = true
      } else {
        this.isUpping = false
      }
      var flag = window.scrollY < (this.getOffset(scrollStart)+200) && window.scrollY > (this.getOffset(scrollStart)) && this.isUpping
      if ( flag ) {
        if (this.isUpping) {
          this.scrollTo('reset_target', -200)
        }
      }
      this.prePoint = this.scrollPoint
    }

  },
  created() {
    this.$store.commit('screen/headerVisible', true);
    this.$store.commit('screen/footerVisible', true);

    this.present_img_data();

  },
  watch: {
    '$route': function (n) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
    }
  },
  mounted () {
    if (this.$route.hash.match(/^#/)) {
      document.getElementById(this.$route.hash.replace(/^#/, '')).scrollIntoView()
    }
    window.addEventListener('scroll', this.scrollReset)
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/scss/_variables.scss";


#sa_top {
  background-color: white;
  // variable
  $accent-color: #FF5891;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  @mixin heading-base {
    font-size: 32px;
    text-align: center;
    font-weight: 400;
    margin: 0 16px 64px 16px;

    @include mq-down {
      font-size: 28px;
      margin-bottom: 32px;
    }
  }

  @mixin container-base {
    padding-top: 128px;
    padding-bottom: 128px;

    @include mq-down {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  @mixin balloon {
    border-radius: 8px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    padding: 8px 24px;
    border-radius: 16px;
    p {
      font-size: 28px;
    }
    @include mq-down {
      padding: 8px 16px;
      border-radius: 8px;
      p {
        font-size: 18px;
      }
    }
  }

  .balloon {
    @include balloon;
    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 20px; height: 20px; /* 吹き出しサイズ */
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0; left: 0;
      width: 100%; height: 100%;
      border-radius: 16px;
      @include mq-down {
        border-radius: 8px;
      }
    }
    &>* {
      position: relative;
      z-index: 3;
      background: white;
    }
  }

  .balloon,
  .balloon::before {
    box-shadow: 0 1px 3px 0 rgba(black,0.3); /* ぼかし距離(第3引数)，拡散距離(第4引数)，色(第5引数) */
  }

  .balloon,
  .balloon::before,
  .balloon::after {
    background: white; /* 背景色 */
  }

  .balloon--top_center {
    margin-top: 32px;

    &:before {
    top: -10px; left: 50%; /* 位置 */
    transform: translateX(-50%) rotate(45deg) skew(10deg,10deg); /* 傾斜角(skew) */
    }
  }

  .balloon--left_top::before {
    left: -6px; top: 50%; /* 位置 */
    transform: translateY(-50%) rotate(45deg) skew(-12deg,-12deg); /* 傾斜角(skew) */
    @include mq-down {
      left: -2px;
    }
  }

  .draw-btn {
    z-index: 5;
  }

  .hero {
    width: 100%;
    height: 680px;
    position: relative;
    overflow: hidden;

    &::after {
      content: '';
      background-color: rgba(white,0.4);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }

    @include mq-down {
      height: 480px;
    }

    .hero-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_hero_image.jpg');
      background-size: contain;
      z-index: 1;
      @media screen and (max-width: 1360px) {
        height: 100%;
        width: initial;
        max-height: 100%;
      }
    }

    .hero-pic {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url('https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_hero_image.jpg');
      background-size: cover;
    }
  }

  .hero-container {
    max-width: 1040px;
    width: 100%;
    text-align: center;
    margin: auto;
    position: relative;
    z-index: 3;

    .face-img {
      margin-top: 96px;
      width: 100%;
      max-width: 144px;
      border-radius: 99px;
      border: solid 4px $accent-color;
      @include mq-down {
        max-width: 128px;
        max-height: 128px;
        margin-top: 0px;
      }
    }

    .sc-speech {
      font-size: 28px;
      font-weight: 500;

      @include mq-down {
        text-align: left;
      }

      @include mq-down(sm) {
        font-size: 20px;
      }
    }

    .draw-btn {
      margin-top: 128px;

      @include mq-down {
        margin-top: 48px;
      }
    }
  }

  .scroll-anime {
    background-color: white;

    .scroll-anime-container {
    max-width: 1040px;
    margin: auto;

      .sai-text {
        @include mq-down {
          line-height: 1.4;
        }
      }

      .face-img {
        width: 100%;
        max-width: 96px;
        border-radius: 99px;
        border: solid 4px $accent-color;
        @include mq-down {
          max-width: 64px;
          max-height: 64px;
        }
      }
    }

    /deep/ .v-carousel__controls {
      background: none;
    }

    .slide1 {
      height: 75%;
    }
    .slide2 {
      height: 75%;
    }
    .slide3 {
      height: 80%;
    }
    .slide4 {
      height: 80%;
    }
  }

  .sc-about {
    background-color: white;

    .sc-about-container {
      @include container-base();
      max-width: 720px;
      margin: auto;
    }

    .heading {
      @include heading-base();
    }

    p {
      font-size: 18px;

      @include mq-down {
        font-size: 16px;
      }
    }
  }

  .sc-great {
    background-color: white;

    .sc-great-container {
      max-width: 1040px;
      margin: auto;
      @include container-base;
      @include mq-down {
        padding-bottom: 32px;
      }
    }

    .heading {
      @include heading-base;
    }

    .great-imgs {
      margin: 0 auto;
      max-width: 160px;

      @include mq-down(sm) {
        max-width: 184px;
      }
    }

    h3 {
      font-size: 20px;
    }

    p {
      margin: 0 20px 62px 20px;
      font-size: 16px;
    }
  }

  .sc-present {
    padding: 64px 0;
    position: relative;

    .heading {
      font-size: 32px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 64px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 20;

      @include mq-down {
        font-size: 22px;
        text-align: left;
      }

      .heading-img {
        width: 40px;
        margin-right: 12px;
        display: inline-block;
      }
    }

    .face-header {
      position: relative;
      z-index: 20;

      .face-img {
        width: 100%;
        max-width: 72px;
        border-radius: 99px;
        border: solid 2px $accent-color;
        position: relative;
        z-index: 20;

        @include mq-down {
          max-width: 56px;
          max-height: 56px;
        }
      }

      @include mq-down {
        .balloon {
          padding: 4px 8px;
        }
      }

      .sc-speech {
        font-size: 20px;
        text-align: start;

        @include mq-down(sm) {
          font-size: 14px;
        }
      }
    }

    .presents-container {
      max-width: 768px;
    }

    .sc-present__items {
      z-index: 2;
      max-width: 20%;
      flex-basis: 20%;
      border-radius: 8px;

      @include mq-down(sm) {
        max-width: 33.3333333%;
        flex-basis: 33.3333333%;
      }
    }

    .sc-bg-splash {
      position: absolute;
      max-width: 800px;
      max-height: 800px;
      width: 80vw;
      height: 80vw;
      margin: auto;
      right: 0;
      left: 0;
      top: 0;
      bottom: 0;
      opacity: .7;

      @include mq-down {
        margin: 0 auto;
        top: initial;
        bottom: 192px;
      }
    }

    @mixin override($color:white, $mode:soft-light) {
      content: '';
      background-color: $color;
      position: absolute;
      width: 100%;
      height: 100%;
      mix-blend-mode: $mode;
      transition: .1s;
      z-index: 50;
    }

    .item-img {
      border-radius: 8px;
      border: solid 4px white;
      cursor : pointer;
      transition: .2s;
      &::after {
        @include override;
        opacity: 0;
      }

      @include mq-down {
        border-width: 2px;
      }

      &:hover {
        // position: relative;
        &::after {
          @include override;
          opacity: .5;
        }
      }
    }
  }
  .sc-tech {
    background-color: white;

    .sc-tech-container {
      @include container-base;
      max-width: 864px;
      margin: auto;
    }

    .heading {
      @include heading-base;
      margin-bottom: 64px;
    }

    .sc-tech3__items {
      max-width: 12.5%;
      flex-basis: 12.5%;
      border-radius: 8px;

      @include mq-down(sm) {
        max-width: 25%;
        flex-basis: 25%;
      }
    }

    h4 {
      margin: 0;
      font-size: 16px;
      margin-top: 48px;
      margin-bottom: 8px;
    }

    .sc-tech-img {
      border-radius: 4px;
    }
  }

  .sc-example {
    background-color: #E3F2FD;
    overflow: hidden;

    .sc-example-contaienr {
      margin: auto;
      max-width: 856px;
      @include container-base;
      padding-bottom: 96px;

      @include mq-down {
        padding-bottom: 24px;
      }
    }

    .heading {
      @include heading-base;
      margin-bottom: 16px;

      @include mq-down {
        margin-bottom: 0;
      }
    }

    .sc-example__img {
      max-height: 192px;
      border-radius: 2px;

      @include mq-down {
        max-height: 240px;
      }
    }

    .sc-example__title {
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 20px;

      @include mq-down {
        font-size: 18px;
      }
    }

    .sc-example__prph {
      font-size: 16px;

      @include mq-down {
        font-size: 14px;
      }
    }

  }

  .sc-api {
    background-color: white;

    .sc-api-container {
      @include container-base;
      margin: auto;
      max-width: 1040px;
    }

    .heading {
      @include heading-base;
      font-size: 32px;

      @include mq-down {
        font-size: 20px;
      }
    }

    .sc-api-description {
      font-size: 16px;
      line-height: 1.7;
      margin-top: 32px;

      @include mq-down {
        margin-top: 64px;
      }
    }

    @mixin illust-txt {
      position: absolute;
      z-index: 56;
    }

    .sc-api-img {
      overflow: visible;

      .sc-api-request {
        @include illust-txt;
        bottom: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        color: #2196F3;
      }
      @mixin txt-shadow {
        text-shadow: 1px 1px 0 white,
        -1px 1px 0 white,
        1px -1px 0 white,
        -1px -1px 0 white;
      }
      .sc-api-service {
        @include illust-txt;
        bottom: 72%;
        left: 3%;
        font-size: 16px;
        color: #0D47A1;
        @include mq-down(lg) {
          @include txt-shadow;
          left: -1%;
        }
      }
      .sc-api-saiapi {
        @include illust-txt;
        top: 60%;
        right: 9%;
        font-size: 16px;
        color: #880E4F;
        @include mq-down(lg) {
          @include txt-shadow;
            right: 3%;
        }
      }
      .sc-api-send {
        @include illust-txt;
        top: 98%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 18px;
        color: #EC407A;
      }
    }
  }
  .btn-inquiry {
    font-size: 24px;
    padding: 0px 32px !important;
    min-height: 56px;
    margin-top: 32px;
    margin-bottom: 32px;
    font-weight: 400;
    @include mq-down {
      margin-top: 24px;
      max-width: 96vw;
      font-size: 18px;
      min-height: 48px;
      max-width: 94vw;
    }
  }

  .faq-container {
    max-width: 1040px;
    margin: auto;
    h3 {
      font-size: 32px;
      font-weight: 400;
    }
    .qa-container {
      max-width: 720px;
      text-align: left;
      margin: 64px auto 0px auto;
      @include mq-down {
        margin: 64px 16px 0px 16px;
      }
      h4 {
        margin-bottom: 0px;
        span {
          margin-right: 4px;
          font-size: 28px;
          font-weight: 500;
          font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
        font-size: 20px;
        font-weight: 500;
        color: #234EB5;
      }
      p {
        font-size: 16px;
        .faq-a {
          color: #E91E63;
          margin-right: 4px;
          font-size: 28px;
          font-weight: 500;
          font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }
    }
  }

  .sc-possibility {
    @include container-base;
    max-width: 720px;
    margin: auto;

    .heading {
      @include heading-base();
    }

    p {
      font-size: 18px;
    }

    .sc-possibility-img {
      max-width: 128px;
      border-radius: 50%;
      margin: 16px;

      @include mq-down {
        margin: 2px;
        max-width: 28vw;
      }
    }

    .btn-inquiry {
      background-color: #FF908A;

      @include mq-down(sm) {
        font-size: 16px;
      }
    }
  }
}
</style>
<i18n src="@/locales/sai_chan/top.json"></i18n>
