<template>
  <div>
    <v-btn dark class="button btn-cv text-none" @click.stop="dialog = true">
      {{ btnText }}
    </v-btn>
    <v-dialog v-model="dialog" max-width="896">
      <v-card>
        <v-layout row wrap justify-space-around fill-height :class="{'pa-3': $vuetify.breakpoint.smAndUp}">
          <v-flex md6>
            <router-link to="/pr">
              <div class="pr-container pa-4" v-ripple="{ class: `blue--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/pr_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_1a") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_1b") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_3") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_5") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="indigo" round outline block to="/pr">
                    {{ $t("cvb_1a") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/fr">
              <div class="fr-container pa-4" v-ripple="{ class: `orange--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/fr/fr_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_35") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_36") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_37") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_38") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#FFA221" round outline block to="/fr">
                    {{ $t("cvb_35") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/sc">
              <div class="sc-container pa-4" v-ripple="{ class: `red--text text--lighten-3` }">
                <div class="icon-container sai-chan shd-m mx-auto mb-3 mt-3">
                  <img width="40" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_2a") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_2b") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_4") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_6") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="pink" round outline block to="/sc">
                    {{ $t("cvb_2a") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/pd">
              <div class="pd-container pa-4" v-ripple="{ class: `blue--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/pd/icon/pd_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_23") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_24") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_25") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_26") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#00549A" round outline block to="/pd">
                    {{ $t("cvb_23") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/mpm">
              <div class="mpm-container pa-4" v-ripple="{ class: `green--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/icon/mpm_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_11") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_12") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_13") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_14") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#007A4B" round outline block to="/mpm">
                    {{ $t("cvb_11") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/lid">
              <div class="lid-container pa-4" v-ripple="{ class: `light-blue--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/lid/icon/lid_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_15") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_16") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_17") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_18") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#26B3CC" round outline block to="/lid">
                    {{ $t("cvb_15") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <a :href='$t("glb_animesr_link")' target="_blank" rel="noopener">
              <div class="animesr-container pa-4" v-ripple="{ class: `blue--text text--lighten-3` }">
                <div class="free-balloon">
                  <div class="icon-container animesr shd-m mx-auto mb-3 mt-3">
                    <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/animesr/animesr_logo.webp">
                  </div>
                  <p class="homecvbtn animesr">＼Free／</p>
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_39") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_40") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_41") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_42") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#1E5EBA" round outline block :href='$t("glb_animesr_link")' target="_blank" rel="noopener">
                    {{ $t("cvb_39") }}
                  </v-btn>
                </div>
              </div>
            </a>
          </v-flex>
          <v-flex md6>
            <router-link to="/ep">
              <div class="ep-container pa-4" v-ripple="{ class: `orange--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img width="40" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_7") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_8") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_9") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_10") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#FF8538" round outline block to="/ep">
                    {{ $t("cvb_7") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/aap">
              <div class="aap-container pa-4" v-ripple="{ class: `purple--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/aap/aap_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_27") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_28") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_29") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_30") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#8F25C7" round outline block to="/aap">
                    {{ $t("cvb_27") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/ps">
              <div class="ps-container pa-4" v-ripple="{ class: `pink--text text--lighten-3` }">
                <div class="free-balloon">
                  <div class="icon-container shd-m mx-auto mb-3 mt-3">
                    <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/ps/ps_logo.svg">
                  </div>
                  <p class="homecvbtn ps">＼Free／</p>
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_31") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_32") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_33") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_34") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#FF5298" round outline block to="/ps">
                    {{ $t("cvb_31") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <v-flex md6>
            <router-link to="/mp">
              <div class="mp-container pa-4" v-ripple="{ class: `light-blue--text text--lighten-3` }">
                <div class="icon-container shd-m mx-auto mb-3 mt-3">
                  <img src="https://storage.googleapis.com/cre8tiveai_public_files/img/mp/icon/mp_logo.svg">
                </div>
                <h2 class="title display-pc">
                  {{ $t("cvb_19") }}
                  <span v-if='$i18n.locale == "ja"'>{{ $t("cvb_20") }}</span>
                </h2>
                <h2 class="title-sp display-sp">{{ $t("cvb_21") }}</h2>
                <v-card-text class="display-pc">
                  {{ $t("cvb_22") }}
                </v-card-text>
                <div class="px-3 display-sp">
                  <v-btn class="text-capitalize subheading" color="#0285D1" round outline block to="/mp">
                    {{ $t("cvb_19") }}
                  </v-btn>
                </div>
              </div>
            </router-link>
          </v-flex>
          <!-- リリース済AIが奇数の場合の調整要素 -->
          <v-flex md6 class="display-pc"></v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  props: {
    btnText: String,
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
.btn-cv {
  color: white;
  width: 448px;
  max-width: 88vw;
  white-space: normal;
  min-height: 64px;
  font-size: 28px;
  font-weight: 400;
  margin: 64px auto;
  border-radius: 99px;
  background: linear-gradient(120deg, #37CAE7, #234EB5);
  box-shadow: 0 2px 10px rgba(0,0,0,0.4) !important;
  &:hover {
    box-shadow: 1px 6px 15px rgba(0,0,0,0.4) !important;
    &::before {
      background: linear-gradient(120deg, #00E5FF, #651FFF);
      opacity: .5;
    }
  }
  @include mq-down(sm) {
    min-width: 0px;
    max-width: 448px;
    width: 88vw;
  }
}
a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
@mixin container-base {
  transition: .2s;
  height: 100%;
  border-radius: 8px;

  .icon-container {
    background-color: white;
    width: 88px;
    height: 88px;
    @include mq-down(sm) {
      width: 64px;
      height: 64px;
    }

    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .2s;
    img {
      width: 40px;
      @include mq-down(sm) {
        width: 28px;
      }
    }
    &.sai-chan {
      border: solid 3px #FF5891;
      box-sizing: content-box;
      img {
        vertical-align: top;
        border-radius: 99px;
        width: 100%;
      }
    }

  }
  h2 {
    &.title-sp {
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
}
.pr-container {
  @include container-base;
  &:hover {
    background-color: #E3F2FD;
  }
  h2 {
    color: #0D47A1;
  }
}
.sc-container {
  @include container-base;
  &:hover {
    background-color: #FCE4EC;
  }
  .icon-container {
    &.sai-chan {
      border: solid 3px #FF5891;
      box-sizing: content-box;
      img {
        vertical-align: top;
        border-radius: 99px;
        width: 100%;
      }
    }
  }
  h2 {
    color: #D81B60;
  }
}
.ep-container {
  @include container-base;
  &:hover {
    background-color: rgba(255, 133, 56, 0.15);  // #FF8538
  }
  h2 {
    color: #FF8538;
  }
}
.mpm-container {
  @include container-base;
  &:hover {
    background-color: rgba(105, 255, 123, 0.15);  // #A6FF7B
  }
  .icon-container {
    img {
      width: 56px;
      @include mq-down(sm) {
        width: 36px;
      }
    }
  }
  h2 {
    color: #007A4B;
  }
}
.lid-container {
  @include container-base;
  &:hover {
    background-color: rgba(177, 240, 255, 0.3);  // #B1F0FF
  }
  .icon-container {
    img {
      width: 56px;
      @include mq-down(sm) {
        width: 36px;
      }
    }
  }
  h2 {
    color: #26B3CC;
  }
}
.mp-container {
  @include container-base;
  &:hover {
    background-color: rgba(2, 133, 209, 0.15);  // #0285D1
  }
  .icon-container {
    img {
      width: 34px;
      @include mq-down(sm) {
        width: 22px;
      }
    }
  }
  h2 {
    color: #0285D1;
  }
}
.pd-container {
  @include container-base;
  &:hover {
    background-color: rgba(0, 176, 255, 0.15);  // #00B0FF
  }
  h2 {
    color: #00549A;
  }
}
.aap-container {
  @include container-base;
  &:hover {
    background-color: rgba(255, 124, 190, 0.15);  // #FF7CBE
  }
  h2 {
    color: #8F25C7;
  }
  .icon-container {
    img {
      width: 52px;
      @include mq-down(sm) {
        width: 35px;
      }
    }
  }
}
.ps-container {
  @include container-base;
  &:hover {
    background-color: rgba(255, 82, 152, 0.15);  // #FF5298
  }
  h2 {
    color: #FF5298;
  }
  .icon-container {
    img {
      width: 83px;
      @include mq-down(sm) {
        width: 61px;
      }
    }
  }
}
.fr-container {
  @include container-base;
  &:hover {
    background-color: rgba(255, 162, 33, 0.15);  // #FFA221
  }
  h2 {
    color: #FFA221;
  }
  .icon-container {
    img {
      width: 76px;
      @include mq-down(sm) {
        width: 55px;
      }
    }
  }
}
.animesr-container {
  @include container-base;
  &:hover {
    background-color: rgba(201, 223, 255, 1);  // #C9DFFF
  }
  .icon-container {
    &.animesr {
      border: solid 3px #1E5EBA;
      box-sizing: content-box;
      img {
        vertical-align: top;
        border-radius: 99px;
        width: 95%;
        margin-top: 10px;
        margin-left: 2px;
      }
    }
  }
  h2 {
    color: #1E5EBA;
  }
}
.coming-soon-container {
  margin-top: 64px;
  font-size: 24px;
  color: #808080;
}
</style>

<i18n src="@/locales/components/home_cv_button.json"></i18n>
