<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t('glb_scp_6') }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t('glb_scp_7') }}</h3>

      <div class="select-container">
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_8') }}</p>
          <div class="selection-item" for="select1" @click="select(CLOTH_TYPE.FANTASY)">
            <v-img class="cloth-image-1" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp"></v-img>
          </div>
        </div>
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_9') }}</p>
          <div class="selection-item" for="select2" @click="select(CLOTH_TYPE.UNIFORM)">
            <v-img class="cloth-image-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_jk.webp"></v-img>
          </div>
        </div>
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_10') }}</p>
          <div class="selection-text-container" @click="select(CLOTH_TYPE.OTHER)">
            <p class="text-comment">{{ $t('glb_scp_11') }}：</p>
            <v-textarea
              class="selection-text-area"
              v-model="text"
              :rules="[textFieldRules.counter]"
              :label="$t('glb_scp_12')"
              height="180"
              solo
              counter
              auto-grow
              maxlength="50"
            >
            </v-textarea>
            <v-btn v-show="text!=''" class="next-button" flat @click="next()">
              <div class="next-container text-none">
                <v-img contain class="next-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/next-arrow.svg"></v-img>
                <p>next</p>
              </div>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="back-button-container">
        <v-btn class="back-button" flat @click="back()">
          <div class="back-container text-none">
            <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
            <p>back</p>
          </div>
        </v-btn>
      </div>
      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedClothType: this.$store.state.saichanPlus.clothType,
      text: this.$store.state.saichanPlus.clothText,
      textFieldRules: {
        counter: value => value.length <= 80 || this.$t('glb_scp_9', [80])
      },
      CLOTH_TYPE: this.$store.state.saichanPlus.CLOTH_TYPE
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    next() {
      this.$emit('clothOrderEdited', {type: this.selectedClothType, text: this.text})
    },
    close() {
      this.$emit('close');
    },
    select(selected) {
      this.selectedClothType = selected

      if (this.selectedClothType !== this.CLOTH_TYPE.OTHER) {
        this.text = ""
        this.$emit('clothOrderEdited', {type: this.selectedClothType, text: this.text})
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_sc-plus-form.scss";

.form-heading {
  margin-top: 20px;
  margin-bottom: 40px;

  @include mq-down {
    margin: 32px 0 24px;
  }
}

.form-card-container {
  padding: 0 96px;

  @include mq-down {
    padding: 0 16px;
  }
}

.select-container {
  @include mq-down {
    align-items: center;
  }

  .selection-item-container {
    display: flex;
    flex-direction: column;
    width: 180px;
    @include mq-down(lg) {
      width: 160px;
    }
    @include mq-down {
      align-items: center;
      width: calc(100vw - 128px);
      margin-bottom: 16px;
    }

    .selection-label {
      font-size: 18px;
      margin-bottom: 10px;
      @include mq-down {
        margin-bottom: 4px;
      }
    }
    .selection-item {
      border-radius: 8px;
      max-height: 180px;
      background-color: white;
      @include mq-down {
        max-height: 240px;
        height: calc(100vw - 156px);
        max-width: 240px;
        width: calc(100vw - 156px);
      }
      @include mq-down(sm) {
        height: calc(100vw - 156px);
      }

      .cloth-image-1 {
        position: relative;
        top: -4%;
        left: -5%;
        width: 97%;
        @include mq-down {
          width: 100%;
          top: -13%;
          left: -5%;
        }
      }

      .cloth-image-2 {
        position: relative;
        top: 2%;
        left: -5%;
        width: 84%;
        transform: rotate(45deg);
        @include mq-down(md) {
          transform: rotate(45deg) translate(-50%, -50%);
          width: 100%;
          left: -20%;
          top: 72%;
        }
      }
    }
  }
}

.selection-text-container {
  position: relative;
  @include mq-down {
    height: 240px;
    max-width: 240px;
    width: calc(100vw - 156px);
  }

  .text-comment {
    position: absolute;
    top: 4px;
    left: 8px;
    z-index: 5;
  }

  .selection-text-area {
    font-size: 16px;
    
    /deep/ .v-input__slot {
      box-shadow: none !important;
      border-radius: 8px !important;
      border: solid transparent 4px;
      transition: .2s !important;

      &:hover, &:active, &:focus {
        border-color: #00B1FF;
        cursor: pointer;
      }
    }

    /deep/ .v-text-field__slot textarea {
      padding-top: 0 !important;
      font-size: 12px;
      line-height: 1.5;
    }

    /deep/ .v-label {
      font-size: 12px;
      height: 100%;
      top: 0px !important;
      white-space: pre-wrap;
      line-height: 1.5;
    }
  }
  /deep/ .v-textarea.v-text-field--enclosed.v-text-field--single-line textarea {
    margin-top: 0;
  }
}

.next-button {
  position: relative;
  top: -120px;
  left: 0;
  padding: 8px 4px 0;
  height: initial;
  width: initial;

  .next-container {
    @include franklin_font;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    transform-origin: 50% 50%; 

    .next-button-image {
      width: 56px;
      margin-bottom: 8px;
      @include moving_image;
    }
  }
}

.slick-dot-2 {
  background-color: #8A8A8A;
}


</style>