import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=78f635df&scoped=true&"
import script from "./AppFooter.vue?vue&type=script&lang=js&"
export * from "./AppFooter.vue?vue&type=script&lang=js&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=78f635df&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f635df",
  null
  
)

/* custom blocks */
import block0 from "@/locales/components/app_footer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcreative_ai_front%2Fapp%2Fsrc%2Fcomponents%2FAppFooter.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports