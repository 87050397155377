<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t('glb_scp_24') }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t('glb_scp_25') }}</h3>
      <h4 class="form-subheading">{{ $t('glb_scp_26') }}</h4>

      <div class="select-container">
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_27') }}</p>
          <div class="selection-item" for="select1" @click="select(true)">
            <p class="selection-text-1">
              <span v-html="$t('glb_scp_30')"></span>
            </p>
            <p class="selection-text-2">
              {{ $t('glb_scp_31') }}
            </p>
          </div>
        </div>

        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_29') }}</p>
          <!-- ToDo CUTE≠決めポーズなので、調整したいところ -->
          <div class="selection-item" for="select2" @click="select(false)">
            <p class="selection-text-1">
              <span v-html="$t('glb_scp_28')"></span>
            </p>
            <p class="selection-text-2">
              {{ $t('glb_scp_31') }}
            </p>
          </div>
        </div>

        <div class="back-button-container">
          <v-btn class="back-button" flat @click="back()">
            <div class="back-container text-none">
              <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
              <p>back</p>
            </div>
          </v-btn>
        </div>
      </div>

      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`"></div>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      willOrderHighResolution: this.$store.state.saichanPlus.orderHighResolution,
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    close() {
      this.$emit('close');
    },
    select(val) {
      this.willOrderHighResolution = val
      this.$emit('highResolutionOrderEdited', this.willOrderHighResolution)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_sc-plus-form.scss";

.form-heading {
  margin-top: 20px;
  margin-bottom: 0px;
}

.form-subheading {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 32px;
}

.select-container {
  max-width: 440px;

  .selection-item-container {
    display: flex;
    flex-direction: column;
    width: 164px;
    align-items: stretch;
    @include mq-down {
      width: initial;
      margin: 0 16px;
      margin-bottom: 24px;
    }

    .selection-label {
      font-size: 18px;
      margin-bottom: 10px;
      @include mq-down {
        margin-bottom: 4px;
      }
    }
    .selection-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 164px;
      border-radius: 8px;
      background-color: white;
      
      .selection-text-1 {
        font-size: 18px;
        font-weight: 400;
      }

      .selection-text-2 {
        @extend .selection-text-1;
        margin-top: 12px;
      }
    }
  }
}

.slick-dot-5 {
  background-color: #8A8A8A;
}
</style>