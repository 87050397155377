<template v-if=toId>
<v-btn dark class="button btn-cv text-none" :class="btnClass" :href="toLink">
  <span  class="aar-btn">
    {{ btnText }}
  </span>
</v-btn>
</template>
<template v-else>
<v-btn dark class="button btn-cv text-none" :class="btnClass" :to="toLink">
  <span  class="aar-btn">
    {{ btnText }}
  </span>
</v-btn>
</template>

<script>
export default {
  props: {
    btnText: String,
    btnClass: {
      type: String,
      default: 'btn-cv-aar'
    },
    toLink: {
      type: String,
      default: '/aar'
    },
  },
  computed: {
    toId() {
      return this.toLink.match(/^#/);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.btn-cv-aar {
  width: 425px;
  height: 100px;
  max-width: 88vw;
  white-space: normal;
  margin: 24px auto 64px auto;
  border-radius: 99px;
  background:
    linear-gradient(230deg, rgba(0, 28, 121, .25), 70%, rgba(127, 255, 184, .5)),
    linear-gradient(30deg, #0028AD 30%, #EEAEFE);
  box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .25) !important;
  &:hover {
    box-shadow: inset 0 -2px 0 rgba(255, 255, 255, .5) !important;
    &::before {
      background:
        linear-gradient(220deg, rgba(0, 39, 168, .25), 70%, rgba(173, 255, 208, .5)),
        linear-gradient(30deg, #0033DB 30%, #F7DBFE);
      opacity: .5;
    }
  }
  @include mq-down(lg) {
    height: 74px;
    margin: 12px auto 24px auto;
    min-width: 0px;
    max-width: 380px;
    width: 65vw;
  }
  @include vf-mq-down(sm) {
    height: 64px;
    font-size: 18px;
    min-width: 0px;
    max-width: 380px;
    width: 75vw;
  }
  /deep/ .v-btn__content {
    width: 100%;
  }
}
.btn-cv-pr {
  @extend .btn-cv-aar;
  width: 475px;
  @include mq-down(lg) {
    height: 74px;
    margin: 12px auto 24px auto;
    width: 65vw;
  }
  @include vf-mq-down(sm) {
    height: 82px;
    margin: 12px auto 24px auto;
    width: 88vw;
  }
}
.aar-btn {
  color: white;
  font-size: 30px;
  font-weight: 400;
  @include mq-down(lg) {
    font-size: 22px;
  }
  @include vf-mq-down(sm) {
    font-size: 18px;
  }
}
a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
</style>
