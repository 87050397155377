<template>
  <div id="app">
    <v-app>
      <app-header/>
      <router-view :auth="auth"/>
      <app-footer/>
      <!-- 汎用エラーモーダル -->
      <v-dialog v-model="showError" persistent width="350">
        <v-card>
          <v-card-title class="title lighten-2 px-5" primary-title>
            {{ $t("err_1") }}
          </v-card-title>
          <v-card-text class="error-modal">
            <span v-html="errorLocalizeId ? $t(errorLocalizeId) : errorMessage"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" flat @click="routeToNext" class="mx-3 my-2">
              {{ $t("err_2")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ゲストユーザー上限通知 -->
      <v-dialog v-model="overGuestLimit" width="350">
        <v-card>
          <v-card-title class="title lighten-2 px-5" primary-title>
            {{ $t("glb_guest_1") }}
          </v-card-title>
          <v-card-text class="error-modal">
            <span v-html="errorLocalizeId ? $t(errorLocalizeId) : errorMessage"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue" flat @click="auth.login('account')" class="mx-3 my-2">
              {{ $t("glb_guest_3")}}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import AuthService from '@/auth/AuthService'
import { setAuthDefaultHeader, setAuthGuestHeader, deleteAuthDefaultHeader, deleteAuthGuestHeader } from '@/requests'
const auth = new AuthService()
const { authNotifier } = auth

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter
  },
  data () {
    return {
      auth,
      // v-dialogは v-modelとして必ずdataを指定しないといけない？ので、
      // 回りくどいが store.state.screen.showErrorをwatchしてshowErrorに反映させる
      showError: false,
      overGuestLimit: false,
    }
  },
  computed: {
    errorMessage() {
      return this.$store.state.screen.errorMessage
    },
    errorLocalizeId() {
      return this.$store.state.screen.errorLocalizeId
    },
    storeShowError() {
      return this.$store.state.screen.showError
    }
  },
  methods: {
    routeToNext() {
      location.href = `${location.protocol}//${location.host}/`
    }
  },
  watch: {
    storeShowError(newFlg) {
      if (this.errorLocalizeId === 'err_40') {
        this.overGuestLimit = newFlg
      } else {
        this.showError = newFlg
        if (newFlg) {
          /*global gtag*/
          gtag('event', 'exception', {
            'description': this.errorMessage
          })
        }
      }
    }
  },
  created() {
    if (this.$store.state.user.stayingServicePath === '/notfound') return
    this.$store.dispatch('master/getMasterData')
    authNotifier.on('authChange', authState => {
      if (authState && authState.accessToken) {
        setAuthDefaultHeader(authState.accessToken)
        // getUser中で呼ばれるsetUserの完了をもってアプリログインが完了したとみなす。
        // ログインが必要なページ(ex. /data, /my) では、
        // $store.state.logined$ を使って完了通知を受けてからAPIを叩く
        this.$store.dispatch('user/getUser')
          .then(() => {
            return this.$store.dispatch('user/getMembers')
          })
        deleteAuthGuestHeader()
      } else {
        // ゲストユーザー用の処理
        if (!authState.authenticated) {
          setAuthGuestHeader()
          this.$store.dispatch('user/getGuestUser')
        }
        deleteAuthDefaultHeader()
      }
    })
    this.auth.setSession()  // AuthService.jsでチェックしてログインしていればauthChangeがemitされてgetUser等が走る
  }
}
</script>

<style>
@import './assets/css/reboot.css';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans|Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:300,400,500,600,700,900&display=swap');

#app {
  font-family: 'Noto Sans JP', 'Source Han Sans JP', 'Noto Sans', 'Roboto', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraginoSans-W3', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: inherit;
}

.application--wrap {
  min-height: inherit !important;
}

.error-modal {
  text-align: left;
}
.error-modal .modal-caution {
  display: inline-block;
  font-size: 0.8rem;
  margin-top: 0.625rem;
}
.modal-caution > a {
  color: #2196f3;
  cursor: pointer;
}
</style>

<i18n>
{
  "ja": {
    "err_1": "エラー",
    "err_2": "トップに戻る",
    "err_10": "現在ご加入のプランでは、1000x1000のサイズまでしか対応しておりません。 より大きな画像を試したい方は2500x2500対応のプロプランをご検討ください。更に大きなサイズが必要な方はお問い合せください",
    "err_11": "現在ご加入のプランでは、2500x2500のサイズまでしか対応しておりません。 より大きな画像を試したい方はお問い合せください",
    "err_12": "画像の表示期限が過ぎましたためデータを削除しました。再度画像のアップロードを行い変換を行ってください。",
    "err_13": "購入したソフトのダウンロード期限が過ぎました。",
    "err_20": "メールの認証が完了していません。メールをご確認の上、本登録のリンクをクリックして認証を完了させてください。<br><span class='modal-caution'>※アカウントの作成を行ってもメールが届かない場合は、Google 等のソーシャルログインをお試しいただくか、<a href='https://ja.cre8tiveai.com/#g-form-area' target='_blank'>お問い合わせ</a>をお願いします。</span>",
    "err_30": "アップロードしたファイルは未対応のファイル形式です。現在、PNGか、JPG、JPEG に対応しております。",
    "err_40": "上限回数に達しました。これ以上実行するにはログインが必要です。会員登録がまだの方は新規会員登録をしてください。"
  },
  "en": {
    "err_1": "Error",
    "err_2": "Back to top",
    "err_10": "Your current plan supports until 1000x1000 image size. Please consider pro plan for bigger images.",
    "err_11": "Your current plan supports until 2500x2500 image size. Please inquire for bigger images.",
    "err_12": "The deadline for displaying the image has passed. Please re-upload the image. ",
    "err_13": "The deadline for downloading purchased software has passed.",
    "err_20": "Email address authentication is not complete.Please check your email and log in.<br><span class='modal-caution'>* If you do not receive an email after creating an account, please try social login. <br>* If you do not receive an email even after creating an account again, please <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>contact us</a>.</span>",
    "err_30": "Uploaded files are unsupported file formats. Currently, we support PNG, JPG and JPEG.",
    "err_40": "Maximum limit reached. You must login to run any more. If you are not a registered member, please register as a new member."
  },
  "fr": {
    "err_1": "Une erreur s’est produite",
    "err_2": "Revenir à la page d'accueil",
    "err_10": "Vous pouvez uploader des images jusqu’à 1000 x 1000 px avec votre plan actuel. \nUtilisez Plan Pro pour uploader des images de 2500 x 2500 px. \nPour des images plus grandes, veuillez nous contacter.",
    "err_11": "Vous pouvez uploader des images jusqu’a 2500 x 2500 px avec votre plan actuel. \nPour des images plus grandes, veuillez nous contacter.",
    "err_12": "Le délai d'affichage de l'image est dépassé. Veuillez télécharger à nouveau l'image. ",
    "err_13": "La date limite pour le téléchargement des logiciels achetés est passée.",
    "err_20": "Votre inscription n’est pas encore terminée.  Veuillez consulter votre e-mail de souscription afin de finaliser votre inscription.<br><span class='modal-caution'>* Si vous ne recevez pas d'e-mail après la création d'un compte, essayez le login social. <br>* Si vous ne recevez pas d'e-mail même après avoir créé un nouveau compte, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>zveuillez nous contacter</a>.</span>",
    "err_30": "Les fichiers téléchargés sont des formats de fichiers non pris en charge. Actuellement, nous prenons en charge les formats PNG, JPG et JPEG.",
    "err_40": "Limite maximale atteinte. Vous devez vous connecter pour continuer. Si vous n'êtes pas un membre enregistré, veuillez vous inscrire en tant que nouveau membre."
  },
  "es": {
    "err_1": "Error",
    "err_2": "Regresar a la página de inicio",
    "err_10": "Tu plan actual se corresponde sólo con el tamaño de hasta 1000 por 1000 pixeles.\n Los que quieren probar las imágenes con alta definición, consideren el plan profesional que se corresponde a 2500 por 2500, por favor. Los que necesitan el tamaño mayor, consultenos, por favor.",
    "err_11": "El plan actual se corresponde sólo con el tamaño de 2500 por 2500 pixeles.\n Los que quieren probar la imagen de mayor resolución, por favor consúltenos.",
    "err_12": "El plazo para mostrar la imagen ha pasado. Por favor, vuelva a subir la imagen. ",
    "err_13": "El plazo para descargar el software adquirido ha pasado.",
    "err_20": "La verificación de tu correo electónico no ha sido finalizada. Por favor confirma tu email y registrate.<br><span class='modal-caution'>* Si no recibe un correo electrónico después de crear una cuenta, pruebe con el inicio de sesión social. <br>* Si no recibe un correo electrónico incluso después de crear una cuenta de nuevo, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>póngase en contacto con nosotros</a>.</span>",
    "err_30": "Los archivos subidos son formatos de archivo no admitidos. Actualmente, apoyamos PNG, JPG y JPEG.",
    "err_40": "Límite máximo alcanzado. Debe iniciar sesión para ejecutar más. Si no eres un miembro registrado, por favor regístrate como nuevo miembro."
  },
  "pt": {
    "err_1": "Erro",
    "err_2": "Voltar ao topo",
    "err_10": "Seu plano atual pode ser aplicado até uma imagem de tamanho 1000 × 1000. Para uma imagem maior, verifique o Pro plan que permite até 2500 × 2500. Se precisar de mais, entre em contato conosco",
    "err_11": "Seu plano atual pode ser aplicado até uma imagem de tamanho 2500 × 2500. Se você quiser experimentar uma imagem maior, entre em contato conosco",
    "err_12": "O prazo para a exibição da imagem já passou. Por favor, carregue a imagem de novo. ",
    "err_13": "O prazo para descarregar o software adquirido já passou.",
    "err_20": "Sua autenticação de email não foi concluída. Verifique seu e-mail e faça o login novamente.<br><span class='modal-caution'>* Se não receber um e-mail depois de criar uma conta, por favor tente o login social. <br>* Se não receber um e-mail mesmo depois de criar uma conta de novo, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>por favor contacte-nos</a>.</span>",
    "err_30": "Os ficheiros carregados são formatos de ficheiro não suportados. Actualmente, suportamos PNG, JPG e JPEG.",
    "err_40": "Limite máximo atingido. Tem de iniciar sessão para executar mais. Se não é um membro registado, registe-se como um novo membro."
  },
  "de": {
    "err_1": "Fehler",
    "err_2": "Zur Startseite",
    "err_10": "Ihr derzeitig aktiver Abo-Plan unterstützt nur Größen bis zu 1000x1000.\nWenn Sie ein größeres Bild ausprobieren möchten, ziehen Sie bitte einen professionellen Plan für 2500x2500 in Betracht. Bitte kontaktieren Sie uns, falls Sie ein höheres Format benötigen.",
    "err_11": "Ihr derzeitig aktiver Abo-Plan unterstützt nur Größen bis zu 2500x2500.\nBitte kontaktieren Sie uns, falls Sie ein höheres Format benötigen.",
    "err_12": "Die Frist für die Anzeige des Bildes ist abgelaufen. Bitte laden Sie das Bild erneut hoch. ",
    "err_13": "Die Frist für das Herunterladen von gekaufter Software ist abgelaufen.",
    "err_20": "Die E-Mail-Authentifizierung ist nicht vollständig. Bitte überprüfen Sie Ihre E-Mail und melden Sie sich an.<br><span class='modal-caution'>* Wenn Sie nach dem Anlegen eines Kontos keine E-Mail erhalten, versuchen Sie es bitte mit Social Login. <br>* Wenn Sie auch nach dem erneuten Anlegen eines Kontos keine E-Mail erhalten, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>wenden Sie sich bitte an uns</a>.</span>",
    "err_30": "Hochgeladene Dateien sind nicht unterstützte Dateiformate. Derzeit unterstützen wir PNG, JPG und JPEG.",
    "err_40": "Maximales Limit erreicht. Sie müssen sich anmelden, um weitere zu starten. Wenn Sie kein registriertes Mitglied sind, registrieren Sie sich bitte als neues Mitglied."
  },
  "nl": {
    "err_1": "Fout",
    "err_2": "Terug naar boven",
    "err_10": "Uw huidige plan ondersteunt tot 1000x1000 afbeeldingsgrootte. Overweeg Pro Plan voor grotere afbeeldingen.",
    "err_11": "Uw huidige plan ondersteunt tot 2500x2500 afbeeldingsgrootte. Informeer naar grotere afbeeldingen.",
    "err_12": "De deadline voor het tonen van de afbeelding is verstreken. Gelieve de afbeelding opnieuw te uploaden. ",
    "err_13": "De termijn voor het downloaden van aangekochte software is verstreken.",
    "err_20": "E-mailadresificatie is niet compleet. Controleer uw e-mail en log in.<br><span class='modal-caution'>* Als u geen e-mail ontvangt na het aanmaken van een account, probeer dan sociaal in te loggen. <br>* Indien u ook na het opnieuw aanmaken van een account geen e-mail ontvangt, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>neem dan contact met ons op</a>.</span>",
    "err_30": "Geüploade bestanden zijn niet -ondersteunde bestandsindelingen. Momenteel ondersteunen we PNG, JPG en JPEG.",
    "err_40": "Maximale limiet bereikt. U moet inloggen om meer uit te voeren. Als u geen geregistreerd lid bent, kunt u zich registreren als een nieuw lid."
  },
  "tr": {
    "err_1": "Hata",
    "err_2": "Başa dönüş",
    "err_10": "Mevcut planınız 1000x1000 görüntü boyutuna kadar desteklenmektedir. Lütfen daha büyük görüntüler için Pro planını düşünün.",
    "err_11": "Mevcut planınız 2500x2500 görüntü boyutuna kadar desteklenmektedir. Lütfen daha büyük görüntüler için bilgi alın.",
    "err_12": "Görüntüyü görüntülemek için son tarih geçti. Lütfen görüntüyü yeniden yükleyin.",
    "err_13": "Satın alınan yazılımı indirmek için son tarih geçti.",
    "err_20": "E-posta adresi kimlik doğrulaması tamamlanmadı. Lütfen e-postanızı kontrol edin ve giriş yapın.<br><span class='modal-caution'>* Bir hesap oluşturduktan sonra bir e-posta almazsanız, lütfen sosyal girişi deneyin. <br>* Bir hesap oluşturduktan sonra bile bir e-posta almazsanız, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>lütfen bizimle iletişime geçin</a>.</span>",
    "err_30": "Yüklenen dosyalar desteklenmeyen dosya biçimleridir. Şu anda PNG, JPG ve JPEG'yi destekliyoruz.",
    "err_40": "Maksimum sınır ulaşıldı. Daha fazla çalıştırmak için giriş yapmalısınız. Kayıtlı bir üye değilseniz, lütfen yeni bir üye olarak kaydolun."
  },
  "zh-cn": {
    "err_1": "错误",
    "err_2": "回到主页",
    "err_10": "您现在加入的方案只有1000x1000的尺寸，如果需要更大尺寸2500x2500的话，请加入专业方案。还需要比2500x2500更大的尺寸，请联系我们",
    "err_11": "您现在加入的方案最大只能够2500x2500，如果需要更大的尺寸，请联系我们",
    "err_12": "显示图片的最后期限已过。请重新上传图片。",
    "err_13": "下载所购软件的最后期限已过。",
    "err_20": "您的邮件还没有验证，请查看您的邮箱以后再次登入<br><span class='modal-caution'>* 如果你在创建账户后没有收到电子邮件，请尝试社交登录。<br>*如果你在再次创建账户后仍未收到电子邮件，<a href='https://cre8tiveai.com/#g-form-area' target='_blank'>请联系我们</a>。</span>",
    "err_30": "上传的文件是不支持的文件格式。目前，我们支持PNG、JPG和JPEG。",
    "err_40": "达到最大限度。你必须登录后才能再运行。如果你不是注册会员，请注册为新会员。"
  },
  "zh-tw": {
    "err_1": "錯誤",
    "err_2": "回到主頁",
    "err_10": "您現在加入的方案只有1000x1000的尺寸，如果需要更大尺寸2500x2500的話，請加入專業方案。還需要比2500x2500更大的尺寸，請聯繫我們",
    "err_11": "您現在加入的方案最大只能夠2500x2500，如果需要更大的尺寸，請聯繫我們",
    "err_12": "顯示圖像的截止日期已經過去。請重新上傳圖像。",
    "err_13": "下載購買軟件的截止日期已經過去。",
    "err_20": "您的郵件還沒有驗證，請查看您的郵箱以後再次登入<br><span class='modal-caution'>*如果您在創建帳戶後沒有收到電子郵件，請嘗試社交登錄。 <br>*即使您再次創建帳戶後，您也沒有收到電子郵件，<a href='https://cre8tiveai.com/#g-form-area' target='_blank'>請聯繫我們</a>。</span>",
    "err_30": "上傳的文件是不支持的文件格式。目前，我們支持PNG，JPG和JPEG。",
    "err_40": "達到最大限制。您必須登錄才能運行。如果您不是註冊會員，請註冊為新成員。"
  },
  "sv": {
    "err_1": "Fel",
    "err_2": "Tillbaka till toppen",
    "err_10": "Din nuvarande plan stöder till 1000x1000 bildstorlek. Tänk på pro -plan för större bilder.",
    "err_11": "Din nuvarande plan stöder till 2500x2500 bildstorlek. Vänligen fråga efter större bilder.",
    "err_12": "Tidsfristen för att visa bilden har gått. Ladda upp bilden igen.",
    "err_13": "Tidsfristen för nedladdning av köpt programvara har gått.",
    "err_20": "E-postadress Autentisering är inte komplett. Kontrollera din e-post och logga in.<br><span class='modal-caution'>* Om du inte får något e-postmeddelande efter att ha skapat ett konto kan du försöka med social inloggning. <br>* Om du inte får ett mail även efter att du har skapat ett konto igen, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>vänligen kontakta oss</a>.</span>",
    "err_30": "Uppladdade filer är icke -stödda filformat. För närvarande stöder vi PNG, JPG och JPEG.",
    "err_40": "Maximal gräns nådd. Du måste logga in för att köra mer. Om du inte är en registrerad medlem, vänligen registrera dig som en ny medlem."
  },
  "it": {
    "err_1": "Errore",
    "err_2": "Tornare al Top.",
    "err_10": "Con il plan attuale che hai sottoscritto, supporta solo fino alla dimensione di 1.000 pixel x 1.000 pixel. Se vuoi provare con dimensioni più grandi, si prega di considerare Pro Plan che supporta fino alla dimensione di 2.500 pixel x 2.500 pixel. Se serve più grande, si prega di contattarci.",
    "err_11": "Con il plan attuale che hai sottoscritto, supporta solo fino alla dimensione di 2.500 pixel x 2.500 pixel. Se vuoi provare con un'immagine più grande, si prega di contattarci.",
    "err_12": "Il termine per la visualizzazione dell'immagine è passato. Si prega di ricaricare l'immagine. ",
    "err_13": "Il termine per scaricare il software acquistato è scaduto.",
    "err_20": "L'autenticazione email non è completa. Controlla la tua email e fai il login.<br><span class='modal-caution'>* Se non ricevi un'email dopo aver creato un account, prova il social login. <br>* Se non ricevi un'email anche dopo aver creato di nuovo un account, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>contattaci</a>.</span>",
    "err_30": "I file caricati sono formati di file non supportati. Attualmente, supportiamo PNG, JPG e JPEG.",
    "err_40": "Limite massimo raggiunto. È necessario effettuare il login per eseguire altre operazioni. Se non sei un membro registrato, registrati come nuovo membro."
  },
  "ko": {
    "err_1": "에러",
    "err_2": "홈화면으로 돌아가기",
    "err_10": "현재 가입 플랜에서는, 1000x1000의 사이즈까지만 대응하고 있습니다.  보다 큰 이미지를 사용하고 싶은 분은 2500 x2500 대응의 프로 플랜을 검토해 주십시오. 한층 더 큰 사이즈가 필요하신 분은 문의해 주세요",
    "err_11": "현재 가입의 플랜에서는, 2500x2500의 사이즈까지만 대응하고 있습니다.  보다 큰 화상을 사용하고 싶으신 분은 문의해 주세요",
    "err_12": "이미지 표시 마감일이 통과되었습니다. 이미지를 다시 업로드하십시오.",
    "err_13": "구매 한 소프트웨어 다운로드 마감일이 통과되었습니다.",
    "err_20": "이메일 인증이 완료되지 않았습니다. 이메일을 확인하신 후, 로그인을 실시해 주세요.<br><span class='modal-caution'>* 계정 생성 후 이메일을 받지 못하셨다면 소셜 로그인을 시도해 주세요. <br>* 계정을 다시 생성 한 후에도 전자 메일을받지 못하면 <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>문의하십시오</a>.</span>",
    "err_30": "업로드 된 파일은 지원되지 않은 파일 형식입니다. 현재 PNG, JPG 및 JPEG를 지원합니다.",
    "err_40": "최대 한도에 도달했습니다. 더 이상 실행하려면 로그인해야합니다. 등록 된 회원이 아닌 경우 새 회원으로 등록하십시오."
  },
  "ar": {
    "err_1": "خطأ",
    "err_2": "العودة إلى الأعلى",
    "err_10": "تدعم خطتك الحالية حتى حجم الصورة 1000x1000. يرجى النظر في خطة Pro للحصول على صور أكبر.",
    "err_11": "تدعم خطتك الحالية حتى حجم الصورة 2500 × 2500. يرجى الاستفسار عن صور أكبر.",
    "err_12": "لقد مرت الموعد النهائي لعرض الصورة. يرجى إعادة تحميل الصورة.",
    "err_13": "لقد مرت الموعد النهائي لتنزيل البرامج التي تم شراؤها.",
    "err_20": "مصادقة عنوان البريد الإلكتروني غير كاملة. يرجى التحقق من بريدك الإلكتروني وتسجيل الدخول.* إذا لم تستلم بريدًا إلكترونيًا بعد إنشاء حساب ، فيرجى تجربة تسجيل الدخول الاجتماعي. * إذا لم تتلقى رسالة بريد إلكتروني حتى بعد إنشاء حساب مرة أخرى، يرجى الاتصال بنا.",
    "err_30": "الملفات التي تم تحميلها هي تنسيقات ملفات غير مدعومة. حاليا ، نحن ندعم PNG و JPG و JPEG.",
    "err_40": "الحد الأقصى الذي تم الوصول إليه. يجب عليك تسجيل الدخول لتشغيل المزيد. إذا لم تكن عضوًا مسجلًا ، فيرجى التسجيل كعضو جديد."
  },
  "th": {
    "err_1": "เกิดความผิดพลาด",
    "err_2": "กลับสู่ด้านบน",
    "err_10": "planที่คุณใช้งานอยู่ รองรับขนาดสูงสุดอยู่ที่ 1,000x1,000 เท่านั้น หากต้องการขนาดที่ใหญ่ขึ้น กรุณาใช้ Pro plan ที่รองรับขนาด 2,500x2,500 กรุณาติดต่อเรา หากคุณต้องการขนาดที่ใหญ่ขึ้น",
    "err_11": "planที่คุณใช้งานอยู่ รองรับขนาดสูงสุดอยู่ที่ 2,500x2,500 เท่านั้น กรุณาติดต่อเรา หากคุณต้องการขนาดที่ใหญ่ขึ้น",
    "err_12": "กำหนดเวลาสำหรับการแสดงภาพได้ผ่านไปแล้ว โปรดอัปโหลดภาพใหม่อีกครั้ง",
    "err_13": "กำหนดส่งซอฟต์แวร์ที่ซื้อมาแล้ว",
    "err_20": "ยืนยันอีเมล์ไม่สมบูรณ์ กรุณาตรวจสอบอีเมล์แล้วเข้าสู่ระบบ<br><span class='modal-caution'>*หากคุณไม่ได้รับอีเมลหลังจากสร้างบัญชี โปรดลองเข้าสู่ระบบโซเชียล <br>*หากคุณไม่ได้รับอีเมลแม้หลังจากสร้างบัญชีอีกครั้งโปรดติดต่อเรา</span>",
    "err_30": "ไฟล์ที่อัปโหลดเป็นรูปแบบไฟล์ที่ไม่ได้รับการสนับสนุน ปัจจุบันเราสนับสนุน PNG, JPG และ JPEG",
    "err_40": "ถึงขีด จำกัด สูงสุด คุณต้องเข้าสู่ระบบเพื่อเรียกใช้อีกต่อไป หากคุณไม่ใช่สมาชิกที่ลงทะเบียนโปรดลงทะเบียนเป็นสมาชิกใหม่"
  },
  "id": {
    "err_1": "Kesalahan",
    "err_2": "Kembali ke atas",
    "err_10": "Rencana Anda saat ini mendukung hingga ukuran gambar 1000x1000. Harap pertimbangkan rencana pro untuk gambar yang lebih besar.",
    "err_11": "Rencana Anda saat ini mendukung hingga ukuran gambar 2500x2500. Silakan tanyakan untuk gambar yang lebih besar.",
    "err_12": "Batas waktu untuk menampilkan gambar telah berlalu. Harap unggah ulang gambar.",
    "err_13": "Batas waktu untuk mengunduh perangkat lunak yang dibeli telah berlalu.",
    "err_20": "Otentikasi alamat email tidak lengkap. Silakan periksa email Anda dan masuk.<br><span class='modal-caution'>* Jika Anda tidak menerima email setelah membuat akun, silakan coba login sosial. <br>* Jika Anda tidak menerima email bahkan setelah membuat akun lagi, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>silakan hubungi kami</a>.</span>",
    "err_30": "File yang diunggah adalah format file yang tidak didukung. Saat ini, kami mendukung PNG, JPG dan JPEG.",
    "err_40": "Batas maksimum tercapai. Anda harus masuk untuk menjalankan lagi. Jika Anda bukan anggota terdaftar, silakan mendaftar sebagai anggota baru."
  },
  "pl": {
    "err_1": "Błąd",
    "err_2": "Powrót do góry",
    "err_10": "Twój obecny plan obsługuje rozmiar obrazu 1000x1000. Rozważ Pro Plan dla większych zdjęć.",
    "err_11": "Twój obecny plan obsługuje do rozmiaru obrazu 2500x2500. Zapytaj o większe zdjęcia.",
    "err_12": "Upłynął termin wyświetlania obrazu. Prosimy o ponowne załadowanie obrazu. ",
    "err_13": "Upłynął termin pobierania zakupionego oprogramowania.",
    "err_20": "Uwierzytelnianie adresów e-mail nie jest kompletny. Sprawdź wiadomość e-mail i zaloguj się.<br><span class='modal-caution'>* Jeśli nie otrzymasz e-maila po utworzeniu konta, spróbuj zalogować się społecznie. <br>* Jeśli nie otrzymasz e-maila nawet po utworzeniu konta ponownie, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>prosimy o kontakt z nami</a>.</span>",
    "err_30": "Przesyłane pliki są nieobsługiwanymi formatami plików. Obecnie obsługujemy formaty PNG, JPG i JPEG.",
    "err_40": "Osiągnięto maksymalny limit. Musisz się zalogować, aby uruchomić kolejne. Jeśli nie jesteś zarejestrowanym użytkownikiem, zarejestruj się jako nowy użytkownik."
  },
  "vi": {
    "err_1": "Lỗi",
    "err_2": "Trở lại đầu trang",
    "err_10": "Kế hoạch hiện tại của bạn hỗ trợ cho đến kích thước hình ảnh 1000x1000. Vui lòng xem xét kế hoạch chuyên nghiệp cho hình ảnh lớn hơn.",
    "err_11": "Kế hoạch hiện tại của bạn hỗ trợ cho đến khi kích thước hình ảnh 2500x2500. Vui lòng hỏi những hình ảnh lớn hơn.",
    "err_12": "Hạn chót để hiển thị hình ảnh đã qua. Vui lòng tải lại hình ảnh.",
    "err_13": "Hạn chót để tải xuống phần mềm đã mua đã được thông qua.",
    "err_20": "Xác thực địa chỉ email không hoàn tất. Vui lòng kiểm tra email của bạn và đăng nhập.<br><span class='modal-caution'>* Nếu bạn không nhận được email sau khi tạo tài khoản, vui lòng thử đăng nhập mạng xã hội. <br>* Nếu bạn không nhận được email ngay cả sau khi tạo một tài khoản một lần nữa, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>vui lòng liên hệ với chúng tôi</a>.</span>",
    "err_30": "Các tệp được tải lên là các định dạng tệp không được hỗ trợ. Hiện tại, chúng tôi hỗ trợ PNG, JPG và JPEG.",
    "err_40": "Giới hạn tối đa đạt được. Bạn phải đăng nhập để chạy thêm. Nếu bạn không phải là thành viên đã đăng ký, vui lòng đăng ký làm thành viên mới."
  },
  "hi": {
    "err_1": "Error",
    "err_2": "Vaapas top par",
    "err_10": "Vartamaan plan keval 1000x1000 ka size tak hee laagoo hai. Un logon ke lie jo adhik prayaas karana chaahate hain, krpaya 2500x2500 ka pro plan par vichaar karen. Yadi aap bade aakaar chaahate hain to krpaya hamase sampark karen.",
    "err_11": "Vartamaan plan keval 2500x2500 ka size tak hee laagoo hai. Yadi aap bade aakaar chaahate hain to krpaya hamase sampark karen.",
    "err_12": "छवि प्रदर्शित करने की समय सीमा बीत चुकी है। कृपया छवि को फिर से अपलोड करें।",
    "err_13": "खरीदे गए सॉफ़्टवेयर को डाउनलोड करने की समय सीमा बीत चुकी है।",
    "err_20": "Email ke confirmation abhee tak nahin kee gaee ha. Krpaya apana Email jaanchane ke baad login karen.<br><span class='modal-caution'>* यदि आपको खाता बनाने के बाद कोई ईमेल प्राप्त नहीं होता है, तो कृपया सामाजिक लॉगिन का प्रयास करें। <br>* यदि आपको एक खाता बनाने के बाद भी कोई ईमेल प्राप्त नहीं होता है, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>तो कृपया हमसे संपर्क करें।</a></span>",
    "err_30": "अपलोड की गई फाइलें असमर्थित फ़ाइल प्रारूप हैं। वर्तमान में, हम PNG, JPG और JPEG का समर्थन करते हैं।",
    "err_40": "अधिकतम सीमा तक पहुंच गई। आपको किसी भी अधिक चलाने के लिए लॉगिन करना होगा। यदि आप एक पंजीकृत सदस्य नहीं हैं, तो कृपया एक नए सदस्य के रूप में पंजीकरण करें।"
  },
  "ru": {
    "err_1": "Ошибка",
    "err_2": "Вернуться к вершине",
    "err_10": "Ваш текущий план поддерживает до 1000x1000 размер изображения. Пожалуйста, рассмотрите Pro Plan для более крупных изображений.",
    "err_11": "Ваш текущий план поддерживает до 2500x2500 размер изображения. Пожалуйста, спросите на более широкие изображения.",
    "err_12": "Срок размещения изображения истек. Пожалуйста, загрузите изображение повторно. ",
    "err_13": "Срок загрузки приобретенного программного обеспечения истек.",
    "err_20": "Аутентификация адреса электронной почты не завершена. Пожалуйста, проверьте свою электронную почту и войдите в систему.<br><span class='modal-caution'>* Если после создания учетной записи вы не получили электронное письмо, попробуйте войти в систему через социальную сеть. <br>* Если вы не получили письмо даже после повторного создания аккаунта, пожалуйста, <a href='https://cre8tiveai.com/#g-form-area' target='_blank'>свяжитесь с нами</a>.</span>",
    "err_30": "Загруженные файлы не поддерживаются. В настоящее время мы поддерживаем PNG, JPG и JPEG.",
    "err_40": "Максимальный лимит достигнут. Вы должны войти в систему, чтобы выполнить дальнейшие действия. Если вы не являетесь зарегистрированным пользователем, пожалуйста, зарегистрируйтесь как новый пользователь."
  },
  "bn": {
    "err_1": "ত্রুটি",
    "err_2": "উপরে ফিরে যাও",
    "err_10": "আপনার বর্তমান পরিকল্পনা 1000x1000 চিত্রের আকার পর্যন্ত সমর্থন করে। আরও বড় চিত্রগুলির জন্য প্রো পরিকল্পনা বিবেচনা করুন।",
    "err_11": "আপনার বর্তমান পরিকল্পনা 2500x2500 চিত্রের আকার পর্যন্ত সমর্থন করে। আরও বড় চিত্রগুলির জন্য অনুসন্ধান করুন।",
    "err_12": "চিত্র প্রদর্শনের জন্য সময়সীমাটি কেটে গেছে। চিত্রটি পুনরায় আপলোড করুন।",
    "err_13": "ক্রয় করা সফ্টওয়্যার ডাউনলোড করার সময়সীমাটি কেটে গেছে।",
    "err_20": "ইমেল ঠিকানা প্রমাণীকরণ সম্পূর্ণ নয়। আপনার ইমেল চেক করুন এবং লগ ইন করুন।<br><span class='modal-caution'>* আপনি একটি অ্যাকাউন্ট তৈরি করার পরে একটি ইমেল না পেলে, সামাজিক লগইন চেষ্টা করুন. <br>* যদি আপনি আবার একটি অ্যাকাউন্ট তৈরি করার পরেও একটি ইমেল না পান তবে দয়া করে আমাদের সাথে যোগাযোগ করুন।</span>",
    "err_30": "আপলোড করা ফাইলগুলি অসমর্থিত ফাইল ফর্ম্যাট। বর্তমানে, আমরা পিএনজি, জেপিজি এবং জেপিইজি সমর্থন করি।",
    "err_40": "সর্বাধিক সীমা পৌঁছেছে। আপনাকে আরও কিছু চালানোর জন্য লগইন করতে হবে। আপনি যদি নিবন্ধিত সদস্য না হন তবে দয়া করে নতুন সদস্য হিসাবে নিবন্ধন করুন।"
  }
}
</i18n>
