<template>
  <v-dialog :value="show" max-width="900" persistent scrollable>
      <v-card class="form-card-container">
        <v-card-text class="scroll-area" id="scroll-target">
          <div class="close-button-container" v-if="currentProgress === PROGRESS_TYPE.PREPARING">
            <v-btn @click="close()" large fab flat>
              <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
            </v-btn>
          </div>

          <!-- まず画像をdropして画家を選ぶ場合 -->
          <painter-select-modal-content
            v-if="currentProgress === PROGRESS_TYPE.PREPARING && !selectedArtistId"
            @painterSelected="startTransProcessing"
          ></painter-select-modal-content>
          <!-- 画家を選んで画像をdropする場合 -->
          <file-select-modal-content v-else-if="currentProgress === PROGRESS_TYPE.PREPARING && selectedArtistId"
            :auth="auth"
            @fileSelected="startTransProcessing">
          </file-select-modal-content>

          <!-- 変換中 -->
          <processing-modal-content v-if="currentProgress === PROGRESS_TYPE.PROCESSING"
            @transferCompleted="transferCompleted"
          ></processing-modal-content>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"

import FileSelectModalContent from './FileSelectModalContent.vue'
import PainterSelectModalContent from './PainterSelectModalContent.vue'
import ProcessingModalContent from './ProcessingModalContent.vue'

// この順番で処理が進む
const PROGRESS_TYPE = {
  PREPARING: 1,
  PROCESSING: 2,
  // 処理が終わったら終わったら結果選択ページに遷移
}

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    auth: Object,
  },
  components: {
    FileSelectModalContent,
    PainterSelectModalContent,
    ProcessingModalContent
  },
  data() {
    return {
      currentProgress: PROGRESS_TYPE.PREPARING,
      PROGRESS_TYPE: PROGRESS_TYPE,
    }
  },
  computed: {
    ...mapGetters("enpainter", [
      "selectedArtistId",
      "requestId"
    ]),
  },
  methods: {
    close() {
      this.currentProgress = PROGRESS_TYPE.PREPARING
      this.$emit("close")
    },
    startTransProcessing() {
      this.$store.dispatch('enpainter/draw', this.$i18n.locale)
      this.currentProgress = PROGRESS_TYPE.PROCESSING
    },
    transferCompleted() {
      this.$store.commit('screen/footerVisible', false) // 遷移後のページは動的に高さが変わるので高さが決まってからfooterを表示するために非表示にしておく
      this.$router.push({name: 'epResult', params: {requestId: this.requestId}});
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.form-card-container {
  padding: 0px;
  position: relative;
  background-color: #FAE0BD !important;
  border-radius: 10px;
  min-height: 584px;

  @include mq-down(md) {
    min-height: initial;
    width: 100%;
  }
  .scroll-area {
    padding: 0;
    height: auto;
    overflow-y: scroll;
    @include mq-down(md) {
      padding: 0 20px;
      width: 100%;
    }
  }
}
.close-button-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  @include mq-down(md) {
    padding: 0 16px;
    top: -12px;
    left: -28px;
  }
}
.close-image {
  width: 36px;
  height: 36px;
  @include mq-down(md) {
    width: 25px;
    height: 25px;
  }
}
</style>
