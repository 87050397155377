import axios from 'axios'
import {API_BASE_URL} from './settings'
import store from './store/index'
import { GUEST_KEY } from './settings'

axios.defaults.baseURL = API_BASE_URL
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.post['Content-Type'] = 'application/json'

// https://github.com/axios/axios#interceptors
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  console.log('[ERROR] axios.interceptors.response: ', error)
  store.commit('screen/showError', error)
  return Promise.reject(error);
})

const setAuthDefaultHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}
const setAuthGuestHeader = () => {
  axios.defaults.headers.common['API_KEY'] = GUEST_KEY
}
const deleteAuthDefaultHeader = () => {
  delete axios.defaults.headers.common['Authorization']
}
const deleteAuthGuestHeader = () => {
  delete axios.defaults.headers.common['API_KEY']
}

export {
  setAuthDefaultHeader, setAuthGuestHeader, deleteAuthDefaultHeader, deleteAuthGuestHeader,
}
