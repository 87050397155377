import axios from 'axios'
import {API_BASE_URL} from '../settings'

const sha1 = require("js-sha1");

/************************************************************************
  stateの要素を更新する際、viewから参照されるような配列はspliceを使って更新すること
  (配列の要素アクセスでの代入 ex. aaa[0] = hoge) ではreactiveにならない
*************************************************************************/
const initialState = {
  seed: "",
  decodedData: [],
  // 最初に選択する3択の画像 (1回目の選択)
  imgIdxFor1stChoice: [],
  imgSrcFor1stChoice: [],
  selectedImgIdxHistory: [], // ユーザの選択した画像IDの履歴
  postData: {},
  postDataHistories: [],
  // 次に選択する15択の画像 (2回目の選択)
  imgIdxFor2ndChoice: [],
  imgSrcFor2ndChoice: [],
  centerImgIdxFor2ndChoice: "",
  centerImgSrcFor2ndChoice: "",
  centerImgSrc512WMFor2ndChoice: "", // WM: water mark(透かし)
  // 画面下部に表示されるユーザの選択履歴
  imgIdxForHistory: [],
  imgSrcForHistory: ["","","","","",""],
  bottom_select: "",
  restored: false, // stripe決済成功時に遷移してきてstateが復元された場合true
}

const addPostDataHistory = (state, prev_history) => {
  if (state.postDataHistories.length < 8) {
    const historyPrev = {
      post_data: prev_history,
      prev: [state.selectedImgIdxHistory[0], state.selectedImgIdxHistory[1], state.selectedImgIdxHistory[2]]
    };
    state.postDataHistories.push(historyPrev);
  }
}

const buildPostData = (state) =>  {
  if (state.selectedImgIdxHistory[0] && state.selectedImgIdxHistory[1] && state.selectedImgIdxHistory[2]) {
    state.postData = {
      prev_0: btoa(state.selectedImgIdxHistory[0]),
      prev_1: btoa(state.selectedImgIdxHistory[1]),
      prev_2: btoa(state.selectedImgIdxHistory[2]),
      seed: state.seed
    };
    addPostDataHistory(state, state.postData);
    state.selectedImgIdxHistory.splice(2, 1, state.selectedImgIdxHistory[1])
    state.selectedImgIdxHistory.splice(1, 1, state.selectedImgIdxHistory[0])
  } else if (state.selectedImgIdxHistory[0] && state.selectedImgIdxHistory[1]) {
    state.postData = {
      prev_0: btoa(state.selectedImgIdxHistory[0]),
      prev_1: btoa(state.selectedImgIdxHistory[1]),
      seed: state.seed
    };
    addPostDataHistory(state, state.postData);
    state.selectedImgIdxHistory.splice(2, 1, state.selectedImgIdxHistory[1])
    state.selectedImgIdxHistory.splice(1, 1, state.selectedImgIdxHistory[0])
  } else if (state.selectedImgIdxHistory[0]) {
    state.postData = { prev_0: btoa(state.selectedImgIdxHistory[0]), seed: state.seed };
    addPostDataHistory(state, state.postData);
    state.selectedImgIdxHistory.splice(1, 1, state.selectedImgIdxHistory[0])
  }
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    historyExists(state) {
      return state.imgSrcForHistory[0].length > 1
    }
  },
  mutations: {
    decodeResponse(state, data) {
      for (let i = 0; i < 14; i++) {
        state.decodedData[i] = atob(data[i])
      }
      state.seed = data["seed"]
    },
    setImagesFor1stChoice(state) {
      for (let i = 0; i < 3; i++) {
        const hash = sha1.create()
        state.imgIdxFor1stChoice.splice(i, 1, state.decodedData[i])
        state.imgSrcFor1stChoice.splice(i, 1, process.env.VUE_APP_IMG_512_WM + hash
          .update((parseInt(state.decodedData[i]) * 1030787).toString(10))
          .hex() + ".png"
        )
      }
    },
    prepareRequestFor2ndChoice(state, selectedImg) {
      // 選択した画像のインデックス取得
      state.selectedImgIdxHistory.splice(0, 1, selectedImg)
      // POSTするデータを定義
      buildPostData(state)
    },
    // 履歴画像インデックスの割り当て
    updateImgIdxForHistory(state) {
      for (let i = 5; i >= 0; i--) {
        state.imgIdxForHistory.splice(i + 1, 1, state.imgIdxForHistory[i])
      }
      state.imgIdxForHistory.splice(0, 1, state.centerImgIdxFor2ndChoice)
    },
    // 履歴画像の割り当て
    updateImgSrcForHistory(state) {
      for (let i = 0; i < 5; i++) {
        state.imgSrcForHistory.splice(i, 1, "")
        if (state.imgIdxForHistory[i]) {
          const hash = sha1.create();
          state.imgSrcForHistory.splice(
            i,
            1,
            process.env.VUE_APP_IMG_128 +
            hash
              .update((parseInt(state.imgIdxForHistory[i]) * 1085047).toString(10))
              .hex() +
            ".png")
        }
      }
    },
    // 上部画像インデックスの割り当て
    setAllImgIdxFor2ndChoice(state, selected_img_index) {
      state.centerImgIdxFor2ndChoice = selected_img_index;
      for (let i = 0; i < 14; i++) {
        state.imgIdxFor2ndChoice.splice(i, 1, state.decodedData[i])
      }
    },
    // 上部画像の割り当て
    setAllImgSrcFor2ndChoice(state) {
      // NOTE. 以下のハッシュ値計算は、改善リスト101により将来的にはサーバ側へ集約したいとのこと
      let hash = sha1.create();
      state.centerImgSrcFor2ndChoice =
        process.env.VUE_APP_IMG_128 +
        hash
          .update((parseInt(state.centerImgIdxFor2ndChoice) * 1085047).toString(10))
          .hex() +
        ".png";
      hash = sha1.create();
      state.centerImgSrc512WMFor2ndChoice =
        process.env.VUE_APP_IMG_512_WM +
        hash
          .update((parseInt(state.centerImgIdxFor2ndChoice) * 1030787).toString(10))
          .hex() +
        ".png";
      for (let i = 0; i < 14; i++) {
        hash = sha1.create();
        state.imgSrcFor2ndChoice.splice(
          i,
          1,
          process.env.VUE_APP_IMG_128 +
          hash.update((parseInt(state.imgIdxFor2ndChoice[i]) * 1085047).toString(10)).hex() +
          ".png" +
          "?" +
          new Date().getTime()
        )
      }
    },
    clearData(state) {
      state.centerImgSrcFor2ndChoice = "";
      state.centerImgIdxFor2ndChoice = "";
      for (let i = 0; i < 14; i++) {
        state.imgSrcFor2ndChoice.splice(i, 1, "")
        state.imgIdxFor2ndChoice.splice(i, 1, "")
      }
      for (let i = 0; i < 6; i++) {
        state.imgSrcForHistory.splice(i, 1, "")
      }
      for (let i = 0; i < 7; i++) {
        state.imgIdxForHistory.splice(i, 1, "")
      }
      for (let i = 0; i < 3; i++) {
        state.selectedImgIdxHistory.splice(i, 1, "")
      }
    },
    popImages(state) {
      let imgSrcForHistory_count = 0;
      const historyLength = state.postDataHistories.length;
      for (let i = 0; i < state.imgSrcForHistory.length; i++) {
        if (typeof state.imgSrcForHistory[i] === String) {
          imgSrcForHistory_count++;
        }
      }
      state.centerImgSrcFor2ndChoice = state.imgSrcForHistory[imgSrcForHistory_count];

      state.postDataHistories.pop();
      const get_history_data = state.postDataHistories[historyLength - 2];
      state.selectedImgIdxHistory = get_history_data["prev"];
      state.postData = get_history_data["post_data"];
      state.bottom_select = state.imgIdxForHistory[0];

      state.imgIdxForHistory.splice(5, 1, "")
      state.imgSrcForHistory.splice(5, 1, "")
      state.imgIdxForHistory.splice(6, 1, "")
      state.imgIdxForHistory.splice(7, 1, "")

      for (let i = 0; i < 7; i++) {
        state.imgIdxForHistory.splice(i, 1, state.imgIdxForHistory[i + 1])
      }
    },
    setIconCenter(state, selected_buttom_number) {
      state.centerImgSrcFor2ndChoice = state.imgSrcForHistory[parseInt(selected_buttom_number)];
    },
    // クリックした過去画像およびその前回その前々回のインデックス取得
    bottom_get_prev(state, selected_buttom_number) {
      for (let i = 0; i < 3; i++) {
        state.selectedImgIdxHistory.splice(i, 1, state.imgIdxForHistory[Number(selected_buttom_number) + i])
      }
    },
    // 下部画像の履歴処理処理
    clear_bottom_history(state, selected_buttom_number) {
      // bottomインデックスデータ並び替え
      for (let i = 0; i < 7; i++) {
        state.imgIdxForHistory.splice(
          i,
          1,
          state.imgIdxForHistory[
            i + parseInt(selected_buttom_number) + 1
          ]
        )
        state.imgSrcForHistory.splice(
          i,
          1,
          state.imgSrcForHistory[
            i + parseInt(selected_buttom_number) + 1
          ]
        )
      }
    },
    // localStorageにstate内容をsetする
    // stripe決済前にsetし、決済成功時に戻ってきた時にgetして顔生成情報を復元するために使う
    dump(state) {
      const stateStr = JSON.stringify(state)
      localStorage.setItem('saichanStore', stateStr)
    },
    restore(state) {
      const stateStr = localStorage.getItem('saichanStore')
      if (!stateStr) return
      const parsedState = JSON.parse(stateStr)
      Object.keys(state).forEach(keyName => {
        state[keyName] = parsedState[keyName]
      });
      state.restored = true
      localStorage.removeItem('saichanStore')
    },
    unsetRestored(state) {
      state.restored = false
    }
  },
  actions: {
    // 最初に選択する3択の画像を取得
    fetchImagesFor1stChoice({commit}) {
      return axios.get(`${API_BASE_URL}/sc/predict_face_ints_first`)
        .then(res => {
          commit('decodeResponse', res.data["next_index"])
          commit('setImagesFor1stChoice')
        })
    },
    // ユーザの画像選択に基づいて次の候補の画像を取得
    fetchImagesFor2ndChoice({commit, state}, selected_img) {
      commit('prepareRequestFor2ndChoice', selected_img)
      const data = JSON.parse(JSON.stringify(state.postData));
      // if (!data || Object.keys(data).length === 0) return;
      return axios.post(process.env.VUE_APP_SAI_CHAN_FACE_AES, data)
        .then(res => {
          commit('decodeResponse', res.data["next_index"])
          commit('updateImgIdxForHistory')
          commit('updateImgSrcForHistory')
          commit('setAllImgIdxFor2ndChoice', selected_img)
          commit('setAllImgSrcFor2ndChoice')
        })
    },
    // 初回クリック時の状態に戻す処理
    clearData({commit}) {
      commit('clearData')
      return Promise.resolve(true)
    },
    // 1つ前に戻す
    back({commit, state}) {
      commit('popImages')
      commit('updateImgSrcForHistory')
      const data = JSON.parse(JSON.stringify(state.postData));
      // if (!data || Object.keys(data).length === 0) return;
      return axios.post(process.env.VUE_APP_SAI_CHAN_FACE_AES, data)
        .then(res => {
          commit('decodeResponse', res.data["next_index"])
          commit('setAllImgIdxFor2ndChoice', state.bottom_select)
          commit('setAllImgSrcFor2ndChoice')
        });
    },
    // 特定の過去画像に戻る
    back_to_index({commit, state}, payload) {
      commit('setIconCenter', payload.selected_buttom_number)
      commit('bottom_get_prev', payload.selected_buttom_number)
      commit('clear_bottom_history', payload.selected_buttom_number)
      const data = JSON.parse(JSON.stringify(state.postData));
      // if (!data || Object.keys(data).length === 0) return;
      return axios.post(process.env.VUE_APP_SAI_CHAN_FACE_AES, data)
      .then(res => {
        commit('decodeResponse', res.data["next_index"])
        commit('setAllImgIdxFor2ndChoice', payload.selected_img_index)
        commit('setAllImgSrcFor2ndChoice')
      })
    }
  },
}