<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t('glb_scp_13') }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t('glb_scp_14') }}</h3>

      <div class="select-container">
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_15') }}</p>
          <div class="selection-item" for="select1" @click="select(POSE_TYPE.FRONT)">
            <v-img class="cloth-image-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_default.webp"></v-img>
          </div>
        </div>
        <div class="selection-item-container">
          <p class="selection-label">{{ $t('glb_scp_16') }}</p>
          <div class="selection-item" for="select2" @click="select(POSE_TYPE.TRADEMARK)">
            <v-img class="cloth-image-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/chara_jk.webp"></v-img>
          </div>
        </div>
        <div class="selection-item-container">
          <p class="selection-label model-sheet-title" v-html="$t('glb_scp_57')"></p>
          <div class="selection-item" for="select2" @click="select(POSE_TYPE.MODELSHEET)">
            <v-img class="model-sheet" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/pose_model_sheet.webp"></v-img>
          </div>
        </div>
      </div>

      <div class="back-button-container">
        <v-btn class="back-button" flat @click="back()">
          <div class="back-container text-none">
            <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
            <p>back</p>
          </div>
        </v-btn>
      </div>
      <div class="slick-dots-container">
        <div v-for="i in 7" :key="i" :class="`slick-dot slick-dot-${i}`"></div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      selectedPoseType: this.$store.state.saichanPlus.poseType,
      text: this.$store.state.saichanPlus.poseText,
      textFieldRules: {
        counter: value => value.length <= 80 || this.$t('glb_scp_9', [80])
      },
      POSE_TYPE: this.$store.state.saichanPlus.POSE_TYPE
    }
  },
  computed: {
    ...mapState('saichanPlus', {
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    close() {
      this.$emit('close');
    },
    select(value) {
      if (this.selectedPoseType !== this.POSE_TYPE.OTHER) {
        this.text = ""
      }
      this.selectedPoseType = value
      this.$emit('poseOrderEdited', {type: this.selectedPoseType, text: this.text})
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_sc-plus-form.scss";

.form-heading {
  margin-top: 20px;
  margin-bottom: 40px;

  @include mq-down {
    margin-bottom: 32px;
  }
}

.select-container {
  max-width: 616px;
  
  @include mq-up {
    align-items: flex-end;
  }

  @include mq-down {
    align-items: center;
  }

  .selection-item-container {
    display: flex;
    flex-direction: column;
    width: 180px;

    @include mq-down {
      width: calc(100vw - 128px);
      margin-bottom: 16px;
    }

    .selection-label {
      font-size: 18px;
      margin-bottom: 10px;
      
      &.model-sheet-title {
        line-height: 1.3;

        @include mq-up {
          white-space: nowrap;
          margin-left: -20px;
          margin-right: -20px;
        }
      }

      @include mq-down {
        margin-bottom: 4px;
      }
    }
    .selection-item {
      border-radius: 8px;
      max-width: 180px;
      width: 180px;
      max-height: 180px;
      height: 180px;
      background-color: white;
      @include mq-down {
        max-height: 320px;
        height: calc(100vw - 156px);
        width: initial;
        max-width: initial;
      }

      .cloth-image-1 {
        position: relative;
        top: -2%;
        left: 6%;
        width: 74%;
        @include mq-down(md) {
          width: 60%;
          left: 15%;
          top: calc(-7.02vw + 29.8px);
        }
        @include mq-down(sm) {
          top: 0;
        }
      }

      .cloth-image-2 {
        position: relative;
        top: 2%;
        left: 9%;
        width: 66%;
        transform: rotate(45deg);
        @include mq-down(md) {
          width: 60%;
          left: 15%;
          top: calc(-7.02vw + 29.8px);
        }
        @include mq-down(sm) {
          top: 0;
        }
      }

      .model-sheet {
        height: 100%;
      }
    }
  }
}

.slick-dot-3 {
  background-color: #8A8A8A;
}

</style>