import axios from 'axios'
import heic2any from 'heic2any'

import { API_BASE_URL } from '../settings'

function initialState() {
  return {
    samplesNum: sampleMovieMaster.length, // idは1からこの数まで
    targetImageFile: null,
    // uploadする生ファイル。 targetImageFileはpng化したheicが入ることがある場合にこちらはheicが入る
    _targetImageFileForUpload: null,
    targetImageSrc: null,
    preparingTargetImageSrc: false,
  }
}

const sampleMovieMaster = [
  // top: トップページで再生, sample: サンプルページで再生
  // https://docs.google.com/spreadsheets/d/16H5UUu0baaChObONr9jOxnqv10rcqdL0MTRnXtJXgwQ/edit?ts=5faf480d#gid=0
  { // zoom-in
    id: 1,
    top: 'https://www.youtube.com/embed/qq23s1V4RXk?controls=0',
    sample: [
      'https://www.youtube.com/embed/__c8zZ4Wnpw?controls=0',
      'https://www.youtube.com/embed/nXYxSfxm0OM?controls=0',
      'https://www.youtube.com/embed/fjPukoP1CW0?controls=0'
    ]
  },
  { // zoom-out
    id: 2,
    top: 'https://www.youtube.com/embed/N9ls3dwfG9g?controls=0',
    sample: [
      'https://www.youtube.com/embed/ZKY4_L69j0U?controls=0',
      'https://www.youtube.com/embed/3rOWi1zoK7I?controls=0',
      'https://www.youtube.com/embed/tYQA4r5xP7A?controls=0'
    ]
  },
  { // dolly-zoom-in
    id: 3,
    top: 'https://www.youtube.com/embed/wMJ1_2nZEyk?controls=0',
    sample: [
      'https://www.youtube.com/embed/GSccB9zo7d8?controls=0',
      'https://www.youtube.com/embed/ks0CaiVaZrg?controls=0',
      'https://www.youtube.com/embed/9WnJMCgQVhk?controls=0'
    ]
  },
  { // circle-motion
    id: 4,
    top: 'https://www.youtube.com/embed/K0OHicHkGKg?controls=0',
    sample: [
      'https://www.youtube.com/embed/nQiopSoepnA?controls=0',
      'https://www.youtube.com/embed/sbhNU-QWuAc?controls=0',
      'https://www.youtube.com/embed/3RvUTn9bH90?controls=0'
    ]
  },
  { // pedestal-up
    id: 5,
    top: 'https://www.youtube.com/embed/b1v1urwoF-U?controls=0',
    sample: [
      'https://www.youtube.com/embed/8EbprycaWYU?controls=0',
      'https://www.youtube.com/embed/mt_e0aSQ39s?controls=0',
      'https://www.youtube.com/embed/LehS9GixMX8?controls=0'
    ]
  },
  { // pedestal-down
    id: 6,
    top: 'https://www.youtube.com/embed/CiPKXDw-8UU?controls=0',
    sample: [
      'https://www.youtube.com/embed/jOvcJJgrl6s?controls=0',
      'https://www.youtube.com/embed/xvlBoO-39w0?controls=0',
      'https://www.youtube.com/embed/1iyMdHnXPkg?controls=0'
    ]
  },
  { // track-right
    id: 7,
    top: 'https://www.youtube.com/embed/uJ6IeKSi3Qc?controls=0',
    sample: [
      'https://www.youtube.com/embed/-wRYy84LV3c?controls=0',
      'https://www.youtube.com/embed/fzsvA-kG3Ro?controls=0',
      'https://www.youtube.com/embed/wuupep_X-Hg?controls=0'
    ]
  },
  { // track-left
    id: 8,
    top: 'https://www.youtube.com/embed/MxC7Y_FuA60?controls=0',
    sample: [
      'https://www.youtube.com/embed/klI_rKZo_0M?controls=0',
      'https://www.youtube.com/embed/Svifo9EpRxM?controls=0',
      'https://www.youtube.com/embed/xVRzlOqEPZ0?controls=0'
    ]
  },
  { // dynamic-zoom-in_1
    id: 9,
    top: 'https://www.youtube.com/embed/w4DAg_m1u-4?controls=0',
    sample: [
      'https://www.youtube.com/embed/h6M-Aej2wVg?controls=0',
      'https://www.youtube.com/embed/hV_pGzizFNA?controls=0',
      'https://www.youtube.com/embed/epINeQ-nmE8?controls=0'
    ]
  },
  { // dynamic-zoom-in_2
    id: 10,
    top: 'https://www.youtube.com/embed/8WGLraYNJpM?controls=0',
    sample: [
      'https://www.youtube.com/embed/UpmXU8e5aL4?controls=0',
      'https://www.youtube.com/embed/Y-Zu3eUA-cA?controls=0',
      'https://www.youtube.com/embed/zh3oOCY7teg?controls=0'
    ]
  },
  { // dynamic-zoom-right_1
    id: 11,
    top: 'https://www.youtube.com/embed/9k01VK6P7Uk?controls=0',
    sample: [
      'https://www.youtube.com/embed/YWirY73pe3U?controls=0',
      'https://www.youtube.com/embed/BtsnnEs6IF8?controls=0',
      'https://www.youtube.com/embed/0VBJ9GNqZV0?controls=0'
    ]
  },
  { // dynamic-zoom-left_1
    id: 12,
    top: 'https://www.youtube.com/embed/2iYibW3a9p0?controls=0',
    sample: [
      'https://www.youtube.com/embed/f1RjaIUhAmY?controls=0',
      'https://www.youtube.com/embed/asu8e25yyhc?controls=0',
      'https://www.youtube.com/embed/ss86BvtG-Zg?controls=0'
    ]
  },
  { // dynamic-zoom-right_2
    id: 13,
    top: 'https://www.youtube.com/embed/dWeCjMXACxI?controls=0',
    sample: [
      'https://www.youtube.com/embed/pLTmfl1WNjY?controls=0',
      'https://www.youtube.com/embed/x5ZG7ZIeKHY?controls=0',
      'https://www.youtube.com/embed/ZG1mMLISd0s?controls=0'
    ]
  },
  { // dynamic-zoom-left_2
    id: 14,
    top: 'https://www.youtube.com/embed/vP-zTte7FIc?controls=0',
    sample: [
      'https://www.youtube.com/embed/ufzCMBrw0Ns?controls=0',
      'https://www.youtube.com/embed/R4xa_uFXUQ4?controls=0',
      'https://www.youtube.com/embed/On45GXluGP8?controls=0'
    ]
  },
]

const fileReader = new FileReader()
export default {
  namespaced: true,
  state: initialState,
  getters: {
    sampleI18nNameId: () => (id) => `glb_mpm_sample${id}`,
    sampleI18nDescriptionId: () => (id) => `glb_mpm_sample_txt${id}`,
    sampleMovieURLForTopPage: () => (id) => sampleMovieMaster[id - 1]['top'],
    sampleThumbnailURLForTopPage: () => (id) => `https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/thumbnail/sample_${id}.webp`,
    // サンプルページのサムネイル(indexは1-3の想定)
    sampleThumbNailURLForSamplePage: () => (id, index) => `https://storage.googleapis.com/cre8tiveai_public_files/img/mpm/thumbnail/sample_${id}_${index}.webp`,
    sampleMovieURLForSamplePage: () => (id, index) => sampleMovieMaster[id - 1]['sample'][index - 1],

    targetImageFile(state) {
      return state.targetImageFile
    },
    targetImageSrc(state) {
      return state.targetImageSrc
    },
    preparingTargetImageSrc(state) {
      return state.preparingTargetImageSrc
    }
  },
  mutations: {
    // https://github.com/vuejs/vuex/issues/1118#issuecomment-356286218
    reset(state) {
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    async setTargetImageFile(state, files) {
      state.preparingTargetImageSrc = true
      // 複数枚のfileがdropされたとしてもtopの1枚だけ扱う
      state.targetImageFile = files[0]
      state._targetImageFileForUpload = files[0]

      // 2020年11現在はブラウザでの表示に対応していないためheic2anyを挟む
      if (state.targetImageFile.type.toLowerCase() === 'image/heic') {
        // Blobで返ってくるので、file name等がとれるようにFile型にしてやる必要がある
        const conversionResult = await heic2any({blob: state.targetImageFile})
        state.targetImageFile = new File([conversionResult], state.targetImageFile.name)
      }

      fileReader.onload = e => {
        // <img src に指定して表示できるようになる
        state.targetImageSrc = e.target.result;
        state.preparingTargetImageSrc = false
      }
      fileReader.readAsDataURL(state.targetImageFile)
    },
  },
  actions: {
    preparePayment({ dispatch }) {
      // 決済に必要な情報(最新のクレジット所持数、チェックキー)を取得
      //   もし加入中のサブスクプランに応じて課金額も変わるのであれば
      //   加入サブスクデータも取得し直すようにしたい
      return dispatch('getCurrentGroupCreditInfoOfUser', null, { root: true })
    },
    async consumeCreditsAndOrder({ state, dispatch, rootGetters }, payload) {
      const group = rootGetters['user/currentGroup']
      let data = new FormData();
      // 生ファイルの方をuploadすることに注意
      data.append('file', state._targetImageFileForUpload)
      data.append('group_id', group.id)
      data.append('token', payload.token)
      data.append('locale', payload.locale)
      data.append('isFHD', payload.isFHD)
      const result = await axios({
        method: 'post',
        url: `${API_BASE_URL}/credit/consume/mpm`,
        data: data
      })
      if (result.status !== 200) {
        dispatch('showErrorOnScreen', { response: result }, { root: true })
        return Promise.reject(result)
      } else {
        /*global gtag*/
        gtag('event', 'credit_consume_complete', {
          'event_category': 'credit',
          'event_label': 'mpm',
          'value': group.subscription_id
        })
        return Promise.resolve(result.data.request_id)
      }
    }
  }
}
