<template>
  <div class="ssm-container">

    <div id="trigger_world"></div>
    <div id="trigger_ap_title1"></div>
    <div id="trigger_disap_title1"></div>
    <div id="trigger_ap_title2"></div>
    <div id="trigger_disap_title2"></div>
    <div id="trigger_ap_title3"></div>
    <div id="trigger_disap_title3"></div>
    <div id="trigger_scene3_icon"></div>
    <div id="trigger_scene3_icon2"></div>
    <div id="trigger_ap_title4"></div>
    <div id="trigger_disap_title4"></div>
    <div id="trigger_ap_title5"></div>
    <div id="trigger_disap_title5"></div>
    <div id="trigger_ap_title6"></div>
    <div id="trigger_disap_title6"></div>
    <div id="trigger_ap_title7"></div>

    <div id="trigger_00"></div>
    <div id="trigger_01"></div>
    <div id="trigger_02"></div>
    <div id="trigger_03"></div>
    <div id="trigger_04"></div>
    <div id="trigger_05"></div>

    <div id="reset_start"></div>

    <div class="world-container">

      <div class="title-container">
        <v-layout align-center justify-center row fill-height class="title1">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_1") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title2">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_2") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title3">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_3") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title4">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_4") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title5">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_5") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title6">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_6") }}</p>
        </v-layout>
        <v-layout align-center justify-center row fill-height class="title7">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face_nico.jpg" class="face-img"></v-img>
          <p class="scene-title">{{ $t("ssm_7") }}</p>
        </v-layout>

      </div>


      <div class="icons-container">
        <div class="scene1">
          <div v-for="(scene1_pic, i) in getScene1Pics" :key="i" class="pic-container icon_disaps1" :class="'indx-'+i">
            <v-img :src="scene1_pic" :lazy-src="scene1_pic" class="pic"></v-img>
          </div>
        </div>
        <div class="heroine_1 icon_disaps2">
          <span class="pink-effect before display-pc"></span>
          <span class="pink-effect before display-sp"></span>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_01.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_01.jpg" class="heroine1_pic display-pc"></v-img>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_sp/heroine_01.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_sp/heroine_01.webp" class="heroine1_pic display-sp"></v-img>
        </div>
        <div class="scene2">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene2-net.svg" class="net"></v-img>
          <div v-for="(scene2_pic, i) in getScene2Pics" :key="i" class="pic-container in-center icon_disaps2" :class="'indx-'+i" :style="'z-index:'+(20-i)+';'">
            <v-img :src="scene2_pic" :lazy-src="scene2_pic" class="pic icon_disaps2"></v-img>
          </div>
        </div>
        <div class="heroine_2 icon_disaps3">
          <span class="pink-effect before display-pc"></span>
          <span class="pink-effect before display-sp"></span>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_02.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_02.jpg" class="heroine2_pic display-pc"></v-img>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_sp/heroine_02.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_sp/heroine_02.jpg" class="heroine2_pic display-sp"></v-img>
        </div>
        <div class="scene3">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene3-net.svg" class="net"></v-img>
          <div v-for="(scene3_pic, i) in getScene3Pics" :key="i" class="pic-container in-center icon_disaps3" :class="'indx-'+i" :style="'z-index:'+(20-i)+';'">
            <v-img :src="scene3_pic" :lazy-src="scene3_pic" class="pic"></v-img>
          </div>
        </div>
        <div class="heroine_3 in-center">
          <span class="pink-effect before display-pc"></span>
          <span class="pink-effect before display-sp"></span>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_03.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_03.jpg" class="heroine3_pic display-pc"></v-img>
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_03.jpg" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_03.jpg" class="heroine3_pic display-sp"></v-img>
        </div>
        <div class="wood_frame">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/wood_frame.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/wood_frame.webp" class="wood_frame_pic"></v-img>
        </div>
        <div class="comp_back">
          <v-img src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/dl-image-back.webp" lazy-src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/dl-image-back.webp" class="comp_back_pic"></v-img>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import * as ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, gsap);

export default {
  name: 'SaiScrollMagic',
  data() {
    return {
      isSP: window.innerWidth < 768, // iPad未満
      scene1_pics: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000002.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000004.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000006.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000010.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000049.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000055.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000057.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000058.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000060.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000069.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000071.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000100.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000113.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000117.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000120.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000150.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000153.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000164.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000192.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000253.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000275.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000343.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000369.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000409.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000434.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000463.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000483.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000489.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000050.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000051.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/0000000306.jpg'
      ],
      scene1_pics_sp: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000002.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000004.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000006.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000010.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000049.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000055.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000057.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000058.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000060.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000069.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000071.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000100.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000113.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000117.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000120.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000150.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000153.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000164.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000192.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000253.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000275.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000343.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000369.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000409.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000434.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000463.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000483.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000489.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000050.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000051.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_1/sp/0000000306.webp'
      ],
      scene2_pics: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000005.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000006.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000008.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000010.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000014.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000022.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000031.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000032.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000033.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000040.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000058.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000071.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000085.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/000087.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_02.jpg'
      ],
      scene2_pics_sp: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000005.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000006.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000008.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000010.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000014.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000022.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000031.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000032.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000033.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000040.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000058.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000071.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000085.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_2/sp/000087.webp',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/heroine_02.jpg'
      ],
      scene3_pics: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/000016.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/000047.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/000059.jpg'
      ],
      scene3_pics_sp: [
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/sp/000016.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/sp/000047.jpg',
        'https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_scrollmagic/scene_3/sp/000059.jpg'
      ]
    }
  },
  computed: {
    getScene1Pics() {
      if (this.isSP) {
        return this.scene1_pics_sp;
      } else {
        return this.scene1_pics;
      }
    },
    getScene2Pics() {
      if (this.isSP) {
        return this.scene2_pics_sp;
      } else {
        return this.scene2_pics;
      }
    },
    getScene3Pics() {
      if (this.isSP) {
        return this.scene3_pics_sp;
      } else {
        return this.scene3_pics;
      }
    },
  },
  mounted() {
    const controller = new ScrollMagic.Controller();

    // 全体をピン留め
    new ScrollMagic.Scene({
      triggerElement: "#trigger_world",
      duration: 5300,
      triggerHook: 0,
      loglevel: 1
    })
    .setPin(".world-container")
    .addTo(controller);
    // .addIndicators({name: "ピン留め"})

    // ==== タイトルの操作 ==== //

    // title1出現
    const aps_title1 = gsap.fromTo(
      ".title1",
      {
        opacity: 0,
        top: "32px",
      },
      {
        opacity: 1,
        top: "0px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title1',
      duration: 150,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(aps_title1)
    .addTo(controller);
    // .addIndicators({ name: 'title1出現' })

    // title1消滅
    const remove_scene = gsap.to(
      ".title1",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title1',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_scene)
    .addTo(controller);

    // title2出現
    const appear_title2 = gsap.to(
      ".title2",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title2',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title2)
    .addTo(controller);

    // title2消滅
    const remove_title2 = gsap.to(
      ".title2",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title2',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_title2)
    .addTo(controller);
    // .addIndicators({ name: 'Scene2消滅' })

    // title3出現
    const appear_title3 = gsap.to(
      ".title3",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title3',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title3)
    .addTo(controller);

    // title3消滅
    const remove_scene3 = gsap.to(
      ".title3",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title3',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_scene3)
    .addTo(controller);

    // title4出現
    const appear_title4 = gsap.to(
      ".title4",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title4',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title4)
    .addTo(controller);

    // title4消滅
    const remove_scene4 = gsap.to(
      ".title4",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title4',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_scene4)
    .addTo(controller);

    // title5出現
    const appear_title5 = gsap.to(
      ".title5",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title5',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title5)
    .addTo(controller);

    // title5消滅
    const remove_scene5 = gsap.to(
      ".title5",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title5',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_scene5)
    .addTo(controller);

    // title6出現
    const appear_title6 = gsap.to(
      ".title6",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title6',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title6)
    .addTo(controller);

    // title6消滅
    const remove_scene6 = gsap.to(
      ".title6",
      {
        opacity: 0,
        top: "-32px",
        duration: 1,
        ease: "back.in(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_disap_title6',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(remove_scene6)
    .addTo(controller);

    // title7出現
    const appear_title7 = gsap.to(
      ".title7",
      {
        opacity: 1,
        top: 0,
        duration: 0.1,
        ease: "back.out(2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_ap_title7',
      duration: 150,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(appear_title7)
    .addTo(controller);


    // ==== Scene1 ==== //

    // Scene1アイコン出現
    const icon_aps1 = gsap.fromTo(
      ".scene1>.pic-container",
      {
        scale: 0.1,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 2,
        ease: "back.out(1)",
        stagger: 0.15
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_00',
      duration: 800,
      triggerHook: 0.4,
      loglevel: 1
    })
    .setTween(icon_aps1)
    .addTo(controller);
    // .addIndicators({ name: 'Scene1アイコン出現' })
    // Scene1ヒロイン出現
    const icon_aps1_heroine = gsap.fromTo(
      ".heroine_1",
      {
        scale: 0.1,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 2,
        ease: "back.out(1)",
        stagger: 0.15
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_00',
      duration: 100,
      triggerHook: 0.2,
      loglevel: 1
    })
    .setTween(icon_aps1_heroine)
    .addTo(controller);
    // .addIndicators({ name: 'Scene1アイコン出現' })

    // アイコンが消える
    const icon_disaps1 = gsap.to(
      ".icon_disaps1",
      {
        scale: 0,
        duration: 2,
        ease: "back.in(1)",
        stagger: 0.15
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_01',
      duration: 600,
      loglevel: 1
    })
    .setTween(icon_disaps1)
    .addTo(controller);
    // .addIndicators({ name: 'アイコンが消える' })

    // ヒロイン1ピンク化（PC）
    const pinknize_scene1_pc = gsap.to(
      ".heroine1_pic.display-pc",
      {
        "border-color": "#FF5891",
        "border-width": "6px",
        "border-radius": "10%",
        width: "110%",
        height: "110%",
        duration: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_01',
      duration: 150,
      triggerHook: 0.65,
      loglevel: 1
    })
    .setTween(pinknize_scene1_pc)
    .addTo(controller);
    // .addIndicators({ name: 'ピンク化01' })

    // ヒロイン1ピンク化（SP）
    const pinknize_scene1_sp = gsap.to(
      ".heroine1_pic.display-sp",
      {
        "border-color": "#FF5891",
        "border-width": "3px",
        "border-radius": "10%",
        width: "110%",
        height: "110%",
        duration: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_01',
      duration: 150,
      triggerHook: 0.65,
      loglevel: 1
    })
    .setTween(pinknize_scene1_sp)
    .addTo(controller);
    // .addIndicators({ name: 'ピンク化01' })

    // ピンクの波紋1（PC）
    const pink_effect1_pc = gsap.to(
      ".heroine_1>.pink-effect.display-pc",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "277px",
        height: "277px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_01",
      duration: 500,
      triggerHook: 0.65,
      loglevel: 1
    })
    // .addIndicators({ name: 'エフェクト01' })
    .setTween(pink_effect1_pc)
    .addTo(controller);

    // ピンクの波紋1（SP）
    const pink_effect1_sp = gsap.to(
      ".heroine_1>.pink-effect.display-sp",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "112px",
        height: "112px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_01",
      duration: 500,
      triggerHook: 0.65,
      loglevel: 1
    })
    // .addIndicators({ name: 'エフェクト01' })
    .setTween(pink_effect1_sp)
    .addTo(controller);

    // 中央に移動
    const move_center = gsap.to(
      ".heroine_1",
      {
        top: "50%",
        left: "50%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_01',
      duration: 300,
      triggerHook: 0.3,
      loglevel: 1
    })
    .setTween(move_center)
    .addTo(controller);
    // .addIndicators({ name: '中央に移動' })

    // ==== Scene2 ==== //

    // Scene2アイコン出現
    const icon_aps_scene2 = gsap.to(
      ".scene2>.pic-container",
      {
        top: function(i) {
          if (i === 0) {
            return "40%";
          } else if (i === 1) {
            return "25%";
          } else if (i === 2) {
            return "40%";
          } else if (i === 3) {
            return "67%";
          } else if (i === 4) {
            return "67%";
          } else if (i === 5) {
            return "10%";
          } else if (i === 6) {
            return "10%";
          } else if (i === 7) {
            return "22%";
          } else if (i === 8) {
            return "52%";
          } else if (i === 9) {
            return "81%";
          } else if (i === 10) {
            return "92%";
          } else if (i === 11) {
            return "92%";
          } else if (i === 12) {
            return "81%";
          } else if (i === 13) {
            return "52%";
          } else if (i === 14) {
            return "22%";
          }
        },
        left: function(i) {
          if (i === 0) {
            return "32%";
          } else if (i === 1) {
            return "50%";
          } else if (i === 2) {
            return "68%";
          } else if (i === 3) {
            return "65%";
          } else if (i === 4) {
            return "35%";
          } else if (i === 5) {
            return "35%";
          } else if (i === 6) {
            return "65%";
          } else if (i === 7) {
            return "84%";
          } else if (i === 8) {
            return "87%";
          } else if (i === 9) {
            return "81%";
          } else if (i === 10) {
            return "59%";
          } else if (i === 11) {
            return "41%";
          } else if (i === 12) {
            return "19%";
          } else if (i === 13) {
            return "13%";
          } else if (i === 14) {
            return "16%";
          }
        },
        width: "10%",
        opacity: 1,
        visibility: "visible",
        duration: 2,
        ease: "back.out(0.8)",
        stagger: 0.05
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_02',
      duration: 600,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(icon_aps_scene2)
    .addTo(controller);
    // .addIndicators({ name: 'アイコン出現' })

    // ヒロイン1正常化（PC）
    const normalize_icon1_pc = gsap.to(
      ".heroine1_pic.display-pc",
      {
        "border-color": "white",
        "border-width": "3px",
        "border-radius": "8%",
        width: "100%",
        height: "100%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_02',
      duration: 300,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(normalize_icon1_pc)
    .addTo(controller);

    // ヒロイン1正常化（SP）
    const normalize_icon1_sp = gsap.to(
      ".heroine1_pic.display-sp",
      {
        "border-color": "white",
        "border-width": "2px",
        "border-radius": "8%",
        width: "100%",
        height: "100%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_02',
      duration: 300,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(normalize_icon1_sp)
    .addTo(controller);

    // Scene2ネット出現
    const net_aps_scene2 = gsap.to(
      ".scene2>.net",
      {
        opacity: 1,
        duration: 2,
        ease: "power3.out"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_02',
      duration: 400,
      triggerHook: 0.3,
      loglevel: 1
    })
    .setTween(net_aps_scene2)
    .addTo(controller);

    // 左上アイコン入れ替わり
    const disap_heroine2_seed = gsap.to(
      ".scene2>.indx-14",
      {
        visibility: "hidden",
        duration: 1
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 1,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(disap_heroine2_seed)
    .addTo(controller);

    const appear_heroine2 = gsap.to(
      ".heroine_2",
      {
        visibility: "visible",
        duration: 1
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 1,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(appear_heroine2)
    .addTo(controller);
    // .addIndicators({ name: '左上アイコン入れ替わり' })

    // ピンクの波紋2（PC）
    const pink_effect2_pc = gsap.to(
      ".heroine_2>.pink-effect.display-pc",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "231px",
        height: "231px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_03",
      duration: 500,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(pink_effect2_pc)
    .addTo(controller);

    // ピンクの波紋2（SP）
    const pink_effect2_sp = gsap.to(
      ".heroine_2>.pink-effect.display-sp",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "94px",
        height: "94px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_03",
      duration: 500,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(pink_effect2_sp)
    .addTo(controller);

    // ヒロイン2ピンク化（PC）
    const pinknize_scene2_pc = gsap.to(
      ".heroine2_pic.display-pc",
      {
        "border-color": "#FF5891",
        "border-width": "6px",
        "border-radius": "10%",
        width: "120%",
        height: "120%",
        duration: 5,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 300,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(pinknize_scene2_pc)
    .addTo(controller);
    // .addIndicators({ name: 'pinknize_heroine_2' })

    // ヒロイン2ピンク化（SP）
    const pinknize_scene2_sp = gsap.to(
      ".heroine2_pic.display-sp",
      {
        "border-color": "#FF5891",
        "border-width": "3px",
        "border-radius": "10%",
        width: "120%",
        height: "120%",
        duration: 5,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 300,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(pinknize_scene2_sp)
    .addTo(controller);
    // .addIndicators({ name: 'pinknize_heroine_2' })

    // Scene2ネットが消える
    const net_disaps2 = gsap.to(
      ".scene2>.net",
      {
        width: 0,
        duration: 2,
        ease: "back.in(1)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 120,
      triggerHook: 0.3,
      loglevel: 1
    })
    .setTween(net_disaps2)
    .addTo(controller);

    // アイコンが消える2
    const icon_disaps2 = gsap.to(
      ".icon_disaps2, .icon_disaps2>.pic, .icon_disaps2>.heroine1_pic",
      {
        width: 0,
        "border-width": 0,
        duration: 2,
        ease: "back.in(1)",
        stagger: 0.08
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 400,
      triggerHook: 0.1,
      loglevel: 1
    })
    .setTween(icon_disaps2)
    .addTo(controller);

    // widthが0に固定されてしまうのを防ぐための処理1
    const icon_repair1 = gsap.to(
      ".icon_disaps2, .icon_disaps2>.pic, .icon_disaps2>.heroine1_pic",
      {
        width: "1%",
        "border-width": 0,
        display: "none",
        duration: 2,
        ease: "back.inOut(1)",
        stagger: 0.08
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 400,
      triggerHook: 0.3,
      loglevel: 1
    })
    .setTween(icon_repair1)
    .addTo(controller);

    // ヒロイン2中央に移動
    const move_center2 = gsap.to(
      ".heroine_2",
      {
        top: "50%",
        left: "50%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 300,
      triggerHook: 0.18,
      loglevel: 1
    })
    .setTween(move_center2)
    .addTo(controller);
    // ヒロイン2中央に移動2
    const move_center2_pic = gsap.to(
      ".heroine2_pic",
      {
        width: "200%",
        height: "200%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_03',
      duration: 300,
      triggerHook: 0.18,
      loglevel: 1
    })
    .setTween(move_center2_pic)
    .addTo(controller);


    // ==== Scene3 ==== //

    // ヒロイン2正常化（PC）
    const normalize_icon2_pic_pc = gsap.to(
      ".heroine2_pic.display-pc",
      {
        "border-color": "white",
        "border-width": "3px",
        "border-radius": "8%",
        width: "170%",
        height: "170%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_scene3_icon',
      duration: 300,
      triggerHook: 0.4,
      loglevel: 1
    })
    .setTween(normalize_icon2_pic_pc)
    .addTo(controller);

    // ヒロイン2正常化（SP）
    const normalize_icon2_pic_sp = gsap.to(
      ".heroine2_pic.display-sp",
      {
        "border-color": "white",
        "border-width": "2px",
        "border-radius": "8%",
        width: "170%",
        height: "170%",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_scene3_icon',
      duration: 300,
      triggerHook: 0.4,
      loglevel: 1
    })
    .setTween(normalize_icon2_pic_sp)
    .addTo(controller);

    // Scene3アイコン出現1(見える化)
    const icon_aps1_scene3 = gsap.to(
      ".scene3>.pic-container, .heroine_3",
      {
        visibility: "visible",
        duration: 2,
        stagger: 0.1
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_scene3_icon2',
      duration: 1,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(icon_aps1_scene3)
    .addTo(controller);

    // Scene3アイコン出現2（動き）
    const icon_aps2_scene3 = gsap.to(
      ".scene3>.pic-container, .heroine_3",
      {
        top: function(i) {
          if (i === 0) {
            return "74%";
          } else if (i === 1) {
            return "77%";
          } else if (i === 2) {
            return "26%";
          } else if (i === 3) {
            return "24%";
          }
        },
        left: function(i) {
          if (i === 0) {
            return "19%";
          } else if (i === 1) {
            return "78%";
          } else if (i === 2) {
            return "81%";
          } else if (i === 3) {
            return "22%";
          }
        },
        opacity: 1,
        width: "16%",
        "z-index": function(i) {
          if (i === 0) {
            return 20;
          } else if (i === 1) {
            return 19;
          } else if (i === 2) {
            return 18;
          } else if (i === 3) {
            return 79;
          }
        },
        duration: 2,
        ease: "power2.in",
        stagger: 0.1
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_scene3_icon',
      duration: 400,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(icon_aps2_scene3)
    .addTo(controller);
    // .addIndicators({ name: 'アイコン出現' })

    // Scene3ネット出現
    const net_aps_scene3 = gsap.to(
      ".scene3>.net",
      {
        opacity: 1,
        duration: 2,
        ease: "power3.out"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_04',
      duration: 400,
      triggerHook: 0.55,
      loglevel: 1
    })
    .setTween(net_aps_scene3)
    .addTo(controller);

    // ピンクの波紋3（PC）
    const pink_effect3_pc = gsap.to(
      ".heroine_3>.pink-effect.display-pc",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "369px",
        height: "369px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_05",
      duration: 500,
      triggerHook: 1,
      loglevel: 1
    })
    .setTween(pink_effect3_pc)
    .addTo(controller);

    // ピンクの波紋3（SP）
    const pink_effect3_sp = gsap.to(
      ".heroine_3>.pink-effect.display-sp",
      {
        "border-width": "32px",
        "box-shadow": "0px 0px 16px 4px #FF4081 inset",
        width: "150px",
        height: "150px",
        opacity: 0,
        duration: 1,
        ease: "power3.out",
        onStart: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 20)); },
        onReverseComplete: function() { this.targets().forEach(elem => elem.style.setProperty("z-index", 0)); }
      }
    )
    new ScrollMagic.Scene({
      triggerElement: "#trigger_05",
      duration: 500,
      triggerHook: 1,
      loglevel: 1
    })
    .setTween(pink_effect3_sp)
    .addTo(controller);

    // ヒロイン3ピンク化（PC）
    const pinknize_scene3_pc = gsap.to(
      ".heroine3_pic.display-pc",
      {
        "border-color": "#FF5891",
        "border-width": "6px",
        "border-radius": "10%",
        width: "120%",
        height: "120%",
        duration: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 150,
      triggerHook: 1,
      loglevel: 1
    })
    .setTween(pinknize_scene3_pc)
    .addTo(controller);

    // ヒロイン3ピンク化（SP）
    const pinknize_scene3_sp = gsap.to(
      ".heroine3_pic.display-sp",
      {
        "border-color": "#FF5891",
        "border-width": "3px",
        "border-radius": "10%",
        width: "120%",
        height: "120%",
        duration: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 150,
      triggerHook: 1,
      loglevel: 1
    })
    .setTween(pinknize_scene3_sp)
    .addTo(controller);

    // Scene3ネットが消える
    const net_disaps3 = gsap.to(
      ".scene3>.net",
      {
        width: 0,
        duration: 2,
        ease: "back.in(1)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 160,
      triggerHook: 0.8,
      loglevel: 1
    })
    .setTween(net_disaps3)
    .addTo(controller);

    // アイコンが消える3
    const icon_disaps3 = gsap.to(
      ".icon_disaps3, .icon_disaps3>.pic, .icon_disaps3>.heroine2_pic",
      {
        width: 0,
        "border-width": 0,
        duration: 2,
        ease: "back.in(1)",
        stagger: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 400,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(icon_disaps3)
    .addTo(controller);

    // widthが0に固定されてしまうのを防ぐための処理2
    const icon_repair2 = gsap.to(
      ".icon_disaps3, .icon_disaps3>.pic, .icon_disaps3>.heroine2_pic",
      {
        width: "1%",
        "border-width": 0,
        display: "none",
        duration: 2,
        ease: "back.inOut(1)",
        stagger: 0.4
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 400,
      triggerHook: 0.8,
      loglevel: 1
    })
    .setTween(icon_repair2)
    .addTo(controller);

    // 中央に移動
    const move_center3 = gsap.to(
      ".heroine_3",
      {
        "z-index": 100,
        top: "50.4%",
        left: "50.5%",
        duration: 1,
        ease: "back.out(0.2)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 500,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(move_center3)
    .addTo(controller);

    // ==== Scene4 ==== //

    // フレームに最適化
    const scale_center3 = gsap.to(
      ".heroine3_pic",
      {
        width: "190%",
        height: "190%",
        "border-width": 0,
        "border-radius": 0,
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 300,
      triggerHook: 0.5,
      loglevel: 1
    })
    .setTween(scale_center3)
    .addTo(controller);

    // フレームのアニメ1(下にスライド)
    const frame_anime = gsap.to(
      ".wood_frame",
      {
        top: "50%",
        opacity: 1,
        duration: 1,
        ease: "back.out(0.3)"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 200,
      triggerHook: 0.24,
      loglevel: 1
    })
    .setTween(frame_anime)
    .addTo(controller);
    // フレームのアニメ2(縮小)
    const frame_anime2 = gsap.to(
      ".wood_frame_pic",
      {
        transform: "scale(1)",
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 200,
      triggerHook: 0.24,
      loglevel: 1
    })
    .setTween(frame_anime2)
    .addTo(controller);

    // 背景のアニメ
    const bg_anime = gsap.to(
      ".comp_back",
      {
        width: "53%",
        opacity: 1,
        duration: 1,
        ease: "power1.inOut"
      }
    )
    new ScrollMagic.Scene({
      triggerElement: '#trigger_05',
      duration: 400,
      triggerHook: 0.6,
      loglevel: 1
    })
    .setTween(bg_anime)
    .addTo(controller);
    // .addIndicators({ name: 'BG出現' })
  },
  methods: {
    resetMagic: function () {
      this.bg_anime.play(0)
    },
  }
}
</script>


<style lang="scss">
@import "../../assets/scss/_variables.scss";

@mixin icon-style($size:100%) {
  width:$size;
  height: $size;
  border-radius: 8%;
  box-shadow: 0px 2px 4px 0px rgba(black,0.4);
  border: solid 3px white;
  margin: -4px;
  box-sizing: content-box;
  @include mq-down {
    border-width: 2px;
  }
}

$margin-top: 0px;

.ssm-container {
  position: relative;
  max-width: 1040px;
  margin: auto;
  margin-bottom: 128px;
  margin-top: $margin-top;

  @include mq-up {
    padding-top: 56px;
  }

  @include mq-down {
    overflow-x: hidden;
  }
}

.ssm-container * {
  box-sizing: border-box;
}

.world-container {
  position: relative;
  // background-color: antiquewhite;
  max-width: 1040px;
  width: 100vw;
  height: 72vw;
  max-height: 760px;

  @include mq-down {
    height: 72vw;
  }
}

.icons-container {
  position: relative;
  top: 0;
  height: 84%;
  width: 100%;
  // background: rgba(orange,.3);
}
@mixin display_trigger($color:violet) {
  background-color: $color;
  height: 2px;
  width: 100%;
  left: 0;
}

#trigger_world {
  position: absolute;
  top: $margin-top;
  margin-top: 16px;

  @include mq-down {
    margin-top: -34vw;
  }
}

#trigger_ap_title1 {
  position: absolute;
  top: $margin-top - 200;
}
#trigger_disap_title1 {
  position: absolute;
  top: $margin-top + 620;
}
#trigger_ap_title2 {
  position: absolute;
  top: $margin-top + 820;
}
#trigger_disap_title2 {
  position: absolute;
  top: $margin-top + 1400;
}
#trigger_ap_title3 {
  position: absolute;
  top: $margin-top + 1600;
}
#trigger_disap_title3 {
  position: absolute;
  top: $margin-top + 2150;
}
#trigger_ap_title4 {
  position: absolute;
  top: $margin-top + 2350;
}
#trigger_disap_title4 {
  position: absolute;
  top: $margin-top + 3600;
}
#trigger_ap_title5 {
  position: absolute;
  top: $margin-top + 3800;
}
#trigger_disap_title5 {
  position: absolute;
  top: $margin-top + 4400;
}
#trigger_ap_title6 {
  position: absolute;
  top: $margin-top + 4600;
}
#trigger_disap_title6 {
  position: absolute;
  top: $margin-top + 4900;
}
#trigger_ap_title7 {
  position: absolute;
  top: $margin-top + 5100;
}


#trigger_00 {
  position: absolute;
  top: $margin-top + 300;
}
#trigger_01 {
  position: absolute;
  top: $margin-top + 1500;
}
#trigger_02 {
  position: absolute;
  top: $margin-top + 2450;
}
#trigger_03 {
  position: absolute;
  top: $margin-top + 3200;
}
#trigger_scene3_icon {
  position: absolute;
  top: $margin-top + 3600;
}
#trigger_scene3_icon2 {
  position: absolute;
  top: $margin-top + 3800;
}
#trigger_04 {
  position: absolute;
  top: $margin-top + 4000;
  // @include display_trigger(red);
}
#trigger_05 {
  position: absolute;
  top: $margin-top + 4800;
  // @include display_trigger;
}

#reset_start {
  position: absolute;
  top: $margin-top + 5200;
}

$accent-color: #FF5891;

.title-container {
  height: 22%;
  position: relative;

  @include mq-down {
    margin: 0 16px;
    height: 30%;
  }

  .face-img {
    width: 100%;
    max-width: 64px;
    border-radius: 99px;
    border: solid 4px $accent-color;
    margin-right: 16px;

    @include mq-down {
      max-width: 40px;
      max-height: 40px;
      margin-top: 0px;
      margin-right: 8px;
      border-width: 2px;
    }
  }

  .scene-title {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    color: #E91E63;
    margin: 0;

    @include mq-down {
      font-size: 4vw;
    }
  }
}

.pink-effect {
  position: absolute;
  border-radius: 999px;
  top: -4px;
  left: -4px;
  transform: translate(-50%, -50%);
  border: solid #FF4081;
  box-sizing: border-box;

  &.before {
    border-width:16px;
    box-shadow:0px 0px 8px 1px #FF4081 inset;
    height: 0%;
    width: 0%;
    opacity: 1;
    z-index: 0;
  }

  /* gsap3系への変更に伴い未使用になったのでコメントアウト
  &.after {
    border-width: 32px;
    box-shadow:0px 0px 16px 4px #FF4081 inset;
    width: 250%;
    height: 250%;
    opacity: 0;
    z-index: 20;
  }
  */
}

@mixin title() {
  position: absolute;
  width: 100%;
}

.title1 {
  @include title;
}

.scene1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .pic-container {
    position: absolute;
  }

  .indx-0 {
    top: 19%;
    left: 49%;
    z-index: 9;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-1 {
    top: 48%;
    left: 52%;
    z-index: 11;
    width: 9%;
    .pic {
      @include icon-style();
    }
  }
  .indx-2 {
    top: 35%;
    left: 58%;
    z-index: 8;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-3 {
    top: 20%;
    left: 75%;
    z-index: 15;
    width: 11%;
    .pic {
      @include icon-style();
    }
  }
  .indx-4 {
    top: 15%;
    left: 60%;
    z-index: 12;
    width: 10%;
    .pic {
      @include icon-style();
    }
  }
  .indx-5 {
    top: 43%;
    left: 78%;
    z-index: 16;
    width: 14%;
    .pic {
      @include icon-style();
    }
  }
  .indx-6 {
    top: 37%;
    left: 14%;
    z-index: 7;
    width: 5%;
    .pic {
      @include icon-style();
    }
  }
  .indx-7 {
    top: 4%;
    left: 65%;
    z-index: 6;
    width: 5%;
    .pic {
      @include icon-style();
    }
  }
  .indx-8 {
    top: 2%;
    left: 50%;
    z-index: 8;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-9 {
    top: 20%;
    left: 14%;
    z-index: 8;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-10 {
    top: 56%;
    left: 65%;
    z-index: 7;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-11 {
    top: 4%;
    left: 12%;
    z-index: 8;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-12 {
    top: 14%;
    left: 89%;
    z-index: 7;
    width: 5%;
    .pic {
      @include icon-style();
    }
  }
  .indx-13 {
    top: 80%;
    left: 20%;
    z-index: 9;
    width: 9%;
    .pic {
      @include icon-style();
    }
  }
  .indx-14 {
    top: 3%;
    left: 76%;
    z-index: 10;
    width: 8%;
    .pic {
      @include icon-style();
    }
  }
  .indx-15 {
    top: 2%;
    left: 34%;
    z-index: 14;
    width: 11%;
    .pic {
      @include icon-style();
    }
  }
  .indx-16 {
    top: 70%;
    left: 67%;
    z-index: 12;
    width: 10%;
    .pic {
      @include icon-style();
    }
  }
  .indx-17 {
    top: 60%;
    left: 35%;
    z-index: 9;
    width: 9%;
    .pic {
      @include icon-style();
    }
  }
  .indx-18 {
    top: 78%;
    left: 81%;
    z-index: 9;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-19 {
    top: 69%;
    left: 47%;
    z-index: 17;
    width: 14%;
    .pic {
      @include icon-style();
    }
  }
  .indx-20 {
    top: 81%;
    left: 38%;
    z-index: 8;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-21 {
    top: 30%;
    left: 4%;
    z-index: 9;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-22 {
    top: 35%;
    left: 38%;
    z-index: 15;
    width: 12%;
    .pic {
      @include icon-style();
    }
  }
  .indx-23 {
    top: 25%;
    left: 33%;
    z-index: 5;
    width: 4%;
    .pic {
      @include icon-style();
    }
  }
  .indx-24 {
    top: 78%;
    left: 7%;
    z-index: 10;
    width: 8%;
    .pic {
      @include icon-style();
    }
  }
  .indx-25 {
    top: 31%;
    left: 23%;
    z-index: 8;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-26 {
    top: 65%;
    left: 25%;
    z-index: 7;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
  .indx-27 {
    top: 46%;
    left: 28%;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-28 {
    top: 9%;
    left: 22%;
    z-index: 12;
    width: 9%;
    .pic {
      @include icon-style();
    }
  }
  .indx-29 {
    top: 40%;
    left: 67%;
    z-index: 9;
    width: 7%;
    .pic {
      @include icon-style();
    }
  }
  .indx-30 {
    top: 30%;
    left: 90%;
    z-index: 7;
    width: 6%;
    .pic {
      @include icon-style();
    }
  }
}

.heroine_1 {
  position: absolute;
  top: 62%;
  left: 15%;
  z-index: 50;
  width: 12%;
  height: auto;

  .heroine1_pic {
    transform: translate(-50%, -50%);
    @include icon-style();

    /* gsap3系への変更に伴い未使用になったのでコメントアウト
    &.pinknized {
      border-color: #FF5891;
      border-width: 6px;
      border-radius: 10%;
      width: 110%;
      height: 110%;

      @include mq-down {
        border-width: 3px;
      }
    }
    */
  }
}

.title2,
.title3,
.title4,
.title5,
.title6,
.title7{
  @include title;
  top: 64px;
  opacity: 0;
}

.scene2 {
  position: absolute;
  top: b0;
  left: 0;
  width: 100%;
  height: 100%;

  .net {
    opacity: 0;
    top: 50%;
    left: 49.5%;
    width: 74%;
    transform: translate(-50%, -50%);
  }

  .pic-container {
    position: absolute;
    width: 10%;
    opacity: 1;

    &.in-center {
      top: 50%;
      left: 50%;
      width: 5%;
      opacity: .75;
      visibility: hidden;
    }
  }
  .pic {
    @include icon-style();
    transform: translate(-50%, -50%);
  }

  .indx-0 {
    top: 40%;
    left: 32%;
  }
  .indx-1 {
    top: 25%;
    left: 50%;
  }
  .indx-2 {
    top: 40%;
    left: 68%;
  }
  .indx-3 {
    top: 67%;
    left: 65%;
  }
  .indx-4 {
    top: 67%;
    left: 35%;
  }
  .indx-5 {
    top: 10%;
    left: 35%;
  }
  .indx-6 {
    top: 10%;
    left: 65%;
  }
  .indx-7 {
    top: 22%;
    left: 84%;
  }
  .indx-8 {
    top: 52%;
    left: 87%;
  }
  .indx-9 {
    top: 81%;
    left: 81%;
  }
  .indx-10 {
    top: 92%;
    left: 59%;
  }
  .indx-11 {
    top: 92%;
    left: 41%;
  }
  .indx-12 {
    top: 81%;
    left: 19%;
  }
  .indx-13 {
    top: 52%;
    left: 13%;
  }
  .indx-14 {
    top: 22%;
    left: 16%;
  }
}

.heroine_2 {
  visibility: hidden;
  position: absolute;
  top: 22%;
  left: 16%;
  z-index: 80;
  width: 10%;
  height: auto;

  .heroine2_pic {
    position: relative;
    transform: translate(-50%, -50%);
    @include icon-style();

    /* gsap3系への変更に伴い未使用になったのでコメントアウト
    &.pinknized {
      border-color: #FF5891;
      border-width: 6px;
      border-radius: 10%;
      width: 120%;
      height: 120%;

      @include mq-down {
        border-width: 3px;
      }
    }

    &.big {
      width: 200%;
      height: 200%;
    }

    &.scene3 {
      width: 170%;
      height: 170%;
    }
    */

    &.normalized {
      border-color: white;
      border-width: 3px;
      border-radius: 10%;
      width: 100%;
      height: 100%;

      @include mq-down {
        border-width: 2px;
      }
    }

  }
}

.scene3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .net {
    opacity: 0;
    top: 50%;
    left: 49.5%;
    width: 55%;
    transform: translate(-50%, -50%);
  }

  .pic-container {
    position: absolute;
    width: 16%;
    visibility: hidden;
    opacity: 1;

    &.in-center {
      top: 50%;
      left: 50%;
      opacity: .5;
      width: 7%;
    }
  }

  .pic {
    @include icon-style();
    transform: translate(-50%, -50%);
  }

  .indx-0 {
    top: 74%;
    left: 19%;
  }
  .indx-1 {
    top: 77%;
    left: 78%;
  }
  .indx-2 {
    top: 26%;
    left: 81%;
  }
}

.heroine_3 {
  position: absolute;
  top: 24%;
  left: 22%;
  visibility: hidden;
  width: 16%;
  z-index: 80;
  height: auto;

  &.in-center {
    top: 50%;
    left: 50%;
    opacity: .5;
    width: 7%;
    z-index: 40;
  }

  .heroine3_pic {
    transform: translate(-50%, -50%);
    @include icon-style();

    /* gsap3系への変更に伴い未使用になったのでコメントアウト
    &.pinknized {
      border-color: #FF5891;
      border-width: 6px;
      border-radius: 10%;
      width: 120%;
      height: 120%;

      @include mq-down {
        border-width: 3px;
      }
    }
    */
  }
}

.scene4 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.wood_frame {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 120;
  opacity: 0;
  filter: drop-shadow(1px 1px 3px rgba(0,0,0,0.3));
  width: 36%;

  .wood_frame_pic {
    width: 100%;
    transform: scale(1.1);
  }
}

.comp_back {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 50;
  transform: translate(-50%, -50%);
  width: 40%;
  opacity: 0;

  .comp_back_pic {
    width: 100%;
  }
}

</style>

<i18n>
{
  "ja": {
    "ssm_1": "どんなイラストがお好みですか？",
    "ssm_2": "100万種類以上のイラストをあなたのために描きます。",
    "ssm_3": "その子のようなテイストでいいですか？",
    "ssm_4": "では、そのイラストに似たキャラを描きますね。",
    "ssm_5": "さらにデザインを絞り込みました。",
    "ssm_6": "完成です！",
    "ssm_7": "SNSのアイコンや広告のデザインで利用する等、自由に使ってください。"
  },
  "en": {
    "ssm_1": "What kind of illustrations do you like?",
    "ssm_2": "I can draw more than 1 million kinds of illustrations for you.",
    "ssm_3": "Is this design OK?",
    "ssm_4": "If you are OK with this design, I will draw another design similar to this character.",
    "ssm_5": "Further refined the design.",
    "ssm_6": "Your illustration is done!",
    "ssm_7": "You can use it freely for SNS icons and advertising designs."
  },
  "fr": {
    "ssm_1": "Choisissez votre type d'image.",
    "ssm_2": "Je peux dessiner plus d’un million d'icônes faciales rien que pour vous.",
    "ssm_3": "Cette image vous convient-elle?",
    "ssm_4": "Je vais dessiner une image correspondant à vos choix!",
    "ssm_5": "J'ai affiné votre choix.",
    "ssm_6": "J'ai terminé!",
    "ssm_7": "Faites-en l'utilisation de votre choix: photo de profil, support publicitaire etc. "
  },
  "es": {
    "ssm_1": "¿Qué tipo de ilustraciones te gustan?",
    "ssm_2": "Dibujo más de 1,000,000 de ilustraciones para ti.",
    "ssm_3": "¿Te parece bien como quedó ella por ahora?",
    "ssm_4": "Entonces, voy a dibujar el personaje que se parece a esa ilustración.",
    "ssm_5": "Me ajusté más el diseño.",
    "ssm_6": "¡Está finalizado!",
    "ssm_7": "Por favor sientase libre de usarlo como ícono de SNS, diseño publicitario, etc."
  },
  "pt": {
    "ssm_1": "Que tipo de ilustração você prefere?",
    "ssm_2": "Capaz de desenhar mais de um milhão de tipos de ilustrações para você",
    "ssm_3": "O gosto da ilustração pode ser assim?",
    "ssm_4": "Desenhe uma ilustração assim",
    "ssm_5": "Reduzir o design",
    "ssm_6": "Concluído",
    "ssm_7": "Livre para usar como ícone do SNS ou design de anúncio"
  },
  "de": {
    "ssm_1": "Welche Art von Illustration magst du?",
    "ssm_2": "Ich habe über eine \r\nMillionen verschiedene Illustrationsmöglichkeiten für Dich.",
    "ssm_3": "Gefallen dir Charaktere wie dieser?",
    "ssm_4": "Ich werde nun einen ähnlichen Charakter zeichnen.",
    "ssm_5": "Ich habe das Design weiter verfeinert.",
    "ssm_6": "Fertig !",
    "ssm_7": "z.B. Als Avatar oder für Werbedesigns frei verwendbar."
  },
  "nl": {
    "ssm_1": "Wat voor soort illustraties do you like?",
    "ssm_2": "Ik kan meer dan 1.000.000 soorten illustraties trekken voor jou.",
    "ssm_3": "Is dit ontwerp OK?",
    "ssm_4": "Als u OK bent met dit ontwerp, zal ik een ander ontwerp vergelijkbaar met dit karakter te trekken.",
    "ssm_5": "Verder verfijnd het ontwerp.",
    "ssm_6": "Uw afbeelding wordt gedaan!",
    "ssm_7": "U kunt vrij gebruik van maken voor SNS iconen en reclame ontwerpen."
  },
  "tr": {
    "ssm_1": "tür resimlerin Size ne gibi do?",
    "ssm_2": "Senin için resimler 1 milyondan fazla çeşit çizebilirsiniz.",
    "ssm_3": "Bu tasarım iyi mi?",
    "ssm_4": "Bu tasarım ile Tamam, ben bu karaktere benzeyen başka tasarım çekecektir.",
    "ssm_5": "Ayrıca tasarım rafine.",
    "ssm_6": "Kişisel illüstrasyon yapılır!",
    "ssm_7": "Sen SNS simgeler ve reklam tasarımları için serbestçe kullanabilirsiniz."
  },
  "zh-cn": {
    "ssm_1": "您喜欢什么样的插图呢？",
    "ssm_2": "我可以为您画出１００万种以上的插图哦！",
    "ssm_3": "像这种人物风格好吗？",
    "ssm_4": "那我就画出这种风格的人物插图哦！",
    "ssm_5": "已把设计范围缩小",
    "ssm_6": "完成！",
    "ssm_7": "可以自由地使用在社交网络平台以及广告等的设计。"
  },
  "zh-tw": {
    "ssm_1": "您喜歡什麼樣的插圖呢？",
    "ssm_2": "我可以為您畫出100萬種以上的插圖哦！",
    "ssm_3": "像這種人物風格好嗎？",
    "ssm_4": "那我就畫出這種風格的人物插圖哦！",
    "ssm_5": "已把設計範圍縮小",
    "ssm_6": "完成！",
    "ssm_7": "可以自由地使用在社交網絡平台以及廣告等的設計。"
  },
  "sv": {
    "ssm_1": "Vilken typ av illustrationer gillar du?",
    "ssm_2": "Jag kan dra mer än 1 miljon typer av illustrationer för dig.",
    "ssm_3": "Är denna design OK?",
    "ssm_4": "Om du är OK med denna design, kommer jag dra en annan konstruktion som liknar denna karaktär.",
    "ssm_5": "Ytterligare förfinat designen.",
    "ssm_6": "Din illustration klar!",
    "ssm_7": "Du kan använda den fritt för SNS ikoner och reklam design."
  },
  "it": {
    "ssm_1": "Che tipo d'illustrazione preferisci?",
    "ssm_2": "Disegno più di 1 milione di tipi d'illustrazione per te.",
    "ssm_3": "Ti piace lo stile di questo/a ragazzo?",
    "ssm_4": "Allora, disegno un personaggio simile a questa illustrazione.",
    "ssm_5": "Ho raffinato ulteriormente il design.",
    "ssm_6": "È pronta!",
    "ssm_7": "Si prega di utilizzare liberamente come immagine profilo sui social network, come disegno pubblicitario, o altro..."
  },
  "ko": {
    "ssm_1": "어떤 일러스트를 선호하나요?",
    "ssm_2": "100만 가지 이상의 일러스트를 당신을 위해 그립니다.",
    "ssm_3": "저 아이와 같은 스타일은 어떻습니까?",
    "ssm_4": "그러면 그 일러스트와 비슷한 캐릭터를 그립니다.",
    "ssm_5": "더 많은 디자인을 추출했습니다.",
    "ssm_6": "완성입니다！",
    "ssm_7": "SNS 아이콘이나 광고 디자인에서 이용하는 등 자유롭게 사용하세요."
  },
  "ar": {
    "ssm_1": "أي نوع من الرسوم التوضيحية هل تحب؟",
    "ssm_2": "يمكنني رسم أكثر من 1 مليون أنواع من الرسوم التوضيحية بالنسبة لك.",
    "ssm_3": "هل هذا التصميم OK؟",
    "ssm_4": "إذا كنت موافق مع هذا التصميم، وسوف رسم تصميم آخر مماثل لهذا الطابع.",
    "ssm_5": "المكرر وعلاوة على ذلك التصميم.",
    "ssm_6": "ويتم التوضيح الخاص بك!",
    "ssm_7": "يمكنك استخدامه بحرية للرموز SNS والتصاميم الإعلانية."
  },
  "th": {
    "ssm_1": "ชอบรูปวาดแบบไหนคะ",
    "ssm_2": "ฉันสามารถวาดภาพให้คุณได้มากกว่า 1 ล้านรูปเลยค่ะ ",
    "ssm_3": "อยากได้รูปที่ให้ความรู้สึกเหมือนรูปนี้ใช่ไหมคะ",
    "ssm_4": "ถ้าอย่างนั้น จะวาดรูปที่คล้ายกับตัวละครนี้ให้นะ",
    "ssm_5": "การออกแบบที่ประณีต",
    "ssm_6": "เสร็จเรียบร้อย!",
    "ssm_7": "ลองเอาไปใช้เป็นไอคอนในSNSหรือใช้ในงานโฆษณาดูนะ"
  },
  "id": {
    "ssm_1": "Apa jenis ilustrasi yang kamu suka?",
    "ssm_2": "Aku bisa menarik lebih dari 1 juta jenis ilustrasi untuk Anda.",
    "ssm_3": "Apakah desain OK ini?",
    "ssm_4": "Jika Anda OK dengan desain ini, saya akan menggambar desain lain yang mirip dengan karakter ini.",
    "ssm_5": "Lebih halus desain.",
    "ssm_6": "ilustrasi Anda dilakukan!",
    "ssm_7": "Anda dapat menggunakannya secara bebas untuk SNS ikon dan desain iklan."
  },
  "pl": {
    "ssm_1": "Jaką ilustracjami lubisz?",
    "ssm_2": "Mogę wyciągnąć więcej niż 1 mln rodzaju ilustracjami dla Ciebie.",
    "ssm_3": "Czy ten projekt ok?",
    "ssm_4": "Jeśli są w porządku z tym wzorem, będę rysować inną konstrukcję podobną do tej postaci.",
    "ssm_5": "Dalsze dopracowali konstrukcję.",
    "ssm_6": "Twój ilustracja jest zrobiona!",
    "ssm_7": "Można go używać swobodnie SNS ikon i konstrukcji reklamowych."
  },
  "vi": {
    "ssm_1": "Những loại hình minh họa làm bạn như thế nào?",
    "ssm_2": "Tôi có thể rút ra hơn 1 triệu loại minh họa cho bạn.",
    "ssm_3": "Là thiết kế này OK?",
    "ssm_4": "Nếu bạn là OK với thiết kế này, tôi sẽ vẽ một thiết kế tương tự như nhân vật này.",
    "ssm_5": "Hơn nữa tinh chế thiết kế.",
    "ssm_6": "minh họa của bạn được thực hiện!",
    "ssm_7": "Bạn có thể sử dụng nó một cách tự do cho SNS biểu tượng và thiết kế quảng cáo."
  },
  "hi": {
    "ssm_1": "Aapaka pasandeeda kis tarah ka illustration hai?",
    "ssm_2": "Aapake lie miliyan se adhik prakaar ke illustration taiyaar karungi.",
    "ssm_3": "Kya aap is tarah svaad ke saath thik hain?",
    "ssm_4": "Phir, main charitr ko aakarshit karungi jo is illustration ke samaan hai.",
    "ssm_5": "Design neeche sankuchit thi.",
    "ssm_6": "Done!",
    "ssm_7": "Ise SNS icon ya prakaashan design ke roop mein use karane ke lie svatantr mahasoos karen."
  },
  "ru": {
    "ssm_1": "Какие иллюстрации вам нравится?",
    "ssm_2": "Я могу нарисовать более 1 миллиона видов иллюстраций для вас.",
    "ssm_3": "Является ли эта конструкция хорошо?",
    "ssm_4": "Если все в порядке с этим проектом, я буду рисовать другой дизайн, похожий на этот персонаж.",
    "ssm_5": "Далее усовершенствовали дизайн.",
    "ssm_6": "Ваша иллюстрация сделано!",
    "ssm_7": "Вы можете свободно использовать его для SNS иконок и рекламных конструкций."
  },
  "bn": {
    "ssm_1": "কি কনটেন্ট কপিরাইট আইনে পূর্বানুমতি ধরনের আপনি পছন্দ করেন?",
    "ssm_2": "আমি আপনার জন্য কনটেন্ট কপিরাইট আইনে পূর্বানুমতি বেশি 1 মিলিয়ন ধরণের আহরণ করতে পারে।",
    "ssm_3": "এই নকশা ঠিক আছে?",
    "ssm_4": "আপনি এই নকশা ঠিক মনে হয়, আমি অন্য নকশা এই অক্ষর অনুরূপ টেনে আনব।",
    "ssm_5": "আরও নকশা পরিশ্রুত।",
    "ssm_6": "তোমার চিত্রণ সম্পন্ন করা হয়!",
    "ssm_7": "আপনি এসএনএস আইকন এবং বিজ্ঞাপন ডিজাইন জন্য অবাধে ব্যবহার করতে পারেন।"
  }
}
</i18n>
