<template>
  <div id="ep">
    <service-header :auth="auth"/>
    <div id="ep-body">
      <div class="mainvisual">
        <img v-if="isSP" class="mainvisual-bg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/top_bg_sp@2x.webp"/>
        <img v-else class="mainvisual-bg" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/top_bg_pc.webp"/>
        <div class="catch-copy-container">
          <p class="catch-copy" v-html="$t('st_top_1_1')"></p>
          <img class="logo-type" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/ep_title_white.svg">
          <img class="logo-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg">
        </div>
      </div>

      <!-- ドロップエリア -->
      <div class="drop_area width-mv">
        <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area-inner" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <p class="white--text display-pc">{{ $t("st_top_5" )}}</p>
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" round outline dark @click="openFileSelect">
            {{ $t("st_top_6") }}
          </v-btn>
          <input ref="inputFile" type="file" id="target_image" name="target_image" accept=".jpg, .jpeg, .png" @change="onSelectFile"/>
        </v-layout>

        <v-layout align-center justify-center column fill-height v-else class="drop_area-inner">
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" @click="auth.login('login')" round outline dark>
            <!-- :small="$vuetify.breakpoint.smAndDown" -->
            {{ $t("st_top_4") }}
          </v-btn>
        </v-layout>
      </div>

      <!-- データページへの導線 -->
      <div v-if="logined" class="lead-other-page px-3">
        <p v-html="$t('glb_10', ['ep'])"></p>
      </div>

      <div class="aboutsf">
        <div class="base-container">
          <div class="display-pc brand-container">
            <v-img class="brand-icon" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/ep_logo.svg"></v-img>
          </div>
          <h1 class="aboutsf-heading">{{ $t("st_top_7") }}</h1>
          <p class="aboutsf-paragraph">
            {{ $t("st_top_8" )}}
          </p>
          <h3 class="aboutsf-subheading">
            <!-- 様々な画像に特別な<br class="hide-md-up">スタイルを当てはめられる -->
            {{ $t("st_top_9") }}
          </h3>
        </div>

        <div class="compare-container">
          <div v-for="(compare_pic, i) in getComparePics" :key=i>
            <h3 class="style-title">{{ $t(styleTitleI18nIds[i]) }}</h3>
            <div class="compare-box">
              <div class="compare-before">
                <div class="before-pic" :style="`background-image:url(${compare_pic[0]})`"></div>
              </div>
              <div class="arrow-container">
                <v-img class="transfer-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/transfer_arrow.svg"></v-img>
              </div>
              <div class="compare-after">
                <div class="after-pic" :style="`background-image:url(${compare_pic[1]})`"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="about-technology">
        <div class="base-container">
          <h2 class="about-technology-h2">
            {{ $t("st_top_13") }}
          </h2>
          <p class="technology-description">{{ $t("st_top_14") }}</p>
        </div>
      </div>

      <!-- 画家一覧 -->
      <div class="painters-area">
        <div class="base-container">
          <h2 class="painters-header">{{ $t("st_top_37") }}</h2>
          <template v-for="(genre, genreIdx) in paintersMaster.genres">
            <!-- 将来ジャンルの区分ができたらジャンル名を表示するかもしれない -->
            <div class="painter-list-container" :key="'painter-list-container-' + genreIdx">
              <template v-for="(painter, idx) in genre.painters">
                <div
                  class="painter-container"
                  :key="'painter-' + idx">
                  <div class="painter-explanation-container">
                    <p class="painter-name">{{ $t(painter.i18nNameId) }}</p>
                    <p class="painter-subtitle">{{ $t(painter.i18nSubtitleId )}}</p>
                    <img class="face-image" :src="painter.faceImageURL" />
                    <img class="eye-catching-image" :src="painter.eyeCatchingImageURL" />
                    <p class="display-sp painter-description">
                      {{ $t(painter.i18nDescriptionId) }}
                    </p>
                  </div>
                  <!-- 元写真 & 変換例 -->
                  <top-carousel :afterImageURLs="painter.afterImageURLs" :beforeImageURLs="painter.beforeImageURLs"/>
                  <!-- 吹き出し(PCのみ) + 変換ボタン -->
                  <div class="decide-button-area">
                    <img class="display-pc balloon-tail" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/dotted_balloon_tail.svg">
                    <span class="display-pc white-mask"/>
                    <div class="display-pc painter-description">
                      <p>{{ $t(painter.i18nDescriptionId) }}</p>
                    </div>
                    <v-btn slot="activator" class="draw-btn" dark round large block @click="selectPainter(painter.artistId)">
                      {{ $t("st_top_38") }}
                    </v-btn>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="usage_steps">
        <h2 class="usage_steps-heading">{{ $t("st_top_16") }}</h2>
        <div class="base-container">
          <div class="steps-flex">
            <div class="one-step">
              <p class="step-number">①</p>
              <h3 class="step-title">
                <!-- <span>ファイルを</span>
                <span>アップロード</span> -->
                <span>{{ $t("st_top_17") }}</span>
              </h3>
              <div class="upload-pic-container">
                <v-img class="upload-icon" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg"></v-img>
                <v-img class="original-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_0.webp"></v-img>
              </div>
              <p class="step-description">
                {{ $t("st_top_20") }}
              </p>
            </div>
            <div class="one-step">
              <p class="step-number">②</p>
              <h3 class="step-title">
                <!-- <span>当てはめる</span>
                <span>スタイルの選択</span> -->
                <span>{{ $t("st_top_18") }}</span>
              </h3>
              <div class="style-pics-box">
                <v-img class="style-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_2.webp"></v-img>
                <v-img class="style-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_3.webp"></v-img>
                <v-img class="style-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_4.webp"></v-img>
                <v-img class="style-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_5.webp"></v-img>
              </div>
              <p class="step-description">
                {{ $t("st_top_21") }}
              </p>
            </div>
            <div class="one-step">
              <p class="step-number">③</p>
              <h3 class="step-title">
                <!-- <span>変換された</span>
                <span>スタイルをget!</span> -->
                <span>{{ $t("st_top_19") }}</span>
              </h3>
              <div class="result-pic-container">
                <v-img class="result-pic" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/step_1.webp"></v-img>
              </div>
              <p class="step-description">
                {{ $t("st_top_22") }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- ドロップエリア -->
      <div class="drop_area">
        <v-layout align-center justify-center column fill-height v-if="logined || guestExec" class="drop_area-inner" @drop.prevent="onDropFile" @dragenter.prevent @dragover.prevent>
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <p class="white--text display-pc">{{ $t("st_top_5") }}</p>
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" round outline dark @click="openFileSelect">
            {{ $t("st_top_6") }}
          </v-btn>
          <input ref="inputFile" type="file" id="target_image" name="target_image" accept=".jpg, .jpeg, .png" @change="onSelectFile"/>
        </v-layout>

        <v-layout align-center justify-center column fill-height v-else class="drop_area-inner">
          <img class="upload-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/icon/upload_icon.svg">
          <v-btn class="text-none drop_area-button" :class="isLongLang ? 'small-text' : ''" @click="auth.login('login')" round outline dark>
            <!-- :small="$vuetify.breakpoint.smAndDown" -->
            {{ $t("st_top_4") }}
          </v-btn>
        </v-layout>
      </div>

      <!-- 広告 -->
      <ad-responsive></ad-responsive>

      <div class="realization">
        <h2 class="realization-heading">
          <!-- <span class="maker-line">
            Style Transferは
          </span>
          <span class="maker-line">
            こんなことに使うことができます
          </span> -->
          <span class="maker-line display-pc">
            {{ $t("st_top_23") }}
          </span>
          <span class="display-sp">
            {{ $t("st_top_23") }}
          </span>
        </h2>
        <div class="base-container">
          <!-- 多種多様なデザイン素材 -->
          <div class="realization-container">
            <div class="realization-head">
              <h3 class="realization-title">
                <span class="realization-title-text">
                  {{ $t("st_top_24") }}
                </span>
              </h3>
            </div>
            <div class="realization-body">
              <div class="realization-body-previous">
                <p class="realization-description">
                  {{ $t("st_top_25") }}
                </p>
              </div>
              <div class="realization-body-following">
                <div v-if="isSP" class="realization-images">
                  <v-img class="realization-image-1" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_1_sp@2x.webp"></v-img>
                  <div class="bottom-images">
                    <v-img class="realization-image-2" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_2_sp@2x.webp"></v-img>
                    <v-img class="realization-image-3" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_3_sp@2x.webp"></v-img>
                  </div>
                </div>
                <div v-else class="realization-body-row-container">
                  <div class="left-images">
                    <v-img max-width="380" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_1.webp"></v-img>
                    <v-img max-width="380" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_2.webp"></v-img>
                  </div>
                  <v-img max-width="240" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/element_3.webp"></v-img>
                </div>
              </div>
            </div>
          </div>

          <!-- 特別なプレゼントに -->
          <div class="realization-container">
            <div class="realization-head">
              <h3 class="realization-title">
                <span class="realization-title-text">
                  {{ $t("st_top_26") }}
                </span>
              </h3>
            </div>
            <div class="realization-body">
              <div class="realization-body-previous">
                <p class="realization-description">
                  {{ $t("st_top_27") }}
                </p>
                <v-img v-if="!isSP" class="realization-previous-img" min-width="163" max-width="163" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/present_1.webp"></v-img>
              </div>
              <div class="realization-body-following">
                <v-img class="realization-following-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/present_2.webp"></v-img>
              </div>
              <v-img v-if="isSP" class="present-previous-img-present" min-width="120" max-width="120" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/present_1.webp"></v-img>
              <img v-if="isSP" class="arrow-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/transfer_arrow.svg"/>
            </div>
          </div>

          <!-- デザインを創り出せる -->
          <div class="realization-container">
            <div class="realization-head">
              <h3 class="realization-title">
                <span class="realization-title-text">
                  {{ $t("st_top_28") }}
                </span>
              </h3>
            </div>
            <div class="realization-body">
              <div class="realization-body-previous less-right-margin">
                <p class="display-pc realization-description short-text">
                  {{ $t("st_top_29") }}
                </p>
                <p class="display-sp realization-description">
                  {{ $t("st_top_29") }}
                </p>
                <v-img v-if="!isSP" class="realization-previous-img with-overflow-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_0@2x.webp"></v-img>
              </div>
              <div class="realization-body-following">
                <div class="realization-body-grid-container">
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_1.webp"/>
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_2.webp"/>
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_3.webp"/>
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_4.webp"/>
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_5.webp"/>
                  <img class="grid-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_6.webp"/>
                </div>
              </div>
              <div v-if="isSP" class="textile-previous-img-container">
                <v-img class="textile-previous-img with-overflow-arrow" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/Textile_0@2x.webp"></v-img>
                <img class="arrow-img" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/transfer_arrow.svg"/>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="about-api">
        <div class="base-container">
          <h2 class="about-api-h2">
            <span class="maker-line">
              cre8tiveAI
            </span>
            <br class="display-sp">
            <span class="maker-line">
              Enpainter API
            </span>
          </h2>
          <h3 class="about-api-heading">
            <!-- <span>APIを使って</span>
            <span>各種システムとの連携を</span>
            <span>行うことが可能です</span> -->
            <span>{{ $t("st_top_31") }}</span>
          </h3>
          <p class="api-descriptions">
            {{ $t("st_top_32") }}
          </p>
          <v-img class="main-illust display-pc" src="https://storage.googleapis.com/cre8tiveai_public_files/img/ep/api_main_illust_pc.svg">
            <p class="p-request" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_33") }}</p>
            <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_34") }}</p>
            <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_35") }}</p>
          </v-img>
          <v-img class="main-illust display-sp" contain src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/07_about_api/api_main_illust_sp.svg">
            <p class="p-request" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_33") }}</p>
            <p class="p-get" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_34") }}</p>
            <p class="p-service" :class="isLongLang ? 'small-text' : ''">{{ $t("st_top_35") }}</p>
          </v-img>
          <v-btn class="api-btn" to="/#g-form-area">
            <span class="dotted">{{ $t("st_top_36") }}</span>
          </v-btn>
        </div>
      </div>

      <!-- 依頼モーダル -->
      <order-modal-container
        ref="orderModalRef"
        :show="showOrderModal"
        :auth="auth"
        @close="closeOrderModal"
      >
      </order-modal-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import OrderModalContainer from "./OrderModalContainer.vue"
import ServiceHeader from '@/components/ServiceHeader.vue'
import AdResponsive from '@/components/GoogleAdsense/AdResponsive.vue'
import TopCarousel from './TopCarousel.vue'
import AuthService from '@/auth/AuthService'

export default {
  name: 'EnpainterTop',
  props: ['auth'],
  components: {
    OrderModalContainer,
    ServiceHeader,
    AdResponsive,
    TopCarousel
  },
  data() {
    return {
      isSP: window.innerWidth < 768, // iPad未満
      showOrderModal: false,
      comparePics: [
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/101.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/102.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/103.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/104.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/105.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/106.webp"],
      ],
      comparePicsSP: [
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/101_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/102_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/103_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/104_sp.webp"],
        ["https://storage.googleapis.com/cre8tiveai_public_files/img/ep/105_sp.webp","https://storage.googleapis.com/cre8tiveai_public_files/img/ep/106_sp.webp"],
      ],
      styleTitleI18nIds: [
        "st_top_10",
        "st_top_11",
        "st_top_12"
      ],
    }
  },
  computed: {
    logined() {
      return this.$store.getters['user/logined']
    },
    guestExec() {
      return this.$store.getters['user/guestExec']
    },
    getComparePics() {
      if (this.isSP) {
        return this.comparePicsSP;
      } else {
        return this.comparePics;
      }
    },
    ...mapGetters("enpainter", [
      "paintersMaster"
    ]),
  },
  methods: {
    // ファイル選択 > 画家選択モーダル表示
    onDropFile(e) {
      // モーダルを開く前にstoreをreset (変換完了して戻ってきた場合でも確実にresetする)
      this.$store.commit('enpainter/reset')

      if (!this.logined && !this.guestExec) return
      const files = []
      // FileList型はforEachできないためforで回す
      for (var i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i]
        files.push(file)
      }
      this.$store.commit('enpainter/setTargetImageFile', files)
      this.showOrderModal = true;
    },
    openFileSelect() {
      this.$refs.inputFile.click()
    },
    onSelectFile(e) {
      this.$store.commit('enpainter/reset')

      const files = []
      for (var i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i]
        files.push(file)
      }
      this.$store.commit('enpainter/setTargetImageFile', files)
      this.showOrderModal = true;
    },
    // 画家選択 > ファイルdropモーダル表示
    selectPainter(artistId) {
      this.$store.commit('enpainter/reset')

      this.$store.commit('enpainter/setArtist', artistId)
      this.showOrderModal = true;
    },
    closeOrderModal() {
      this.$store.commit('enpainter/cleartargetImageFile')
      this.$store.commit('enpainter/setArtist', null)
      this.showOrderModal = false;
      this.$refs.inputFile.value = ''
    }
  },
  watch: {
    '$route': function (n) {
      if (n.hash.match(/^#/)) {
        document.getElementById(n.hash.replace(/^#/, '')).scrollIntoView()
      }
    }
  },
  created() {
    // 結果ページ(=header非表示)から戻ってきた時を考慮し、明示的にtrue
    this.$store.commit('screen/headerVisible', true)
    this.$store.commit('screen/footerVisible', true)
    this.$store.commit('enpainter/reset')
    if (!AuthService.isAuthenticated(1)) this.$store.dispatch('user/getGuestUser')
  },
  mounted() {
    // v-htmlでaタグを使用すると非SPA遷移になるので、SPA遷移になるように改良
    // SPA遷移だと遷移スピードも速いし、ServiceHeader経由ですぐサービストップページに戻ることができる
    const targetElems = document.getElementsByClassName('to-data-page')
    if (targetElems.length) {
      const dataPageLink = targetElems[0]
      dataPageLink.addEventListener('click', (event) => {
        event.preventDefault()
        this.$router.push(dataPageLink.getAttribute('href'))
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import url(https://use.typekit.net/ddm1tzj.css);

* {
  color: #424242;
}

@mixin container_base($width: 1028px) {
  padding: 0 56px;
  overflow: hidden;

  @include mq-down(md) {
    padding: 0 24px;
  }

  .base-container {
    position: relative;
    max-width: $width;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin base_heading {
  text-align: center;
  font-weight: 500;
  font-size: 48px;

  @include mq-down(lg) {
    font-size: 40px;
  }

  @include mq-down {
    font-size: 400;
    font-size: 25px;
    line-height: 30px;
  }
}

@mixin base_subheading {
  text-align: center;
  font-size: 40px;
  font-weight: 500;

  @include mq-down(lg) {
    font-size: 32px;
  }

  @include mq-down(md) {
    font-size: 400;
    font-size: 25px;
    line-height: 30px;
  }
}

#ep-body {
  font-family: 'noto-sans-cjk-jp', 'Noto Sans CJK JP', 'Source Han Sans JP', '\30D2\30E9\30AE\30CE\89D2\30B4   ProN W3', 'Hiragino Kaku Gothic ProN', 'HiraginoSans-W3', 'Avenir', Helvetica, Arial, sans-serif;
}

.mainvisual {
  position: relative;
  width:100%;
  .mainvisual-bg {
    width: 100%;
  }
  .catch-copy-container {
    position: absolute;
    background: linear-gradient(30deg, #FFAB38, #985A2F);
    text-align: left;
    top: 48%;
    left: 20%;
    padding: 12px 10px;
    @include mq-down(md) {
      top: 48%;
      left: 24px;
      padding: 12px 10px;
    }
    .catch-copy {
      color: #fff;
      font-size: 25px;
      line-height: 32px;
      //font-weight: lighter;
      text-align: left;
      margin-bottom: 8px;
    }
    .logo-type {
      width: 86px;
    }
    .logo-icon {
      position: absolute;
      bottom: 6px;
      left: 106px;
      width: 20px;
    }
  }
}

// ドロップエリア
.drop_area {
  background: linear-gradient(99deg, #FFAB38, #93562F);
  max-width: 980px;
  margin: 40px auto 0;
  border-radius: 16px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  bottom: 0;
  height: 188px;

  &.width-mv {
    margin-top: -6vw;
  }

  @include mq-up(xl) {
    height: 200px;
  }

  @include mq-down(xl) {
    height: 200px;
    height: 182px;
  }

  @include mq-down(lg) {
    margin-right: 32px;
    margin-left: 32px;
  }

  @include mq-down(md) {
    height: 172px;
  }

  @include mq-down(sm) {
    height: 172px;
  }

  @include mq-down(md) {
    margin-right: 16px;
    margin-left: 16px;
    height: initial;
    padding: 10px;

    &.width-mv {
      margin-top: -52px;
    }
  }

  @include mq-down(xs) {
    margin-right: 8px;
    margin-left: 8px;
    height: 120px;
    padding: 8px;
  }

  p {
    margin-bottom: 0px;

    @include mq-up {
      font-size: calc(0.135vw + 12px);
    }
  }

  .drop_area-inner {
    max-width: 976px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed #fff;
    padding: 8px;

    .upload-img {
      height: 48px;
      margin-top: 8px;
      margin-bottom: 8px;

      @include mq-down(md) {
        margin-bottom: 0px;
      }

      @include mq-down(sm) {
        height: 40px;
      }
    }

    .drop_area-button {
      font-size: 14px;
      margin: 8px;
      @include mq-down {
        padding: 0 32px;
      }
      @include mq-down(sm) {
        font-size: 12px;
      }
      @include mq-down(xs) {
        &.small-text {
          font-size: 10px;
          padding: 0 16px;
        }
      }
    }
  }

  input {
    display: none;
  }
}

// データページへの導線
.lead-other-page {
  @include container_base;
  margin-top: 10px;
  @include mq-down {
    margin-top: 5px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    @include mq-down {
      font-size: 12px;
    }
    @include mq-down(sm) {
      font-size: 10px;
    }
    /deep/ a {
      color: #0D47A1;
      cursor: pointer;
    }
  }
}

.aboutsf {
  @include container_base(1040px);
  margin-top: 64px;
  @include mq-down {
    margin-top: 0;
  }

  .brand-container {
    border-radius: 99px;
    box-shadow: 0px 3px 6px #00000029;
    width: 80px;
    height: 80px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .brand-icon {
      width: 38px;
      flex: none;
      margin-left: 4px;
    }
  }

  .aboutsf-heading {
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    max-width: 720px;
    line-height: 55px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    @include mq-down {
      font-size: 25px;
      font-weight: 400;
      line-height: 30px;
    }
  }

  .aboutsf-paragraph {
    margin-top: 32px;
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;

    @include mq-down {
      font-size: 16px;
      line-height: 32px;
      font-weight: 400;
    }
  }

  .aboutsf-subheading {
    margin-top: 96px;
    @include base_subheading;
    @include mq-down {
      margin-top: 88px;
      font-weight: 400;
    }
  }

  .compare-container {
    margin-top: 96px;
    position: relative;
    max-width: 808px;
    margin-left: auto;
    margin-right: auto;
    @include mq-down {
      margin-top: 25px;
    }

    .style-title {
      margin-top: 48px;
      font-size: 32px;
      text-align: center;
      font-weight: 400;

      @include mq-down {
        font-size: 18px;
        line-height: 30px;
      }
    }

    .compare-box {
      margin-top: 8px;
      position: relative;

      @include mq-down(md) {
        margin: 8px 0 0;
      }
    }

    @mixin text_before_after {
      position: absolute;
      font-size: 40px;
      display: inline-block;
      top: -60px;

      @include mq-down(md) {
        font-size: 30px;
        top: -40px;
        left: 0;
        right: initial;
        transform: none;
      }
    }

    @mixin compare_pic {
      background-size: cover;
      background-repeat: no-repeat;
      max-height: 300px;
      max-width: 420px;
      height: 28vw;
      width: calc(52.63vw + -64.2px);

      @include mq-down(md) {
        height: 40vw;
        max-width: initial;
        width: initial;
      }
    }

    .compare-before {
      position: relative;
      top: 0;
      left: 0;

      .before-pic {
        @include compare_pic;
        background-position-x: left;

        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
        @include mq-down(md) {
          clip-path: none;
        }
      }
    }

    .compare-after {
      position: absolute;
      top: 0;
      right: 0;

      @include mq-down(md) {
        position: relative;
        margin-top: 16px;
      }

      .after-pic {
        @include compare_pic;
        background-position-x: left;

        clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
        @include mq-down(md) {
          clip-path: none;
        }
      }
    }

    .arrow-container {
      background-color: white;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      position: absolute;
      bottom: 118px;
      transform: translateY(-50%);
      top: 50%;
      right: 0;
      left: 0;
      margin-left: auto;
      margin-right: auto;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0px 3px 6px rgba(black, .16);

      @include mq-down(lg) {
        height: 10vw;
        width: 10vw;
      }

      @include mq-down(md) {
        top: initial;
        transform: rotate(90deg) translateX(50%);
        height: 18vw;
        width: 18vw;
        bottom: 50%;
      }

      .transfer-arrow {
        margin: 24% 16% 0 20%;
      }
    }
  }
}

.about-technology {
  background-color: #F2FF52;
  margin-top: 69px;
  overflow: hidden;
  @include container_base(924px);
  .about-technology-h2 {
    font-size: 40px;
    font-weight: 500;
    margin-top: 81px;
    @include mq-down {
      margin-top: 54px;
      font-size: 25px;
      line-height: 30px;
      font-weight: 400;
    }
  }
  .technology-description {
    max-width: 810px;
    font-size: 24px;
    font-weight: 500;
    line-height: 48px;
    margin-top: 50px;
    margin-bottom: 134px;
    margin-left: auto;
    margin-right: auto;
    @include mq-down {
      margin-top: 25px;
      margin-bottom: 84px;
      font-size: 16px;
      line-height: 32px;
      font-weight: 400;
    }
  }
}

.painters-area {
  margin-top: 80px;
  @include container_base();
  overflow-x: scroll; // 横幅1024pxのときに右端が少し切れるためscrollで表示可能にする
  @include mq-down {
    margin-top: 70px;
    padding: 0;
  }

  .painters-header {
    font-size: 40px;
    font-weight: 500;
    margin-bottom: 50px;
    @include mq-down {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 33px;
      font-weight: 400;
    }
  }
  .painter-container {
    display: flex;
    flex-flow: row;
    @include mq-up {
      min-width: 1024px;
    }
    @include mq-down {
      flex-flow: column;
    }
    .painter-explanation-container {
      position: relative;
      width: 360px; // なぜか指定が効かない? ので、painter-nameのsize + marginで設定する
      height: 330px;
      margin-right: 14px;
      @include mq-down {
        margin-right: 0;
        width: 100%;
        height: unset;
      }
      .painter-name {
        margin-top: 29px;
        margin-left: 29px;
        margin-right: 29px;
        width: 302px;
        text-align: center;
        font-size: 21px;
        font-weight: 700;
        color: #9F5F30;
        padding-bottom: 6px;
        border-bottom: solid #9F5F30 1px;
        @include mq-down {
          margin-left: auto;
          margin-right: auto;
        }
      }
      .painter-subtitle {
        margin-left: auto;
        margin-right: auto;
        color: #9F5F30;
        font-size: 16px;
        font-weight: 500;
        max-width: 176px;
        line-height: 20px;
      }
      .face-image {
        position: absolute;
        top: 80px;
        right: 24px;
        width: 60px;
        height: 60px;
      }
      .eye-catching-image {
        position: absolute;
        bottom: 20px;
        left: 75px;
        width: 210px;
        @include mq-down {
          position: static;
          width: 150px;
          margin-top: 16px;
          margin-left: auto;
          margin-right: auto;
        }
      }
      // SPのみ
      .painter-description {
        margin: 15px 17px;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        color: #93562F;
        text-align: left;
      }
    }
    &:nth-child(odd) {
      .painter-explanation-container {
        background-color: #FFC77C;
      }
    }
    &:nth-child(even) {
      .painter-explanation-container {
        background-color: #EBFC6F;
      }
    }
    .decide-button-area {
      position: relative;
      height: 330px;
      @include mq-down {
        height: 88px;
      }
      .balloon-tail {
        position: absolute;
        top: 46px;
        left: -10px;
        width: 48px;
        height: 32px;
        z-index: 3;
        transform: rotateY(180deg);
      }
      // balloon-tailの根元部分の点線を隠す
      .white-mask {
        position: absolute;
        top: 45px;
        left: 23px;
        width: 12px;
        height: 34px;
        z-index: 5;
        background-color: #fff;
      }
      .painter-description {
        margin-left: 20px;
        width: 240px;
        padding: 8px 12px 8px 16px;
        background-color: #fff;
        p {
          font-size: 15px;
          line-height: 23px;
          color: #93562F;
          text-align: left;
          margin: 0;
        }
        border: 2.5px dashed #9F5F30;
        border-radius: 4px;
      }
      .draw-btn {
        position: absolute;
        bottom: 0px;
        left: 28px;
        display: inline-block;
        background-color: #FFAB38;
        font-size: 20px;
        font-weight: 500;
        height: 55px;
        border-radius: 99px;
        max-width: 240px;

        @include mq-down {
          position: relative;
          left: 0px;
          display: inline-flex;
          margin: 8px auto 24px auto;
          padding: 0;
          font-size: initial;
          height: 44px;
          width: 285px;
        }
        /deep/ .v-btn__content {
          width: 100%;
        }
      }
    }
  }
}

.usage_steps {
  @include container_base(1028px);
  margin-top: 160px;
  background-color: #FFAB38;
  text-align: center;
  padding: 0 32px;
  @include mq-down {
    margin-top: 68px;
  }

  * {
    color: white;
  }

  .usage_steps-heading {
    @include base_heading;
    margin-top: 48px;
  }

  .steps-flex {
    margin-top: 48px;
    display: flex;
    margin-bottom: 64px;

    @include mq-down(md) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }

    .one-step {
      flex: 1 300px;

      @include mq-up {
        &:nth-child(2) {
          margin: 0 56px;

          @include mq-down(lg) {
            margin: 0 32px;
          }
        }
      }

      @include mq-down {
        max-width: 512px;
        margin-bottom: 43px;
      }

      .step-number {
        font-size: 48px;

        @include mq-down(lg) {
          margin-bottom: 0px;
        }
      }
      .step-title {
        font-size: 27px;
        font-weight: 500;
        height: 76px;
        text-align: center;
        margin: 0 -12px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;

        @include mq-down(lg) {
          font-size: calc(0.87vw + 17.4px);
          margin-bottom: 17px;
        }

        @include mq-down {
          height: initial;
          font-weight: medium;
          //font-size: calc(1.79vw + 18.3px);
          font-size: 25px;
        }
      }
      .step-description {
        margin-top: 54px;
        font-size: 18px;
        text-align: left;
        font-weight: 500;
        line-height: 30px;

        @include mq-down {
          margin-top: calc(2.24vw + 6.8px);
          font-size: 14px;
          line-height: 28px;
          margin-bottom: 0;
        }
      }

      // ステップ１
      .upload-pic-container {
        max-height: 240px;
        height: calc(36.76vw + -142.4px);
        margin-top: 24px;
        display: flex;
        position: relative;
        align-items: flex-end;

        @include mq-down(md) {
          height: initial;
          max-height: initial;
          flex-direction: column;
          align-items: center;
        }

        .upload-icon {
          @include mq-up(md) {
            position: absolute;
            transform: translateX(-50%);
            top: -2%;
            left: 50%;
            width: 98px;
            max-height: 75px;
            height: calc(9.93vw + -28.2px);

            @include mq-down(lg) {
              top: calc(6.93vw + -81.2px);
            }
          }

          @include mq-down(md) {
            width: calc(11.19vw + 64.2px);
          }
        }
        .original-pic {
          @include mq-up(md) {
            max-height: 150px;
            height: calc(25.74vw + -117.6px);
            margin: 0 40px;

            @include mq-down(xl) {
              margin: 0 calc(5vw + -20px);
            }

            @include mq-down(lg) {
              margin: 0 calc(3.46vw + -6.6px);
              height: calc(21.65vw + -56.2px);
            }
          }

          @include mq-down(md) {
            margin-top: calc(2.24vw + 6.8px);
            //width: calc(17.9vw + 162.7px);
            width: 300px;
            height: 200px;

            /deep/.v-image__image {
              background-size: contain;
            }
          }
        }
      }
      // ステップ２
      .style-pics-box {
        max-height: 240px;
        height: calc(36.76vw + -142.4px);
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include mq-down {
          max-height: initial;
          height: initial;
          justify-content: space-around;
        }

        .style-pic {
          width: 47%;
          height: 45%;
          flex: none;
          position: relative;
          z-index: 1;
          overflow: visible;
          @include mq-down {
            width: 146px;
            height: 106px;
          }

          &:nth-child(3),&:nth-child(4) {
            margin-top: auto;

            @include mq-down {
              margin-top: 16px;
            }
          }

          &:nth-child(3) {
            &:after {
              content: '';
              position: absolute;
              border: 3px solid #F2FF52;
              transform: translate(-50%, -50%);
              border-radius: 5px;
              top: 50%;
              left: 50%;
              width: calc(100% + 20px);
              height: calc(100% + 20px);
              z-index: 4;
            }
          }
        }
      }
      // ステップ３
      .result-pic-container {
        max-height: 240px;
        height: calc(36.76vw + -142.4px);
        margin-top: 24px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: solid 7px #93562F;

        @include mq-down {
          max-height: initial;
          height: initial;
        }

        .result-pic {
          width: 82.7%;
          flex: none;

          @include mq-down {
            margin: calc(2.68vw + 7.4px) 0;
          }
        }
      }
    }
  }
}

.realization {
  @include container_base;

  .realization-heading {
    @include base_heading;
    margin: 112px -32px 77px;

    @include mq-down {
      max-width: 90%;
      font-size: calc(4.47vw + 5.7px);
      margin: 32px 18px;
    }
  }

  .realization-container {
    margin-bottom: 96px;

    @include mq-down {
      margin-bottom: 38px;
    }

    .realization-head {
      display: flex;
      margin-bottom: 40px;

      @include mq-down {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 8px;
      }

      .realization-title {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: relative;
        padding: 32px 32px;
        color: #424242;
        border: dashed 3px #424242;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: #F2FF52;
        z-index: 3;

        @include mq-down(md) {
          padding: 16px 16px;
          //margin-top: 28px;
          margin-bottom: 32px;
        }

        .realization-title-text {
          position: relative;
          z-index: 1;
          font-weight: bold;
          font-size: 32px;

          @include mq-down(cn) {
            font-size: calc(3.91vw + -10px);
          }

          @include mq-down(md) {
            font-size: calc(2.24vw + 10.8px);
          }
        }
        &:after {
          content: '';
          background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/ep/dotted_balloon_tail_yellow.webp);
          background-size: contain;
          width: 37px;
          height: 42px;
          position: absolute;
          top: calc(100% - 4px);
          left: 50%;
          transform: translateX(-50%);
          z-index: 5;
        }
      }
    }

    .realization-body {
      position: relative;
      display: flex;

      @include mq-down {
        flex-direction: column-reverse;
      }

      .realization-body-previous {
        flex: 3;
        margin-right: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &.less-right-margin {
          margin-right: 8px;
        }

        @include mq-down {
          flex: initial;
          margin: 0;
        }

        .realization-description {
          font-size: 18px;
          text-align: left;
          margin-bottom: 32px;
          line-height: 30px;
          &.short-text {
            width: 284px;
          }

          @include mq-down {
            font-size: calc(0.89vw + 11.2px);
            margin-top: 15px;
            margin-bottom: 16px;
          }
        }

        .realization-previous-img {
          margin-right: 56px;
          overflow: visible;
          margin-left: auto;
          width: 200px;
          height: 165px;

          @include mq-down {
            margin: 0;
            align-self: center;
            width: 164px;
            height: 135px;
          }

          &:after {
            content: '';
            position: absolute;
            transform: translateY(-50%);
            top: 50%;
            right: -76px;
            height: 64px;
            width: 64px;
            background-image: url(https://storage.googleapis.com/cre8tiveai_public_files/img/ep/transfer_arrow.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;

            @include mq-down(lg) {
              width: calc(5.19vw + 12.1px);
              height: calc(5.19vw + 12.1px);
              right: calc(-6.06vw + -15.5px);
            }

            @include mq-down {
              right: 50%;
              transform: translateX(50%) rotateZ(90deg);
              top: initial;
              width: 80px;
              height: 80px;
              bottom: -100px;
            }
            @include mq-down(sm) {
              height: 56px;
              width: 56px;
              bottom: -64px;
            }
          }
          &.with-overflow-arrow {
            &:after {
              right: -42px;
            }
          }
        }
      }

      .present-previous-img-present {
        position: absolute;
        top: -18px;
        right: -12px;

      }
      .arrow-img {
        position: absolute;
        top: 96px;
        right: 82px;
        transform: rotateY(180deg);
        width: 68px;
        height: 68px;
      }

      .textile-previous-img-container {
        position: relative;
        .textile-previous-img {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 52px;
          @include mq-down {
            width: 60%;
          }
        }
        .arrow-img {
          position: absolute;
          top: 60%;
          right: 130px;
          transform: scale(-1, 1) rotate(90deg);
          width: 18%;
        }
      }

      .realization-body-following {
        flex: 5;
        align-self: flex-end;

        @include mq-down {
          flex: initial;
          width: 100%;
          max-width: 512px;
          align-self: center;
        }

        .realization-following-img {
          width: 100%;
        }

        .realization-body-row-container {
          display: flex;
          flex-flow: row nowrap;
          .left-images {
            width: 380px;
            height: 348px;
          }
        }
        .realization-images {
          .realization-image-1 {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
          .bottom-images {
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            .realization-image-2, .realization-image-3 {
              width: 50%;
            }
          }
        }
        .realization-body-grid-container {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          .grid-image {
            max-width: 200px;
            max-height: 165px;
            width: 32%;
            // NOTE. 3番目「まで」の指定
            &:nth-child(-n + 3) {
              margin-bottom: 17px;
            }
            @include mq-down {
              width: calc(50% - 4px);
              max-width: unset;
              max-height: unset;
              height: unset;
              margin-bottom: 8px;
              margin-right: 0px;
              margin-left: 0px;
              &:nth-child(-n + 3) {
                margin-bottom: 8px;
              }
              &:nth-child(even) {
                margin-left: 8px;
                margin-right: 0px;
              }
              &:nth-child(5) {
                margin-left: 0px;
                margin-right: 0px;
              }
            }
          }
        }
      }
    }
  }
}

.about-api {
  background-color: #DEDEDE;
  margin-top: 0;
  overflow: hidden;
  @include container_base(924px);

  .about-api-h2 {
    margin: 96px -8px 64px;
    padding: 0;
    font-size: 48px;

    @include mq-down {
      font-size: calc(2.68vw + 23.4px);
      margin-top: 45px;
      margin-bottom: 34px;
    }

    .maker-line {
      @include mq-up {
        &:first-child {
          padding-right: 10px;
        }
      }
    }
  }

  .about-api-heading {
    margin-top: 72px;
    font-size: 48px;
    font-weight: 600;
    padding: 0 58px;

    span {
      max-width: 852px;
      line-height: 1.6;
    }

    @include mq-down(xxl) {
      font-size: calc(2.38vw + 13.7px);
    }

    @include mq-down(lg) {
      margin-top: 32px;
    }

    @include mq-down(md) {
      margin-top: 0px;
      font-size: 25px;
      line-height: 28px;
      padding: 0;
    }
  }

  .api-descriptions {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
    padding: 0 58px;
    @include mq-down(xxl) {
      font-size: calc(0.89vw + 7.1px);
    }
    @include mq-down(md) {
      margin-top: 24px;
      font-size: 16px;
      line-height: 32px;
      padding: 0;
    }
  }
  .main-illust {
    margin: 104px 40px 96px;
    position: relative;
    overflow: visible;
    @include mq-down(xxl) {
      margin: 104px calc(-8.93vw + 168.6px) 64px;
    }
    @include mq-down(md) {
      margin: 56px auto 0;
      padding: 0;
      max-width: 512px;
    }

    @mixin illust_text {
      position: absolute;
      font-size: 27px;
      font-weight: 600;
      line-height: 1.6;
      left: 50%;
      margin: 0;
      max-width: 360px;
      @include mq-down(xxl) {
        font-size: calc(1.82vw + 0.8px);
      }
      @include mq-down(lg) {
        font-size: calc(2.2vw + 2px);
        line-height: 1.6;
        max-width: 280px;
      }
      @include mq-down(md) {
        font-size: 14px;
        line-height: 18px;
        max-width: 110px;
        white-space: normal;
      }
    }
    @mixin small-text-template {
      font-size: 21px;
      @include mq-down(xxl) {
        font-size: calc(1.32vw + 0.8px);
      }
      @include mq-down(md) {
        font-size: 12px;
      }
    }
    .p-request {
      @include illust_text;
      transform: translate(-50%, -100%);
      top: 20%;
      white-space: normal;

      @include mq-down(md) {
        top: 38%;
        font-size: 12px;
        line-height: 1.2;
      }
      &.small-text {
        @include small-text-template;
      }
    }
    .p-get {
      @include illust_text;
      max-width: 288px;
      transform: translate(-50%, 0%);
      top: 72%;
      @include mq-down(xl) {

      }
      @include mq-down(md) {
        top: 70%;
        font-size: 12px;
        line-height: 1.2;
        white-space: normal;
      }
      &.small-text {
        @include small-text-template;
      }
    }
    .p-service {
      @include illust_text;
      font-size: 170%;
      line-height: 32px;
      letter-spacing: 0.96px;
      transform: translate(50%, 0%);
      width: 50%;
      top: 86%;
      left: initial;
      right: 15%;
      max-width: 40%;
      @include mq-down(xxl) {
        font-size: 24px;
      }
      @include mq-down(lg) {
        font-size: calc(1.73vw + 0.7px);
      }
      @include mq-down(md) {
        transform: translate(50%, 0%);
        font-size: 2.2vw;
        line-height: 20px;
        top: 84%;
        left: initial;
        right: 16.8%;
        width: initial;
        max-width: initial;
      }
      &.small-text {
        @include small-text-template;
      }
    }
  }
  .api-btn {
    margin: 32px 0 128px;
    width: 900px;
    height: 184px;
    font-size: 45px;
    font-weight: 500;
    line-height: 43px;
    border-radius: 999px;
    background-color: #707070 !important;

    .dotted {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 3px dashed white;
      border-radius: 99px;
      height: 164px;
      width: 880px;
      color: white;
    }
    @include mq-down(lg) {
      width: 327px;
      height: 80px;
      font-size: 16px;
      line-height: 14px;
      font-weight: 500;
      margin-bottom: 75px;
      .dotted {
        border: 2px dashed white;
        width: 317px;
        height: 70px;
      }
    }
    @media screen and (max-width:350px) {
      width: 290px;
      max-width: 82vw;
      font-size: 11px;
      .dotted {
        width: 280px;
        max-width: 78vw;
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
}

</style>
<i18n>
{
  "ja": {
    "st_top_1": "AIが、著名な画家風の絵画を創り出す！",
    "st_top_2": "さっそくあなたの写真をアップロードしよう！",
    "st_top_1_1": "１枚の写真が、<br>絵画に。",
    "st_top_3": "Enpainter とは",
    "st_top_4": "ログインして利用する",
    "st_top_5": "ここに画像をドラッグまたは、",
    "st_top_6": "ファイルを選択",
    "st_top_7": "写真を有名画家の絵画風に加工するAI「Enpainter」とは？",
    "st_top_8": "様々な有名画家の特徴を学習したAIです。ゴッホやピカソ、ムンクなどの誰もが知っている有名画家の特徴を10種類以上学習しています。Enpainter は、それぞれの画家の様々な画風を学習させているため、まるでその画家が現代に蘇ったかのような、絵画（水彩画、油絵）の画像を創り出すことができます。写真をアップロードするだけで、有名画家に描いてもらったようなオリジナル画像をすぐにGETすることができます。",
    "st_top_9": "様々な画像に画家の画風を当てはめられる",
    "st_top_10": "フィンセント・ファン・ゴッホ風にしたい",
    "st_top_11": "パブロ・ピカソ風にしたい",
    "st_top_12": "エドヴァルド・ムンク風にしたい",
    "st_top_13": "Enpainter を支える技術",
    "st_top_14": "Enpainter は Deep Learning を使うことで有名画家の画風を学習したAIです。画家の特定の絵画を当てはめる手法ではなく、多数の絵画をAI に学習させる手法を用いることで、その画家がどのような絵画を描くのかという特徴を学習させています。それによって、未知の画像をEnpainter に与えても、まるでその画家が描いたかのような絵画に変換してくれます。",
    "st_top_15": "様々な有名画家のスタイルを当てはめてオリジナルの画像を描きだすことができる",
    "st_top_16": "利用方法はとても簡単",
    "st_top_17": "ファイルをアップロード",
    "st_top_18": "画家を選択",
    "st_top_19": "画家風に変換された画像をGET！",
    "st_top_20": "画家風に変換したい写真を、本ページ上部にあるファイル選択領域にアップロードしてください。",
    "st_top_21": "自分がアップロードした画像に当てはめたい画家を選択してください。",
    "st_top_22": "30秒ほどお待ちいただくと画家風に変換された画像が描きだされます。画像をご確認いただき、問題なければ画像をダウンロードしてください。",
    "st_top_23": "様々なシーンで Enpainter を活用いただけます",
    "st_top_24": "多種多様なデザイン素材を創りだせます",
    "st_top_25": "一枚の写真から様々な画家風に変換することができるので、多種多様なデザイン素材に変換できます。ウェブサイトやバナーの素材やパンフレットのデザイン、チラシの作成等にもご利用いただけます。写真はいいけど、その写真素材のテイストを変えたいという時にお試しいただきたいです。",
    "st_top_26": "結婚式など、特別なプレゼントに！",
    "st_top_27": "結婚式のウェルカムボードをゴッホ風にしたり、家族のアルバムを様々な画家風で作ってみたり、入学式等の記念撮影にモネ風に変換してキャンバス地やアクリル板にプリントして部屋に飾るのも良いかもしれません。",
    "st_top_28": "テキスタイルデザインを創り出せる！",
    "st_top_29": "1種類のテキスタイルから、様々なテイストのテキスタイルを創ることができます。そのまま壁紙やファブリックに利用したり、変換画像を新たなテキスタイルデザインに応用できます。",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "APIを使って各種システムとの連携を 行うことが可能です",
    "st_top_32": "cre8tiveAI Enpainter APIを利用すれば、貴方のサービスに様々な画家風に変換できるAI を取り入れることができます。APIの導入は、どのようなサービスを実現したいのかや連携するシステムの仕様等によって諸条件が変わりますので、以下からお問い合わせください。",
    "st_top_33": "画家風変換リクエスト",
    "st_top_34": "画家風に変換した画像の送付",
    "st_top_35": "様々な企業サービス",
    "st_top_36": "APIについて問い合わせる",
    "st_top_37": "10種類以上の有名画家風に描いてもらえます",
    "st_top_38": "この画家にする"
  },
  "en": {
    "st_top_1": "AI creates various artist-like paintings.",
    "st_top_2": "Let's upload your photos now!",
    "st_top_1_1": "A single photograph<br>becomes an art.",
    "st_top_3": "What is Enpainter?",
    "st_top_4": "sign in to use",
    "st_top_5": "Drag images here or",
    "st_top_6": "Select files.",
    "st_top_7": "What is the AI \"Enpainter\" that processes a picture like a painting by a famous painter?",
    "st_top_8": "It is an AI that learned the characteristics of various famous artists.Enpainter has learned more than 10 different features of famous artists, such as Van Gogh, Picasso and Munch. Enpainter learns the different styles of painting by each painter, so you can create images of paintings (watercolors, oil paintings) as if they were resurrected to the present day.Simply upload a photo and get an original image like a famous painter.",
    "st_top_9": "Can apply the style of an artist to a variety of images",
    "st_top_10": "Make it look like Van Gogh style.",
    "st_top_11": "Make it look like Pablo Picasso style.",
    "st_top_12": "Make it look like Munch style.",
    "st_top_13": "Technology to support Enpainter",
    "st_top_14": "Enpainter is an AI that learned the style of painting by famous painters by using Deep Learning. Rather than applying a specific painting by a painter, the AI learns the characteristics of the painter's paintings by training the AI on a large number of paintings. This allows you to give an unknown image to Enpainter and convert it into a painting as if it had been painted by the painter.",
    "st_top_15": "Can apply different famous artists' styles to create your original images.",
    "st_top_16": "Very easy to use",
    "st_top_17": "Upload File",
    "st_top_18": "Select Painter",
    "st_top_19": "Get a picture converted into a painter's style!",
    "st_top_20": "Please upload the photo you want to convert to artist style in the file selection area at the top of this page.",
    "st_top_21": "Select the artist you want to apply to the image you uploaded.",
    "st_top_22": "Wait about 30 seconds, then you will be able to get an image converted to the style of the artist you choose. Please check the image and download it if there is no problem.",
    "st_top_23": "You can take advantage of Enpainter in a variety of situations.",
    "st_top_24": "You can create a wide variety of design materials.",
    "st_top_25": "Enpainter can be converted from a single photo to various artists' styles, so it can be converted to a variety of design materials. You can also use it for designing materials and brochures for websites and banners, as well as for making flyers. I would like you to try out when you want to change the taste of the photo you have.",
    "st_top_26": "Use it for special gifts like a wedding! ",
    "st_top_27": "Make a wedding welcome board in the style of a Van Gogh. Convert a family album into various styles of paintings. Convert memorial photos to Monet style and print them on canvas or acrylic plates to hang in your room. It can be used in a variety of ways.",
    "st_top_28": "Creating textile design",
    "st_top_29": "You can create a variety of textiles from a single type. You can apply converted images to your wallpaper, fabric, or new textiles.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "You can use API to work with various systems.",
    "st_top_32": "cre8tiveAI Enpainter API allows you to incorporate AI that can be converted into various artist styles in your service. The conditions for API implementation vary depending on the service you want to achieve and the specifications of the system you work with, so please contact usfrom below.",
    "st_top_33": "Painter-style conversion request",
    "st_top_34": "Sending an image converted to the style of an artist",
    "st_top_35": "Various corporate services",
    "st_top_36": "Inquire about the API",
    "st_top_37": "You can get more than 10 famous artists style.",
    "st_top_38": "Select"
  },
  "fr": {
    "st_top_1": "AI crée diverses peintures d'artistes.",
    "st_top_2": "Téléchargez vos photos maintenant !",
    "st_top_1_1": "Une seule photographie<br>devient un art.",
    "st_top_3": "Qu'est-ce qu'Enpainter ?",
    "st_top_4": "Se connecter",
    "st_top_5": "Glissez votre fichier ici",
    "st_top_6": "Choisissez un fichier",
    "st_top_7": "Qu'est-ce que l'IA \"Enpainter\" qui traite un tableau comme une peinture d'un peintre célèbre ?",
    "st_top_8": "C'est une IA qui a appris les caractéristiques de divers artistes célèbres. Enpainter a appris plus de 10 caractéristiques différentes d'artistes célèbres, tels que Van Gogh, Picasso et Munch. Enpainter apprend les différents styles de peinture de chaque peintre, ce qui vous permet de créer des images de peintures (aquarelles, peintures à l'huile) comme si elles étaient ressuscitées à l'époque actuelle. Il suffit de télécharger une photo et d'obtenir une image originale comme un peintre célèbre.",
    "st_top_9": "Peut appliquer le style d'un artiste à une variété d'images",
    "st_top_10": "Faites en sorte que cela ressemble au style de Van Gogh.",
    "st_top_11": "Faites en sorte que cela ressemble au style de Pablo Picasso.",
    "st_top_12": "Faites en sorte que ça ressemble au style de Munch.",
    "st_top_13": "Technologie pour soutenir Enpainter",
    "st_top_14": "Enpainter est une IA qui a appris le style de peinture de peintres célèbres en utilisant Deep Learning. Plutôt que d'appliquer une peinture spécifique d'un peintre, l'IA apprend les caractéristiques des peintures du peintre en formant l'IA sur un grand nombre de peintures. Cela permet de donner une image inconnue à l'IA et de la convertir en une peinture comme si elle avait été peinte par le peintre.",
    "st_top_15": "Vous pouvez appliquer différents styles d'artistes célèbres pour créer vos images originales.",
    "st_top_16": "Très facile à utiliser",
    "st_top_17": "Charger un fichier",
    "st_top_18": "Choisir un peintre",
    "st_top_19": "Transformez une image en un style de peintre !",
    "st_top_20": "Veuillez télécharger la photo que vous souhaitez convertir en style d'artiste dans la zone de sélection des fichiers en haut de cette page.",
    "st_top_21": "Sélectionnez l'artiste que vous souhaitez appliquer à l'image que vous avez téléchargée.",
    "st_top_22": "Attendez environ 30 secondes, puis vous pourrez obtenir une image convertie dans le style de l'artiste que vous aurez choisi. Veuillez vérifier l'image et la télécharger s'il n'y a pas de problème.",
    "st_top_23": "Vous pouvez profiter d'Enpainter dans diverses situations.",
    "st_top_24": "Vous pouvez créer une grande variété de matériaux de conception.",
    "st_top_25": "Enpainter peut convertir une seule photo en différents styles d'artistes, ce qui lui permet d'utiliser divers matériaux de conception. Vous pouvez également l'utiliser pour concevoir du matériel et des brochures pour des sites web et des bannières, ainsi que pour réaliser des prospectus. J'aimerais que vous l'essayiez lorsque vous voulez changer le goût de la photo que vous avez.",
    "st_top_26": "Utilisez-le pour des cadeaux spéciaux comme un mariage ! ",
    "st_top_27": "Faites un panneau d'accueil pour le mariage dans le style d'un Van Gogh. Convertissez un album de famille en différents styles de peintures. Convertissez des photos commémoratives dans le style Monet et imprimez-les sur des toiles ou des plaques acryliques à accrocher dans votre chambre. Il peut être utilisé de différentes manières.",
    "st_top_28": "Créer un design textile",
    "st_top_29": "Vous pouvez créer une variété de textiles à partir d'un seul type. Vous pouvez appliquer des images converties à votre papier peint, à votre tissu ou à de nouveaux textiles.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Vous pouvez utiliser l'API pour travailler avec différents systèmes.",
    "st_top_32": "L'API cre8tiveAI Enpainter vous permet d'incorporer dans votre service des AI qui peuvent être converties en différents styles d'artistes. Les conditions d'implémentation de l'API varient selon le service que vous souhaitez obtenir et les spécifications du système avec lequel vous travaillez, veuillez donc nous contacter ci-dessous.",
    "st_top_33": "Demande de conversion de style peintre",
    "st_top_34": "Envoi d'une image convertie au style d'un artiste",
    "st_top_35": "Divers services aux entreprises",
    "st_top_36": "Renseignez-vous sur l'API",
    "st_top_37": "Vous pouvez obtenir le style de plus de 10 artistes célèbres.",
    "st_top_38": "Sélectionnez"
  },
  "es": {
    "st_top_1": "AI crea varias pinturas de artistas.",
    "st_top_2": "¡Subamos tus fotos ahora!",
    "st_top_1_1": "Una sola fotografía se<br>convierte en un arte.",
    "st_top_3": "¿Qué es Enpainter?",
    "st_top_4": "Registrarte y utilizar",
    "st_top_5": "Arrastra la imagen aquí,",
    "st_top_6": "o elige los archivos.",
    "st_top_7": "¿Qué es la IA \"Enpainter\" que procesa un cuadro como un cuadro de un pintor famoso?",
    "st_top_8": "Es una IA que aprendió las características de varios artistas famosos. El pintor ha aprendido más de 10 características diferentes de artistas famosos, como Van Gogh, Picasso y Munch. Enpainter aprende los diferentes estilos de pintura de cada pintor, así que puedes crear imágenes de cuadros (acuarelas, óleos) como si estuvieran resucitadas hasta el día de hoy.Simplemente sube una foto y consigue una imagen original como un pintor famoso.",
    "st_top_9": "Puede aplicar el estilo de un artista a una variedad de imágenes",
    "st_top_10": "Haz que se vea como el estilo de Van Gogh.",
    "st_top_11": "Haz que se vea como el estilo de Pablo Picasso.",
    "st_top_12": "Haz que se vea como el estilo de Munch.",
    "st_top_13": "Tecnología para apoyar a Enpainter",
    "st_top_14": "Enpainter es una IA que aprendió el estilo de pintura de pintores famosos usando Deep learning. En lugar de aplicar una pintura específica de un pintor, la IA aprende las características de los cuadros del pintor entrenando a la IA en un gran número de pinturas. Esto le permite dar una imagen desconocida a Enpainter y convertirla en un cuadro como si hubiera sido pintado por el pintor.",
    "st_top_15": "Puede aplicar diferentes estilos de artistas famosos para crear sus imágenes originales.",
    "st_top_16": "Muy fácil de usar",
    "st_top_17": "Subir archivo",
    "st_top_18": "Seleccione el pintor",
    "st_top_19": "¡Convierta un cuadro en el estilo de un pintor!",
    "st_top_20": "Por favor, sube la foto que quieres convertir al estilo de artista en el área de selección de archivos en la parte superior de esta página.",
    "st_top_21": "Selecciona el artista que quieres aplicar a la imagen que has subido.",
    "st_top_22": "Espera unos 30 segundos, entonces podrás obtener una imagen convertida al estilo del artista que elijas. Por favor, comprueba la imagen y descárgala si no hay ningún problema.",
    "st_top_23": "Puedes aprovechar el Enpainter en una variedad de situaciones.",
    "st_top_24": "Puedes crear una amplia variedad de materiales de diseño.",
    "st_top_25": "Enpainter se puede convertir de una sola foto a varios estilos de artistas, por lo que se puede convertir en una variedad de materiales de diseño. También se puede utilizar para diseñar materiales y folletos para sitios web y pancartas, así como para hacer folletos. Me gustaría que lo probaras cuando quieras cambiar el sabor de la foto que tienes.",
    "st_top_26": "¡Úsalo para regalos especiales como una boda! ",
    "st_top_27": "Haz una tabla de bienvenida a la boda al estilo de un Van Gogh. Convierte un álbum familiar en varios estilos de pinturas. Convierte las fotos conmemorativas al estilo de Monet e imprímelas en lienzo o placas de acrílico para colgarlas en tu habitación. Puede ser usado de varias maneras.",
    "st_top_28": "Creando el diseño textil",
    "st_top_29": "Puedes crear una variedad de textiles de un solo tipo. Puedes aplicar imágenes convertidas al papel tapiz, a la tela o a las nuevas telas.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Puedes usar el API para trabajar con varios sistemas.",
    "st_top_32": "El API de cre8tiveAI Enpainter le permite incorporar la IA que puede ser convertida en varios estilos de artistas a su servicio. Las condiciones para la implementación del API varían dependiendo del servicio que quiera conseguir y de las especificaciones del sistema con el que trabaje, por lo que le rogamos que se ponga en contacto con nosotros desde abajo.",
    "st_top_33": "Solicitud de conversión al estilo de un pintor",
    "st_top_34": "Enviando una imagen convertida al estilo de un artista",
    "st_top_35": "Varios servicios corporativos",
    "st_top_36": "Pregunte sobre el API",
    "st_top_37": "Puedes conseguir el estilo de más de 10 artistas famosos.",
    "st_top_38": "Seleccione"
  },
  "pt": {
    "st_top_1": "AI cria várias pinturas semelhantes a artistas.",
    "st_top_2": "Vamos carregar as suas fotos agora!",
    "st_top_1_1": "Uma única fotografia<br>become uma arte.",
    "st_top_3": "O que é o Enpainter?",
    "st_top_4": "Use após o login",
    "st_top_5": "Arraste a imagem aqui",
    "st_top_6": "Selecione o arquivo",
    "st_top_7": "O que é a IA \"Enpainter\" que processa um quadro como uma pintura de um pintor famoso?",
    "st_top_8": "É uma IA que aprendeu as características de vários artistas famosos. Enpainter aprendeu mais de 10 características diferentes de artistas famosos, tais como Van Gogh, Picasso e Munch. Enpainter aprende os diferentes estilos de pintura de cada pintor, para que possa criar imagens de pinturas (aguarelas, pinturas a óleo) como se fossem ressuscitadas até aos dias de hoje, basta carregar uma fotografia e obter uma imagem original como um pintor famoso.",
    "st_top_9": "Pode aplicar o estilo de um artista a uma variedade de imagens",
    "st_top_10": "Fazer com que pareça ao estilo Van Gogh.",
    "st_top_11": "Fazê-lo parecer ao estilo de Pablo Picasso.",
    "st_top_12": "Fazer com que pareça estilo Munch.",
    "st_top_13": "Tecnologia para apoiar o Enpainter",
    "st_top_14": "Enpainter é uma IA que aprendeu o estilo de pintura por pintores famosos, utilizando Deep Learning. Em vez de aplicar uma pintura específica de um pintor, a IA aprende as características das pinturas do pintor através da formação da IA num grande número de pinturas. Isto permite dar uma imagem desconhecida ao Enpainter e convertê-la numa pintura como se tivesse sido pintada pelo pintor.",
    "st_top_15": "Pode aplicar diferentes estilos de artistas famosos para criar as suas imagens originais.",
    "st_top_16": "Muito fácil de usar",
    "st_top_17": "Carregar ficheiro",
    "st_top_18": "Seleccione Pintor",
    "st_top_19": "Obtenha um quadro convertido no estilo de um pintor!",
    "st_top_20": "Por favor, carregue a fotografia que pretende converter para estilo artístico na área de selecção de ficheiros no topo desta página.",
    "st_top_21": "Seleccione o artista que pretende aplicar à imagem que carregou.",
    "st_top_22": "Espere cerca de 30 segundos, depois poderá obter uma imagem convertida ao estilo do artista que escolher. Por favor, verifique a imagem e descarregue-a se não houver problema.",
    "st_top_23": "Pode tirar partido do Enpainter numa variedade de situações.",
    "st_top_24": "É possível criar uma grande variedade de materiais de desenho.",
    "st_top_25": "O Enpainter pode ser convertido de uma única fotografia para vários estilos de artistas, por isso pode ser convertido para uma variedade de materiais de desenho. Também pode ser utilizado para desenhar materiais e brochuras para websites e banners, bem como para fazer panfletos. Gostaria que experimentassem quando quiserem alterar o sabor da fotografia que têm.",
    "st_top_26": "Use-o para presentes especiais como um casamento! ",
    "st_top_27": "Fazer uma tábua de boas-vindas ao casamento no estilo de um Van Gogh. Converter um álbum de família em vários estilos de pintura. Converter fotografias memoriais em estilo Monet e imprimi-las em tela ou placas de acrílico para pendurar no seu quarto. Pode ser usado de várias maneiras.",
    "st_top_28": "Criação de desenho têxtil",
    "st_top_29": "Pode-se criar uma variedade de têxteis a partir de um único tipo. Pode aplicar imagens convertidas ao seu papel de parede, tecido, ou novos têxteis.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Pode utilizar o API para trabalhar com vários sistemas.",
    "st_top_32": "Cre8tiveAI Enpainter API permite-lhe incorporar IA que pode ser convertida em vários estilos de artistas no seu serviço. As condições para a implementação de API variam dependendo do serviço que pretende alcançar e das especificações do sistema com o qual trabalha, por isso, contacte usfrom abaixo.",
    "st_top_33": "Pedido de conversão ao estilo de pintor",
    "st_top_34": "Envio de uma imagem convertida ao estilo de um artista",
    "st_top_35": "Vários serviços empresariais",
    "st_top_36": "Inquira sobre o API",
    "st_top_37": "Pode obter mais de 10 artistas famosos ao estilo.",
    "st_top_38": "Seleccione"
  },
  "de": {
    "st_top_1": "AI schafft verschiedene Künstler-ähnliche Gemälde.",
    "st_top_2": "Lassen Sie uns jetzt Ihre Fotos hochladen!",
    "st_top_1_1": "Eine einzelne Fotografie <br>wird zur Kunst.",
    "st_top_3": "Was ist Enpainter?",
    "st_top_4": "Einloggen und sofort verwenden",
    "st_top_5": "Ziehen Sie ein Bild per Drag-and-Drop oder",
    "st_top_6": "wählen Sie eine Datei aus",
    "st_top_7": "Was ist der KI-\"Enpainter\", der ein Bild wie ein Gemälde eines berühmten Malers bearbeitet?",
    "st_top_8": "Es ist eine KI, die die Eigenschaften verschiedener berühmter Künstler kennen gelernt hat. Enpainter hat mehr als 10 verschiedene Merkmale berühmter Künstler wie Van Gogh, Picasso und Munch gelernt. Enpainter lernt die verschiedenen Malstile der einzelnen Maler kennen, so dass Sie Bilder von Gemälden (Aquarelle, Ölgemälde) erstellen können, als wären sie bis zum heutigen Tag wiederauferstanden. Laden Sie einfach ein Foto hoch und erhalten Sie ein Originalbild wie ein berühmter Maler.",
    "st_top_9": "Kann den Stil eines Künstlers auf eine Vielzahl von Bildern anwenden",
    "st_top_10": "Lassen Sie es nach Van Gogh aussehen.",
    "st_top_11": "Lassen Sie es nach dem Stil von Pablo Picasso aussehen.",
    "st_top_12": "Lassen Sie es nach Munch-Stil aussehen.",
    "st_top_13": "Technologie zur Unterstützung von Enpainter",
    "st_top_14": "Enpainter ist eine KI, die den Malstil berühmter Maler mit Hilfe von Deep Learning erlernt hat. Anstatt ein bestimmtes Gemälde eines Malers anzuwenden, lernt die KI die Eigenschaften der Gemälde des Malers, indem sie die KI an einer großen Anzahl von Gemälden trainiert. Auf diese Weise können Sie dem Enpainter ein unbekanntes Bild geben und es in ein Gemälde umwandeln, als ob es vom Maler gemalt worden wäre.",
    "st_top_15": "Kann verschiedene Stile berühmter Künstler anwenden, um Ihre Originalbilder zu erstellen.",
    "st_top_16": "Sehr leicht zu bedienen",
    "st_top_17": "Datei hochladen",
    "st_top_18": "Maler auswählen",
    "st_top_19": "Lassen Sie ein Bild in den Stil eines Malers umwandeln!",
    "st_top_20": "Bitte laden Sie das Foto, das Sie in einen Künstlerstil umwandeln möchten, im Dateiauswahlbereich oben auf dieser Seite hoch.",
    "st_top_21": "Wählen Sie den Künstler aus, den Sie auf das von Ihnen hochgeladene Bild anwenden möchten.",
    "st_top_22": "Warten Sie etwa 30 Sekunden, dann können Sie ein Bild in den Stil des von Ihnen gewählten Künstlers konvertieren lassen. Bitte überprüfen Sie das Bild und laden Sie es herunter, wenn es keine Probleme gibt.",
    "st_top_23": "Sie können die Vorteile von Enpainter in einer Vielzahl von Situationen nutzen.",
    "st_top_24": "Sie können eine Vielzahl von Entwurfsmaterialien erstellen.",
    "st_top_25": "Enpainter kann von einem einzigen Foto in verschiedene Künstlerstile umgewandelt werden, so dass es in eine Vielzahl von Designmaterialien umgewandelt werden kann.Sie können es auch für die Gestaltung von Materialien und Broschüren für Websites und Banner sowie für die Herstellung von Flyern verwenden.Ich möchte Sie bitten, auszuprobieren, wann Sie den Geschmack des Fotos ändern möchten.",
    "st_top_26": "Verwenden Sie es für besondere Geschenke wie eine Hochzeit! ",
    "st_top_27": "Stellen Sie ein Begrüßungsbrett für die Hochzeit im Stil eines Van Gogh her. Ein Familienalbum in verschiedene Gemäldestile umwandeln. Konvertieren Sie Erinnerungsfotos in den Monet-Stil und drucken Sie sie auf Leinwand oder Acrylplatten, um sie in Ihrem Zimmer aufzuhängen. Es kann auf vielfältige Weise verwendet werden.",
    "st_top_28": "Erstellen von Textildesign",
    "st_top_29": "Sie können aus einem einzigen Typ eine Vielzahl von Textilien erstellen. Sie können konvertierte Bilder auf Ihre Tapete, Ihren Stoff oder neue Textilien anwenden.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Sie können API verwenden, um mit verschiedenen Systemen zu arbeiten.",
    "st_top_32": "cre8tiveAI Enpainter API ermöglicht es Ihnen, AI, die in verschiedene Künstlerstile konvertiert werden kann, in Ihren Service einzubinden. Die Bedingungen für die API-Implementierung variieren je nach dem Service, den Sie erreichen möchten, und den Spezifikationen des Systems, mit dem Sie arbeiten, also kontaktieren Sie uns bitte von unten.",
    "st_top_33": "Umwandlungsantrag im Stil eines Malers",
    "st_top_34": "Senden eines in den Stil eines Künstlers umgewandelten Bildes",
    "st_top_35": "Verschiedene Unternehmensdienste",
    "st_top_36": "Erkundigen Sie sich über die API",
    "st_top_37": "Sie können den Stil von mehr als 10 berühmten Künstlern erhalten.",
    "st_top_38": "Wählen Sie"
  },
  "nl": {
    "st_top_1": "AI maakt verschillende kunstenaarsachtige schilderijen.",
    "st_top_2": "Laten we nu uw foto's uploaden!",
    "st_top_1_1": "Een enkele foto<br> wordt een kunst.",
    "st_top_3": "Wat is Enpainter?",
    "st_top_4": "aanmelden bij gebruik",
    "st_top_5": "Sleep afbeeldingen hier of",
    "st_top_6": "Selecteer bestand.",
    "st_top_7": "Wat is de AI \"Enpainter\" die een beeld verwerkt als een schilderij van een beroemde schilder?",
    "st_top_8": "Het is een AI die de kenmerken van verschillende beroemde kunstenaars heeft geleerd. Enpainter heeft meer dan 10 verschillende kenmerken van beroemde kunstenaars geleerd, zoals Van Gogh, Picasso en Munch. Enpainter leert de verschillende stijlen van schilderen van elke schilder, zodat u afbeeldingen van schilderijen (aquarellen, olieverfschilderijen) kunt maken alsof ze tot op de dag van vandaag zijn herrezen, upload gewoon een foto en krijg een origineel beeld zoals een beroemde schilder.",
    "st_top_9": "Kan de stijl van een kunstenaar toepassen op een verscheidenheid aan beelden",
    "st_top_10": "Laat het eruit zien als Van Gogh.",
    "st_top_11": "Laat het lijken op de Pablo Picasso-stijl.",
    "st_top_12": "Laat het eruit zien als Munch stijl.",
    "st_top_13": "Technologie ter ondersteuning van Enpainter",
    "st_top_14": "Enpainter is een AI die de schilderstijl van beroemde schilders heeft geleerd door gebruik te maken van Deep learning. In plaats van het toepassen van een specifiek schilderij door een schilder, leert de AI de kenmerken van de schilderijen van de schilder door het trainen van de AI op een groot aantal schilderijen. Hierdoor kun je een onbekend beeld aan Enpainter geven en dit omzetten in een schilderij alsof het door de schilder is geschilderd.",
    "st_top_15": "Kan verschillende beroemde kunstenaars stijlen toepassen om uw originele beelden te creëren.",
    "st_top_16": "Zeer gemakkelijk te gebruiken",
    "st_top_17": "Bestand uploaden",
    "st_top_18": "Selecteer Schilder",
    "st_top_19": "Laat een foto omzetten in een schilderstijl!",
    "st_top_20": "Upload de foto die u wilt converteren naar artiestenstijl in het bestandsselectiegebied bovenaan deze pagina.",
    "st_top_21": "Selecteer de kunstenaar die u wilt toepassen op de afbeelding die u hebt geüpload.",
    "st_top_22": "Wacht ongeveer 30 seconden, dan kun je een beeld laten omzetten naar de stijl van de kunstenaar die je kiest. Controleer de afbeelding en download deze als er geen probleem is.",
    "st_top_23": "U kunt gebruik maken van Enpainter in verschillende situaties.",
    "st_top_24": "U kunt een grote verscheidenheid aan ontwerpmaterialen creëren.",
    "st_top_25": "Enpainter kan worden geconverteerd van een enkele foto naar verschillende kunstenaars stijlen, zodat het kan worden omgezet in een verscheidenheid aan design materialen. U kunt het ook gebruiken voor het ontwerpen van materialen en brochures voor websites en banners, maar ook voor het maken van flyers. Ik wil graag dat je het uitprobeert als je de smaak van de foto wilt veranderen.",
    "st_top_26": "Gebruik het voor speciale geschenken zoals een bruiloft! ",
    "st_top_27": "Maak een welkomstbord voor bruiloften in de stijl van een Van Gogh. Zet een familiealbum om in verschillende schilderstijlen. Zet gedenkfoto's om in Monet-stijl en druk ze af op canvas of acrylplaten om ze in uw kamer te hangen. Het kan op verschillende manieren worden gebruikt.",
    "st_top_28": "Het creëren van textiele vormgeving",
    "st_top_29": "U kunt verschillende soorten textiel maken uit één type. U kunt geconverteerde afbeeldingen toepassen op uw behang, stof of nieuw textiel.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "U kunt de API gebruiken om met verschillende systemen te werken.",
    "st_top_32": "cre8tiveAI Enpainter API stelt u in staat om AI die kan worden omgezet in verschillende artiestenstijlen in uw service op te nemen. De voorwaarden voor API-implementatie variëren afhankelijk van de service die u wilt bereiken en de specificaties van het systeem waarmee u werkt, dus neem hieronder contact op met u.",
    "st_top_33": "Omzettingsverzoek in schilderstijl",
    "st_top_34": "Het sturen van een beeld geconverteerd naar de stijl van een kunstenaar",
    "st_top_35": "Diverse bedrijfsdiensten",
    "st_top_36": "Informeer naar de API",
    "st_top_37": "U kunt meer dan 10 beroemde kunstenaars stijl te krijgen.",
    "st_top_38": "Selecteer"
  },
  "tr": {
    "st_top_1": "AI çeşitli sanatçı benzeri tablolar oluşturur.",
    "st_top_2": "Şimdi fotoğraflarınızı yüklemek olsun!",
    "st_top_1_1": "Tek bir fotoğraf<br>bir sanat olur.",
    "st_top_3": "Enpainter nedir?",
    "st_top_4": "Kullanım oturum",
    "st_top_5": "Resimleri sürükleme burada ya",
    "st_top_6": "Dosyaları seç.",
    "st_top_7": "Ünlü bir ressam tarafından bir tablo gibi bir resim işler AI \"Enpainter\" nedir?",
    "st_top_8": "Çeşitli ünlü artists.Enpainter özellikleri Van Gogh, Picasso ve Munch gibi ünlü sanatçıların, 10'dan fazla farklı özellikler öğrendi öğrenilen bir AI olduğunu. Eğer onlar day.Simply bir fotoğraf yüklemek günümüze dirildi sanki resimlerinde (suluboya, yağlıboya tablolar) görüntülerini oluşturmak ve ünlü bir ressam gibi orijinal bir görüntü alabilirsiniz Enpainter, her ressam boyama farklı stilleri öğrenir.",
    "st_top_9": "Görüntülerin çeşitli bir sanatçının stil uygulamak Can",
    "st_top_10": "Van Gogh stili gibi görünmesini sağlayın.",
    "st_top_11": "o Pablo Picasso tarzı gibi görünmesini sağlayın.",
    "st_top_12": "o Munch stili gibi görünmesini sağlayın.",
    "st_top_13": "Enpainter desteklemek için Teknoloji",
    "st_top_14": "Enpainter Deep Learning kullanarak ünlü ressamların resim tarzını öğrenmiş bir AI olduğunu. Aksine bir ressam tarafından belirli bir boyama uygulayarak yerine, AI resimlerinin çok sayıdaki AI eğiterek ressamın resimlerinin özelliklerini öğrenir. Bu Enpainter bilinmeyen bir görüntü vermek ve onu ressam tarafından boyanmış sanki bir resim haline dönüştürmek için izin verir.",
    "st_top_15": "Orijinal görüntüler oluşturmak için farklı ünlü sanatçıların stillerini uygulayabilir.",
    "st_top_16": "kullanımı çok kolay",
    "st_top_17": "Dosya yükleme",
    "st_top_18": "seç Ressam",
    "st_top_19": "Bir ressamın tarzı haline dönüştürülmüş bir resim alın!",
    "st_top_20": "Bu sayfanın en üstündeki dosya seçimi alanda sanatçı tarzına dönüştürmek istediğiniz fotoğrafı yükleyin.",
    "st_top_21": "Yüklediğiniz resme uygulamak istediğiniz sanatçıyı seçin.",
    "st_top_22": "30 saniye hakkında, o zaman bir görüntü almak mümkün olacak Bekle seçtiğiniz sanatçının tarzı dönüştürülür. resmi kontrol ve hiçbir sorun varsa bunu indirin.",
    "st_top_23": "Sen durumlarda çeşitli Enpainter yararlanabilir.",
    "st_top_24": "Sen tasarım malzemelerin çeşitli oluşturabilirsiniz.",
    "st_top_25": "tasarım malzemelerin çeşitli dönüştürülebilir böylece Enpainter, çeşitli sanatçıların tarzları tek fotoğraftan dönüştürülebilir. Ayrıca yanı sıra el ilanları yapmak için, web siteleri ve afişler için malzemeler ve broşürler tasarlamak için kullanabilirsiniz. Ben size sahip fotoğrafın tadını değiştirmek istediğinizde denemek istiyorum.",
    "st_top_26": "Bir düğün gibi özel hediyeler için kullanın!",
    "st_top_27": "Bir Van Gogh tarzında bir düğün karşılama kartını olun. Resimlerin çeşitli stilleri içine bir aile albümü dönüştürün. Monet stiline anma fotoğrafları dönüştürün ve odanıza asmak için tuval veya akrilik plakalar üzerine yazdırabilirsiniz. Bu çeşitli şekillerde kullanılabilir.",
    "st_top_28": "tekstil tasarımı oluşturma",
    "st_top_29": "Tek bir tip tekstil çeşitli oluşturabilirsiniz. Eğer duvar kağıdı, kumaş veya yeni tekstil dönüştürülen görüntüleri uygulayabilirsiniz.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Çeşitli sistemlerle çalışmak için API kullanabilirsiniz.",
    "st_top_32": "cre8tiveAI Enpainter API, hizmetinde çeşitli sanatçı stilleri dönüştürülebilir AI dahil sağlar. API uygulaması için koşullar öylesine aşağıdaki iletişim usfrom memnun, ulaşmak istediğiniz hizmet ve birlikte çalıştığınız sistemin özelliklerine bağlı olarak değişir.",
    "st_top_33": "Ressam tarzı dönüşüm isteği",
    "st_top_34": "bir sanatçının tarzına dönüştürülmüş bir görüntü gönderme",
    "st_top_35": "Çeşitli kurumsal hizmetler",
    "st_top_36": "API Sorgula",
    "st_top_37": "Birden fazla 10 ünlü sanatçı stili alabilirsiniz.",
    "st_top_38": "seçmek"
  },
  "zh-cn": {
    "st_top_1": "AI创作出各种类似艺术家的画作。",
    "st_top_2": "现在就上传你的照片吧!",
    "st_top_1_1": "一张照片<br>就成了一种艺术。",
    "st_top_3": "Enpainter是什么？",
    "st_top_4": "登入使用",
    "st_top_5": "把图档拖拽至这裡，或着",
    "st_top_6": "选择图档",
    "st_top_7": "把照片加工成著名画家的绘画风格的人工智能\"Enpainter\"是什么？",
    "st_top_8": "这是一种学习了各种著名画家的特点的人工智能，比如梵高、毕加索、蒙克等，超过十种大家所熟知的著名画家的特点。由于每个画家都学习不同的画风，因此，就像那位画家在现代复活一样，可以创作出绘画（水彩画、油画）的图像。只要上传照片，就能立即得到如著名画家画出一般的照片。",
    "st_top_9": "把各种图片用画家的风格再次创造出来",
    "st_top_10": "想要转换成梵高风格",
    "st_top_11": "想要转换成毕加索风格",
    "st_top_12": "想要转换成蒙克风",
    "st_top_13": "Enpainter的技术",
    "st_top_14": "Enpainter是使用Deep learning学习著名画家的画风的人工智能。它不是将图片塞进某张名画，而是采用让人工智能学习多个画家绘画技巧的方法，来学习那位画家的绘画特点。因此，即使将未知的图像提供给 Enpainter，也将其转换为就像那个画家所画的图像。",
    "st_top_15": "可以将各种著名画家的画风反映到图中，制作出独一无二的图像",
    "st_top_16": "使用方法很简单",
    "st_top_17": "上传文件",
    "st_top_18": "选择画家",
    "st_top_19": "把图像转换成画家风格！",
    "st_top_20": "请把想转换成画家风格的照片上传到本页顶部的文件选择区域。",
    "st_top_21": "请选择想要与自己上传的照片相匹配的画家。",
    "st_top_22": "等待30秒钟左右，人工智能将为你画出被转换为画家风的照片。请确认图像，没有问题请下载图像。",
    "st_top_23": "你可以用Enpainter做各种各样的事情。",
    "st_top_24": "创造出各种不同的设计素材。",
    "st_top_25": "可从一张图片变换为多种画家风格，可变换为多种多样的设计素材。也可用于网站、横幅材质、小册子设计、传单制作等。原始照片可能也够用，但是想换一种心情或者制作更加精美新鲜的设计的时候请尝试一下。",
    "st_top_26": "作为婚礼上的特别礼物！",
    "st_top_27": "用名画风制作结婚请帖，家庭相册，或者在开学典礼等纪念照变换成摩奈风，印在画布或丙烯板上，作为房间的装饰。",
    "st_top_28": "你可以设计出你的风格！",
    "st_top_29": "可以从一张图片制作出多种风格的图片。直接使用在壁纸或织物上，或将转换图像应用到新的设计上都是可以的。",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "可以利用应用程序，与各种系统进行互动。",
    "st_top_32": "使用cre8tiveAI Enpainter API，可在您的服务中采用可变换为多种画家风格的AI。导入API时，根据想要实现何种服务、连接系统的规格等，各种条件会有所不同，请从以下开始咨询。",
    "st_top_33": "画家风格转换",
    "st_top_34": "转化成画家风格的图像传送",
    "st_top_35": "多种多样的企业服务",
    "st_top_36": "关于API进行咨询",
    "st_top_37": "可以让十多位著名画家画出这张照片。",
    "st_top_38": "选择"
  },
  "zh-tw": {
    "st_top_1": "AI在10秒內創造出各種藝術家般的繪畫",
    "st_top_2": "讓我們現在上傳您的照片！",
    "st_top_1_1": "單張照片變成<br>一種藝術。",
    "st_top_3": "Enpainter是什麼？",
    "st_top_4": "登入使用",
    "st_top_5": "把圖檔拖拽至這裡，或著",
    "st_top_6": "選擇檔案",
    "st_top_7": "什麼是AI“Enpainter”由著名畫家處理像一幅畫的照片嗎？",
    "st_top_8": "這是一種學習了各種著名畫家的特點的人工智能，比如梵高、畢加索、蒙克等，超過十種大家所熟知的著名畫家的特點。由於每個畫家都學習不同的畫風，因此，就像那位畫家在現代復活一樣，可以創作出繪畫（水彩畫、油畫）的圖像。只要上傳照片，就能立即得到如著名畫家畫出一般的照片。",
    "st_top_9": "把各種圖片用畫家的風格再次創造出來",
    "st_top_10": "想要轉換成梵高風格",
    "st_top_11": "想要轉換成畢加索風格",
    "st_top_12": "想要轉換成蒙克風",
    "st_top_13": "Enpainter的技術",
    "st_top_14": "Enpainter是使用Deep learning學習著名畫家的畫風的人工智能。它不是將圖片塞進某張名畫，而是採用讓人工智能學習多個畫家繪畫技巧的方法，來學習那位畫家的繪畫特點。因此，即使將未知的圖像提供給 Enpainter，也將其轉換爲就像那個畫家所畫的圖像。",
    "st_top_15": "可以將各種著名畫家的畫風反映到圖中，製作出獨一無二的圖像",
    "st_top_16": "使用方法很簡單",
    "st_top_17": "上傳文件",
    "st_top_18": "選擇畫家",
    "st_top_19": "把圖像轉換成畫家風格！",
    "st_top_20": "請把想轉換成畫家風格的照片上傳到本頁頂部的文件選擇區域。",
    "st_top_21": "請選擇想要與自己上傳的照片相匹配的畫家。",
    "st_top_22": "等待30秒鐘左右，人工智能將爲你畫出被轉換爲畫家風的照片。請確認圖像，沒有問題請下載圖像。",
    "st_top_23": "你可以用Enpainter做各種各樣的事情。",
    "st_top_24": "創造出各種不同的設計素材。",
    "st_top_25": "可從一張圖片變換爲多種畫家風格，可變換爲多種多樣的設計素材。也可用於網站、橫幅材質、小冊子設計、傳單製作等。原始照片可能也夠用，但是想換一種心情或者製作更加精美新鮮的設計的時候請嘗試一下。",
    "st_top_26": "作爲婚禮上的特別禮物！",
    "st_top_27": "用名畫風製作結婚請帖，家庭相冊，或者在開學典禮等紀念照變換成摩奈風，印在畫布或丙烯板上，作爲房間的裝飾。",
    "st_top_28": "你可以設計出你的風格！",
    "st_top_29": "可以從一張圖片製作出多種風格的圖片。直接使用在壁紙或織物上，或將轉換圖像應用到新的設計上都是可以的。",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "可以利用應用程序，與各種系統進行互動。",
    "st_top_32": "使用cre8tiveAI Enpainter API，可在您的服務中採用可變換爲多種畫家風格的AI。導入API時，根據想要實現何種服務、連接系統的規格等，各種條件會有所不同，請從以下開始諮詢。",
    "st_top_33": "畫家風格轉換",
    "st_top_34": "轉化成畫家風格的圖像傳送",
    "st_top_35": "多種多樣的企業服務",
    "st_top_36": "關於API進行諮詢",
    "st_top_37": "可以讓十多位著名畫家畫出這張照片。",
    "st_top_38": "選擇"
  },
  "sv": {
    "st_top_1": "AI skapar olika konstnärsliknande målningar.",
    "st_top_2": "Låt oss ladda upp dina bilder nu!",
    "st_top_1_1": "En enda fotografi<br>blir en konst.",
    "st_top_3": "Vad är Enpainter?",
    "st_top_4": "logga in för att använda",
    "st_top_5": "Dra bilder här eller",
    "st_top_6": "Välj filer.",
    "st_top_7": "Vad är AI \"Enpainter\" som behandlar en bild som en målning av en känd konstnär?",
    "st_top_8": "Det är en AI som lärde egenskaperna hos olika kända artists.Enpainter har lärt sig mer än 10 olika funktioner av kända artister, såsom Van Gogh, Picasso och Munch. Enpainter lär sig olika typer av målning av varje målare, så att du kan skapa bilder av målningar (akvareller, oljemålningar) som om de var uppståndna föreliggande day.Simply ladda upp en bild och få en originalbild som en känd målare.",
    "st_top_9": "Kan tillämpa stilen av en konstnär till en mängd olika bilder",
    "st_top_10": "Gör det ser ut som Van Gogh stil.",
    "st_top_11": "Gör det ser ut som Pablo Picasso stil.",
    "st_top_12": "Gör det ser ut som Munch stil.",
    "st_top_13": "Teknik för att stödja Enpainter",
    "st_top_14": "Enpainter è un'intelligenza artificiale che ha imparato lo stile della pittura di pittori famosi utilizzando Deep Learning.  I stället för att tillämpa en specifik målning av en målare, lär sig AI egenskaperna hos målare målningar genom att utbilda AI på ett stort antal målningar. Detta gör det möjligt att ge en okänd bild till Enpainter och omvandla den till en målning som om det hade målats av konstnären.",
    "st_top_15": "Kan använda olika kända konstnärs stilar för att skapa dina originalbilder.",
    "st_top_16": "Mycket lätt att använda",
    "st_top_17": "Ladda upp fil",
    "st_top_18": "Välj Painter",
    "st_top_19": "Få en bild omvandlas till en målare stil!",
    "st_top_20": "Ladda upp bilden du vill konvertera till artist stil i filen valområdet längst upp på denna sida.",
    "st_top_21": "Välj den artist du vill använda bilden som du laddade upp.",
    "st_top_22": "Vänta ungefär 30 sekunder, då du kommer att kunna få en bild omvandlas till stilen av konstnären du väljer. Kontrollera bilden och ladda ned det om det är inga problem.",
    "st_top_23": "Du kan dra nytta av Enpainter i olika situationer.",
    "st_top_24": "Du kan skapa en mängd olika konstruktionsmaterial.",
    "st_top_25": "Enpainter kan omvandlas från ett enda foto till olika konstnärs stilar, så det kan omvandlas till en mängd olika konstruktionsmaterial. Du kan också använda den för att utforma material och broschyrer för webbplatser och banderoller, liksom för att göra flygblad. Jag skulle vilja att ni prova ut när du vill ändra smaken av foto du har.",
    "st_top_26": "Använd den för speciella gåvor som ett bröllop!",
    "st_top_27": "Gör en bröllops välkommen ombord i stil med en Van Gogh. Konvertera en familjealbum i olika stilar av målningar. Konvertera minnesmärke bilder till Monet stil och skriva ut dem på duk eller akrylplattor att hänga i ditt rum. Den kan användas i en mängd olika sätt.",
    "st_top_28": "Skapa textildesign",
    "st_top_29": "Du kan skapa en mängd olika textilier från en enda typ. Du kan använda konverterade bilder till din tapet, tyg eller nya textilier.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Du kan använda API för att arbeta med olika system.",
    "st_top_32": "cre8tiveAI Enpainter API gör det möjligt att införliva AI som kan omvandlas till olika konstnärs stilar i din tjänst. Villkoren för genomförandet API varierar beroende på vilken tjänst du vill uppnå och specifikationerna för systemet du arbetar med, så kontakta usfrom nedan.",
    "st_top_33": "Painter stil begäran konvertering",
    "st_top_34": "Att skicka en bild konverteras till stilen av en konstnär",
    "st_top_35": "Olika företagstjänster",
    "st_top_36": "Fråga om API",
    "st_top_37": "Du kan få mer än 10 kända artister stil.",
    "st_top_38": "Välj"
  },
  "it": {
    "st_top_1": "AI crea vari dipinti di tipo artistico.",
    "st_top_2": "Carichiamo le tue foto ora!",
    "st_top_1_1": "Una sola fotografia<br>diventa un'arte.",
    "st_top_3": "Che cos'è Enpainter?",
    "st_top_4": "Fai il login per utilizzare",
    "st_top_5": "Trascina un'immagine qui, oppure",
    "st_top_6": "seleziona un file",
    "st_top_7": "Cos'è l'AI \"Enpainter\" che elabora un quadro come un dipinto di un famoso pittore?",
    "st_top_8": "È un'intelligenza artificiale che ha imparato le caratteristiche di vari artisti famosi. Enpainter ha imparato più di 10 diverse caratteristiche di artisti famosi, come Van Gogh, Picasso e Munch. Enpainter apprende i diversi stili di pittura di ogni pittore, in modo da poter creare immagini di quadri (acquerelli, dipinti ad olio) come se fossero resuscitati fino ai giorni nostri.basta caricare una foto e ottenere un'immagine originale come un pittore famoso.",
    "st_top_9": "Può applicare lo stile di un artista a una varietà di immagini",
    "st_top_10": "Fallo sembrare in stile Van Gogh.",
    "st_top_11": "Fallo sembrare alla maniera di Pablo Picasso.",
    "st_top_12": "Fallo sembrare come lo stile di Munch.",
    "st_top_13": "Tecnologia a supporto di Enpainter",
    "st_top_14": "Enpainter è un'intelligenza artificiale che ha imparato lo stile della pittura di pittori famosi utilizzando l'apprendimento profondo. Invece di applicare un dipinto specifico di un pittore, l'IA impara le caratteristiche dei dipinti del pittore allenando l'IA su un gran numero di dipinti. Questo permette di dare un'immagine sconosciuta a Enpainter e di convertirla in un dipinto come se fosse stata dipinta dal pittore.",
    "st_top_15": "Può applicare diversi stili di artisti famosi per creare le vostre immagini originali.",
    "st_top_16": "Molto facile da usare",
    "st_top_17": "Carica file",
    "st_top_18": "Seleziona Pittore",
    "st_top_19": "Ottenere un quadro convertito nello stile di un pittore!",
    "st_top_20": "Si prega di caricare la foto che si desidera convertire in stile artista nell'area di selezione dei file in cima a questa pagina.",
    "st_top_21": "Selezionare l'artista che si desidera applicare all'immagine caricata.",
    "st_top_22": "Aspettate circa 30 secondi, poi potrete ottenere un'immagine convertita allo stile dell'artista che avete scelto. Controllate l'immagine e scaricatela se non ci sono problemi.",
    "st_top_23": "Potete approfittare di Enpainter in diverse situazioni.",
    "st_top_24": "È possibile creare un'ampia varietà di materiali di design.",
    "st_top_25": "Enpainter può essere convertito da una singola foto a diversi stili di artisti, in modo da poter essere convertito in una varietà di materiali di design. Può anche essere utilizzato per la progettazione di materiali e brochure per siti web e banner, così come per la realizzazione di volantini. Vorrei che lo provaste quando volete cambiare il gusto della foto che avete.",
    "st_top_26": "Usatelo per regali speciali come un matrimonio! ",
    "st_top_27": "Fate un comitato di benvenuto per il matrimonio nello stile di un Van Gogh. Convertire un album di famiglia in vari stili di dipinti. Convertire foto commemorative in stile Monet e stamparle su tela o su lastre acriliche da appendere in camera. Può essere utilizzato in vari modi.",
    "st_top_28": "Creazione di design tessile",
    "st_top_29": "È possibile creare una varietà di tessuti da un unico tipo. È possibile applicare immagini convertite alla carta da parati, al tessuto o a nuovi tessuti.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "È possibile utilizzare le API per lavorare con vari sistemi.",
    "st_top_32": "cre8tiveAI Enpainter API permette di incorporare AI che può essere convertito in vari stili di artisti nel vostro servizio. \nLe condizioni per l'implementazione dell'API variano a seconda del servizio che si desidera ottenere e delle specifiche del sistema con cui si lavora, quindi si prega di contattarci da qui sotto.",
    "st_top_33": "Richiesta di conversione in stile pittore",
    "st_top_34": "Inviare un'immagine convertita allo stile di un artista",
    "st_top_35": "Vari servizi aziendali",
    "st_top_36": "Richiedi informazioni sull'API",
    "st_top_37": "È possibile ottenere più di 10 artisti famosi stile.",
    "st_top_38": "Selezionare"
  },
  "ko": {
    "st_top_1": "AI가 10 초 안에 다양한 아티스트와 같은 그림을 만듭니다.",
    "st_top_2": "지금 사진을 업로드합시다!",
    "st_top_1_1": "단일 사진<br>아트된다.",
    "st_top_3": "Enpainter이란?",
    "st_top_4": "로그인 하고 이용하기",
    "st_top_5": "여기에 이미지를 드러그 또는,",
    "st_top_6": "파일을 선택",
    "st_top_7": "사진을 유명 화가의 회화풍으로 가공하는 AI 'Enpainter'란?",
    "st_top_8": "다양한 유명 화가의 특징들을 학습한 AI입니다.고흐나 피카소, 뭉크 등 누구나 알고 있는 유명 화가의 특징을 10가지 이상 학습하고 있습니다.Enpainter는 각 화가의 다양한 화풍을 학습시키고 있기 때문에 마치 그 화가가 현대에 살아난 것 같은 회화(수채화, 유화)의 이미지를 만들어 낼 수 있습니다.사진을 업로드하는 것만으로 유명 화가가 그려준 것 같은 오리지널 이미지를 곧바로 GET 할 수 있습니다.",
    "st_top_9": "다양한 이미지에 화가의 스타일을 적용",
    "st_top_10": "빈센트 반 고흐풍처럼 만들고 싶다",
    "st_top_11": "파블로 피카소풍으로 만들고 싶다",
    "st_top_12": "에드발드 뭉크풍으로 만들고싶다",
    "st_top_13": "Enpainter를 지탱하는 기술",
    "st_top_14": "Enpainter는 Deep Learning을 사용하여 유명 화가의 화풍을 학습한 AI입니다. 화가의 특정 회화를 적용하는 방식이 아니라 다수의 회화들을 AI에게 학습시키는 기법을 사용하여 해당 화가의 특징을 학습시키고 있습니다.그래서 미지의 이미지를 Enpainter에게 줘도 마치 그 화가가 그린 것 같은 그림으로 변환해 줍니다.",
    "st_top_15": "우리는 다양한 유명 화가들의 스타일을 적용하여 자신만의 오리지널 이미지를 만들어 낼 수 있다",
    "st_top_16": "이용방법은 매우 간단합니다.",
    "st_top_17": "파일 업로드",
    "st_top_18": "화가를 선택",
    "st_top_19": "화가풍으로 변환된 이미지를 GET!",
    "st_top_20": "화가풍으로 변환하고 싶은 이미지를 본 페이지 상단에 있는 파일 선택 영역에 업로드 해 주세요.",
    "st_top_21": "자신이 올린 이미지에 적합한 화가를 선택하세요.",
    "st_top_22": "30초 정도 기다려 주시면 화가풍으로 변환된 이미지가 그려집니다.이미지를 확인하시고 문제가 없다면 이미지를 다운로드해 주세요.",
    "st_top_23": "다양한 상황에서 Enpainter를 활용할 수 있습니다.",
    "st_top_24": "다양한 디자인 소재를 만들어 낼 수 있습니다.",
    "st_top_25": "한 장의 사진에서 다양한 화가풍으로 변환할 수 있기 때문에 다양한 디자인 소재로 변환할 수 있습니다.웹 사이트 및 배너의 소재와 팜플렛 디자인, 전단 작성 등에도 이용하실 수 있습니다.사진도 좋지만, 그 사진 소재의 텍스쳐를 바꾸고 싶을 때 사용해 주셨으면 합니다.",
    "st_top_26": "결혼식 등 특별한 선물로!",
    "st_top_27": "결혼식의 웰컴 보드를 고흐풍으로 하거나 가족의 앨범을 여러가지 화가풍으로 만들어 보거나 입학식등의 기념 촬영에 모네풍으로 변환해 캔버스지나 아크릴판에 프린트 해 방에 장식하는 것도 좋습니다.",
    "st_top_28": "텍스타일 디자인을 만들어 낼 수 있습니다!",
    "st_top_29": "한종류의 텍스타일에서 다양한 텍스타일들을 만들 수 있습니다.그대로 벽지나 패브릭에 이용하거나 변환 화상을 새로운 텍스트 스타일 디자인에 응용할 수 있습니다.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "API를 사용하여 각종 시스템과의 연계가 가능합니다.",
    "st_top_32": "cre8tive AI Enpainter API를 이용하면 당신의 서비스에 다양한 화가풍으로 변환할 수 있는 AI를 도입할 수 있습니다.API의 도입은 어떤 서비스를 실현하고 싶은지 및 연계하는 시스템의 사양 등에 따라 여러 조건이 바뀌므로, 아래로 문의해 주십시오.",
    "st_top_33": "화가풍 변환요청",
    "st_top_34": "화가풍으로 변환된 화상의 전송",
    "st_top_35": "다양한 기업 서비스",
    "st_top_36": "API에 대해 문의",
    "st_top_37": "열 명 이상의 유명 화가가 그려줍니다",
    "st_top_38": "고르다"
  },
  "ar": {
    "st_top_1": "AI يخلق لوحات مختلفة مثل الفنان.",
    "st_top_2": "دعونا تحميل الصور الخاصة بك الآن!",
    "st_top_1_1": "تصبح صورة واحدة فنا.",
    "st_top_3": "ما هو Enpainter؟",
    "st_top_4": "تسجيل الدخول إلى استخدام",
    "st_top_5": "صور السحب هنا أو",
    "st_top_6": "اختر الملفات.",
    "st_top_7": "ما هي منظمة العفو الدولية \"Enpainter\" الذي يعالج صورة مثل لوحة للرسام الشهير؟",
    "st_top_8": "وهي منظمة العفو الدولية أن تعلم خصائص مختلف artists.Enpainter الشهيرة علمت أكثر من 10 ميزات مختلفة من الفنانين المشهورين، مثل فان جوخ وبيكاسو ومونش. Enpainter يتعلم أنماط مختلفة من الطلاء قبل كل رسام، حتى تتمكن من خلق صور لوحات (الألوان المائية واللوحات الزيتية) كما لو كانوا أحياء حتى الوقت الحاضر day.Simply تحميل الصور والحصول على الصورة الأصلية مثل الرسام الشهير.",
    "st_top_9": "يمكن تطبيق أسلوب فنان لمجموعة متنوعة من الصور",
    "st_top_10": "جعلها تبدو وكأنها أسلوب فان جوخ.",
    "st_top_11": "جعلها تبدو وكأنها أسلوب بابلو بيكاسو.",
    "st_top_12": "جعلها تبدو وكأنها أسلوب مونش.",
    "st_top_13": "التكنولوجيا لدعم Enpainter",
    "st_top_14": "Enpainter هو منظمة العفو الدولية أن تعلم أسلوب اللوحة من قبل الرسامين الشهيرة باستخدام Deep Learning. بدلا من تطبيق لوحة محددة من قبل رسام، ومنظمة العفو الدولية يتعلم خصائص لوحات الرسام من خلال تدريب AI على عدد كبير من اللوحات. هذا يسمح لك لإعطاء صورة غير معروفة لEnpainter وتحويله إلى لوحة كما لو كانت قد رسمت من قبل رسام.",
    "st_top_15": "يمكن تطبيق أساليب مختلفة مشاهير الفنانين \"لإنشاء الصور الأصلية.",
    "st_top_16": "من السهل جدا للاستخدام",
    "st_top_17": "رفع ملف",
    "st_top_18": "اختر الرسام",
    "st_top_19": "الحصول على صورة تحويلها إلى أسلوب الرسام!",
    "st_top_20": "يرجى تحميل الصورة التي تريد تحويله إلى أسلوب الفنان في منطقة التحديد ملف في أعلى هذه الصفحة.",
    "st_top_21": "حدد الفنان الذي تريد تطبيقه إلى الصورة التي تم تحميلها.",
    "st_top_22": "انتظر حوالي 30 ثانية، وبعد ذلك سوف تكون قادرة على الحصول على صورة تحويلها إلى أسلوب الفنان الذي تختاره. يرجى التحقق من الصورة وتحميل البرنامج إذا لم يكن هناك مشكلة.",
    "st_top_23": "يمكنك الاستفادة من Enpainter في مجموعة متنوعة من الحالات.",
    "st_top_24": "يمكنك إنشاء مجموعة متنوعة واسعة من المواد التصميم.",
    "st_top_25": "Enpainter يمكن تحويلها من صورة واحدة لأساليب مختلف الفنانين، لذلك يمكن تحويلها إلى مجموعة متنوعة من المواد التصميم. يمكنك أيضا استخدامه لتصميم المواد والكتيبات للمواقع وشعارات، وكذلك لجعل الركاب. أود لك لمحاولة الخروج عندما تريد تغيير طعم الصورة لديك.",
    "st_top_26": "استخدامه للهدايا خاصة مثل حفل زفاف!",
    "st_top_27": "جعل لوحة زفاف موضع ترحيب في اسلوب فان جوخ. تحويل ألبوم العائلة إلى أنماط مختلفة من اللوحات. تحويل الصور التذكارية لأسلوب مونيه وطباعتها على قماش أو الاكريليك لوحات لشنق في الغرفة الخاصة بك. ويمكن استخدامها في مجموعة متنوعة من الطرق.",
    "st_top_28": "إنشاء تصميم المنسوجات",
    "st_top_29": "يمكنك إنشاء مجموعة متنوعة من المنسوجات من نوع واحد. يمكنك تطبيق الصور التي تم تحويلها إلى ورق الحائط الخاص بك، والنسيج، أو المنسوجات جديدة.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "يمكنك استخدام API للعمل مع الأجهزة المختلفة.",
    "st_top_32": "cre8tiveAI Enpainter API تسمح لك لدمج AI التي يمكن تحويلها إلى أنماط مختلفة الفنان في خدمتكم. تختلف الظروف لتنفيذ API اعتمادا على الخدمة التي تريد تحقيق ومواصفات النظام الذي عمل مع، لذا يرجى usfrom الاتصال أدناه.",
    "st_top_33": "طلب تحويل أسلوب الرسام",
    "st_top_34": "إرسال صورة تحويلها إلى النمط فنان",
    "st_top_35": "خدمات للشركات المختلفة",
    "st_top_36": "استفسار عن API",
    "st_top_37": "يمكنك الحصول على أكثر من 10 الشهيرة على غرار الفنانين.",
    "st_top_38": "تحديد"
  },
  "th": {
    "st_top_1": "AI สร้างภาพวาดศิลปินเหมือนต่างๆ",
    "st_top_2": "ลองอัปโหลดภาพถ่ายของคุณตอนนี้!",
    "st_top_1_1": "รูปถ่ายเพียงครั้งเดียวจะกลายเป็นงานศิลปะ",
    "st_top_3": "Enpainter คืออะไร?",
    "st_top_4": "ลงชื่อเข้าใช้",
    "st_top_5": "ลากภาพที่นี่หรือ",
    "st_top_6": "เลือกไฟล์",
    "st_top_7": "เอไอ \"Enpainter\" ที่ประมวลผลภาพเหมือนภาพวาดโดยจิตรกรที่มีชื่อเสียงคืออะไร?",
    "st_top_8": "มันเป็น AI ที่ได้เรียนรู้ลักษณะของ artists.Enpainter ต่างๆที่มีชื่อเสียงได้เรียนรู้มากกว่า 10 คุณสมบัติที่แตกต่างกันของศิลปินที่มีชื่อเสียงเช่น Van Gogh, Picasso และเคี้ยว Enpainter เรียนรู้สไตล์ที่แตกต่างของการวาดภาพโดยแต่ละจิตรกรเพื่อให้คุณสามารถสร้างภาพของภาพวาด (สีน้ำภาพวาดสีน้ำมัน) ราวกับว่าพวกเขาฟื้นขึ้นมาจนถึงปัจจุบัน day.Simply อัปโหลดภาพและได้รับภาพต้นฉบับเหมือนจิตรกรที่มีชื่อเสียง",
    "st_top_9": "สามารถใช้สไตล์ของศิลปินเพื่อความหลากหลายของภาพ",
    "st_top_10": "ให้มีลักษณะเหมือนรูปแบบแวนโก๊ะ",
    "st_top_11": "ทำให้ดูเหมือนสไตล์ Pablo Picasso",
    "st_top_12": "ให้มีลักษณะเหมือนรูปแบบเคี้ยว",
    "st_top_13": "เทคโนโลยีเพื่อสนับสนุน Enpainter",
    "st_top_14": "Enpainter เป็น AI ที่ได้เรียนรู้รูปแบบของภาพวาดโดยจิตรกรที่มีชื่อเสียงโดยใช้ Deep Learning แทนที่จะใช้เฉพาะภาพวาดโดยจิตรกรเอไอเรียนรู้ลักษณะของภาพวาดของจิตรกรโดยการฝึกอบรมเอไอในจำนวนมากของภาพวาด นี้จะช่วยให้คุณที่จะให้ภาพที่ไม่รู้จักกับ Enpainter และแปลงเป็นภาพวาดราวกับว่ามันได้รับการวาดโดยจิตรกร",
    "st_top_15": "สามารถนำไปใช้รูปแบบศิลปินที่มีชื่อเสียงที่แตกต่างกันในการสร้างภาพต้นฉบับของคุณ",
    "st_top_16": "ใช้งานง่ายมาก",
    "st_top_17": "อัพโหลดไฟล์",
    "st_top_18": "เลือกจิตรกร",
    "st_top_19": "ได้รับภาพแปลงเป็นรูปแบบของจิตรกร!",
    "st_top_20": "โปรดอัปโหลดภาพที่คุณต้องการแปลงให้เข้ากับสไตล์ของศิลปินในพื้นที่การเลือกไฟล์ที่ด้านบนสุดของหน้านี้",
    "st_top_21": "เลือกศิลปินที่คุณต้องการที่จะนำไปใช้กับภาพที่คุณอัปโหลด",
    "st_top_22": "รอสักครู่ประมาณ 30 วินาทีจากนั้นคุณจะสามารถที่จะได้รับภาพที่แปลงเป็นรูปแบบของศิลปินที่คุณเลือก กรุณาตรวจสอบภาพและดาวน์โหลดได้ถ้าไม่มีปัญหา",
    "st_top_23": "คุณสามารถใช้ประโยชน์จาก Enpainter ในหลากหลายสถานการณ์",
    "st_top_24": "คุณสามารถสร้างความหลากหลายของวัสดุการออกแบบ",
    "st_top_25": "Enpainter สามารถแปลงจากภาพเดียวรูปแบบศิลปินต่าง ๆ ดังนั้นจึงสามารถแปลงเป็นความหลากหลายของวัสดุการออกแบบ นอกจากนี้คุณยังสามารถใช้สำหรับการออกแบบวัสดุและโบรชัวร์สำหรับเว็บไซต์และป้ายเช่นเดียวกับการทำใบปลิว ผมอยากให้คุณลองออกเมื่อคุณต้องการที่จะเปลี่ยนรสชาติของภาพที่คุณมี",
    "st_top_26": "ใช้สำหรับของขวัญพิเศษเช่นงานแต่งงาน!",
    "st_top_27": "ให้คณะกรรมการจัดงานแต่งงานต้อนรับในรูปแบบของแวนโก๊ะ แปลงอัลบั้มครอบครัวลงในหลากหลายรูปแบบของภาพวาด แปลงภาพถ่ายที่ระลึกถึงสไตล์โมเนต์และพิมพ์บนผืนผ้าใบหรืออะคริลิแผ่นที่จะแขวนในห้องของคุณ มันสามารถนำมาใช้ในความหลากหลายของวิธีการ",
    "st_top_28": "การสร้างการออกแบบสิ่งทอ",
    "st_top_29": "คุณสามารถสร้างความหลากหลายของสิ่งทอจากชนิดเดียว คุณสามารถใช้ภาพที่แปลงวอลล์เปเปอร์ผ้าหรือสิ่งทอใหม่ของคุณ",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "คุณสามารถใช้ API เพื่อทำงานร่วมกับระบบต่างๆ",
    "st_top_32": "cre8tiveAI Enpainter API ช่วยให้คุณสามารถที่จะรวม AI ที่สามารถแปลงเป็นรูปแบบของศิลปินต่าง ๆ ในการให้บริการของคุณ เงื่อนไขสำหรับการดำเนินงาน API แตกต่างกันไปขึ้นอยู่กับบริการที่คุณต้องการเพื่อให้บรรลุและข้อกำหนดของระบบการทำงานของคุณด้วยดังนั้นโปรดติดต่อ usfrom ด้านล่าง",
    "st_top_33": "คำขอแปลงจิตรกรสไตล์",
    "st_top_34": "ส่งภาพแปลงเป็นรูปแบบของศิลปิน",
    "st_top_35": "บริการขององค์กรต่างๆ",
    "st_top_36": "สอบถามเกี่ยวกับ API ได้",
    "st_top_37": "คุณจะได้รับรูปแบบมากขึ้นกว่า 10 ศิลปินที่มีชื่อเสียง",
    "st_top_38": "เลือก"
  },
  "id": {
    "st_top_1": "AI menciptakan berbagai lukisan seniman-seperti.",
    "st_top_2": "Mari kita meng-upload foto Anda sekarang!",
    "st_top_1_1": "Sebuah foto tunggal<br>menjadi sebuah seni.",
    "st_top_3": "Apa Enpainter?",
    "st_top_4": "masuk ke penggunaan",
    "st_top_5": "Drag gambar sini atau",
    "st_top_6": "Pilih file.",
    "st_top_7": "Apa AI \"Enpainter\" yang memproses gambar seperti lukisan oleh pelukis terkenal?",
    "st_top_8": "Ini adalah AI yang mempelajari karakteristik berbagai artists.Enpainter terkenal telah belajar lebih dari 10 fitur yang berbeda dari artis terkenal, seperti Van Gogh, Picasso dan Munch. Enpainter belajar gaya yang berbeda dari lukisan oleh masing-masing pelukis, sehingga Anda dapat membuat gambar lukisan (cat air, lukisan minyak) seolah-olah mereka dibangkitkan sampai sekarang day.Simply meng-upload foto dan mendapatkan gambar asli seperti pelukis terkenal.",
    "st_top_9": "Dapat menerapkan gaya seorang seniman untuk berbagai gambar",
    "st_top_10": "Membuatnya terlihat seperti gaya Van Gogh.",
    "st_top_11": "Membuatnya terlihat seperti gaya Pablo Picasso.",
    "st_top_12": "Membuatnya terlihat seperti gaya Munch.",
    "st_top_13": "Teknologi untuk mendukung Enpainter",
    "st_top_14": "Enpainter adalah AI yang belajar gaya lukisan oleh pelukis terkenal dengan menggunakan Deep Learning. Daripada menerapkan sebuah lukisan tertentu dengan pelukis, AI belajar karakteristik lukisan pelukis dengan melatih AI pada sejumlah besar lukisan. Hal ini memungkinkan Anda untuk memberikan gambar yang tidak diketahui Enpainter dan mengubahnya menjadi lukisan seolah-olah itu telah dilukis oleh pelukis.",
    "st_top_15": "Dapat menerapkan gaya artis terkenal yang berbeda untuk membuat gambar asli Anda.",
    "st_top_16": "Sangat mudah untuk digunakan",
    "st_top_17": "Unggah data",
    "st_top_18": "Pilih Painter",
    "st_top_19": "Mendapatkan gambaran diubah menjadi gaya pelukis!",
    "st_top_20": "Silakan upload foto yang ingin dikonversi ke gaya artis di daerah pemilihan file di bagian atas halaman ini.",
    "st_top_21": "Pilih artis yang Anda ingin menerapkan untuk gambar yang Anda upload.",
    "st_top_22": "Tunggu sekitar 30 detik, maka Anda akan bisa mendapatkan gambar dikonversi dengan gaya artis yang Anda pilih. Silakan periksa gambar dan men-download jika tidak ada masalah.",
    "st_top_23": "Anda dapat mengambil keuntungan dari Enpainter dalam berbagai situasi.",
    "st_top_24": "Anda dapat membuat berbagai macam bahan desain.",
    "st_top_25": "Enpainter dapat dikonversi dari satu foto dengan gaya berbagai artis, sehingga dapat dikonversi ke berbagai bahan desain. Anda juga dapat menggunakannya untuk merancang bahan dan brosur untuk website dan spanduk, serta untuk membuat brosur. Saya ingin Anda untuk mencoba ketika Anda ingin mengubah rasa dari foto yang Anda miliki.",
    "st_top_26": "Menggunakannya untuk hadiah khusus seperti pernikahan!",
    "st_top_27": "Membuat papan pernikahan selamat datang dalam gaya Van Gogh. Mengkonversi sebuah album keluarga menjadi berbagai gaya lukisan. Mengkonversi foto peringatan untuk gaya Monet dan mencetaknya di atas kanvas atau akrilik piring untuk menggantung di kamar Anda. Hal ini dapat digunakan dalam berbagai cara.",
    "st_top_28": "Membuat desain tekstil",
    "st_top_29": "Anda dapat membuat berbagai tekstil dari satu jenis. Anda dapat menerapkan gambar dikonversi ke wallpaper Anda, kain, atau tekstil baru.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Anda dapat menggunakan API untuk bekerja dengan berbagai sistem.",
    "st_top_32": "cre8tiveAI Enpainter API memungkinkan Anda untuk menggabungkan AI yang dapat diubah menjadi berbagai gaya artis di layanan Anda. Kondisi untuk implementasi API bervariasi tergantung pada layanan yang ingin Anda capai dan spesifikasi dari sistem Anda bekerja dengan, jadi silakan kontak usfrom bawah.",
    "st_top_33": "Permintaan konversi pelukis bergaya",
    "st_top_34": "Mengirim gambar dikonversi dengan gaya seorang seniman",
    "st_top_35": "Berbagai layanan korporasi",
    "st_top_36": "Menanyakan tentang API",
    "st_top_37": "Anda bisa mendapatkan lebih dari 10 gaya artis terkenal.",
    "st_top_38": "Pilih"
  },
  "pl": {
    "st_top_1": "AI tworzy różne obrazy artystyczne.",
    "st_top_2": "Załadujmy twoje zdjęcia teraz!",
    "st_top_1_1": "Pojedyncza fotografia<br>zakłada się na sztukę.",
    "st_top_3": "Co to jest Enpainter?",
    "st_top_4": "zaloguj się do użytku",
    "st_top_5": "Przeciągnij zdjęcia tutaj lub",
    "st_top_6": "Wybierz pliki.",
    "st_top_7": "Czym jest AI \"Enpainter\", który przetwarza obraz jak obraz znanego malarza?",
    "st_top_8": "Jest to SI, która poznała charakterystykę różnych znanych artystów. Malarz poznał ponad 10 różnych cech znanych artystów, takich jak Van Gogh, Picasso i Munch. Enpainter uczy się różnych stylów malowania każdego malarza, dzięki czemu można tworzyć obrazy (akwarele, obrazy olejne) tak, jakby były wskrzeszone do dnia dzisiejszego.Wystarczy załadować zdjęcie i uzyskać oryginalny obraz jak słynny malarz.",
    "st_top_9": "Potrafi zastosować styl artysty do różnych obrazów",
    "st_top_10": "Niech wygląda jak styl Van Gogha.",
    "st_top_11": "Spraw, by wyglądał jak styl Pabla Picassa.",
    "st_top_12": "Niech wygląda jak styl Muncha.",
    "st_top_13": "Technologia wspomagająca malarza",
    "st_top_14": "Enpainter jest SI, która nauczyła się stylu malowania znanych malarzy za pomocą Deep Leaning. Zamiast stosować konkretny obraz malarza, SI uczy się charakterystyki obrazów malarza, szkoląc go na dużej liczbie obrazów. Pozwala to na nadanie Enpainter nieznanego obrazu i przekształcenie go w obraz, tak jakby został namalowany przez malarza.",
    "st_top_15": "Może stosować różne style znanych artystów, aby stworzyć swoje oryginalne obrazy.",
    "st_top_16": "Bardzo łatwy w użyciu",
    "st_top_17": "Prześlij plik",
    "st_top_18": "Wybierz Malarz",
    "st_top_19": "Przekształć obraz w styl malarza!",
    "st_top_20": "Zdjęcie, które chcesz przekonwertować na styl artysty, prześlij w obszarze wyboru plików na górze tej strony.",
    "st_top_21": "Wybierz artystę, który chcesz zastosować do przesłanego obrazu.",
    "st_top_22": "Poczekaj około 30 sekund, wtedy będziesz mógł uzyskać obraz przekonwertowany na wybrany przez siebie styl artysty. Proszę sprawdzić obraz i pobrać go, jeśli nie ma problemu.",
    "st_top_23": "Możesz skorzystać z usługi Enpainter w różnych sytuacjach.",
    "st_top_24": "Możesz stworzyć szeroką gamę materiałów projektowych.",
    "st_top_25": "Enpainter może być przekształcony z jednego zdjęcia do różnych stylów artystów, więc może być przekształcony do różnych materiałów projektowych. Można go również używać do projektowania materiałów i broszur na stronach internetowych i banerach, a także do tworzenia ulotek. Chciałbym, abyś spróbował, kiedy chcesz zmienić smak zdjęcia, które posiadasz.",
    "st_top_26": "Użyj go na specjalne prezenty, jak na przykład ślub! ",
    "st_top_27": "Zróbcie weselną deskę powitalną w stylu Van Gogha. Zamień album rodzinny na różne style obrazów. Zamień zdjęcia pamiątkowe na styl Moneta i wydrukuj je na płótnie lub płycie akrylowej do powieszenia w swoim pokoju. Może być używany na różne sposoby.",
    "st_top_28": "Tworzenie projektów tekstylnych",
    "st_top_29": "Można tworzyć różne tekstyliów z jednego typu. Możesz zastosować przekonwertowane obrazy na tapetę, tkaninę lub nowe tekstylia.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Możesz używać API do pracy z różnymi systemami.",
    "st_top_32": "cre8tiveAI Enpainter API pozwala na włączenie AI, która może być konwertowana na różne style artystów w Twoim serwisie.warunki wdrożenia API różnią się w zależności od usługi, którą chcesz osiągnąć i specyfikacji systemu, z którym pracujesz, więc prosimy o kontakt z usfrom poniżej.",
    "st_top_33": "Wniosek o konwersję w stylu malarskim",
    "st_top_34": "Wysyłanie obrazu przekształconego w styl artysty",
    "st_top_35": "Różne usługi korporacyjne",
    "st_top_36": "Zapytaj o API",
    "st_top_37": "Można uzyskać więcej niż 10 znanych artystów.",
    "st_top_38": "Wybierz"
  },
  "vi": {
    "st_top_1": "AI tạo ra nhiều bức tranh nghệ sĩ như thế nào.",
    "st_top_2": "Hãy upload ảnh của bạn bây giờ!",
    "st_top_1_1": "Một bức ảnh duy nhất trở thành<br>một nghệ thuật.",
    "st_top_3": "Enpainter là gì?",
    "st_top_4": "đăng nhập vào sử dụng",
    "st_top_5": "Kéo hình ảnh đây hoặc",
    "st_top_6": "Chọn tệp tin.",
    "st_top_7": "AI \"Enpainter\" mà xử lý một bức tranh như một bức tranh của một họa sĩ nổi tiếng là gì?",
    "st_top_8": "Nó là một AI đó học được những đặc điểm của artists.Enpainter nổi tiếng khác nhau đã học được hơn 10 tính năng khác nhau của các nghệ sĩ nổi tiếng, chẳng hạn như Van Gogh, Picasso và Munch. Enpainter học các phong cách khác nhau của hội họa của mỗi họa sĩ, vì vậy bạn có thể tạo ra hình ảnh của bức tranh (màu nước, tranh sơn dầu) như thể họ đã sống lại đến nay day.Simply tải lên một bức ảnh và nhận được một hình ảnh ban đầu giống như một họa sĩ nổi tiếng.",
    "st_top_9": "Có thể áp dụng phong cách của một nghệ sĩ với một loạt các hình ảnh",
    "st_top_10": "Làm cho nó trông giống như phong cách Van Gogh.",
    "st_top_11": "Làm cho nó trông giống như phong cách của Pablo Picasso.",
    "st_top_12": "Làm cho nó trông giống như phong cách Munch.",
    "st_top_13": "Công nghệ để hỗ trợ Enpainter",
    "st_top_14": "Enpainter là một AI mà học được phong cách của vẽ bởi họa sĩ nổi tiếng bằng cách sử dụng Deep Learning. Thay vì áp dụng một bức tranh cụ thể bằng một họa sĩ, AI biết các đặc điểm của những bức tranh của họa sĩ bằng cách đào tạo AI trên một số lượng lớn các bức tranh. Điều này cho phép bạn đưa ra một hình ảnh chưa biết đến Enpainter và chuyển đổi nó thành một bức tranh như thể nó đã được vẽ bởi họa sĩ.",
    "st_top_15": "Có thể áp dụng phong cách nghệ sĩ nổi tiếng khác nhau để tạo ra hình ảnh ban đầu của bạn.",
    "st_top_16": "Rất dễ dàng để sử dụng",
    "st_top_17": "Cập nhật dử liệu",
    "st_top_18": "Chọn Painter",
    "st_top_19": "Được một hình ảnh chuyển đổi thành phong cách của một họa sĩ!",
    "st_top_20": "Hãy tải lên ảnh bạn muốn chuyển đổi sang phong cách nghệ sĩ trong lĩnh vực lựa chọn tập tin ở phía trên cùng của trang này.",
    "st_top_21": "Chọn nghệ sĩ bạn muốn áp dụng cho các hình ảnh bạn đã tải lên.",
    "st_top_22": "Chờ khoảng 30 giây, sau đó bạn sẽ có thể để có được một hình ảnh chuyển đổi sang phong cách của các nghệ sĩ mà bạn chọn. Vui lòng kiểm tra hình ảnh và tải về nếu không có vấn đề.",
    "st_top_23": "Bạn có thể tận dụng lợi thế của Enpainter trong một loạt các tình huống.",
    "st_top_24": "Bạn có thể tạo ra một loạt các vật liệu thiết kế.",
    "st_top_25": "Enpainter thể được chuyển đổi từ một bức ảnh duy nhất để phong cách khác nhau của các nghệ sĩ, vì vậy nó có thể được chuyển đổi sang nhiều loại vật liệu thiết kế. Bạn cũng có thể sử dụng nó để thiết kế vật liệu và tài liệu quảng cáo cho các trang web và các biểu ngữ, cũng như để làm tờ rơi quảng cáo. Tôi muốn bạn thử ra khi bạn muốn thay đổi khẩu vị của ảnh mà bạn có.",
    "st_top_26": "Sử dụng nó cho những món quà đặc biệt như một đám cưới!",
    "st_top_27": "Thực hiện một bảng chào đón đám cưới theo phong cách của một Van Gogh. Chuyển đổi một album gia đình vào phong cách khác nhau của bức tranh. Chuyển đổi hình ảnh lưu niệm với phong cách Monet và in chúng trên vải hoặc acrylic tấm để treo trong phòng của bạn. Nó có thể được sử dụng trong nhiều cách khác nhau.",
    "st_top_28": "Tạo thiết kế dệt may",
    "st_top_29": "Bạn có thể tạo ra một loạt hàng dệt từ một loại duy nhất. Bạn có thể áp dụng các hình ảnh chuyển đổi sang hình nền của bạn, vải, hoặc dệt may mới.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Bạn có thể sử dụng API để làm việc với các hệ thống khác nhau.",
    "st_top_32": "cre8tiveAI Enpainter API cho phép bạn kết hợp AI có thể được chuyển đổi thành phong cách khác nhau nghệ sĩ trong dịch vụ của bạn. Các điều kiện để thực hiện API thay đổi tùy theo các dịch vụ mà bạn muốn đạt được và các thông số kỹ thuật của hệ thống bạn làm việc với, vì vậy xin vui lòng liên lạc usfrom dưới đây.",
    "st_top_33": "yêu cầu chuyển đổi Painter kiểu",
    "st_top_34": "Gửi một hình ảnh chuyển đổi sang phong cách của một nghệ sĩ",
    "st_top_35": "dịch vụ của công ty khác nhau",
    "st_top_36": "Hỏi về API",
    "st_top_37": "Bạn có thể nhận được nhiều hơn 10 phong cách nghệ sĩ nổi tiếng.",
    "st_top_38": "Lựa chọn"
  },
  "hi": {
    "st_top_1": "ऐ विभिन्न कलाकार की तरह चित्रों पैदा करता है।",
    "st_top_2": "के लिए अपनी तस्वीरों अब अपलोड करते हैं!",
    "st_top_1_1": "एक एकल तस्वीर एक कला बन जाता है।",
    "st_top_3": "Enpainter क्या है?",
    "st_top_4": "उपयोग करने के लिए प्रवेश",
    "st_top_5": "खींचें छवियों यहाँ या",
    "st_top_6": "फ़ाइलें चुनें।",
    "st_top_7": "ऐ \"Enpainter\" है कि एक प्रसिद्ध चित्रकार द्वारा एक पेंटिंग की तरह एक तस्वीर को संसाधित करता है क्या है?",
    "st_top_8": "यह एक ऐ कि सीखा विभिन्न प्रसिद्ध artists.Enpainter की विशेषताओं इस तरह के वान गाग, पिकासो और मंच के रूप में प्रसिद्ध कलाकारों, के 10 से अधिक विभिन्न सुविधाओं सीखा है है। Enpainter, प्रत्येक चित्रकार द्वारा चित्रकला की विभिन्न शैलियों सीखता है ताकि आप चित्रों (जल रंग, तेल चित्रों) के चित्र के रूप में यदि वे मौजूद day.Simply एक तस्वीर अपलोड करने के लिए पुनर्जीवित किया गया बना सकते हैं और एक प्रसिद्ध चित्रकार की तरह एक मूल छवि प्राप्त कर सकते हैं।",
    "st_top_9": "छवियों की एक किस्म के एक कलाकार की शैली लागू कर सकते हैं",
    "st_top_10": "यह वान गाग शैली की तरह बनाएं।",
    "st_top_11": "यह पाब्लो पिकासो शैली की तरह बनाएं।",
    "st_top_12": "यह मंच शैली की तरह बनाएं।",
    "st_top_13": "प्रौद्योगिकी Enpainter समर्थन करने के लिए",
    "st_top_14": "Enpainter एक ऐ कि Deep Learning का उपयोग करके प्रसिद्ध चित्रकारों द्वारा चित्रकला की शैली सीखा है। एक चित्रकार द्वारा एक विशिष्ट चित्र को लागू करने के बजाय, ऐ चित्रों की एक बड़ी संख्या पर ऐ प्रशिक्षण के द्वारा चित्रकार के चित्रों की विशेषताओं सीखता है। यह आपको Enpainter को एक अज्ञात छवि देने के लिए और यह एक पेंटिंग में तब्दील जैसे कि वह चित्रकार द्वारा चित्रित किया गया था करने के लिए अनुमति देता है।",
    "st_top_15": "अपने मूल चित्र बनाने के लिए विभिन्न प्रसिद्ध कलाकारों 'शैलियाँ लागू कर सकते हैं।",
    "st_top_16": "उपयोग करने के लिए बहुत आसान",
    "st_top_17": "दस्तावेज अपलोड करें",
    "st_top_18": "पेंटर का चयन करें",
    "st_top_19": "एक तस्वीर एक चित्रकार की शैली में बदल दिया हो जाओ!",
    "st_top_20": "तो आप इस पेज के शीर्ष पर फ़ाइल चयन क्षेत्र में कलाकार शैली के लिए कनवर्ट करना चाहते हैं तस्वीर अपलोड करें।",
    "st_top_21": "कलाकार आप जो चित्र आपने अपलोड करने के लिए लागू करना चाहते हैं।",
    "st_top_22": "इंतजार 30 सेकंड के बारे में है, तो आप एक छवि प्राप्त करने में सक्षम हो जाएगा कलाकार आप चुनते हैं की शैली को बदल दिया। छवि की जाँच करें और अगर वहाँ कोई समस्या नहीं है इसे डाउनलोड करें।",
    "st_top_23": "आप स्थितियों की एक किस्म में Enpainter का लाभ ले सकते।",
    "st_top_24": "आप डिजाइन सामग्री की एक विस्तृत विविधता बना सकते हैं।",
    "st_top_25": "Enpainter, विभिन्न कलाकारों 'शैलियों के लिए एक एकल तस्वीर से परिवर्तित किया जा सकता है, तो यह डिजाइन सामग्री की एक किस्म के लिए परिवर्तित किया जा सकता है। तुम भी, वेबसाइटों और बैनर के लिए सामग्री और ब्रोशर डिजाइन करने के लिए उपयोग कर सकते हैं और साथ ही यात्रियों को बनाने के लिए। मैं जब आप आप तस्वीर का स्वाद बदलना चाहते हैं आप बाहर की कोशिश करना चाहते हैं।",
    "st_top_26": "एक शादी की तरह विशेष उपहार के लिए यह प्रयोग करें!",
    "st_top_27": "एक वान गाग की शैली में एक शादी के स्वागत बोर्ड बनाओ। चित्रों के विभिन्न शैलियों में एक परिवार के एल्बम बदलें। मोनेट शैली के लिए स्मारक तस्वीरें कन्वर्ट और अपने कमरे में लटका कैनवास या एक्रिलिक प्लेटों पर उन्हें प्रिंट। यह विभिन्न तरीकों से किया जा सकता है।",
    "st_top_28": "वस्त्र डिजाइन बनाना",
    "st_top_29": "आप एक ही प्रकार से वस्त्रों की एक किस्म बना सकते हैं। आप अपने वॉलपेपर, कपड़े, या नए वस्त्रों में परिवर्तित छवियों आवेदन कर सकते हैं।",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "आप विभिन्न प्रणालियों के साथ काम करने के लिए एपीआई का उपयोग कर सकते हैं।",
    "st_top_32": "cre8tiveAI Enpainter एपीआई आप ऐ है कि आपकी सेवा में विभिन्न कलाकार शैलियों में बदला जा सकता शामिल करने के लिए अनुमति देता है। API कार्यान्वयन के लिए शर्तों सेवा और प्रणाली जिसके साथ आप काम के विनिर्देशों हासिल करना चाहते हैं के आधार पर बदलती है, तो नीचे दिए गए संपर्क usfrom कृपया।",
    "st_top_33": "पेंटर शैली रूपांतरण अनुरोध",
    "st_top_34": "एक छवि एक कलाकार की शैली करने के लिए परिवर्तित भेजा जा रहा है",
    "st_top_35": "विभिन्न कॉर्पोरेट सेवाओं",
    "st_top_36": "एपीआई के बारे में पूछताछ",
    "st_top_37": "आप 10 से अधिक प्रसिद्ध कलाकारों शैली मिल सकती है।",
    "st_top_38": "चुनते हैं"
  },
  "ru": {
    "st_top_1": "AI создает различные картины, похожие на картины художника.",
    "st_top_2": "Давайте загрузим ваши фотографии сейчас!",
    "st_top_1_1": "Одна фотография<br> - это искусство.",
    "st_top_3": "Что такое Enpainter?",
    "st_top_4": "войти в использовании",
    "st_top_5": "Перетащите изображения здесь или",
    "st_top_6": "Выбрать файлы.",
    "st_top_7": "Что такое ИИ \"Enpainter\", который обрабатывает картину как картину известного художника?",
    "st_top_8": "Это ИИ, который узнал характеристики различных известных художников. Энпейнтер узнал более 10 различных характеристик известных художников, таких как Ван Гог, Пикассо и Мунк. Enpainter изучает различные стили живописи каждого художника, так что вы можете создавать изображения картин (акварели, картины маслом), как если бы они были воскрешены до наших дней, просто загрузите фотографию и получите оригинальное изображение, как у знаменитого художника.",
    "st_top_9": "Может применить стиль художника к различным изображениям",
    "st_top_10": "Сделать его похожим на стиль Ван Гога.",
    "st_top_11": "Сделайте его похожим на стиль Пабло Пикассо.",
    "st_top_12": "Сделай это похожим на стиль Манча.",
    "st_top_13": "Технология для поддержки Enpainter",
    "st_top_14": "Enpainter - это ИИ, который узнал стиль живописи известных художников, используя Глубокое изучение. Вместо того, чтобы применять конкретную картину художника, AI изучает характеристики картин художника, обучая AI большому количеству картин. Это позволяет дать неизвестное изображение Энпэйнтеру и преобразовать его в картину, как если бы оно было написано художником.",
    "st_top_15": "Может применять различные стили известных художников для создания оригинальных изображений.",
    "st_top_16": "Очень простой в использовании",
    "st_top_17": "Загрузить файл",
    "st_top_18": "Выберите Художник",
    "st_top_19": "Получите картину, преобразованную в стиль живописца!",
    "st_top_20": "Пожалуйста, загрузите фотографию, которую вы хотите преобразовать в стиль художника, в область выбора файлов в верхней части этой страницы.",
    "st_top_21": "Выберите художника, которого хотите применить к загруженному изображению.",
    "st_top_22": "Подождите около 30 секунд, тогда вы сможете получить изображение, преобразованное в стиль выбранного вами художника. Пожалуйста, проверьте изображение и скачайте его, если нет проблем.",
    "st_top_23": "Вы можете использовать Enpainter в различных ситуациях.",
    "st_top_24": "Вы можете создавать самые разнообразные дизайнерские материалы.",
    "st_top_25": "Enpainter может быть преобразован из одной фотографии в различные стили художников, таким образом, он может быть преобразован в различные дизайнерские материалы. Вы также можете использовать его для разработки материалов и брошюр для веб-сайтов и баннеров, а также для изготовления флаеров. Я хотел бы, чтобы вы попробовали, когда захотите изменить вкус имеющейся фотографии.",
    "st_top_26": "Используйте его для специальных подарков, как свадьба! ",
    "st_top_27": "Сделайте свадебную доску в стиле Ван Гога. Преобразовать семейный альбом в картины различных стилей. Конвертируйте памятные фотографии в стиль Моне и распечатывайте их на холсте или акриловых пластинах, чтобы повесить их в своей комнате. Их можно использовать различными способами.",
    "st_top_28": "Создание текстильного дизайна",
    "st_top_29": "Вы можете создавать различные текстильные изделия из одного типа. Вы можете применить преобразованные изображения к обоям, ткани или новому текстилю.",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "Вы можете использовать API для работы с различными системами.",
    "st_top_32": "cre8tiveAI Enpainter API позволяет включать AI, который может быть преобразован в различные стили художника в вашем сервисе. условия для реализации API варьируются в зависимости от службы, которую вы хотите достичь и спецификации системы, с которой вы работаете, поэтому, пожалуйста, свяжитесь с usfrom ниже.",
    "st_top_33": "Запрос на преобразование в стиль живописца",
    "st_top_34": "Отправка изображения, преобразованного в стиль художника.",
    "st_top_35": "Различные корпоративные услуги",
    "st_top_36": "Спросите о API",
    "st_top_37": "Вы можете получить более 10 известных художников стиля.",
    "st_top_38": "Выберите"
  },
  "bn": {
    "st_top_1": "এআই বিভিন্ন শিল্পী মত পেইন্টিং তৈরি করে।",
    "st_top_2": "আসুন আপনার ফটোগুলি এখন আপলোড করা যাক!",
    "st_top_1_1": "একটি একক আলোকচিত্র একটি শিল্প হয়ে ওঠে।",
    "st_top_3": "Enpainter কি?",
    "st_top_4": "ব্যবহার করতে সাইন ইন",
    "st_top_5": "টেনে আনুন চিত্র এখানে বা",
    "st_top_6": "ফাইল নির্বাচন করুন।",
    "st_top_7": "এআই \"Enpainter\" যে একটি বিখ্যাত চিত্রশিল্পী দ্বারা একটি পেইন্টিং মত একটি ছবি প্রক্রিয়াকরণ কি?",
    "st_top_8": "এটি একটি এআই যে শিখেছি বিভিন্ন বিখ্যাত artists.Enpainter বৈশিষ্ট্য যেমন ভ্যান গখ, পিকাসো এবং মুখ না খুলিয়া চিবানো যেমন বিখ্যাত শিল্পী, এর চেয়ে বেশি 10 বিভিন্ন বৈশিষ্ট্য শিখেছি করেছে। Enpainter, প্রতিটি চিত্রশিল্পী দ্বারা পেন্টিং বিভিন্ন শৈলীকে শিখে যাতে আপনি পেইন্টিং (watercolors, তৈলচিত্র) এর ছবি যেন তারা বর্তমান day.Simply একটি ছবি আপলোড করতে পুনরুত্থান হয়েছিল তৈরি এবং একটি বিখ্যাত চিত্রশিল্পী মতো প্রকৃত চিত্র পেতে পারেন।",
    "st_top_9": "ইমেজ বিভিন্ন শিল্পী শৈলী আবেদন করতে পারি",
    "st_top_10": "এটা ভ্যান গঘ শৈলী মতন দেখতে বানান।",
    "st_top_11": "এটা পাবলো পিকাসো শৈলী মতন দেখতে বানান।",
    "st_top_12": "এটা মুখ না খুলিয়া চিবানো শৈলী মতন দেখতে বানান।",
    "st_top_13": "প্রযুক্তি Enpainter সমর্থন করার জন্য",
    "st_top_14": "Enpainter একটি এআই যে Deep Learning ব্যবহার করে বিখ্যাত চিত্রশিল্পী দ্বারা পেন্টিং শৈলী শিখেছি হয়। চিত্রশিল্পী দ্বারা কোনো নির্দিষ্ট পেইন্টিং আবেদন পরিবর্তে, এআই পেইন্টিং বৃহৎ সংখ্যার উপর এআই প্রশিক্ষণ চিত্রশিল্পী এর চিত্রকলার বৈশিষ্ট্য learns। এই আপনি Enpainter একটি অজানা ইমেজ দিতে এবং এটি একটি পেইন্টিং রূপান্তর যেমন যদি এটা চিত্রশিল্পী দ্বারা আঁকা হয়েছে করতে পারবেন।",
    "st_top_15": "আপনার মূল ইমেজ তৈরি বিভিন্ন বিখ্যাত শিল্পীর শৈলী প্রয়োগ করতে পারেন।",
    "st_top_16": "ব্যবহার করা খুব সহজ",
    "st_top_17": "ফাইল আপলোড করুন",
    "st_top_18": "পেইন্টার নির্বাচন",
    "st_top_19": "একটি ছবি চিত্রশিল্পী শৈলী রূপান্তরিত পান!",
    "st_top_20": "আপনি এই পৃষ্ঠার উপরের ফাইল নির্বাচন এলাকায় শিল্পী শৈলী রূপান্তর করতে চান ছবির আপলোড করুন।",
    "st_top_21": "শিল্পী ছবিতে আপনার আপলোড করা প্রয়োগ করতে চান তা নির্বাচন করুন।",
    "st_top_22": "দাঁড়াও 30 সেকেন্ড সম্পর্কে, তাহলে আপনি একটি ইমেজ পেতে সক্ষম হবে শিল্পী আপনি নির্বাচন শৈলী রূপান্তরিত। চেক চিত্র এবং যদি কোন সমস্যা নেই এটা বিনামূল্যে ডাউনলোড করুন।",
    "st_top_23": "আপনি পরিস্থিতিতে বিভিন্ন Enpainter সুবিধা গ্রহণ করতে পারেন।",
    "st_top_24": "আপনি নকশা উপকরণ বিভিন্ন ধরণের তৈরি করতে পারেন।",
    "st_top_25": "Enpainter, বিভিন্ন শিল্পীর শৈলী একটি একক ফটো থেকে পরিবর্তিত করা যায় তাই এটি নকশা উপকরণ বিভিন্ন রূপান্তরিত করা যেতে পারে। এছাড়াও আপনি, ওয়েবসাইট ও ব্যানার জন্য উপকরণ এবং পুস্তিকা নকশা জন্য ব্যবহার করতে পারেন সেইসাথে প্রচার পত্র তৈরীর জন্য। আমি যখন আপনি আপনার আছে ছবির স্বাদ পরিবর্তন করতে চান আপনি চেষ্টা করে দেখতে চাই।",
    "st_top_26": "একটি বিবাহের মত বিশেষ উপহার জন্য এটি ব্যবহার করুন!",
    "st_top_27": "একটি ভ্যান গখ এর বিন্যাসে একটি বিবাহের স্বাগত বোর্ড করুন। চিত্রকলার বিভিন্ন শৈলী মধ্যে একটি পরিবার অ্যালবাম রূপান্তর করুন। মনেট শৈলী স্মারক ফটো রূপান্তর ও আপনার রুমে স্তব্ধ ক্যানভাস বা এক্রাইলিক প্লেট তাদের মুদ্রণ করুন। এটা তোলে বিভিন্ন উপায়ে ব্যবহার করা যেতে পারে।",
    "st_top_28": "টেক্সটাইল নকশা তৈরি করা হচ্ছে",
    "st_top_29": "আপনি একটি একক ধরণ থেকে বস্ত্র বিভিন্ন তৈরি করতে পারেন। তুমি তোমার ওয়ালপেপার, ফ্যাব্রিক, অথবা নতুন বস্ত্র রূপান্তরিত চিত্র আবেদন করতে পারেন।",
    "st_top_30": "cre8tiveAI Enpainter API",
    "st_top_31": "আপনি বিভিন্ন সিস্টেমের সাথে কাজ করতে এপিআই ব্যবহার করতে পারেন।",
    "st_top_32": "cre8tiveAI Enpainter API আপনাকে এআই আপনার পরিষেবাতে বিভিন্ন শিল্পী শৈলী রূপান্তরিত করা যাবে নিগমবদ্ধ করতে পারবেন। API এর বাস্তবায়নের শর্ত সেবা এবং সিস্টেম আপনার সাথে কাজ বিশেষ উল্লেখ আপনি অর্জন করতে চান তার উপর নির্ভর করে পরিবর্তিত হতে, তাই সেগুলি নীচে পরিচিতির usfrom দয়া করে।",
    "st_top_33": "পেইন্টার-শৈলী রূপান্তর অনুরোধ",
    "st_top_34": "একটি চিত্র একজন শিল্পী শৈলী রূপান্তরিত পাঠানো হচ্ছে",
    "st_top_35": "বিভিন্ন কর্পোরেট সেবা",
    "st_top_36": "এপিআই সম্পর্কে জিজ্ঞাসা করা",
    "st_top_37": "আপনার 10 টির বেশী বিখ্যাত শিল্পী শৈলী পেতে পারেন।",
    "st_top_38": "নির্বাচন করা"
  }
}
</i18n>
