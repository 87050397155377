<template>
  <v-dialog :value="show" max-width="900" persistent scrollable>
      <v-card class="form-card-container">
        <v-card-text class="scroll-area">
          <div class="close-button-container">
            <v-btn @click="close()" large fab flat :disabled="executing">
              <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
            </v-btn>
          </div>
          <v-container class="ca-card-container" ref="container">
            <!-- 選択した画像 -->
            <p class="uploaded-text">{{ $t("mpm_order1")}}</p>
            <div v-if="targetImageFile" class="uploaded-item">
              <p>{{ targetImageFile.name }}</p>
              <img v-if="targetImageSrc" :src="targetImageSrc" class="uploaded-img"/>
            </div>

            <!-- 注意書き -->
            <div class="caution-container">
              <p v-html="$t('mpm_order2')"></p>
            </div>

            <!-- 確定ボタン -->
            <!-- クレジット -->
            <div v-if="canOrderByCredit(isFHD=false)">
              <v-dialog v-model="showCreditConfirm" width="500" :disabled="executing">
                <v-btn
                  slot="activator"
                  class="draw-btn"
                  dark
                  round
                  large
                  block
                  :disabled="showIndicator"
                  @click="fireShowCreditModalEvent"
                >{{ $t("mpm_order3") }}</v-btn>
                <v-card>
                  <v-card-title
                    class="title lighten-2 px-5 font-weight-bold"
                    primary-title
                  >{{ $t("mpm_order3") }}</v-card-title>
                  <v-card-text class="text-xs-left px-5">
                    {{ $t("glb_ep_5", [`${MPM_UNIT_CREDIT_FOR_ORDER}`, `${currentGroupTotalCredits}`]) }}
                    <br />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      flat="flat"
                      @click="showCreditConfirm = false"
                    >{{ $t("glb_5") }}</v-btn>
                    <v-btn color="primary" flat @click="orderByCredit(isFHD=false)">{{ $t("glb_6") }}</v-btn>
                  </v-card-actions>
                </v-card>
                -->
              </v-dialog>
              <p class="price-info">{{ $t("glb_credit_consume", [MPM_UNIT_CREDIT_FOR_ORDER, currentGroupTotalCredits])}}</p>
            </div>
            <!-- 都度課金 -->
            <div v-else>
              <v-btn
                slot="activator"
                class="draw-btn"
                dark
                round
                large
                block
                :disabled="showIndicator"
                @click="openStripe(isFHD=false)"
              >{{ $t("mpm_order3") }}</v-btn>
            </div>

            <!-- FullHD / 59.94FPS についての 注意書き -->
            <div class="caution-container">
              <div v-if="currentSubscriptionID == 6">
                <p v-html="$t('mpm_order5')"></p>
              </div>
              <div v-else>
                <p v-html="$t('mpm_order6')"></p>
              </div>
            </div>
            <!-- FullHD用のボタン -->
            <!-- クレジット（メガプラン） -->
            <div v-if="this.currentSubscriptionID == 6 && canOrderByCredit(isFHD=true)">
              <v-dialog v-model="showCreditConfirmFHD" width="500" :disabled="executing">
                <v-btn
                  slot="activator"
                  class="draw-fhd-btn"
                  dark
                  round
                  large
                  block
                  :disabled="showIndicator"
                  @click="fireShowCreditModalEvent"
                >{{ $t("mpm_order7") }}</v-btn>
                <v-card>
                  <v-card-title
                    class="title lighten-2 px-5 font-weight-bold"
                    primary-title
                  >{{ $t("mpm_order7") }}</v-card-title>
                  <v-card-text class="text-xs-left px-5">
                    {{ $t("glb_ep_5", [`${MPM_FHD_UNIT_CREDIT_FOR_ORDER}`, `${currentGroupTotalCredits}`]) }}
                    <br />
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="red darken-1"
                      flat="flat"
                      @click="showCreditConfirmFHD = false"
                    >{{ $t("glb_5") }}</v-btn>
                    <v-btn color="primary" flat @click="orderByCredit(isFHD=true)">{{ $t("glb_6") }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <p class="price-info">{{ $t("glb_credit_consume", [MPM_FHD_UNIT_CREDIT_FOR_ORDER, currentGroupTotalCredits])}}</p>
            </div>
            <!-- 都度課金（メガプラン） -->
            <div v-else-if="this.currentSubscriptionID == 6 && !canOrderByCredit(isFHD=true)">
              <v-btn
                slot="activator"
                class="draw-btn"
                dark
                round
                large
                block
                :disabled="showIndicator"
                @click="openStripe(isFHD=true)"
              >{{ $t("mpm_order3") }}</v-btn>
            </div>
            <!-- メガプランでない場合はFHD処理は利用不可 -->
            <div v-else>
              <v-btn
                slot="activator"
                class="draw-fhd-btn-disabled"
                dark
                round
                large
                block
                disabled
              >{{ $t("mpm_order7") }}</v-btn>
            </div>
            <div v-if="showIndicator" class="loading-indicator-container">
              <v-progress-circular indeterminate color="#46BA4D"></v-progress-circular>
            </div>
            <div class="spec-container">
              <movie-spec/>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
import {
  API_BASE_URL, STRIPE_KEY, MPM_UNIT_CREDIT_FOR_ORDER, MPM_FHD_UNIT_CREDIT_FOR_ORDER
} from '../../settings'
import MovieSpec from "./MovieSpec.vue"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  components: {
    MovieSpec
  },
  data() {
    return {
      MPM_UNIT_CREDIT_FOR_ORDER: MPM_UNIT_CREDIT_FOR_ORDER,
      MPM_FHD_UNIT_CREDIT_FOR_ORDER: MPM_FHD_UNIT_CREDIT_FOR_ORDER,
      showCreditConfirm: false,
      showCreditConfirmFHD: false,
      executing: false, // クレジット実行はしばし時間がかかるため二重実行防止のdisable用に使う
    }
  },
  computed: {
    ...mapGetters("movingPhotoMaker", [
      "targetImageFile",
      "targetImageSrc",
      "preparingTargetImageSrc"
    ]),
    currentGroupTotalCredits() {
      return this.$store.state.user.currentGroupCreditInfo.total || 0
    },
    showIndicator() {
      return this.executing || this.preparingTargetImageSrc
    },
    currentSubscriptionID() {
      const currentGroup = this.$store.getters['user/currentGroup']
      if (currentGroup == null) {  // 未ログイン状態
        return -1
      }
      let subsc = this.$store.state.master.subscriptions.find((s) => s.id === currentGroup.subscription_id )
      return subsc.grouping_id
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
    fireShowCreditModalEvent() {
      const group = this.$store.getters["user/currentGroup"];
      /*global gtag*/
      gtag("event", "show_credit_modal", {
        event_category: "credit",
        event_label: "mpm",
        value: group.subscription_id
      });
    },
    canOrderByCredit(isFHD) {
      if (isFHD) {
        return this.currentGroupTotalCredits >= MPM_FHD_UNIT_CREDIT_FOR_ORDER
      } else {
        return this.currentGroupTotalCredits >= MPM_UNIT_CREDIT_FOR_ORDER
      }
    },
    // クレジット決済して注文
    async orderByCredit(isFHD) {
      if (this.executing) return
      this.executing = true
      this.showCreditConfirm = false
      this.showCreditConfirmFHD = false
      const requestId = await this.$store.dispatch(
        'movingPhotoMaker/consumeCreditsAndOrder',
        {token: this.$store.state.user.currentGroupCreditInfo.checkKey,
         locale: this.$i18n.locale,
         isFHD: isFHD
        }
      )
      if (requestId) {
        this.executing = false
        this.$router.push({name: 'mpmComplete', query: {req: requestId}});
      }
    },
    // 都度課金決済して注文
    // NOTE. stripe.redirectToCheckout を呼ぶと画面遷移するため、storeでなくここ(view)で処理する
    //       (store内の処理で画面遷移させたくない)
    async openStripe(isFHD) {
      const group = this.$store.getters["user/currentGroup"];
      let data = new FormData();
      data.append('file', this.targetImageFile)
      data.append('group_id', group.id)
      data.append('locale', this.$i18n.locale)
      data.append('isFHD', isFHD)
      const res = await axios({
        method: 'post',
        url: `${API_BASE_URL}/payment/v3/mpm/prepare`,
        data: data
      });
      if (res.status != 200) {
        this.$store.commit("screen/showError", res.data);
        console.log("[ERROR] ", res.data);
        return;
      }
      gtag("event", "show_payment_screen", {
        event_category: "payment",
        event_label: "pay_as_you_go_mpm",
        value: group.subscription_id
      });
      /*global Stripe*/
      const stripe = Stripe(STRIPE_KEY);
      const { error } = await stripe.redirectToCheckout({
        sessionId: res.data.session_id
      });
      if (error) {
        this.$store.commit("screen/showError", error);
        console.log("[ERROR]", error);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

.form-card-container {
  padding: 0px;
  position: relative;
  background-color: white;
  border-radius: 10px;
  min-height: 584px;

  @include mq-down(md) {
    min-height: initial;
    width: 100%;
    border-radius: 2px;
  }
  .scroll-area {
    padding: 0;
    height: auto;
    overflow-y: scroll;
    @include mq-down(md) {
      padding: 0 20px;
      width: 100%;
    }
  }
}
.close-button-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  @include mq-down(md) {
    padding: 0 16px;
    top: -12px;
    left: -28px;
  }
}
.close-image {
  width: 36px;
  height: 36px;
  @include mq-down(md) {
    width: 25px;
    height: 25px;
  }
}
.ca-card-container {
  margin: 0 0 46.7px;
  padding: 0;
  max-width: 900px;
  padding-top: 45px;

  .uploaded-text {
    font-size: 20px;
    line-height: 29px;
    font-weight: 400;
    //font: Regular 20px/50px Noto Nastaliq Urdu;
    letter-spacing: 0px;
    color: #424242;
    opacity: 1;
    margin-bottom: 5px;

    @include mq-down(md) {
      margin-top: 0;
      font-size: 15px;
    }
  }
}
.uploaded-item {
  height: 80px;
  margin-left: 75px;
  margin-right: 75px;
  background-color: #A6FF7B;
  border-radius: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq-down {
    flex-direction: column;
    height: 130px;
    margin-left: 0px;
    margin-right: 0px;
    justify-content: flex-start;
  }
  p {
    margin-bottom: 0;
    position: relative;
    font-size: 20px;
    font-weight: 300;
    line-height: 48px;
    word-wrap: break-word;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #424242;

    @include mq-down {
      left: 0;
      padding: 0 16px;
      margin: 0;
      font-size: 16px;
      max-width: 100%;
      line-height: 23px;
      margin-top: 16px;
    }
  }
  .uploaded-img {
    position: absolute;
    top: 10px;
    right: 17px;
    height: 60px;

    @include mq-down {
      position: static;
      padding: 0;
      margin-top: 15px;
      max-height: 60px;
    }
  }
}
.caution-container {
  margin: 47px auto 0;
  p {
    margin: 0;
    font-size: 18px;
    line-height: 40px;
  }
}
.draw-btn {
  margin: 0 auto;
  background: linear-gradient(120deg, #A6FF7B, #007A4B);
  font-size: 20px;
  margin-top: 39px;
  height: 55px;
  border-radius: 99px;
  width: 280px;
  @include mq-down {
    display: inline-flex;
    margin: 47px auto 0 auto;
    padding: 0;
    font-size: 20px;
    height: 60px;
    width: 285px;
  }
}
.draw-fhd-btn {
  margin: 0 auto;
  background: linear-gradient(120deg, #A6FF7B, #007A4B);
  font-size: 16px;
  margin-top: 39px;
  height: 55px;
  border-radius: 99px;
  width: 280px;
  @include mq-down {
    display: inline-flex;
    margin: 47px auto 0 auto;
    padding: 0;
    font-size: 16px;
    height: 60px;
    width: 285px;
  }
}
.draw-fhd-btn-disabled {
  margin: 0 auto;
  background: linear-gradient(120deg, #B7BBB6, #414543);
  font-size: 16px;
  margin-top: 39px;
  height: 55px;
  border-radius: 99px;
  width: 280px;
  @include mq-down {
    display: inline-flex;
    margin: 47px auto 0 auto;
    padding: 0;
    font-size: 16px;
    height: 60px;
    width: 285px;
  }
}
.price-info {
  margin: 10px auto 0;
}
.loading-indicator-container {
  margin-top: 24px;
}
.spec-container {
  margin-top: 74px;
  @include mq-down {
    margin-top: 25px;
  }
}
</style>

<i18n>
{
  "ja": {
    "mpm_order1": "選択した画像",
    "mpm_order2": "選択した画像を14種類の動画に変換しますか？<br>\n動画化には15分程度のお時間がかかります。",
    "mpm_order3": "動画に変換する",
    "mpm_order4": "動画化には15分程度の時間がかかります",
    "mpm_order5": "選択した画像を14種類の動画に変換しますか？<br>\n動画化には30〜60分程度のお時間がかかります。",
    "mpm_order6": "FHD / 59.94FPS の動画化には30分 - 60分程度の時間がかかります。<br>※FHD / 59.94FPS の変換をするためにはメガプランに加入する必要があります。",
    "mpm_order7": "FHD / 59.94FPSに変換する"
  },
  "en": {
    "mpm_order1": "Selected image",
    "mpm_order2": "Do you want to convert your selected images to 14 kinds of videos? <br>It will take about 15 minutes to make a video.",
    "mpm_order3": "Convert to video",
    "mpm_order4": "It takes about 15 minutes to make a video",
    "mpm_order5": "Do you want to convert your selected images to 14 kinds of videos?<br> It will take about 30 - 60 minutes to make a video.",
    "mpm_order6": "FHD / 59.94FPS video conversion takes about 30 - 60 minutes.<br>* You must subscribe to the Mega Plan for FHD / 59.94FPS conversion.",
    "mpm_order7": "Convert to FHD / 59.94FPS"
  },
  "fr": {
    "mpm_order1": "Image sélectionnée",
    "mpm_order2": "Vous souhaitez convertir les images que vous avez sélectionnées en 14 types de vidéos ? <br>Il vous faudra environ 15 minutes pour réaliser une vidéo.",
    "mpm_order3": "Convertir en vidéo",
    "mpm_order4": "Il faut environ 15 minutes pour réaliser une vidéo",
    "mpm_order5": "Vous souhaitez convertir les images que vous avez sélectionnées en 14 types de vidéos ?<br> Il vous faudra environ 30 - 60 minutes pour réaliser une vidéo.",
    "mpm_order6": "La conversion d'une vidéo FHD / 59.94FPS prend environ 30 à 60 minutes.<br>* Vous devez souscrire au Mega Plan pour la conversion FHD / 59.94FPS.",
    "mpm_order7": "Conversion en FHD / 59.94FPS"
  },
  "es": {
    "mpm_order1": "Imagen seleccionada",
    "mpm_order2": "¿Quieres convertir tus imágenes seleccionadas en 14 tipos de videos? <br>Llevará unos 15 minutos hacer un vídeo.",
    "mpm_order3": "Convertir a video<br>* Debes suscribirte al Mega Plan para la conversión FHD / 59.94FPS.",
    "mpm_order4": "Se tarda unos 15 minutos en hacer un vídeo",
    "mpm_order5": "¿Quieres convertir tus imágenes seleccionadas en 14 tipos de videos?<br> Llevará unos 30 - 60 minutos hacer un vídeo.",
    "mpm_order6": "La conversión de vídeo FHD / 59.94FPS tarda entre 30 y 60 minutos.<br>* Debes suscribirte al Mega Plan para la conversión FHD / 59.94FPS.",
    "mpm_order7": "Convertir a FHD / 59.94FPS"
  },
  "pt": {
    "mpm_order1": "Imagem selecionada",
    "mpm_order2": "Quer converter as suas imagens seleccionadas em 14 tipos de vídeos? <br>Demorará cerca de 15 minutos a fazer um vídeo.",
    "mpm_order3": "Converter em vídeo",
    "mpm_order4": "Demora cerca de 15 minutos a fazer um vídeo",
    "mpm_order5": "Quer converter as suas imagens seleccionadas em 14 tipos de vídeos?<br> Demorará cerca de 30 - 60 minutos a fazer um vídeo.",
    "mpm_order6": "La conversión de vídeo FHD / 59.94FPS tarda entre 30 y 60 minutos.<br>* Deve subscrever o Plano Mega para conversão FHD / 59.94FPS.",
    "mpm_order7": "Converter para FHD / 59.94FPS"
  },
  "de": {
    "mpm_order1": "Ausgewähltes Bild",
    "mpm_order2": "Möchten Sie Ihre ausgewählten Bilder in 14 Arten von Videos konvertieren? <br>Die Erstellung eines Videos dauert etwa 15 Minuten.",
    "mpm_order3": "In Video konvertieren",
    "mpm_order4": "Es dauert etwa 15 Minuten, ein Video zu erstellen.",
    "mpm_order5": "Möchten Sie Ihre ausgewählten Bilder in 14 Arten von Videos konvertieren?<br> Die Erstellung eines Videos dauert etwa 30 - 60 Minuten.",
    "mpm_order6": "Die Konvertierung von Videos in FHD / 59.94FPS dauert etwa 30 bis 60 Minuten.<br>* Für die Konvertierung in FHD / 59.94FPS müssen Sie den Mega Plan abonnieren.",
    "mpm_order7": "Konvertieren in FHD / 59.94FPS"
  },
  "nl": {
    "mpm_order1": "Geselecteerde afbeelding",
    "mpm_order2": "Wilt u uw geselecteerde afbeeldingen omzetten naar 14 soorten video's? <br>Het duurt ongeveer 15 minuten om een video te maken.",
    "mpm_order3": "Omzetten naar video",
    "mpm_order4": "Het duurt ongeveer 15 minuten om een video te maken",
    "mpm_order5": "Wilt u uw geselecteerde afbeeldingen omzetten naar 14 soorten video's?<br> Het duurt ongeveer 30 - 60 minuten om een video te maken.",
    "mpm_order6": "FHD / 59.94FPS video conversie duurt ongeveer 30 - 60 minuten.<br>* U moet zich abonneren op het Mega Plan voor FHD / 59.94FPS conversie.",
    "mpm_order7": "Converteren naar FHD / 59.94FPS"
  },
  "tr": {
    "mpm_order1": "Seçilen resim",
    "mpm_order2": "Seçtiğiniz görüntüleri 14 tür videoya dönüştürmek ister misiniz? <br>Bir video yapmak yaklaşık 15 dakika sürecektir.",
    "mpm_order3": "Videoya Dönüştür",
    "mpm_order4": "Bir video yapmak yaklaşık 15 dakika sürer",
    "mpm_order5": "Seçtiğiniz görüntüleri 14 tür videoya dönüştürmek ister misiniz?<br> Bir video yapmak yaklaşık 30-60 dakika sürecektir.",
    "mpm_order6": "FHD / 59.94FPS video dönüşüm yaklaşık 30-60 dakika sürer.<br>* FHD / 59.94FPS dönüşümü için Mega Plan'a abone olmalısınız.",
    "mpm_order7": "FHD / 59.94FPS'ye dönüştürün"
  },
  "zh-cn": {
    "mpm_order1": "选择的图像",
    "mpm_order2": "你想把你选择的图片转换成14种视频吗？<br>这将需要大约15分钟来制作一个视频。",
    "mpm_order3": "转换为视频",
    "mpm_order4": "制作一个视频大约需要15分钟",
    "mpm_order5": "你想把你选择的图片转换成14种视频吗？<br>这将需要大约30 - 60分钟来制作一个视频。",
    "mpm_order6": "FHD / 59.94FPS视频转换大约需要30 - 60分钟。<br>* 你必须订阅巨型计划以实现FHD/59.94FPS转换。",
    "mpm_order7": "转换为FHD/59.94FPS"
  },
  "zh-tw": {
    "mpm_order1": "選擇的圖像",
    "mpm_order2": "您想將所選圖像轉換為14種視頻嗎？<br>製作視頻大約需要15分鐘。",
    "mpm_order3": "轉換為視頻",
    "mpm_order4": "製作視頻大約需要15分鐘",
    "mpm_order5": "您想將所選圖像轉換為14種視頻嗎？<br>製作視頻大約需要30到60分鐘。",
    "mpm_order6": "FHD / 59.94FPS視頻轉換大約需要30-60分鐘。<br>*您必須訂閱FHD / 59.94FPS轉換的巨型計劃。",
    "mpm_order7": "轉換為FHD / 59.94FPS"
  },
  "sv": {
    "mpm_order1": "Vald bild",
    "mpm_order2": "Vill du konvertera dina valda bilder till 14 typer av videor? <br>Det tar cirka 15 minuter att göra en video.",
    "mpm_order3": "Konvertera till video",
    "mpm_order4": "Det tar cirka 15 minuter att göra en video",
    "mpm_order5": "Vill du konvertera dina valda bilder till 14 typer av videor?<br> Det tar cirka 30 - 60 minuter att göra en video.",
    "mpm_order6": "FHD / 59.94fps videokonvertering tar cirka 30 - 60 minuter.<br>* Du måste prenumerera på MEGA -planen för FHD / 59.94FPS -konvertering.",
    "mpm_order7": "Konvertera till FHD / 59.94fps"
  },
  "it": {
    "mpm_order1": "Immagine selezionata",
    "mpm_order2": "Volete convertire le immagini selezionate in 14 tipi di video? <br>Ci vorranno circa 15 minuti per realizzare un video.",
    "mpm_order3": "Convertire in video",
    "mpm_order4": "Ci vogliono circa 15 minuti per realizzare un video",
    "mpm_order5": "Volete convertire le immagini selezionate in 14 tipi di video?<br> Ci vorranno circa 30 - 60 minuti per realizzare un video.",
    "mpm_order6": "La conversione dei video FHD / 59,94 FPS richiede circa 30-60 minuti.<br>* Per la conversione FHD / 59,94 FPS è necessario sottoscrivere il piano Mega.",
    "mpm_order7": "Conversione in FHD / 59,94FPS"
  },
  "ko": {
    "mpm_order1": "선택한 화상",
    "mpm_order2": "선택한 이미지를 14 가지 종류의 비디오로 변환 하시겠습니까? <br>비디오를 만드는 데 약 15 분이 걸립니다.",
    "mpm_order3": "비디오로 변환하십시오",
    "mpm_order4": "비디오를 만드는 데 약 15 분이 걸립니다",
    "mpm_order5": "선택한 이미지를 14 가지 종류의 비디오로 변환 하시겠습니까?<br> 비디오를 만드는 데 약 30-60 분이 걸립니다.",
    "mpm_order6": "FHD / 59.94FPS 비디오 변환은 약 30-60 분이 소요됩니다.<br>* FHD / 59.94fps 변환에 대한 메가 플랜을 구독해야합니다.",
    "mpm_order7": "FHD / 59.94fps로 변환하십시오"
  },
  "ar": {
    "mpm_order1": "صورة محددة",
    "mpm_order2": "هل تريد تحويل الصور التي اخترتها إلى 14 نوعًا من مقاطع الفيديو؟ <br>سوف يستغرق الأمر حوالي 15 دقيقة لصنع مقطع فيديو.",
    "mpm_order3": "تحويل الفيديو",
    "mpm_order4": "يستغرق حوالي 15 دقيقة لصنع مقطع فيديو",
    "mpm_order5": "هل تريد تحويل الصور التي اخترتها إلى 14 نوعًا من مقاطع الفيديو؟ سيستغرق الأمر حوالي 30 - 60 دقيقة لصنع مقطع فيديو.",
    "mpm_order6": "يستغرق تحويل الفيديو FHD / 59.94FPS حوالي 30 - 60 دقيقة.<br>* يجب عليك الاشتراك في خطة MEGA لتحويل FHD / 59.94FPS.",
    "mpm_order7": "تحويل إلى FHD / 59.94 إطارًا في الثانية"
  },
  "th": {
    "mpm_order1": "ภาพที่เลือก",
    "mpm_order2": "คุณต้องการแปลงภาพที่คุณเลือกเป็น 14 รายการวิดีโอหรือไม่? <br>จะใช้เวลาประมาณ 15 นาทีในการทำวิดีโอ",
    "mpm_order3": "แปลงเป็นวิดีโอ",
    "mpm_order4": "ใช้เวลาประมาณ 15 นาทีในการทำวิดีโอ",
    "mpm_order5": "คุณต้องการแปลงภาพที่คุณเลือกเป็น 14 รายการวิดีโอหรือไม่?<br> จะใช้เวลาประมาณ 30 - 60 นาทีในการทำวิดีโอ",
    "mpm_order6": "การแปลงวิดีโอ FHD / 59.94FPS ใช้เวลาประมาณ 30 - 60 นาที<br>* คุณต้องสมัครสมาชิกแผนขนาดใหญ่สำหรับการแปลง FHD / 59.94FPS",
    "mpm_order7": "แปลงเป็น FHD / 59.94FPS"
  },
  "id": {
    "mpm_order1": "Gambar yang dipilih",
    "mpm_order2": "Apakah Anda ingin mengonversi gambar yang Anda pilih menjadi 14 jenis video? <br>Ini akan memakan waktu sekitar 15 menit untuk membuat video.",
    "mpm_order3": "Konversi ke video",
    "mpm_order4": "Dibutuhkan sekitar 15 menit untuk membuat video",
    "mpm_order5": "Apakah Anda ingin mengonversi gambar yang Anda pilih menjadi 14 jenis video?<br> Ini akan memakan waktu sekitar 30 - 60 menit untuk membuat video.",
    "mpm_order6": "Konversi video FHD / 59.94FPS memakan waktu sekitar 30 - 60 menit.<br>* Anda harus berlangganan rencana mega untuk konversi FHD / 59.94FPS.",
    "mpm_order7": "Konversi ke FHD / 59.94FPS"
  },
  "pl": {
    "mpm_order1": "Wybrany obraz",
    "mpm_order2": "Czy chcesz przekonwertować swoje wybrane zdjęcia na 14 rodzajów filmów? <br>Zrobienie filmu zajmie około 15 minut.",
    "mpm_order3": "Konwersja na wideo",
    "mpm_order4": "Nagranie filmu trwa około 15 minut.",
    "mpm_order5": "Czy chcesz przekonwertować swoje wybrane zdjęcia na 14 rodzajów filmów?<br> Zrobienie filmu zajmie około 30 - 60 minut.",
    "mpm_order6": "Konwersja wideo FHD / 59,94FPS trwa około 30-60 minut.<br> * Aby uzyskać konwersję FHD / 59,94FPS, należy wykupić Mega Plan.",
    "mpm_order7": "Konwersja do FHD / 59,94FPS"
  },
  "vi": {
    "mpm_order1": "Hình ảnh được chọn",
    "mpm_order2": "Bạn có muốn chuyển đổi hình ảnh đã chọn của mình thành 14 loại video không? <br>Sẽ mất khoảng 15 phút để làm một video.",
    "mpm_order3": "Chuyển đổi sang video",
    "mpm_order4": "Mất khoảng 15 phút để làm video",
    "mpm_order5": "Bạn có muốn chuyển đổi hình ảnh đã chọn của mình thành 14 loại video không?<br> Sẽ mất khoảng 30 - 60 phút để làm video.",
    "mpm_order6": "Chuyển đổi video FHD / 59,94FPS mất khoảng 30 - 60 phút.<br>* Bạn phải đăng ký kế hoạch lớn để chuyển đổi FHD / 59,94fps.",
    "mpm_order7": "Chuyển đổi sang FHD / 59,94fps"
  },
  "hi": {
    "mpm_order1": "Chayanit picture",
    "mpm_order2": "क्या आप अपनी चयनित छवियों को 14 प्रकार के वीडियो में बदलना चाहते हैं? <br>वीडियो बनाने में लगभग 15 मिनट लगेंगे।",
    "mpm_order3": "वीडियो में परिवर्तित करें",
    "mpm_order4": "वीडियो बनाने में लगभग 15 मिनट लगते हैं",
    "mpm_order5": "क्या आप अपनी चयनित छवियों को 14 प्रकार के वीडियो में बदलना चाहते हैं?<br> वीडियो बनाने में लगभग 30 - 60 मिनट लगेंगे।",
    "mpm_order6": "FHD / 59.94FPS वीडियो रूपांतरण में लगभग 30 - 60 मिनट लगते हैं।<br>* आपको FHD / 59.94FPS रूपांतरण के लिए MEGA योजना की सदस्यता लेनी होगी।",
    "mpm_order7": "FHD / 59.94fps में परिवर्तित करें"
  },
  "ru": {
    "mpm_order1": "Выбранное изображение",
    "mpm_order2": "Вы хотите конвертировать выбранные изображения в 14 видов видео? <br>Съемка видео займет около 15 минут.",
    "mpm_order3": "Конвертировать в видео",
    "mpm_order4": "Создание видео занимает около 15 минут",
    "mpm_order5": "Вы хотите конвертировать выбранные изображения в 14 видов видео?<br> Съемка видео займет около 30 - 60 минут.",
    "mpm_order6": "Конвертирование видео FHD / 59,94FPS занимает около 30 - 60 минут.<br> * Для конвертации в FHD / 59.94FPS необходимо подписаться на тарифный план Mega Plan.",
    "mpm_order7": "Конвертация в FHD / 59.94FPS"
  },
  "bn": {
    "mpm_order1": "নির্বাচিত চিত্র",
    "mpm_order2": "আপনি কি আপনার নির্বাচিত চিত্রগুলিকে 14 ধরণের ভিডিওতে রূপান্তর করতে চান? <br>ভিডিও তৈরি করতে প্রায় 15 মিনিট সময় লাগবে।",
    "mpm_order3": "ভিডিওতে রূপান্তর করুন",
    "mpm_order4": "একটি ভিডিও তৈরি করতে প্রায় 15 মিনিট সময় লাগে",
    "mpm_order5": "আপনি কি আপনার নির্বাচিত চিত্রগুলিকে 14 ধরণের ভিডিওতে রূপান্তর করতে চান?<br> একটি ভিডিও তৈরি করতে প্রায় 30 - 60 মিনিট সময় লাগবে।",
    "mpm_order6": "এফএইচডি / 59.94 এফপিএস ভিডিও রূপান্তরটি প্রায় 30 - 60 মিনিট সময় নেয়।<br>* আপনাকে অবশ্যই এফএইচডি / 59.94fps রূপান্তরকরণের জন্য মেগা পরিকল্পনায় সাবস্ক্রাইব করতে হবে।",
    "mpm_order7": "এফএইচডি / 59.94fps এ রূপান্তর করুন"
  }
}
