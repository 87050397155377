<template>
  <v-btn dark class="btn-sc pink lighten-2 text-none" @click.stop="dialog = true" to="/sc/make">
    <span class="btn-contents">
      {{ btnText }}
    </span>
    <v-img class="palet" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sc_palet.svg"></v-img>
  </v-btn>
</template>

<script>
export default {
  data () {
    return {
      dialog: false
    }
  },
  props: {
    btnText: String,
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
.btn-sc {
  max-width: 88vw;
  width: 448px;
  min-height: 64px;
  font-size: 28px;
  font-weight: 400;
  border-radius: 99px;
  
  @include mq-down(sm) {
    min-width: 0px;
    max-width: 448px;
    width: 88vw;
    font-size: 6vw;
  }

  .btn-contents {
    max-width: 88vw;
    white-space: normal;
  }
}
a {
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

.palet {
  max-width: 96px;
  margin-right: -68px;
  @include mq-down {
    margin-right: -38px;
    max-width: 88px;
  }
}
</style>
