<template>
  <div class="example-image-container">
    <div class="after-image-carousel" ref="afterImageCarousel" :style="{ height: afterImageHeight + 'px', width: afterImageWidth + 'px' }">
      <img v-for="(url, idx) in afterImageURLs"
        :key="idx" :src="url" class="after-example-image" :style="{ height: afterImageHeight + 'px', width: afterImageWidth + 'px' }"/>
    </div>
    <div class="before-example-image-container">
      <div class="before-example-image" :class="{focused: focusedExampleIdx === 0}" @click="onSelectExampleImage(0)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="beforeImageURLs[0]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
      <div class="before-example-image" :class="{focused: focusedExampleIdx === 1}" @click="onSelectExampleImage(1)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="beforeImageURLs[1]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
      <div class="before-example-image" :class="{focused: focusedExampleIdx === 2}" @click="onSelectExampleImage(2)" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"><img :src="beforeImageURLs[2]" :style="{ height: beforeImageHeight + 'px', width: beforeImageWidth + 'px' }"/></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['afterImageURLs', 'beforeImageURLs'],
  data() {
    return {
      focusedExampleIdx: 0,
      afterImageHeight: 229.5, // 変換後の画像の高さ(タブレットサイズ以下は動的に調整する)
      afterImageWidth: 368, // // 変換後の画像の横幅(タブレットサイズ以下は動的に調整する)
      beforeImageHeight: 80.3, // 変換前の画像の高さ(同上)
      beforeImageWidth: 122.0 //  変換前の画像の横幅(同上)
    }
  },
  methods: {
    onSelectExampleImage(index) {
      this.focusedExampleIdx = index
      // 選択された画像が表示されるようなめらかにスクロール
      //let carouselImageWidth = this.$refs.afterImageCarousel.clientWidth
      this.$refs.afterImageCarousel.scrollTo({
        left: this.afterImageWidth * index,
        top: 0,
        behavior: 'smooth'
      })
    },
    calcAfterImageHeight() {
      // タブレット幅以下であれば動的に計算
      // TODO: https://bitbucket.org/k-sugawara_radius5/creative_ai/pull-requests/209 で話した改修を行ったら $vuetifyを使うよう修正したい
      //if (window.innerWidth < this.$vuetify.breakpoint.thresholds.sm) {
      if (window.innerWidth < 767) {
        // 280 / 450 ... 素材画像の縦比率
        this.afterImageHeight = 280 / 450 * window.innerWidth
        this.afterImageWidth = window.innerWidth
        // スクリーン横幅から両端のマージン(24pxずつ) を引いた領域の3分割が1画像あたりの横幅
        this.beforeImageWidth = (window.innerWidth - 48) / 3
        this.beforeImageHeight = 98 / 150 * this.beforeImageWidth
      }
    },
  },
  created() {
    this.calcAfterImageHeight()
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";
@import url(https://use.typekit.net/ddm1tzj.css);

/*
  PCでの各画像の高さは以下の条件を満たすようにして決めた
  after-example-imageの横長さをA, 高さをB, before-example-imageの横長さをC, 高さをDとすると
  ・A = 3C (before画像3枚の横幅とafter画像の横幅が同じ)
  ・B + 14px + D = 330px (デザイン仕様より全体の高さ330px, afterとbeforeの間隔が14px)
  ・A : B = 450px : 280px (素材画像のintrinsic sizeより)
  ・C : D = 150px : 98px  (同上)
  これより A=368.8px, B=229.5px, C=122.0px, D=80.3px
*/
.example-image-container {
  height: 360px;
  @include mq-down {
    height: unset;
  }
  .after-image-carousel {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    width: 368.8px;
    height: 229.5px;
    margin-bottom: 14px;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mq-down {
      width: 100%;
      margin-bottom: 12px;
      //height: initial;
    }
  }
  .after-example-image {
    width: 368.8px;
    height: 229.5px;
    position: relative;
    //object-fit: contain;
    object-fit: fill;
    //display: inline;
    flex: 0 0 auto;
    @include mq-down {
      width: 100%; // widthもうまく効かない場合があるためjsで指定することにした
      // NOTE. 実機だと高さが280px(=PCでのアスペクト比を崩さない画像高さ)になってしまって
      // object-fit: contain でアスペクト比を崩さず表示すると画像の上下にあまりがでてしまう・・
      // (PCで画面を狭めたときは問題ない) => 仕方がないのでjsでheightを設定した
    }
  }
  .before-example-image-container {
    @include mq-down {
      margin-left: auto;
      margin-right: auto;
    }
    .before-example-image {
      display: inline-block;
      margin-bottom: 20px;
      &.focused {
        outline: 3px solid #42A5F5;
        margin-right: 3px; // outlineが右隣の画像の下に回り込んでしまうのを防ぐ
        @include mq-down {
          outline: 2px solid #42A5F5;
          margin-right: 2px;
        }
      }
      @include mq-down {
        //width: calc((100% -48px) / 3);
        margin-bottom: 12px;
      }
      img {
        box-sizing: border-box;
        width: 120px;
        height: 78.4px;
        @include mq-down {
          width: 100%;
          //height: initial;
        }
      }
    }
  }
}
</style>