<template>
  <v-dialog persistent :value="showDialog" width="900">
    <v-card class="form-card-container">
      
      <div class="close-button-container">
        <v-btn @click="close()" large fab flat>
          <img class="close-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/pr/renewal/upload/cross_close.svg">
        </v-btn>
      </div>

      <p class="selected-image-text">{{ $t("glb_scp_1") }}</p>
      <v-img :src="orderImageSrc" class="order-image"></v-img>

      <v-layout align-center justify-center class="sai-guide-container">
        <v-img class="sai-face" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/saiChan_face.webp">
          <v-img class="purple-plus" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/01_firstView/plus_purple.svg"></v-img>
        </v-img>
        <p class="sai-balloon">
          {{ $t('glb_scp_45') }}
        </p>
      </v-layout>

      <h3 class="form-heading">{{ $t('glb_scp_46') }}</h3>

      <div class="confirm-container">
        <!-- 顔 -->
        <p class="confirm-contents">
          <span class="left-contents">{{ $t('glb_scp_47') }} :</span>
          <span class="right-contents">
            {{ faceAdjust ? $t('glb_scp_5') : $t('glb_scp_4') }}
          </span>
        </p>
        <!-- 服装 -->
        <p class="confirm-contents">
          <span class="left-contents">
            {{ $t('glb_scp_7') }} :&nbsp;
          </span>
          <span class="right-contents" v-if="clothType === CLOTH_TYPE.FANTASY">
            {{ $t('glb_scp_8') }}
          </span>
          <span class="right-contents" v-else-if="clothType === CLOTH_TYPE.UNIFORM">
            {{ $t('glb_scp_9') }}
          </span>
          <span class="right-contents" v-else>
            {{ $t('glb_scp_10') }}[<span class="other-text">{{ clothText }}</span>]
          </span>
        </p>
        <!-- ポーズ -->
        <p class="confirm-contents">
          <span class="left-contents">
            {{ $t('glb_scp_48') }} :&nbsp;
          </span>
          <span class="right-contents" v-if="poseType === POSE_TYPE.FRONT">
            {{ $t('glb_scp_15') }}
          </span>
          <span class="right-contents" v-else-if="poseType === POSE_TYPE.TRADEMARK">
            {{ $t('glb_scp_16') }}
          </span>
          <span class="right-contents" v-else-if="poseType === POSE_TYPE.MODELSHEET" v-html="$t('glb_scp_57')">
          </span>
        </p>
        <!-- 表情差分 -->
        <p class="confirm-contents">
          <span class="left-contents">
            {{ $t('glb_scp_49') }} :&nbsp;
          </span>
          <span class="right-contents" v-if="changeExpression">
            {{ $t('glb_scp_52') }}
          </span>
          <span class="right-contents" v-else>
            {{ $t('glb_scp_21') }}
          </span>
        </p>
        <!-- 高解像度化 -->
        <p class="confirm-contents">
          <span class="left-contents">
            {{ $t('glb_scp_50') }} :&nbsp;
          </span>
          <span class="right-contents" v-if="orderHighResolution">
            {{ $t('glb_scp_53') }}
          </span>
          <span class="right-contents" v-else>
            {{ $t('glb_scp_29') }}
          </span>
        </p>
        <!-- その他 -->
        <p class="confirm-contents">
          <span class="left-contents">
            {{ $t('glb_scp_51') }} :&nbsp;
          </span>
          <span class="right-contents other-text">
            {{ otherText }}
          </span>
        </p>
        <div v-show="true" @click="confirm" class="selection-item elevation-3">
          {{ $t('glb_scp_54', [($i18n.locale == "ja" ? requiredJPY : requiredUSD).toLocaleString()]) }}
        </div>
      </div>
      <div class="back-button-container">
        <v-btn class="back-button" flat @click="back()">
          <div class="back-container text-none">
            <v-img contain class="back-button-image" src="https://storage.googleapis.com/cre8tiveai_public_files/img/sai-chan/sai-plus/99_orderform/chevron-back.svg"></v-img>
            <p>back</p>
          </div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      
    }
  },
  computed: {
    ...mapGetters('saichanPlus', [
      'requiredCredits',
      'requiredJPY',
      'requiredUSD'
    ]),
    ...mapState('saichanPlus', {
      faceAdjust: state => state.faceAdjust,
      clothType: state => state.clothType,
      clothText: state => state.clothText,
      poseType: state => state.poseType,
      poseText: state => state.poseText,
      changeExpression: state => state.changeExpression,
      orderHighResolution: state => state.orderHighResolution,
      expressionText: state => state.expressionText,
      otherText: state => state.otherText,
      // マスタデータ
      CLOTH_TYPE: state => state.CLOTH_TYPE,
      POSE_TYPE: state => state.POSE_TYPE,
      // 画像データ
      orderImageSrc: state => state.orderImageSrc,
    })
  },
  methods: {
    back() {
      this.$emit('back')
    },
    confirm() {
      this.$emit('confirmed')
    },
    close() {
      this.$emit('close');
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables.scss";
@import "../../../assets/scss/_sc-plus-form.scss";

.form-heading {
  margin-top: 16px;
  margin-bottom: 32px;
}

.next-button-container {
  margin-top: 0;

  .next-button {
    padding: 8px 4px 0;
    height: initial;
    width: initial;

    .next-container {
      @include franklin_font;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      font-weight: 400;
      transform-origin: 50% 50%; 

      .next-button-image {
        width: 64px;
        margin-bottom: 12px;
        @include moving_image;
      }
    }
  }
}

.confirm-container {
  padding-bottom: 16px;
  .confirm-contents {
    font-size: 16px;
    margin-bottom: 4px;
    text-align: center;
    .left-contents {
      display: inline-block;
      text-align: right;
      width: 30%;
      vertical-align: top;
      @include mq-down {
        width: 40%;
      }
    }
    .right-contents {
      display: inline-block;
      text-align: left;
      width: 55%;

      @include mq-down {
        width: 60%;
      }
    }
  }
}

.selection-item {
  margin: 48px auto 32px;
  border-radius: 99px;
  max-width: 500px;
  padding: 26px 2px;
  background-color: white;
  font-size: 18px;
  font-weight: 400;

  &::before {
    border-radius: 99px;
  }
  @include mq-down {
    max-width: initial;
    margin-top: 16px;
    margin-bottom: 72px;
  }
}

.other-text {
  padding: 4px 0 0;
  font-size: 14px;
}

.back-button-container {
  @include mq-down {
    bottom: -32px;
  }
}

</style>