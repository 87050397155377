import axios from 'axios'

// マスタデータ。色々な場所から参照用途のみに使う。
const initialState = {
  subscriptions: []
}

export default {
  namespaced: true,
  state: Object.assign({}, initialState),
  getters: {
    subscriptionById: (state) => (id) => {
      return state.subscriptions.find(s => s.id === id)
    },
    changeableSubscriptionsByLang: (state) => (lang) => {
      return state.subscriptions.filter(s => s.language === lang && s.plan_changeable === 1)
    }
  },
  mutations: {
    setMasterData(state, data) {
      state.subscriptions = data.subscriptions
    },
  },
  actions: {
    getMasterData({commit}) {
      return new Promise(() => {
        axios.get(`/master/`).then(res => {
          commit('setMasterData', res.data)
        })
        // requests.jsの axios.interceptors.response でエラーキャッチ + dialog表示を行っている
        //.catch(err => reject(err.response))
      })
    }
  },
}
